<template>
  <div class="pages">
	<div class="lines-title" style="margin-top: 0;">
		<div class="lines-left"></div>
		<span>安全设置</span>
		<div class="hengxian"></div>
	</div>  
	<div class="list">
		<div class="item">
			<div class="left">
				<div class="title">登录密码</div>
				<div class="info">安全性高的密码可以使账户更安全。建议您定期更换密码，设置6～12位登录密码</div>
			</div>
			<div class="right cursor" v-if="userInfo.passWordIs==0" @click="clickPwdShow">
				<img src="../../../static/images/center/wait@2x.png" />
				<span>设置</span>
			</div>
			<div class="right cursor" v-if="userInfo.passWordIs==1" @click="clickPwdShow">
				<img src="../../../static/images/center/ok@2x.png" />
				<span>修改</span>
			</div>
		</div>
		<div class="item">
			<div class="left">
				<div class="title">安全手机<span>{{ $mHelper.mobileHide(userInfo.mobile || '') }}</span></div>
				<div class="info">安全手机可以用户登录账号、重置密码或其他安全验证</div>
			</div>
			<div class="right cursor" @click="alertPhoneShow = true">
				<img src="../../../static/images/center/ok@2x.png" />
				<span>修改</span>
			</div>
		</div>
		<div class="item">
			<div class="left">
				<div class="title">支付密码</div>
				<div class="info">设置账户支付密码</div>
			</div>
			<div class="right cursor">
				<div v-if="userInfo.pwdState == 1" @click='setWithdrawalPsw(1)'>
					<img src="../../../static/images/center/wait@2x.png" />
					<span>设置</span>
				</div>
				<div v-else @click="setWithdrawalPsw(2)">
					<img src="../../../static/images/center/ok@2x.png" />
					<span>修改</span>
				</div>
			</div>
		</div>
		<div class="item" v-if="userInfo.identity == 1 || userInfo.identity == 2">
			<div class="left">
				<div class="title">微信绑定</div>
				<div class="info">第三方登录方式，绑定微信后登录时可使用扫描二维码登录该账户</div>
			</div>
			<div class="right cursor">
				<div v-if="userInfo.openId" @click='cancelBind()'>
					<img src="../../../static/images/center/ok@2x.png" />
					<span>解绑</span>
				</div>
				<div v-else @click="wxBindType">
					<img src="../../../static/images/center/wait@2x.png" />
					<span>绑定</span>
				</div>
			</div>
		</div>
	</div>
	
    
    <el-dialog :visible.sync="wxCode" width="40%" center top='20vh' class="dialog-wechat">
      <div class="w-font-13">
		<div class="dialog-title">
			<span v-if="userInfo.openId">微信解绑</span>
			<span v-else>微信绑定</span>
		</div>
        <div class="dialog-info w-m-t-24 w-m-b-36">请使用微信扫一扫扫描下方二维码</div>  
       
        <div class="mdx-flex mdx-row-center" style="margin-bottom: -100px;">
          <wxlogin appid="wxa4d88247803ff3be" :scope="'snsapi_login'"
            :redirect_uri='encodeURIComponent($store.state.callback + "/setting")' :state="userInfo.openId ? 2 : 1"
            :href="'data:text/css;base64,LmltcG93ZXJCb3ggLnFyY29kZSB7d2lkdGg6IDIwMHB4O30KLmltcG93ZXJCb3ggLnRpdGxlIHtkaXNwbGF5OiBub25lO30KLmltcG93ZXJCb3ggLmluZm8ge3dpZHRoOiAyMDBweDt9Ci5zdGF0dXNfaWNvbiB7ZGlzcGxheTogbm9uZX0KLmltcG93ZXJCb3ggLnN0YXR1cyB7dGV4dC1hbGlnbjogY2VudGVyO30g'"
            rel="external nofollow">
          </wxlogin>
        </div>
        <!-- <div class="w-m-t-10 mdx-text-center w-m-t-15">
          使用微信*扫一扫*扫码绑定
        </div> -->
      </div>
    </el-dialog>
    <el-dialog :visible.sync="alertPhoneShow" width="40%" center top='20vh'>
      <div class="w-font-13">
        <div class="dialog-title">更换安全手机 </div>
        <div class="w-m-t-24">
          <el-form label-position="left" label-width="70px" :model="formLabelAlign">
			<div class="dialog-info">1、安全手机用于平台登录、获取验证码、短信通知；</div>  
			<div class="dialog-info">2、已注册的手机号不能使用，一个手机号只能绑定一个账户。</div>  
            <el-form-item class="w-m-t-32" label="手机号:">
              <el-input v-model="formLabelAlign.mobile" :maxlength="11" onkeyup="value=value.replace(/[^\d]/g,'')" size="small" placeholder="请输入正确的手机号码"></el-input>
            </el-form-item>
            <el-form-item label="验证码:">
              <div class="mdx-flex">
                <div class="wzk-flex-1">
                  <el-input v-model="formLabelAlign.code" :maxlength="6" onkeyup="value=value.replace(/[^\d]/g,'')" size="small" placeholder="请输入验证码"></el-input>
                </div>
                <div class="form-sms">
                  <el-button type="text" @click.stop='sendSms'>{{ codetext }} </el-button>
                </div>
              </div>
            </el-form-item>
          </el-form>
        </div>
        <div class="dialog-bom mdx-flex mdx-row-center w-m-t-54">
			<el-button class="close" size="small" @click="alertPhoneShow = false">取 消</el-button>
           <el-button type="primary" size="small" @click='alertPhoneTap'>提交</el-button>
        </div>
      </div>
    </el-dialog>
    <el-dialog :visible.sync="setPswDialg" width="40%" center top='20vh'>
      <div class="w-font-13">
        <div class="dialog-title">设置支付密码</div>
        <div class="w-m-t-24">
          <el-form label-position="left" label-width="70px" :model="setPswForm">
			<div class="dialog-info">1、支付密码用于账户工程师首页、质保金提现二次安全验证；</div>
			<div class="dialog-info">2、密码规则：6-12位数字或字母组合</div>  
			<div class="pwd-tabs">
				<div class="left">
					<div class="yuan">1</div>
					<div class="pwd-title">安全手机验证</div>
				</div>
				<div class="xian"></div>
				<div class="left">
					<div class="yuan yuan2">2</div>
					<div class="pwd-title">设置支付密码</div>
				</div>
			</div>
            <el-form-item label="手机号:">
              <span>{{ setPswForm.mobile }}</span>
              <!-- <el-input v-model="setPswForm.mobile" :maxlength="11" onkeyup="value=value.replace(/[^\d]/g,'')" size="small"></el-input> -->
            </el-form-item>
            <el-form-item label="验证码:">
              <div class="mdx-flex">
                <div class="">
                  <el-input v-model="setPswForm.code" :maxlength="6" onkeyup="value=value.replace(/[^\d]/g,'')" size="small"></el-input>
                </div>
                <div class="form-sms">
                  <el-button type="text" @click.stop='sendSmsPsw'>{{ codetext2 }} </el-button>
                </div>
              </div>
            </el-form-item>
          </el-form>
        </div>
        <div class="dialog-bom mdx-flex mdx-row-center w-m-t-35">
			<el-button class="close" size="small" @click="setPswDialg = false">取 消</el-button>
          <el-button type="primary" size="small" @click='submitVerify'>验证</el-button>
        </div>
      </div>
    </el-dialog>
    <el-dialog :visible.sync="setPasswordPanle" width="40%" center top='20vh'>
      <div class="w-font-13">
      <div class="dialog-title">设置支付密码</div>
        <div class="w-m-t-24">
          <el-form label-position="right" label-width="130px" :model="passwordForm">
			<div class="dialog-info">1、支付密码用于账户工程师首页、质保金提现二次安全验证；</div>
			<div class="dialog-info">2、密码规则：6-12位数字或字母组合</div>  
			<div class="pwd-tabs">
				<div class="left">
					<div class="yuan yuan3">
						<img src="../../../static/images/center/ok@2x.png" />
					</div>
					<div class="pwd-title">安全手机验证</div>
				</div>
				<div class="xian"></div>
				<div class="left">
					<div class="yuan">2</div>
					<div class="pwd-title">设置支付密码</div>
				</div>
			</div>
            <el-form-item label="新支付密码：">
              <el-input v-model="passwordForm.password" type="password" :maxlength="12" size="small" :show-password="true" placeholder="*6-12位数字或字母"></el-input>
            </el-form-item>
            <el-form-item label="确认支付密码：">
              <el-input v-model="passwordForm.confPassword" type="password" placeholder="请再次输入支付密码" size="small" :show-password="true"></el-input>
            </el-form-item>
          </el-form>
        </div>
        <div class="dialog-bom mdx-flex mdx-row-center w-m-t-35">
			<el-button class="close" size="small" @click="setPasswordPanle = false">取 消</el-button>
          <el-button type="primary" size="small" @click='saveWithdrawalPsw'>保存</el-button>
        </div>
      </div>
    </el-dialog>
	
	<el-dialog :visible.sync="setPwdShow" width="40%" center top='20vh'>
	  <div class="w-font-13">
	    <div class="dialog-title">设置登录密码 </div>
	    <div class="w-m-t-24">
	      <el-form label-position="left" label-width="95px" :model="setPwdForm" ref="expPwd" :rules="rulesSetPwd">
			<div class="dialog-info">1、密码用于账号密码登录</div>  
			<div class="dialog-info">2、密码规则：6-12位数字或字母组合</div>  
	        <el-form-item class="w-m-t-32" label="手机号:" prop="mobile">
	          <el-input v-model="setPwdForm.mobile" :maxlength="11" onkeyup="value=value.replace(/[^\d]/g,'')" size="small" placeholder="请输入正确的手机号码" disabled></el-input>
	        </el-form-item>
			<el-form-item label="新密码：" prop="password">
			  <el-input v-model="setPwdForm.password" type="password" :maxlength="12" size="small" :show-password="true" placeholder="*6-12位数字或字母"></el-input>
			</el-form-item>
			<el-form-item label="确认密码：" prop="confPassword">
			  <el-input v-model="setPwdForm.confPassword" type="password" placeholder="请再次输入密码" size="small" :show-password="true"></el-input>
			</el-form-item>
	        <el-form-item label="验证码:" prop="code">
	          <div class="mdx-flex">
	            <div class="wzk-flex-1">
	              <el-input v-model="setPwdForm.code" :maxlength="6" onkeyup="value=value.replace(/[^\d]/g,'')" size="small" placeholder="请输入验证码"></el-input>
	            </div>
	            <div class="form-sms">
	              <el-button type="text" @click.stop='sendSmsPwd'>{{ codetext3 }} </el-button>
	            </div>
	          </div>
	        </el-form-item>
	      </el-form>
	    </div>
	    <div class="dialog-bom mdx-flex mdx-row-center w-m-t-54">
			<el-button class="close" size="small" @click="setPwdShow = false">取 消</el-button>
	       <el-button type="primary" size="small" @click='saveWithPwd'>提交</el-button>
	    </div>
	  </div>
	</el-dialog>
	
    <alertToast ref="alert"></alertToast>
  </div>
</template>

<script>
import alertToast from '../../../components/alertToast/index.vue'
import rulesData from '../../../api/rules.js'
import wxlogin from 'vue-wxlogin';
export default {
  components: {
    alertToast,
    wxlogin
  },
  computed: {
    userInfo() {
      return this.$store.getters['userInfo']
    }
  },
  data() {
    return {
      wxCode: false,
      alertPhoneShow: false,
      setPswDialg: false,
      setPasswordPanle: false,
      setPswForm: {
        mobile: '',
        code: ''
      },
      passwordForm: {
        password: "",
        confPassword: ""
      },
      formLabelAlign: {
        mobile: '',
        code: ''
      },
	  setPwdForm: {
		mobile: '',
		code: '',
	    password: "",
	    confPassword: ""
	  },
      codeSeconds: 0, // 验证码发送时间间隔
      codetext: '获取验证码',
      codeSeconds2: 0, // 验证码发送时间间隔
      codetext2: '获取验证码',
	  
	  codeSeconds3: 0, // 验证码发送时间间隔
	  codetext3: '获取验证码',
	  setPwdShow: false,
	  
	  rulesSetPwd: rulesData.rulesSetPwd,
	  
    }
  },
  created() {
    let data = this.$route.query
    if (data.code && data.state == 1) {
      //if (this.userInfo && this.userInfo.name == "龙庆圣") {}
      this.$http.post('user/bindWechat', {
        type: 1,
        pcCode: data.code
      }).then(res => {
        if (res.code == 200) {
          // this.$mHelper.to('/setting')
          this.$message.success('绑定成功')
          this.$uniApi.Logins(); //获取个人用户信息
          this.$router.push({
            query: {}
          });
        }
      }).catch(err => {
        this.$router.push({
          query: {}
        });
      })

    }
  },
  methods: {
    saveWithdrawalPsw() {
      console.log(this.passwordForm)
      if (this.passwordForm.password == "") {
        this.$message.error('请输入支付密码');
        return
      }
	  if (this.passwordForm.password.length < 6) {
	    this.$message.error('密码长度最低为6位');
	    return
	  }
	  
      if (this.passwordForm.confPassword == "") {
        this.$message.error('请再次输入支付密码');
        return
      }
      if (this.passwordForm.confPassword !== this.passwordForm.password) {
        this.$message.error('两次输入的密码不一致');
        return
      }
      this.$http.post('user/setPassword', {
        newPassword: this.passwordForm.password
      }).then(res => {
        if (res.code == 200) {
          this.setPasswordPanle = false;
          this.passwordForm.password = "";
          this.passwordForm.confPassword = "";
          this.$refs.alert.alertToast('支付密码设置成功！')
          this.$uniApi.Logins(); //获取个人用户信息
        }
      })
    },
	
	saveWithPwd(){
		this.$refs['expPwd'].validate((valid) => {
			if (valid) {
				if(this.setPwdForm.password.length < 6){
					this.$message.error('密码最低长度为6位！');
					return
				}
				
				if (this.setPwdForm.confPassword !== this.setPwdForm.password) {
				  this.$message.error('两次输入的密码不一致！');
				  return
				}
				this.$http.post('user/forgetPasswordUpdate', {
				    mobile: this.setPwdForm.mobile,
				    code: this.setPwdForm.code,
				    password: this.setPwdForm.password,
					source:2,  //用户来源(1=小程序，2=PC,3=泛华官网)
				}).then(res => {
				  if (res.code == 200) {
				    this.setPwdShow = false;
					this.setPwdForm.code = "";
				    this.setPwdForm.password = "";
				    this.setPwdForm.confPassword = "";
				    this.$refs.alert.alertToast('登录密码设置成功！')
				    this.$uniApi.Logins(); //获取个人用户信息
				  }
				})
			}
		})
		
		
	},
	clickPwdShow(){
		this.setPwdForm.mobile = this.userInfo.mobile;
		this.setPwdShow = true;
	},
	
    setWithdrawalPsw(type) {
      console.log(this.userInfo)
      console.log('设置支付密码');
      this.setPswForm.mobile = `${this.userInfo.mobile.slice(0, 3)}****${this.userInfo.mobile.slice(7)}`
      this.setPswDialg = true;
    },
    //打開微信tanchuan
    wxBindType() {
      this.wxCode = true;
    },
    //接触绑定
    cancelBind() {
      console.log('解绑微信'); //请求成功后弹窗
      const h = this.$createElement;
      this.$msgbox({
        title: '提示',
        message: h('p', {
          style: 'margin-bottom: 20px'
        }, [
          h('span', null, '是否要解除微信绑定？ '),
        ]),
        center: true,
        showCancelButton: true,
        confirmButtonText: '确认解绑',
        cancelButtonText: '我再想想',
        beforeClose: (action, instance, done) => {
          if (action === 'confirm') {
            instance.confirmButtonLoading = true;
            this.$http.post('user/bindWechat', {
              type: 2
            }).then(res => {
              if (res.code == 200) {
                done();
                setTimeout(() => {
                  instance.confirmButtonLoading = false;
                }, 300);
              }
            })
          } else {
            done();
          }
        }
      }).then(action => {
        this.$uniApi.Logins(); //获取个人用户信息
        this.$refs.alert.alertToast('您已成功解绑微信！', '提示', '#000')
      });
    },
    alertPhoneTap() {
      let checkSendCode = this.$mGraceChecker.check(this.formLabelAlign, this.$mFormRule.ptLogin);
      if (!checkSendCode) {
        this.$message.error(this.$mGraceChecker.error)
        return;
      }
      this.$http.post('/user/updateMobile', this.formLabelAlign).then(res => {
        if (res.code == 200) {
          this.alertPhoneShow = false
          this.$refs.alert.alertToast('您已成功修改手机号！')
          this.$uniApi.Logins(); //获取个人用户信息
        }
      })
    },
    //发送验证码
    sendSms() {
      if (this.codeSeconds > 0) {
        return;
      }
      let checkSendCode = this.$mGraceChecker.check(this.formLabelAlign, this.$mFormRule.sendCodeRule);
      if (!checkSendCode) {
        this.$message.error(this.$mGraceChecker.error)
        return;
      }
      this.$http.post('sms/sendCode', this.formLabelAlign).then(res => {
        if (res.code == 200) {
          this.$message.success('验证码发送成功，请注意查收')
          this.handleSmsCodeTime(59);
        }
      })
    },
    sendSmsPsw() {
      if (this.codeSeconds2 > 0) {
        return;
      }
      this.$http.post('sms/sendCode', {
        mobile: this.userInfo.mobile,
      }).then(res => {
        if (res.code == 200) {
          this.$message.success('验证码发送成功，请注意查收')
          this.handleSmsCodeTime2(59);
        }
      })
    },
	sendSmsPwd() {
	  if (this.codeSeconds3 > 0) {
	    return;
	  }
	  this.$http.post('sms/sendCode', {
	    mobile: this.userInfo.mobile,
	  }).then(res => {
	    if (res.code == 200) {
	      this.$message.success('验证码发送成功，请注意查收')
	      this.handleSmsCodeTime3(59);
	    }
	  })
	},
    submitVerify() {
      if (this.setPswForm.code == "") {
        this.$message.error('请输入短信验证码');
        return;
      }
      var submitData = {
        mobile: this.userInfo.mobile,
        code: this.setPswForm.code,
      }

      this.$http.get('user/phoneCheck', submitData).then(res => {
        if (res.code == 200) {
          this.setPswDialg = false;
          this.setPasswordPanle = true;
          this.setPswForm.code = ""
        }
      })
    },
    //验证码倒计时
    handleSmsCodeTime(time) {
      let timer = setInterval(() => {
        if (time <= 0) {
          clearInterval(timer);
          this.codeSeconds = 0;
          this.codetext = '再次发送';
        } else {
          this.codeSeconds = time;
          this.codetext = time;
          time--;
        }
      }, 1000);
    },
    handleSmsCodeTime2(time) {
      let timer = setInterval(() => {
        if (time <= 0) {
          clearInterval(timer);
          this.codeSeconds2 = 0;
          this.codetext2 = '再次发送';
        } else {
          this.codeSeconds2 = time;
          this.codetext2 = time;
          time--;
        }
      }, 1000);
    },
	handleSmsCodeTime3(time) {
	  let timer = setInterval(() => {
	    if (time <= 0) {
	      clearInterval(timer);
	      this.codeSeconds3 = 0;
	      this.codetext3 = '再次发送';
	    } else {
	      this.codeSeconds3 = time;
	      this.codetext3 = time;
	      time--;
	    }
	  }, 1000);
	},
	
	
  }
}
</script>

<style lang="scss" scoped>
.pages {
	width: 100%;
	padding: 0 0 0 45px;
	box-sizing: border-box;
	.list{
		.item{
			display: flex;
			justify-content: space-between;
			align-items: center;
			margin-top: 32px;
			.left{
				.title{
					font-family: PingFangSC, PingFang SC;
					font-weight: 600;
					font-size: 18px;
					color: rgba(0,0,0,0.85);
					line-height: 25px;
					text-align: left;
					font-style: normal;
					span{
						font-family: PingFangSC, PingFang SC;
						font-weight: 400;
						font-size: 16px;
						color: rgba(0,0,0,0.45);
						line-height: 22px;
						text-align: left;
						font-style: normal;
						margin-left: 16px;
					}
				}
				.info{
					font-family: PingFangSC, PingFang SC;
					font-weight: 400;
					font-size: 16px;
					color: rgba(0,0,0,0.85);
					line-height: 22px;
					text-align: left;
					font-style: normal;
					margin-top: 8px;
				}
			}
			.right{
				display: flex;
				align-items: center;
				img{
					width: 14px;
					height: 14px;
				}
				span{
					font-family: PingFangSC, PingFang SC;
					font-weight: 400;
					font-size: 16px;
					color: #4077DD;
					line-height: 22px;
					text-align: right;
					font-style: normal;
					margin-left: 12px;
				}
			}
		}
	}
}	
	
::v-deep .el-dialog{
	width: 700px !important;
	min-height: 367px;
	background: #FFFFFF;
	box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.1), 0px 0px 5px 0px rgba(0,0,0,0.14);
	border-radius: 10px;
	.el-dialog__headerbtn{
		top: 30px;
	}
	.el-dialog__body{
		padding:0 32px 32px;
		.dialog-title{
			font-family: PingFangSC, PingFang SC;
			font-weight: 500;
			font-size: 18px;
			color: rgba(0,0,0,0.85);
			line-height: 25px;
			text-align: left;
			font-style: normal;
		}
		.dialog-info{
			font-family: PingFangSC, PingFang SC;
			font-weight: 400;
			font-size: 14px;
			color: rgba(0,0,0,0.45);
			line-height: 20px;
			text-align: left;
			font-style: normal;
		}
		.el-form-item__label{
			font-family: PingFangSC, PingFang SC;
			font-weight: 400;
			font-size: 14px;
			color: rgba(0,0,0,0.85);
			font-style: normal;
		}
		.form-sms{
			margin-left: 24px;
			.el-button{
				width: 120px;
				height: 32px;
				background: #10C550;
				border-radius: 6px;
				font-family: PingFangSC, PingFang SC;
				font-weight: 400;
				font-size: 14px;
				color: #FFFFFF;
				line-height: 22px;
				font-style: normal;
				display: flex;
				justify-content: center;
				align-items: center;
			}
		}
		.dialog-bom{
			.el-button{
				width: 96px;
				height: 40px;
				background: #4077DD;
				border-radius: 8px;
				font-family: PingFangSC, PingFang SC;
				font-weight: 400;
				font-size: 16px;
				color: #FFFFFF;
				line-height: 24px;
				font-style: normal;
				display: flex;
				justify-content: center;
				align-items: center;
			}
			.close{
				background: #FFFFFF;
				border-radius: 6px;
				border: 1px solid #4077DD;
				color: #4077DD;
				margin-right: 14px;
			}
		}
		.pwd-tabs{
			display: flex;
			align-items: center;
			margin-top: 24px;
			margin-bottom: 26px;
			.left{
				display: flex;
				align-items: center;
				.yuan{
					width: 32px;
					height: 32px;
					background: #1677FF;
					border-radius: 16px;
					font-family: PingFangSC, PingFang SC;
					font-weight: 400;
					font-size: 14px;
					color: #FFFFFF;
					font-style: normal;
					display: flex;
					justify-content: center;
					align-items: center;
					img{
						width: 12px;
						height: 12px;
					}
				}
				.yuan2{
					background: rgba(0,0,0,0.06);
					color: rgba(0,0,0,0.45);
				}
				.yuan3{
					background: #E6F4FF;
				}
				.pwd-title{
					font-family: PingFangSC, PingFang SC;
					font-weight: 500;
					font-size: 16px;
					color: rgba(0,0,0,0.88);
					line-height: 24px;
					text-align: left;
					font-style: normal;
					margin-left: 8px;
				}
			}
			.xian{
				width: 240px;
				height: 1px;
				background: rgba(0,0,0,0.15);
				margin-left: 30px;
				margin-right: 30px;
			}
		}
	}
}
.dialog-wechat{
	::v-deep .el-dialog{
		width: 500px !important;
	}
}



</style>
