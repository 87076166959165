<template>
    <div class="project-box">
		<div class="boxCenter">
		  <div class="searchBox">
		    <el-input placeholder="地区/项目类型/专业/总投资/总规模/服务内容" placeholder-class="placeholderClass" v-model="searchText" @keyup.enter.native="searchTap">
		      <i slot="prefix" class="el-input__icon el-icon-search" style="color: #4077DD"></i>
		      <template slot="append">
		        <el-button type="primary" style="background-color: #4077DD" @click="searchTap">搜索</el-button>
		      </template>
		    </el-input>
		  </div>
		  <div class="sxxBox">
		    <el-form label-position="right" label-width="80px" :model="formLabelAlign">
		      <div class="mingHeind">
		        <el-form-item label="专业" class="mingHeind">
		          <template>
		            <div class="" style="display: flex">
		              <el-checkbox :indeterminate="isIndeterminate" v-model="specCheckAll" @change="speckAllChange">全选</el-checkbox>
		              <div style="width: 20px"></div>
		              <div style="flex: 1">
		                <el-checkbox-group v-model="specCheck" @change="speckChange">
		                  <el-checkbox v-for="(item, index) in majorList" :label="item" :key="index + 'b'">
		                    {{ item.name }}
		                  </el-checkbox>
		                </el-checkbox-group>
		              </div>
		            </div>
		          </template>
		        </el-form-item>
		        <el-form-item label="地区" class="mingHeind">
		          <template>
		            <template>
		              <div class="" style="display: flex">
		                <el-checkbox
		                  :indeterminate="isIndeterminate2"
		                  v-model="addCheckAll"
		                  @change="addAllChange"
		                  >全选
		                </el-checkbox>
		                <div style="width: 20px"></div>
		                <div style="flex: 1">
		                  <el-checkbox-group v-model="addCheck" @change="addChange">
		                    <el-checkbox
		                      v-for="(item, index) in addList"
		                      :label="item"
		                      :key="index + 'v'"
		                    >
		                      {{ item.cityName }}
		                    </el-checkbox>
		                  </el-checkbox-group>
		                </div>
		              </div>
		            </template>
		          </template>
		        </el-form-item>
		        <el-form-item label="服务费" class="mingHeind">
		          <template>
		            <template>
		              <div class="" style="display: flex">
		                <div style="flex: 1" class="box">
		                  <el-radio-group v-model="radio" @change="sevChange">
		                    <el-radio
		                      class="radio"
		                      v-for="(item, index) in sevList"
		                      :label="item"
		                      :key="index + 'l'"
		                      >{{ item.money }}</el-radio
		                    >
		                    <el-input
		                      v-model="start"
		                      style="display: inline; margin-left: 5px"
		                      @blur="startChange"
		                    ></el-input>
		                    <span style="margin-left: 5px">-</span>
		                    <el-input
		                      v-model="end"
		                      style="display: inline; margin-left: 10px"
		                      @blur="endChange"
		                    ></el-input>
		                  </el-radio-group>
		                </div>
		              </div>
		            </template>
		          </template>
		        </el-form-item>
		
		        <el-form-item label="交付时间" class="mingHeind">
		          <template>
		            <template>
		              <div class="" style="display: flex">
		                <div style="flex: 1">
		                  <el-radio-group v-model="timeCheck" @change="timeChange">
		                    <el-radio
		                      v-for="(item, index) in timeList"
		                      :label="item.id"
		                      :key="index + 'o'"
		                      >{{ item.time }}</el-radio
		                    >
		                  </el-radio-group>
		                </div>
		              </div>
		            </template>
		          </template>
		        </el-form-item>
				<el-form-item label="发布时间" class="mingHeind">
				  <template>
				    <template>
				      <div class="" style="display: flex">
				        <div style="flex: 1">
				          <el-radio-group v-model="timeXmCheck" @change="timeXmChange">
							<el-radio label="" key="1" >全部</el-radio>
				            <el-radio label="1" key="2" >近一年</el-radio>
							<el-radio label="2" key="3" >近两年</el-radio>
				          </el-radio-group>
				        </div>
				      </div>
				    </template>
				  </template>
				</el-form-item>
		      </div>
		    </el-form>
		  </div>
		  <div class="listbox">
			  <div class="list-top">
				  <div class="left">
					  <div class="left-item" :class="sortRule==0?'active-left':''" @click="activeRule(0)">智能排序</div>
					  <div class="left-item" :class="sortRule==1?'active-left':''" @click="activeRule(1)">最新优先</div>
					  <div class="left-item" :class="sortRule==2?'active-left':''" @click="activeRule(2)" v-if="userInfo.identity==3">收益优先</div>
					  <div class="left-item" :class="sortRule==3?'active-left':''" @click="activeRule(3)">规模优先</div>
				  </div>
				  <div class="right">
					  共<span>{{totalCount || '0'}}</span>个项目
				  </div>
			  </div>
			  <div class="list-content">
				  <div class="listLines list1 wzk-cursor" v-for="(item, index) in list" :key="index" @click="toDetails(item)">
				     <div class="left">
						<div class="names line2">
							<span v-if="item.status == 3" class="proj-state on-sign-up">报名中</span>
							<span v-if="item.status == 4" class="proj-state on-conduct">项目进行中</span>
							<span v-if="item.status == 5" class="proj-state on-success" >圆满完成</span>
							<span v-if="item.status == 9" class="topState on-completed">已取消</span>
							<span class="proj-state" style="color: #3B77FF; border:1px solid #3B77FF; padding: 3.67px 4px;" v-if="item.fbMid == userInfo.id">我的</span>
							<span>
								{{ item.regionFy }}/{{ item.typeFy }}
								<template>
									<span v-for="(row, index) in item.majorFy" :key="index + 'q'" >/{{ row.name }}</span>
								</template>
								<span> /总投资:￥{{ item.totalInvestment || "00" }} </span>
								<span> /总规模:{{ item.totalScale }}m² </span>
								/{{ item.serviceContentFy }}
							</span>
						</div>
						<div class="tagsBox">
						  <el-tag type="info" class="tags">{{ item.regionFy }}</el-tag>
						  <el-tag type="info" class="tags" v-if="item.necessary == 1">需要出现场</el-tag>
						  <el-tag type="info" class="tags" v-if="item.provided == 1" >提供CAD图纸</el-tag>
						  <el-tag type="info" class="tags">{{ item.typeFy }}</el-tag>
						  <template>
						    <el-tag type="info" class="tags" v-for="(row, index) in item.majorFy" :key="index + 'w'" >{{ row.name }}</el-tag>
						  </template>
						  <el-tag type="info" class="tags" v-if="item.serviceContentFy">{{item.serviceContentFy}}</el-tag>
						  <template>
						    <el-tag type="info" class="tags" v-for="(row, index) in item.softwareFy" :key="index + 'e'" >{{ row.label }}</el-tag>
						  </template>
						</div>
						
						<div class="time-box">
							<div class="times" v-if="userInfo.identity==3">创建时间：{{ item.createTime || '0000-00-00 00:00:00'}}</div>
							<div class="times">交付时间：{{ item.deliveryDeadline || '0000-00-00 00:00:00'}}</div>
							<div class="name-time" v-if="item.status==3">{{ limitTimeFilter(item.fbTime,item.bmTime) }}</div>
						</div>
					 </div>
					 <div class="right">
						 <div class="priceOrStatus">
						    <div class="right-title">总规模</div>
							<div class="prices">
								{{ item.totalScale  || '0'}}m²
							</div>
						 </div>
						 <div class="priceOrStatus" style="margin-left: 71px;" v-if="userInfo.identity==3 || userInfo.identity==2 && changeType==2">
						    <div class="right-title">工程师收益</div>
							<div class="prices">
								 <span>{{item.earningsAmount || '0'}}元</span>
							</div>
						 </div>
						 <div class="priceOrStatus" style="margin-left: 71px;" v-else>
						    <div class="right-title">总投资</div>
								<div class="prices">
									<span>{{ item.totalInvestment || '0'}}元</span>
								</div>
						 </div>
					 </div>
				  </div>
			  </div>
		    
		    <div class="mdx-flex mdx-row-center queryParamss" style="margin-top: 30px" v-if="list.length">
		      <el-pagination
		        :pageSize="queryParams.limit"
		        :currentPage="queryParams.page"
		        background
		        layout="prev, pager, next"
		        @current-change="pageChange"
		        :page-count="queryParams.totalPage || 1"
		      >
		      </el-pagination>
		    </div>
		  </div>
		</div>
	</div>
</template>

<script>
import comData from "../../common/commonData.js";
import addOptions from "../../api/area.js";
import { getCustomType, initNumberValue } from "@/common/utils/num-validate.js";
export default {
  data() {
    return {
	  sortRule: 0,
      searchText: "",
      formLabelAlign: {},
      start: "",
      end: "",
      specCheck: [],
      specCheckAll: false,
      majorList: [],
      //
      addCheck: [],
      addCheckAll: [],
      addList: [],

      list: [],
      queryParams: {
        page: 1, //当前页数
        limit: 10, //每页条数
        totalPage: 1,
      },
      isIndeterminate: false,
      isIndeterminate2: false,
      //服务费
      sevVal: "",
      radio: 0,
      sevList: [
        {
          cost: "全选",
          money: "全选",
          id: 1,
        },
        {
          cost: "0-1000",
          money: "0元-1000元",
          id: 2,
        },
        {
          cost: "1000-2000",
          money: "1000元-2000元",
          id: 3,
        },
        {
          cost: "2000-4000",
          money: "2000元-4000元",
          id: 4,
        },
        {
          cost: "4000-6000",
          money: "4000元-6000元",
          id: 5,
        },
        {
          cost: "6000",
          money: "6000元以上",
          id: 6,
        },
        {
          cost: "",
          money: "自定义",
          id: 7,
        },
      ],
      //交付时间
      timeId: "",
      timeCheck: 6,
      timeList: [
        {
          id: 0,
          time: "全选",
        },
        {
          id: 1,
          time: "7天内",
        },
        {
          id: 2,
          time: "7-21天",
        },
        {
          id: 3,
          time: "21-60天",
        },
        {
          id: 4,
          time: "60天以上",
        },
		// {
		//   id: 6,
		//   time: "近一年",
		// },
		// {
		//   id: 5,
		//   time: "一年以上",
		// },
      ],
      ischeck: "",
	  totalCount: 0,
	  timeXmCheck: '1',
	  changeType: 2,
    };
  },
  computed: {
    userInfo() {
      return this.$store.getters['userInfo']
    },
  
  },
  created() {
	  this.changeType = localStorage.getItem('changeType');
	  
    this.$http.get("region/list").then((res) => {
      if (res.code == 200) {
        this.addList = res.data;
      } else {
        this.$message.error("地址获取失败");
      }
    });
    //专业类型获取
    this.$http.get("dict_data/getMajors").then((res) => {
      if (res.code == 200) {
        this.majorList = res.data;
      } else {
        this.$message.error("专业获取失败");
      }
    });
    this.getList();
    this.setKeyWords();
	
  },
  methods: {
	//报名有效期
	limitTimeFilter(fbTime,val) {
		if(val == null || fbTime == null){
			val = 0;
			fbTime = 0;
		}
		let time = new Date().getTime();
		fbTime = Date.parse(fbTime);
		
		val =  ( fbTime + val*60*60*1000 ) - time;
		if(val=='' || val < 0){
			val = 0;
		}
		if (val <= 0) {
			return "";
		} else {
			var days = Math.floor(val / (1000 * 60 * 60 * 24));
			var lefth = Math.floor((val / (1000 * 60 * 60)) % 24);
			var leftm = Math.floor((val / (1000 * 60)) % 60);
			var lefts = Math.floor((val / 1000) % 60);
			return "报名还剩"+ days + "天" +lefth + "时" + leftm + "分";
			//return "报名还剩"+ days + "天" +lefth + "时" + leftm + "分" + lefts + "秒";
		}
	},
	activeRule(e){
		if(this.sortRule!=e){
			this.sortRule = e;
			this.list = [];
			this.queryParams.page = 1;
			this.getList();
		}
	},
    searchTap() {
      this.queryParams.page = 1;
      this.getList();
    },
    //
    getList() {
      const loading = this.$loading({
        lock: true,
        text: "加载中...",
        spinner: "el-icon-loading",
        background: "rgba(255, 255, 255, 0.8)",
      });
      let major = [];
      let region = [];
      this.specCheck.forEach((e) => {
        major.push(e.id);
      });
	  
      this.addCheck.forEach((e) => {
        region.push(e.code);
      });
      let start = null;
      let end = null;
      if (this.sevVal != "") {
        start = this.sevVal.split("-")[0];
        end = this.sevVal.split("-")[1];
      }
      this.$http.post("project/list", {
          pageNum: this.queryParams.page,
          pageSize: this.queryParams.limit,
          major: major,
          region: region,
          qname: this.searchText,
          dateType: this.timeId,
          incomeStart: start,
          incomeEnd: end,
		  sortRule: this.sortRule,
		  cycle :this.timeXmCheck,
        }).then((res) => {
          if (res.code == 200) {
            this.queryParams.totalPage = res.data.pages;
            res.data.list.forEach(function (item) {
              item.typeAB = getCustomType(item.type);
              item.totalInvestment = initNumberValue(item.totalInvestment); //总投资(元)
              item.totalScale = initNumberValue(item.totalScale); //总规模（m2）
            });
            this.list = res.data.list;
			this.totalCount = res.data.total;
          } else {
            this.list = [];
			this.totalCount = 0;
          }
          loading.close();
        })
        .catch((err) => {
          loading.close();
        });
    },
    pageChange(e) {
      this.queryParams.page = e;
      this.getList();
    },
    toDetails(item) {
		if(item.fbMid == this.userInfo.id){
			if(item.isEvaluateProject==1 || item.isEvaluateUser==1 ){
				this.$mHelper.to("/myPublishProjectDetail?id=" + item.id + "&mrate=" + item.mangerEvaluate + "&urate=" + item.userEvaluate);
			}else{
				this.$mHelper.to("/myPublishProjectDetail?id=" + item.id);
			}
		}else{
			this.$mHelper.to("/hallDetail?id=" + item.id);
		}
    },
    //专业
    speckAllChange(val) {
      this.specCheck = val ? this.majorList : [];
      this.isIndeterminate = false;
      this.queryParams.page = 1;
      this.getList();
    },
    speckChange(value) {
      this.queryParams.page = 1;
      let checkedCount = value.length;
      this.specCheckAll = checkedCount === this.majorList.length;
      this.isIndeterminate =
        checkedCount > 0 && checkedCount < this.majorList.length;
      this.getList();
    },
    //地区
    addAllChange(val) {
      this.addCheck = val ? this.addList : [];
      this.isIndeterminate2 = false;
      this.queryParams.page = 1;
      this.getList();
    },
    addChange(value) {
      this.queryParams.page = 1;
      let checkedCount = value.length;
      this.specCheckAll = checkedCount === this.addList.length;
      this.isIndeterminate2 =
        checkedCount > 0 && checkedCount < this.addList.length;
      this.getList();
    },
    //服务费
    sevChange(value) {
      this.ischeck = value;
      if (value.id != 7) {
        this.start = "";
        this.end = "";
      }
      if (value.id != 1) {
        this.sevVal = value.cost;
      } else {
        this.sevVal = "";
      }
      if (value.id == 7 && this.start != "" && this.end != "") {
        value.cost = this.start + "-" + this.end;
        this.sevVal = value.cost;
      } else if (value.id == 7 && this.start == "" && this.end == "") {
        return;
      }
      this.queryParams.page = 1;
      this.getList();
    },
    //交付时间
    timeChange(value) {
      if (value != 0) {
        this.timeId = value;
      } else {
        this.timeId = null;
      }
      this.queryParams.page = 1;
      this.getList();
    },
	timeXmChange(){
	   if(this.timeXmCheck == 0){
		   this.timeXmCheck = '';
	   }
	   this.queryParams.page = 1;
	   this.getList();
	},
    setKeyWords() {
      let element = document.getElementsByTagName("meta");
      let elementArr = Array.from(element);
      let keyWordMeta = elementArr.find((item) => item.name == "keywords");
      if (keyWordMeta) {
        keyWordMeta.content =
          "中一鼎合项目,中一鼎合做的项目有哪些,房建土建,钢结构,精装修,幕墙,房建安装,市政土建,市政管网,市政电气,市政智能化,工程量计算,编清单,组价,概算,住宅,公寓,别墅,学校,医疗建筑,宾馆酒店,交通枢纽,场馆,商业,办公,厂房,物流仓储,地下室,污水处理厂,自来水厂,变电站,加油站,热力站,公交场站,垃圾站,公厕,市政工程,广联达,斯维尔,鲁班,品茗,宏业,新点,一点智慧,智多星,算王,晨曦,广龙,易达,博奥,福莱,造价项目";
      }
    },
    startChange() {
      if (this.ischeck.id == 7) {
        this.sevVal = this.start + "-" + this.end;
        this.getList();
      }
    },
    endChange() {
      if (this.ischeck.id == 7) {
        this.sevVal = this.start + "-" + this.end;
        this.getList();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.project-box{
	width: 100%;
	height: 100%;
	background-image: url('../..//static/images/project_bg@2x.jpg');
	background-repeat: no-repeat;
	background-size: 100% 900px;
	.searchBox {
		width: 100%;
		display: flex;
		justify-content: center;
		padding-top: 77px;
		::v-deep .el-input__inner {
			color: #4077DD;
			border: 1px solid #4077DD;
		}
		::v-deep .el-input-group__append {
			border: 1px solid #4077DD;
			background-color: #4077DD;
		}
		::v-deep ::-webkit-input-placeholder {
			color: #BFBFBF;
		}
		::v-deep ::-moz-input-placeholder {
			color: #BFBFBF;
		}
		::v-deep ::-ms-input-placeholder {
			color: #BFBFBF;
		}
	}
	
	.sxxBox {
	    min-height: 332px;
	    background-color: #FFFFFF;
	    box-sizing: border-box;
	    box-shadow: 0px 6px 10px 6px rgba(44,46,51,0.03), 6px 6px 20px 3px rgba(44,46,51,0.06);
	    border-radius: 8px;
	    margin-top: 48px;
	    padding-top: 20px;
	    padding-bottom: 1px;
	}
	
}	
	
	
.box {
  ::v-deep .el-input__inner {
    width: 50px;
    height: 26px;
    padding: 0 6px;
  }
  .el-radio-group {
    font-size: 15px;
    ::v-deep .el-radio {
      margin-right: 0 !important;
      margin-left: 30px;
    }
    ::v-deep .el-radio:first-child {
      margin-left: 0;
    }
  }
}
.listbox {
  background-color: #fff;
  padding-bottom: 30px;
  margin-bottom: 30px;
  margin-top: 24px;
  min-height: 302px;
  box-shadow: 0px 6px 10px 6px rgba(44,46,51,0.03), 6px 6px 20px 3px rgba(44,46,51,0.06);
  border-radius: 8px;
	.list-top{
		width: calc(100% - 48px);
		height: 69px;
		border-bottom: 1px solid #E8E8E8;
		padding: 0 24px;
		display: flex;
		justify-content: space-between;
		align-items: center;
	   .left{
		   display: flex;
		   justify-content: flex-start;
		   align-items: center;
		   .left-item{
			   font-family: PingFangSC, PingFang SC;
			   font-weight: 400;
			   font-size: 16px;
			   color: rgba(0,0,0,0.85);
			   line-height: 22px;
			   text-align: left;
			   font-style: normal;
			   margin-right: 24px;
			   cursor: pointer;
		   }
		   .active-left{
			   font-weight: 600;
			   color: rgba(0,0,0,0.85);
		   }
	   }
	   .right{
		   font-family: PingFangSC, PingFang SC;
		   font-weight: 400;
		   font-size: 16px;
		   color: rgba(0,0,0,0.85);
		   line-height: 22px;
		   text-align: left;
		   font-style: normal;
		   span{
			   color: #4077DD;
			   padding: 0 6px;
		   }
	   }
	}
	.list-content{
		padding: 0 24px;
		.listLines {
			border-bottom: 1px solid #ddd;
			background-color: #fff;
			// margin-top: 20px;
			background-color: #fff;
			padding: 20px 0;
			box-sizing: border-box;
			display: flex;
			justify-content: space-between;
			align-items: center;
			.left{
				width: calc(100% - 400px);
				.names {
				    font-family: PingFangSC, PingFang SC;
				    font-weight: 500;
				    font-size: 16px;
				    color: rgba(0,0,0,0.85);
				    line-height: 21px;
				    text-align: left;
				    font-style: normal;
					
					.proj-state {
						  margin-right: 8px;
						  font-family: PingFangSC, PingFang SC;
						  font-weight: 500;
						  font-size: 10px;
						  color: #FFFFFF;
						  line-height: 38px;
						  text-align: center;
						  font-style: normal;
						  border-radius: 4px;
						  padding: 4.34px 4px;
						  position: relative;
						  top: -1.5px;
					}
					.on-sign-up {
						 background: #1677FF;
					}
					.on-conduct {
						  background: #FFC726;
					}
					.on-completed {
						  background: #E8E8E8;
					}
					.on-success{
						background: #10c550;
					}
				}
				.time-box{
					margin-top: 15px;
					display: flex;
					align-items: center;
					.name-time{
						font-family: PingFangSC, PingFang SC;
						font-weight: 400;
						font-size: 12px;
						color: #FF4D4F;
						line-height: 21px;
						text-align: left;
						font-style: normal;
					}
					.times {
					    font-family: PingFangSC, PingFang SC;
					    font-weight: 400;
					    font-size: 14px;
					    color: rgba(0,0,0,0.85);
					    line-height: 21px;
					    font-style: normal;
						margin-right: 24px;
					}
				}
				
				.tagsBox{
					//margin-top: 13px;
					.tags {
					    background: #F5F7FA;
						border-radius: 4px;
					    margin-right: 8px;
					    height: 22px;
					    line-height: 22px;
						font-family: PingFangSC, PingFang SC;
						font-weight: 400;
						font-size: 12px;
						color: #555C70;
						line-height: 20px;
						font-style: normal;
						margin-top: 10px;
					}
				}
				
			}
			.right{
				display: flex;
				.priceOrStatus {
					.right-title{
						font-family: PingFangSC, PingFang SC;
						font-weight: 400;
						font-size: 14px;
						color: rgba(0,0,0,0.45);
						line-height: 36px;
						text-align: right;
						font-style: normal;
					}
					.prices {
						font-family: PingFangSC, PingFang SC;
						font-size: 18px;
						font-weight: 400;
						color: #4077DD;
						line-height: 36px;
					}
				}
			}
		
		}
	}
	
}


.mingHeind {
  ::v-deep .el-form-item__content {
    line-height: 20px;
  }

  ::v-deep .el-form-item__label {
    line-height: 20px;
  }
 //  ::v-deep .el-checkbox__label{
	//   font-family: PingFangSC, PingFang SC;
	// font-weight: 400;
	// font-size: 16px;
	// color: rgba(0,0,0,0.85);
	// line-height: 18px;
	// text-align: left;
	// font-style: normal;
 //  }
}




</style>
