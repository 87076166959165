import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    zoneId: 1, //专区id
    token: localStorage.getItem('token') ? localStorage.getItem('token') : null,
    userInfo: {},
    // baseURL: process.env.NODE_ENV === "development" ? '/api' : 'https://jcyladmin.e-mall365.com/prod-api/api/',
    // downloadUrl: 'https://jcyladmin.e-mall365.com/dev-api/',
    // baseURL: process.env.NODE_ENV === "development" ? '/api' : 'http://62.234.119.43/prod-api/api/',
    baseURL: process.env.VUE_APP_BASE_API,
    //callback:process.env.VUE_APP_BASE,
    //baseURL:'http://web.zhongyidinghe.cn/prod-api/api/',
    callback: 'https://www.zhongyidinghe.cn', //回调地址 http://zbpc.kunchuangtech.net
    cartCount: 0,
    msgNum: 0,
    contrastList: [],
    commonData: {}
    
  },
  getters: {
    token: state => state.token,
    zoneId: state => state.zoneId,
    userInfo: state => state.userInfo,
    commonData: state => state.commonData,
    contrastList: state => state.contrastList,
    msgNum: state => state.msgNum,
  },
  mutations: {
    changeLogin(state, {
      token,
      userInfo
    }) {
      state.token = token;
      state.userInfo = userInfo;
      localStorage.setItem('token', token);
    },
    saveUser(state, {
      userInfo
    }) {
      state.userInfo = userInfo;
      localStorage.setItem('userInfo', JSON.stringify(userInfo));
    },
    outLogin(state) {
      state.token = '';
      state.userInfo = {};
      localStorage.removeItem('token');
    },
    saveCommonData(state, data) {
      state.commonData = data;
    }
  },
  actions: {},
  modules: {}
})
