<template>
  <div class="boxCenter">
    <div class="topNavs">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item>消息通知</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="conTBVox">
      <el-menu :default-active="activeIndex" class="el-menu-demo" mode="horizontal" @select="handleSelect">
        <el-menu-item :index="2">
          <span>
            项目通知（{{ projectNum || 0 }}）
          </span>
        </el-menu-item>
        <el-menu-item :index="1"><span>
            系统通知（{{ xtNum || 0 }}）
          </span></el-menu-item>
          <div  style="display:  flex;  justify-content:  flex-end; margin-top:  10px;">
              <el-button  type="primary"   icon="el-icon-document-checked"  @click="lookAll(activeIndex)">全部已读</el-button>
          </div>
      </el-menu>

      <!-- <div class="listbox" v-for="(item, index) in list" :key='index'
        @click="$mHelper.to('/informationDetail?types=1&id=' + item.id )"> -->

        <div class="listbox" v-for="(item, index) in list" :key='index'
        @click="$mHelper.to(`/informationDetail?types=1&id=${item.id}&processType=${item.processType}`)">


        <div class="mdx-flex mdx-row-between mdx-relative">
          <div class="titles wzk-flex-1">
            <!-- {{item.title||''}} -->
            <template v-if="item.isRead == 2">
              <el-badge is-dot class="item">{{ item.title || '' }}</el-badge>
            </template>
            <template v-else>
              {{ item.title || '' }}
            </template>
          </div>
          <div style="color: #828282; font-size: 14px;margin-left: 50px;">
            {{ item.createTime || '' }}
          </div>
        </div>
        <div class="content">
          {{ item.content || '' }}
        </div>
      </div>
    </div>
    <div class="mdx-flex mdx-row-center " style="margin-top: 30px; margin-bottom: 30px;" v-if="list.length">
      <el-pagination :pageSize="queryParams.limit" :currentPage='queryParams.page' background layout="prev, pager, next"
        @current-change='pageChange' :page-count="queryParams.totalPage || 1">
      </el-pagination>
    </div>
  </div>
</template>

<script>
import { removeHtmlTag, isArrObj } from "@/common/utils/common.js"
export default {
  data() {
    return {
      activeIndex: 2,
      list: [{}],
      queryParams: {
        page: 1, //当前页数
        limit: 10, //每页条数
        totalPage: 1
      },
      projectNum: 0,
      xtNum: 0
    }
  },
  created() {
    this.getList();
    this.getCount();
  },
  beforeDestroy() { //页面关闭时清除定时器
    this.getCount();
  },
  methods: {
    handleSelect(e) {
      this.activeIndex = e;
      this.queryParams.page = 1
      this.list = []
      this.getList();
    },
    pageChange(e) {
      this.queryParams.page = e
      this.getList();
    },
    lookAll(){
        this.$http.get('notice/lookAll', {
                type: this.activeIndex,}).then(res => {
                    console.log(res)

        })
        this.handleSelect(this.activeIndex)
      },
    getList() {
      this.$http.get('notice/getNotice', {
        type: this.activeIndex,
        pageNum: this.queryParams.page,
        pageSize: this.queryParams.limit
      }).then(res => {
        if (res.code == 200) {
          console.log(1,res.data.list);
          this.list = [];
          this.queryParams.totalPage = res.data.pages;
          if (res.data && isArrObj(res.data.list)) {
            res.data.list.forEach(item => {
              item.content = removeHtmlTag(item.content);
              this.list.push(item)
            });
          }
          //this.list = res.data.list
        } else {
          this.list = []
        }
      })
    },
    getCount() {
      this.$http.get('notice/wdNum',).then(res => {
        if (res.code == 200) {

          this.projectNum = res.data.projectNum
          this.xtNum = res.data.xtNum
          this.$store.state.msgNum = parseInt(res.data.projectNum) + parseInt(res.data.xtNum)
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.listbox {
  border-bottom: 1px solid #ddd;
  padding: 20px 0;
  margin: 0 20px;

  .titles {
    font-weight: 550;
  }

  .content {
    font-size: 14px;
    margin-top: 20px;
  }
}

.conTBVox {
  border-radius: 5px;
  padding: 20px;
  padding-top: 0;
  box-sizing: border-box;
  background-color: #FFFFFF;
}

::v-deep .el-menu {
  background-color: #FFFFFF;
  border: none;
}

::v-deep .el-menu-item:hover {
  background-color: #FFFFFF !important;
}

::v-deep .el-menu-item * {
  font-size: 18px;
}

::v-deep .el-menu-item.is-active {
  background-color: #FFFFFF !important;
  color: #000;
  font-weight: 550;

  span {
    // color: #fff !important;
  }
}
</style>
