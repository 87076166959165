<template>
  <div class="boxCenter">
	<div class="tits">
	  	<div class="tits-title">注册工程师</div>
		<div class="tits-info">请尽可能的完整、正确的进行项目资料的填写。</div>
	  </div>
    <el-form label-position="right" label-width="300px" :model="formData" :rules="engineerRule" ref="gcsRef">
      <div class="mingHeind">
		  <div class="lines-title">
		  	<div class="lines-left"></div>
		  	<span>专业信息</span>
		  	<div class="hengxian"></div>
		  </div>
        <el-form-item label="项目类型" class="mingHeind" prop="type">
          <el-checkbox-group v-model.trim="formData.type" :max="10" @change="changeGoodAt">
            <el-checkbox :label="item.dictValue" v-for="(item, index) in checkList" :key='index'>
              {{ item.dictLabel }}
            </el-checkbox>
          </el-checkbox-group>
          <p v-show="chooseTip1.show" style="color: #ff8533;">{{ chooseTip1.text }}</p>
        </el-form-item>
        <el-form-item label="擅长软件" prop="software">
          <el-checkbox-group v-model.trim="formData.software" :max="5" @change="changeGoodSoftware">
            <el-checkbox :label="item.dictValue" v-for="(item, index) in softwareList" :key='index'>
              {{ item.dictLabel }}
            </el-checkbox>
          </el-checkbox-group>
          <p v-show="chooseTip2.show" style="color: #ff8533;">{{ chooseTip2.text }}</p>
        </el-form-item>
        <el-form-item label="擅长地区" prop="region">
          <template>
            <el-checkbox-group v-model.trim="formData.region" :max="8" @change="changeGoodArea">
              <el-checkbox :label="item.code" v-for="(item, index) in addList" :key='index'>{{ item.cityName }} </el-checkbox>
            </el-checkbox-group>
          </template>
          <p v-show="chooseTip3.show" style="color: #ff8533;">{{ chooseTip3.text }}</p>
        </el-form-item>
        <el-form-item label="擅长专业" prop="major">
          <template>
            <el-checkbox-group v-model.trim="formData.major" :max="5" @change="changeGoodSpecial">
              <el-checkbox :label="item.id" v-for="(item, index) in majorList" :key='index' @change="majorChange($event, item)">{{ item.name }}</el-checkbox>
            </el-checkbox-group>
            <p v-show="chooseTip4.show" style="color: #ff8533;">{{ chooseTip4.text }}</p>
            <p class="tishiTitle">
              {{ majorListTetx }}
            </p>
          </template>
        </el-form-item>
        <!-- <el-form-item label="是否可以出现场" prop="necessary">
          <template>
            <el-radio v-model.trim="formData.necessary" :label="1">是</el-radio>
            <el-radio v-model.trim="formData.necessary" :label="2">否</el-radio>
          </template>
        </el-form-item>
        <el-form-item label="有无团队">
          <template>
            <el-radio v-model.trim="formData.team" :label="1">有</el-radio>
            <el-radio v-model.trim="formData.team" :label="2">无</el-radio>
          </template>
        </el-form-item> -->
      </div>
      <el-form-item label="工程造价从业年限" prop="years">
        <div class="inputWidth mdx-flex">
          <el-input type="text" onkeyup="value=value.replace(/[^\d]/g,'')" v-model.trim="formData.years" :maxlength="4" placeholder="请输入您的从业年限"></el-input>
          <span class="dabwue">年</span>
        </div>
      </el-form-item>
      <el-form-item label="执业资格">
        <div class="inputWidth">
          <el-select v-model.trim="formData.practiceQua" placeholder="请选择">
            <el-option :label="item.dictLabel" v-for="(item, index) in zyTypeList" :key='index' :value="item.dictValue"></el-option>
          </el-select>
        </div>
      </el-form-item>
	  <div class="lines-title" v-if="certification != 1">
	  	<div class="lines-left"></div>
	  	<span>实名认证</span>
	  	<div class="hengxian"></div>
	  </div>
      <el-form-item label="姓名" prop="name" v-if="certification != 1">
        <div class="inputWidth">
          <el-input type="text" :disabled="true" :maxlength="12" v-model.trim="formData.name" placeholder="上传身份证照片后 自动识别姓名"></el-input>
        </div>
      </el-form-item>
      <el-form-item label="身份证号码" prop="idCard" v-if="certification != 1">
        <div class="inputWidth">
          <el-input type="text" :disabled="true" :maxlength="18" v-model.trim="formData.idCard" placeholder="上传身份证照片后 自动识别身份证号码"></el-input>
        </div>
      </el-form-item>
      <el-form-item label="上传身份证照片" prop="portrait" v-if="certification != 1">
        <div class="cardBox mdx-flex">
          <div class="cards">
            <el-upload class="avatar-uploader" :action='action' :headers='headers' :data="{ type: 1 }" 
			:show-file-list="false" :on-success="(response, file, fileList) => handleAvatarSuccess(response, 1)" accept="image/jpg,image/jpeg,image/png" :before-upload="beforeAvatarUpload">
              <img v-if="formData.portrait" :src="formData.portrait" class="avatar">
              <img v-else src="../../static/images/cardZheng.png" class="avatar">
            </el-upload>
          </div>
          <div class="cards">
            <el-upload class="avatar-uploader" :action='action' :headers='headers' :data="{ type: 2 }" 
			:show-file-list="false" :on-success="(response, file, fileList) => handleAvatarSuccess(response, 2)" accept="image/jpg,image/jpeg,image/png" :before-upload="beforeAvatarUpload">
              <img v-if="formData.surface" :src="formData.surface" class="avatar">
              <img v-else src="../../static/images/cardFan.png" class="avatar">
            </el-upload>
          </div>
        </div>
      </el-form-item>
	  <div class="lines-title">
	  	<div class="lines-left"></div>
	  	<span>个人介绍</span>
		<div class="add-title cursor" @click="updataIntro">
			<img src="../../static/images/edit@2x.png" />
			<span>添加个人介绍</span>
		</div>
	  	<div class="hengxian hengxian2"></div>
	  </div>
      <div class="jieshao">
        {{ this.formData.introduction || '请添加个人介绍' }}
      </div>
      
	  <div class="lines-title">
	  	<div class="lines-left"></div>
	  	<span>工作经历</span>
		<div class="add-title cursor" @click="expUpdata(-1)">
			<img src="../../static/images/edit@2x.png" />
			<span>添加工作经历</span>
		</div>
	  	<div class="hengxian hengxian2"></div>
	  </div>
      <div>
        <el-table :data="formData.experiences" :key="timeStamp" :show-header="false" style="width: 100%">
          <el-table-column prop="frameDate" label="工作时间" width="180px">
          </el-table-column>
          <el-table-column prop="workCompany" label="工作单位" width="180px">
          </el-table-column>
          <el-table-column prop="position" label="职位" width="180px">
          </el-table-column>
          <el-table-column prop="achievement" label="业绩" align="">
          </el-table-column>
          <el-table-column prop="creationtime" label="操作" align="" width="100">
            <template slot-scope="scope">
              <el-button type="text" @click="expUpdata(scope.$index, scope.row)">编辑</el-button>
              <el-button type="text" @click="expDetele(scope.$index, scope.row)">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
     
	  <div class="lines-title">
	  	<div class="lines-left"></div>
	  	<span>专业证书</span>
		<div class="add-title cursor" @click="addZhenshu">
			<img src="../../static/images/edit@2x.png" />
			<span>添加专业证书</span>
		</div>
	  	<div class="hengxian hengxian2"></div>
	  </div>
      <div class="certificateBox mdx-flex-wrap">
        <div class="certificateBox_lines " v-for="(item, index) in formData.files" :key='index'>
          <div class="certificateBox_lines_imgBox">
            <img style="width: 135px;height: 180px;" :src="item.url">
            <div class="iconbox" @click.stop="formData.files.splice(index, 1)">
              <i class="el-icon-close"></i>
            </div>
          </div>
          <div class="certificateBox_lines_name line2" style="white-space: normal;word-break: break-all;">{{ item.name }}</div>
        </div>
      </div>
      <p style="line-height: 50px;font-size: 14px;color: #ff0000; margin-top: 32px;">
        提示：您注册工程师后将成为平台灵活用工
      </p>
      <div class="check  mdx-flex">
        <el-checkbox-group v-model.trim="xieyiCheck" style="margin-top: 2px;">
          <el-checkbox label=""></el-checkbox>
        </el-checkbox-group>
        <div style="margin-left: 10px;font-size: 14px;">
          我已阅读并同意 <span @click="$mHelper.otherTo('/xieyi?type=3')" class="wzk-cursor" style="color: #4077DD;">《中一鼎合造价众包平台服务规则》</span> 和 <span
            @click="$mHelper.otherTo('/xieyi?type=6')" class="wzk-cursor" style="color: #4077DD;">《工程师服务协议》</span>
        </div>
      </div>
      <div class="subimtBtmn">
        <el-button type="primary" class="subimtBtmn_btn" @click="sumimtGcs">提交</el-button>
      </div>
    </el-form>
    <!-- 弹窗 -->
    <el-dialog :visible.sync="jsShow" width="40%" center top='30vh'>
      <div class="puopContext">
        <div class="dialog-title">个人介绍 </div>
        <p style="color: #ff0000;font-size: 13px;">禁止出现手机\QQ\微信等个人联系方式</p>
        <div style="height: 20px;"></div>
        <el-input type="textarea" :rows="5" placeholder="请输入内容" :maxlength="1000" v-model.trim="introduction"
          style="font-size: 14px;">
        </el-input>
      </div>
      <div class="puopfootBox mdx-text-right">
        <span>{{ introduction.length }}/1000字</span>
        <el-button size="small" @click="jsShow = false">取 消</el-button>
        <el-button size="small" type="primary" @click="introductionTap()">确认</el-button>
      </div>
    </el-dialog>
	
    <el-dialog :visible.sync="expShow" width="50%" center top='30vh'>
      <div class="puopContext">
        <el-form label-position="left" label-width="300px" :model="experiences" ref="expRef" :rules="rulesExp">
		  <div class="dialog-title">添加工作经历 </div>
          <div style="height: 15px;"></div>
          <el-form-item label="单位性质" prop="workCompany">
            <div class="inputWidth">
              <el-select v-model.trim="experiences.workCompany" placeholder="请选择单位性质">
                <el-option :label="item" v-for="(item, index) in natureUnit" :key='index' :value="item">
                </el-option>
              </el-select>
            </div>
          </el-form-item>
          <el-form-item label="工作岗位" prop="position">
            <div class="inputWidth">
              <el-input type="text" v-model.trim="experiences.position" placeholder="请输入您的工作岗位"></el-input>
            </div>
          </el-form-item>
		  <el-form-item label="工作开始日期-截至日期:" prop="time" style="position: relative;">
		    <div class="inputWidth">
		      <el-date-picker value-format="yyyy-MM" format="yyyy年MM" v-model.trim="experiences.time"
		        :picker-options="pickerOptions0" type="monthrange" range-separator="至" start-placeholder="开始月份" end-placeholder="结束月份">
		      </el-date-picker>
		    </div>
		  </el-form-item>
          <el-form-item label="主要业绩:" prop="achievement">
            <div class="">
               <el-input type="textarea" :rows="5" :maxlength="1000" placeholder="请输入内容" v-model.trim="experiences.achievement" style="font-size: 14px;"> </el-input>
            </div>
          </el-form-item>
          <div style="height: 15px;"></div>
          <div class="puopfootBox mdx-text-right">
            <span>{{ experiences.achievement.length }}/1000字</span>
            <el-button size="small" @click="expShow = false">取 消</el-button>
            <el-button size="small" type="primary" @click="expAddAlertTap">确认</el-button>
          </div>
        </el-form>
      </div>

    </el-dialog>
    <el-dialog :visible.sync="certShow" width="40%" center top='30vh'>
      <div class="puopContext">
        <el-form label-position='top' label-width="80px" :model="files">
		  <div class="dialog-title">添加证书 </div>
          <div style="height: 15px;"></div>
          <el-form-item label="证书名称">
			  <el-select v-model="selectAddZheng" placeholder="请选择" @change="selectZhengChange">
			  	<el-option v-for="(item,index) in zyTypeList" :key='index' :label="item.dictLabel" :value="item.dictValue"></el-option>
			  </el-select>
			  <div class="inputWidth" v-if="selectAddZheng =='其他'">
			    <div class="inputWidth" style="margin-top: 10px;">
			      <el-input type="text" v-model="files.name" placeholder="请输入证书名称" maxlength="30"></el-input>
			    </div>
			  </div>
          </el-form-item>
          <el-form-item label="证书图片">
            <el-upload style="margin-top: -20px;" class="upload-demo" :action='action1' ref="files"
              :on-success="handleChange" :on-remove="handleRemove" multiple :limit="1"
              accept="image/jpg,image/jpeg,image/png" :on-exceed="handleExceed" :file-list="fileList">
              <el-button size="small" type="primary">点击上传</el-button>
            </el-upload>
          </el-form-item>
          <div class="puopfootBox mdx-text-right">
            <el-button size="small" @click="certShow = false">取 消</el-button>
            <el-button size="small" :loading="zsloading" type="primary" @click="addCertificate">确认</el-button>
          </div>
        </el-form>
      </div>

    </el-dialog>
    <el-dialog :visible.sync="titleShow" width="40%" center top='20vh' class="tip-dialog">
      <div class="puopContext w-font-13">
        <div class="mdx-text-center">
          <h3>提示</h3>
        </div>
        <div class="mdx-text-center w-m-t-10">
          您已成功注册为工程师！
        </div>
        <div class="mdx-text-center w-m-t-20">
          <el-button type="primary" style="padding: 10px 30px;border-radius: 2px;" class="" @click="$mHelper.to('/')">我知道了
          </el-button>
        </div>
      </div>

    </el-dialog>
  </div>
</template>

<script>
import addOptions from '../../api/area.js'
import rulesData from '../../api/rules.js'
export default {
  data() {
    return {
      fileList: [],
      action: this.$store.state.baseURL + 'engineer/idCardOCRCheck',
      action1: this.$store.state.baseURL + 'upload/uploadCos',
      headers: {
        token: this.$store.getters['token']
      },
      pickerOptions0: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        }
      },
      xieyiCheck: [],
      titleShow: false,
      certification: 0,//1-已实名认证 2-未认证
      jsShow: false, //个人介绍弹窗
      introduction: '', //个人介绍

      expShow: false, //添加个人工作经验弹窗
      expShowId: 0,
      rulesExp: rulesData.rulesExp,
      gcsRules: rulesData.gcsRules, //工程师
      engineerRule: rulesData.gcsRules,
      certShow: false, //证书弹窗
      //多选
      check: [], //项目类型
      checkList: [], //项目类型
      softwareCheck: [], //擅长软件
      softwareList: [], //擅长软件
      addCheck: [], //擅长地区
      addList: [], //擅长地区
      majorCheck: [], //擅长专业
      majorList: [], //擅长专业
      majorListTetx: '',
      zyTypeList: [], //职业资格
      natureUnit: ["施工单位", "设计单位", "监理单位", "咨询单位", '项目管理公司', '建设单位', '管理机构', '招标代理', '个人工作室', '自由职业', '其他'],
      experiences: { //工作经历
        "achievement": "", //成果（业绩）
        "frameDate": "", //工作时间段（2003年4月-至今）
        "position": "", //职位（造价师（造价员，预算员）
        "workCompany": "", //工作单位（咨询单位）
        time: []
      },
      files: { //技能证书
        "name": "",
        "url": ""
      },
      zsloading: false,
      formData: {
        "experiences": [],
        "files": [],
        "idCard": "",
        "introduction": "",
        "major": [],
        "name": "",
        "necessary": 0,
        "portrait": "",
        "practiceQua": '',
        "region": [],
        "software": [],
        "surface": "",
        "team": '', //有无团队（1.有，2.无）
        "type": [],
        "years": ''
      },
      timeStamp: 0,
      chooseArr1: [],
      chooseArr2: [],
      chooseArr3: [],
      chooseArr4: [],
      chooseTip1: {
        show: false,
        text: '“项目类型”最多可选10个'
      },
      chooseTip2: {
        show: false,
        text: '“擅长软件”最多可选5个'
      },
      chooseTip3: {
        show: false,
        text: '“擅长地区”最多可选8个'
      },
      chooseTip4: {
        show: false,
        text: '“擅长专业”最多可选5个'
      },
	  selectAddZheng: '',
    }
  },
  created() {
    this.getUserInfo();
    this.$http.get('region/list').then(res => {
      if (res.code == 200) {
        this.addList = res.data
      } else {
        this.$message.error('地址获取失败')
      }
    })
    //擅执业资格获取
    this.$http.get('dict_data/getDictData', {
      dictType: 'zy_type'
    }).then(res => {
      if (res.code == 200) {
        this.zyTypeList = res.data
      } else {
        this.$message.error('执业资格获取失败')
      }
    })
    //擅长软件获取
    this.$http.get('dict_data/getDictData', {
      dictType: 'cc_rj'
    }).then(res => {
      if (res.code == 200) {
        this.softwareList = res.data
      } else {
        this.$message.error('擅长软件获取失败')
      }
    })
    //项目类型获取
    this.$http.get('dict_data/getDictData', {
      dictType: 'pro_type'
    }).then(res => {
      if (res.code == 200) {
        this.checkList = res.data
      } else {
        this.$message.error('项目类型获取失败')
      }
    })
    //专业类型获取
    this.$http.get('dict_data/getMajors').then(res => {
      if (res.code == 200) {
        this.majorList = res.data
      } else {
        this.$message.error('专业获取失败')
      }
    })
  },
  methods: {
    getUserInfo() {
      this.$http.get('user/getUserInfo').then(res => {
        console.log(res)
        if (res.code == 200) {
          if (res.data.hasOwnProperty('certification')) {
            this.certification = res.data.certification;

            if (res.data.certification == 1) {//注册工程师之前已实名认证
              // delete this.formData.name
              // delete this.formData.portrait
              // delete this.formData.surface
              if (res.data.idCard) {
                this.formData.idCard = res.data.idCard
              }
              if (res.data.name) {
                this.formData.name = res.data.name
              }
              if (res.data.portrait) {
                this.formData.portrait = res.data.portrait
              }
              if (res.data.surface) {
                this.formData.surface = res.data.surface
              }
            }
          }
        } else {
          console.log('---login---')
          //store.commit('outLogin')
        }
      })
    },
    //擅长项目勾选
    changeGoodAt(data) {
      if (data) {
        this.chooseTip1.show = data.length == 10 ? true : false
      }
    },
    //擅长软件勾选
    changeGoodSoftware(data) {
      if (data) {
        this.chooseTip2.show = data.length == 5 ? true : false
      }
    },
    //擅长地区勾选
    changeGoodArea(data) {
      if (data) {
        this.chooseTip3.show = data.length == 8 ? true : false
      }
    },
    //擅长专业勾选
    changeGoodSpecial(data) {
      if (data) {
        this.chooseTip4.show = data.length == 5 ? true : false
      }
    },
    majorChange(e, item) {
      console.log(e);
      console.log(item);
      if (e) {
        //专业类型获取
        this.$http.get('dict_data/getMajors', { id: item.id }).then(res => {
          if (res.code == 200) {
            // this.majorList = res.data
            this.majorListTetx = '提示：“' + item.name + '”专业函盖:'
            res.data.forEach(e => {
              this.majorListTetx += e.name + '、'
            })
            console.log(this.majorListTetx);
          } else {
            // this.$message.error('专业获取失败')
          }
        })
      } else {
        // this.majorListTetx=''
      }
    },
    //保存提交
    sumimtGcs() {
      if (this.certification == 1) {
        this.engineerRule = rulesData.engineerRules;
      }
      this.$refs['gcsRef'].validate((valid) => {
        if (valid) {
          if (this.xieyiCheck.length < 1) {
            this.$message.error('请阅读并同意勾选《中一鼎合造价众包平台服务规则》和《工程师服务协议》')
            return
          }
          if (this.certification != 1) {
            if (!this.formData.portrait || !this.formData.surface) {
              this.$message.error('请上传身份证正反面');
              return;
            }
          }
          // console.log(this.formData)
          this.$http.post('engineer/save', this.formData).then(res => {
            if (res.code == 200) {
              this.titleShow = true;
              localStorage.setItem('identityS',2);
              localStorage.setItem('changeType',2)
              this.$uniApi.Logins(); //获取个人用户信息
            }
          })
        } else {
          this.$message.warning('请完善信息');
          return false;
        }
      });
    },
    //添加和编辑工作经历
    expAddAlertTap() {
      this.$refs['expRef'].validate((valid) => {
        if (valid) {
          console.log(this.experiences.time);
          if (this.experiences.time.length < 1) {
            this.$message.error('请选择工作时间段')
            return
          }
          if (!this.experiences.achievement) {
            this.$message.error('请输入主要业绩')
            return
          }
          if (this.expShowId >= 0) {
            this.experiences.frameDate = this.experiences.time.join('至')
            this.formData.experiences[this.expShowId] = (JSON.parse(JSON.stringify(this
              .experiences)))
            this.$refs['expRef'].resetFields(); //初始化数据
            this.experiences.time = []
            this.experiences.achievement = ''
            this.expShow = false;
            console.log(this.formData.experiences[this.expShowId]);
          } else {
            this.experiences.frameDate = this.experiences.time.join('至')
            this.formData.experiences.push(JSON.parse(JSON.stringify(this.experiences)))
            this.$refs['expRef'].resetFields(); //初始化数据
            this.experiences.time = []
            this.experiences.achievement = ''
            this.expShow = false;
          }
          this.timeStamp++
        }
      });
    },
    introductionTap() {
      if (!this.introduction) {
        this.$message.success('请输入个人介绍')
        return;
      }
      this.formData.introduction = JSON.parse(JSON.stringify(this.introduction))
      this.jsShow = false;
    },
    //编辑个人介绍弹窗
    updataIntro() {
      this.introduction = JSON.parse(JSON.stringify(this.formData.introduction))
      this.jsShow = true
    },
    //工作经验
    expUpdata(index, item) {
      if (index >= 0) {
        this.expShowId = index;
        this.experiences = JSON.parse(JSON.stringify(item))
        this.experiences.time = this.experiences.frameDate.split('至')
        this.expShow = true
      } else {
        this.expShowId = -1;
        this.expShow = true
        this.$nextTick(() => {
          this.$refs['expRef'].resetFields(); //初始化数据
          this.experiences.time = []
          this.experiences.achievement = ''
        })

      }
    },
	//删除
	expDetele(index, item) {
	  let data = item
	  this.formData.experiences.splice(index, 1)
	  
	},
	//添加证书选择
	selectZhengChange(e) {
	 this.zyTypeList.forEach(vo=>{
		 if(e==vo.dictValue){
			 this.selectAddZheng = vo.dictLabel;
		 }
	 })
	},
    //添加证书
    addCertificate() {
      if(this.selectAddZheng==''){
      	this.$message.error('请选择证书名称')
      	return
      }else if(this.selectAddZheng=='其他'){
      	if (!this.files.name) {
      	  this.$message.error('请输入证书名字')
      	  return
      	}
      }else{
      	this.files.name = this.selectAddZheng;
      }
      if (!this.files.url) {
        this.$message.error('请上传证书图片')
        return
      }
      this.zsloading = true;
      setTimeout(() => {
        this.formData.files.push(JSON.parse(JSON.stringify(this.files)))
        this.$refs['files'].clearFiles();
        this.files.url = '';
        this.files.name = '';
        this.certShow = false
        this.zsloading = false;
      }, 500);
    },
    //证书图片上传回调
    handleChange(e) {
      if (e.code == 200) {
        this.files.url = e.data
      } else {
        this.$message.error(e.msg)
      }
    },
    handleRemove(file, fileList) {
      this.files.url = ''
    },
    handleExceed(files, fileList) {
      this.$message.warning(`最多选择1张图片`);
    },
    beforeAvatarUpload(file) {
      var testmsg = file.name.substring(file.name.lastIndexOf('.') + 1)
      const extension = testmsg === 'jpg'
      const extension2 = testmsg === 'png'
      const extension4 = testmsg === 'jpeg'
      if (!extension && !extension2 && !extension4) {
        this.$message({
          message: '上传文件只能是 jpg, jpeg, png格式!',
          type: 'warning'
        })
        return false
      }
      return extension || extension2 || extension4
    },
    handleAvatarSuccess(e, type) {
      if (e.code != 200) {
        this.$message.error(e.msg)
        return
      }
      if (type == 1) {
        this.formData.name = e.data.name
        this.formData.idCard = e.data.idCard
        this.formData.portrait = e.data.url
      } else {
        this.formData.surface = e.data.url
      }
    },

    addZhenshu() {
      this.certShow = true
      this.$nextTick(() => {
        this.files.name = ''
        this.$refs['files'].clearFiles();
      })
    }
  }
}
</script>

<style lang="scss" scoped>
  .tits {
    margin-top: 32px;
	.tits-title{
		font-family: PingFangSC, PingFang SC;
		font-weight: 600;
		font-size: 36px;
		color: rgba(0,0,0,0.85);
		line-height: 80px;
		text-align: left;
		font-style: normal;
	}
	.tits-info{
		font-family: PingFangSC, PingFang SC;
		font-weight: 400;
		font-size: 16px;
		color: rgba(0,0,0,0.85);
		line-height: 22px;
		text-align: left;
		font-style: normal;
		margin-top: 8px;
	}
  }
  
  ::v-deep .el-form-item{
  	display: flex;
  	flex-flow: column;
  	margin-bottom: 0;
	margin-top: 25px;
     .el-form-item__label {
  	  font-family: PingFangSC, PingFang SC;
  	  font-weight: 400;
  	  font-size: 16px;
  	  color: rgba(0,0,0,0.85);
  	  line-height: 20px;
  	  text-align: left;
  	  font-style: normal;
  	}
  	.el-form-item__content{
  		margin-left: 0 !important;
  		margin-top: 16px;
  		.el-radio__label{
  			font-family: PingFangSC, PingFang SC;
  			font-weight: 400;
  			font-size: 14px;
  			color: rgba(0,0,0,0.88);
  			line-height: 22px;
  			text-align: left;
  			font-style: normal;
  			padding-left: 8px;
  		}
  		.el-radio__input.is-checked .el-radio__inner{
  			border-color: #1677FF;
  			background-color: #1677FF;
  		}
  		.el-input{
  			min-width: 420px;
  		}
  	}
  }
	
	
.puopContext {
	margin-top: 0;
  p {
    margin-top: 5px;
  }
  .title{
	  font-family: PingFangSC, PingFang SC;
	  font-weight: 400;
	  font-size: 16px;
	  color: rgba(0,0,0,0.88);
	  line-height: 22px;
	  text-align: left;
	  font-style: normal;
  }
}

.dabwue {
  margin-left: 10px;
  position: relative;
  top: 10px;
}

.titleShow {}

.puopfootBox {
  span {
    color: #ff0000;
    margin-right: 10px;
  }
}

::v-deep .el-dialog--center .el-dialog__body {
  padding-top: 5px;
}

// ::v-deep .el-dialog__header {
//   display: none !important;
// }

.subimtBtmn {
  margin-top: 30px;
  text-align: center;
  margin-bottom: 32px;
  &_btn {
   padding: 12px 60px;
   font-size: 16px;
   background-color: #4077DD;
   border-color: #4077DD;
  }
}

.certificateBox {
  display: flex;

  &_lines {
    width: 135px;
    margin-top: 20px;
    margin-right: 18px;

    &_imgBox {
      position: relative;

      .iconbox {
        display: none;
        position: absolute;
        top: 0;
        right: 0;
        width: 16px;
        height: 16px;
        background: rgba(0, 0, 0, 0.4);
        text-align: center;
        line-height: 16px;
      }

      .el-icon-close {
        color: #fff;
        font-size: 13px;

      }
    }

    &_name {
      margin-top: 10px;
    }
  }
}

.certificateBox_lines_imgBox:hover {
  .iconbox {
    display: block;
  }
}

.jieshao {
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  font-size: 14px;
  color: rgba(0,0,0,0.85);
  line-height: 21px;
  text-align: left;
  font-style: normal;
  margin-top: 12px;
  white-space: normal;
  word-break: break-all;
}

::v-deep .el-table__cell{
	font-family: PingFangSC, PingFang SC;
	font-weight: 400;
	font-size: 14px;
	color: rgba(0,0,0,0.85);
	line-height: 21px;
	text-align: left;
	font-style: normal;
}

.alertText {
  font-size: 14px;
  color: #777;
}

.cards {
  width: 150px;
  margin-right: 24px;
  text-align: center;
  .avatar {
    margin-top: 10px;
    height: 102px;
    width: 150px;
  }
}

.titsl {
  margin-top: 50px;
  margin-bottom: 30px;
  width: 140px;
  text-align: right;
}

.titels2 {
  margin-top: 30px;
  margin-bottom: 10px;
}

.inputWidth {
  width: 420px;
  ::v-deep .el-date-editor--monthrange.el-input, .el-date-editor--monthrange.el-input__inner {
     width: 420px;
  }
}


.tishiTitle {
  font-size: 14px;
  color: #007AFF;
}

::v-deep .el-dialog{
	background: #FFFFFF;
	box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.1), 0px 0px 5px 0px rgba(0,0,0,0.14);
	border-radius: 10px;
	.el-dialog__headerbtn{
		top: 30px;
	}
	.el-dialog__body{
		padding:0 32px 32px;
		.dialog-title{
			font-family: PingFangSC, PingFang SC;
			font-weight: 500;
			font-size: 18px;
			color: rgba(0,0,0,0.85);
			line-height: 25px;
			text-align: left;
			font-style: normal;
		}
		.dialog-info{
			font-family: PingFangSC, PingFang SC;
			font-weight: 400;
			font-size: 14px;
			color: rgba(0,0,0,0.45);
			line-height: 20px;
			text-align: left;
			font-style: normal;
		}
		.el-form-item__label{
			font-family: PingFangSC, PingFang SC;
			font-weight: 400;
			font-size: 14px;
			color: rgba(0,0,0,0.85);
			font-style: normal;
		}
		.el-form-item{
			display: flex;
			flex-flow: column;
			margin-bottom: 0;
			margin-top: 25px;
		   .el-form-item__label {
			  font-family: PingFangSC, PingFang SC;
			  font-weight: 400;
			  font-size: 16px;
			  color: rgba(0,0,0,0.85);
			  line-height: 20px;
			  text-align: left;
			  font-style: normal;
			}
			.el-form-item__content{
				margin-left: 0 !important;
				margin-top: 16px;
				.el-radio__label{
					font-family: PingFangSC, PingFang SC;
					font-weight: 400;
					font-size: 14px;
					color: rgba(0,0,0,0.88);
					line-height: 22px;
					text-align: left;
					font-style: normal;
					padding-left: 8px;
				}
				.el-radio__input.is-checked .el-radio__inner{
					border-color: #1677FF;
					background-color: #1677FF;
				}
				.el-input{
					min-width: 420px;
				}
			}
		}
	}
}

.tip-dialog{
	::v-deep .el-dialog{
		width: 350px !important;
		height: 200px;
		background: #FFFFFF;
		border-radius: 10px;
		backdrop-filter: blur(42px);
		h3{
			font-family: PingFangSC, PingFang SC;
			font-weight: 600;
			font-size: 16px;
			color: rgba(0,0,0,0.85);
			font-style: normal;
		}
	}
}

</style>
