<template>
  <div id="app" style="width: 100%;">
	<template v-if="this.paths!='/login'">
	   <TopNav></TopNav>
	</template>
    <div class="mains" ref="main">
      <!-- <router-view ref="refdata"></router-view> -->
      <keep-alive>
        <router-view ref="refdata" v-if="$route.meta.keepAlive">
          <!-- 这里是会被缓存的视图组件，比如 page1,page2 -->
        </router-view>
      </keep-alive>
      <router-view ref="refdata" v-if="!$route.meta.keepAlive">
        <!-- 这里是不被缓存的视图组件，比如 page3 -->
      </router-view>
    </div>
    <template v-if="this.paths!='/login'">
      <Footer></Footer>
    </template>
    <SideBox></SideBox>
    <left-box></left-box>
  </div>
</template>
<script>
  import TopNav from './components/topNav'
  import Footer from './components/Footer'
  import SideBox from './components/side-box'
  import LeftBox from './components/left-box'
  export default {
    components: {
      TopNav,
      Footer,
      SideBox,
      LeftBox
    },
    data() {
      return {
        clientHeight: '',
      }
    },
    computed: {
      paths() {;
        return this.$route.path
      }
    },
    created() {
      if (this.$store.state['token']) {
        this.$uniApi.Logins(); //获取个人用户信息
        this.$http.get('notice/wdNum', ).then(res => {
          if (res.code == 200) {
            this.$store.state.msgCount = parseInt(res.data.projectNum) + parseInt(res.data.xtNum)
          }
        })
      }
    },
    methods: {
      urlRules() {
        if (this.paths) {

        }
      },
      changeFixed(clientHeight) { //动态修改样式
        this.$refs.main.style.minHeight = this.clientHeight - (60 + 306) + 'px';
      },
    },
    watch: {
      clientHeight: function() {
        this.changeFixed(this.clientHeight)
      },

    },
    mounted() {
      this.clientHeight = `${document.documentElement.clientHeight}`
      window.onresize = function temp() {
        this.clientHeight = `${document.documentElement.clientHeight}`;
      };
    },
  }
</script>
<style lang="scss">
  // ::v-deep .el-dialog__header {
  //   display: none !important;
  // }
  .el-dialog__headerbtn .el-dialog__close {
    color: #185BA1 !important;
  }

  .success {
    color: #34C759;
  }

  .error {
    color: #FF3B30;
  }

  .blue {
    color: #0090D4;
  }

  .infoColor {
    color: #757575;
  }

  .puopContext {
    margin-top: 20px;
  }

  .mains {
    width: 100%;
  }

  .puopfootBox {
    margin-top: 40px;
    text-align: center;
    width: 100%;

    ::v-deep .el-button {
      padding: 8px 20px;
    }
  }

  .topNavs {
    margin: 20px 0;
  }

  @import '@/static/css/common.scss';
  @import '@/common/css/flex.scss';

  * {
    margin: 0;
    padding: 0;
  }

  body {
    background-color: #FFFFFF;

  }

  #app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #2c3e50;
  }

  #nav {
    padding: 30px;

    a {
      font-weight: bold;
      color: #2c3e50;

      &.router-link-exact-active {
        color: #42b983;
      }
    }
  }

  .m-flex {
    display: flex;
  }

  .m-flex-y {
    display: flex;
    flex-direction: column;
  }

  .m-flex-bettween {
    justify-content: space-between;
  }

  .m-flex-center {
    justify-content: center;
  }
</style>
