<template>
  <div class="wary" ref="wary">
    <div class="logobox">
      <div class="leftbox">
		<div class="left-title">找项目、找人才</div>  
		<div class="left-title left2-title">专业，高效</div>  
		<div class="left-info">提供造价线上一站式服务</div>
		<div class="left-info">满足企业对高素质造价师的迫切需求</div>
		<div class="left-btn-list">
			<button class="left-btn-class">安卓端下载</button>
			<button class="left-btn-class left2-btn-class">IOS端下载</button>
		</div>
	  </div>
      <div class="rightbox" ref="rightbox">
		<div class="top-logo cursor" @click="$mHelper.to('/')">
			<img src="../../static/images/logo@2x.png" />
		</div>
		<div class="tab-lines"  v-if="loginType==1">
			<div class="title" :class="loginIdentity==1?'active-color':''" @click="IdentityCheck(1)">短信验证码登录</div>
			<div class="lines"></div>
			<div class="title" :class="loginIdentity==2?'active-color':''" @click="IdentityCheck(2)">账号密码登录</div>
		</div>
        <div class="rightImage">
            <img src="../../static/images/codeLoin.png" v-if="loginType!=2" @click="loginTypeCheck()">
            <img src="../../static/images/ptLogin.png" v-else @click="loginTypeCheck()">
        </div>
		

        <template v-if="loginType==1">
          <el-form :model="loginFrom" ref="logoFrom">
            <el-form-item v-if="loginIdentity==1">
			  <div class="input-title">手机号码</div>	
              <div class="inputBox">
                <el-input v-model="loginFrom.mobile" :maxlength="11" onkeyup="value=value.replace(/[^\d]/g,'')" placeholder="请输入手机号码"></el-input>
              </div>
            </el-form-item>
            <el-form-item v-else>
			  <div class="input-title">账号</div>	
              <div class="inputBox">
                <el-input v-model="loginFrom.username" placeholder="请输入账号"></el-input>
              </div>
            </el-form-item>
            <el-form-item v-if="loginIdentity==2">
			  <div class="input-title">密码</div>	
              <div class="inputBox">
                <el-input v-model="loginFrom.password" :maxlength="18" type="password" show-password placeholder="请输入密码"></el-input>
              </div>
            </el-form-item>
            <el-form-item v-else>
              <div class="inputBox inputCode">
				<div class="input-title">动态验证码</div>	
                <div class="codelines">
					<el-input v-model="loginFrom.code" onkeyup="value=value.replace(/[^\d]/g,'')" :maxlength="6" placeholder="请输入验证码"></el-input>
					<div class="codeBox wzk-cursor" @click.stop='sendSms'>{{codetext}}</div>
				</div>
              </div>
            </el-form-item>
			<div class="check mdx-flex" style="margin-top: 32px;" v-if="loginType==1">
			  <el-checkbox-group v-model="checkList" style="margin-top: 5px;">
			    <el-checkbox label=""></el-checkbox>
			  </el-checkbox-group>
			  <div class="xieyi">
			    我已阅读并同意 <span @click="$mHelper.to('/xieyi?type=1')">《中一鼎合造价众包平台用户协议》</span> 和 <span  @click="$mHelper.to('/xieyi?type=2')">《中一鼎合造价众包平台隐私协议》</span>
			  </div>
			</div>
            <div class="dengluBot">
              <el-button type="primary" style="width: 100%;" @click="loginBtns">登录</el-button>
            </div>
          </el-form>
        </template>
        <template v-if="loginType==2">
          <div class="mdx-text-center w-m-t-20">
            <wxlogin appid="wxa4d88247803ff3be" :scope="'snsapi_login'"
              :redirect_uri='encodeURIComponent($store.state.callback+"/login")' rel="external nofollow">
            </wxlogin>
          </div>
        </template>
        <template v-if="loginType==3">
          <el-form :model="loginFrom" ref="logoFrom">
            <el-form-item v-if="loginIdentity==1">
              <div class="mdx-center-lg inputBox">
                <el-input v-model="loginFrom.mobile" :maxlength="11" onkeyup="value=value.replace(/[^\d]/g,'')" placeholder="手机号"></el-input>
              </div>
            </el-form-item>
            <el-form-item v-else>
              <div class="mdx-center-lg inputBox">
                <el-input v-model="loginFrom.username" placeholder="账号"></el-input>
              </div>
            </el-form-item>
            <div class="mdx-center-lg inputBox">
              <el-input v-model="loginFrom.code" onkeyup="value=value.replace(/[^\d]/g,'')" :maxlength="6" placeholder="验证码"></el-input>
              <div class="codeBox wzk-cursor" @click.stop='sendSms'>{{codetext}}
              </div>
            </div>
			<div class="check mdx-flex" style="margin-top: 32px;" v-if="loginType==1">
			  <el-checkbox-group v-model="checkList" style="margin-top: 5px;">
			    <el-checkbox label=""></el-checkbox>
			  </el-checkbox-group>
			  <div class="xieyi">
			    我已阅读并同意 <span @click="$mHelper.to('/xieyi?type=1')">《中一鼎合造价众包平台用户协议》</span> 和 <span  @click="$mHelper.to('/xieyi?type=2')">《中一鼎合造价众包平台隐私协议》</span>
			  </div>
			</div>
            <div class="dengluBot">
              <el-button type="primary" style="width: 100%;" @click="mobileBingding">绑定</el-button>
            </div>
          </el-form>
        </template>
       
       
      </div>
    </div>
  </div>
</template>

<script>
  import wxlogin from 'vue-wxlogin';
  export default {
    components: {
      wxlogin
    },
    data() {
      return {
        checkList: [],
        loginType: 1, //手机号登录 2验证码登录
        loginIdentity: 1, //1验证码登录 2账号密码登录
        loginFrom: {
          username: '',
          password: '',
          code: '',
          mobile: '', //手机号
        },
        codeSeconds: 0, // 验证码发送时间间隔
        codetext: '获取验证码',
        openId: '',
        wxLoginCode: '',
		clientHeight: '',
      }
    },
    head: {
      script: [{
        src: "http://res.wx.qq.com/connect/zh_CN/htmledition/js/wxLogin.js"
      }]
    },
	watch: {
	  clientHeight: function() {
	    this.changeFixed(this.clientHeight)
	  },
	
	},
    mounted() {
      // var obj = new WxLogin({
      //   id: "weixin",
      //   appid: "wx3bdb1192c22883f3",
      //   scope: "snsapi_login",
      //   redirect_uri: "http://domain/weixinlogin"
      // });
	  
	  this.clientHeight = `${document.documentElement.clientHeight}`
	  window.onresize = function temp() {
	    this.clientHeight = `${document.documentElement.clientHeight}`;
	  };
    },
    created() {
      let data = this.$route.query
      if (data.code) {
        this.$http.post('/auth/LoginByWeChat', {
          pcCode: data.code
        }).then(res => {
          console.log(res);
          if (res.code == 200) {
            this.$message.success('登录成功')
            this.$store.commit('changeLogin', {
              token: res.data.token,
              userInfo: res.data.userInfo
            })
            this.$mHelper.to('/')
          }
          if (res.code == 1001) {
            this.openId = res.data;
            this.loginType = 3
          } else {
            this.loginType = 2
          }
        })
      }
    },
    methods: {
		changeFixed(clientHeight) { //动态修改样式
		 this.$refs.wary.style.minHeight = this.clientHeight + 'px';
		 this.$refs.rightbox.style.minHeight = this.clientHeight + 'px';
		},
      topage() {
        alert(1)
      },

      //點擊登錄
      loginBtns() {
        if (this.checkList.length < 1) {
          this.$message.error('请阅读并同意勾选《中一鼎合造价众包平台用户协议》和《中一鼎合造价众包平台隐私协议》')
          return
        }
        if (this.loginIdentity == 1) {
          console.log('普通用戶登錄');
          let checkSendCode = this.$mGraceChecker.check(this.loginFrom, this.$mFormRule.ptLogin);
          if (!checkSendCode) {
            this.$message.error(this.$mGraceChecker.error)
            return;
          }
          this.$http.post('/auth/loginByMobile', this.loginFrom).then(res => {
            if (res.code == 200) {
              this.$message({
                message: '登录成功',
                offset: 60,
                type: 'success',
                showClose: true,
              });
              // this.$message.success('登录成功')
              this.$store.commit('changeLogin', {
                token: res.data.token,
                userInfo: res.data.userInfo
              })
			  console.log(res.data.userInfo.identity,'loginloginmobile')
              if(res.data.userInfo.identity == 2){
                localStorage.setItem('changeType',2)
              }else if(res.data.userInfo.identity == 1){
                localStorage.setItem('changeType',1)
              }else{
                localStorage.setItem('changeType',3)
              }
              this.$mHelper.to('/')
            }
            if (res.code == 4003) {
              console.log('请完成个人资料后登录');
              this.$message.warning('请完成个人资料后登录')
              this.$mHelper.to('/fillInfo?uuid=' + res.data.uuid + '&mobile=' + res.data.userName + '&identity=' +
                res.data.identity)
            }
          })
        } else {
          let checkSendCode = this.$mGraceChecker.check(this.loginFrom, this.$mFormRule.ygLogin);
          if (!checkSendCode) {
            this.$message.error(this.$mGraceChecker.error)
            return;
          }
          this.$http.post('auth/loginByUsername', this.loginFrom).then(res => {
            if (res.code == 200) {
              this.$message({
                message: '登录成功',
                offset: 60,
                type: 'success',
                showClose: true,
              });
              //this.$message.success('登录成功')
              this.$store.commit('changeLogin', {
                token: res.data.token,
                userInfo: res.data.userInfo
              })
			   console.log(res.data.userInfo.identity,'loginloginusername')
              if(res.data.userInfo.identity == 2){
                localStorage.setItem('changeType',2)
              }else if(res.data.userInfo.identity == 1){
                localStorage.setItem('changeType',1)
              }else{
                localStorage.setItem('changeType',3)
              }
              this.$mHelper.to('/')
            }
            if (res.code == 4003) {
              console.log('请完成个人资料后登录');
              this.$message.warning('请完成个人资料后登录')
              this.$mHelper.to('/fillInfo?uuid=' + res.data.uuid + '&mobile=' + res.data.mobile + '&identity=' +
                res.data.identity)
            }
          })
        }
      },
      //微信登绑定手机号
      mobileBingding() {
        let checkSendCode = this.$mGraceChecker.check(this.loginFrom, this.$mFormRule.ptLogin);
        if (!checkSendCode) {
          this.$message.error(this.$mGraceChecker.error)
          return;
        }
        this.$http.post('auth/bindMobile', {
          openId: this.openId,
          code: this.loginFrom.code,
          mobile: this.loginFrom.mobile
        }).then(res => {
          if (res.code == 200) {
            this.$message.success('登录成功')
            this.$store.commit('changeLogin', {
              token: res.data.token,
              userInfo: res.data.userInfo
            })
            this.$mHelper.to('/')
          }
          if (res.code == 4003) {
            console.log('请完成个人资料后登录');
            this.$message.warning('请完成个人资料后登录')
            this.$mHelper.to('/fillInfo?uuid=' + res.data.uuid + '&mobile=' + res.data.userName + '&identity=' +
              res.data.identity)
          }
        })
      },
      //发送验证码
      sendSms() {
        if (this.codeSeconds > 0) {
          return;
        }
        let checkSendCode = this.$mGraceChecker.check(this.loginFrom, this.$mFormRule.sendCodeRule);
        if (!checkSendCode) {
          this.$message.error(this.$mGraceChecker.error)
          return;
        }
        this.$http.post('sms/sendCode', this.loginFrom).then(res => {
          if (res.code == 200) {
            this.$message.success('验证码发送成功，请注意查收')
            this.handleSmsCodeTime(59);
          }
        })
      },
      //验证码倒计时
      handleSmsCodeTime(time) {
        let timer = setInterval(() => {
          if (time <= 0) {
            clearInterval(timer);
            this.codeSeconds = 0;
            this.codetext = '再次发送';
          } else {
            this.codeSeconds = time;
            this.codetext = time;
            time--;
          }
        }, 1000);
      },
      IdentityCheck(e) {
        this.loginIdentity = e;
        if (this.loginIdentity == 1) {
          this.loginType = 1
        }
        this.$refs['logoFrom'].resetFields();
      },
      loginTypeCheck() {
        this.loginType = this.loginType == 1 ? 2 : 1
        this.$refs['logoFrom'].resetFields();
      }
    }
  }
</script>
<style lang="scss" scoped>
  .check {
	  width: 420px;
	  .xieyi{
		  font-family: PingFangSC, PingFang SC;
		  font-weight: 400;
		  font-size: 16px;
		  color: rgba(0,0,0,0.85);
		  line-height: 20px;
		  text-align: left;
		  font-style: normal;
		  margin-left: 12px;
	  }
    span {
      color: #4077DD;
      cursor: pointer;
    }
  }

  .dengluBot {
    width: 420px;
    height: 45px;
    background: #4077DD;
    border-radius: 6px;
	margin-top: 32px;
	button{
		 background: #4077DD;
		 border: 0;
		 font-family: PingFangSC, PingFang SC;
		 font-weight: 400;
		 font-size: 14px;
		 color: #FFFFFF;
		 line-height: 22px;
		 text-align: center;
		 font-style: normal;
	}
  }

  .wary {
    width: 100%;
	height: 100%;
	background-image: url('../../static/images/loginbg.jpg');
	background-size: 100% 100%;
  }

  .logobox {
    overflow: hidden;
	width: 100%;
	display: flex;
	justify-content: space-between;
	.leftbox{
		width: calc(100% - 804px);
		padding-left: 102px;
		padding-top: 139px;
		.left-title{
			font-family: HYk1gj;
			font-size: 48px;
			color: #FFDF65;
			line-height: 80px;
			text-align: left;
			font-style: normal;
		}
		.left2-title{
			margin-bottom: 18px;
		}
		.left-info{
			font-family: PingFangSC, PingFang SC;
			font-weight: 500;
			font-size: 24px;
			color: #FFFFFF;
			line-height: 48px;
			text-align: left;
			font-style: normal;
		}
		.left-btn-list{
			.left-btn-class{
				width: 102px;
				height: 32px;
				background: #10C550;
				border-radius: 6px;
				font-family: PingFangSC, PingFang SC;
				font-weight: 400;
				font-size: 14px;
				color: #FFFFFF;
				line-height: 22px;
				text-align: center;
				font-style: normal;
				border: 0;
				margin-top: 104px;
			}
			.left2-btn-class{
				background: #FFB420;
				margin-left: 24px;
			}
		}
		
	}
	.rightbox {
	  position: relative;
	  // width: 700px;
	  min-width: 22.2%;
	  padding: 0 140px;
	  background-color: #FFFFFF;
	  .top-logo{
		  margin-top: 163px;
		  img{
			  width: 225px;
			  height: 46px;
		  }
	  }
	  .tab-lines{
		  width: 420px;
		  height: 45px;
		  background: #FFFFFF;
		  border-radius: 4px;
		  border: 1px solid #E8E8E8;
		  margin-top: 54px;
		  display: flex;
		  justify-content: space-between;
		  align-items: center;
		  .title{
			  font-family: PingFangSC, PingFang SC;
			  font-weight: 400;
			  font-size: 14px;
			  color: rgba(0,0,0,0.85);
			  line-height: 20px;
			  text-align: center;
			  font-style: normal;
			  width: 209px;
			  display: flex;
			  justify-content: center;
			  align-items: center;
		  }
		  .lines{
			  width: 1px;
			  height: 45px;
			  background: #E8E8E8;
		  }
		  .active-color{
			  color: #4077DD;
		  }
	  }
	  
	  .rightImage {
	    img {
	      position: absolute;
	      width: 80px;
	      height: 80px;
	      top: 0;
		  right: 0;
	      cursor: pointer;
	    }
	  }
	  .el-form-item{
		  margin-bottom: 0;
		  height: 116px;
	  }
	  .input-title{
		  font-family: PingFangSC, PingFang SC;
		  font-weight: 400;
		  font-size: 16px;
		  color: rgba(0,0,0,0.85);
		  line-height: 20px;
		  text-align: left;
		  font-style: normal;
		  margin-top: 32px;
	  }
	  .inputBox {
	    width: 420px;
	    height: 45px;
		margin-top: 19px;
	    ::v-deep .el-input__inner {
			width: 420px;
			height: 45px;
			background: #FFFFFF;
			border-radius: 4px;
			border: 1px solid #E8E8E8;
	    }
	  
	    position: relative;
	  
	    .codeBox {
			 width: 120px;
			 height: 45px;
			 background: #10C550;
			 border-radius: 6px;
			 font-family: PingFangSC, PingFang SC;
			 font-weight: 400;
			 font-size: 14px;
			 color: #FFFFFF;
			 line-height: 22px;
			 text-align: center;
			 font-style: normal;
			 display: flex;
			 justify-content: center;
			 align-items: center;
	    }
	  }
	  .inputCode{
		  margin-top: 32px;
		  .codelines{
			  display: flex;
			  justify-content: space-between;
			  align-items: center;
			  margin-top: 19px;
		  }
		  ::v-deep .el-input {
		  	width: 276px;
		  }  
			::v-deep .el-input__inner {
				width: 276px;
				height: 45px;
				background: #FFFFFF;
				border-radius: 4px;
				border: 1px solid #E8E8E8;
			}  
	  }
	  
	  
	}
	
  }

 

  
</style>
