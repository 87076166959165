<template>
	<div class="pages">
		<div class="lines-title w-m-t-0">
			<div class="lines-left"></div>
			<span>评分信息</span>
			<div class="hengxian"></div>
		</div>
		<div class="engi-top w-m-t-32">
			<div class="left">
				<!-- <WzkAvatar :isGender="true" :gender="enginInfo.gender" :imgSrc='enginInfo.headImgUrl'></WzkAvatar> -->
				<div class="number w-m-t-0">工程师编号：{{enginInfo.engineerNo||''}}</div>
				<div class="number">综合得分：<span style="color: #4077DD;">{{enginInfo.synthesize||''}}</span></div>
				<div class="number">好评率：{{enginInfo.favorable||''}}</div>
				<div class="number">及时率：{{enginInfo.timeliness||''}}</div>
				<div class="number">优秀率：<span v-if="enginInfo.excellent">{{enginInfo.synthesize||''}}%</span><span v-else>--</span></div>
				<div class="number">信誉值：{{enginInfo.reputation|| '--'}}</div>
			</div>
			<div class="tubiaoLienss">
				<div class="mdx-flex">
					<div id="myChart" :style="{width: '45%', height: '230px'}"></div>
					<div id="container" :style="{width: '55%', height: '230px'}"></div>
				</div>
				<div class="mdx-flex mdx-row-between">
					<span>
					   共完成：{{enginInfo.completed||''}}个
					</span>
					<span>
					   总投资：{{enginInfo.investment||''}}元
					</span>
					<span>
					   总用时：{{enginInfo.totalDay||''}}天
					</span>
				</div>
			</div>
		</div>
		<div class="lines-title ">
			<div class="lines-left"></div>
			<span>基本信息</span>
			<div class="hengxian"></div>
		</div>
		<div class="info-box">
			<div class="listLines" style="flex: 1;">
				<div class="lfetInfo wzk-flex-1">
					<div class="lfetInfo_lines">
						<div class="lfetInfo_lines_title">擅长项目：</div>
						<div class="wzk-flex-1 ">
							 <el-tag type="info" class="tags" v-for="(item,index) in enginInfo.typeFy" :key='index'>{{item.label}}</el-tag>
							 <i class="wzk-bianji wzk iconst" @click="listSelctShow(1)"></i>
						</div>
					</div>
					<div class="lfetInfo_lines">
						<div class="lfetInfo_lines_title">擅长专业：</div>
						<div>
							<el-tag type="info" class="tags" v-for="(item,index) in enginInfo.majorFy" :key='index'>{{item.name}}</el-tag>
							<i class="wzk-bianji wzk iconst" @click="listSelctShow(3)"></i>
						</div>
					</div>
				    <div class="lfetInfo_lines">
						<div class="lfetInfo_lines_title">擅长软件：</div>
						<div class="wzk-flex-1">
							<el-tag type="info" class="tags" v-for="(item,index) in enginInfo.softwareFy" :key='index'>{{item.label}} </el-tag>
							<i class="wzk-bianji wzk iconst" @click="listSelctShow(2)"></i>
						</div>
				    </div>
					<div class="lfetInfo_lines">
						<div class="lfetInfo_lines_title">擅长地区：</div>
						<div class="wzk-flex-1 mdx-flex-wrap" style="display: flex;" v-if="enginInfo.region">
						  <div v-for="(item,index) in enginInfo.regionFy" :key='index'>
							<el-tag type="info" class="tags">
							  <template>
								{{item.name}}
							  </template>
							</el-tag>
						  </div>
						  <i class="wzk-bianji wzk iconst wzk-cursor" @click="addAlertTaps()"></i>
						</div>
					</div>
					<div class="lfetInfo_lines">
						<div class="lfetInfo_lines_title">现场工作：</div>
						<div class="w-m-l-10">
							<template>
								<el-radio v-model="enginInfo.necessary" :label="1" @change="radioChange($event,1)">是</el-radio>
								<el-radio v-model="enginInfo.necessary" :label="2" @change="radioChange($event,1)">否</el-radio>
							</template>
						</div>
					</div>
					<div class="lfetInfo_lines">
						<div class="lfetInfo_lines_title">有无团队：</div>
						<div class="w-m-l-10">
							<template>
								<el-radio v-model="enginInfo.team" :label="1" @change="radioChange($event,2)">有</el-radio>
								<el-radio v-model="enginInfo.team" :label="2" @change="radioChange($event,3)">无</el-radio>
							</template>
						</div>
					</div>
					<div class="lfetInfo_lines">
						<div class="lfetInfo_lines_title">从业年限：</div>
						<div class="w-m-l-10">
						  {{enginInfo.years||'0'}}年<i class="wzk-bianji wzk iconst wzk-cursor" @click="yearShowTap()" style="margin-left: 10px;"></i>
						</div>
					</div>
					<div class="lfetInfo_lines">
						<div class="lfetInfo_lines_title" style="display: flex; align-items: center;">执业资格：</div>
						<div class="w-m-l-10">
							<!-- 二级造价工程师 -->
							<el-select v-model="enginInfo.practiceQua" placeholder="请选择" @change="selectChange">
								<el-option :label="item.dictLabel" v-for="(item,index) in zyTypeList" :key='index' :value="item.dictValue"></el-option>
							</el-select>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="lines-title">
			<div class="lines-left"></div>
			<span>专业证书</span>
				<div class="add-title cursor" @click="addZhenshu()">
					<img src="../../../static/images/edit@2x.png" />
					<span>添加专业证书</span>
				</div>
			<div class="hengxian hengxian2"></div>
		</div>
		<div class="mdx-flex-wrap" style="display: flex;">
		  <div class="certificateBox mdx-flex-wrap" v-for="(item,index) in enginInfo.files" :key='index'>
		    <div class="certificateBox_lines">
		      <div class="certificateBox_lines_imgBox">
		        <img style="width: 135px;height: 180px;" :src="item.url">
		        <div class="iconbox" @click="detailsEngin(item,index)">
		          <i class="el-icon-close"></i>
		        </div>
		      </div>
		      <div class="certificateBox_lines_name line2" style="text-align: left; white-space: normal; word-break: break-all;">{{item.name||''}}</div>
		    </div>
		  </div>
		</div>
	    <div class="lines-title">
	    	<div class="lines-left"></div>
	    	<span>个人介绍</span>
	    		<div class="add-title cursor" @click="updataIntro()">
	    			<img src="../../../static/images/edit@2x.png" />
	    			<span>编辑个人介绍</span>
	    		</div>
	    	<div class="hengxian hengxian2"></div>
	    </div>
		<div class="person-info w-m-t-24">
		  {{enginInfo.introduction||'无'}}
		</div>
		<div class="lines-title">
			<div class="lines-left"></div>
			<span>工作经历</span>
				<div class="add-title cursor" @click="expUpdata(-1)">
					<img src="../../../static/images/edit@2x.png" />
					<span>添加工作经历</span>
				</div>
			<div class="hengxian hengxian2"></div>
		</div>
	    <div class="lc-list">
			<div class="lc-item" v-for="(item,index) in enginInfo.experiences" :key="index">
				<div class="itemtitle">
					<div class="title">
						<span>{{item.workCompany || ''}} | {{item.position || ''}}</span>
						<span class="caozuo cursor" @click="expUpdata(index,item)">编辑</span>
						<span class="caozuo cursor" @click="expDetele(index,item)">删除</span>
					</div>
					<div class="time">{{item.frameDate || ''}}</div>
				</div> 
				<div class="info">
					{{item.achievement || ''}}
				</div>
			</div>
		</div>
		
		<div class="lines-title">
			<div class="lines-left"></div>
			<span>项目经验</span>
				<div class="add-title cursor" @click="exproUpdata(-1)">
					<img src="../../../static/images/edit@2x.png" />
					<span>添加项目经验</span>
				</div>
			<div class="hengxian hengxian2"></div>
		</div>
		<div class="lc-list">
			<div class="lc-item" v-for="(item,index) in enginInfo.experienceProject" :key="index">
				<div class="itemtitle">
					<div class="title">
						<span>{{item.projectName || ''}}</span>
						<span class="caozuo cursor" @click="exproUpdata(index,item)">编辑</span>
						<span class="caozuo cursor" @click="exproDetele(index,item)">删除</span>
					</div>
					<div class="time">{{item.workTime || ''}}</div>
				</div> 
				<div class="info">
					{{item.remark || ''}}
				</div>
			</div>
		</div>
		
		<div class="lines-title">
			<div class="lines-left"></div>
			<span>线上项目</span>
			<div class="hengxian"></div>
		</div>
		<div class="w-m-t-24 w-m-b-30">
			<div class="expList" v-for="(item,index) in expList" :key='index'>
			  <div class="names cursor" @click="toDetails(item)">
			    {{item.regionFy}}/{{item.typeFy}}
			    <template>
			      <span v-for="(row,index) in item.majorFy" :key="index+'q'">/{{row.name}}</span>
			    </template>
			    <span>
			      /总投资:￥{{item.totalInvestment||'00'}}
			    </span>
			    <span>
			      /总规模:{{item.totalScale}}m²
			    </span>
			    /{{item.serviceContentFy}}
			  </div>
			  <!-- <div class="prices">
			    ￥{{item.totalInvestment||'0.00'}}
			  </div>
			  <div class="teims">
			    交付时间：{{item.engineerDeadline}}
			  </div> -->
			  <div class="commont-box">
				  <div class="commont-title">客户评价：</div>
				  <div>
				    <el-rate v-model="item.uRate" disabled disabled-void-color="#D8D8D8"></el-rate>
				  </div>
				 <!-- <div class="commontText">
					<div class="">服务态度：{{item.uRamrk1 || ''}}</div>
					<div class="">成果质量：{{item.uRamrk2 || ''}}</div>
				  </div> -->
				  
				  <div class="commont-title w-m-t-24">项目经理评价：</div>
				  <div>
				    <el-rate v-model="item.mRate" disabled disabled-void-color="#D8D8D8"></el-rate>
				  </div>
				 <!-- <div class="commontText">
				     <div class="">服务态度：{{item.mRamrk1 || ''}}</div>
				     <div class="">工作质量：{{item.mRamrk2 || ''}}</div>
					 <div class="">工作表现：{{item.mRamrk3 || ''}}</div>
				  </div> -->
			  </div>
			 
			</div>
			<div class="mdx-flex mdx-row-center " style="margin-top: 30px;" v-if="list.length">
			  <el-pagination :pageSize="queryParams.limit" :currentPage='queryParams.page' background layout="prev, pager, next" @current-change='pageChange' :page-count="queryParams.totalPage||1"></el-pagination>
			</div>
		</div>
	
    
	
	<el-dialog :visible.sync="jsShow" width="40%" center top='30vh'>
      <div class="puopContext">
		<div class="dialog-title">个人介绍 </div>
        <p style="color: #ff0000;font-size: 13px;">禁止出现手机\QQ\微信等个人联系方式</p>
        <div style="height: 20px;"></div>
        <el-input type="textarea" :rows="5" placeholder="请输入内容" :maxlength="1000" v-model.trim="introduction" style="font-size: 14px;"></el-input>
      </div>
      <div class="puopfootBox mdx-text-right">
        <span>{{introduction.length}}/1000字</span>
        <el-button size="small" @click="jsShow = false">取 消</el-button>
        <el-button size="small" type="primary" :loading="loading" @click="introductionTap()">确认</el-button>
      </div>
    </el-dialog>
    <el-dialog :visible.sync="yearShow" width="40%" center top='30vh'>
      <div class="puopContext">
        <h4 style="color: rgba(0,0,0,0.85);">工程造价从业年限：</h4>
        <div style="height: 10px;"></div>
        <el-input type="number" onkeyup="value=value.replace(/[^\d]/g,'')" placeholder="请输入工程造价从业年限" :maxlength="4" v-model.trim="yearNunber" style="font-size: 14px;">
        </el-input>
      </div>
      <div class="puopfootBox mdx-text-right">
        <el-button size="small" @click="yearShow = false">取 消</el-button>
        <el-button size="small" type="primary" :loading="loading" @click="yuearSaves()">确认</el-button>
      </div>
    </el-dialog>
    <el-dialog :visible.sync="expShow" width="50%" center top='30vh'>
      <div class="puopContext">
        <el-form label-position="left" label-width="300px" :model="experiences" ref="expRef" :rules="rulesExp">
			<div class="dialog-title">编辑工作经历 </div>
          <div style="height: 15px;"></div>
          <el-form-item label="单位性质" prop="workCompany">
            <div class="inputWidth">
              <el-select v-model="experiences.workCompany" placeholder="请选择单位性质">
                <el-option :label="item" v-for="(item,index) in natureUnit" :key='index' :value="item">
                </el-option>
              </el-select>
            </div>
          </el-form-item>
          <el-form-item label="工作岗位" prop="position">
            <div class="inputWidth">
              <el-input type="text" v-model="experiences.position" placeholder="请输入您的工作岗位" maxlength="20"></el-input>
            </div>
          </el-form-item>
		  <el-form-item label="工作开始日期-截至日期:" prop="time" style="position: relative;">
		    <div class="inputWidth">
		     <el-date-picker value-format="yyyy-MM" format="yyyy年MM" v-model="experiences.time" :picker-options="pickerOptions0" type="monthrange" range-separator="至" start-placeholder="开始月份" end-placeholder="结束月份">
		     </el-date-picker>
		    </div>
		  </el-form-item>
          <el-form-item label="主要业绩:" prop="achievement">
            <div class="">
               <el-input type="textarea" :rows="5" :maxlength="1000" placeholder="请输入内容" v-model="experiences.achievement" style="font-size: 14px;"> </el-input>
            </div>
          </el-form-item>
          <div style="height: 15px;"></div>
          <div class="puopfootBox mdx-text-right">
            <span>{{experiences.achievement.length}}/1000字</span>
            <el-button size="small" @click="expShow = false">取 消</el-button>
            <el-button size="small" type="primary" :loading='loading' @click="expAddAlertTap">确认</el-button>
          </div>
        </el-form>
      </div>
    </el-dialog>
    <el-dialog :visible.sync="certShow" width="40%" center top='30vh'>
      <div class="puopContext">
        <el-form label-position='top' label-width="80px" :model="files">
          <h4>添加证书</h4>
          <div style="height: 15px;"></div>
          <el-form-item label="名称">
			<el-select v-model="selectAddZheng" placeholder="请选择" @change="selectZhengChange">
				<el-option v-for="(item,index) in zyTypeList" :key='index' :label="item.dictLabel" :value="item.dictValue"></el-option>
			</el-select>
            <div class="inputWidth" v-if="selectAddZheng =='其他'">
              <div class="inputWidth" style="margin-top: 10px;">
                <el-input type="text" v-model="files.name" placeholder="请输入证书名称" maxlength="30"></el-input>
              </div>
            </div>
          </el-form-item>
          <el-form-item label="证书图片">
            <el-upload style="margin-top: -20px;" class="upload-demo" :action='action' ref="files"
              :on-success="handleChange" :on-remove="handleRemove" multiple :limit="1"
              accept="image/jpg,image/jpeg,image/png" :on-exceed="handleExceed" :file-list="fileList">
              <el-button size="small" type="primary">点击上传</el-button>
            </el-upload>
          </el-form-item>
          <div class="puopfootBox mdx-text-right">
            <el-button size="small" @click="certShow = false">取 消</el-button>
            <el-button size="small" :loading="loading" type="primary" @click="addCertificate">确认
            </el-button>
          </div>
        </el-form>
      </div>
    </el-dialog>
    <el-dialog :visible.sync="addAlertShow" :before-close="areaClose" width="40%" center top='30vh'>
      <div class="puopContext">
        <el-form label-position='top' label-width="80px" :model="addCheckForm">
          <el-form-item>
            <template slot="label">
              <div class="m-flex">
                <span>擅长地区：</span>
                <span style="color: #ff8533;">{{'（最多可选8个）'}}</span>
              </div>
            </template>
            <el-checkbox-group v-model="addCheckForm.value" :max="areaTip.max">
              <el-checkbox :label="item.code" v-for="(item,index) in addList" :key='index'>
                {{item.cityName}}
              </el-checkbox>
            </el-checkbox-group>
          </el-form-item>
          <div class="puopfootBox mdx-text-right">
            <el-button size="small" @click="clearAreaTip(1)">取 消</el-button>
            <el-button size="small" :loading="loading" type="primary" @click="addressCheckSave">确认
            </el-button>
          </div>
        </el-form>
      </div>
    </el-dialog>
    <el-dialog :visible.sync="checkShow" :before-close="checkClose" width="40%" center top='30vh'>
      <div class="puopContext">
        <el-form label-position='top' label-width="80px" :model="addCheckForm">
          <el-form-item>
            <template slot="label">
              <div class="m-flex">
                <span>{{checkTitle+'：'}}</span>
                <span style="color: #ff8533;">{{chooseTip.text}}</span>
              </div>
            </template>
            <template v-if="addCheckForm.type!=3">
              <el-checkbox-group v-model="addCheckForm.selectList" :max="chooseTip.max">
                <el-checkbox :label="item.dictValue" v-for="(item,index) in checkList" :key='index'>
                  {{item.dictLabel}}
                </el-checkbox>
              </el-checkbox-group>
            </template>
            <template v-if="addCheckForm.type==3">
              <el-checkbox-group v-model="addCheckForm.selectList" :max="chooseTip.max">
                <el-checkbox :label="item.id" v-for="(item,index) in checkList" :key='index'>
                  {{item.name}}
                </el-checkbox>
              </el-checkbox-group>
            </template>
          </el-form-item>
          <div class="puopfootBox mdx-text-right">
            <el-button size="small" @click="clearChooseTip(1)">取 消</el-button>
            <el-button size="small" :loading="loading" type="primary" @click="selcetCheckTap">确认
            </el-button>
          </div>
        </el-form>
      </div>
    </el-dialog>
	
	<el-dialog :visible.sync="exproShow" width="50%" center top='30vh'>
	  <div class="puopContext">
	    <el-form label-position="left" label-width="300px" :model="experienceProject" ref="exproRef" :rules="rulesExpro">
			<div class="dialog-title">编辑项目经验 </div>
	      <div style="height: 15px;"></div>
	      <el-form-item label="项目名称" prop="projectName">
	        <div class="inputWidth">
	          <el-input type="text" v-model="experienceProject.projectName" placeholder="请输入项目名称" maxlength="20"></el-input>
	        </div>
	      </el-form-item>
		  <el-form-item label="开始日期-截至日期:" prop="time" style="position: relative;">
		    <div class="inputWidth">
		     <el-date-picker value-format="yyyy-MM" format="yyyy年MM" v-model="experienceProject.time" :picker-options="pickerOptions0" type="monthrange" range-separator="至" start-placeholder="开始月份" end-placeholder="结束月份">
		     </el-date-picker>
		    </div>
		  </el-form-item>
	      <el-form-item label="主要业绩:" prop="remark">
	        <div class="">
	           <el-input type="textarea" :rows="5" :maxlength="1000" placeholder="请输入内容" v-model="experienceProject.remark" style="font-size: 14px;"> </el-input>
	        </div>
	      </el-form-item>
	      <div style="height: 15px;"></div>
	      <div class="puopfootBox mdx-text-right">
	        <span>{{experienceProject.remark.length}}/1000字</span>
	        <el-button size="small" @click="exproShow = false">取 消</el-button>
	        <el-button size="small" type="primary" :loading='loading' @click="exproAddAlertTap">确认</el-button>
	      </div>
	    </el-form>
	  </div>
	</el-dialog>
	
  </div>
</template>

<script>
  import addOptions from '../../../api/area.js'
  // import WzkAvatar from '../../../components/wzk-Avatar/index.vue'
  import rulesData from '../../../api/rules.js'
  export default {
    components: {
      // WzkAvatar
    },
    data() {
      return {
        yearShow: false, //工程造价从业年限弹窗
        yearNunber: '',
        addCheckForm: {
          value: [],
          selectList: [],
          type: 0
        },
        chooseTip: {
          show: false,
          text: '',
          max: 20,
        },
        areaTip: {
          show: false,
          text: '“擅长地区”最多可选8个',
          max: 8,
        },
        checkShow: false,
        checkList: [],
        checkTitle: '',
        addAlertShow: false,
        addList: [],
        softwareList: [],
        objTypeList: [],
        majorList: [],
        //
        fileList: [],
        pickerOptions0: {
          disabledDate(time) {
            return time.getTime() > Date.now();
          }
        },
        action: this.$store.state.baseURL + 'upload/uploadCos',
        loading: false,
        zyTypeList: [], //擅执业资格
        enginInfo: {},
        jsShow: false,
        introduction: '',
        //
        expShow: false,
        //certShow
        certShow: false,
        formLabelAlign: {

        },
        value: 4,
        list: [{}],
        queryParams: {
          page: 1, //当前页数
          limit: 10, //每页条数
          totalPage: 1
        },
        noticeList: [],
        files: { //技能证书
          "name": "",
          "url": ""
        },
        timeStamp: 0,
        rulesExp: rulesData.rulesExp,
        natureUnit: ["施工单位", "设计单位", "监理单位", "咨询单位", '项目管理公司', '建设单位', '管理机构', '招标代理', '个人工作室', '自由职业', '其他'],
        experiences: { //工作经历
          "achievement": "", //成果（业绩）
          "frameDate": "", //工作时间段（2003年4月-至今）
          "position": "", //职位（造价师（造价员，预算员）
          "workCompany": "", //工作单位（咨询单位）
          "time": []
        },
        expShowId: 0,
        expList: [],
		selectAddZheng: '',
		
		exproShow: false,
		exproShowId: 0,
		exproList: [],
		rulesExpro: rulesData.rulesExpro,
		experienceProject: { //项目经验
		  "projectName": "",
		  "workTime": "",
		  "remark": "",
		  "time": []
		},
		
		
      }
    },
    mounted() {
      this.getEchartData()
    },
    created() {
      //地址获取
      this.$http.get('region/list').then(res => {
        if (res.code == 200) {
          this.addList = res.data
        } else {
          this.$message.error('地址获取失败')
        }
      })
      this.getUserDetail()
      //擅执业资格获取
      this.$http.get('dict_data/getDictData', {
        dictType: 'zy_type'
      }).then(res => {
        if (res.code == 200) {
          this.zyTypeList = res.data
        } else {
          this.$message.error('执业资格获取失败')
        }
      })

      //擅长软件获取
      this.$http.get('dict_data/getDictData', {
        dictType: 'cc_rj'
      }).then(res => {
        if (res.code == 200) {
          this.softwareList = res.data
        } else {
          this.$message.error('擅长软件获取失败')
        }
      })
      //项目类型获取
      this.$http.get('dict_data/getDictData', {
        dictType: 'pro_type'
      }).then(res => {
        if (res.code == 200) {
          this.objTypeList = res.data
        } else {
          this.$message.error('项目类型获取失败')
        }
      })
      //专业类型获取
      this.$http.get('dict_data/getMajors').then(res => {
        if (res.code == 200) {
          this.majorList = res.data
        } else {
          this.$message.error('专业获取失败')
        }
      })
    },
    methods: {
      //获取用户工程师信息
      getUserDetail() {
        this.$http.get('engineer/detail').then(res => {
          if (res.code == 200) {
            res.data.region = res.data.region.split(',')
            res.data.major = []
            res.data.majorFy.forEach(e => {
              res.data.major.push(e.id)
            })
            res.data.type = []
            res.data.typeFy.forEach(e => {
              res.data.type.push(e.value)
            })
            res.data.software = []
            res.data.softwareFy.forEach(e => {
              res.data.software.push(e.value)
            })
			
			var ata =[];
			if(res.data.experiences.length >0){
				res.data.experiences.forEach(vo=>{
					 if(vo.status==0){
						 ata.push(vo);
					 }
				});
				res.data.experiences = ata;
			}
			
			var aba =[];
			if(res.data.experienceProject.length >0){
				res.data.experienceProject.forEach(vo=>{
					 if(vo.status==0){
						 aba.push(vo);
					 }
				});
				res.data.experienceProject = aba;
			}
			
            this.enginInfo = res.data
            //   console.log(3)
            // console.log(this.enginInfo)
            this.timeStamp++;
            this.$forceUpdate();
            this.getExpList()
			let datas = []
			let datas2 = []
              datas2.push({
                  hpl:this.enginInfo.favorable.replace("%",""),
                  jsl:this.enginInfo.timeliness.replace("%",""),
                  yxl:this.enginInfo.excellent,
                  xyz:this.enginInfo.reputation,
                  wcl:this.enginInfo.completesize,
              })
			res.data.hxMap.forEach(e => {
			  datas.push({
			    value: e.bfb,
			    name: e.type
			  })

			})
				this.getEchartData(datas)
				this.getEchartData2(datas2)
          }
        })
      },
      pageChange(e) {
        this.queryParams.page = e
        this.getExpList()
      },
      getExpList() {
        this.$http.get('engineer/experience', {
          pageNum: this.queryParams.page,
          pageSize: this.queryParams.limit,
          engineerId: this.enginInfo.id
        }).then(res => {
          if (res.code == 200) {
            if (res.code == 200) {
              this.queryParams.totalPage = res.data.pages
			  
			  res.data.list.forEach((vo,index)=>{
			  	if(vo.evaluates2!=null){
			  		vo.uRate = (vo.evaluates2.attitude + vo.evaluates2.quality)/2;
					vo.uRamrk1 = vo.evaluates2.gcRemark;
					vo.uRamrk2 = vo.evaluates2.jlRemark;
			  	}else{
			  		vo.uRate = null;
					vo.uRamrk1 = "";
					vo.uRamrk2 = "";
			  	}
			  	
			  	if(vo.jlEvaluates!=null){
			  		let aa = JSON.parse(vo.jlEvaluates.evaluateContent);
			  		vo.mRate = (aa[0].isCheck +  aa[1].isCheck + aa[2].isCheck)/3;
					vo.mRamrk1 = aa[0].items[0].note;
					vo.mRamrk2 = aa[1].items[0].note;
					vo.mRamrk3 = aa[2].items[0].note;
			  	}else{
			  		vo.mRate = null;
					vo.mRamrk1 = "";
					vo.mRamrk2 = "";
					vo.mRamrk3 = "";
			  	}
			  })
			  
              this.expList = res.data.list
            }
          }
        })
      },
      //
      //更改擅长数据弹窗数据渲染
      listSelctShow(type) {
        // type//1项目类型 2擅长软件 3擅长专业
        this.addCheckForm.type = type
        if (type == 1) {
          this.addCheckForm.selectList = this.enginInfo.type
          this.checkTitle = '项目类型'
          this.checkList = this.objTypeList;
          this.chooseTip.max = 10;
          this.chooseTip.text = '（最多可选10个）';
        } else if (type == 2) {
          this.addCheckForm.selectList = this.enginInfo.software
          this.checkTitle = '擅长软件'
          this.checkList = this.softwareList;
          this.chooseTip.max = 5;
          this.chooseTip.text = '（最多可选5个）';
        } else {
          this.addCheckForm.selectList = this.enginInfo.major
          this.checkTitle = '擅长专业'
          this.checkList = this.majorList
          this.chooseTip.max = 5;
          this.chooseTip.text = '（最多可选5个）';
        }
        this.checkShow = true;
      },
      clearChooseTip(scene=0) {
        if (scene==1) {
          this.checkShow = false
        }
        this.chooseTip = {
          show: false,
          text: '',
          max: 20,
        }
      },
	  toDetails(item) {
		if(item.isEvaluateProject==1 || item.isEvaluateUser==1 ){
			this.$mHelper.to("/myProjectDetail?id=" + item.id + "&mrate=" + item.mangerEvaluate + "&urate=" + item.userEvaluate);
		}else{
			this.$mHelper.to("/myProjectDetail?id=" + item.id);
		}
	  },
      checkClose(done) {
        this.clearChooseTip();
        done();
      },
      checkboxChange(data) {
        //type 1项目类型 2擅长软件 3擅长专业
        var tipMap = [
          {
            type: 1,
            max: 10,
            text: '“项目类型”最多可选10个',
          },
          {
            type: 2,
            max: 5,
            text: '“擅长软件”最多可选5个'
          },
          {
            type: 3,
            max: 5,
            text: '“擅长专业”最多可选5个'
          },
        ];
        var changeTip = tipMap.find(item=>item.type==this.addCheckForm.type);
        if (changeTip&&data) {
          if (data.length==changeTip.max) {
            this.chooseTip.show = true;
            this.chooseTip.text = changeTip.text;
          }else{
            this.chooseTip.show = false;
          }
        }
      },
      //更改擅长数据弹窗数据更改
      selcetCheckTap() {
        if (this.addCheckForm.selectList.length < 1) {
          this.$message.error('请选择有效选项')
          return
        }
        this.loading = true;
        let data = JSON.parse(JSON.stringify(this.enginInfo))
        if (this.addCheckForm.type == 1) {
          data.type = this.addCheckForm.selectList
        } else if (this.addCheckForm.type == 2) {
          data.software = this.addCheckForm.selectList
        } else {
          data.major = this.addCheckForm.selectList
        }
        setTimeout(() => {
          this.$http.post('engineer/update', data).then(res => {
            if (res.code == 200) {
              this.$message.success('修改成功')
              this.getUserDetail();
              this.clearChooseTip(1);
            }
            this.loading = false
          }).catch(err => {
            this.loading = false
          })
        }, 500);
      },
      // 修改地址保存
      addressCheckSave() {
        if (this.addCheckForm.value.length < 1) {
          this.$message.error('请选择有效地址')
          return
        }
        this.loading = true;
        let data = JSON.parse(JSON.stringify(this.enginInfo))
        data.region = this.addCheckForm.value
        setTimeout(() => {
          this.$http.post('engineer/update', data).then(res => {
            if (res.code == 200) {
              this.$message.success('修改成功')
              this.getUserDetail();
              this.clearAreaTip(1);
            }
            this.loading = false
          }).catch(err => {
            this.loading = false
          })
        }, 500);
      },
      //点击修改地址
      addAlertTaps(item) {
        this.addCheckForm.value = this.enginInfo.region;
        this.addAlertShow = true;
      },
      areaChange(data){
         // text: '“擅长地区”最多可选8个'
        if (data) {
          this.areaTip.show = data.length == 8 ? true : false;
        }
      },
      clearAreaTip(secne=0) {
        if (secne==1) {
          this.addAlertShow = false;
        }
        this.areaTip = {
          show: false,
          text: '“擅长地区”最多可选8个',
          max: 8,
        }
      },
      areaClose(done) {
        done();
      },
      //工程造价从业年限弹窗
      yearShowTap() {
        this.yearNunber = this.enginInfo.years
        this.yearShow = true;
      },
      //工程造价从业年限保存
      yuearSaves() {
        if (!this.yearNunber) {
          this.$message.error('请输入工程造价从业年限')
          return
        }
        let data = JSON.parse(JSON.stringify(this.enginInfo))
        data.years = this.yearNunber
        this.loading = true;
        setTimeout(() => {
          this.$http.post('engineer/update', data).then(res => {
            if (res.code == 200) {
              this.$message.success('修改成功')
              this.getUserDetail();
              this.yearShow = false;
            }
            this.loading = false;
          }).catch(err => {
            this.loading = false;
          })
        }, 500);
      },
      // 单选修改
      radioChange(e, type) {
        // type//1是否可以现场工作 2有无团队
        let data = JSON.parse(JSON.stringify(this.enginInfo))
        if (type == 1) {
          data.necessary = e
        } else {
          data.team = e
        }
        this.$http.post('engineer/update', data).then(res => {
          if (res.code == 200) {
            this.$message.success('修改成功')
          } else {
            this.getUserDetail();
          }
        }).catch(err => {})
      },
	  //添加证书选择
	  selectZhengChange(e) {
		 this.zyTypeList.forEach(vo=>{
			 if(e==vo.dictValue){
				 this.selectAddZheng = vo.dictLabel;
			 }
		 })
	  },
      //执业资格修改
      selectChange(e) {
        let data = JSON.parse(JSON.stringify(this.enginInfo))
        data.practiceQua = e;
        this.$http.post('engineer/update', data).then(res => {
          if (res.code == 200) {
            this.$message.success('修改成功')
          } else {
            this.getUserDetail();
          }
          this.loading = false
        }).catch(err => {
          this.loading = false
        })
      },
      //添加和编辑工作经历
      expAddAlertTap() {
        this.$refs['expRef'].validate((valid) => {
          if (valid) {
            console.log(this.experiences.time);
            if (this.experiences.time.length < 1) {
              this.$message.error('请选择工作时间段')
              return
            }
            if (!this.experiences.achievement) {
              this.$message.error('请输入主要业绩')
              return
            }
			
			this.experiences.engineerId = this.enginInfo.id;
			
            this.loading = true
            if (this.expShowId >= 0) {
              //编辑
              this.experiences.frameDate = this.experiences.time.join('至')
              this.experiences.type = 2;
              this.$http.post('engineer/experience', this.experiences).then(res => {
                this.loading = false
                if (res.code == 200) {
                  this.$message.success('修改成功')
                  this.$refs['expRef'].resetFields(); //初始化数据
                  this.experiences.time = []
                  this.experiences.achievement = ''
                  this.expShow = false;
                  this.getUserDetail();
                }
              }).catch(err => {
                this.loading = false
              })
            } else {
              this.experiences.frameDate = this.experiences.time.join('至')
              this.experiences.type = 1;
              this.$http.post('engineer/experience', this.experiences).then(res => {
                this.loading = false
                if (res.code == 200) {
                  this.$message.success('添加成功')
                  this.$refs['expRef'].resetFields(); //初始化数据
                  this.experiences.time = []
                  this.experiences.achievement = ''
                  this.expShow = false;
                  this.getUserDetail();
                }
              }).catch(err => {
                this.loading = false
              })
            }
          }
        });
      },
	  
	  //添加和编辑项目经验
	  exproAddAlertTap() {
	    this.$refs['exproRef'].validate((valid) => {
	      if (valid) {
	        if (this.experienceProject.time.length < 1) {
	          this.$message.error('请选择时间段')
	          return
	        }
	        if (!this.experienceProject.remark) {
	          this.$message.error('请输入项目描述')
	          return
	        }
			
			this.experienceProject.engineerId = this.enginInfo.id;
			
	        this.loading = true
	        if (this.exproShowId >= 0) {
	          //编辑
	          this.experienceProject.workTime = this.experienceProject.time.join('至')
	          this.experienceProject.type = 2;
	          this.$http.post('engineer/experienceProject', this.experienceProject).then(res => {
	            this.loading = false
	            if (res.code == 200) {
	              this.$message.success('修改成功')
	              this.$refs['exproRef'].resetFields(); //初始化数据
				  this.experienceProject.projectName = ''
	              this.experienceProject.time = []
	              this.experienceProject.remark = ''
	              this.exproShow = false;
	              this.getUserDetail();
	            }
	          }).catch(err => {
	            this.loading = false
	          })
	        } else {
	          this.experienceProject.workTime = this.experienceProject.time.join('至')
	          this.experienceProject.type = 1;
	          this.$http.post('engineer/experienceProject', this.experienceProject).then(res => {
	            this.loading = false
	            if (res.code == 200) {
	              this.$message.success('添加成功')
	              this.$refs['exproRef'].resetFields(); //初始化数据
				  this.experienceProject.projectName = ''
	              this.experienceProject.time = []
	              this.experienceProject.remark = ''
	              this.exproShow = false;
	              this.getUserDetail();
	            }
	          }).catch(err => {
	            this.loading = false
	          })
	        }
	      }
	    });
	  },
	  
      // 个人介绍
      introductionTap() {
        if (!this.introduction) {
          this.$message.success('请输入个人介绍')
          return;
        }
        this.loading = true
        let data = JSON.parse(JSON.stringify(this.enginInfo))
        data.introduction = this.introduction;
        this.$http.post('engineer/update', data).then(res => {
          if (res.code == 200) {
            this.$message.success('修改成功')
            this.jsShow = false;
            this.getUserDetail();
          }
          this.loading = false
        }).catch(err => {
          this.loading = false
        })
        // this.formData.introduction = JSON.parse(JSON.stringify(this.introduction))
        // this.jsShow = false;
      },
      // alertEngin(){
      // 	let data=
      // },
      //添加证书
      addCertificate() {
		if(this.selectAddZheng==''){
			this.$message.error('请选择证书名称')
			return
		}else if(this.selectAddZheng=='其他'){
			if (!this.files.name) {
			  this.$message.error('请输入证书名字')
			  return
			}
		}else{
			this.files.name = this.selectAddZheng;
		}
        
        if (!this.files.url) {
          this.$message.error('请上传证书图片')
          return
        }
        this.loading = true;
        setTimeout(() => {
          let data = this.files;
          data.type = 1
          this.$http.post('/engineer/skill', data).then(res => {
            if (res.code == 200) {
              this.files.url = '';
              this.files.name = '';
			  this.selectAddZheng = '';
              this.certShow = false
              this.$message.success('添加成功')
              this.getUserDetail();
            }
            this.loading = false;
          })
        }, 500);
      },
      //删除证书
      detailsEngin(item, index) {
        const loading = this.$loading({
          lock: true,
          text: '删除中...',
          spinner: 'el-icon-loading',
          background: 'rgba(255, 255, 255, 0.8)'
        });
        setTimeout(() => {
          let data = item;
          data.type = 3
          this.$http.post('/engineer/skill', item).then(res => {
            if (res.code == 200) {
              this.$message.success('删除成功')
              this.enginInfo.files.splice(index, 1)
            }
            loading.close();
          }).catch(err => {
            loading.close();
          })
        }, 500);
      },
      //证书图片上传回调
      handleChange(e) {
        this.files.url = e.data
      },
      handleRemove(file, fileList) {
        this.files.url = ''
      },
      handleExceed(files, fileList) {
        this.$message.warning(`最多选择1张图片`);
      },
      beforeAvatarUpload(file) {
        var testmsg = file.name.substring(file.name.lastIndexOf('.') + 1)
        const extension = testmsg === 'jpg'
        const extension2 = testmsg === 'png'
        const extension4 = testmsg === 'jpeg'
        if (!extension && !extension2 && !extension4) {
          this.$message({
            message: '上传文件只能是 jpg, jpeg, png格式!',
            type: 'warning'
          })
          return false
        }
        return extension || extension2 || extension4
      },
      //编辑个人介绍弹窗
      updataIntro() {
        this.introduction = this.enginInfo.introduction || ''
        this.jsShow = true
      },
      //添加工作经验
      expUpdata(index, item) {
        if (index >= 0) {
          this.expShowId = index;
          this.experiences = JSON.parse(JSON.stringify(item))
          this.experiences.time = this.experiences.frameDate.split('至')
          this.expShow = true
        } else {
          this.expShowId = -1;
          this.$nextTick(() => {
            this.$refs['expRef'].resetFields(); //初始化数据
            this.experiences.time = []
            this.experiences.achievement = ''
          })
          this.expShow = true
        }
      },
      //删除
      expDetele(index, item) {
        let data = item
        data.type = 3
        this.$http.post('engineer/experience', item).then(res => {
          this.loading = false
          if (res.code == 200) {
            this.$message.success('删除成功')
            this.enginInfo.experiences.splice(index, 1)
          }

        })
      },
	  
	  //添加项目经验
	  exproUpdata(index, item) {
	    if (index >= 0) {
	      this.exproShowId = index;
	      this.experienceProject = JSON.parse(JSON.stringify(item))
	      this.experienceProject.time = this.experienceProject.workTime.split('至')
	      this.exproShow = true
	    } else {
	      this.exproShowId = -1;
	      this.$nextTick(() => {
	        this.$refs['exproRef'].resetFields(); //初始化数据
	        this.experienceProject.time = []
	        this.experienceProject.remark = ''
	      })
	      this.exproShow = true
	    }
	  },
	  //删除
	  exproDetele(index, item) {
	    let data = item
	    data.type = 3
	    this.$http.post('engineer/experienceProject', item).then(res => {
	      this.loading = false
	      if (res.code == 200) {
	        this.$message.success('删除成功')
	        this.enginInfo.experienceProject.splice(index, 1)
	      }
	  
	    })
	  },
	  
	  
      addZhenshu() {
        this.certShow = true
        this.$nextTick(() => {
          this.files.name = ''
          this.$refs['files'].clearFiles();
        })
      },

      getEchartData(datas) {
        let myChart = this.$echarts.init(document.getElementById('myChart'))
        // 绘制图表
        myChart.setOption({
          legend: {
            show: false
          },
          toolbox: {
            show: false,
            feature: {
              mark: {
                show: false
              },
              dataView: {
                show: true,
                readOnly: false
              },
              restore: {
                show: true
              },
              saveAsImage: {
                show: false
              }
            }
          },
          series: [{
            name: '',
            type: 'pie',
            radius: [45, 80],
            center: ['50%', '50%'],
            roseType: 'area',
            itemStyle: {
              borderRadius: 1
            },
            // color: ['#00AFC0', '#0090D4', '#185BA1'],
            data: datas
          }]
        });
      },
        getEchartData2(datas2) {
            console.log("datas2")
            console.log(datas2)
            var mCharts = this.$echarts.init(document.getElementById('container'))
			// 各个维度的最大值
            var dataMax = [
                {
                    name: '好评率',
                    max: 100
                },
                {
                    name: '及时率',
                    max: 100
                },
                {
                    name: '优秀率',
                    max: 100
                },
                {
                    name: '信誉值',
                    max: 100
                },
                {
                    name: '完成率',
                    max: 100
                },
            ]
            mCharts.setOption({
                radar: {
                    indicator: dataMax,  //配置各个维度的最大值
                },
                series:[
                    {
                        radius: [45, 80],
                        center: ['50%', '100%'],
                        type: 'radar',
                        data:
                            [
                            {
                                value: [datas2[0].hpl,datas2[0].jsl,datas2[0].yxl,datas2[0].xyz,datas2[0].wcl]                            }
                        ]
                    }
                ]
            })
        }
    }
  }
</script>


<style lang="scss" scoped>
.pages{
	width: 100%;
	padding: 0 0 0 45px;
	box-sizing: border-box;
	.engi-top{
		display: flex;
		justify-content: space-between;
		.left{
			.number{
				font-family: PingFangSC, PingFang SC;
				font-weight: 400;
				font-size: 16px;
				color: rgba(0,0,0,0.85);
				line-height: 21px;
				text-align: left;
				font-style: normal;
				margin-top: 24px;
			}
		}
		.tubiaoLienss {
		  width: 500px;
		}
	}
}	



	
	
	
	
  .el-tooltip__popper {

    width: 800px !important;
    background-color: #ffffff;
    color: #333;

  }

  .el-tooltip__popper {
    width: 80%;
    /*修改宽度*/
    box-shadow: 0 0 10px #999;
    background: #fff !important;
    /*背景色  !important优先级*/
    opacity: 0.7 !important;
    /*背景色透明度*/
    color: #333 !important;
    line-height: 20px;
    /*字体颜色*/

  }
</style>
<style lang="scss" scoped>
#myChart{

  display: flex;
  float: left;
}
#container{
  display: flex;
  float: right;

}
  ::v-deep .el-tooltip__popper {
    width: 100px !important;
  }

  // .el-tooltip__popper {
  //   max-width: 80%
  // }

  .puopContext {
	  margin-top: 0;
    p {
      margin-top: 5px;
    }
	.title{
		font-family: PingFangSC, PingFang SC;
		font-weight: 400;
		font-size: 16px;
		color: rgba(0,0,0,0.88);
		line-height: 22px;
		text-align: left;
		font-style: normal;
	}
  }

  .iconst {
    font-size: 18px;
    margin-top: 5px;
    color: #185BA1;
    position: relative;
    top: 5px;
  }

  ::v-deep .el-dialog--center .el-dialog__body {
    padding-top: 5px;
  }

  // ::v-deep .el-dialog__header {
  //   display: none !important;
  // }
  
  ::v-deep .el-dialog--center .el-dialog__body {
    padding-top: 10px;
  }

  ::v-deep  .el-rate__icon {
    font-size: 24px !important;
	// color: #d8d8d8 !important;
  }
  ::v-deep  .el-icon-star-on {
  	color: #FFB420;
  }

  .certificateBox {
    display: flex;
	
    &_lines {
      width: 135px;
      margin-top: 20px;
      margin-right: 18px;

      &_imgBox {
        position: relative;

        .iconbox {
          position: absolute;
          top: 0;
          right: 0;
          width: 16px;
          height: 16px;
          background: rgba(0, 0, 0, 0.4);
          text-align: center;
          line-height: 16px;
        }

        .el-icon-close {
          color: #fff;
          font-size: 13px;

        }
      }

      &_name {
        margin-top: 10px;
      }
    }
  }

  .expList {
	margin-bottom: 12px;
    .names {
       font-family: PingFangSC, PingFang SC;
       font-weight: 500;
       font-size: 16px;
       color: #3B77FF;
       line-height: 21px;
       text-align: left;
       font-style: normal;
    }
    .prices {
      font-size: 24px;
      font-weight: 550;
      color: #FF3B30;
    }
    .teims {
      font-size: 14px;
      color: #757575;
    }
	.commont-box{
		// min-height: 248px;
		background: #F7F7F7;
		border-radius: 4px;
		padding: 12px;
		margin-top: 12px;
		.commont-title{
			font-family: PingFangSC, PingFang SC;
			font-weight: 500;
			font-size: 14px;
			color: rgba(0,0,0,0.85);
			line-height: 20px;
			text-align: left;
			font-style: normal;
			margin-bottom: 8px;
		}
		.commontText {
		  font-family: PingFangSC, PingFang SC;
		  font-weight: 400;
		  font-size: 14px;
		  color: rgba(0,0,0,0.85);
		  line-height: 20px;
		  text-align: left;
		  font-style: normal;
		  margin-top: 8px;
		}
	}
    
  }


  .miaoshu {
    margin-top: 20px;
    font-size: 14px;
    color: #666;
  }

  .conTBVox {
    border-radius: 5px;
    padding: 20px;
    box-sizing: border-box;
    background-color: #FFFFFF;
  }

  .alertBtn {
    color: #666666;
  }

  .listLines {
    background-color: #fff;
    // margin-top: 20px;
    background-color: #fff;
    padding-top: 24px;
    box-sizing: border-box;

    .names {
      font-size: 16px;
    }

    .times {
      font-size: 14px;
      color: #757575;
    }

    .tags {
      background: #F5F7FA;
	  border-radius: 4px;
      color: #757575;
      margin-right: 8px;
      height: 28px;
      line-height: 28px;
	  font-family: PingFangSC, PingFang SC;
	  font-weight: 400;
	  font-size: 14px;
	  color: #555C70;
	  text-align: left;
	  font-style: normal;
	  margin-bottom: 10px;
    }
	
	.addrss {
	  font-size: 14px;
	  margin-left: 20px;
	  color: #666666;
	}
	
	.lfetInfo_lines {
	  display: flex;
	  margin-bottom: 12px;
	  .lfetInfo_lines_title {
	    margin-right: 8px;
		font-family: PingFangSC, PingFang SC;
		font-weight: 400;
		font-size: 16px;
		color: rgba(0,0,0,0.85);
		line-height: 21px;
		font-style: normal;
	  }
	}
	
	.conBox {
	  display: flex;
	  align-items: center;
	  justify-content: space-between;
	
	  .lfetInfo_lines {
	    display: flex;
	    // align-items: center;
	    margin-top: 10px;
	
	    .lfetInfo_lines_title {
	      margin-top: 2px;
	      width: 110px;
	      text-align: right;
	      margin-right: 20px;
	    }
	  }
	}
	
  }
  .person-info{
	  font-family: PingFangSC, PingFang SC;
	  font-weight: 400;
	  font-size: 16px;
	  color: rgba(0,0,0,0.85);
	  line-height: 21px;
	  text-align: left;
	  font-style: normal;
	  white-space: normal;
	  word-break: break-all;
  }
  .lc-list{
	  .lc-item{
		  margin-top: 24px;
		  .itemtitle{
			  display: flex;
			  justify-content: space-between;
			  .title{
				  font-family: PingFangSC, PingFang SC;
				  font-weight: 500;
				  font-size: 16px;
				  color: rgba(0,0,0,0.85);
				  line-height: 21px;
				  text-align: left;
				  font-style: normal;
				  .caozuo{
					  font-family: PingFangSC, PingFang SC;
					  font-weight: 400;
					  font-size: 14px;
					  color: #3B77FF;
					  line-height: 21px;
					  text-align: right;
					  font-style: normal;
					  margin-left: 24px;
				  }
			  }
			  .time{
				  font-family: PingFangSC, PingFang SC;
				  font-weight: 400;
				  font-size: 14px;
				  color: rgba(0,0,0,0.45);
				  line-height: 21px;
				  text-align: right;
				  font-style: normal;
			  }
		  }
		  .info{
			  font-family: PingFangSC, PingFang SC;
			  font-weight: 400;
			  font-size: 16px;
			  color: rgba(0,0,0,0.85);
			  line-height: 21px;
			  text-align: left;
			  font-style: normal;
			  margin-top: 12px;
			  white-space: normal;
			  word-break: break-all;
		  }
	  }
  }
  .lines-title .hengxian2 {
    width: calc(100% - 222px);
  }
  
::v-deep .el-dialog{
	background: #FFFFFF;
	box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.1), 0px 0px 5px 0px rgba(0,0,0,0.14);
	border-radius: 10px;
	.el-dialog__headerbtn{
		top: 30px;
	}
	.el-dialog__body{
		padding:0 32px 32px;
		.dialog-title{
			font-family: PingFangSC, PingFang SC;
			font-weight: 500;
			font-size: 18px;
			color: rgba(0,0,0,0.85);
			line-height: 25px;
			text-align: left;
			font-style: normal;
		}
		.dialog-info{
			font-family: PingFangSC, PingFang SC;
			font-weight: 400;
			font-size: 14px;
			color: rgba(0,0,0,0.45);
			line-height: 20px;
			text-align: left;
			font-style: normal;
		}
		.el-form-item__label{
			font-family: PingFangSC, PingFang SC;
			font-weight: 400;
			font-size: 14px;
			color: rgba(0,0,0,0.85);
			font-style: normal;
		}
		.el-form-item{
			display: flex;
			flex-flow: column;
			margin-bottom: 0;
			margin-top: 25px;
		   .el-form-item__label {
			  font-family: PingFangSC, PingFang SC;
			  font-weight: 400;
			  font-size: 16px;
			  color: rgba(0,0,0,0.85);
			  line-height: 20px;
			  text-align: left;
			  font-style: normal;
			}
			.el-form-item__content{
				margin-left: 0 !important;
				margin-top: 16px;
				.el-radio__label{
					font-family: PingFangSC, PingFang SC;
					font-weight: 400;
					font-size: 14px;
					color: rgba(0,0,0,0.88);
					line-height: 22px;
					text-align: left;
					font-style: normal;
					padding-left: 8px;
				}
				.el-radio__input.is-checked .el-radio__inner{
					border-color: #1677FF;
					background-color: #1677FF;
				}
				.el-input{
					min-width: 420px;
				}
			}
		}
	}
}
	
	
	.puopfootBox {
	  span {
	    color: #ff0000;
	    margin-right: 10px;
	  }
	}
	
</style>
