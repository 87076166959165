<template>
  <div class="boxCenter">
    <div class="searchBox ">
      <el-input placeholder="找人才，找项目，就来中一鼎合造价众包平台" placeholder-class="placeholderClass" v-model="searchText"
        @keyup.enter.native="searchTap">
        <i slot="prefix" class="el-input__icon el-icon-search" style="color: #185BA1;"></i>
        <template slot="append">
          <el-button type="primary" style="background-color: #185BA1;" @click='searchTap'>搜索</el-button>
        </template>
      </el-input>
    </div>

    <el-menu :default-active="activeIndex" class="el-menu-demo" mode="horizontal" @select="handleSelect">
      <el-menu-item index="1">项目（{{countItem.proNum||0}}）</el-menu-item>
      <el-menu-item index="2">人才（{{countItem.talenNum||0}}）</el-menu-item>
      <el-menu-item index="3">平台资讯（{{countItem.newNum||0}}）</el-menu-item>
    </el-menu>
    <div class="list">
      <template v-if="activeIndex==1">
        <div class="listLines list1" @click="toDetails(item)" v-for="(item,index) in list1" :key='index'>
          <div class="names line2">
            {{item.regionFy}}/{{item.typeFy}}
            <template>
              <span v-for="(row,index) in item.majorFy" :key="index+'q'">/{{row.name}}</span>
            </template>
            <span>
              /总投资:￥{{item.totalInvestment||'00'}}
            </span>
            <span>
              /总规模:{{item.totalScale}}m²
            </span>
            /{{item.serviceContentFy}}
          </div>
          <div class="tagsBox">
            <el-tag type="info" class="tags">{{item.regionFy}}</el-tag>
            <el-tag type="info" class="tags" v-if="item.necessary==1">需要出现场</el-tag>
            <el-tag type="info" class="tags" v-if="item.provided==1">提供CAD图纸</el-tag>
            <el-tag type="info" class="tags">{{item.typeFy}}</el-tag>
            <template>
              <el-tag type="info" class="tags" v-for="(row,index) in item.majorFy" :key="index+'w'">{{row.name}}
              </el-tag>
            </template>
            <el-tag type="info" class="tags" v-if="item.serviceContentFy">{{item.serviceContentFy}}</el-tag>
            <template>
              <el-tag type="info" class="tags" v-for="(row,index) in item.softwareFy" :key="index+'e'">{{row.label}}
              </el-tag>
            </template>
          </div>
          <div class="priceOrStatus">
            <span class="prices">
              总投资：￥{{item.totalInvestment||'0.00'}}
            </span>
            <span class="prices w-m-l-20" v-if="item.profitType==2">
              固定总价
            </span>
            <span class="status">
              工程师报名中
            </span>
          </div>
          <div class="times">
            交付时间：{{item.engineerDeadline}}
          </div>
        </div>
      </template>
      <template v-if="activeIndex==2">
        <div class="listLines list2" @click="toDetailsTalents(item)" v-for="(item,index) in list2" :key='index'>
          <div class="mdx-flex">
            <WzkAvatar :isGender="true" :imgSrc='item.headImgUrl' :gender="item.gender||1"></WzkAvatar>
            <span class="username">{{$mHelper.nameSlice(2,userInfo,item.name,item.name)}}</span>
            <span class="number">{{item.engineerNo}}</span>
            <span class="addrss">{{item.provinceFy}}·{{item.cityFy}}</span>
          </div>
          <div class="conBox">
            <div class="lfetInfo wzk-flex-1">
              <div class="lfetInfo_lines">
                <div class="lfetInfo_lines_title">项目类型</div>
                <div class="wzk-flex-1">
                  <el-tag type="info" class="tags" v-for="(item,index) in item.typeFy" :key="index+'t'">{{item.label}}
                  </el-tag>
                </div>
              </div>
              <div class="lfetInfo_lines">
                <div class="lfetInfo_lines_title">擅长软件</div>
                <div class="wzk-flex-1">
                  <el-tag type="info" class="tags" v-for="(item,index) in item.softwareFy" :key="index+'s'">
                    {{item.label}}
                  </el-tag>
                </div>
              </div>
              <div class="lfetInfo_lines">
                <div class="lfetInfo_lines_title">擅长地区</div>
                <div class="wzk-flex-1">
                  <el-tag type="info" class="tags" v-for="(item,index) in item.regionFy" :key="index+'e'">{{item.name}}
                  </el-tag>
                </div>
              </div>
              <div class="lfetInfo_lines">
                <div class="lfetInfo_lines_title">擅长专业</div>
                <div class="wzk-flex-1">
                  <el-tag type="info" class="tags" v-for="(item,index) in item.majorFy" :key="index+'m'">{{item.name}}
                  </el-tag>
                </div>
              </div>
            </div>
            <div class="rightBox">
              <div class="rightBox_lines">
                <div class="numbers">{{item.favorable||''}}</div>
                <div class="text">好评率</div>
              </div>
              <div class="rightBox_lines">
                <div class="numbers">{{item.completed||''}}</div>
                <div class="text">已完成</div>
              </div>
              <div class="rightBox_lines">
                <div class="numbers">{{item.timeliness||''}}</div>
                <div class="text">及时率</div>
              </div>
              <div class="rightBox_lines">
                <div class="numbers">{{item.reputation||''}}</div>
                <div class="text">信誉值</div>
              </div>
            </div>
          </div>
        </div>
      </template>
      <template v-if="activeIndex==3">
        <div class="listLines list3" v-for="(item,index) in list3" :key='index'
          @click="$mHelper.to('/imageTextDetail?types=9&id='+item.id)">
          <div class="leftImg">
            <el-image style="width: 234px; height: 117px" :src="item.img" :fit="fit"></el-image>
          </div>
          <div class="rightBox">
            <div class="names line3">
              {{item.title||''}}
            </div>
            <div class="times">
              交付时间：{{item.createTime||''}}
            </div>
          </div>
        </div>
      </template>
      <div class="mdx-flex mdx-row-center " style="margin-top: 30px;">
        <el-pagination :pageSize="queryParams.limit" :currentPage='queryParams.page' background
          layout="prev, pager, next" @current-change='pageChange' :page-count="queryParams.totalPage||1">
        </el-pagination>
      </div>
      <el-dialog :visible.sync="puopShow" width="40%" center top='30vh'>
        <div class="mdx-text-center">
          <h4>提示</h4>
          <div class="puopContext">
            <p>您还未注册工程师，是否立刻去注册？</p>
            <p>注册成功后即可申请项目！</p>
          </div>
        </div>
        <div class="puopfootBox">
          <el-button size="small" @click="puopShow = false;clickNum++">取 消</el-button>
          <el-button size="small" type="primary" @click="puopShow = false,$mHelper.to('/EngineerRegist')">去注册
          </el-button>
        </div>
      </el-dialog>

    </div>
  </div>
</template>

<script>
  import WzkAvatar from '../../../components/wzk-Avatar/index.vue'
  export default {
    components: {
      WzkAvatar

    },
    data() {
      return {
        searchText: '',
        puopShow: false,
        activeIndex: '1',
        list1: [],
        list2: [],
        list3: [],
        queryParams: {
          page: 1, //当前页数
          limit: 10, //每页条数
          totalPage: 1
        },
        //静态测试可删除
        clickNum: 1,
        countItem:{
          proNum:0,
          talenNum:0,
          newNum:0
        }
      }
    },
    created() {
      this.getList();
    },
    computed: {
      userInfo() {
        return this.$store.getters['userInfo']
      }
    },
    methods: {
      searchTap() {
        this.queryParams.page = 1
        this.getList();
      },
      pageChange(e) {
        if (this.activeIndex == 1) {
          this.queryParams.page = e
          this.getList()
        } else if (this.activeIndex == 2) {
          this.queryParams.page = e
          this.getList()
        } else {
          this.queryParams.page = e
          this.getList()
        }
      },
      getList() {
        if (this.activeIndex == 1) {
          this.getProjectList();
        } else if (this.activeIndex == 2) {
          this.getTalentsList();
        } else {
          this.getNewList();
        }
        this.getCount();
      },
      getNewList() {
        const loading = this.$loading({
          lock: true,
          text: '加载中...',
          spinner: 'el-icon-loading',
          background: 'rgba(255, 255, 255, 0.8)'
        });

        this.$http.get('/information/list', {
          pageNum: this.queryParams.page,
          pageSize: this.queryParams.limit,
          title: this.searchText
        }).then(res => {
          if (res.code == 200) {
            let list = []
            res.data.list.forEach((e, index) => {
              e.years = e.createTime.slice(0, 4)
              e.days = e.createTime.slice(5, 10)
              if (index != 0) {
                list.push(e)
              }
            })
            this.queryParams.totalPage = res.data.pages
            this.list3 = res.data.list
          } else {
            this.list3 = []
          }
          loading.close();
        }).catch(err => {
          loading.close();
        })
      },
      getCount() {
        this.$http.get('project/xmTj', {
          name: this.searchText
        }).then(res => {
          if (res.code == 200) {
            // countItem:{
            //   proNum:0,
            //   talenNum:0,
            //   newNum:0
            // }
            this.countItem={
              proNum:res.data.xm,
              talenNum:res.data.rc,
              newNum:res.data.zx,
            }
          } else {
            this.countItem={
              proNum:0,
              talenNum:0,
              newNum:0,
            }
          }
        })
      },
      getTalentsList() {
        const loading = this.$loading({
          lock: true,
          text: '加载中...',
          spinner: 'el-icon-loading',
          background: 'rgba(255, 255, 255, 0.8)'
        });

        this.$http.post('engineer/list', {
          pageNum: this.queryParams.page,
          pageSize: this.queryParams.limit,
          qname: this.searchText
        }).then(res => {
          if (res.code == 200) {
            this.queryParams.totalPage = res.data.pages
            this.list2 = res.data.list
          } else {
            this.list2 = []
          }
          loading.close();
        }).catch(err => {
          loading.close();
        })
      },
      getProjectList() {
        const loading = this.$loading({
          lock: true,
          text: '加载中...',
          spinner: 'el-icon-loading',
          background: 'rgba(255, 255, 255, 0.8)'
        });
        this.$http.post('project/list', {
          pageNum: this.queryParams.page,
          pageSize: this.queryParams.limit,
          qname: this.searchText
        }).then(res => {
          if (res.code == 200) {
            this.queryParams.totalPage = res.data.pages
            this.list1 = res.data.list
          } else {
            this.list = []
          }
          loading.close();
        }).catch(err => {
          loading.close();
        })
      },


      handleSelect(e) {
        console.log(e);
        this.activeIndex = e
        this.queryParams.page = 1
        this.getList();
      },
      toDetails(item) {
        this.$mHelper.to('/hallDetail?id=' + item.id)
      },
      toDetailsTalents(item) {
        let url = "?id=" + item.id + "&pathName=搜索" + "&pathUrl=/search"
        this.$mHelper.to('/talentsDetail' + url)
      }
    }
  }
</script>

<style lang="scss" scoped>
  // ::v-deep .el-dialog__header {
  //   display: none !important;
  // }

  ::v-deep .el-dialog--center .el-dialog__body {
    padding-top: 0;
  }


  .listLines {
    background-color: #fff;
    margin-top: 20px;
    background-color: #fff;
    padding: 20px;
    box-sizing: border-box;

    .names {
      font-size: 18px;
    }

    .times {
      font-size: 14px;
      color: #757575;
    }

    .tags {
      background: #EEEEEE;
      color: #757575;
      margin-right: 15px;
      height: 28px;
      line-height: 28px;
    }
  }

  .list1 {
    .times {
      font-size: 14px;
      color: #757575;
    }

    .tagsBox {
      margin-top: 10px;


    }

    .priceOrStatus {
      line-height: 50px;

      .prices {
        font-size: 18px;
        font-weight: 550;
        color: #FF3B30;
      }

      .status {
        margin-left: 30px;
        font-size: 14px;
        color: #FF3B30;
      }
    }
  }

  .list2 {
    .username {
      margin-left: 20px;
      font-size: 18px;
      font-weight: 550;
    }

    .number {
      margin-left: 20px;
    }

    .addrss {
      font-size: 14px;
      margin-left: 20px;
      color: #666666;
    }

    .conBox {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .lfetInfo_lines {
        display: flex;
        margin-top: 10px;

        .lfetInfo_lines_title {
          margin-top: 2px;
          width: 120px;
          text-align: right;
          margin-right: 20px;
        }
      }
    }

    .rightBox {
      display: flex;

      .rightBox_lines {
        padding: 0 20px;
        text-align: center;

        .numbers {
          font-size: 36px;
          font-weight: 550;
        }
      }

      .text {
        margin-top: 10px;
        font-size: 16px;
      }
    }
  }

  .list3 {
    display: flex;

    .rightBox {
      padding: 5px 0;
      margin-left: 20px;
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
  }

  .searchBox {
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 20px 0;

    .placeholderClass {
      color: #185BA1;
    }

    ::v-deep .el-input-group {
      width: 700px;
    }

    ::v-deep .el-input__inner {
      color: #185BA1;
      border: 1px solid #185BA1;
    }

    ::v-deep .el-input-group__append {
      border: 1px solid #185BA1;
      background-color: #185BA1;
    }

    ::v-deep ::-webkit-input-placeholder {
      color: #185BA1;
    }

    ::v-deep ::-moz-input-placeholder {
      color: #185BA1;
    }

    ::v-deep ::-ms-input-placeholder {
      color: #185BA1;
    }
  }

  ::v-deep .el-menu {
    background-color: #F3F7FA;
    padding-left: 30px;
  }

  ::v-deep .el-menu-item:hover {
    background-color: #F3F7FA !important;
  }

  ::v-deep .el-menu-item.is-active {
    background-color: #F3F7FA !important;
    color: #000;
    font-weight: 550;

    span {
      // color: #fff !important;
    }
  }
</style>
