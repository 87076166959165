<template>
  <div class="content">
    <div class="cbox">
      <div class="wary boxCenter">
        <div class="topNavClass">
          <img src="../../static/images/logo@2x.png" @click="$mHelper.to('/')" class="navlogo cursor">

          <div class="mdx-flex">
            <div class="linenavList">
              <div v-for="(item,index) in navList" :key='index' class="wzk-cursor" :class="paths==item.path?'active':''"
                @click="toPage(item)">
                {{item.label}}
              </div>
            </div>
            <div>
              <!-- <div @click="wordDetail">测试</div> -->
              <template v-if="userInfo.identity==3">
                <el-button type="primary" @click="$mHelper.to('/workbench')">工作台</el-button>
              </template>
              <template v-else>
                <el-button type="primary" @click="operateRelease">发布需求</el-button>
              </template>
            </div>
            <div class="infoBox">
              <div class="mdx-flex">
                <div class="user-avatar-box" style="margin-top: 5px;" @click="$mHelper.to('/myCenter')">
                  <!-- <el-image style="width: 30px; height: 30px;border-radius: 50%;" :src="url" fit="fill"></el-image> -->
                  <el-avatar :size="30" :src="userInfo.headImgUrl" @error="errorHandler">
                    <img src="../../static/images/morenImg.png" />
                  </el-avatar>
                  <div v-if="userInfo.certification==1" class="has-auth">
                    <img src="../../static/images/has_certified.png" alt="">
                  </div>
                </div>
                <template v-if="userInfo.identity==3">
                  <div class="niackName wzk-cursor" v-if="$store.state.token">
                    <el-dropdown @command="handleCommand">
                      <span class="el-dropdown-link">
                        {{userInfo.showName}}<i class="el-icon-arrow-down el-icon--right"></i>
                      </span>
                      <el-dropdown-menu slot="dropdown">
                        <el-dropdown-item command='/myCenter'>个人中心</el-dropdown-item>
                        <!-- <el-dropdown-item command='/setting'>设置</el-dropdown-item> -->
                        <el-dropdown-item command='outLogin'>退出登录</el-dropdown-item>
                      </el-dropdown-menu>
                    </el-dropdown>
                  </div>
                </template>
                <template v-if="userInfo.identity==2">
                  <div class="niackName wzk-cursor" v-if="$store.state.token">
                    <el-dropdown @command="handleCommand">
                      <span class="el-dropdown-link">
                        {{changeType == 1?userInfo.nickname:userInfo.showName}}<i class="el-icon-arrow-down el-icon--right"></i>
                      </span>
                      <el-dropdown-menu slot="dropdown">
                        <el-dropdown-item command='/myCenter'>个人中心</el-dropdown-item>
                        <el-dropdown-item command='/myPublishProject' v-if="changeType == 1">我的发布项目</el-dropdown-item>
                        <el-dropdown-item command='/myProject' v-if="changeType == 2">我参与的项目</el-dropdown-item>
                        <el-dropdown-item command='/wallet'>我的钱包</el-dropdown-item>
                        <el-dropdown-item command='/complaints' v-if="changeType == 1">项目投诉</el-dropdown-item>
                        <el-dropdown-item command='outLogin' style="color: #FF4D4F;">退出登录</el-dropdown-item>
						<el-dropdown-item command='changeCustom' class="tabjs" v-if="changeType == 2">切换为客户</el-dropdown-item>
						<el-dropdown-item command='changeGcs' class="tabjs"  v-if="changeType == 1">切换为工程师</el-dropdown-item>
                      </el-dropdown-menu>
                    </el-dropdown>
                  </div>
                </template>
                <template v-if="userInfo.identity==1">
                  <div class="niackName wzk-cursor" v-if="$store.state.token">
                    <el-dropdown @command="handleCommand">
                      <!-- <span class="el-dropdown-link">
                        {{userInfo.showName}}<i class="el-icon-arrow-down el-icon--right"></i>
                      </span> -->
                      <span class="el-dropdown-link">
                        {{userInfo.nickname}}<i class="el-icon-arrow-down el-icon--right"></i>
                      </span>
                      <el-dropdown-menu slot="dropdown">
                        <el-dropdown-item command='/myCenter'>个人中心</el-dropdown-item>
                        <el-dropdown-item command='/myPublishProject' v-if="identityS == 1">我发布的项目</el-dropdown-item>
                        <el-dropdown-item command='/wallet'>我的钱包</el-dropdown-item>
						<el-dropdown-item command='/complaints'>项目投诉</el-dropdown-item>
                        <!-- <el-dropdown-item command='/setting'>设置及更多</el-dropdown-item> -->
                        <el-dropdown-item command='/EngineerRegist' v-if="identityS == 1 && userInfo.rzStatus==0 && userInfo.bindAuthId==null">注册为工程师</el-dropdown-item>
                        <el-dropdown-item command='outLogin' style="color: #FF4D4F;">退出登录</el-dropdown-item>
                      </el-dropdown-menu>
                    </el-dropdown>
                  </div>
                </template>
                <div v-if="!$store.state.token" class="niackName wzk-cursor" @click="$mHelper.to('/login')">登录/注册</div>
                <!-- <div>邓工</div> -->
              </div>
            </div>
            <div @click="$mHelper.to('/informationList')" class="wzk-cursor" v-if="$store.state.token">
              <i class="el-icon-message-solid"></i>
              <span id="" v-if="$store.state.msgNum<99">
                （{{$store.state.msgNum}}）
              </span>
              <span id="" v-else>
                （99+）
              </span>
            </div>
          </div>
        </div>

      </div>
    </div>
    <div style="height: 64px;"></div>
	<!--发布方式-->
    <el-dialog title="" :visible.sync="centerDialogVisible" width="50%" center class="fb-tip">
      <div class="textStyle">请选择发布模式 </div>
      <div class="textStyle">(首次使用建议选择便捷发布)</div>
      <span slot="footer" class="dialog-footer">
        <el-button class="footer-btn-left" @click="convenientRelease">便捷发布</el-button>
        <el-button type="primary footer-btn-right" @click="releaseFn">发布</el-button>
      </span>
    </el-dialog>
	<!--实名认证-->
	<el-dialog title="" :visible.sync="certificationShow" width="50%" center class="fb-tip cert-tip">
		<img style="width: 95px; height: 95px;" src="../../static/images/home/cert@2x.png" />
		<div style="font-family: PingFangSC, PingFang SC;font-weight: 500;font-size: 18px;color: rgba(0,0,0,0.85);line-height: 36px;margin-top: 39px;">您当前未进行实名认证，请先完成实名认证</div>
		<span slot="footer" class="dialog-footer">
			<el-button class="footer-btn-left" @click="certificationShow=false">暂不认证</el-button>
			<el-button type="primary footer-btn-right" @click="goTocert">立即认证</el-button>
		</span>
	</el-dialog>
  </div>

</template>

<script>
  export default {
    data() {
      return {
        centerDialogVisible:false,
		certificationShow: false,
        url: 'https://img2.baidu.com/it/u=2761317825,1023537553&fm=26&fmt=auto',
        navList: [{
          label: '首页',
          path: '/'
        }, {
          label: '项目大厅',
          path: '/hall'
        }, {
          label: '人才库',
          path: '/talents'
        }, {
          label: '资讯',
          path: '/newsTrends'
        }, {
          label: '关于我们',
          path: '/AboutUs'
        }],
        changeType:2,
        identity:null,
        identityS:localStorage.getItem('identityS') || 1,
      }
    },
    computed: {
      userInfo() {
        return this.$store.getters['userInfo']
      },
      paths() {
        return this.$route.path
      }
    },
    created() {
      if(localStorage.getItem('changeType')){
        this.changeType = localStorage.getItem('changeType');
		console.log(this.changeType,'changeType');
      }
      if (this.$store.state['token']) {
        this.$uniApi.Logins(); //获取个人用户信息
        this.$http.get('notice/wdNum', ).then(res => {
          if (res.code == 200) {
            this.$store.state.msgNum = parseInt(res.data.projectNum) + parseInt(res.data.xtNum)
          }
        })
        // setTimeout(()=>{
        //   if(this.$store.state.userInfo.identity){
        //     this.identity = this.$store.state.userInfo.identity;
        //   }else{
        //     this.identity = JSON.parse(localStorage.getItem('userInfo')).identity;
        //   }
        //   localStorage.setItem('changeType',this.identity)
        //   this.changeType = this.identity;
        // },10);
      }
    },
    methods: {
      wordDetail() {
        // 微软提供的方法
        // window.open('https://view.officeapps.live.com/op/view.aspx?src=https://gczjzb-1308562443.cos.ap-nanjing.myqcloud.com/file/70821699260822152.pdf')
        window.open('https://gczjzb-1308562443.cos.ap-nanjing.myqcloud.com/file/70821699260822152.pdf')
      },
      //便捷发布
      convenientRelease(){
        this.centerDialogVisible = false;
        this.$router.push({
          path:'/release',
          query:{
            releaseType: 1
          }
        })
        if(this.$route.path == '/release'){
          setTimeout(()=>{
            this.$router.go(0)
          },10)
        }
      },
      // 发布
      releaseFn(){
        this.centerDialogVisible = false;
        this.$router.push({
          path:'/release',
          query:{
            releaseType: 2
          }
        })
        if(this.$route.path == '/release'){
          setTimeout(()=>{
            this.$router.go(0)
          },10)
        }
      },
      //发布需求
      operateRelease(){
        if(this.changeType == 2 && this.userInfo.identity == 2){
          this.$message.warning('请先切换为客户身份');
          return
        }
		
		if(this.userInfo.certification==1){
			this.centerDialogVisible = true;
		}else{
			this.certificationShow = true;	
		}
		
		
      },
      errorHandler() {
        return 'https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fb-ssl.duitang.com%2Fuploads%2Fitem%2F201811%2F19%2F20181119144210_FYE3Q.thumb.400_0.jpeg&refer=http%3A%2F%2Fb-ssl.duitang.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=jpeg?sec=1644062100&t=169d849ae1712e5a60a41841ded718a7'
      },
      toPage(item) {
        this.$mHelper.to(item.path)
      },
	  goTocert(){
		  this.certificationShow = false;	
		  this.$mHelper.to('/myCenter');
	  },
      handleCommand(e) {
        if(e == 'changeCustom'){
          localStorage.setItem('changeType',1)
          this.changeType = 1;
          this.$router.push({
            path: '/'
          });
          this.$message.success('已切换到客户身份')
          return
        }
        if(e == 'changeGcs'){
          localStorage.setItem('changeType',2)
          this.changeType = 2;
          this.$router.push({
            path: '/'
          })
          this.$message.success('已切换到工程师身份')
          return
        }
        if (e == 'outLogin') {
          this.$message.success('退出登录成功');
          this.$store.commit('outLogin');
          this.$store.state.msgNum = 0
          this.changeType = 2;
          this.$router.push({
            path: '/login'
          })
          localStorage.clear();
          return;
        }
        if (e == '/myCenter') {
          if (this.userInfo.identity == 2) {
            this.$router.push({
              //path: '/engineerInfo'
			  path: '/myCenter'
            })
          } else {
            this.$router.push({
              path: e
            })
          }
          return;
        }
        this.$router.push({
          path: e
        })
      },
    }
  }
</script>

<style lang="scss" scoped>
	.fb-tip{
		::v-deep .el-dialog{
			width: 414px !important;
			height: 235px;
			background: #FFFFFF;
			border-radius: 10px;
			backdrop-filter: blur(42px);
			.textStyle{
				font-family: PingFangSC, PingFang SC;
				font-weight: 500;
				font-size: 16px;
				color: rgba(0,0,0,0.85);
				line-height: 36px;
				text-align: left;
				font-style: normal;
			}
			.dialog-footer{
				display: flex;
				justify-content: center;
				.footer-btn-left{
					width: 96px;
					height: 40px;
					background: #FFFFFF;
					border-radius: 6px;
					border: 1px solid #4077DD;
					font-family: PingFangSC, PingFang SC;
					font-weight: 400;
					font-size: 16px;
					color: #4077DD;
					line-height: 24px;
					text-align: center;
					font-style: normal;
					display: flex;
					justify-content: center;
					align-items: center;
				}
				.footer-btn-right{
					width: 96px;
					height: 40px;
					background: #4077DD;
					border-radius: 8px;
					font-family: PingFangSC, PingFang SC;
					font-weight: 400;
					font-size: 16px;
					color: #FFFFFF;
					line-height: 24px;
					text-align: center;
					font-style: normal;
					display: flex;
					justify-content: center;
					align-items: center;
					margin-left: 24px;
				}
			}
			
		}
	}
	.cert-tip{
		::v-deep .el-dialog{
			width: 456px !important;
			height: 374px;
			.el-dialog__body{
				width: 100%;
				padding: 0;
				display: flex;
				flex-flow: column;
				align-items: center;
			}
			.el-dialog__footer{
				margin-top: 80px;
			}
		}
	}
	

  .content {
    z-index: 99999;
  }

  .topNavClass {
    height: 64px;
    display: flex;
    align-items: center;
    justify-content: space-between;
	::v-deep .el-button--primary {
		width: 88px;
		height: 32px;
		background: #10C550;
		border-radius: 6px;
		font-family: PingFangSC, PingFang SC;
		font-weight: 400;
		font-size: 14px;
		color: #FFFFFF;
		line-height: 22px;
		text-align: center;
		font-style: normal;
		display: flex;
		justify-content: center;
		align-items: center;
		border: 0;
	}  
  }

  .cbox {
    // box-shadow: 0 0 10px #ddd;
    position: fixed;
    width: 100%;
    background-color: #fff;
    top: 0;
    z-index: 99999;
  }

  .wary {
	height: 64px;
    margin-left: auto;
    margin-right: auto;
  }

  .navlogo {
    width: 225px;
    height: 46px;
  }

  .linenavList {
    display: flex;
    align-items: center;
	font-family: PingFangSC, PingFang SC;
	font-weight: 400;
	font-size: 14px;
	color: rgba(0,0,0,0.85);
	line-height: 20px;
	text-align: left;
	font-style: normal;
    .active {
      color: #4077DD;
      font-weight: 550;
    }
  }

  .linenavList>div {
    margin: 0 25px;

  }

  .infoBox {
    margin: 0 10px;
    margin-left: 40px;

    .user-avatar-box {
      position: relative;
      .has-auth {
        position: absolute;
        top: 20px;
        left: -2px;
        width: 34px;
        height: 10px;
        img {
          width: 100%;
          height: 100%;
        }
        // min-width: 62px;
        // padding: 1px 4px;
        // color: #FFFFFF;
        // font-size: 6px;
        // text-align: center;
        // white-space: nowrap;
        // background-color: #185BA1;
        // border-radius: 13px;
      }
    }
    .niackName {
      margin: 0 10px;
      font-size: 14px;
	  color: #4077DD;
    }
	
	
  }
</style>
