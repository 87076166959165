<template>
  <!-- 个人中心 -->
  <div class="boxCenter">
    <div class="topTitles">
      个人中心
    </div>
    <div class="leftList" style="display: flex;">
		<div class="leftBox">
			<div class="leftTop">
				<el-image class='' :src="userInfo.headImgUrl">
					<div slot="error">
						<img src="../../static/images/mrtx.png">
					</div>
				</el-image>
				<div class="">
					<div class="left-title line2">{{userInfo.nickname}}</div>
					<div class="left-wrz" v-if="userInfo.certification==2">未认证</div>
					<div class="left-qyrz" v-else-if="userInfo.rzStatus==2">企业认证</div>
					<div class="left-grrz" v-else-if="userInfo.certification==1">个人认证</div>
				</div>
			</div>  
		    <template v-if="userInfo.identity==1">
				<div v-for="(item,index) in menuList" :key='index' :class="item.select?'actives':''" @click="toPage(item)">
					<div class="leftTabs" :class="userInfo.authExamine!=null && userInfo.id!=userInfo.authExamine.mid && index==2 ? 'disNone' : index==1 ? 'disNone' : ''">
						<template>
							<img :src="item.icon" />
						   {{item.title}}
						</template>
					</div>
				</div>
			</template>
			<template v-else-if="userInfo.identity==2">
				<div v-for="(item,index) in menuList" :key='index' :class="item.select?'actives':''" @click="toPage(item)">
					<div v-if="changeType==2">
						<div class="leftTabs" v-if="item.tyle2==true ? 'disNone':'' ">
							<template>
								<img :src="item.icon" />
							    {{item.title}}
							</template>
						</div>
					</div>
					<div v-else>
						<div class="leftTabs" :class="index==1?'disNone':''">
							<template>
								<img :src="item.icon" />
							    {{item.title}}
							</template>
						</div>
					</div>
					
				</div>
			</template>
			<template v-else>
				<div v-for="(item,index) in menuList" :key='index' :class="item.select?'actives':''" @click="toPage(item)">
					<div class="leftTabs" :class="index==1?'disNone':index==2?'disNone':index==4?'disNone':index==5?'disNone':index==6?'disNone':''">
						<template>
							<img :src="item.icon" />
						    {{item.title}}
						</template>
					</div>
				</div>
			</template>
		</div>
		<div class="rightBox w-m-l-20">
			<router-view ref="refdata"></router-view>
		</div>
    </div>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        tabIndex: 0,
        menuList: [
			{
			  path: '/myCenter',
			  title: '账户信息',
			  select: false,
			  icon:  require('@/static/images/center/zhanghuxinxi@2x.png'),
			  tyle2: true,
			 
			},{
			  path: '/engineerInfo',
			  title: '工程师主页',
			  select: false,
			  icon:  require('@/static/images/center/gongchengshi@2x.png'),
			   tyle2: true,
			},{
			  path: '/certification',
			  title: '企业认证',
			  select: false,
			  icon:  require('@/static/images/center/qiye@2x.png'),
			},{
			  path: '/SecuritySet',
			  title: '安全设置',
			  select: false,
			  icon:  require('@/static/images/center/anquanshezhi@2x.png'),
			   tyle2: true,
			}, {
			  path: '/InvoiceSet',
			  title: '发票设置',
			  select: false,
			  icon:  require('@/static/images/center/fapiaoshezhi@2x.png'),
			}, {
			  path: '/BankSet',
			  title: '银行卡设置',
			  select: false,
			  icon:  require('@/static/images/center/yinhangka@2x.png'),
			  tyle2: true,
			}, 
			{
			  path: '/cancelAccount',
			  title: '注销账号',
			  select: false,
			  icon:  require('@/static/images/center/zhuxiaozhanghao@2x.png'),
			  tyle2: true,
			}
		],
		changeType: 2,
      }
    },

    computed: {
      userInfo() {
        return this.$store.getters['userInfo']
      },
      paths() {
        return this.$route.path
      }
    },
	created() {
		if(localStorage.getItem('changeType')){
		  this.changeType = localStorage.getItem('changeType');
		  console.log(this.changeType,'changeType')
		   console.log(this.userInfo,'userInfo')
		}	
	},
    watch: {
      paths() {
        this.selectUpdata();
      },
    },
    mounted() {
      this.selectUpdata();
    },
    methods: {
      toPage(item) {
        if (item.path) {
          this.$router.push({
            path: item.path
          })
        }
      },
      selectUpdata() {
        this.menuList.forEach(e => {
          if (this.$route.path == e.path) {
            e.select = true
          } else {
            e.select = false
          }
        })
      },
    }
  }
</script>

<style lang="scss" scoped>
.disNone{
	display: none !important;
}
.topTitles {
    font-family: PingFangSC, PingFang SC;
    font-weight: 600;
    font-size: 36px;
    color: rgba(0,0,0,0.85);
    line-height: 80px;
    text-align: left;
    font-style: normal;
	margin-top: 32px;
	margin-bottom: 32px;
}

.leftList{
	
}

.leftBox {
    border-radius: 5px;
    background-color: #fff;
    width: 210px;
	min-height: 700px;
    // padding-right: 30px;
    >div {
      cursor: pointer;
      line-height: 40px;
      font-size: 15px;
      color: #333333;
    }
	.leftTabs{
		width: 100%;
		height: 49px;
		background: rgba(255,255,255,0.04);
		border-radius: 8px;
		font-family: PingFangSC, PingFang SC;
		font-weight: 600;
		font-size: 18px;
		color: rgba(0,0,0,0.85);
		line-height: 25px;
		text-align: left;
		font-style: normal;
		display: flex;
		align-items: center;
		margin-bottom: 16px;
		img{
			width: 14px;
			height: 14px;
			margin-right: 12px;
			margin-left: 14px;
		}
	}
    .actives {
	  background: #EBF1FB;
	  border-radius: 8px;
    }
	
	.leftTop{
		display: flex;
		justify-content: flex-start;
		margin-bottom: 48px;
		::v-deep .el-image{
			img{
				width: 72px;
				height: 72px;
				border-radius: 5px;
				margin-right: 20px;
			}
		}
		.left-title{
			font-family: PingFangSC, PingFang SC;
			font-weight: 600;
			font-size: 18px;
			color: rgba(0,0,0,0.85);
			line-height: 25px;
			text-align: left;
			font-style: normal;
			width: 120px;
			white-space: normal;
			word-break: break-all;
		}
		.left-wrz{
			width: 64px;
			height: 28px;
			border-radius: 4px;
			border: 1px solid #4077DD;
			font-family: HYk1gj;
			font-size: 12px;
			color: #4077DD;
			line-height: 20px;
			font-style: normal;
			display: flex;
			justify-content: center;
			align-items: center;
			margin-top: 8px;
		}
		.left-grrz{
			width: 64px;
			height: 28px;
			background: linear-gradient( 180deg, #0D9AF6 0%, #267CFC 100%);
			box-shadow: 0px 0px 4px 0px rgba(43,104,255,0.59);
			border-radius: 4px;
			font-family: HYk1gj;
			font-size: 12px;
			color: #FFFFFF;
			line-height: 20px;
			font-style: normal;
			display: flex;
			justify-content: center;
			align-items: center;
			margin-top: 8px;
		}
		.left-qyrz{
			width: 64px;
			height: 28px;
			background: linear-gradient( 180deg, #F6A00D 2%, #FC8526 100%);
			box-shadow: 0px 0px 4px 0px rgba(255,140,43,0.59);
			border-radius: 4px;
			font-family: HYk1gj;
			font-size: 12px;
			color: #FFFFFF;
			line-height: 20px;
			font-style: normal;
			display: flex;
			justify-content: center;
			align-items: center;
			margin-top: 8px;
		}
	}
}
.rightBox{
	width: calc(100% - 230px);
	overflow: hidden;
}
</style>
