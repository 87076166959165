<template>
  <!-- 登录完填写信息 -->
  <div class="warys">
    <div class="boxCenter pagebox">
      <el-form label-position="right" label-width="120px" :model="formLabelAlign" :rules="rulesFrom" ref="ruleForm">
        <el-form-item label="头像:">
          <div class="inputRight mdx-flex">
            <div class="block">
              <!-- <el-image class='image-slot' src="./static/images/mrtx.png" v-if="!formLabelAlign.headImgUrl">
              </el-image> -->
              <!-- <img v-if="formLabelAlign.headImgUrl" :src="formLabelAlign.headImgUrl"
                style="width: 80px;height: 80px;border-radius: 5px;" class="avatar"> -->
              <el-image style="width: 80px;height: 80px;border-radius: 5px;" :src="formLabelAlign.headImgUrl">
                <div slot="error" class="image-slot">
                  <img src="../../static/images/mrtx.png" style="width: 80px;height: 80px;border-radius: 5px;">
                </div>
              </el-image>
            </div>
            <el-upload class="avatar-uploader" :action='action' :show-file-list="false"
              accept="image/jpg,image/jpeg,image/png" :on-success="handleAvatarSuccess"
              :before-upload="beforeAvatarUpload">
              <el-button type="primary" style="margin-left: 30px;" size="small">更换头像</el-button>
            </el-upload>
          </div>
        </el-form-item>
        <el-form-item label="手机号:">
          {{ formLabelAlign.mobile }}
        </el-form-item>
        <el-form-item label="昵称:" prop="nickname">
          <div class="inputRight">
            <el-input v-model="formLabelAlign.nickname" placeholder="请输入昵称"></el-input>
          </div>
        </el-form-item>
        <el-form-item label="性别:">
          <div class="inputRight">
            <template>
              <el-radio v-model="formLabelAlign.gender" :label="1">男</el-radio>
              <el-radio v-model="formLabelAlign.gender" :label="2">女</el-radio>
            </template>
          </div>
        </el-form-item>
        <template v-if="identity != 3">
          <el-form-item label="所在地区:" prop="address">
            <div class="inputRight">
              <el-cascader v-model="formLabelAlign.address" :options="addOptions" :props='props'
                @change="changeAddress">
              </el-cascader>
            </div>
          </el-form-item>
        </template>
        <el-form-item label="QQ号码:">
          <div class="inputRight">
            <el-input v-model="formLabelAlign.qq" placeholder="请输入QQ号码"></el-input>
          </div>
        </el-form-item>
        <el-form-item label="电子邮件:">
          <div class="inputRight">
            <el-input v-model="formLabelAlign.mailbox" placeholder="请输入电子邮件"></el-input>
          </div>
        </el-form-item>
        <!-- <el-form-item label="">
          <el-button type="primary" style="padding: 10px 40px;" @click="submitForm">提交</el-button>
        </el-form-item> -->
      </el-form>
      <div class="true-name-box">
        <div class="t-form-item m-flex auth-status">
          <div class="form-label">实名认证：</div>
          <div>未认证</div>
        </div>
        <div class="t-form-item">
          <div class="form-label label-1">上传身份证照片</div>
        </div>
        <div class="t-form-item m-flex">
          <div class="form-label"></div>
          <div class="m-flex">
            <el-upload class="auth-uploader" :action='action1' :data="{ type: 1 }" :show-file-list="false"
              :on-success="(response, file, fileList) => handleIdcardSuccess(response, 1)"
              accept="image/jpg,image/jpeg,image/png" :before-upload="beforeIdcarUpload">
              <img v-if="idCardInfo.portrait" style="width: 317px; height: 208px" :src="idCardInfo.portrait">
              <img v-else style="width: 317px; height: 208px" :src="tempIdInfo.portrait">
            </el-upload>
            <el-upload class="auth-uploader" :action="action1" :show-file-list="false" :data="{ type: 2 }"
              :on-success="(response, file, fileList) => handleIdcardSuccess(response, 2)"
              accept="image/jpg,image/jpeg,image/png" :before-upload="beforeIdcarUpload">
              <img v-if="idCardInfo.surface" style="width: 317px; height: 208px" :src="idCardInfo.surface">
              <img v-else style="width: 317px; height: 208px" :src="tempIdInfo.surface">
            </el-upload>
          </div>
        </div>
        <div class="t-form-item m-flex auth-status" v-if="idCardInfo.name">
          <div class="form-label label-1">姓名</div>
          <div class="no-edit label-1">{{ idCardInfo.name }}</div>
        </div>
        <div class="t-form-item m-flex auth-status" v-if="idCardInfo.idCard">
          <div class="form-label label-1">身份证号码</div>
          <div class="no-edit label-1">{{ idCardInfo.idCard }}</div>
        </div>
        <div class="t-form-item m-flex">
          <div class="form-label"></div>
          <el-button type="primary" style="padding: 10px 40px;" @click="submitForm">提交</el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import addOptions from '../../api/area.js'
import ruleData from '../../api/rules.js'
export default {
  data() {
    return {
      props: {
        label: 'cityName',
        value: 'code'
      },
      rulesFrom: ruleData.perfectInfo,
      action: this.$store.state.baseURL + 'upload/uploadCos',
      action1: this.$store.state.baseURL + 'engineer/idCardOCRCheck',
      addOptions: [], //地址
      radio: '1',
      idCardInfo: {
        portrait: '',//人像
        surface: '',//国徽
        name: '',//姓名
        idCard: '',//证件号码
      },
      tempIdInfo: {
        portrait: require('@/static/images/idcard_a.png'),//人像
        surface: require('@/static/images/idcard_b.png'),//国徽
      },
      formLabelAlign: {
        headImgUrl: '',
        nickname: '',
        mobile: '',
        qq: '',
        mailbox: '', //邮箱
        uuid: null,
        gender: 1,
        province: '',
        city: '',
        address: []
      },
      type: 1,
      identity: 1,
    }
  },
  created() {
    this.identity = this.$route.query.identity || 1
    this.formLabelAlign.uuid = this.$route.query.uuid || 0
    this.formLabelAlign.mobile = this.$route.query.mobile || ''
    this.type = this.$route.query.type || 1

    //
    this.$http.get('region/json').then(res => {
      if (res.code == 200) {
        this.addOptions = res.data
        console.log(this.addOptions);
      }
    })

    console.log(this.addOptions);
  },
  methods: {
    beforeIdcarUpload() {
      const h2 = this.$createElement;
      this.$message({
        duration: 1500,
        message: h2('p', null, [
          h2('span', { style: 'color: #185ba1' }, '正在上传… '),
        ])
      });
    },
    handleIdcardSuccess(res, type) {
      if (res.code != 200) {
        this.$message.error(res.msg)
        return
      }
      if (type == 1) {
        this.idCardInfo.portrait = res.data.url;
        this.idCardInfo.name = res.data.name;
        this.idCardInfo.idCard = res.data.idCard;
      } else {
        this.idCardInfo.surface = res.data.url;
      }
    },
    submitForm() {
      console.log(this.idCardInfo)
      this.$refs['ruleForm'].validate((valid) => {
        if (valid) {
          for (const key in this.idCardInfo) {
            if (Object.hasOwnProperty.call(this.idCardInfo, key)) {
              const element = this.idCardInfo[key];
              if (element) {
                this.formLabelAlign[key] = element
              }
            }
          }
          console.log(this.formLabelAlign)
          this.$http.post('auth/saveProfile', this.formLabelAlign).then(res => {
            if (res.code == 200) {
              this.$message.success('登录成功')
              this.$store.commit('changeLogin', {
                token: res.data.token,
                userInfo: res.data.userInfo
              })
              this.$mHelper.to('/')
            }
          })
        }
      });
    },
    changeAddress(e) {
      this.formLabelAlign.province = e[0]
      this.formLabelAlign.city = e[1]
    },
    handleAvatarSuccess(e, e1, e2) {
      this.formLabelAlign.headImgUrl = e.data
    },
    beforeAvatarUpload(file) {
      var testmsg = file.name.substring(file.name.lastIndexOf('.') + 1)
      const extension = testmsg === 'jpg'
      const extension2 = testmsg === 'png'
      const extension4 = testmsg === 'jpeg'
      if (!extension && !extension2 && !extension4) {
        this.$message({
          message: '上传文件只能是 jpg, jpeg, png格式!',
          type: 'warning'
        })
        return false
      }
      return extension || extension2 || extension4
    }
  }
}
</script>

<style scoped lang="scss">
.image-slot {
  border-radius: 10px;
  width: 80px;
  height: 80px;
}

.image-error {
  width: 80px;
  height: 80px;
  background-color: #eee;
}

.warys {
  padding: 30px;
}

.pagebox {
  background-color: #fff;
  padding: 30px;
  box-sizing: border-box;
  border-radius: 10px;
}

.inputRight {
  width: 300px;
}

.true-name-box {
  color: #333333;

  .t-form-item {
    margin-bottom: 30px;

    &.auth-status {
      font-size: 20px;
      line-height: 28px;
    }

    .label-1 {
      font-size: 16px;
      line-height: 22.4px;
    }

    .form-label {
      width: 112px;
      margin-right: 25px;
      text-align: right;
    }

    .no-edit {
      color: #666666;
    }
  }

  .auth-uploader {
    margin-right: 60px;
  }

}
</style>
