/* 1.引入文件 */
import axios from 'axios' //引入 axios库
import store from '../store'
import qs from 'qs' //引入 node中自带的qs模块（数据格式转换）
import mHelper from '../common/helper.js'
import {
  Notification,
  MessageBox,
  Message
} from 'element-ui'

/* 2.全局默认配置 */
let baseURL
// 判断开发环境（一般用于本地代理）
// if (process.env.NODE_ENV === 'development') { // 开发环境
//   // baseURL = '/api' // 你设置的本地代理请求（跨域代理），下文会详细介绍怎么进行跨域代理
//   baseURL = 'http://zhongyitest.zhongsou.com/prod-api/api/'
//   // baseURL = 'http://192.168.31.8:8188/api/'
// } else { // 编译环境
//   if (process.env.type === 'test') { // 测试环境
//     baseURL = store.state['baseURL']
//   } else { // 正式环境
//     baseURL = store.state['baseURL']
//   }
// }
baseURL = process.env.VUE_APP_BASE_API
// 配置axios的属性
axios.defaults.timeout = 30000; // 请求超时时间1分钟
axios.defaults.baseURL = baseURL; // 你的接口地址
axios.defaults.responseType = "json";
axios.defaults.withCredentials = false; //是否允许带cookie这些

/*你也可以创建一个实例，然后在实例中配置相关属性，此方法和上面的方法一样，写法不同，怎么用随个人
 *喜好，我比较喜欢用这种方法，如下：
 */
const Axios = axios.create({
  baseURL: baseURL, // 后台服务地址
  timeout: 30000, // 请求超时时间1分钟
  responseType: "json",
  withCredentials: false // 是否允许带cookie这些
});

/* 3.设置拦截器 */
/*如果不是用创建实例的方式配置，那么下面的Axios都要换成axios,也就是文件开头你用import引入axios
时定义的变量*/
Axios.interceptors.request.use((config) => {
  //发送请求前进行拦截
  //  可在此处配置请求头信息
  var userToken = localStorage.getItem('token') ? localStorage.getItem('token') : null;
  config.headers["token"] = userToken;
  // config.headers["token"] = store.state.token;

  if (config.method == "post") {
    /*数据转换: axios post方式默认是json格式提交数据，如果使用application/x-www-form-urlencoded数据格式提交，要用qs.stringify()进行转换,个人建议不在拦截器中全局配置，因为不够灵活，还有一点是，如果
设置了重新请求的配置，那么重新请求时，请求体中的config里面的传参就会被再次进行qs.stringify()转
换，会使得参数丢失，造成请求失败。*/
    // config.data = qs.stringify(config.data)
  }
  return config;
}, (error) => {
  //console.log("错误的传参", 'fail');
  return Promise.reject(error)
})
Axios.interceptors.response.use((res) => {
  //请求响应后拦截
  if (res.config.responseType == "blob") {
    if (res.data.type === 'application/force-download') {
      return Promise.resolve(res)
    }else{
      if(res.data.msg) {
        Message.error(res.data.msg);
      }
      return Promise.reject(res)
    }

  } else if (res.data.code == 200) { // 对响应数据做些事
    return Promise.resolve(res.data)
  } else if (res.data.code == 4003) { // 对响应数据做些事
    return Promise.resolve(res.data)
  } else if (res.data.code == 1001) { // 对响应数据做些事
    return Promise.resolve(res.data)
  } else if (res.data.code == 403) {
    store.commit('outLogin')
    mHelper.to('/')
  } else {
    if(res.data.msg) {
      Message.error(res.data.msg);
    }
    return Promise.reject(res)
  }
  return res;
}, (error) => {

  //alert("网络异常!") 404等问题可以在这里处理
  Message.error('网络异常');
  return Promise.reject(error)
})
export default Axios
