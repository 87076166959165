<template>
    <div class="person-box">
		<div class="boxCenter">
		  <div class="searchBox">
		    <el-input placeholder="工程师编号或姓名" placeholder-class="placeholderClass" v-model="searchText" @keyup.enter.native="searchTap">
		      <i slot="prefix" class="el-input__icon el-icon-search" style="color: #4077DD"></i>
		      <template slot="append">
		        <el-button type="primary" style="background-color: #4077DD" @click="searchTap">搜索</el-button>
		      </template>
		    </el-input>
		  </div>
		  <div class="sxxBox">
		    <el-form label-position="right" label-width="80px" :model="formLabelAlign">
		      <div class="mingHeind">
		        <el-form-item label="专业" class="mingHeind">
		          <template>
		            <div class="" style="display: flex;">
		              <el-checkbox :indeterminate="isIndeterminate" v-model="specCheckAll" @change="speckAllChange">全选
		              </el-checkbox>
		              <div style="width: 20px;"></div>
		              <div style="flex: 1;">
		                <el-checkbox-group v-model="specCheck" @change="speckChange">
		                  <el-checkbox v-for="(item,index) in majorList" :label="item" :key="index+'b'">
		                    {{item.name}}
		                  </el-checkbox>
		                </el-checkbox-group>
		              </div>
		            </div>
		          </template>
		        </el-form-item>
				<el-form-item label="类型" class="mingHeind">
				  <template>
				    <div class="" style="display: flex;">
				      <el-checkbox :indeterminate="isIndeterminate3" v-model="typeCheckAll" @change="typeAllChange">全选
				      </el-checkbox>
				      <div style="width: 20px;"></div>
				      <div style="flex: 1;">
				        <el-checkbox-group v-model="typeCheck" @change="typeChange">
				          <el-checkbox v-for="(item,index) in typeList" :label="item" :key="index+'t'">
				            {{item.dictLabel}}
				          </el-checkbox>
				        </el-checkbox-group>
				      </div>
				    </div>
				  </template>
				</el-form-item>
		        <el-form-item label="地区" class="mingHeind">
		          <template>
		            <template>
		              <div class="" style="display: flex;">
		                <el-checkbox :indeterminate="isIndeterminate2" v-model="addCheckAll" @change="addAllChange">全选
		                </el-checkbox>
		                <div style="width: 20px;"></div>
		                <div style="flex: 1;">
		                  <el-checkbox-group v-model="addCheck" @change="addChange">
		                    <el-checkbox v-for="(item,index) in addList" :label="item" :key="index+'v'">
		                      {{item.cityName}}
		                    </el-checkbox>
		                  </el-checkbox-group>
		                </div>
		              </div>
		            </template>
		          </template>
		        </el-form-item>
				<el-form-item label="工作年限" class="mingHeind">
				  <template>
				    <template>
				      <div class="" style="display: flex">
				        <div style="flex: 1">
				          <el-radio-group v-model="timeCheck" @change="timeChange">
				            <el-radio v-for="(item, index) in timeList" :label="item" :key="index + 'o'">{{ item.time }}</el-radio>
				          </el-radio-group>
				        </div>
				      </div>
				    </template>
				  </template>
				</el-form-item>
				<el-form-item label="有无证书" class="mingHeind">
				  <template>
				    <template>
				      <div class="" style="display: flex">
						  <el-radio v-model="zhengCheck" @change="zhengAllChange">全选</el-radio>
				        <div style="flex: 1">
				          <el-radio-group v-model="zhengCheck" @change="zhengChange">
				              <el-radio v-for="(item, index) in zhengList" :label="item" :key="index + 'z'">{{ item.dictLabel }}</el-radio>
				          </el-radio-group>
				        </div>
				      </div>
				    </template>
				  </template>
				</el-form-item>
		      </div>
		    </el-form>
		  </div>
		  <div class="listbox">
			  <div class="list-top">
				  <div class="left">
					  <div class="left-item" :class="sortRule==1?'active-left':''" @click="activeRule(1)">智能排序</div>
					  <div class="left-item" :class="sortRule==2?'active-left':''" @click="activeRule(2)">评价优先</div>
					  <!-- <div class="left-item" :class="sortRule==3?'active-left':''" @click="activeRule(3)">综合评分优先</div> -->
					  <div class="left-item" :class="sortRule==4?'active-left':''" @click="activeRule(4)">接单数优先</div>
				  </div>
				  <div class="right">
					  共<span>{{totalCount || '0'}}</span>个人才
				  </div>
			  </div>
			  <div class="list-content">
				  <div class="listLines wzk-cursor" @click="toDetails(item)" v-for="(item,index) in list" :key='index'>
					  <div class="avatar">
						  <WzkAvatar :isGender="true" :imgSrc='item.headImgUrl' :gender="item.gender||1" :width="70" :height="70" :waryWidth="72" :waryHeight="72"></WzkAvatar>
					  </div>  
					  <div class="item-info">
						  <div class="person-top">
							  <div class="top-left">
								   <span class="username">{{$mHelper.nameSlice(2,userInfo,item.name,item.name)}}</span>
								   <span class="toptabs">{{item.engineerNo}}</span>
								   <span class="toptabs">{{item.provinceFy}}·{{item.cityFy}}</span>
								   <span class="toptabs">{{item.years || '0'}}年工作经验</span>
								   <span class="toptabs" v-if="item.files!=null">有证书</span>
								   <span class="toptabs" v-if="item.team==1">有团队</span>
								   <span class="toptabs" v-if="item.certification==1">已认证</span>
							  </div>
							  <div class="top-right">
							  		已完成{{item.completed || '0'}}	单			  
							  </div>
						  </div>
						  <div class="conBox">
						  	<div class="leftInfo">
						  		<div class="lfetInfo_lines">
						  			<div class="lfetInfo_lines_title ">擅长项目：</div>
						  			<div class="wzk-flex-1">
						  				<el-tag type="info" class="tags tags1" v-for="(item,index) in item.typeFy" :key="index+'t'">{{item.label}} </el-tag>
						  			</div>
						  		</div>
								<div class="lfetInfo_lines">
									<div class="lfetInfo_lines_title ">擅长专业：</div>
									<div class="wzk-flex-1">
										<el-tag type="info" class="tags tags2" v-for="(item,index) in item.majorFy" :key="index+'m'">{{item.name}}</el-tag>
									</div>
								</div>
						  		<div class="lfetInfo_lines">
						  			<div class="lfetInfo_lines_title ">擅长软件：</div>
						  			<div class="wzk-flex-1">
						  				<el-tag type="info" class="tags tags3" v-for="(item,index) in item.softwareFy" :key="index+'s'">{{item.label}}</el-tag>
						  			</div>
						  		</div>
						  		<div class="lfetInfo_lines">
						  			<div class="lfetInfo_lines_title ">擅长区域：</div>
						  			<div class="wzk-flex-1">
						  				<el-tag type="info" class="tags tags4" v-for="(item,index) in item.regionFy" :key="index+'e'">{{item.name}} </el-tag>
						  			</div>
						  		</div>
						  		
						      </div>
						  	<div class="rightBox">
						  		<div class="rightBox_lines">
									<div class="text">好评率</div>
						  			<div class="numbers">{{item.favorable||''}}</div>
						  		</div>
						  		<div class="rightBox_lines" style="margin-left: 59px;">
									<div class="text">综合评分</div>
						  			<div class="numbers">{{item.synthesize||''}}</div>
						  		</div>
						  	</div>
						  </div>
					  </div>
					  
				  </div>
			  </div>	  
		    
		    <div class="mdx-flex mdx-row-center queryParamss" style="margin-top: 30px;" v-if="list.length">
		      <el-pagination :pageSize="queryParams.limit" :currentPage='queryParams.page' background
		        layout="prev, pager, next" @current-change='pageChange' :page-count="queryParams.totalPage||1">
		      </el-pagination>
		    </div>
		  </div>
		</div>
	</div>
</template>

<script>
  import WzkAvatar from '../../components/wzk-Avatar/index.vue'
  import comData from '../../common/commonData.js'
  import addOptions from '../../api/area.js'
  export default {
    components: {
      WzkAvatar
    },
    data() {
      return {
		sortRule: 1,  
        majorList: [], //专业
		typeList: [], //擅长类型
        addList: [], //地区
        //
        searchText: '',
        formLabelAlign: {},

        specCheck: [],
        specCheckAll: false,
		
		typeCheck: [],
		typeCheckAll: false,

        //
        addCheck: [],
        addCheckAll: [],

        list: [],
        queryParams: {
          page: 1, //当前页数
          limit: 10, //每页条数
          totalPage: 1
        },
        isIndeterminate: false,
        isIndeterminate2: false,
		isIndeterminate3: false,
		//工作年限
		timeId: null,
		timeCheck: 0,
		timeList: [
		  {
		    id: 4,
		    time: "全选",
		  },
		  {
		    id: 1,
		    time: "0～5年",
		  },
		  {
		    id: 2,
		    time: "5～10年",
		  },
		  {
		    id: 3,
		    time: "10年以上",
		  },
		],
		totalCount: 0,
		//有无证书
		proPractice: null,
		zhengCheck: 0,
		zhengCheckAll: [],
		zhengList: [],
      }
    },
    computed: {
      userInfo() {
        return this.$store.getters['userInfo']
      }
    },
    created() {
      this.$http.get('region/list').then(res => {
        if (res.code == 200) {
          this.addList = res.data
        } else {
          this.$message.error('地址获取失败')
        }
      })
      //专业类型获取
      this.$http.get('dict_data/getMajors').then(res => {
        if (res.code == 200) {
          this.majorList = res.data
        } else {
          this.$message.error('专业获取失败')
        }
      })
	  //项目类型获取
	  this.$http.get('dict_data/getDictData', {
	    dictType: 'pro_type'
	  }).then(res => {
	    if (res.code == 200) {
	      this.typeList = res.data
	    } else {
	      this.$message.error('项目类型获取失败')
	    }
	  })
	  //擅执业资格获取
	  this.$http.get('dict_data/getDictData', {
	    dictType: 'zy_type'
	  }).then(res => {
	    if (res.code == 200) {
	      this.zhengList = res.data
	    } else {
	      this.$message.error('执业资格获取失败')
	    }
	  })
      this.getList();
      this.setKeyWords();
    },
    methods: {
		activeRule(e){
			if(this.sortRule!=e){
				this.sortRule = e;
				this.list = [];
				this.queryParams.page = 1;
				this.getList();
			}
		},	
		searchTap(){
			this.queryParams.page=1
			this.getList();
		},
		  //
		getList() {
			const loading = this.$loading({
			  lock: true,
			  text: '加载中...',
			  spinner: 'el-icon-loading',
			  background: 'rgba(255, 255, 255, 0.8)'
			});
			let major = []
			let region = []
			let types = []
			this.specCheck.forEach(e => {
			  major.push(e.id)
			})
			this.addCheck.forEach(e => {
			  region.push(e.code)
			})
			this.typeCheck.forEach(e => {
			  types.push(e.dictValue)
			})
			this.$http.post('engineer/list', {
			  pageNum: this.queryParams.page,
			  pageSize: this.queryParams.limit,
			  major: major,
			  region: region,
			  typeList: types,
			  qname:this.searchText,
			  status: this.sortRule,
			  proPractice: this.proPractice,
			  type: this.timeId,
			}).then(res => {
			  if (res.code == 200) {
				this.queryParams.totalPage = res.data.pages
				
				this.list = res.data.list
				this.totalCount = res.data.total;
			  } else {
				this.list = [];
				this.totalCount = 0;
			  }
			  loading.close();
			}).catch(err => {
			  loading.close();
			})
		},
		pageChange(e) {
			this.queryParams.page = e
			this.getList();
		},
		toDetails(item) {
			let url = "?id=" + item.id + "&pathName=人才库" + "&pathUrl=/talents"
			this.$mHelper.to('/talentsDetail' + url)
		},
		  //全选
		speckAllChange(val) {
			this.specCheck = val ? this.majorList : [];
			this.isIndeterminate = false;
			this.queryParams.page = 1
			this.getList();
		},
		speckChange(value) {
			let checkedCount = value.length;
			this.specCheckAll = checkedCount === this.majorList.length;
			this.isIndeterminate = checkedCount > 0 && checkedCount < this.majorList.length;
			this.queryParams.page = 1
			this.getList();
		},
		typeAllChange(val) {
			this.typeCheck = val ? this.typeList : [];
			this.isIndeterminate3 = false;
			this.queryParams.page = 1
			this.getList();
		},
		typeChange(value) {
			let checkedCount = value.length;
			this.typeCheckAll = checkedCount === this.typeList.length;
			this.isIndeterminate3 = checkedCount > 0 && checkedCount < this.typeList.length;
			this.queryParams.page = 1
			this.getList();
		},
		addAllChange(val) {
			this.addCheck = val ? this.addList : [];
			this.isIndeterminate2 = false;
			this.queryParams.page = 1
			this.getList();
		},
		addChange(value) {
			let checkedCount = value.length;
			this.specCheckAll = checkedCount === this.addList.length;
			this.isIndeterminate2 = checkedCount > 0 && checkedCount < this.addList.length;
			this.queryParams.page = 1
			this.getList();
		},
		  //工作年限
		timeChange(value) {
			if (value.id != 4) {
			  this.timeId = value.id;
			} else {
			  this.timeId = null;
			}
			this.queryParams.page = 1;
			this.getList();
		},
		zhengAllChange(val) {
			this.proPractice = null;
			this.queryParams.page = 1
			this.getList();
		},
		zhengChange(value) {
			this.proPractice = value.dictValue;
			this.queryParams.page = 1
			this.getList();
		},
		  
		setKeyWords() {
			let element = document.getElementsByTagName('meta');
			let elementArr = Array.from(element);
			let keyWordMeta = elementArr.find(item=>item.name=='keywords');
			if (keyWordMeta) {
			  keyWordMeta.content = "工程师,造价工程师,中一鼎合人才库,造价师人才库,中一鼎合工程师,中一鼎合造价师,哪里可以找造价师,灵活用工平台,造价咨询兼职,造价师线上人才库";
			}
		}
    }
  }
</script>

<style lang="scss" scoped>
.person-box{
	width: 100%;
	height: 100%;
	background-image: url('../..//static/images/project_bg@2x.jpg');
	background-repeat: no-repeat;
	background-size: 100% 900px;
	.searchBox {
		width: 100%;
		display: flex;
		justify-content: center;
		padding-top: 77px;
		::v-deep .el-input__inner {
			color: #4077DD;
			border: 1px solid #4077DD;
		}
		::v-deep .el-input-group__append {
			border: 1px solid #4077DD;
			background-color: #4077DD;
		}
		::v-deep ::-webkit-input-placeholder {
			color: #BFBFBF;
		}
		::v-deep ::-moz-input-placeholder {
			color: #BFBFBF;
		}
		::v-deep ::-ms-input-placeholder {
			color: #BFBFBF;
		}
	}
	.sxxBox {
	    min-height: 332px;
	    background-color: #FFFFFF;
	    box-sizing: border-box;
	    box-shadow: 0px 6px 10px 6px rgba(44,46,51,0.03), 6px 6px 20px 3px rgba(44,46,51,0.06);
	    border-radius: 8px;
	    margin-top: 48px;
	    padding-top: 20px;
	    padding-bottom: 1px;
	}
}


  .listbox {
    background-color: #fff;
    padding-bottom: 30px;
    margin-bottom: 30px;
    margin-top: 24px;
    min-height: 302px;
    box-shadow: 0px 6px 10px 6px rgba(44,46,51,0.03), 6px 6px 20px 3px rgba(44,46,51,0.06);
    border-radius: 8px;
	.list-top{
		width: calc(100% - 48px);
		height: 69px;
		border-bottom: 1px solid #E8E8E8;
		padding: 0 24px;
		display: flex;
		justify-content: space-between;
		align-items: center;
	   .left{
		   display: flex;
		   justify-content: flex-start;
		   align-items: center;
		   .left-item{
			   font-family: PingFangSC, PingFang SC;
			   font-weight: 400;
			   font-size: 16px;
			   color: rgba(0,0,0,0.85);
			   line-height: 22px;
			   text-align: left;
			   font-style: normal;
			   margin-right: 24px;
			   cursor: pointer;
		   }
		   .active-left{
			   font-weight: 600;
			   color: rgba(0,0,0,0.85);
		   }
	   }
	   .right{
		   font-family: PingFangSC, PingFang SC;
		   font-weight: 400;
		   font-size: 16px;
		   color: rgba(0,0,0,0.85);
		   line-height: 22px;
		   text-align: left;
		   font-style: normal;
		   span{
			   color: #4077DD;
			   padding: 0 6px;
		   }
	   }
	}
	.list-content{
		padding: 0 24px;
		.listLines {
			display: flex;
			justify-content: space-between;
			padding: 24px 0;
		   .avatar{
			   width: 88px;
		   }
		   .item-info{
			   width: calc(100% - 88px);
			   .person-top{
				   display: flex;
				   justify-content: space-between;
				   
				   .top-left{
					   display: flex;
					   justify-content: flex-start;
					   align-items: center;
					   .username{
						   font-family: PingFangSC, PingFang SC;
						   font-weight: 500;
						   font-size: 18px;
						   color: rgba(0,0,0,0.85);
						   line-height: 21px;
						   text-align: left;
						   font-style: normal;
						   margin-right: 12px;
					   }
					   .toptabs{
						   background: #F5F7FA;
						   border-radius: 4px;
						   padding: 1px 8px;
						   font-family: PingFangSC, PingFang SC;
						   font-weight: 400;
						   font-size: 12px;
						   color: #555C70;
						   line-height: 20px;
						   text-align: left;
						   font-style: normal;
						   margin-right: 8px;
					   }
				   }
				   .top-right{
					   font-family: PingFangSC, PingFang SC;
					   font-weight: 400;
					   font-size: 14px;
					   color: rgba(0,0,0,0.45);
					   line-height: 21px;
					   text-align: left;
					   font-style: normal;
				   }
			   }
			   .conBox {
			     display: flex;
			     align-items: center;
			     justify-content: space-between;
					.leftInfo{
						 width: calc(100% - 220px);
						 .lfetInfo_lines {
						    display: flex;
						    margin-top: 10px;
						 	.lfetInfo_lines_title {
						 		 width: 88px;
						 		 font-family: PingFangSC, PingFang SC;
						 		 font-weight: 400;
						 		 font-size: 16px;
						 		 color: rgba(0,0,0,0.85);
						 		 line-height: 22px;
						 		 text-align: left;
						 		 font-style: normal;
						 	}
						 	.tags{
						 		font-family: PingFangSC, PingFang SC;
						 		font-weight: 400;
						 		font-size: 12px;
						 		line-height: 20px;
						 		text-align: left;
						 		font-style: normal;
						 		border-radius: 4px;
						 		height: auto;
						 		padding: 0.6px 7px;
						 		margin-right: 8px;
								margin-bottom: 8px;
						 	}
						 	.tags1{
						 		background: rgba(16,197,80,0.1);
						 		color: #10C550;
						 	}
						 	.tags2{
						 		background: rgba(255,210,39,0.1);
						 		color: #FFB420;
						 	}
						 	.tags3{
						 		background: rgba(255,77,79,0.1);
						 		color: #FF4D4F;
						 	}
						 	.tags4{
						 		background: rgba(31,89,196,0.1);
						 		color: #4077DD;
						 	}
						 }
					}
					
					.rightBox {
					   display: flex;
					   .rightBox_lines {
						   .text {
								font-family: PingFangSC, PingFang SC;
								font-weight: 400;
								font-size: 14px;
								color: rgba(0,0,0,0.45);
								line-height: 20px;
								text-align: right;
								font-style: normal;
						   }
						 .numbers {
							font-family: PingFangSC, PingFang SC;
							font-weight: 400;
							font-size: 18px;
							color: #4077DD;
							line-height: 36px;
							text-align: right;
							font-style: normal;
						 }
					   }
					 
					}
			    }
		    }
		}
		
		
		
		
		
	}
  }


  .mingHeind {
    ::v-deep .el-form-item__content {
      line-height: 20px;
    }

    ::v-deep .el-form-item__label {
      line-height: 20px;
    }
  }


</style>
