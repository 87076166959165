<template>
  <div></div>
</template>

<script>
  export default {
    methods: {
      alertToast(content = '', title = '提示', color = '#000000') {
        const h = this.$createElement;
        console.log(h);
        this.$msgbox({
          title: title,
          message: h('p', {
            style: 'margin-bottom: 20px;color:'+color,
          }, [
            h('span', {}, content),
          ]),
          center: true,
          showCancelButton: true,
          showConfirmButton: false,
          cancelButtonText: '我知道了',
        }).then(action => {

        });
      },
    }
  }
</script>

<style scoped="scoped">
  ::v-deep .el-dialog__headerbtn .el-dialog__close {
    color: #FF0000;
  }
</style>
