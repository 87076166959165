import store from './../store'

import router from '../router'

export default {

  to(path) {
    router.push({
      path: path,
    })
  },
  rTo(path) {
    router.push({
      path: path,
    })
  },
  otherTo(url) {
    window.open(url)
  },
  mobileSlice(type = 1, data = '') {
    if (type == 3) { //工程师
		if (!data) {
		  return '--'
		}
        return data
    }else{
		return data.slice(0, 3)+ '****' + data.slice(7, 11)
	}
    return ''
  
  },
  qqSlice(type = 1, data = '') {
    if (type == 3) { //工程师
  		if (!data) {
  		  return '--'
  		}
        return data
    }else{
  		return data.slice(0, 3)+ '****' + data.slice(5, 11)
  	}
    return ''
  
  },
  
  emailSlice(type = 1, data = '') {
    if (type == 3) { //工程师
  		if (!data) {
  		  return '--'
  		}
        return data
    }else{
  		return data.slice(0, 3)+ '****' + data.slice(7, 20)
  	}
    return ''
  
  },
  
  nameSlice(type = 1, user = {}, nameNick = '', name = '') {

    if (type == 1) { //工程师
      return nameNick
    }
    if (type == 2) { //工程师
      if (!name) {
        return '未知'
      }
      if (user.identity == 3) {
        return name
      } else {
        return name.slice(0, 1) + '工'
      }
    }
    if (type == 3) { //工程师
    if (!name) {
      return '未知'
    }
      return name.slice(0, 1) + '经理'
    }
    return ''

  },
  usernameSlice(user = {}, nameNick = '', name = '') {
    if (user.identity == 3) {
      return name.slice(0, 1) + '经理'
    } else if (user.identity == 2) {
      return name.slice(0, 1) + '工'
    } else {
      return nameNick
    }
    if (type == 2) { //工程师
      if (user.identity == 3) {
        return name
      } else {
        return name.slice(0, 1) + '工'
      }
    }
    if (type == 3) { //工程师
      return name.slice(0, 1) + '经理'
    }
    return ''

  },
  // 去掉字符串中的空格
  trim(str) {
    if (!str) {
      return '';
    }
    return str.replace(/\s*/g, '');
  },

  // 判断两个对象是否相同
  isObjectValueEqual(x, y) {
    // 指向同一内存时
    if (x === y) {
      return true;
    } else if (
      typeof x == 'object' &&
      x != null &&
      typeof y == 'object' && y != null
    ) {
      if (Object.keys(x).length != Object.keys(y).length) return false;

      for (var prop in x) {
        if (y.hasOwnProperty(prop)) {
          if (!this.isObjectValueEqual(x[prop], y[prop])) return false;
        } else return false;
      }

      return true;
    } else return false;
  },
  mobileHide(cellValue) {
    if (Number(cellValue) && String(cellValue).length === 11) {
      var mobile = String(cellValue)
      var reg = /^(\d{3})\d{4}(\d{4})$/
      return mobile.replace(reg, '$1****$2')
    } else {
      return cellValue
    }
  }
};
