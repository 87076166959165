export default {
	list: [],
	specialty: [{
		label: '房建土建'
	}, {
		label: '钢结构'
	}, {
		label: '精装修'
	}, {
		label: '幕墙'
	}, {
		label: '房建安装（给排水、供暖、通风空调）'
	}, {
		label: '房建安装（电气、智能化）'
	}, {
		label: '市政土建'
	}, {
		label: '市政管网'
	}, {
		label: '市政电气和智能化'
	}, {
		label: '其它'
	}],
	typeList: [{
		label: '住宅'
	}, {
		label: '别墅'
	}, {
		label: '学校'
	}, {
		label: '医疗建筑'
	}, {
		label: '宾馆酒店'
	}, {
		label: '交通枢纽'
	}, {
		label: '场馆'
	}, {
		label: '商业'
	}, {
		label: '办公'
	}, {
		label: '厂房及配套建筑'
	}, {
		label: '物流仓储'
	}, {
		label: '单独地下室'
	}, {
		label: '污水处理厂'
	}, {
		label: '自来水厂'
	}, {
		label: '变电站'
	}, {
		label: '加油加气站'
	}, {
		label: '热力站'
	}, {
		label: '公交场站'
	}, {
		label: '垃圾站'
	}, {
		label: '公厕'
	}, {
		label: '市政工程'
	}, {
		label: '其他'
	}],
	serviceList: [{
		label: '工程量计算'
	}, {
		label: '工程量计算审核'
	}, {
		label: '编清单'
	}, {
		label: '清单编制审核'
	}, {
		label: '组价审核'
	}, {
		label: '工程量计算+编清单'
	}, {
		label: '（工程量计算+编清单）审核'
	}, {
		label: '工程量计算+组价'
	}, {
		label: '（工程量计算+组价）审核'
	}, {
		label: '编清单+组价'
	}, {
		label: '（编清单+组价）审核'
	}, {
		label: '工程量计算+编清单+组价'
	}, {
		label: '（工程量计算+编清单+组价）审核'
	}, {
		label: '概算编制'
	}, {
		label: '概算审核'
	}, {
		label: '其他'
	}],
	softwareList: ['广联达', '斯维尔', '鲁班', '品茗', '宏业', '新点', '一点智慧', '智多星', '算王', '晨曦', '广龙', '易达', '博奥', '福莱', '其它']
}
