<template>
  <div class="boxCenter w-m-b-32">

    <div class="conTBVox w-m-t-32 ">
      <el-menu :default-active="activeIndex" class="el-menu-demo" mode="horizontal" @select="handleSelect">
        <el-menu-item :index="1">
          新闻动态
        </el-menu-item>
        <el-menu-item :index="2">
          政策法规
        </el-menu-item>
        <el-menu-item :index="3">
          平台规则
        </el-menu-item>
      </el-menu>
      <div class="mdx-flex mdx-flex-wrap mdx-row-between ">
        <div class="listbox" v-for="(item,index) in list" :key='index'  @click="$mHelper.to('/imageTextDetail?types=9&id='+item.id)">
          <div>
            <img :src="item.img" style="width: 100%;height: 135px;">
          </div>
          <div class="mdx-flex listLines2">
            <div class="left">
              <div>
                <h2>{{item.years}}</h2>
              </div>
              <div class="times">{{item.days}}</div>
            </div>
            <div class="xians"></div>
            <div class="contentText" style="height: 50px;">
              <!-- <div class="titlesLines">CEO就新科技园落成发表重要讲话</div> -->
              <div class="line1">
                {{item.title||''}}
              </div>
              <div class="neirong line1">
                  {{item.brief||''}}
              </div>
            </div>
            <div class="icons">
              <i class="el-icon-right" style="font-size: 20px;color: #C5C5C5;"></i>
            </div>
          </div>
        </div>
        <div style="width: 24%;"  v-for="(item,index) in (4-(list.length%4))" :key='index' >
        </div>
      </div>
    </div>
    <div class="mdx-flex mdx-row-center " style="margin-top: 30px;" v-if="list.length">
      <el-pagination :pageSize="queryParams.limit" :currentPage='queryParams.page' background layout="prev, pager, next"
        @current-change='pageChange' :page-count="queryParams.totalPage||1">
      </el-pagination>
    </div>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        activeIndex: 1,
        list: [],
        queryParams: {
          page: 1, //当前页数
          limit: 12, //每页条数
          totalPage: 1
        },
      }
    },
    created() {
      this.getList();
      this.setKeyWords();
    },
    methods: {
      handleSelect(e) {
        this.activeIndex = e;
        this.queryParams.page = 1
        this.list = []
        this.getList();
      },
      pageChange(e) {
        this.queryParams.page = e
        this.getList();
      },
      getList() {
        this.$http.get('/information/list', {
          type: this.activeIndex,
          pageNum: this.queryParams.page,
          pageSize: this.queryParams.limit
        }).then(res => {
          if (res.code == 200) {
            let list = []
            res.data.list.forEach((e, index) => {
              e.years = e.createTime.slice(0, 4)
              e.days = e.createTime.slice(5, 10)
              if (index != 0) {
                list.push(e)
              }
            })
            this.queryParams.totalPage = res.data.pages
            this.list = res.data.list
          } else {
            this.list = []
          }
        })
      },
      setKeyWords() {
        let element = document.getElementsByTagName('meta');
        let elementArr = Array.from(element);
        let keyWordMeta = elementArr.find(item=>item.name=='keywords');
        if (keyWordMeta) {
          keyWordMeta.content = "造价新闻,造价行业,造价政策,造价行业发展前景,造价行业现状,中一鼎合新闻,中一鼎合最新动态,中一鼎合众包平台,中一鼎合线上业务,中一鼎合,造价咨询平台";
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  .listbox {
    width: 24%;
    margin-bottom: 20px;
    background-color: #fff;
    padding-bottom: 10px;

    .titles {
      font-weight: 550;
    }

    .content {
      font-size: 14px;
      margin-top: 20px;
    }
  }

  .listLines2 {
    text-align: center;
    margin-top: 10px;

    .left {
      width: 80px;
    }

    .times {
      font-size: 18px;
      color: #666;
      margin-top: 10px;
    }

    .xians {
      height: 30px;
      border-left: 1px solid #999;
    }

    .contentText {
      flex: 1;
      padding-left: 15px;

      .titlesLines {
        // font-size: 18px;
        // font-weight: 550;
      }

      .neirong {
        color: #757575;
        font-size: 14px;
        margin-top: 10px;
      }
    }
  }

  .icons {
    margin: 0 10px;
  }

  .conTBVox {}

  ::v-deep .el-menu {
    background-color: #F3F7FA;
    border: none;
  }

  ::v-deep .el-menu-item {
    background-color: #F3F7FA;
    border: none;
    border: none;
    color: #333;
  }

  ::v-deep .el-menu-item:hover {
    background-color: #F3F7FA !important;
    border: none;
  }

  ::v-deep .el-menu-item.is-active {
    background-color: #F3F7FA !important;
    color: #185BA1;
    font-weight: 550;
    border: none;

    span {
      // color: #fff !important;
    }
  }

  ::v-deep .el-menu--horizontal .el-menu-item:not(.is-disabled):focus,
  .el-menu--horizontal .el-menu-item:not(.is-disabled):hover {
    color: #185BA1;
  }
</style>
