<template>
  <div class="appWidth">
    <div class="mdx-relative bannerBoxsss">
      <el-carousel trigger="click" height="416px">
        <el-carousel-item v-for="(item, index) in bannerList" :key='index'>
          <el-image style="width: 100%; height: 100%;cursor: pointer;" :src="item.imgUrl" fit="fill"
            @click="$mHelper.to('/imageTextDetail?types=1&id=' + item.id)"></el-image>
        </el-carousel-item>
      </el-carousel>
    </div>
    <div class="">
      <div class="boxCenter">
        <h2 style="margin: 20px 0;">平台证书</h2>
        <div class="mdx-row-between" style="display: flex;width: 100%;" v-if="certificateList.length > 0">
          <div style="width: 29%;padding-top: 50px;margin-right: 30px;">
            <!-- <img class="certify-img" v-if="certificateList.length > 0" :src="certificateList[0].imgUrl" alt=""
              style="height: 247px;width: 375px;"> -->
            <el-image style="width: 100%; height:247px" :src="certificateList[0].imgUrl" fit="fill"></el-image>
          </div>
          <div style="width: 29%;">
            <!-- <img class="certify-img" v-if="certificateList.length > 1" :src="certificateList[1].imgUrl" alt=""
              style="height: 247px;width: 375px;"> -->
              <el-image style="width: 100%; height:247px" :src="certificateList[1].imgUrl" fit="fill"></el-image>
          </div>
          <div style="width: 29%;padding-top: 50px;margin-left: 30px;">
            <!-- <img class="certify-img" v-if="certificateList.length > 2" :src="certificateList[2].imgUrl" alt=""
              style="height: 247px;width: 375px;"> -->
              <el-image style="width: 100%; height:247px" :src="certificateList[2].imgUrl" fit="fill"></el-image>
          </div>
        </div>
      </div>
      <div class="ys_box boxCenter">
        <div class="leftBox">
          <h3 style="margin-bottom: 20px;">平台优势</h3>
          <p v-for="(item, index) in superiorityList" :key="index + 's'" class="line1">
            <template v-if="index < 10">
              {{ item.title }}
            </template>
          </p>

        </div>
        <div class="carouselBox lbIMgBox">
          <el-carousel class="carousel-wrap" height="100%">
            <el-carousel-item v-for="item in advantageBannerList" :key="item.id">
              <img :src="item.imgUrl" width="100%" height="100%">
            </el-carousel-item>
          </el-carousel>
        </div>
      </div>
      <div class="tongjiBox">
        <div class="boxCenter">
          <h2 style="padding: 30px 0;">服务对象</h2>
        </div>
        <div class="mdx-flex contentBoxs boxCenter mdx-row-between">
          <div class="tjiLines">
            <div class="num">
              <img src="../../../static/images/laji/duixiang1.png">
            </div>
            <div class="text">甲方</div>
          </div>
          <div class="tjiLines">
            <div class="num">
              <img src="../../../static/images/laji/duixiang2.png">
            </div>
            <div class="text">设计单位</div>
          </div>
          <div class="tjiLines">
            <div class="num">
              <img src="../../../static/images/laji/duixiang3.png">
            </div>
            <div class="text">施工单位</div>
          </div>
          <div class="tjiLines">
            <div class="num">
              <img src="../../../static/images/laji/duixiang4.png">
            </div>
            <div class="text">咨询公司</div>
          </div>
          <div class="tjiLines">
            <div class="num">
              <img src="../../../static/images/laji/duixiang5.png">
            </div>
            <div class="text">工程师</div>
          </div>
        </div>
      </div>
      <div class="titles boxCenter">
        <h2>服务范围</h2>
        <div class="mdx-flex fuwuBox mdx-row-between">
          <div v-for="(item, index) in serveList" :key='index'>
            <img :src="item.imgUrl">
          </div>
        </div>
      </div>

      <div class="titles boxCenter">
        <h2>项目案例</h2>
        <div class="mdx-flex fuwuBox ">
          <el-button :type="NavIndex == index ? 'primary' : ''" v-for="(item, index) in anliNavList" :key="index"
            @click='anliTap(index)'>{{ item.dictLabel }}</el-button>
        </div>
        <div class="mdx-flex mdx-row-between mdx-flex-wrap allistbox">
          <div class="anliLiens " v-for="(item, index) in anliList" :key='index'>
            <img :src="item.img">
            <div class="names">
              {{ item.title }}
            </div>
            <div class="futitiel">
              XXXX类XX平台
            </div>
          </div>
          <template v-if="anliList.length % 4 != 0">
            <div class="anliLiens " v-for="(item, index) in (4 - (anliList.length % 4))" :key="index = 's'">

            </div>
          </template>
        </div>
      </div>
      <div class="mdx-flex mdx-row-center queryParamss" style="margin-top: 30px;" v-if="anliList.length">
        <el-pagination :pageSize="queryParams.limit" :currentPage='queryParams.page' background
          layout="prev, pager, next" @current-change='pageChange' :page-count="queryParams.totalPage || 1">
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      searchText: '',
      bannerList: [],
      advantageBannerList: [], //平台优势轮播图
      certificateList: [], //证书图片
      lcList: [{
        text: '发布需求',
        img: require('../../../static/images/lcImg/lc1.png')
      }, {
        text: '需求审核',
        img: require('../../../static/images/lcImg/lc2.png')
      }, {
        text: '签约付款',
        img: require('../../../static/images/lcImg/lc3.png')
      }, {
        text: '派单作业',
        img: require('../../../static/images/lcImg/lc4.png')
      }, {
        text: '平台质控',
        img: require('../../../static/images/lcImg/lc5.png')
      }, {
        text: '项目完成',
        img: require('../../../static/images/lcImg/lc6.png')
      }, {
        text: '质保期',
        img: require('../../../static/images/lcImg/lc7.png')
      }],
      serveList: [],
      anliNavList: [],
      NavIndex: 0,

      anliList: [],
      queryParams: {
        page: 1, //当前页数
        limit: 10, //每页条数
        totalPage: 1
      },
      superiorityList: [],
    }
  },
  created() {
    //获取轮播图
    this.getBannerList();
    //平台优势文本
    this.$http.get('platform/list', {
      type: 1
    }).then(res => {
      if (res.code == 200) {
        this.superiorityList = res.data
      }
    })
    this.setKeyWords();
  },
  methods: {
    getBannerList() {
      this.$http.get('banner/getBanner', {
        showScene: 3
      }).then(res => {
        if (res.code == 200) {
          this.bannerList = res.data
        }
      })
      this.$http.get('banner/getBanner', {
        showScene: 2
      }).then(res => {
        if (res.code == 200) {
          this.advantageBannerList = res.data
          console.log(this.advantageBannerList)
        }
      })
      this.$http.get('banner/getBanner', {
        showScene: 7
      }).then(res => {
        if (res.code == 200) {
          this.certificateList = res.data
        }
      })
      this.$http.get('banner/getBanner', {
        showScene: 6
      }).then(res => {
        if (res.code == 200) {
          this.serveList = res.data
        }
      })
      this.$http.get('dict_data/getDictData', {
        dictType: 'pro_al_type'
      }).then(res => {
        if (res.code == 200) {
          this.anliNavList = res.data
          this.getAnliList();
        }
      })
    },
    anliTap(index) {
      this.NavIndex = index
      this.getAnliList();
    },
    pageChange(e) {
      this.queryParams.page = e
      this.getAnliList();
    },
    getAnliList() {
      this.$http.get('case/list', {
        pageNum: this.queryParams.page,
        pageSize: this.queryParams.limit,
        type: this.anliNavList[this.NavIndex].dictValue,
      }).then(res => {
        if (res.code == 200) {
          this.queryParams.totalPage = res.data.pages
          this.anliList = res.data.list
        }
        // loading.close();
      }).catch(err => {
        // loading.close();
      })
    },
    setKeyWords() {
        let element = document.getElementsByTagName('meta');
        let elementArr = Array.from(element);
        let keyWordMeta = elementArr.find(item=>item.name=='keywords');
        if (keyWordMeta) {
          keyWordMeta.content = "中一鼎合造价众包平台,中一鼎合线上业务,中一鼎合,造价咨询平台,中一鼎合工程管理有限公司,zhongyidinghe.cn,中一鼎合怎么样,中一鼎合可以做造价咨询吗,中一鼎合资质,中一鼎合业务,中一鼎合工程管理有限公司介绍,zhongyidinghe.com,北京市丰台区南四环西路128号诺德中心2号楼5层,中一鼎合官网";
        }
      }
  }
}
</script>

<style lang="scss" scoped>
@media screen and (max-width:1475px) {
  .certify-img {
    width: 100% !important;
  }
}

.bannerBoxsss {
  max-width: 80%;
  min-width: 1080px;
  height: 416px;
  margin-left: auto;
  margin-right: auto;

  ::v-deep .el-carousel__arrow {
    height: 50px !important;
    width: 50px !important;
    z-index: 9999999999;
  }

  ::v-deep .el-carousel__indicators--horizontal {
    .el-carousel__indicator--horizontal button {
      width: 10px;
      height: 10px;
      background: rgba(255, 255, 255, 0.7);
      border-radius: 50%;
    }

    .el-carousel__indicator--horizontal.is-active button {
      width: 10px;
      height: 10px;
      background: #ffffff;
      border-radius: 10px;
    }
  }
}

.allistbox {
  padding: 20px 30px;

  .anliLiens {
    background-color: #fff;
    text-align: center;
    width: 22%;
    margin-bottom: 40px;

    img {
      width: 100%;
      height: 180px;
    }

    .names {
      font-weight: 550;
      margin-top: 10px;
    }

    .futitiel {
      margin: 20px 0;
      font-size: 14px;
      color: #757575;
    }
  }
}

.searchBox {
  position: absolute;
  top: 10px;
  z-index: 99998;
  width: 100%;
  display: flex;
  justify-content: center;

  .placeholderClass {
    color: #185BA1;
  }

  ::v-deep .el-input-group {
    width: 700px;
  }

  ::v-deep .el-input__inner {
    color: #185BA1;
    border: 1px solid #185BA1;
  }

  ::v-deep .el-input-group__append {
    border: 1px solid #185BA1;
    background-color: #185BA1;
  }

  ::v-deep ::-webkit-input-placeholder {
    color: #185BA1;
  }

  ::v-deep ::-moz-input-placeholder {
    color: #185BA1;
  }

  ::v-deep ::-ms-input-placeholder {
    color: #185BA1;
  }
}








.lbIMgBox {
  ::v-deep .el-carousel__indicators--horizontal {
    position: absolute;
    top: 0;
    right: -200px;
    text-align: right;

    .el-carousel__indicator--horizontal button {
      width: 8px;
      height: 8px;
      background: #ffffff;
      border-radius: 50%;
      opacity: 0.5;
    }

    .el-carousel__indicator--horizontal.is-active button {
      width: 24px;
      height: 8px;
      background: #ffffff;
      opacity: 0.5;
      border-radius: 10px;
    }
  }
}

.fuwuBox {
  margin: 20px 0;

  img {
    width: 210px;
    height: 75px;
    border-radius: 5px;
  }

  .el-button {
    width: 144px;
  }
}

.tongjiBox {
  background-color: #fff;

  .contentBoxs {
    padding-bottom: 30px;

    .tjiLines {
      width: 25%;
      text-align: center;

      .num {
        font-size: 26px;
        font-weight: 550;

        img {
          width: 120px;
          height: 120px;
        }
      }

      .text {
        margin-top: 20px;
        font-size: 18px;
        color: #333333;
      }

      span {
        font-size: 14px;
        font-weight: 400 !important;
      }
    }
  }
}



.ys_box {
  display: flex;
  margin-top: 50px;
  color: #fff;
  position: relative;
  // padding: 0 30px;
  box-sizing: border-box;

  .leftBox {
    padding: 30px;
    box-sizing: border-box;
    line-height: 30px;
    // width: 60%;
    width: 38%;
    background: #185BA1;
  }

  .carouselBox {
    // position: absolute;
    // right: 50px;
    // top: 50px;
    // width: 40%;
    width: 62%;

    .carousel-wrap {
      height: 100%;
    }
  }
}





.titles {
  // margin: 30px 0;
  margin-top: 30px;
  margin-bottom: 30px;
}
</style>
