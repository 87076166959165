<template>
  <div class="boxCenter">
    <!-- 发布 -->
    <div class="tits">
    	<div class="tits-title">智能估价</div>
    	<div class="tits-info">
    		<div class="tits-left"></div>
    		基本信息
    		<div class="hengxian"></div>
    	</div>
    </div>
    <div class="conTBVox">
      <el-form label-position="top" label-width="300px" :model="formData" :rules="rulesForm" ref="releaseRef">
        <div class="mingHeind">
          <el-form-item label="项目类型" class="mingHeind w-m-t-24" prop="type">
            <el-radio v-model="formData.type" :label="item.dictValue" v-for="(item,index) in typeList" :key='index' @change="typeChage">
              {{item.dictLabel}}
            </el-radio>
          </el-form-item>
          <!-- <el-form-item label="所含专业(计算范围)" class="mingHeind" prop="major">
            <el-select v-model="formData.major" multiple placeholder="请选择" style="width: 50%;">
              <el-option v-for="item in majorList" :key="item.value" :label="item.name" :value="item.id">
              </el-option>
            </el-select>
          </el-form-item> -->
          <el-form-item label="所含专业(计算范围)" class="mingHeind" prop="majorListValue">
            <el-cascader style="width: 100%;" v-model="formData.majorListValue" :options="majorList"
              :show-all-levels="false" :props="{ multiple: true, checkStrictly: false,}" clearable></el-cascader>
          </el-form-item>
          <el-form-item label="总投资" class="mingHeind" prop="totalInvestment" v-if="typeAorB=='B'||typeAorB==''">
            <div class="mdx-flex">
              <el-input v-model="formData.totalInvestment" :maxlength="10"
                oninput="value=((value.replace(/[^0-9.]/g,'')).replace(/([0-9]+\.[0-9]{2})[0-9]*/,'$1'))"
                placeholder="请填写总投资"></el-input>
              <span class="dabwue"> 元</span>
            </div>
            <div class="w-font-14 error">
              提示：请填写所需服务对应工程范围的工程费总投资，如无数据可不填
            </div>
          </el-form-item>
          <el-form-item label="总规模" class="mingHeind" prop="totalScale" v-if="typeAorB=='A'||typeAorB==''">
            <div class="mdx-flex">
              <el-input v-model="formData.totalScale" :maxlength="10"
                oninput="value=((value.replace(/[^0-9.]/g,'')).replace(/([0-9]+\.[0-9]{2})[0-9]*/,'$1'))"
                placeholder="请填写建筑面积">
              </el-input>
              <span class="dabwue">m²</span>
            </div>
            <div class="w-font-14 error">
              提示: 请填写建筑面积，如无数据可不填
            </div>
          </el-form-item>
          <el-form-item label="总占地面积" class="mingHeind" prop="totalArea" v-if="typeAorB=='A'||typeAorB==''">
            <div class="mdx-flex">
              <el-input v-model="formData.totalArea" :maxlength="10"
                oninput="value=((value.replace(/[^0-9.]/g,'')).replace(/([0-9]+\.[0-9]{2})[0-9]*/,'$1'))"
                placeholder="请填写项目总占地面积"></el-input>
              <span class="dabwue">m²</span>
            </div>
            <div class="w-font-14 error">
              提示：请填写项目总占地面积，如无数据可不填
            </div>
          </el-form-item>
          <el-form-item label="服务内容" class="mingHeind" prop="serviceContent">
            <el-radio v-model="formData.serviceContent" :label="item.dictValue" v-for="(item,index) in serviceList"
              :key='index' >
              {{item.dictLabel}}
            </el-radio>
          </el-form-item>
          <el-form-item label="要求软件" class="mingHeind">
            <template>
              <el-checkbox-group v-model="formData.software" >
                <el-checkbox :label="item.dictValue" v-for="(item,index) in softwareList" :key='index' @change="softChange(item.dictValue)">
                  {{item.dictLabel}}
                </el-checkbox>
              </el-checkbox-group>
            </template>
          </el-form-item>
		  <el-form-item label="所在地区:" prop="region">
		    <div class="inputRight">
		      <el-cascader style="width: 420px;" v-model="formData.regionInt" :options="addOptions" :props='props' @change="changeAddress"></el-cascader>
		    </div>
		  </el-form-item>
          <el-form-item label="交付截止时间:" prop="deliveryDeadline">
            <div class="inputRight">
              <el-date-picker style="width: 420px;" v-model="formData.deliveryDeadline" :picker-options="pickerOptions" type="datetime" value-format="yyyy-MM-dd HH:mm" format="yyyy-MM-dd HH:mm" placeholder="选择日期时间">
              </el-date-picker>
            </div>
          </el-form-item>
          <el-form-item label="是否需要出现场:" style="display:none">
            <div class="mdx-flex">
              <div>
                <el-radio v-model="formData.necessary" :label="1" >是</el-radio>
                <el-radio v-model="formData.necessary" :label="2" >否</el-radio>
              </div>
              <div class="mdx-flex w-m-l-20" v-if="formData.necessary==1">
                <el-input type="text" onkeyup="value=value.replace(/[^\d]/g,'')" v-model="formData.days"
                placeholder="出现场天数" :maxlength="6"></el-input>
                <span class="day-unit">天</span>
              </div>
            </div>
          </el-form-item>
          <el-form-item label="是否提供CAD图纸:" prop="provided">
            <el-radio v-model="formData.provided" :label="1">有</el-radio>
            <el-radio v-model="formData.provided" :label="2">无</el-radio>
          </el-form-item>

          <el-form-item label="" class="mingHeind">
            <div class="subimtBtmn">
              <el-button type="primary" class="subimtBtmn_btn" :loading="loading" @click="releaseXuqiu">估价</el-button>
              <h2 class="error">智能估价金额：
                {{guPrice > 0 ? '¥'+guPrice : '暂无估价'}}
              </h2>
            </div>

          </el-form-item>
          <el-form-item label="" class="mingHeind">
            <div style="line-height: 20px; color: rgba(0,0,0,0.85); font-size: 14px;">
              当前价格为根据系统预估的服务费价格，具体报价受项目特点（包含但不限于单体数量、楼型差异等各种特征因素）影响，可能会与最终报价存在差异。如有疑问，可查看
              <span class="wzk-cursor" style="color: #4077DD;" @click="$mHelper.to('/xieyi?type=4')">《中一鼎合造价众包平台收费标准》</span>
              或联系客服电话：{{customerSerTel}}
            </div>
            <!-- <div style="line-height: 20px; color: #999;font-size: 14px;">
              当前价格为系统预估的服务费价格，经项目经理审核后的服务费暂估价将在“个人中心—我的项目—待付<br />
              款”中。服务费最终价格将根据完成作业后计算出的工程造价和收费标准得出。不计算工程造价的，按服务<br />
              费暂估价结算。如有疑问，可查看<span class="wzk-cursor blue" @click="$mHelper.to('/xieyi?type=4')">《鼎力工程造价一站式众包服务平台收费标准》</span>
            </div> -->

          </el-form-item>

        </div>
      </el-form>
    </div>
  </div>
</template>

<script>
  import comData from '../../common/commonData.js'
  import ruleData from '../../api/rules.js'
  export default {
    data() {
      return {
        pickerOptions: {
          disabledDate(v) {
            return v.getTime() < new Date().getTime() - 86400000;
          }
        }, //控制时间显示
        isKeepAlive: true,
        majorListValue: [],
        IconImg: require('../../static/images/fileIcon.png'),
        action: this.$store.state.baseURL + 'upload/uploadCos',
        uploading: false,
        loading: false,
        typeList: [], //擅长类型
        majorList: [], //专业类型获取
        serviceList: [], //服务内容
        softwareList: [], //要求软件
        checkList: [],
        props: {
          label: 'cityName',
          value: 'code'
        },
        customerSerTel: '',
        rulesForm: ruleData.releaseRules,
        addOptions: [], //地址
        files: [{}],
        formData: {
          regionInt: [],//所在地区
          majorListValue: [],//所含专业(计算范围)
          days: '', //出现场天数
          deliveryDeadline: "", //交付截止时间
          estimatedPrice: '', //预估价格（元）
          files: [], //上传附件
          major: [], //所含专业
          necessary: 2, //是否需要出现场（1.是，2.否）
          phone: "", //联系电话
          provided: 1, //是否提供CAD图纸（1有，2.无）
          qq: "", //QQ号码
          region: "", //所在地区(直接存储名称)
          remark: "", //项目描述
          serviceContent: '', //服务内容
          software: [], //要求软件
          totalArea: '', //总占地面积（m2）
          totalInvestment: '', //总投资(元)
          totalScale: '', //总规模（m2）
          type: '', //项目类型

        },
        // typeList: comData.typeList,
        // serviceList: comData.serviceList,
        check: [],
        options: [{
          value: '选项1',
          label: '黄金糕'
        }, {
          value: '选项2',
          label: '双皮奶'
        }, {
          value: '选项3',
          label: '蚵仔煎'
        }, {
          value: '选项4',
          label: '龙须面'
        }, {
          value: '选项5',
          label: '北京烤鸭'
        }],
        guPrice: 0,
        typeAorB:''
      }
    },
    created() {
      // if (!this.isKeepAlive) {
      //   return
      // }
      let queryData = this.$route.query;
      if (queryData.id) {

        this.$http.get('project/detail', {
          id: queryData.id
        }).then(res => {
          if (res.code == 200) {
            let data1 = []
            let data2 = []
            res.data.majorFy.forEach(e => {
              data1.push(e.id)
            })
            res.data.softwareFy.forEach(e => {
              data2.push(e.value)
            })
            res.data.major = data1
            res.data.software = data2
            if (res.data.region) {
              let str1 = res.data.region.substring(0, 2);
              let data3 = []
              data3.push(str1)
              data3.push(res.data.region)
              res.data.regionInt = data3
            }
            this.formData = res.data
          }
        })
      }
      //项目类型获取
      this.$http.get('dict_data/getDictData', {
        dictType: 'pro_type'
      }).then(res => {
        if (res.code == 200) {
          this.typeList = res.data
        } else {
          this.$message.error('项目类型获取失败')
        }
      })
      //专业类型获取
      this.$http.get('dict_data/majorList').then(res => {
        if (res.code == 200) {
          let list = []
          res.data.forEach(e => {
            if (e.children.length) {
              let children = []
              e.children.forEach(es => {
                children.push({
                  value: es.id,
                  label: es.name
                })
              })
              list.push({
                value: e.id,
                label: e.name,
                children: children
              })
            } else {
              list.push({
                value: e.id,
                label: e.name
              })
            }
          })
          this.majorList = list
        } else {
          this.$message.error('专业获取失败')
        }
      })
      //服務類容
      this.$http.get('dict_data/getDictData', {
        dictType: 'project_service_content'
      }).then(res => {
        if (res.code == 200) {
          this.serviceList = res.data
        } else {
          this.$message.error('服務類容获取失败')
        }
      })
      //地址获取
      this.$http.get('region/json').then(res => {
        if (res.code == 200) {
          this.addOptions = res.data
          //console.log(this.addOptions);
        }
      })
      //要求软件获取
      this.$http.get('dict_data/getDictData', {
        dictType: 'cc_rj'
      }).then(res => {
        if (res.code == 200) {
          this.softwareList = res.data
        } else {
          this.$message.error('要求软件获取失败')
        }
      })
      this.$nextTick(e => {
        this.$refs['releaseRef'].resetFields();
        this.formData.regionInt = []
      })
      //地址获取
      this.$http.get('/config/getByKey',{configKey: 'telephone_numbers'}).then(res => {
        if (res.code == 200) {
          this.customerSerTel = res.data.configValue
        }
      })
    },

    methods: {
      handleChangeData() {

      },
      releaseXuqiu() {
        // if (this.checkList.length < 1) {
        //   this.$message.error('请阅读并同意勾选《中一鼎合平台服务规则》')
        //   return
        // }
        this.$refs['releaseRef'].validate((valid) => {
          if (valid) {
            if (this.formData.necessary == 1 && !this.formData.days) {
              this.$message.error('请输入出现场天数')
              return
            }
            this.loading = true
            let dataLines = []
            this.formData.majorListValue.forEach(e => {
              dataLines.push(e[e.length - 1])
            })
            this.formData.major = dataLines;
            this.formData.estimatedPrice = this.guPrice > 0 ? this.guPrice : 0;
            console.log(this.formData)
            this.$http.post('project/assess', this.formData).then(res => {
              this.loading = false
              if (res.code == 200) {
                this.$message.success('估价成功');
                if (res.data) {
                  this.guPrice = Number(res.data).toFixed(2);
                }
                // if (this.formData.id) {
                //   this.$router.go(-1)
                // } else {
                //   this.$mHelper.to('/myPublishProject')
                // }
              }
            })
          } else {
            this.$message.warning('请完善信息');
            return false;
          }
        });
        //this.estimateMoney(); //为啥这样写，因为页面是我复制过来的
      },
      typeChage(e){
        // console.log(e);
        if(e<=13){
          this.typeAorB='A'
        }else{
          this.typeAorB='B'
        }
        // 类型选择
        //this.estimateMoney(); //为啥这样写，因为页面是我复制过来的
      },
      estimateMoney() {
        let dataLines = []
        this.loading = true
        this.formData.majorListValue.forEach(e => {
          dataLines.push(e[e.length - 1])
        })
        this.formData.major = dataLines;
        this.$http.post('project/assess', this.formData).then(res => {
          this.loading = false
          if (res.code == 200) {
            this.guPrice = res.data || 0
          }
        }).catch(err => {
          this.loading = false
        })
      },

      softChange(city) {
        //this.formData.software = this.formData.software.includes(city) ? [city] : [];
        //this.estimateMoney();
      },
      starUpload() {
        this.uploading = true
      },
      handleAvatarSuccess(response, file, fileList) {
        if (file.response.code == 200) {
          let data = {
            name: file.name,
            url: file.response.data
          }
          this.formData.files.push(data)
        } else {
          this.$message.error(file.response.msg)
        }
        this.uploading = false
      },
      changeAddress(e) {
        this.formData.region = e[1]
        //console.log(this.formData.region);
      },

    },

  }
</script>

<style lang="scss" scoped>
  .subimtBtmn {
    margin-top: 30px;
    display: flex;
    align-items: center;

    &_btn {
      padding: 12px 60px;
      font-size: 16px;
      margin-right: 30px;
    }
	.el-button{
		width: 96px;
		height: 40px;
		background: #4077DD;
		border-radius: 8px;
		font-family: PingFangSC, PingFang SC;
		font-weight: 400;
		font-size: 16px;
		color: #FFFFFF;
		line-height: 24px;
		font-style: normal;
		display: flex;
		justify-content: center;
		align-items: center;
	}
  }

  ::v-deep .el-form-item__label {
	   font-family: PingFangSC, PingFang SC;
	   font-weight: 400;
	   font-size: 16px;
	   color: rgba(0,0,0,0.85);
	   line-height: 20px;
	   text-align: left;
	   font-style: normal;
  }
  
  ::v-deep .el-radio__label{
	  font-family: PingFangSC, PingFang SC;
	  font-weight: 400;
	  font-size: 14px;
	  color: rgba(0,0,0,0.88);
	  line-height: 22px;
	  text-align: left;
	  font-style: normal;
	  padding-left: 8px;
  }

  .dabwue {
    margin-left: 10px;
    position: relative;
    // top: 10px;
  }

  .day-unit {
    margin-left: 10px;
  }

 .tits {
   margin-top: 32px;
 	.tits-title{
 		font-family: PingFangSC, PingFang SC;
 		font-weight: 600;
 		font-size: 36px;
 		color: rgba(0,0,0,0.85);
 		line-height: 80px;
 		text-align: left;
 		font-style: normal;
 	}
 	.tits-info{
 		display: flex;
 		align-items: center;
 		font-family: PingFangSC, PingFang SC;
 		font-weight: 500;
 		font-size: 16px;
 		color: rgba(0,0,0,0.85);
 		line-height: 22px;
 		text-align: left;
 		font-style: normal;
 		margin-top: 32px;
 		.tits-left{
 			width: 6px;
 			height: 18px;
 			background: #4077DD;
 			border-radius: 2px;
 			margin-right: 17px;
 		}
 		.hengxian{
 			width: calc(100% - 115px);
 			height: 1px;
 			background-color: #E8E8E8;
 			margin-left: 22px;
 		}
 	}
 }

  ::v-deep .el-tag.el-tag--info {
    background-color: #B5B5B5;
  }

  .conTBVox {
  
  }

  .certificateBox {
    display: flex;

    &_lines {
      width: 110px;
      margin-top: 20px;
      margin-right: 18px;
      text-align: center;

      &_imgBox {
        position: relative;

        .iconbox {
          display: none;
          position: absolute;
          top: 0;
          right: 0;
          width: 16px;
          height: 16px;
          background: rgba(0, 0, 0, 0.4);
          text-align: center;
          line-height: 16px;
        }

        .el-icon-close {
          color: #fff;
          font-size: 13px;

        }
      }

      &_name {
        line-height: 20px;
        word-break: break-all;
        text-align: center;
      }
    }
  }

  .certificateBox_lines_imgBox:hover {
    .iconbox {
      display: block;
    }
  }
</style>
