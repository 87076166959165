<template>
  <!-- 个人中心 -->
  <div class="boxCenter">
	<div class="top-box">
		<div class="topTitles">我参与的项目</div>
		<div class="searchBox">
			<el-input placeholder="项目搜索" placeholder-class="placeholderClass" v-model="searchText" @keyup.enter.native="searchTap">
				<i slot="prefix" class="el-input__icon el-icon-search" style="color: #4077DD"></i>
				<template slot="append">
					<el-button type="primary" style="background-color: #4077DD" @click="searchTap">搜索</el-button>
				</template>
			</el-input>
		</div>
	</div>    
	<div class="tabs-navs">
		<div class="item" v-for="(item, index) in menuList" :key="index + 'z'" :class="tabIndex == index ? 'actives' : ''" @click="selectUpdata(item, index)">
			{{ item.title }}({{item.num || '0'}})
		</div>
	</div>  
	<!-- 状态status（-1拆分项目，1.待审核，2.待付款，3.已发布(报名中)，4.作业中/待验收，5.已完成，6.投诉项目，9.已取消） -->
	<div class="work-list">
		<div class="item" v-for="item in list" :key="item.id">
			<div class="item-top" @click="toDetails(item)">
				<div class="title m-flex">
					<div class="ellipsis" style="width: 600px;">
						<!-- <span class="titTip">快捷发布</span> -->
						<span class="titTip" v-if="item.parentProjectId!=null && item.parentProjectId > 0">关联项目</span>
						<span>{{ item.regionFy }}/{{ item.typeFy }}</span>
						<template v-if="item.majorFy.length">
						   <span v-for="(row, index) in item.majorFy" :key="index">/{{ row.name }}</span>
						</template>
						<span> /总投资:￥{{ item.totalInvestment || "" }} </span>
						<span> /总规模:{{ item.totalScale }}m² </span>
						<span>/{{ item.serviceContentFy }}</span>
					</div>
					<span class="w-m-l-16" style="color: #10C550; font-size: 16px;" v-if="item.status == 5">圆满完成</span>
					<span class="w-m-l-16" style="color: rgba(0,0,0,0.45); font-size: 16px;" v-else-if="item.status == 9">已取消</span>
				</div>
				<div class="item-sn">项目编号:{{ item.sn }}</div>
			</div>
			<div class="item-center" @click="toDetails(item)">
				<div class="left">
					<div class="left-tags">
						<el-tag type="info" class="tags">{{ item.regionFy }}</el-tag>
						<el-tag type="info" class="tags" v-if="item.necessary == 1">需要出现场</el-tag>
						<el-tag type="info" class="tags" v-if="item.provided == 1">提供CAD图纸</el-tag>
						<el-tag type="info" class="tags">{{ item.typeFy }}</el-tag>
						<template v-if="item.majorFy.length">
							<el-tag type="info" class="tags" v-for="(row, index) in item.majorFy" :key="index + 'c'">
								{{ row.name }}
							</el-tag>
						</template>
						<el-tag type="info" class="tags" v-if="item.serviceContentFy">
							{{ item.serviceContentFy}}
						</el-tag>
						<template v-if="item.softwareFy.length">
							<el-tag type="info" class="tags" v-for="(rows, indexs) in item.softwareFy" :key="indexs + 'x'">
								{{ rows.label }}
							</el-tag>
						</template>
					</div>
					<div class="left-price" v-if="item.status != 9">
					  <span class="prices"> 工程师收益：{{ item.earningsAmount || "" }}</span>
					  <span class="prices w-m-l-20" v-if="item.projectType == 2"> 固定总价</span>
					</div>
				</div>
				<div class="right">
					<div class="" v-if="item.status == 1">
						<div class="status error w-m-l-10">
						  当前邀请已过期
						</div>
					</div>
					<div class="m-flex" v-if="item.status==3">
						<span class="status blue" v-if="item.status==3">工程师报名中</span>
						
						<div class="status blue w-m-l-10" v-if="item.status==3&&item.offlineStatus==1">
						  线下转账审核中
						</div>
						<el-popover placement="top" width="300" trigger="hover" v-else-if="item.status == 3 && item.offlineStatus == 3">
						  <div class="">{{item.offRemark || ''}}</div>
						  <span slot="reference">
						     <span class="status error w-m-l-10"> 线下转账审核未通过</span>
						  </span>
						</el-popover>
					</div>
					<div v-if="item.status == 5">
						<div class="rate-list">
							<div class="item-rate">
							   <div class="item-title">客户评分：</div>
							   <el-rate v-model="item.userEvaluate" disabled disabled-void-color="#D8D8D8"></el-rate>
							</div>
							<div class="item-rate">
								<div class="item-title">项目经理评分：</div>
								<el-rate v-model="item.mangerEvaluate" disabled disabled-void-color="#D8D8D8"></el-rate>
							</div>
						</div>
					</div>
					
				</div>
			</div>
			<div class="item-timeBtn">
				<div class="itme-time">
					<span class=""> 项目交付时间：{{ item.deliveryDeadline }}</span>
					<span class="w-m-l-30">提交成果时间：{{ item.engineerDeadline }}</span>
					<span v-if="[5].includes(menuList[tabIndex].status)" style="margin-left: 25px; color: #2BD181;">质保期剩余时间：{{item.warrantyDay}}</span>
				</div>
				<div class="itme-btn m-flex">
					<template v-if="item.status==3&&item.fgStatus==1">
					  <el-button size="small" class="pay-type-btn" @click.stop='refuseApplyBtn(item,index)'>拒绝申请</el-button>
					</template>
					<template v-if="item.status==3&&item.fgStatus==1">
					  <!-- 已发布待审核 -->
					  <el-button type="primary" @click.stop="applyBtnShow(item)">立即申请</el-button>
					</template>
					<template v-if="item.status==3&&item.fgStatus==3||item.status==3&&item.zpEngineer==2&&item.fgJurisdiction==1">
					  <!-- 已发布待审核 -->
					  <el-button @click.stop="cancelBtns(item)">取消接单</el-button>
					</template>
					<template v-if="item.status==4&&item.isBjJe==2">
					  <!-- 已发布待审核 -->
					  <el-button @click.stop="showPayType(item)">补缴质保金</el-button>
					</template>
					<template v-if="item.status==3&&item.fgStatus==3||item.status==3&&item.zpEngineer==2&&item.fgJurisdiction==1">
					  <!-- 已发布待审核 -->
					  <el-button type="primary" @click.stop='materialsbtnTap(item)'>查阅资料</el-button>
					</template>
					<template v-if="item.status==3&&item.fgStatus==3 && item.offlineStatus!=1||item.status==3&&item.zpEngineer==2&&item.fgJurisdiction==1 && item.offlineStatus!=1">
					  <!-- 已发布待审核 -->
					  <el-button type="primary" @click.stop='showPayType(item)'>支付质保金</el-button>
					</template>
					<template v-if="item.status==3&&item.fgJurisdiction==2 &&item.fgStatus==2 ">
					  <el-button @click.stop="cancelBtns(item)">取消报名</el-button>
					</template>
					<template v-if="item.status==4 && item.cancelOrder == null || item.status==4 && item.cancelOrder.type == 8 && item.cancelOrder.status == 3">
					   <el-button  class="pay-type-btn" @click.stop='openCaneal(item)'>申请取消项目</el-button>
					</template>
					<template v-if="tabIndex==2">
					  <!-- 已发布待审核 -->
					  <el-button type="primary" @click.stop='materialsbtnTap(item)'>查阅资料</el-button>
					</template>
					<template v-if="tabIndex==2">
					  <!-- 已发布待审核 -->
					  <el-button type="primary" @click.stop="submitChengguo(item)">提交成果</el-button>
					</template>
					<el-button size="small" type="primary" @click="lookBtn(item)" v-if="item.status == 5">查看账单</el-button>
				</div>
			</div>
			<div class="payContextBox" v-if="tabIndex == 2 && item.progress">
			  <!-- {{item.progress}} -->
			  <span>项目进展：</span>{{ item.progress.title }} {{ " " }}{{ item.progress.createTime }}
			</div>
			
			<img src="../../../static/images/project/type-icon-1@2x.png" class="top-left-tabs" v-if="item.status==4 && item.checkStatus == -1" />
			<img src="../../../static/images/project/type-icon-3@2x.png" class="top-left-tabs"  v-if="item.status==4 && item.checkStatus == 1 || item.status==4 && item.checkStatus == 2 || item.status==4 && item.checkStatus == 6" />
			<img src="../../../static/images/project/type-icon-2@2x.png" class="top-left-tabs" v-else-if="(item.status == 4 && item.checkStatus == 3) || (item.status == 4 && item.checkStatus == 5)" />
		</div>
		<div class="mdx-flex mdx-row-center" style="margin-top: 30px; margin-bottom: 32px;" v-if="list.length" >
		  <el-pagination :pageSize="queryParams.limit" :currentPage="queryParams.page" background layout="prev, pager, next" @current-change="pageChange" :page-count="queryParams.totalPage || 1"></el-pagination>
		</div>
	</div>
	  
    
    
    <el-dialog class="tipPopup" title="申请确认" :visible.sync="applyShow" width="40%" center top='20vh'>
      <div class=" ">
		  <div class="title">需在此时间前交付项目成果，请确认您可以完成工作。</div>
		  <div class="title" style="color: #FF4D4F;">项目交付时间：{{dataItem.engineerDeadline||''}}</div>
		  <div class="title w-m-t-12">工程师收益：{{dataItem.earningsAmount||'0.00'}}元</div>
		  <div class="title"> 缴纳质保金：{{dataItem.depositAmount||'0.00'}}元 </div>
			<!-- <div>
			  1、须在此时间前交付项目成果，点击“立即申请”表明您有意愿、有时间、有能力完成本项目。<br />
			  2、点击“立即申请”也表明您接受本项目的质量要求和平台作业质量规范并拥有要求的软件。<br />
			  3、点击“立即申请”也表明您同意对后续的资料进行保密。平台项目经理将根据系统匹配推荐依次向成功申请的工程师开放资料查阅权限，以便于工程师评估、接单。<br />
			  4、如您在查阅资料、支付质保金接单后放弃项目，将扣除您的项目质保金并限制您接单60天，同时将影响您的信誉值。<br />
			</div> -->
			<div class="info w-m-t-14">
			  收益为暂估收益，最终收益将在您计算出工程成果后确定
			</div>
			<div class="info">
			  质保金比例为您收益的10%，项目完成后2个月内如无质保问题，您的质保金将退还至您的账户
			</div>
			<div class="info">
			  如您在接单后取消接单或未完成工作，将扣除您的项目质保金并限制您接单2个月，同时将影响您的信誉值！
			</div>
			<div class="info">
			  点击确认申请表明您同意对后续的资料进行保密，并接受项目质量要求
			</div>
			<span slot="footer" class="dialog-bom mdx-flex mdx-row-center w-m-t-20">
				<el-button class="close" size="small" @click="applyShow=false">取 消</el-button>
			   <el-button :loading="loading" type="primary" class="" @click='applyBtnTap()'> 确认申请</el-button>
			</span>
      </div>
    </el-dialog>
	
    <el-dialog class="tipPopup" title="查阅资料"  :visible.sync="materialsShow" width="40%" center top='20vh'>
      <div class="puopContext w-font-16">
        <div class="mdx-flex mdx-row-center w-m-t-50 w-m-b-50">
          <el-button type="primary" :loading="loading" style="padding: 10px 30px;border-radius: 2px;" class="" @click='downWenjian()'> 下载资料</el-button>
        </div>
      </div>
    </el-dialog>
    <el-dialog class="tipPopup" :title="dataItem.isBjJe==2?'补缴质保金':'支付质保金'" :visible.sync="payTypeShow" width="40%" top='20vh'>
     
      <div class=" ">
        <div class="" style="font-family: PingFangSC, PingFang SC; font-weight: 400; font-size: 18px; color: rgba(0,0,0,0.85); line-height: 36px;">
          <div>
            {{dataItem.regionFy}}/{{dataItem.typeFy}}
            <!-- {{dataItem.majorFy}} -->
            <template v-if="dataItem.majorFy.length">
              <span v-for="(row,indexss) in dataItem.majorFy" :key="indexss+'t'">/{{row.name}}</span>
            </template>
            <span>
              /总投资:￥{{dataItem.totalInvestment||'00'}}
            </span>
            <span>
              /总规模:{{dataItem.totalScale}}m²
            </span>
            /{{dataItem.serviceContentFy}}
          </div>
        </div>
        <div v-if="dataItem.isBjJe==2">
          项目编号：{{dataItem.sn}}
        </div>
        <div class="">
          <el-checkbox-group v-model="paycheck" @change="bindCheckBox">
            <div class="payLines w-m-t-12">
              <el-checkbox label="1"><i class="wzk wzk-weixinPay" style="color: #3BB431; margin-right: 5px;"></i>微信支付</el-checkbox>
            </div>
            <div class="payLines w-m-t-12">
              <el-checkbox label="2"> <i class="wzk wzk-zhifubaoPay" style="color: #1677FF; margin-right: 5px;"></i>支付宝支付</el-checkbox>
            </div>
            <div class="payLines w-m-t-12">
              <el-checkbox label="3"> <i class="wzk wzk-xianxiaPay" style="color: #FD6727; margin-right: 5px;"></i>线下转账</el-checkbox>
            </div>
			<div class="payLines w-m-t-12">
			  <el-checkbox label="7"><i class="wzk wzk-xianxiaPay" style="color: #FF3B30; margin-right: 5px;"></i>余额支付</el-checkbox>
			</div>
          </el-checkbox-group>
        </div>
        <div class="mdx-flex mdx-row-right ">
          <div class="w-m-r-20 error">
            <h3>应付金额 ￥{{dataItem.isBjJe==2?dataItem.bjGcsJe:dataItem.depositAmount}}</h3>
          </div>
          <el-button type="primary" style="padding: 10px 30px;border-radius: 2px;" class="" @click='payEnd()'>
            去付款
          </el-button>
        </div>
      </div>
    </el-dialog>
    <el-dialog  class="tipPopup" title="支付提示"  :visible.sync="transShow" width="40%" center top='20vh'>
      <div class="puopContext" style="font-family: PingFangSC, PingFang SC; font-weight: 500; font-size: 18px; color: rgba(0,0,0,0.85); line-height: 36px;">

        <div>
          {{dataItem.regionFy}}/{{dataItem.typeFy}}
          <!-- {{dataItem.majorFy}} -->
          <template v-if="dataItem.majorFy.length">
            <span v-for="(row,indexss) in dataItem.majorFy" :key="indexss+'y'">/{{row.name}}</span>
          </template>
          <span>
            /总投资:￥{{dataItem.totalInvestment||'00'}}
          </span>
          <span>
            /总规模:{{dataItem.totalScale}}m²
          </span>
          /{{dataItem.serviceContentFy}}
        </div>
        <div class="error w-font-14">
            请将您要充值的金额在银行柜台、网银、手机银行转账至：
            ( 该账户为公司账户，仅支持对公转账。)
        </div>
		<div>银行开户名： {{ bankInfo.name || "" }}</div>
        <div>银行账号： {{ bankInfo.account || "" }}</div>
        <div>开户银行： {{ bankInfo.deposit || "" }}</div>
        <div style="height: 1px"></div>
        <div class="w-font-13">线下交易流程：</div>
        <div class="w-font-13">1.请在汇款后，保存汇款凭证</div>
        <div class="w-font-13">2.点击上传凭证按钮</div>
        <div class="w-font-13">3.上传转账凭证</div>
        <div class="w-font-13">
          4.平台审核通过后，此项目会进入到【进行中的项目】，平台开始工作
        </div>
        <div class="mdx-flex mdx-row-right w-m-t-10">
          <div class="w-m-r-20 error">
            <h3> 应付金额：￥{{ dataItem.isBjJe==2?dataItem.bjGcsJe:dataItem.depositAmount ||'0.00' }}</h3>
          </div>
          <el-button type="primary" style="padding: 10px 30px;border-radius: 2px;" class="" @click='upPingZheng()'>
            去付款
          </el-button>
        </div>
      </div>
    </el-dialog>
    <el-dialog  class="tipPopup"  title="凭证" :visible.sync="credentialsShow" show-cancel-button :before-close='handleClose' width="40%" center top='20vh'>
      <div class="puopContext w-font-13">
        <div style="font-family: PingFangSC, PingFang SC; font-weight: 400; font-size: 18px; color: rgba(0,0,0,0.85); line-height: 36px; margin-bottom: 20px;">
          <div>
            {{dataItem.regionFy}}/{{dataItem.typeFy}}
            <!-- {{dataItem.majorFy}} -->
            <template v-if="dataItem.majorFy.length">
              <span v-for="(row,indexss) in dataItem.majorFy" :key="indexss">/{{row.name}}</span>
            </template>
            <span>
              /总投资:￥{{dataItem.totalInvestment||'00'}}
            </span>
            <span>
              /总规模:{{dataItem.totalScale}}m²
            </span>
            /{{dataItem.serviceContentFy}}
          </div>
        </div>
        <div class="wzk-cursor">
          <img src="../../../static/images/jindu.png" style="width: 100%;">
        </div>
        <div>
          <el-form label-position="right" label-width="100px" :model="bankFrom" :rules="transferRules"
            ref="xianxiaPays">
            <el-form-item label="开户银行:" prop="bankDeposit">
              <el-input v-model="bankFrom.bankDeposit" placeholder="请输入开户银行"></el-input>
            </el-form-item>
            <el-form-item label="付款人账号:" prop="paymentAccount">
              <el-input v-model="bankFrom.paymentAccount" placeholder="请输入付款人账号"></el-input>
            </el-form-item>
            <!-- {{bankFrom.receipt}} -->
            <el-form-item label="上传回执单:" prop="receipt">
              <el-upload class="upload-demo" :action="action" :on-success="receiptSuccess" :on-remove="handleRemove"
                multiple :limit="1" :on-exceed="handleExceed" :file-list="fileList">
                <el-button size="small" type="primary">点击上传</el-button>
              </el-upload>
            </el-form-item>
          </el-form>
        </div>

        <div class="mdx-flex mdx-row-right w-m-t-20">
          <el-button type="primary" style="padding: 10px 30px;border-radius: 2px;" class="" @click='upataPingzheng()'>
            提交凭证
          </el-button>
          <el-button style="padding: 10px 30px;border-radius: 2px;" class="" @click='handleClose'>
            取消
          </el-button>
        </div>
      </div>
    </el-dialog>
    <el-dialog  class="tipPopup" :visible.sync="credSuccessShow" width="40%" center top='20vh'>
      <div class="puopContext w-font-13">
         <div style="font-family: PingFangSC, PingFang SC; font-weight: 400; font-size: 18px; color: rgba(0,0,0,0.85); line-height: 36px; margin-bottom: 20px;">
          <div>
            {{dataItem.regionFy}}/{{dataItem.typeFy}}
            <!-- {{dataItem.majorFy}} -->
            <template v-if="dataItem.majorFy.length">
              <span v-for="(row,indexss) in dataItem.majorFy" :key="indexss">/{{row.name}}</span>
            </template>
            <span>
              /总投资:￥{{dataItem.totalInvestment||'00'}}
            </span>
            <span>
              /总规模:{{dataItem.totalScale}}m²
            </span>
            /{{dataItem.serviceContentFy}}
          </div>
        </div>
        <div class="wzk-cursor">
          <img src="../../../static/images/jinduZhong.png" style="width: 100%;">
        </div>
        <div class="mdx-text-center" style="margin: 50px 0;">
          <i class="el-icon-success  w-font-120" style="color: #185BA1;"></i>
          <p>
            您已成功上传凭证！请等待平台审核
          </p>
        </div>
      </div>
    </el-dialog>
    <el-dialog  class="tipPopup" title="提交成果" :visible.sync="supplementShow" show-cancel-button :before-close='handleClose' width="40%" center top='20vh'>
      <div class="puopContext   w-font-16">
        <div>
          <div>
            {{dataItem.regionFy}}/{{dataItem.typeFy}}
            <!-- {{dataItem.majorFy}} -->
            <template v-if="dataItem.majorFy.length">
              <span v-for="(row,indexss) in dataItem.majorFy" :key="indexss">/{{row.name}}</span>
            </template>
            <span>
              /总投资:￥{{dataItem.totalInvestment||'00'}}
            </span>
            <span>
              /总规模:{{dataItem.totalScale}}m²
            </span>
            /{{dataItem.serviceContentFy}}
          </div>
        </div>
        <div class="w-m-t-10">
          <!--  -->
          <el-upload class="upload-demo" ref="files" :action="action" :on-success="cgSuccess" :on-remove="cgRemove"
            :on-progress="onProgress" :on-error="onError"
            multiple :limit="1" :on-exceed="handleExceed" :file-list="fileList">
            <el-button size="small" type="primary">点击上传</el-button>
            <span v-if="onPrepareFile" class="prepare-file">
            文件准备中，请稍后… 文件名末尾显示
            <i class="tip-success el-icon-circle-check"></i>
            才算上传成功
            </span>
          </el-upload>
        </div>
        <div class="mdx-flex mdx-row-center w-m-t-30">
          <el-button type="primary" style="padding: 10px 40px;border-radius: 2px;" class=""
            @click.stop="suppleUpdata()">
            提交
          </el-button>
        </div>
      </div>
    </el-dialog>
    <el-dialog class="tipPopup" title="微信支付" :visible.sync="WechatShow" show-cancel-button @close='wxPayPuopClose' width="700px" center top='20vh'>
      <div class="w-font-13">
        <div class="mdx-flex mdx-row-between" style="margin-top: 50px;">
          <div class="w-m-l-20">
            <div class="qrcode" id="qrcode" ref="qrCodeUrl">

            </div>
            <div>
              <el-button type="primary" style="width: 250px;border-radius: 0;">
                <div class="mdx-flex">
                  <i class="wzk wzk-full-screen" style="font-size: 30px;margin-right: 10px;"></i>
                  <div class="mdx-text-left">
                    请使用微信扫一扫<br />
                    扫描二维码支付
                  </div>
                </div>
              </el-button>
            </div>
          </div>
          <div class="w-m-r-50 ">
            <img src="../../../static/images/wxImg1.png" height="350px">
          </div>
        </div>
      </div>
    </el-dialog>
	
	<!--账单查询-->
	<el-dialog title="账单详情" :visible.sync="lookPrice"  width="40%" left top='10vh' class="look-box">
	  <div class="">
		  <div class="title">
			  {{dataItem.regionFy}}/{{dataItem.typeFy}}
			  <!-- {{dataItem.majorFy}} -->
			  <template v-if="dataItem.majorFy.length">
			    <span v-for="(row,indexss) in dataItem.majorFy" :key="indexss">/{{row.name}}</span>
			  </template>
			  <span>
			    /总投资:￥{{dataItem.totalInvestment||'00'}}
			  </span>
			  <span>
			    /总规模:{{dataItem.totalScale}}m²
			  </span>
			  /{{dataItem.serviceContentFy}}
		  </div>
		  <div class="look-title">工程师收益如下：</div>
		  <div class="look-bbx">
			  <div class="m-flex color1">
				  <div class="left">初始收益:</div>
				  <div class="right">{{zdList.commission || '0.00'}}元</div>
			  </div>
			  <div class="m-flex color1">
				  <div class="left">少补收益:</div>
				  <div class="right">{{zdList.commission1 || '0.00'}}元</div>
			  </div>
			  <div class="m-flex color1">
				  <div class="left">多退收益:</div>
				  <div class="right">{{zdList.commission2 || '0.00'}}元</div>
			  </div>
			  <div class="m-flex color1">
				  <div class="left">投诉扣除收益:</div>
				  <div class="right">{{zdList.commission3 || '0.00'}}元</div>
			  </div>
			  <div class="lines-next"></div>
			  <div class="m-flex color3 ">
				  <div class="left">收益总额:</div>
				  <div class="right">{{zdList.commission4 || '0.00'}}元</div>
			  </div>
			  <div class="m-flex color2">
				  <div class="left">已结算:</div>
				  <div class="right">{{zdList.commission5 || '0.00'}}元</div>
			  </div>
			  <div class="m-flex color2 w-m-b-16">
				  <div class="left">待结算:</div>
				  <div class="right">{{zdList.commission6 || '0.00'}}元</div>
			  </div>
			  <!-- <div class="m-flex color2" v-for="(item,index) in zdList.commissionList" :key="index">
				  <div class="left">{{item.takeReason}}:
					  <el-dropdown>
						<el-button size="small" class="more-btn w-m-r-10" type="text" @click.stop="">
							<img src="../../../static/images/center/wait@2x.png" />
						</el-button>
						<el-dropdown-menu slot="dropdown"> 
							<el-dropdown-item>{{item.takeReason || ''}}， {{item.takeAmount || '0.00'}}元</el-dropdown-item>
						</el-dropdown-menu>
					  </el-dropdown>
				  </div>
				  <div class="right">{{item.takeAmount || '0.00'}}元</div>
			  </div> -->
		  	  			 
		  </div>
		<div class="look-bbx" style="margin-top: 33px;">
			 <div class="m-flex color1">
				<div class="left">初始质保金:</div>
				<div class="right">{{zdList.quality || '0.00'}}元</div>
			 </div>
			 <!-- <div class="m-flex color1">
				  <div class="left">延期扣减质保金:</div>
				  <div class="right">{{zdList.quality1 || '0.00'}}元</div>
			 </div> -->
			 <div class="m-flex color1">
				  <div class="left">质保金调整:</div>
				  <div class="right">{{zdList.quality2+zdList.quality1 || '0.00'}}元</div>
			 </div>
			 <div class="lines-next"></div>
			 <div class="m-flex color3 ">
				  <div class="left">质保金总额:</div>
				  <div class="right">{{zdList.quality3 || '0.00'}}元</div>
			 </div>
			 <div class="m-flex color2">
				  <div class="left">已退还:</div>
				  <div class="right">{{zdList.quality4 || '0.00'}}元</div>
			 </div>
			 <div class="m-flex color2 w-m-b-16">
				  <div class="left">待退还:</div>
				  <div class="right">{{zdList.quality5 || '0.00'}}元</div>
			 </div>
		</div>
		  <!-- <div class="look-title">客户服务费如下:</div>
		  <div class="look-bbx">
			  <div class="m-flex color1">
				  <div class="left">初始服务费:</div>
				  <div class="right">{{zdList. serviceAccount || '0.00'}}元</div>
			  </div>
			  <div class="m-flex color1" v-for="(item,index) in zdList.serviceAccountList" :key="index">
				  <div class="left">{{item.takeReason}}:
					 <el-dropdown>
					 	<el-button size="small" class="more-btn w-m-r-10" type="text" @click.stop="">
					 		<img src="../../../static/images/center/wait@2x.png" />
					 	</el-button>
					 	<el-dropdown-menu slot="dropdown"> 
					 		<el-dropdown-item>{{item.takeReason || ''}}，{{item.takeAmount || '0.00'}}元</el-dropdown-item>
					 	</el-dropdown-menu>
					 </el-dropdown>
				  </div>
				  <div class="right">{{item.takeAmount || '0.00'}}元</div>
			  </div>
			  <div class="m-flex color1">
				  <div class="left">实际服务费:</div>
				  <div class="right">{{zdList.service || '0.00'}}元</div>
			  </div>
			  <div class="lines-next"></div>
			  <div class="m-flex color3">
				  <div class="left">实际结算:</div>
				  <div class="right">{{zdList.serviceMoney}}元</div>
			  </div>
			  <div class="m-flex color2">
				  <div class="left">应退还:</div>
				  <div class="right">{{zdList.service}}元</div>
			  </div>
		  </div> -->
	  </div>
	</el-dialog>
	
	<!--申请结束项目-->
	<el-dialog title="申请取消项目" :visible.sync="cancalgxmCloseShow"  width="40%" center top='20vh' class="tipPopup look-box lookCancel">
	  <div class="w-m-t-16">
		   <el-form ref="form">
			<div class="title w-m-t-12>" style="color: rgba(0,0,0,0.85);">项目已经开始作业，如果由于您的原因取消项目，会导致您的质保金被扣除，影响您的信誉值并导致一段时间无法承接新的项目（具体处理方式请参见 <span @click="$mHelper.otherTo('/xieyi?type=3')" class="wzk-cursor" style="color: #4077DD;">《中一鼎合造价众包平台服务规则》</span>）。请问您确定要取消项目吗？</div>
			<el-form-item label="" prop="cacalNotes" class="w-m-t-12">
				<el-input v-model="cacalNotes" placeholder="请详细说明申请理由" type="textarea" maxlength="255" :autosize="{ minRows: 6, maxRows: 6 }" class="input"></el-input>
			 </el-form-item>
		   </el-form>
		  <span slot="footer" class="dialog-bom mdx-flex mdx-row-center w-m-t-50">
			<el-button class="close" size="small" @click.stop="cancalgxmCloseShow=false">取 消</el-button>
		    <el-button class="w-m-l-24" type="primary" size="small" @click.stop="submitCacnlEvaluate">确 定</el-button>
		  </span>
	  </div>
	</el-dialog>
	
	<!--支付密码-->
	<el-dialog :visible.sync="inputPassword" width="40%" center top='20vh' class="dialog-pwd">
	  <div class="w-font-13">
		<div class="dialog-title">请输入支付密码</div>
	    <div v-if="userInfo.pwdState == 1">
	      <div class="mdx-text-center w-m-t-40 shet-title">您还未设置支付密码</div>
	      <div class="dialog-bom mdx-text-center w-m-t-40">
	        <!-- <div>设置及更多→安全设置支付密码</div> -->
	        <el-button type="primary" class="" @click='toSetPassword'>去设置支付密码</el-button>
	      </div>
	    </div>
	    <div v-else>
	      <div class="mdx-text-center">
	        <div style="width: 100%;margin: 40px 0 60px 0">
	          <el-input v-model="withdrawPassword" type="password" placeholder="请输入支付密码"></el-input>
	        </div>
	      </div>
	      <div class="dialog-bom mdx-flex mdx-row-center w-m-t-46">
			<el-button class="close" size="small" @click="inputPassword = false">取 消</el-button>
	        <el-button type="primary" size="small" :loading="loading" @click='payBalance()'> 确定</el-button>
	      </div>
	    </div>
	  </div>
	</el-dialog>
	
    <alertToast ref="alert"></alertToast>
    <div v-html="alipayWap" ref="alipayWap"></div>
  </div>
</template>

<script>
  import alertToast from '../../../components/alertToast/index.vue'
  import rulesData from '../../../api/rules.js'
  import Axios from "../../../api/axios.js" // 导入配置好的axios文件
  import QRCode from 'qrcodejs2'
  import { initNumberValue } from "@/common/utils/num-validate.js"
  export default {
    components: {
      alertToast
    },
    data() {
      return {
        action: this.$store.state.baseURL + 'upload/uploadCos',
        fileList: [],
        transferRules: rulesData.transferRules,
        loading: false,
        credFrom: {
          name: ''
        },
        paycheck: ['1'],
        applyShow: false, //确认申请项目弹窗
        materialsShow: false, //查看材料弹窗
        payTypeShow: false, //支付弹窗
        transShow: false, //线下转账提示
        credentialsShow: false, //显示凭证弹窗
        credSuccessShow: false, //回款凭证上传成功
        supplementShow: false,//提交成果
        checkList: false,
        tabIndex: 0,
        menuList: [{
          title: '邀请中',
          status: 1,
		  num: 0,
        }, {
          title: '报名中',
          status: 2,
		  num: 0,
        }, {
          title: '进行中',
          status: 4,
		  num: 0,
        }, {
          title: '已结束',
          status: 5,
		  num: 0,
        }],
        clientHeight: '',
        list: [],
        dataItem: {
          majorFy: []
        },
        bankInfo: {},

        queryParams: {
          page: 1, //当前页数
          limit: 10, //每页条数
          totalPage: 1
        },
        bankFrom: {
          bankDeposit: "", //开户银行（线下转账必传）
          payMethod: 0, //支付方式（1.微信，2.支付宝，3.小程序微信支付，4.线下转账）
          paymentAccount: "", //付款人账号（线下转账必传）
          receipt: '', //回执单（线下转账必传）
          sourceId: 0, //项目id(客户付款、工程师支付定金、工程师补缴定金（质保金）、客户补缴尾款 必传)
          type: 0 //类型（1.客户付款，2.工程师支付定金，3.工程师补缴定金（质保金），4.提现，6.客户补缴尾款）
        },
        // 成果文件
        files: {
          name: '',
          url: '',
        },
        onPrepareFile: false,
        alipayWap: '', //支付宝节点
        WechatShow: false, //微信支付弹窗,
		lookPrice: false,
		searchText: '',
		zdList: {}, //账单数据
		cancalgxmCloseShow: false, //申请取消项目
		cacalNotes: '', 
		inputPassword: false,
		withdrawPassword: "",//支付密码
		 
      }
    },
    computed: {
		userInfo() {
		  return this.$store.getters['userInfo']
		},
      paths() {
        return this.$route.path
      }
    },
    watch: {
      clientHeight: function() {
        this.changeFixed(this.clientHeight)
      },
    },
    activated() {
      this.getList();
      //银行信息
      this.$http.get('config/getBank').then(res => {
        if (res.code == 200) {
          this.bankInfo = res.data
        }
      })
    },
    mounted() {
      this.clientHeight = `${document.documentElement.clientHeight}`
      window.onresize = function temp() {
        this.clientHeight = `${document.documentElement.clientHeight}`;
      };
    },
    methods: {
      pageChange(e) {
        this.queryParams.page = e
        this.getList();
      },
      getList() {
        const loading = this.$loading({
          lock: true,
          text: '加载中...',
          spinner: 'el-icon-loading',
          background: 'rgba(255, 255, 255, 0.8)'
        });
        this.$http.get('partake/list', {
          status: this.menuList[this.tabIndex].status,
          pageNum: this.queryParams.page,
          pageSize: this.queryParams.limit,
		  qName: this.searchText
        }).then(res => {
          if (res.code == 200) {
			  if(this.userInfo.identity!=2){
			  	this.$mHelper.to('/'); 
			  }
            this.queryParams.totalPage = res.data.projectList.pages
			
			this.menuList[0].num = res.data.yqzNum;
			this.menuList[1].num = res.data.bmzNum;
			this.menuList[2].num = res.data.jxzNum;
			this.menuList[3].num = res.data.yjsNum;
			
            res.data.projectList.list.forEach(function(item){
              item.depositAmount = initNumberValue(item.depositAmount)
              item.totalInvestment = initNumberValue(item.totalInvestment)
              item.totalScale = initNumberValue(item.totalScale)
            })
            this.list = res.data.projectList.list
          } else {
            this.list = []
          }
          loading.close();
        }).catch(err => {
          loading.close();
        })
      },
	  searchTap() {
	    this.queryParams.page = 1;
	    this.getList();
	  },
      toDetails(item) {
		if(item.isEvaluateProject==1 || item.isEvaluateUser==1 ){
			this.$mHelper.to("/myProjectDetail?id=" + item.id + "&mrate=" + item.mangerEvaluate + "&urate=" + item.userEvaluate);
		}else{
			this.$mHelper.to("/myProjectDetail?id=" + item.id);
		}
      },
      selectUpdata(item, index) {
        this.tabIndex = index
        this.queryParams.page = 1
        this.getList();
      },
      changeFixed(clientHeight) { //动态修改样式
        this.$refs.main.style.minHeight = this.clientHeight - (60 + 356) + 'px';
      },
      //取消接单
      cancelBtns(item) {
        //请求成功后弹窗
        const h2 = this.$createElement;
        this.$msgbox({
          title: '提示',
          message: h2('p', {
            style: 'margin-bottom: 20px'
          }, [
            h2('span', {
              style: 'color: red'
            }, '取消后将无法再次申请此项目'),
            h2('br', null, ''),
            h2('span', {
              style: 'color: red'
            }, '您确定要取消吗？'),
          ]),
          center: true,
          showCancelButton: true,
          confirmButtonText: '确认取消',
          cancelButtonText: '我再想想',
          beforeClose: (action, instance, done) => {
            if (action === 'confirm') {
              instance.confirmButtonLoading = true;
              instance.confirmButtonText = '执行中...';
              this.$http.post('partake/reject', {
                id: item.id,
              }).then(res => {
                if (res.code == 200) {
                  done();
                  setTimeout(() => {
                    instance.confirmButtonLoading = false;
                  }, 300);
                }
              })
            } else {
              done();
            }
          }
        }).then(action => {
          this.getList();
          this.$refs.alert.alertToast('取消成功！', '提示', '#ff0000')
        });

      },
	  
	  openCaneal(item){
		  this.dataItem = item;
		  this.cacalNotes = '';
		  this.cancalgxmCloseShow = true;
	  },
	  
	  //申请结束项目
	  submitCacnlEvaluate(){
	  	if(this.cacalNotes=='' || this.cacalNotes==null){
	  		this.$message.error('请填写申请取消原因！');
	  		return;
	  	}
	  	
	  	const h2 = this.$createElement;
	  	this.$confirm("", {
	  	  title: "提示",
	  	  message: h2(
	  	    "p",{ style: "margin-bottom: 20px", },
	  	    [
	  	      h2(
	  	        "span", { style: "color: red",}, "是否确认申请取消项目"
	  	      ),
	  	      h2("br", null, ""),
	  	      h2(
	  	        "span", { style: "color: red",}, ""
	  	      ),
	  	    ]
	  	  ),
	  	  center: true,
	  	  showCancelButton: true,
	  	  confirmButtonText: "确认申请",
	  	  cancelButtonText: "我再想想",
	  	}).then(() => {
	  	    var durTip = this.$message({
	  	      duration: 1000,
	  	      message: h2("p", null, [
	  	        h2("span", { style: "color: #4077DD" }, "处理中… "),
	  	      ]),
	  	    });
	  	    this.$http.post("project/cancelOrder", {
	  	        projectId: this.dataItem.id,
	  	        type: 8,
	  	        applyCause: this.cacalNotes
	  	      }).then((res) => {
	  	        durTip.close();
	  	        if (res.code == 200) {
	  				this.cancalgxmCloseShow = false,
	  	           this.getList();
	  	           this.$message.success('申请成功')
	  	        }
	  	      }).catch((err) => {
	  	        durTip.close();
	  	      });
	  	  }).catch((action) => {
	  			 durTip.close();
	  	  });
	  },
	  
      //拒绝申请
      refuseApplyBtn(item, index) {
        //请求成功后弹窗
        console.log(item);
        const h1 = this.$createElement;
        this.$msgbox({
          title: '提示',
          message: h1('p', {
            style: 'margin-bottom: 20px'
          }, [
            h1('span', {
              style: 'color: red'
            }, '拒绝申请后将无法再次申请此项目 '),
            h1('br', {
              style: 'color: red'
            }, ''),
            h1('span', {
              style: 'color: red'
            }, '您确定要拒绝申请吗？ '),
          ]),
          center: true,
          showCancelButton: true,
          confirmButtonText: '确认拒绝',
          cancelButtonText: '我再想想',
          beforeClose: (action, instance, done) => {
            if (action === 'confirm') {
              let datas = {
                id: item.id,
              }
              console.log('----------');
              console.log(datas);
              instance.confirmButtonLoading = true;
              this.$http.post('partake/reject', datas).then(res => {
                if (res.code == 200) {
                  done();
                  setTimeout(() => {
                    instance.confirmButtonLoading = false;
                  }, 300);
                }
              }).catch(err => {
                instance.confirmButtonLoading = false;
              })
            } else {
              done(false);
            }
          }
        }).then(action => {
          if (action === 'confirm') {
            this.getList();
            this.$refs.alert.alertToast('您已成功拒绝此项目！', '提示', '#ff0000')
          }
        });

      },
      submitChengguo(item) {
        this.dataItem = item
        this.files = {};
        this.supplementShow = true
        this.$nextTick(() => {
          this.$refs['files'].clearFiles();
        })
      },
      //上传结果请求
      suppleUpdata() {
        if (!this.files.name || !this.files.url) {
          this.$message.error('请上传文件后提交')
          return
        }
        this.loading = true
        this.$http.post('partake/submitResult', {
          url: this.files.url,
          name: this.files.name,
          id: this.dataItem.id
        }).then(res => {
          this.loading = false
          if (res.code == 200) {
            this.$refs.alert.alertToast('您已成功提交工作成果！', '提示', '#000000')
            this.supplementShow = false
            this.getList();
          }
        }).catch(err => {
          this.loading = false
        })

      },
      //选择支付方式后点击支付
      payEnd() {
        this.payTypeShow = false;
        if (this.paycheck[0] == '1') {
          this.payTypeShow = false
          console.log('微信支付');
          this.WeChatPay();
          // this.$message.error('开发中...')
        } else if (this.paycheck[0] == '2') {
          this.payTypeShow = false
          console.log('支付宝支付');
          // this.$message.error('开发中...')
          this.AlipayPay()
        } else if (this.paycheck[0] == '3') {
          console.log('线下转账');
          this.transShow = true
        }else if (this.paycheck[0] == "7") {
			console.log("余额支付");
			this.inputPassword = true;
		}

      },
      //弹窗关闭
      wxPayPuopClose() {
        console.log('关闭微信支付弹窗');
        clearInterval(this.payTimeer);
        done();
      },
      WeChatPay() {
        this.loading = true
        this.$http.post('pay/recharge', {
          payMethod: 1,
          type: this.dataItem.isBjJe == 2 ? 3 : 2,
          sourceId: this.dataItem.id
        }).then(res => {
          if (res.code == 200) {
            this.WechatShow = true;
            this.$nextTick(() => {
              document.getElementById("qrcode").innerHTML = "";
              var qrcode = new QRCode(this.$refs.qrCodeUrl, {
                text: res.data.codeUrl, // 需要转换为二维码的内容
                width: 220,
                height: 220,
                colorDark: '#000000',
                colorLight: '#ffffff',
                correctLevel: QRCode.CorrectLevel.H
              })
            })
            this.paySatausPoll(res.sn)
          }
          this.loading = false;
        }).catch(err => {
          this.loading = false;
        })
      },
      paySatausPoll(payId) {
        this.payTimeer = setInterval(() => {
          if (payId) {
            this.$http.get('payOrder/getOrder',{
              sn: payId
            }).then(e => {
              if (e.code == 200) {
                if (e.data.status == 2) {
                  this.WechatShow = false;
                  var tipMsg = "支付成功！";
                  if(e.data.type==1) {
                    //客户付项目款
                    tipMsg = '恭喜您支付成功！该项目已进入【进行中的项目】平台已开始工作';
                  }
                  // if(e.data.type==6) {
                  //   tipMsg = '您已补缴成功！';//客户补缴尾款
                  // }
                  this.$refs.alert.alertToast(tipMsg, '提示', '#000')
                  this.getList();
                }
              }
            })
          }
        }, 2000);
      },
      //支付宝支付
      AlipayPay() {
        const loading = this.$loading({
          lock: true,
          text: '加载中...',
          spinner: 'el-icon-loading',
          background: 'rgba(255, 255, 255, 0.8)'
        });
        this.$http.post('pay/recharge', {
          payMethod: 2,
          type: this.dataItem.isBjJe == 2 ? 3 : 2,
          sourceId: this.dataItem.id
        }).then(res => {
          if (res.code == 200) {
            this.alipayWap = res.data
            console.log(this.alipayWap);
            this.$nextTick(() => {
              this.$refs.alipayWap.children[0].submit();
            })
          }
          this.loading = false;
        }).catch(err => {
          this.loading = false;
        })
      },
      //显示上传凭证弹窗
      upPingZheng() {
        this.transShow = false
        this.credentialsShow = true
      },
      upataPingzheng() { //提交凭证
        this.$refs['xianxiaPays'].validate((valid) => {
          if (valid) {
            this.loading = true;
            this.bankFrom.payMethod = 4;
            //this.dataItem.isBjJe  保质金1-已补缴，2-待补缴
            //this.bankFrom.type  2-工程师支付定金，3-工程师补缴定金（质保金）
            this.bankFrom.type = this.dataItem.isBjJe == 2 ? 3 : 2;
            this.bankFrom.sourceId = this.dataItem.id;
            
            this.$http.post('pay/recharge', this.bankFrom).then(res => {
              if (res.code == 200) {
                this.getList();
                this.credentialsShow = false
                this.credSuccessShow = true
              }
              this.loading = false
            }).catch(err => {
              this.loading = false
            })
          } else {}
        });
      },
      //点击支付保证金按钮
      showPayType(item) {
        this.dataItem = item;
        this.payTypeShow = true;
      },
      //打开申请弹窗
      applyBtnShow(item) {
        this.dataItem = item
        this.applyShow = true;
      },
      materialsbtnTap(item) {
        this.dataItem = item
        this.materialsShow = true;
      },
      //下载材料
      downWenjian() {
        let url = this.$store.state.baseURL + '/partake/batchDownload?id=' + this.dataItem.id;
        this.loading = true
        // this.materialsShow = false;
        this.$http.downloadFile('partake/batchDownload', {
          id: this.dataItem.id
        }).then(res => {
          var reader = new FileReader();
          let that = this
          this.loading = false
          reader.onloadend = function(event) {
            //因为返回的报错格式是字符串，手动转换成对象，转换成功表示请求失败
            //转换失败就意味着你拿到的result是文件流，那么直接手动下载就好
            try {
              let data = JSON.parse(event.target.result)
              that.$message.error(res.msg);

            } catch (err) {
              const link = document.createElement('a'); // 创建a标签
              let blob = new Blob([res.data]);
              link.style.display = 'none';
              link.href = URL.createObjectURL(blob); // 创建下载的链接
              link.setAttribute('download', '项目资料' + '.' + 'zip'); // 给下载后的文件命名
              document.body.appendChild(link);
              link.click(); // 点击下载
              document.body.removeChild(link); //  完成移除元素
              window.URL.revokeObjectURL(link.href); // 释放blob对象
              that.loading = false
              that.materialsShow = false;
            }
          };
          reader.readAsText(res.data);
        }).catch(err => {
          this.loading = false
        })
      },
      //确认申请弹窗，阅读协议确认申请
      applyBtnTap() {
        this.loading = true
        this.$http.post('partake/applySave', {
          id: this.dataItem.id
        }).then(res => {
          this.loading = false
          if (res.code == 200) {
            this.applyShow = false;
            //this.$refs.alert.alertToast('您已申请本项目，等待项目经理开放资料查阅权限', '提示', '#000')
			
            this.getList();
          }
        }).catch(err => {
          this.loading = false
        })

      },
     //上传超出限制
    handleExceed() {
        this.$message.warning('最多上传一个文件')
    },
      
    onProgress(event, file, fileList) {
      this.onPrepareFile = true;
    },
    onError(event, file, fileList) {
      this.onPrepareFile = false;
      var eFile = file.name||'当前文件';
      this.$message.error(`${eFile}上传失败，请重试`)
    },
      //成果文件上传回调
      cgSuccess(e, files) {
        console.log(e);
        console.log(files.name);
        if (e.code == 200) {
          this.files.name = files.name
          this.files.url = e.data
        } else {
          this.$message.error(e.msg)
        }
      },
      //成果文件删除回调
      cgRemove() {
        this.files = {}
      },
	  receiptSuccess(e, file) {
	    if (file.response.code == 200) {
	      this.bankFrom.receipt = file.response.data;
	    } else {
	      this.$message.success(file.response.msg);
	    }
	  },
      //删除凭证
      handleRemove() {
        this.bankFrom.receipt = ''
      },
      handleClose(done) {

        this.$confirm('当前页面未保存，是否退出？', '提示', {
            center: true,
          }).then(e => {
            this.credentialsShow = false;
            done();
          }).catch(err => {});
      },
      bindCheckBox(value) {
        if (this.paycheck.length > 1) {
          this.paycheck.splice(0, 1)
        }
      },
	  
	  lookBtn(item){
		this.dataItem = item  
	  	this.$http.get("project/checkDetails", {
	  	    id: item.id,
	  	  }).then((res) => {
	  	    this.loading = false;
	  	    if (res.code == 200) {
	  			this.zdList = res.data;
	  			this.lookPrice=true
	  	     
	  	    }
	  	  }).catch((err) => {
	  			
	  	  });
	  	
	  },
	  
	  toSetPassword() {
	    this.$router.push({
	      path: "/SecuritySet"
	    })
	  },
	  payBalance(){
	  	if(this.withdrawPassword==''){
	  		this.$message.warning("请输入支付密码！");
	  		return;
	  	}
	  	this.loading = true;
	  	this.$http.post("pay/recharge", {
	  	    payMethod: 7,
	  	    type: this.dataItem.isBjJe == 2 ? 3 : 2,
	  	    sourceId: this.dataItem.id,
	  		walletPwd: this.withdrawPassword,
	  	  }).then((res) => {
	  	    if (res.code == 200) {
	  		   this.$message.success("支付成功！");
	  		   this.inputPassword = false,
	  	       this.getList();
	  	    }
	  	    this.loading = false;
	  	  }).catch((err) => {
	  	    this.loading = false;
	  	  });
	  },
	  
	  
    }
  }
</script>

<style lang="scss" scoped>
.top-box{
	margin-top: 32px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	.topTitles{
		font-family: PingFangSC, PingFang SC;
		font-weight: 600;
		font-size: 36px;
		color: rgba(0,0,0,0.85);
		text-align: left;
		font-style: normal;
		line-height: 80px;
	}
	.searchBox {
		width: 590px;
		display: flex;
		justify-content: center;
		::v-deep .el-input__inner {
			color: #4077DD;
			border: 1px solid #4077DD;
		}
		::v-deep .el-input-group__append {
			border: 1px solid #4077DD;
			background-color: #4077DD;
		}
		::v-deep ::-webkit-input-placeholder {
			color: #BFBFBF;
		}
		::v-deep ::-moz-input-placeholder {
			color: #BFBFBF;
		}
		::v-deep ::-ms-input-placeholder {
			color: #BFBFBF;
		}
	}
}	

.tabs-navs{
	display: flex;
	margin-top: 24px;
	width: 100%;
	height: 40px;
	.item {
		cursor: pointer;
		margin-right: 42px;
		font-family: PingFangSC, PingFang SC;
		font-weight: 600;
		font-size: 16px;
		color: rgba(0,0,0,0.85);
		line-height: 22px;
		text-align: left;
		font-style: normal;
		position: relative;
	}
	.actives {
		&:before{
			content: '';
			width: 66px;
			height: 6px;
			background: #4077DD;
			border-radius: 2px;
			position: absolute;
			bottom: 0;
			left: 0;
		}
	}
}

.work-list{
	margin-top: 24px;
	min-height: 500px;
	.item{
		min-height: 155px;
		background: #FFFFFF;
		box-shadow: 0px 6px 10px 6px rgba(44,46,51,0.03), 6px 6px 20px 3px rgba(44,46,51,0.06);
		border-radius: 8px;
		padding: 24px;
		margin-bottom: 24px;
		position: relative;
		.item-top{
			display: flex;
			justify-content: space-between;
			position: relative;
			z-index: 99;
			.title{
				width: 690px;
				height: 24px;
				font-family: PingFangSC, PingFang SC;
				font-weight: 500;
				font-size: 18px;
				color: rgba(0,0,0,0.85);
				line-height: 21px;
				text-align: left;
				font-style: normal;
				.titTip{
					padding: 2px 5px;
					border-radius: 4px;
					border: 1px solid #4077DD;
					font-family: PingFangSC, PingFang SC;
					font-weight: 500;
					font-size: 10px;
					color: #4077DD;
					line-height: 14px;
					text-align: center;
					font-style: normal;
					margin-right: 8px;
					position: relative;
					top: -2px;
				}
			}
			.item-sn{
				font-family: PingFangSC, PingFang SC;
				font-weight: 400;
				font-size: 14px;
				color: rgba(0,0,0,0.45);
				line-height: 21px;
				text-align: right;
				font-style: normal;
			}
		}
		.item-center{
			display: flex;
			justify-content: space-between;
			align-items: center;
			.left{
				max-width: 60%;
				.left-tags{
					margin-top: 12px;
					::v-deep .el-tag{
						background: #F5F7FA;
						border-radius: 4px;
						font-family: PingFangSC, PingFang SC;
						font-weight: 400;
						font-size: 12px;
						color: #555C70;
						line-height: 20px;
						font-style: normal;
						margin-right: 8px;
						padding: 0 8px;
						height: 22px;
						margin-bottom: 5px;
					}
				}
				.left-price{
					margin-top: 11px;
					font-family: PingFangSC, PingFang SC;
					font-weight: 600;
					font-size: 24px;
					color: #FF4D4F;
					line-height: 36px;
					font-style: normal;
				}
			}
			.right{
				.pay-type{
					font-family: PingFangSC, PingFang SC;
					font-weight: 500;
					font-size: 16px;
					color: #FF4D4F;
					line-height: 21px;
					text-align: right;
					font-style: normal;
				}
				.pay-waiting{
					font-family: PingFangSC, PingFang SC;
					font-weight: 500;
					font-size: 16px;
					color: #FFB420;
					line-height: 21px;
					text-align: right;
					font-style: normal;
				}
				.no-info{
					font-family: PingFangSC, PingFang SC;
					font-weight: 400;
					font-size: 16px;
					color: rgba(0,0,0,0.45);
					line-height: 21px;
					text-align: right;
					font-style: normal;
				}
				.sure-info{
					display: flex;
					align-items: center;
					justify-content: flex-end;
					img{
						width: 20px;
						height: 20px;
					}
					span{
						font-family: PingFangSC, PingFang SC;
						font-weight: 400;
						font-size: 16px;
						color: #FF4D4F;
						line-height: 21px;
						text-align: right;
						font-style: normal;
					}
				}
				.error-info{
					font-family: PingFangSC, PingFang SC;
					font-weight: 400;
					font-size: 16px;
					color: #FFB420;
					line-height: 21px;
					text-align: right;
					font-style: normal;
				}
				.rate-list{
					.item-rate{
						display: flex;
						align-items: center;
						.item-title{
							font-family: PingFangSC, PingFang SC;
							font-weight: 400;
							font-size: 14px;
							color: rgba(0,0,0,0.85);
							line-height: 30px;
							text-align: right;
							font-style: normal;
							width: 100px;
						}
						::v-deep .el-rate{
							height: auto;
							.el-rate__icon {
							  font-size: 26px !important;
							}
						}
					}
				}
				
			}
		}
		.item-timeBtn{
			display: flex;
			justify-content: space-between;
			min-height: 40px;
			.itme-time{
				font-family: PingFangSC, PingFang SC;
				font-weight: 400;
				font-size: 14px;
				color: rgba(0,0,0,0.85);
				line-height: 21px;
				font-style: normal;
				display: flex;
				align-items: flex-end;
			}
			.itme-btn{
				::v-deep .el-button{
					// width: 96px;
					padding: 12px 15px;
					height: 40px;
					background: #4077DD;
					border-radius: 8px;
					font-family: PingFangSC, PingFang SC;
					font-weight: 400;
					font-size: 16px;
					color: #FFFFFF;
					display: flex;
					align-items: center;
					justify-content: center;
				}
				.pay-type-btn{
					background: #FFFFFF;
					border: 1px solid #4077DD;
					color: #4077DD;
				}
				.more-btn{
					background: #FFFFFF;
					border: 1px solid #E8E8E8;
					color: rgba(0,0,0,0.45);
				}
			}
		}
		.payContextBox{
			height: 53px;
			background: #F7F7F7;
			border-radius: 8px;
			font-family: PingFangSC, PingFang SC;
			font-weight: 400;
			font-size: 14px;
			color: rgba(0,0,0,0.85);
			line-height: 21px;
			display: flex;
			align-items: center;
			margin-top: 15px;
			span{
				color: #4077DD;
				margin-right: 9px;
				margin-left: 16px;
			}
		}
		
		.top-left-tabs{
			width: 80px;
			height: 32px;
			position: absolute;
			left: 0;
			top: 0;
		}
		
	}
}

	.tipPopup{
		::v-deep .el-dialog{
			box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.1), 0px 0px 5px 0px rgba(0,0,0,0.14);
			border-radius: 10px;
			.el-dialog__header{
				padding: 20px 36px 0 28px;
				.el-dialog__title{
					font-family: PingFangSC, PingFang SC;
					font-weight: 500;
					font-size: 18px;
					color: rgba(0,0,0,0.85);
					line-height: 25px;
					text-align: left;
					font-style: normal;
				}
			}
			.el-dialog__body{
				.title{
					font-family: PingFangSC, PingFang SC;
					font-weight: 400;
					font-size: 18px;
					color: rgba(0,0,0,0.85);
					line-height: 27px;
				}
				.info{
					font-family: PingFangSC, PingFang SC;
					font-weight: 400;
					font-size: 14px;
					color: rgba(0,0,0,0.45);
					line-height: 20px;
				}
			}
			.dialog-bom{
				.el-button{
					width: 96px;
					height: 40px;
					background: #4077DD;
					border-radius: 8px;
					font-family: PingFangSC, PingFang SC;
					font-weight: 400;
					font-size: 16px;
					color: #FFFFFF;
					line-height: 24px;
					font-style: normal;
					display: flex;
					justify-content: center;
					align-items: center;
				}
				.close{
					background: #FFFFFF;
					border-radius: 6px;
					border: 1px solid #4077DD;
					color: #4077DD;
				}
			}
			.fp-left{
				font-family: PingFangSC, PingFang SC;
				font-weight: 500;
				font-size: 16px;
				color: rgba(0,0,0,0.85);
				line-height: 21px;
				text-align: left;
				font-style: normal;
				margin-bottom: 12px;
			}
		}
	}



.pingCss{
	::v-deep .el-dialog{
		width: 700px;
		background: #FFFFFF;
		box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.1), 0px 0px 5px 0px rgba(0,0,0,0.14);
		border-radius: 10px;
		.el-dialog__header{
			padding: 32px 36px 0 28px;
			.el-dialog__title{
				font-family: PingFangSC, PingFang SC;
				font-weight: 500;
				font-size: 18px;
				color: rgba(0,0,0,0.85);
				line-height: 25px;
				text-align: left;
				font-style: normal;
			}
		}
		.el-dialog__body{
			padding: 0 28px 8px;
			.el-form-item{
				margin-top: 24px;
				.el-form-item__content{
					margin-left: 0 !important;
					.ping-info{
						font-family: PingFangSC, PingFang SC;
						font-weight: 400;
						font-size: 16px;
						color: rgba(0,0,0,0.85);
						line-height: 32px;
						text-align: justify;
						font-style: normal;
					}
					.el-textarea{
						margin-top: 12px;
					}
					.el-rate__icon{
						font-size: 28px;
					}
					.ping-note{
						display: block;
						resize: vertical;
						padding: 5px 15px;
						line-height: 1.5;
						box-sizing: border-box;
						width: 100%;
						min-height: 120px;
						font-size: inherit;
						color: #606266;
						background-color: #fff;
						background-image: none;
						border: 1px solid #dcdfe6;
						border-radius: 4px;
						transition: border-color .2s cubic-bezier(.645, .045, .355, 1);
						margin-top: 12px;
					}
				}
			}
		}
	}
}
	
	
.proj-box{
	::v-deep .el-dialog{
		width: 700px !important;
		height: 656px;
		background: #FFFFFF;
		box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.1), 0px 0px 5px 0px rgba(0,0,0,0.14);
		border-radius: 10px;
		.el-dialog__headerbtn{
			top: 30px;
		}
		.el-dialog__body{
			padding:0 32px 32px;
			.dialog-title{
				font-family: PingFangSC, PingFang SC;
				font-weight: 500;
				font-size: 18px;
				color: rgba(0,0,0,0.85);
				line-height: 25px;
				text-align: left;
				font-style: normal;
			}
			.searchBox{
				.el-input-group__append{
					background-color: #4077dd;
					border: 1px solid #4077dd;
				}
			}
			.dialog-bom{
				.el-button{
					width: 96px;
					height: 40px;
					background: #4077DD;
					border-radius: 8px;
					font-family: PingFangSC, PingFang SC;
					font-weight: 400;
					font-size: 16px;
					color: #FFFFFF;
					line-height: 24px;
					font-style: normal;
					display: flex;
					justify-content: center;
					align-items: center;
				}
				.close{
					background: #FFFFFF;
					border-radius: 6px;
					border: 1px solid #4077DD;
					color: #4077DD;
					margin-right: 14px;
				}
			}
			
			
		}
	}
	.pro-list{
		width: 100%;
		height: 415px;
		overflow-y: scroll;
		overflow-x: hidden;
		margin-top: 24px;
		.item{
			::v-deep .el-checkbox{
				display: flex;
				align-items: center;
				margin-bottom: 24px;
				.el-checkbox__label{
					padding-left: 25px;
					width: 100%;
					display: flex;
					.el-image{
						position: relative;
						top: 4px;
					}
				}
			}
			.user-box{
				margin-left: 16px;
				.item-title{
					font-family: PingFangSC, PingFang SC;
					font-weight: 400;
					font-size: 16px;
					color: rgba(0,0,0,0.85);
					line-height: 21px;
					text-align: left;
					font-style: normal;
					display: flex;
					align-items: center;
					.tj-title{
						border-radius: 4px;
						border: 1px solid #4077DD;
						font-family: PingFangSC, PingFang SC;
						font-weight: 400;
						font-size: 12px;
						color: #4077DD;
						line-height: 21px;
						text-align: left;
						font-style: normal;
						padding: 0 4px;
						margin-left: 8px;
					}
					::v-deep .el-rate{
						margin-left: 24px;
						position: relative;
						top: -2px;
						.el-rate__icon{
							font-size: 22px;
							margin-right: 1px;
						}
					}
				}
				
				.item-tabs{
					margin-top: 12px;
					span{
						background: #F5F7FA;
						border-radius: 4px;
						font-family: PingFangSC, PingFang SC;
						font-weight: 400;
						font-size: 12px;
						color: #555C70;
						line-height: 20px;
						text-align: left;
						font-style: normal;
						padding: 3px 8px;
						margin-right: 8px;
					}
				}
			}
			
		}
	}
}	

.look-box{
	::v-deep .el-dialog{
		width: 700px;
		min-height: 633px;
		background: #FFFFFF;
		box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.1), 0px 0px 5px 0px rgba(0,0,0,0.14);
		border-radius: 10px;
		padding: 32px;
		.el-dialog__header{
			padding: 0;
		}
		.el-dialog__body{
			padding: 0;	
		}
	}
	.title{
		font-family: PingFangSC, PingFang SC;
		font-weight: 500;
		font-size: 18px;
		color: rgba(0,0,0,0.85);
		line-height: 36px;
		text-align: left;
		font-style: normal;
		margin-top: 16px;
	}
	.look-title{
		font-family: PingFangSC, PingFang SC;
		font-weight: 500;
		font-size: 16px;
		color: rgba(0,0,0,0.45);
		line-height: 36px;
		text-align: left;
		font-style: normal;
		margin-top: 33px;
		margin-bottom: 8px;
	}
	.look-bbx{
		font-family: PingFangSC, PingFang SC;
		font-weight: 500;
		font-size: 18px;
		color: rgba(0,0,0,0.85);
		line-height: 36px;
		text-align: left;
		font-style: normal;
		.m-flex{
			justify-content: space-between;
			position: relative;
			img{
				width: 14px;
				height: 14px;
			}
		}
		.lines-next{
			width: 100%;
			height: 1px;
			background-color: #E8E8E8;
			margin-top: 17px;
			margin-bottom: 9px;
		}
		.color1{
			color: rgba(0,0,0,0.85);
		}
		.color2{
			color: #FF4D4F;
		}
		.color3{
			color: #4077DD;
		}
		
	}
}	
.lookCancel{
	::v-deep .el-dialog{
		width: 700px;
		min-height: 433px;
	}
}
	
	
	
  .qrcode {
    display: inline-block;
    box-shadow: 0 0 10px #888;
    width: 250px;
    height: 250px;
    padding: 15px; // 利用padding的特性，挤出白边
    box-sizing: border-box;
    border-radius: 2px;
    margin-bottom: 20px;

    img {
      width: 220;
      height: 220;
      background-color: #fff; //设置白色背景色

      box-sizing: border-box;
    }
  }
  
  .dialog-pwd{
  	::v-deep .el-dialog{
  		width: 400px !important;
  		min-height: 260px;
  		.shet-title{
  			font-family: PingFangSC, PingFang SC;
  			font-weight: 500;
  			font-size: 16px;
  			color: rgba(0,0,0,0.85);
  			line-height: 20px;
  			font-style: normal;
  		}
  		.el-dialog__body{
  			.el-input__inner{
  				height: 40px;
  				margin-left: 0;
  			}
  			.dialog-bom{
  				display: flex;
  				justify-content: center;
  				.el-button{
  					width: auto;
  				}
  			}
  		}
  	}
  }
  



</style>
