<template>
  <!-- 个人中心 -->
  <div class="boxCenter">
	<div class="top-box">
		<div class="topTitles">工作台</div>
		<div class="searchBox">
			<el-input placeholder="项目搜索" placeholder-class="placeholderClass" v-model="searchText" @keyup.enter.native="searchTap">
				<i slot="prefix" class="el-input__icon el-icon-search" style="color: #4077DD"></i>
				<template slot="append">
					<el-button type="primary" style="background-color: #4077DD" @click="searchTap">搜索</el-button>
				</template>
			</el-input>
		</div>
	</div>  
	<div class="tabs-navs">
		<div class="left">
			<div class="item" v-for="(item, index) in menuList" :key="index + 'z'" :class="tabIndex == index ? 'actives' : ''" @click="selectUpdata(item, index)">
			  {{ item.title }}({{item.num || '0'}})
			</div>
		</div>
		<div class="right" v-if="tabIndex==4">
			<span style="color: #4077DD; cursor: pointer;" v-if="showType==0" @click="showGetList(1)">显示已取消</span>
			<span style="color: #4077DD; cursor: pointer;" v-if="showType==1" @click="showGetList(0)">隐藏已取消</span>
			<!-- <el-radio v-model="showType" :label="1" @change="showGetList">显示已取消</el-radio> -->
		</div>
	</div>  
	<!-- 状态status（-1拆分项目，1.待审核，2.待付款，3.已发布(报名中)，4.作业中/待验收，5.已完成，6.投诉项目，9.已取消） -->
	<div class="work-list">
		<div class="item" v-for="item in list" :key="item.id">
			<div class="item-top" @click="toDetails(item)">
				<div class="title m-flex">
					<div class="ellipsis" style="max-width: 600px;">
						<span class="titTip" v-if="item.releaseMode==1">快捷发布</span>
						<span class="titTip" v-if="item.parentProjectId!=null && item.parentProjectId > 0">关联项目</span>
						<span>{{ item.regionFy }}/{{ item.typeFy }}</span>
						<template v-if="item.majorFy.length">
						   <span v-for="(row, index) in item.majorFy" :key="index">/{{ row.name }}</span>
						</template>
						<span> /总投资:￥{{ item.totalInvestment || "" }} </span>
						<span> /总规模:{{ item.totalScale }}m² </span>
						<span>/{{ item.serviceContentFy }}</span>
					</div>
					<span class="w-m-l-16" style="color: #10C550; font-size: 16px;" v-if="item.status == 5">圆满完成</span>
					<span class="w-m-l-16" style="color: rgba(0,0,0,0.45); font-size: 16px;" v-else-if="item.status == 9">已取消</span>
				</div>
				<div class="item-sn">项目编号:{{ item.sn }}</div>
			</div>
			<div class="item-center" @click="toDetails(item)">
				<div class="left">
					<div class="left-tags">
						<el-tag type="info" class="tags">{{ item.regionFy }}</el-tag>
						<el-tag type="info" class="tags" v-if="item.necessary == 1">需要出现场</el-tag>
						<el-tag type="info" class="tags" v-if="item.provided == 1">提供CAD图纸</el-tag>
						<el-tag type="info" class="tags">{{ item.typeFy }}</el-tag>
						<template v-if="item.majorFy.length">
							<el-tag type="info" class="tags" v-for="(row, index) in item.majorFy" :key="index + 'c'">
								{{ row.name }}
							</el-tag>
						</template>
						<el-tag type="info" class="tags" v-if="item.serviceContentFy">
							{{ item.serviceContentFy}}
						</el-tag>
						<template v-if="item.softwareFy.length">
							<el-tag type="info" class="tags" v-for="(rows, indexs) in item.softwareFy" :key="indexs + 'x'">
								{{ rows.label }}
							</el-tag>
						</template>
					</div>
					<div class="left-price" v-if="tabIndex!=5">
					  <span class="prices" v-if="item.status == 1"> 预估价格：{{ item.estimatedPrice || "" }}</span>
					  <span class="prices" v-else> 平台价格：￥{{ item.orderPrice }}</span>
					  <span class="prices w-m-l-20" v-if="item.projectType == 2"> 固定总价</span>
					</div>
				</div>
				<div class="right">
					<div v-if="item.status == 2">
						<div class="error pay-type" v-if="item.offlineStatus==-1">
						  {{ limitTimeFilter(item.automaticTimeFy) }}
						  <span class="status" v-if="getInvalidState(item) && item.status == 2">
						    已失效
						  </span>
						</div>
						<div class="pay-waiting" v-else-if="item.offlineStatus==1">已提交线上转账凭证，审核中</div>
						<el-popover placement="top" width="300" trigger="hover" v-else-if="item.offlineStatus == 3">
						  <div class="">{{item.offRemark || ''}}</div>
						  <span slot="reference">
						     <span class="pay-type"> 已上传转账凭证，审核失败</span>
						  </span>
						</el-popover>
					</div>
					<div class="m-flex" v-if="item.status==3">
						<div>
							<div class="sure-info" v-if="item.signUpNum > 0">
								<img src="../../../static/images/project/hot@2x.png" />
								<span> {{item.signUpNum}} 个工程师已报名</span>
							</div>
							<div class="no-info" v-else>暂无工程师报名</div>
							<!-- <div class="error-info">授权人员【望江楼】已取消报名，请及时重新授权</div> -->
						</div>
						<div class="status error w-m-l-10" v-if="item.status == 3 && item.zpEngineer == 1">
						  未指派
						</div>
						<div class="status blue w-m-l-10" v-if="item.status == 3 && item.zpEngineer == 2">
						  工程师确认中
						</div>
					</div>
					<div v-if="item.status == 5 && tabIndex!=5">
						<div class="rate-list">
							<div class="item-rate">
							   <div class="item-title">客户评分：</div>
							   <el-rate v-model="item.userEvaluate" disabled disabled-void-color="#D8D8D8"></el-rate>
							</div>
							<div class="item-rate">
								<div class="item-title">项目经理评分：</div>
								<el-rate v-model="item.mangerEvaluate" disabled disabled-void-color="#D8D8D8"></el-rate>
							</div>
						</div>
					</div>
					<div class="" v-if="tabIndex==5 && item.tprojectComplainList!=null">
						<span style="color: #FFB817;" v-if="item.tprojectComplainList.status==1">待处理</span>
						<span style="color: #20E087;" v-if="item.tprojectComplainList.status==2">已通过</span>
						<span class="error" v-if="item.tprojectComplainList.status==3">已拒绝</span>
						<span style="color: #1890ff;" v-else-if="item.tprojectComplainList.statuss == 4">已接收</span>
						<span style="color: #999999;" v-else-if="item.tprojectComplainList.status == 9" >已关闭</span>
					</div>
					
				</div>
			</div>
			<div class="item-timeBtn" v-if="tabIndex!=5">
				<div class="itme-time">
					<span class=""> 项目交付时间：{{ item.deliveryDeadline }}</span>
					<span class="w-m-l-30" v-if="tabIndex != 0">工程师成果交付时间：{{ item.engineerDeadline }}</span>
				</div>
				<div class="itme-btn m-flex">
					<template class="m-flex" v-if="tabIndex == 0">
						<div class="m-flex">
							<el-button size="small" class="pay-type-btn" :loading="loading" @click="cancelBack(item)">驳回项目</el-button>
							<!-- 已发布待审核 -->
							<el-button size="small" type="primary" @click.stop="$mHelper.to('/needEdit?id=' + item.id)" >编辑审核</el-button>
						</div>
					</template>
					<!-- <template v-if="tabIndex == 2">
						已发布待审核
						<el-button class="pay-type-btn" size="small" type="primary" @click="quanSave(item)">权限设置 </el-button>
					</template> -->
					<template v-if="tabIndex == 3">
						<!-- 已发布待审核 -->
						<el-dropdown @command="handleCommand">
							<el-button size="small" class="more-btn w-m-r-10" type="text" @click.stop="">更多</el-button>
							<el-dropdown-menu slot="dropdown"> 
								<el-dropdown-item :command="{ item: item, type: 1, }">代客户取消</el-dropdown-item>
								<el-dropdown-item :command="{ item: item, type: 2, }">代工程师取消</el-dropdown-item >
								<el-dropdown-item :command="{ item: item, type: 4, }">平台取消</el-dropdown-item >
								
							</el-dropdown-menu>
						</el-dropdown>
					</template>
					<template v-if="item.status == 2 && getInvalidState(item) && item.offlineStatus!=1">
						<el-button class="pay-type-btn" size="small" @click.stop="$mHelper.to('/needEdit?id=' + item.id)" :loading="loading">重新发起</el-button>
					</template>
					<template v-if="item.status == 3 && item.zpEngineer == 1">
						<!-- 已发布待审核 -->
						<el-button class="pay-type-btn" size="small" @click.stop="cancelBtns(item)" :loading="loading">取消订单</el-button >
					</template>
					<template v-if="item.status == 3 && (item.zpEngineer == 1 || item.zpEngineer == 2)">
						<!-- 已发布未分配 -->
						<el-button size="small" type="primary" @click.stop="openInvites(item)"> 邀请工程师</el-button>
					</template>
					
					<template v-if="item.status == 4">
						<!-- 已发布待审核 -->
						<el-button size="small" type="primary" @click.stop="openBuchong(item)">上传资料</el-button>
						<!-- 已发布待审核 -->
						<el-button size="small" type="primary" @click.stop="cgPuopShow(item)">上传成果</el-button>
						<!-- 已发布待审核 -->
						<el-button size="small" type="primary" @click.stop="materialsbtnTap(item)" v-if="item.checkStatus == 1">成果审核</el-button>
						<!-- 已审核待转交 -->
						<el-button size="small" type="primary" @click.stop="toUserApply(item)" v-if="item.checkStatus == 6">转交客户</el-button>
					</template>
					
					<template v-if="item.status == 5">
						<el-button class="more-btn" size="small" v-if="item.isEvaluateProject == 1"  @click.stop="lookEvaluation(item.id)">查看评价</el-button>
						<el-button class="more-btn" size="small" type="primary" @click.stop="goEvaluate(item.id)" v-else>立即评价</el-button>
						<el-button size="small" type="primary" @click="lookBtn(item)">查看账单</el-button>
						<el-button size="small" type="primary" @click.stop="fapiaoInfoTap(item)" v-if="item.isInvoice == 2">发票审核</el-button>
					</template>
					
				</div>
			</div>
			<div class="payContextBox" v-if="tabIndex > 1 && item.progress && tabIndex!=5">
			  <!-- {{item.progress}} -->
			  <span>项目进展：</span>{{ item.progress.title }} {{ " " }}{{ item.progress.createTime }}
			</div>
			
			<div class="ts-info" v-if="tabIndex==5 && item.tprojectComplainList!=null">
				<div class="ts-top">
					<div class="ts-title">
						<span v-if="item.tprojectComplainList.status==1 || item.tprojectComplainList.status==4 || item.tprojectComplainList.status==9">客户投诉：</span>
						<span v-if="item.tprojectComplainList.status==2 || item.tprojectComplainList.status==3">投诉原因：</span>
					</div>
					<div class="ts-type">
						<span v-if="item.tprojectComplainList.reason==1">工作成果质量存在问题</span>
						<span v-if="item.tprojectComplainList.reason==2">项目交付时间延误</span>
						<span v-if="item.tprojectComplainList.reason==3">造价师服务态度不好</span>
						<span v-if="item.tprojectComplainList.reason==4">造价师个人经验及信息与实际不符</span>
						<span v-if="item.tprojectComplainList.reason==5">其他问题</span>
					</div>
				</div>
				<div class="ts-cont">
					<span v-if="item.tprojectComplainList.status==1 || item.tprojectComplainList.status==4 || item.tprojectComplainList.status==9">{{item.tprojectComplainList.remark || ''}}</span>		
					<span v-if="item.tprojectComplainList.status==2 || item.tprojectComplainList.status==3">{{item.tprojectComplainList.clRemark || ''}}：</span>
				</div>
				<div class="ts-time">
					<span v-if="item.tprojectComplainList.status==1 || item.tprojectComplainList.status==4 || item.tprojectComplainList.status==9">投诉时间：{{item.tprojectComplainList.createTime || ''}}</span>
					<span v-if="item.tprojectComplainList.status==2 || item.tprojectComplainList.status==3">处理时间：{{item.tprojectComplainList.updateTime || ''}}</span>
				</div>
			</div>
			
			<img src="../../../static/images/project/type-icon-1@2x.png" class="top-left-tabs" v-if="item.status==4 && item.checkStatus == -1" />
			<img src="../../../static/images/project/type-icon-3@2x.png" class="top-left-tabs"  v-if="item.status==4 && item.checkStatus == 1 || item.status==4 && item.checkStatus == 2 || item.status==4 && item.checkStatus == 6" />
			<img src="../../../static/images/project/type-icon-2@2x.png" class="top-left-tabs" v-else-if="(item.status == 4 && item.checkStatus == 3) || (item.status == 4 && item.checkStatus == 5)" />
		</div>
		<div class="mdx-flex mdx-row-center" style="margin-top: 30px; margin-bottom: 32px;" v-if="list.length" >
		  <el-pagination :pageSize="queryParams.limit" :currentPage="queryParams.page" background layout="prev, pager, next" @current-change="pageChange" :page-count="queryParams.totalPage || 1"></el-pagination>
		</div>
	</div>
	

    <el-dialog  class="tipPopup" title="成果审核" :visible.sync="materialsShow" width="40%" center top="20vh">
      <div class="puopContext w-font-16">
        <div class="mdx-flex mdx-row-center w-m-t-50 w-m-b-50">
          <el-button type="primary" style="padding: 10px 30px; border-radius: 2px" :loading="loadFruit" @click="downFruitFile">
            <!-- @click='downWenjian()' -->
            下载成果文件
          </el-button>
        </div>
      </div>
      <div class="mdx-flex mdx-row-right">
        <el-button type="text" size="small" @click="toExamine">审核驳回</el-button>
        <!-- :disabled="checkStatus" -->
        <el-button type="primary" size="small" @click.stop="through">审核通过</el-button>
      </div>
    </el-dialog>

    <el-dialog :visible.sync="credSuccessShow" width="40%" center top="20vh">
      <div class="puopContext w-font-13">
        <div>
          <div>
            项目名称：
            {{ dataItem.regionFy }}/{{ dataItem.typeFy }}
            <!-- {{dataItem.majorFy}} -->
            <template v-if="dataItem.majorFy.length">
              <span v-for="(row, indexss) in dataItem.majorFy" :key="indexss"
                >/{{ row.name }}</span
              >
            </template>
            <span> /总投资:￥{{ dataItem.totalInvestment || "" }} </span>
            <span> /总规模:{{ dataItem.totalScale }}m² </span>
            /{{ dataItem.serviceContentFy }}
          </div>
        </div>
        <div class="wzk-cursor">
          <img src="../../../static/images/jindu.png" style="width: 100%" />
        </div>
        <div class="mdx-text-center" style="margin: 50px 0">
          <i class="el-icon-success w-font-120" style="color: #185ba1"></i>
          <p>您已成功上传凭证！请等待平台审核</p>
        </div>
      </div>
    </el-dialog>
    <!-- //补充材料 -->
    <el-dialog class="tipPopup" title="上传补充资料" :visible.sync="supplementShow" show-cancel-button :before-close="handleClose" width="40%" center top="20vh">
      <div class="puopContext w-font-16">
        <div>
          <div>
            项目名称： {{ dataItem.regionFy }}/{{ dataItem.typeFy }}
            <!-- {{dataItem.majorFy}} -->
            <template v-if="dataItem.majorFy.length">
              <span v-for="(row, indexss) in dataItem.majorFy" :key="indexss" >/{{ row.name }}</span>
            </template>
            <span> /总投资:￥{{ dataItem.totalInvestment || "00" }} </span>
            <span> /总规模:{{ dataItem.totalScale }}m² </span>
            /{{ dataItem.serviceContentFy }}
          </div>
        </div>
        <div class="w-m-t-10">
          <el-upload class="upload-demo" ref="files" :action="action" :on-success="receiptSuccess" :on-remove="handleRemove"  multiple :limit="9" :on-exceed="handleExceed" :file-list="fileList">
            <el-button size="small" type="primary">上传文件</el-button>
          </el-upload>
        </div>
        <div class="mdx-flex mdx-row-center w-m-t-30">
          <el-button type="primary" :loading="loading" style="padding: 10px 40px; border-radius: 2px" class="" @click.stop="suppleUpdata(1)">
            提交
          </el-button>
        </div>
      </div>
    </el-dialog>
    <el-dialog  class="tipPopup" title="上传成果" :visible.sync="resultsShow" show-cancel-button :before-close="handleClose" width="40%" center top="20vh">
      <div class="puopContext w-font-16">
        <div>
          项目名称：
          {{ dataItem.regionFy }}/{{ dataItem.typeFy }}
          <!-- {{dataItem.majorFy}} -->
          <template v-if="dataItem.majorFy.length">
            <span v-for="(row, indexss) in dataItem.majorFy" :key="indexss">/{{ row.name }}</span>
          </template>
          <span> /总投资:￥{{ dataItem.totalInvestment || "" }} </span>
          <span> /总规模:{{ dataItem.totalScale }}m² </span>
          /{{ dataItem.serviceContentFy }}
        </div>
        <div class="w-m-t-10">
          <el-upload class="upload-demo" ref="files" :action="action" :on-success="upFruitfileSuccess" :on-remove="handleFruitRemove" multiple :limit="9" :on-exceed="handleExceed" :file-list="upFruitfileList">
            <el-button size="small" type="primary">上传文件</el-button>
          </el-upload>
        </div>
        <div class="mdx-flex mdx-row-center w-m-t-30">
          <el-button type="primary" :loading="loading" style="padding: 10px 40px; border-radius: 2px" class="" @click.stop="suppleUpdata(2)">
            提交
          </el-button>
        </div>
      </div>
    </el-dialog>
    <!-- 审核驳回 -->
    <el-dialog class="tipPopup" title="审核驳回" :visible.sync="rejectShow" show-cancel-button :before-close="handleClose" width="50%" center top="20vh">
      <div class="puopContext w-font-16">
        <el-form :model="ruleForm" label-width="180px" ref="ruleForm">
          <div v-for="(item, index) in listdata" :key="index + 'w'">
            <el-form-item :label="item.item.item" prop="principle">
              <el-select v-model="item.isCheck" placeholder="请选择" @change="selectValue(item.isCheck)">
                <el-option label="是" value="1"></el-option>
                <el-option label="否" value="2"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="存在的问题" v-if="item.isCheck == 1 && item.subItem.length">
              <!-- <el-checkbox-group
                v-if="item.isCheck == 1"
                v-model="ruleForm.selectedData"
                style="
                  display: flex;
                  flex-flow: column nowrap;
                  align-items: flex-start;
                "
              > -->
              <div v-for="item in item.subItem" :key="item.id">
                <el-checkbox :label="item.id" @change="datesChange(item)" :value="item.ischecked">{{ item.item }}({{ item.itemDes }})</el-checkbox>
                <el-input type="textarea" placeholder="请写明具体存在的问题" maxlength="255" v-if="item.ischecked" v-model="item.content" @input="changeValue(item)"></el-input>
              </div>
              <!-- </el-checkbox-group> -->
            </el-form-item>
          </div>
        </el-form>
        <div class="mdx-flex mdx-row-right w-m-t-30">
          <el-button
            type="primary"
            style="padding: 10px 40px; border-radius: 2px"
            :disabled="rejected"
            @click.stop="rejectedObject()"
          >
            确认驳回
          </el-button>
        </div>
      </div>
    </el-dialog>
    <el-dialog class="tipPopup" title="邀请工程师申请此项目" :visible.sync="inviteShow" show-cancel-button width="30%" center top="20vh">
      <div class="puopContext w-m-t-30 w-font-16">
        <div class="mdx-flex">
          <div class="">工程师编号</div>
          <div class="wzk-flex-1 w-m-l-20">
            <el-input style="margin-top: 0" type="text" placeholder="请输入工程师编号" v-model.trim="engineerNo"></el-input>
          </div>
        </div>
		<div class="mdx-flex w-m-t-16">
		  <div class="">推荐的理由</div>
		  <div class="wzk-flex-1 w-m-l-20">
		    <el-input v-model="addInvitesNotes" placeholder="请输入推荐理由" type="textarea" maxlength="255" :autosize="{ minRows: 6, maxRows: 8 }" class="input"></el-input>
		  </div>
		</div>
		<div class="dialog-bom mdx-flex mdx-row-center w-m-t-46">
			<el-button class="close" size="small" @click="inviteShow = false">取 消</el-button>
		    <el-button type="primary" size="small" :loading="loading" @click.stop="inviteTap()">邀请</el-button>
		</div>
      </div>
    </el-dialog>
	
    <el-dialog class="tipPopup" title="发票审核" :visible.sync="fapiaoInfoShow" show-cancel-button width="40%" center top="20vh">
      <div class="puopContext w-font-16">
        <div class="w-m-l-20 fapBox">
          <div class="mdx-flex">
            <div class="fp-left">发票抬头：</div>
            <div class="w-m-l-20 fp-left">{{ invoiceInfo.invoiceHeader || "无" }}</div>
          </div>
          <div class="mdx-flex">
            <div class="fp-left">单位税号：</div>
            <div class="w-m-l-20 fp-left">{{ invoiceInfo.taxNo || "无" }}</div>
          </div>
          <div class="mdx-flex">
            <div class="fp-left">注册地址：</div>
            <div class="w-m-l-20 fp-left">{{ invoiceInfo.zcAddress || "无" }}</div>
          </div>
          <div class="mdx-flex">
            <div class="fp-left">注册电话：</div>
            <div class="w-m-l-20 fp-left">{{ invoiceInfo.phone || "无" }}</div>
          </div>
          <div class="mdx-flex">
            <div class="fp-left">开户银行：</div>
            <div class="w-m-l-20 fp-left">{{ invoiceInfo.invoiceHeader || "无" }}</div>
          </div>
          <div class="mdx-flex">
            <div class="fp-left">银行账号：</div>
            <div class="w-m-l-20 fp-left">{{ invoiceInfo.account || "无" }}</div>
          </div>
          <div class="mdx-flex">
            <div class="fp-left">接收邮箱：</div>
            <div class="w-m-l-20 fp-left">{{ invoiceInfo.email || "无" }}</div>
          </div>
          <div class="mdx-flex">
            <div class="fp-left">邮寄地址：</div>
            <div class="w-m-l-20 fp-left">{{ invoiceInfo.yjAddress || "无" }}</div>
          </div>
        </div>
        <div class="w-font-14 error w-m-t-30">
          如客户未完善信息，请联系客户在个人中心-发票设置中完善发票信息
        </div>
        <div class="mdx-flex mdx-row-center w-m-t-30">
          <el-button type="primary" class="" @click.stop="invoiceTap(1)">
            确认提交
          </el-button>
          <el-button class="" @click.stop="invoiceTap(2)">
            拒绝申请
          </el-button>
        </div>
      </div>
    </el-dialog>
	
    <!-- 项目评价 -->
    <el-dialog title="项目评价" class="pingCss" :visible.sync="dialogVisible" width="50%" left :modal="false" :before-close="cancle" :close-on-click-modal="false">
      <el-form ref="form" :model="form" label-width="80px">
        <div v-for="(item, index) in problemList" :key="item.id">
          <el-form-item>
           <div class="m-flex">
			   <div class="ping-info">{{ item.item }}：</div>
			   <el-rate :value="item.isCheck" @change="rateChange($event, item, index)" :max="item.num" :allow-half="true"></el-rate>
		   </div>
            <el-input v-model="item.note" placeholder="请写明具体存在的问题" type="textarea" maxlength="255" :autosize="{ minRows: 4, maxRows: 6 }" class="input"></el-input>
          </el-form-item>
        </div>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" size="small" @click.stop="submitEvaluate" :disabled="isDisable">提交评价</el-button>
        <el-button size="small" @click.stop="cancle" style="width: 80px">取 消</el-button>
      </span>
    </el-dialog>
    <!-- 查看评价 -->
    <el-dialog title="项目评价" class="pingCss" :visible.sync="isWrite" width="50%" left :modal="false" :close-on-click-modal="false">
      <el-form ref="form" label-width="80px">
        <el-form-item v-for="item in lookList" :key="item.id">
          <div class="m-flex">
			  <div class="ping-info">{{ item.title }}：</div>
			  <el-rate v-model="item.num" disabled></el-rate>
		  </div>
          <div class="ping-note">
            {{ item.note }}
          </div>
        </el-form-item>
      </el-form>
    </el-dialog>
	<!-- 评价成功 -->
	<el-dialog class="tipPopup" title="提示" :visible.sync="evaluateSucces" :modal="false" width="30%" top="40vh" center :show-close="false" @click.stop.native="evaluateSucces = true" :close-on-click-modal="false">
	  <div style="text-align: center; color: #333333ff; font-size: 16px; margin-top: 20px;">
	    您已评价成功
	  </div>
	  <span slot="footer" class="dialog-footer">
	    <el-button plain size="small" @click.stop="successEva">我知道了</el-button>
	  </span>
	</el-dialog>

    <el-dialog class="tipPopup" title="提示" :modal="false" :visible.sync="cancel" :close-on-click-modal="false" width="24%" top="40vh" :show-close="false" @click.stop.native="cancel = true">
      <div style="color: red; margin-top: 20px; text-align: left">
        该页面内容未保存，您确定要退出吗？
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click.stop="cancelEvaluation">确认取消</el-button>
        <el-button type="info" size="small" @click.stop="(dialogVisible = true), (cancel = false)" style="color: #a1a1a1">我再想想</el-button>
      </span>
    </el-dialog>
   
	<!--报名人员权限设置-->
	<el-dialog :visible.sync="saveQxShow" width="40%" center top='15vh' class="proj-box">
	  <div class="">
		<div class="dialog-title">报名人员</div>
		<div class="searchBox w-m-t-16">
		    <el-input placeholder="项目ID" placeholder-class="placeholderClass" v-model="searchPersonText" @keyup.enter.native="searchPersonTap">
		      <i slot="prefix" class="el-input__icon el-icon-search" style="color: #bfbfbf"></i>
		      <template slot="append">
		        <el-button type="primary" style="background-color: #4077DD" @click="searchPersonTap">搜索</el-button>
		      </template>
		    </el-input>
		</div>
	    <el-form label-position='top' label-width="80px">
	      <el-form-item>
			<div class="pro-list">
				<div class="item">
					<el-radio v-model="addCheckId" :label="item.id" v-for="(item,index) in bmList" :key="index" >
						<div class="block">
							<el-image class='image-slot' :src="userInfo.headImgUrl">
								<div slot="error" class="image-slot">
									<img src="../../../static/images/mrtx.png" style="width: 46px;height: 46px;border-radius: 50%;">
								</div>
							</el-image>
						</div>
						<div class="user-box">
						   <div class="item-title">
							   <span>{{ $mHelper.nameSlice(2, userInfo, item.name, item.name) }}</span>
							   <span class="tj-title" v-if="item.invitation==0">客户邀请</span>
							   <span class="tj-title" v-if="item.invitation==1">项目经理邀请</span>
								<el-rate v-model="item.rateValue" disabled></el-rate>
						   </div>
						   <div class="item-tabs">
								<span>精通{{item.majorFy.length || '0'}}个专业</span>
								<span>精通{{item.softwareFy.length || '0'}}个专业软件</span>
								<span>精通{{item.typeFy.length || '0'}}个项目类型</span>
								<span>擅长{{item.regionFy.length || '0'}}个地区</span>
								<span>{{item.years || '0'}}年工作经验</span>
								<span v-if="item.files!=null">有专业证书</span>
						   </div>
						</div>
						
					</el-radio>
					
					<!-- <el-checkbox-group v-model="addCheckForm" :max="10"  @change="speckCheckChange">
					   <el-checkbox v-for="(item,index) in bmList" :label="item" :key="index" >
						  <div class="block">
						  	<el-image class='image-slot' :src="userInfo.headImgUrl">
						  		<div slot="error" class="image-slot">
						  			<img src="../../../static/images/mrtx.png" style="width: 46px;height: 46px;border-radius: 50%;">
						  		</div>
						  	</el-image>
						  </div>
						  <div class="user-box">
							   <div class="item-title">
								   <span>{{ $mHelper.nameSlice(2, userInfo, item.name, item.name) }}</span>
								   <span class="tj-title" v-if="item.invitation==0">客户推荐</span>
								   <span class="tj-title" v-if="item.invitation==1">由我推荐</span>
								    <el-rate v-model="item.rateValue" disabled></el-rate>
							   </div>
							   <div class="item-tabs">
									<span>精通{{item.majorFy.length || '0'}}个专业</span>
									<span>精通{{item.softwareFy.length || '0'}}个专业软件</span>
									<span>精通{{item.typeFy.length || '0'}}个项目类型</span>
									<span>擅长{{item.regionFy.length || '0'}}个地区</span>
									<span>{{item.years || '0'}}年工作经验</span>
									<span v-if="item.files!=null">有专业证书</span>
							   </div>
						  </div>
						
					  </el-checkbox>
					</el-checkbox-group> -->
				</div>
			</div>
	        
	      </el-form-item>
	      <div class="dialog-bom mdx-flex mdx-row-center w-m-t-35">
	         <el-button size="small"  class="close" @click="saveQxShow = false">取 消</el-button>
	         <el-button size="small" :loading="loading" type="primary" @click="qxCheckSave">确认授权</el-button>
	      </div>
	    </el-form>
	  </div>
	</el-dialog>
	<!--账单查询-->
	<el-dialog title="账单详情" :visible.sync="lookPrice"  width="40%" left top='10vh' class="look-box">
	  <div class="">
	  		  <div class="title">
	  			  {{dataItem.regionFy}}/{{dataItem.typeFy}}
	  			  <!-- {{dataItem.majorFy}} -->
	  			  <template v-if="dataItem.majorFy.length">
	  			    <span v-for="(row,indexss) in dataItem.majorFy" :key="indexss">/{{row.name}}</span>
	  			  </template>
	  			  <span>
	  			    /总投资:￥{{dataItem.totalInvestment||'00'}}
	  			  </span>
	  			  <span>
	  			    /总规模:{{dataItem.totalScale}}m²
	  			  </span>
	  			  /{{dataItem.serviceContentFy}}
	  		  </div>
	  		  <div class="look-title">工程师收益如下：</div>
	  		  <div class="look-bbx">
	  			  <div class="m-flex color1">
	  				  <div class="left">初始收益:</div>
	  				  <div class="right">{{zdList.commission || '0.00'}}元</div>
	  			  </div>
				  <div class="m-flex color1">
					  <div class="left">少补收益:</div>
					  <div class="right">{{zdList.commission1 || '0.00'}}元</div>
				  </div>
				  <div class="m-flex color1">
					  <div class="left">多退收益:</div>
					  <div class="right">{{zdList.commission2 || '0.00'}}元</div>
				  </div>
				  <div class="m-flex color1">
					  <div class="left">投诉扣除收益:</div>
					  <div class="right">{{zdList.commission3 || '0.00'}}元</div>
				  </div>
				  <div class="lines-next"></div>
				  <div class="m-flex color3 ">
					  <div class="left">收益总额:</div>
					  <div class="right">{{zdList.commission4 || '0.00'}}元</div>
				  </div>
				  <div class="m-flex color2">
					  <div class="left">已结算:</div>
					  <div class="right">{{zdList.commission5 || '0.00'}}元</div>
				  </div>
				  <div class="m-flex color2 w-m-b-16">
					  <div class="left">待结算:</div>
					  <div class="right">{{zdList.commission6 || '0.00'}}元</div>
				  </div>
	  			  <!-- <div class="m-flex color2" v-for="(item,index) in zdList.commissionList" :key="index">
	  				  <div class="left">{{item.takeReason}}:
	  					  <el-dropdown>
	  						<el-button size="small" class="more-btn w-m-r-10" type="text" @click.stop="">
	  							<img src="../../../static/images/center/wait@2x.png" />
	  						</el-button>
	  						<el-dropdown-menu slot="dropdown"> 
	  							<el-dropdown-item>{{item.takeReason || ''}}， {{item.takeAmount || '0.00'}}元</el-dropdown-item>
	  						</el-dropdown-menu>
	  					  </el-dropdown>
	  				  </div>
	  				  <div class="right">{{item.takeAmount || '0.00'}}元</div>
	  			  </div> -->
	  			 
	  		  </div>
	  		 <div class="look-bbx" style="margin-top: 33px;">
	  			 <div class="m-flex color1">
				    <div class="left">初始质保金:</div>
				    <div class="right">{{zdList.quality || '0.00'}}元</div>
	  			 </div>
	  			 <!-- <div class="m-flex color1">
					  <div class="left">延期扣减质保金:</div>
					  <div class="right">{{zdList.quality1 || '0.00'}}元</div>
	  			 </div> -->
	  			 <div class="m-flex color1">
					  <div class="left">质保金调整:</div>
					  <div class="right">{{zdList.quality2+zdList.quality1 || '0.00'}}元</div>
	  			 </div>
	  			 <div class="lines-next"></div>
	  			 <div class="m-flex color3 ">
					  <div class="left">质保金总额:</div>
					  <div class="right">{{zdList.quality3 || '0.00'}}元</div>
	  			 </div>
	  			 <div class="m-flex color2">
					  <div class="left">已退还:</div>
					  <div class="right">{{zdList.quality4 || '0.00'}}元</div>
	  			 </div>
	  			 <div class="m-flex color2 w-m-b-16">
					  <div class="left">待退还:</div>
					  <div class="right">{{zdList.quality5 || '0.00'}}元</div>
	  			 </div>
	  		 </div>
	  		  <div class="look-title">客户服务费如下:</div>
	  		  <div class="look-bbx">
	  			  <div class="m-flex color1">
					  <div class="left">初始服务费:</div>
					  <div class="right">{{zdList.service || '0.00'}}元</div>
	  			  </div>
	  			  <div class="m-flex color1">
					  <div class="left">少补服务费:</div>
					  <div class="right">{{zdList.service1 || '0.00'}}元</div>
	  			  </div>
	  			  <div class="m-flex color1">
					  <div class="left">多退服务费:</div>
					  <div class="right">{{zdList.service2 || '0.00'}}元</div>
	  			  </div>
	  			  <div class="m-flex color1">
					  <div class="left">投诉退还服务费:</div>
					  <div class="right">{{zdList.service3 || '0.00'}}元</div>
	  			  </div>
	  			  <div class="lines-next"></div>
	  			  <div class="m-flex color3 ">
					  <div class="left">服务费总额:</div>
					  <div class="right">{{zdList.service4 || '0.00'}}元</div>
	  			  </div>
	  			  <div class="m-flex color2">
					  <div class="left">已结算:</div>
					  <div class="right">{{zdList.service5 || '0.00'}}元</div>
	  			  </div>
	  			  <div class="m-flex color2 w-m-b-16">
					  <div class="left">待结算:</div>
					  <div class="right">{{zdList.service6 || '0.00'}}元</div>
	  			  </div>
	  			  
	  		  </div>
	  </div>
	</el-dialog>
	
	<!--客户取消审核-->
	<el-dialog title="客户取消" :visible.sync="cancalShowApply"  width="40%" center top='10vh' class="tipPopup look-box">
	  <div class="">
		   <el-form ref="form" label-width="88px">
			  <div class="title w-m-t-24" style="color: rgba(0,0,0,0.85);">客户已申请取消项目，请根据工程师作业情况进行费用扣减</div>
			  <div class="title w-m-t-12" style="color: #FF4D4F;"> 项目交付周期：{{zdMyInfo.jfTime || ''}}；订单价格：{{zdMyInfo.platformFee || '0'}}元</div>
			  <div class="title w-m-t-0" style="color: #FF4D4F;">工程师作业天数：{{zdMyInfo.gcsDays || '0'}}天</div>
			  <div class="title w-m-t-0" style="color: #FF4D4F;" v-if="zdMyInfo.checkStatus==6">工程师已上传项目成果文件，且已完成项目经理质控</div>
			  
			  <el-form-item label="收取服务费:" class="w-m-t-8">
				<div class="mdx-flex">
				  <div>
					<el-radio v-model="khRstatus" :label="1">固定比例</el-radio>
					<el-radio v-model="khRstatus" :label="2">固定金额</el-radio>
				  </div>
				  <div class="mdx-flex w-m-l-20">
					<el-input type="text" onkeyup="value=value.replace(/[^\d]/g,'')" v-model="khNumber" placeholder="请输入" :maxlength="6"></el-input>
					<span class="dabwue" v-if="khRstatus==1">%</span>
					<span class="dabwue" v-if="khRstatus==2">元</span>
				  </div>
				  <el-button class="close w-m-l-16" type="primary" size="small" @click.stop="getMoneyInfo">更 正</el-button>
				</div>
			  </el-form-item>
			  
			  <div class="look-title">以下为客户实际结算服务费账单：</div>
			  <div class="look-bbx">
				  <!-- <div class="m-flex color1">
					  <div class="left">初始服务费:</div>
					  <div class="right">{{zdMyInfo.platformFee || '0.00'}}元</div>
				  </div> -->
				  <div class="m-flex color1" v-for="(item,index) in zdMyInfo.serviceAccountList" :key="index">
					  <div class="left">{{item.takeReason}}:
						 <!-- <el-dropdown>
							<el-button size="small" class="more-btn w-m-r-10" type="text" @click.stop="">
								<img src="../../../static/images/center/wait@2x.png" />
							</el-button>
							<el-dropdown-menu slot="dropdown"> 
								<el-dropdown-item>{{item.takeReason || ''}}，{{item.takeAmount || '0.00'}}元</el-dropdown-item>
							</el-dropdown-menu>
						 </el-dropdown> -->
					  </div>
					  <div class="right">{{item.takeAmount || '0.00'}}元</div>
				  </div>
				  <div class="lines-next"></div>
				  <div class="m-flex color3">
					  <div class="left">服务费总额:</div>
					  <div class="right">{{zdMyInfo.totalService || '0.00'}}元</div>
				  </div>
				  <div class="m-flex color2">
					  <div class="left">已结算服务费:</div>
					  <div class="right">{{zdMyInfo.serviceMoney || '0.00'}}元</div>
				  </div>
				  <div class="m-flex color2">
					  <div class="left">待结算:</div>
					  <div class="right">{{zdMyInfo.service || '0.00'}}元</div>
				  </div>
			  </div>
		   </el-form>
		   <div class="check mdx-flex mdx-col-center w-m-t-32">
		     <el-checkbox-group v-model="checkRadiobox">
		        <el-checkbox label=""></el-checkbox>
		     </el-checkbox-group>
		     <div class="w-m-l-8">
		       我已与客户进行协商，并取得客户授权进行项目取消
		     </div>
		   </div>
		  <span slot="footer" class="dialog-bom mdx-flex mdx-row-center w-m-t-50">
			<el-button class="close" size="small" @click="cancalShowApply=false">取 消</el-button>
		    <el-button class="w-m-l-24" type="primary" size="small" :loading="loading" @click.stop="cancalquxiao(1)">确 定</el-button>
		  </span>
	  </div>
	</el-dialog>
	
	<!--代工程师取消-->
	<el-dialog title="工程师取消" :visible.sync="cancalgcsCloseShow"  width="40%" center top='20vh' class="tipPopup look-box">
	  <div class="">
		   <el-form ref="form" >
			  <div class="title w-m-t-12" style="color: rgba(0,0,0,0.85);">您正在代替造价师进行取消申请。一旦取消，该项目进入【已完成-已取消】列表。平台将扣除全部造价师收益，扣除全部质保金；服务费全额退还客户。</div>
			  
			  <el-form-item label="是否不可抗力:" class="w-m-t-10">
				 <div class="mdx-flex">
					 <div>
						 <el-radio v-model="cannot" :label="1">是</el-radio>
						 <el-radio v-model="cannot" :label="2">不是</el-radio>
					 </div>
				 </div>
			  </el-form-item>
			  
			  <div class="check mdx-flex mdx-col-center w-m-t-32">
			    <el-checkbox-group v-model="checkRadiobox2">
			       <el-checkbox label=""></el-checkbox>
			    </el-checkbox-group>
			    <div class="w-m-l-8">
			      我已与造价师进行协商，并取得造价师授权进行项目取消
			    </div>
			  </div>
		   </el-form>
		  <span slot="footer" class="dialog-bom mdx-flex mdx-row-center w-m-t-50">
			<el-button class="close" size="small" @click.stop="cancalgcsCloseShow=false">取 消</el-button>
		    <el-button class="w-m-l-24" type="primary" size="small" :loading="loading"  @click.stop="cancalquxiao(2)">确 定</el-button>
		  </span>
	  </div>
	</el-dialog>
	<!--平台取消-->
	<el-dialog title="平台取消" :visible.sync="cancalpingCloseShow"  width="40%" center top='20vh' class="tipPopup look-box">
	  <div class="">
		   <el-form label-position="right" label-width="98px" ref="form">
			  <div class="title w-m-t-12" style="color: rgba(0,0,0,0.85);">由于平台原因我司将取消该项目。我司已于客户及工程师进行沟通，并于双方达成一致。全额退还客户服务费；退还工程师全部质保金，并承诺工程师承接项目时减免一定额度的管理费用。</div>
			  
			  <el-form-item label="减免管理费：" class="mingHeind w-m-t-16" prop="phone">
				 <el-input v-model="ptNumber" :maxlength="10" oninput="value=((value.replace(/[^0-9.]/g,'')).replace(/([0-9]+\.[0-9]{2})[0-9]*/,'$1'))" placeholder="请输入减免管理费金额"></el-input>
			  </el-form-item>
		   </el-form>
		  <span slot="footer" class="dialog-bom mdx-flex mdx-row-center w-m-t-50">
			<el-button class="close" size="small" @click.stop="cancalpingCloseShow=false">取 消</el-button>
		    <el-button class="w-m-l-24" type="primary" size="small" :loading="loading"  @click.stop="cancalquxiao(4)">确 定</el-button>
		  </span>
	  </div>
	</el-dialog>
	
	<!--邀请弹框-->
	<el-dialog class="" title="邀请工程师" :visible.sync="invitesBoxShow" width="30%" center top="20vh">
		<div style="border: 1px solid #4077DD;width: 70%; height: 60px; cursor: pointer; border-radius: 4px; font-family: PingFangSC, PingFang SC;font-weight: 400; font-size: 16px; color: #4077dd; display: flex; align-items: center; justify-content: center; margin: 20px auto;"  @click="goToInvites()">
			 到人才库邀请
		</div>
		<div class="dialog-bom mdx-flex mdx-row-center w-m-t-35">
			<el-button size="small" class="close" style="width: 120px; height: 40px;" @click="invitation()">通过工程师ID邀</el-button>
			<el-button size="small" class="close" style="width: 120px; height: 40px; margin-left: 30px;" @click="openInviresList()">查看已邀请</el-button>
		</div>
	</el-dialog>
	
	<!--邀请列表-->
	<el-dialog class="tipPopup" title="已邀请列表" :visible.sync="invitesListShow" show-cancel-button width="750px" center top="20vh">
	    <div class="left-box-invites">
	    	<div class="item-invites m-flex" v-for="(item, index) in bmInvitesList" :key='index'>
	    		<div class="left m-flex">
	    			<WzkAvatar :isGender="true" :gender="item.gender || 1" :imgSrc="item.headImgUrl" :waryWidth="70" :waryHeight="70" :width="70" :height="70" :borderRadius="35"></WzkAvatar>
	    			<div class="user-invites">
	    				<div class="invits-top m-flex">
	    					<span class="username">{{ $mHelper.nameSlice(2, userInfo, item.name, item.name) }}</span>
	    					<span class="userTag" v-if="item.invitation==0">客户邀请</span>
	    					<el-popover placement="right" width="300" trigger="hover" v-if="item.invitation==1">
	    					  <div class="" v-if="item.inviteReason!=null">{{item.inviteReason}}</div>
	    					  <span slot="reference">
	    					     <span class="userTag">项目经理邀请</span>
	    					  </span>
	    					</el-popover>
	    					<el-popover placement="right" width="300" trigger="hover" v-if="item.is_fm==1">
	    					  <div class="">该工程师在进行中的项目较多，请谨慎选择</div>
	    					  <span slot="reference">
	    					     <span class="userTag" style="color: #FF4D4F; border-color: #FF4D4F;">繁忙</span>
	    					  </span>
	    					</el-popover>
	    					<el-rate v-model="item.rateValue" disabled disabled-void-color="#D8D8D8"></el-rate>
	    				</div>
	    				<div class="invites-sn">
	    					<span>工程师编号：{{ item.engineerNo || '' }}</span>
	    					<!-- <span class="w-m-l-24">工程师电话：{{ item.mobile || '' }}</span> -->
	    				</div>
	    				<div class="invites-tags">
	    					<span>精通{{item.majorFy.length || '0'}}个专业</span>
	    					<span>精通{{item.softwareFy.length || '0'}}个专业软件</span>
	    					<span>精通{{item.typeFy.length || '0'}}个项目类型</span>
	    					<span>擅长{{item.regionFy.length || '0'}}个地区</span>
	    					<span>{{item.years || '0'}}年工作经验</span>
	    					<span v-if="item.files!=null">有专业证书</span>
	    				</div>
	    			</div>
	    		</div>
	    		<div class="right m-flex" >
	    			<span style="color: #4077DD;" v-if="item.statusApply==1">待确认</span>
	    			<span style="color: #10C550;" v-if="item.statusApply==2">已接受</span>
	    			<span style="color: #FF4D4F;" v-if="item.statusApply==9">已拒绝</span>
	    		</div>
	    	</div>
	    </div>
	</el-dialog>
	
    <alertToast ref="alert"></alertToast>
  </div>
</template>

<script>
import alertToast from "../../../components/alertToast/index.vue";
import { initNumberValue } from "@/common/utils/num-validate.js";
import WzkAvatar from '../../../components/wzk-Avatar/index.vue';
export default {
  components: {
    alertToast,WzkAvatar
  },
  data() {
    return {
      queryCh: 0,
      chischecValue: false,
      num: 0,
      rejected: true, //确认驳回
      checkStatus: false, // 审核驳回
      ruleForm: {
        //表单
        principle: "",
        technology: "",
        selectedData: [],
      },
      checkName: [],
      principle: "",
      technology: "",
      lookList: [],
      isWrite: false,
      isDisable: false,
      evaluateList: [], // 评价得问题
      form: {
        // 评价内容
        name: "",
        name1: "",
        name2: "",
      },
      scoring: {
        // 分数
        num: 0,
        num1: 0,
        num2: 0,
      },
      evaluateId: 0, // 项目id
      evaluateSucces: false,
      cancel: false,
      dialogVisible: false, // 评价弹框
      isKeepAlive: true,
      action: this.$store.state.baseURL + "upload/uploadCos",
      loading: false,
      engineerNo: "", //工程师编号
      credFrom: {
        name: "",
      },
      paycheck: ["1"],
      inviteShow: false,
      fapiaoInfoShow: false,
      rejectShow: false,
      applyShow: false, //确认申请项目弹窗
      materialsShow: false, //查看材料弹窗
      payTypeShow: false, //支付弹窗
      transShow: false, //线下转账提示
      credentialsShow: false, //显示凭证弹窗
      credSuccessShow: false, //回款凭证上传成功
      supplementShow: false, //补充资料
      resultsShow: false, //成果
      checkList: false,
      fileList: [],
      upFruitfileList: [],
      textarea: "",
      tabIndex: 0,
      menuList: [
        {
          title: "待审核",
          status: 1,
		  num: 0,
        },
        {
          title: "待支付",
          status: 2,
		  num: 0,
        },
        {
          title: "报名中",
          status: 3,
		  num: 0,
        },
        {
          title: "进行中",
          status: 4,
		  num: 0,
        },
        {
          title: "已结束",
          status: 5,
		  num: 0,
        },
        {
          title: "被投诉",
          status: 10,
		  num: 0,
        },
      ],
      clientHeight: "",
      list: [],
      dataItem: {
        majorFy: [],
      },
      queryParams: {
        page: 1, //当前页数
        limit: 10, //每页条数
        totalPage: 1,
      },

      ticker: null, //倒计时
      files: [], //上传文件
      waitFruitfiles: [],
      invoiceInfo: {},
      loadFruit: false,
      listdata: [],
      itemIdArr: [],
      subItemIdArr: [],
      problemList: {},
      allChecked: [],
	  saveQxShow: false,
	  value: 4,
	  addCheckForm: {
	    value: [],
	    selectList: [],
	  },
	  lookPrice: false,
	  searchText: '',
	  searchPersonText: '',
	   bmList: [],
	   addCheckId: '',
	   zdList: {}, //账单数据
	   cancalShowApply: false, //客户取消审核
	   cancalgcsCloseShow: false,	//代工程师取消	  
	   cancalpingCloseShow: false,	//平台取消
	   ptNumber: '',//平台取消减免费用
	   khNumber: '',
	   khRstatus: 1,
	   zdMyInfo: {},
	   checkRadiobox: [],
	   checkRadiobox2: [],
	   cannot: 0, //是否不可抗力
	   addInvitesNotes:'', //推荐的理由
	   showType: 0,
	   invitesBoxShow: false,  //邀请方式弹框
	   invitesListShow: false, //邀请列表弹框
	   bmInvitesList: [],
    };
	
  },
  computed: {
	  userInfo() {
	    return this.$store.getters['userInfo']
	  },
	  paths() {
		  return this.$route.path;
	  },
  },
  watch: {
    clientHeight: function () {
      this.changeFixed(this.clientHeight);
    },
  },
  mounted() {
    this.clientHeight = `${document.documentElement.clientHeight}`;
    window.onresize = function temp() {
      this.clientHeight = `${document.documentElement.clientHeight}`;
    };
    //这一段是防止进入页面出去后再进来计时器重复启动
    if (this.ticker) {
      clearInterval(this.ticker);
    }
    this.beginTimer();
  },
  activated() {
    if (this.isKeepAlive) {
      this.getList();
    }
  },
  beforeRouteLeave(to, from, next) {
    // if (to.name == 'workbenchDetail') {
    //   this.isKeepAlive = true;
    // }
    // if (to.name == 'xieyi') {
    //   this.isKeepAlive = false;
    // }
    next();
  },

  methods: {
    selectValue(index) {
      this.listdata.forEach((item) => {
        if (item.isCheck == 2) {
          item.subItem.forEach((subItem) => {
            if (subItem.ischecked) {
              // this.$set(subItem, "ischecked", false);
               subItem.ischecked = false;
              if (this.allChecked.length > 0) {
                this.allChecked.forEach((allItem, index) => {
                  if (allItem == subItem.id) {
                    this.allChecked.splice(index, 1);
                  }
                });
              }
            }
          });
        }
      });
      // this.$forceUpdate();
      // this.listdata.forEach((item) => {
      //   if (item.isCheck == 2) {
      //     item.subItem.forEach((subItem) => {
      //         subItem.ischecked = false

      //       if (subItem.ischecked) {
      //         subItem.ischecked = false
      //         if (this.allChecked.length > 0) {
      //           this.allChecked.forEach((allItem, index) => {
      //             if (allItem == subItem.id) {
      //               this.allChecked.splice(index, 1);
      //             }
      //           });
      //         }
      //       }
      //     });
      //   }
      // });
      if (this.allChecked.length > 0) {
        this.rejected = false;
      } else {
        this.rejected = true;
      }
      // this.num = item
      // if(item == 2 && this.chischecValue){
      //  this.rejected = true
      // }else if(item == 1 && this.chischecValue){
      //   this.rejected = false
      // }
    },
    // 打分
    rateChange(e, item, index) {
      this.problemList[index].isCheck = e;
    },
    changeValue(item) {
      this.$forceUpdate();
    },
    //点击多选框事件
    datesChange(data) {
      data.ischecked = !data.ischecked;
      if (this.allChecked.length > 0) {
        this.allChecked.forEach((allItem, index) => {
          if (allItem == data.id) {
            this.allChecked.splice(index, 1);
          }
        });
      }
      this.listdata.forEach((item) => {
        if (item.isCheck == 1) {
          item.subItem.forEach((subItem) => {
            if (subItem.ischecked && !this.allChecked.includes(subItem.id)) {
              console.log('subItem',subItem)
              this.allChecked.push(subItem.id);
            }
          });
        }
      });
      if (this.allChecked.length > 0) {
        this.rejected = false;
      } else {
        this.rejected = true;
      }
      //this.chischecValue = item.ischecked;
      // 控制确认驳回按钮
      // if(item.ischecked){
      //   this.rejected = false
      // } else{
      //   this.rejected = true
      // }
    },
    // 确认驳回
    rejectedObject() {
      this.loading = true;
      let arr = [];
      this.listdata.forEach((item, index) => {
        if (item.isCheck == 1) {
          arr.push({
            isCheck: 1,
            name: item.item.item,
            items: [],
          });
          item.subItem.forEach((subItem) => {
            if (subItem.ischecked) {
              arr[arr.length - 1].items.push({
                id: subItem.id,
                num: subItem.num,
                score: subItem.score,
                preItemId: subItem.preItemId,
                title: subItem.item + subItem.itemDes,
                note: subItem.content,
              });
            }
          });
        }
      });
      this.$http.post("director/review", {
          id: this.dataItem.id,
          type: 2,
          remark: JSON.stringify(arr),
        }).then((res) => {
          this.loading = false;
          if (res.code == 200) {
            this.rejectShow = false;
            this.$refs.alert.alertToast(
              "您已驳回此工作成果！",
              "提示",
              "#ff0000"
            );
            this.getList();
            this.ruleForm = {};
            this.ruleForm.selectedData = [];
            this.isReject = true;
            this.rejected = true;
          }
        }).catch((err) => {
          this.loading = false;
        });
      this.getList();
    },
    // 审核驳回
    toExamine() {
      this.rejectShow = true;
      this.materialsShow = false;
      this.$http.get("/projectEvaluateProject/getBase2", {
          evaluateType: 1,
        }).then((res) => {
          if (res.code === 200) {
            res.data.forEach((item) => {
              item.isCheck = "2";
              item.subItem.forEach((subItem) => {
                subItem.ischecked = false;
                // this.allChecked.push(subItem);
              });
            });
            this.listdata = res.data;
          }
        }).catch((err) => {
          console.log(err);
        });
    },
    successEva() {
      this.evaluateSucces = false;
      this.getList();
    },
    // 查看评价
    lookEvaluation(id) {
      this.$http.get("/projectEvaluateProject/getBase", {
          evaluateType: 2,
        }).then((res) => {
          if (res.code === 200) {
            this.evaluateList = [];
            res.data.forEach((item) => {
              this.evaluateList.push(item.item);
            });
            this.isWrite = true;
            this.$http.get("/projectEvaluateProject/getDetailByProjectId", {
                projectId: id,
                evaluateType: 2,
              }).then((res) => {
                if (res.code === 200) {
                  if ((res.data[0].length = 0)) {
                    this.lookList = [];
                  } else {
                    this.lookList = JSON.parse(res.data[0].evaluateContent);
                  }
                  let arr = [];
                  this.lookList.forEach((item) => {
                    item.items.forEach((sub, index) => {
                      arr.push(sub);
                    });
                  });
                  this.lookList = arr;
                }
              }).catch((err) => {
                console.log(err);
              });
          }
        }).catch((err) => {
          console.log(err);
        });
    },
    // 项目评价
    goEvaluate(id) {
      this.evaluateId = id;
      this.dialogVisible = true;
      this.$http.get("/projectEvaluateProject/getBase", {
          evaluateType: 2,
        }).then((res) => {
          if (res.code === 200) {
            res.data.forEach((item) => {
              item.note = "";
              item.isCheck = 0;
            });
            this.problemList = res.data;
          }
        }).catch((err) => {
          console.log(err);
        });
    },
    // 提交评价
    submitEvaluate() {
      let arrList = [];
      this.problemList.forEach((item) => {
        if (item.isCheck > 0) {
			// if(item.note=='' || item.note == null){
			// 	 this.$message.success('操作成功')
			// }
			
          arrList.push({
            isCheck: item.isCheck,
            items: [
              {
                id: item.id,
                num: item.isCheck,
                fraction: item.isCheck * item.score,
                score: item.score,
                preItemId: item.preItemId,
                title: item.item,
                note: item.note,
              },
            ],
          });
        }
      });
      this.$http.post("/projectEvaluateProject/setEvaluate", {
          projectId: this.evaluateId,
          evaluateType: 2,
          mid: this.$store.state.userInfo.id,
          content: JSON.stringify(arrList),
        }).then((res) => {
          if (res.code == 200) {
            this.dialogVisible = false;
            this.evaluateSucces = true;
          }
          this.form = {};
          this.scoring = {};
        }).catch((err) => {});
    },
    // 取消评价
    cancle() {
      this.problemList.forEach((item) => {
        if (item.note == "" && item.isCheck == 0) {
          this.dialogVisible = false;
        } else {
          this.cancel = true;
          this.dialogVisible = true;
        }
      });
    },
    // 确认取消
    cancelEvaluation() {
      this.cancel = false;
      this.dialogVisible = false;
      this.form = {};
      this.scoring = {};
    },
    getList() {
      const loading = this.$loading({
        lock: true,
        text: "加载中...",
        spinner: "el-icon-loading",
        background: "rgba(255, 255, 255, 0.8)",
      });
      this.$http.get("director/list", {
          status: this.menuList[this.tabIndex].status,
          pageNum: this.queryParams.page,
          pageSize: this.queryParams.limit,
		  qName: this.searchText,
		  showType: this.showType
        }).then((res) => {
          if (res.code == 200) {
			if(this.userInfo.identity!=3){
				this.$mHelper.to('/'); 
			}
			  
			  
            this.queryParams.totalPage = res.data.projectList.pages;
			
			this.menuList[0].num = res.data.dshNum;
			this.menuList[1].num = res.data.dzfNum;
			this.menuList[2].num = res.data.bmzNum;
			this.menuList[3].num = res.data.jxzNum;
			this.menuList[4].num = res.data.yjsNum;
			this.menuList[5].num = res.data.btsNum;
			
            res.data.projectList.list.forEach(function (item) {
              item.totalInvestment = initNumberValue(item.totalInvestment);
              item.totalScale = initNumberValue(item.totalScale);
              item.estimatedPrice = initNumberValue(item.estimatedPrice);
              item.orderPrice = initNumberValue(item.orderPrice);
            });
            this.list = res.data.projectList.list;
          } else {
            this.list = [];
          }
          loading.close();
        })
        .catch((err) => {
          loading.close();
        });
    },
	searchTap() {
	  this.queryParams.page = 1;
	  this.getList();
	},
	searchPersonTap() {
		this.bmList = [];
	  this.getPersonList();
	},
	quanSave(item){
		this.dataItem = item;
		this.saveQxShow = true;
		this.bmList = [];
		this.addCheckForm = [];
		this.getPersonList();
	},
	getPersonList(){
		this.$http.get('director/bmList', {
		  id: this.dataItem.id,
		  pageNum: 1,
		  pageSize: 999,
		  status: 2,
		  qName: this.searchPersonText
		}).then(res => {
		  if (res.code == 200) {
			  res.data.list.forEach((e)=>{
			  	e.rateValue = e.synthesize/20 || 0;
			  })  
		      this.bmList = res.data.list
		  }
		})
	},
	speckCheckChange(value) {
	   this.addCheckForm = value;
	},
	qxCheckSave(){
		let major = [];
		this.addCheckForm.forEach((e) => {
		  major.push(e.id);
		});
		this.$http.post('director/permissionSet', {
		  projectId: this.dataItem.id,
		  type: 1,
		  engineerIds: major
		}).then(res => {
		  if (res.code == 200) {
		    this.$message.success('操作成功')
		    this.getPersonList();
		  }
		})
	},
	
    pageChange(e) {
      this.queryParams.page = e;
      this.getList();
    },
    //下拉菜单
    handleCommand(e) {
		this.dataItem = e.item;
		if(e.type==1){
			this.getMoneyInfo();
			this.cancalShowApply = true;
		}else if(e.type==2){
			this.cancalgcsCloseShow = true;
		}else if(e.type==4){
			this.cancalpingCloseShow = true;
		}
		
      // const h2 = this.$createElement;
      // this.$confirm("", {
      //   title: "提示",
      //   message: h2(
      //     "p",{ style: "margin-bottom: 20px", },
      //     [
      //       h2(
      //         "span", { style: "color: red",}, "是否确认取消该项目"
      //       ),
      //       h2("br", null, ""),
      //       h2(
      //         "span", { style: "color: red",}, "您确定要取消吗？"
      //       ),
      //     ]
      //   ),
      //   center: true,
      //   showCancelButton: true,
      //   confirmButtonText: "确认取消",
      //   cancelButtonText: "我再想想",
      // }).then(() => {
      //     var durTip = this.$message({
      //       duration: 1000,
      //       message: h2("p", null, [
      //         h2("span", { style: "color: #185ba1" }, "处理中… "),
      //       ]),
      //     });

      //     this.$http.post("project/cancelOrder", {
      //         type: e.type == 1 ? 7 : 8,
      //         projectId: e.item.id,
      //       }).then((res) => {
      //         durTip.close();
      //         if (res.code == 200) {
      //           this.getList();
      //           this.$refs.alert.alertToast("取消成功！", "提示", "#ff0000");
      //         }
      //       }).catch((err) => {
      //         durTip.close();
      //         console.log(err);
      //       });
      //   }).catch((action) => {
      //     console.log("action", action);
      //   });
    },
	getMoneyInfo(){
		let form = {};
		if(this.khNumber==""){
			form = {
				projectId: this.dataItem.id,
			}
		}else{
			let type = "";
			if(this.khRstatus==1){
				type = 0;
			}else if(this.khRstatus==2){
				type = 1;
			}
			form = {
				projectId: this.dataItem.id,
				type: type,
				typeNum: this.khNumber,
			}
		}
		
		this.$http.post("project/cancelProject", form).then((res) => {
		    if (res.code == 200) {
				 if(res.data){
				 	this.zdMyInfo = res.data;
				 }else{
				 	this.$message.error(res.msg);
				 }
		    } else {
		    }
		  }).catch((err) => {
		    
		  });
	},
	cancalquxiao(type){
		let data = this.dataItem;
		let form = {};
		if(type == 1){	//带客户取消
			if (this.checkRadiobox.length < 1) {
			  this.$message.error('请勾选我已与客户进行协商，并取得客户授权进行项目取消 ')
			  return
			}
		
			if (this.khRstatus==1 && this.khNumber == '') {
			  this.$message.error("请输入收取服务费比例");
			  return;
			}
			if (this.khRstatus==2 && this.khNumber == '') {
			  this.$message.error("请输入收取服务费金额");
			  return;
			}
			
			if(this.khRstatus==1){
				form = {
					projectId: data.id,
					type: 10,
					payWay: 0,
					payMoneyScale: this.khNumber,
					review: 2,
				}
			}else if(this.khRstatus==2){
				form = {
					projectId: data.id,
					type: 10,
					payWay: 1,
					payMoneyScale: this.khNumber,
					review: 2,
				}
			}
			
		}else if(type==2){	//待工程师取消
			if (this.checkRadiobox2.length < 1) {
			  this.$message.error('请勾选我已与造价师进行协商，并取得造价师授权进行项目取消 ')
			  return
			}
			
			if(this.cannot==0){
				this.$message.error('请选择是否不可抗力！ ')
				return
			}
			
			let canType = 0;
			if(this.cannot==1){
				canType = 0;
			}else if(this.cannot==2){
				canType = 1;
			}
			
			form = {
				projectId: data.id,
				cannot: canType,
				type: 11,
				review: 2,
			}
		
		}else if(type==4){  //平台取消
			if (this.ptNumber=='' || this.ptNumber==null) {
			  this.$message.error("请输入减免管理费金额");
			  return;
			}
			form = {
				projectId: data.id,
				type: 9,
				decreaseExpense: this.ptNumber,
				review: 2,
			}
			
		}
		
		//请求成功后弹窗
		const h2 = this.$createElement;
		this.$msgbox({
		  title: "提示",
		  message: h2(
		    "p",{ style: "margin-bottom: 20px",},
		    [
		      h2( "span", { style: "color: red",},"是否确认取消该项目"
		      ),
		      h2("br", null, ""),
		      h2( "span", {style: "color: red", },"您确定要取消吗？"
		      ),
		    ]
		  ),
		  center: true,
		  showCancelButton: true,
		  confirmButtonText: "确认取消",
		  cancelButtonText: "我再想想",
		  beforeClose: (action, instance, done) => {
		    if (action === "confirm") {
		      instance.confirmButtonLoading = true;
		      instance.confirmButtonText = "执行中...";
		      this.$http.post("project/cancelOrderSave", form).then((res) => {
		          if (res.code == 200) {
		            done();
					this.cannot=0;
		            setTimeout(() => {
		              instance.confirmButtonLoading = false;
		            }, 300);
		          }
		        }).catch((error)=>{
					instance.confirmButtonText = "确认取消";
					 setTimeout(() => {
					   instance.confirmButtonLoading = false;
					 }, 300);
				});
		    } else {
		      done();
		    }
		  },
		}).then((action) => {
		  this.getList();
		  this.cancalShowApply = false;
		  this.cancalgcsCloseShow = false;
		  this.cancalpingCloseShow = false;
		  this.$message.success("取消成功！");
		});
		
		
		
	},
	
	
	
    // 取消订单
    handleCommand_orign(e) {
      // e.type == 1 //客户取消订单  2 工程师取消订单
      console.log(e);
      let data = e.item;
      //请求成功后弹窗
      const h2 = this.$createElement;
      this.$msgbox({
        title: "提示",
        message: h2(
          "p",
          {
            style: "margin-bottom: 20px",
          },
          [
            h2(
              "span",
              {
                style: "color: red",
              },
              "是否确认取消该项目"
            ),
            h2("br", null, ""),
            h2(
              "span",
              {
                style: "color: red",
              },
              "您确定要取消吗？"
            ),
          ]
        ),
        center: true,
        showCancelButton: true,
        confirmButtonText: "确认取消",
        cancelButtonText: "我再想想",
        beforeClose: (action, instance, done) => {
          if (action === "confirm") {
            instance.confirmButtonLoading = true;
            instance.confirmButtonText = "执行中...";
            this.$http
              .post("project/cancelOrder", {
                type: e.type == 1 ? 7 : 8,
                projectId: e.item.id,
              })
              .then((res) => {
                if (res.code == 200) {
                  done();
                  setTimeout(() => {
                    instance.confirmButtonLoading = false;
                  }, 300);
                }
              });
          } else {
            done();
          }
        },
      }).then((action) => {
        this.getList();
        this.$refs.alert.alertToast("取消成功！", "提示", "#ff0000");
      });
    },
    getInvalidState(item) {
      if (item.automaticTimeFy > 0) {
        return false;
      } else {
        return true;
      }
    },
    toDetails(item) {
	  if(item.isEvaluateProject==1 || item.isEvaluateUser==1 ){
	  	this.$mHelper.to("/workbenchDetail?id=" + item.id + "&mrate=" + item.mangerEvaluate + "&urate=" + item.userEvaluate);
	  }else{
	  	this.$mHelper.to("/workbenchDetail?id=" + item.id);
	  }
    },
    selectUpdata(item, index) {
      this.tabIndex = index;
	  this.showType = 0;
      this.queryParams.page = 1;
      this.getList();
    },
	showGetList(e){
		this.showType = e;
		this.queryParams.page = 1;
		this.getList();
	},
    changeFixed(clientHeight) {
      //动态修改样式
      this.$refs.main.style.minHeight = this.clientHeight - (60 + 356) + "px";
    },
    //邀请工程师
    inviteTap() {
      if (!this.engineerNo) {
        this.$message.error("请输入工程师编号");
        return;
      }
      this.loading = true;
      this.$http.post("/director/invitation", {
          engineerNo: this.engineerNo,
          projectId: this.dataItem.id,
		  inviteReason: this.addInvitesNotes,
        }).then((res) => {
          this.loading = false;
          if (res.code == 200) {
            this.inviteShow = false;
            this.getList();
            const h1 = this.$createElement;
            this.$msgbox({
              title: "提示",
              message: h1(
                "p",
                {
                  style: "margin-bottom: 20px",
                },
                [
                  h1("span", null, "您已成功邀请工程师申请此项目！ "),
                  h1(
                    "br",
                    {
                      style: "color: red",
                    },
                    ""
                  ),
                  h1(
                    "span",
                    null,
                    "如此工程师同意申请，则会出现在项目申请列表中！ "
                  ),
                ]
              ),
              center: true,
              showCancelButton: true,
              showConfirmButton: false,
              cancelButtonText: "我知道了",
            });
          }
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    //审核通过
    through() {
		
      const h = this.$createElement;
      this.$msgbox({
        title: "提示",
        message: h(
          "p",
          {
            style: "margin-bottom: 20px",
          },
          [
            h(
              "span",
              {
                style: "color: red",
              },
              //"是否需要客户多退少补! "
			  "是否确认成果审核通过"
            ),
          ]
        ),
        center: true,
        showCancelButton: true,
        confirmButtonText: "通过",
        cancelButtonText: "取消",
        beforeClose: (action, instance, done) => {
          if (action === "confirm") {
            instance.confirmButtonLoading = true;
			
            this.$http.post("director/review", {
                id: this.dataItem.id,
                type: 1,
              }).then((res) => {
                this.loading = false;
                if (res.code == 200) {
                  this.materialsShow = false;
                  instance.confirmButtonLoading = false;
                  //this.$refs.alert.alertToast('审核已通过，此成果已发给客户！', '提示', '#ff0000');
				  this.getList();
				    setTimeout(() => {
				      done();
				      setTimeout(() => {
				        instance.confirmButtonLoading = false;
						this.toUserApply(this.dataItem);	
				      }, 300);
				    }, 2000);
				   
				  // done();
                }
              }).catch((err) => {
                 this.loading = false;
              });
          } else {
            done();
          }
        },
      }).then((action) => {
          this.getList();
        }).catch((err) => {
          console.error(err);
        });
    },
	cancelBack(item) {
      //请求成功后弹窗
      const h = this.$createElement;
      this.$msgbox({
        title: "提示",
        message: h(
          "p",
          {
            style: "margin-bottom: 20px",
          },
          [
            h(
              "span",
              {
                style: "color: red",
              },
              "您确定要取消订单吗？ "
            ),
          ]
        ),
        center: true,
        showCancelButton: true,
        confirmButtonText: "确认取消",
        cancelButtonText: "我再想想",
        beforeClose: (action, instance, done) => {
          if (action === "confirm") {
            instance.confirmButtonLoading = true;
            instance.confirmButtonText = "执行中...";
            this.$http.get("project/editProject", {
                id: item.id,
                status: 9,
              }).then((res) => {
                if (res.code == 200) {
                  done();
                  setTimeout(() => {
                    instance.confirmButtonLoading = false;
                  }, 300);
                }
              });
          } else {
			instance.confirmButtonLoading = false;
            done();
          }
        },
      }).then((action) => {
        this.getList();
        this.$refs.alert.alertToast("您已成功取消该订单！", "提示", "#ff0000");
      });
    },
    //取消接单、  项目经理取消未指派订单（项目经理操作）
    cancelBtns(item, index) {
      //请求成功后弹窗
      const h = this.$createElement;
      this.$msgbox({
        title: "提示",
        message: h(
          "p",
          {
            style: "margin-bottom: 20px",
          },
          [
            h(
              "span",
              {
                style: "color: red",
              },
              "您确定要取消订单吗？ "
            ),
          ]
        ),
        center: true,
        showCancelButton: true,
        confirmButtonText: "确认取消",
        cancelButtonText: "我再想想",
        beforeClose: (action, instance, done) => {
          if (action === "confirm") {
            instance.confirmButtonLoading = true;
            instance.confirmButtonText = "执行中...";
            this.$http
              .post("project/cancelOrder", {
                projectId: item.id,
                type: 6,
              })
              .then((res) => {
                if (res.code == 200) {
                  done();
                  setTimeout(() => {
                    instance.confirmButtonLoading = false;
                  }, 300);
                }
              });
          } else {
            done();
          }
        },
      }).then((action) => {
        this.getList();
        this.$refs.alert.alertToast("您已成功取消该订单！", "提示", "#ff0000");
      });
    },
    //拒绝申请
    refuseApplyBtn() {
      this.rejectShow = false;
      const h1 = this.$createElement;
      this.$msgbox({
        title: "提示",
        message: h1(
          "p",
          {
            style: "margin-bottom: 20px",
          },
          [
            h1(
              "span",
              {
                style: "color: red",
              },
              "拒绝申请后将无法再次申请此项目 "
            ),
            h1(
              "br",
              {
                style: "color: red",
              },
              ""
            ),
            h1(
              "span",
              {
                style: "color: red",
              },
              "您确定要拒绝申请吗？ "
            ),
          ]
        ),
        center: true,
        showCancelButton: true,
        showConfirmButton: true,
        confirmButtonText: "确认拒绝",
        cancelButtonText: "我知道了",
      }).then((e) => {
        const h2 = this.$createElement;
        this.$msgbox({
          title: "提示",
          message: h2(
            "p",
            {
              style: "margin-bottom: 20px",
            },
            [h2("span", null, "您已成功拒绝此项目")]
          ),
          center: true,
          showCancelButton: true,
          showConfirmButton: false,
          cancelButtonText: "我知道了",
        }).then((action) => {});
      });
    },
    fapiaoInfoTap(item) {
      this.dataItem = item;
      this.loading = true;
      this.$http
        .get("director/invoiceInfo", {
          projectId: this.dataItem.id,
        })
        .then((res) => {
          if (res.code == 200) {
            this.invoiceInfo = res.data || {};
            this.fapiaoInfoShow = true;
          }
        });
    },
    //上传补充资料弹窗
    openBuchong(item) {
      this.dataItem = item;
      this.files = [];
      this.supplementShow = true;
      this.$nextTick(() => {
        this.fileList = [];
        this.$refs["files"].clearFiles();
      });
    },
    cgPuopShow(item) {
      this.dataItem = item;
      this.files = [];
      this.resultsShow = true;
      this.$nextTick(() => {
        this.fileList = [];
        this.$refs["files"].clearFiles();
      });
    },
    //上传结果请求
    suppleUpdata(type) {
      //1 补充材料 ，2上传成果
      if (type == 1) {
        if (this.files.length < 1) {
          this.$message.error("请上传文件后提交");
          return;
        }
      } else {
        if (this.waitFruitfiles.length < 1) {
          this.$message.error("请上传文件后提交");
          return;
        }
      }
      this.loading = true;
      if (type == 1) {
        this.$http.post("director/supplyFile", {
            files: this.files,
            id: this.dataItem.id,
          }).then((res) => {
            this.loading = false;
            if (res.code == 200) {
              this.$message.success("上传成功");
              this.supplementShow = false;
            }
          })
          .catch((err) => {
            this.loading = false;
          });
      } else {
        this.$http.post("director/uploadCg", {
            files: this.waitFruitfiles,
            id: this.dataItem.id,
          }).then((res) => {
            this.loading = false;
            if (res.code == 200) {
              this.$message.success("上传成功");
              this.resultsShow = false;
            } else {
            }
          })
          .catch((err) => {
            this.loading = false;
          });
      }
      // this.supplementShow = false
      // const h2 = this.$createElement;
      // this.$msgbox({
      //   title: '提示',
      //   message: h2('p', {
      //     style: 'margin-bottom: 20px'
      //   }, [
      //     h2('span', null, '您已成功提交工作成果！ '),
      //   ]),
      //   center: true,
      //   showCancelButton: true,
      //   showConfirmButton: false,
      //   cancelButtonText: '我知道了',
      // }).then(action => {

      // });
    },
    //下载材料
    downWenjian() {
      this.loading = true;
      // if(this.dataItem.checkStatus!=3){
      //   // this.$message.success('暂无可审核的文件')
      //   return
      // }
      this.$http
        .downloadFile("director/download", {
          id: this.dataItem.id,
        })
        .then((res) => {
          var reader = new FileReader();
          let that = this;
          this.loading = false;
          reader.onloadend = function (event) {
            //因为返回的报错格式是字符串，手动转换成对象，转换成功表示请求失败
            //转换失败就意味着你拿到的result是文件流，那么直接手动下载就好
            try {
              let data = JSON.parse(event.target.result);
              that.$message.error(res.msg);
            } catch (err) {
              const link = document.createElement("a"); // 创建a标签
              let blob = new Blob([res.data]);
              link.style.display = "none";
              link.href = URL.createObjectURL(blob); // 创建下载的链接
              link.setAttribute("download", "成果文件" + "." + "zip"); // 给下载后的文件命名
              document.body.appendChild(link);
              link.click(); // 点击下载
              document.body.removeChild(link); //  完成移除元素
              window.URL.revokeObjectURL(link.href); // 释放blob对象
              that.materialsShow = false;
            }
          };
          reader.readAsText(res.data);
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    //下载成果文件
    downFruitFile() {
      this.loadFruit = true;
      this.$http
        .downloadFile("director/download", {
          id: this.dataItem.id,
        })
        .then((res) => {
          console.log(res.data);
          this.loadFruit = false;
          if (res.data.type === "application/force-download") {
            const link = document.createElement("a"); // 创建a标签
            let blob = new Blob([res.data]);
            link.style.display = "none";
            link.href = URL.createObjectURL(blob); // 创建下载的链接['成果文件.zip']
            link.setAttribute("download", "成果文件.zip"); // 给下载后的文件命名
            document.body.appendChild(link);
            link.click(); // 点击下载
            document.body.removeChild(link); //  完成移除元素
            window.URL.revokeObjectURL(link.href); // 释放blob对象
            this.loading = false;
            this.materialsShow = false;
          } else {
            // 将blob文件流转换成json
            let _that = this;
            const reader = new FileReader();
            reader.onload = function (event) {
              let data = JSON.parse(event.target.result);
              if (data && data.hasOwnProperty("msg")) {
                _that.$message.error(data.msg);
              }
            };
            reader.readAsText(res.data);
          }
        })
        .catch((err) => {
          console.log(err);
          let _that = this;
          if (err.data.type === "application/json") {
            const reader = new FileReader();
            reader.onload = function (event) {
              let data = JSON.parse(event.target.result);
              console.log(data);
              if (data && data.hasOwnProperty("msg")) {
                _that.$message.error(data.msg);
              }
            };
            reader.readAsText(err.data);
          }
          this.loadFruit = false;
        });
    },
    //选择支付方式后点击支付
    payEnd() {
      if (this.paycheck[0] == "1") {
        console.log("微信支付");
        this.$message.error("开发中...");
      } else if (this.paycheck[0] == "2") {
        console.log("支付宝支付");
        this.$message.error("开发中...");
      } else if (this.paycheck[0] == "3") {
        console.log("线下转账");
        this.transShow = true;
      }
      this.payTypeShow = false;
    },
    invoiceTap(type) {
      //
      console.log(type);
      this.$http.post("director/checkInvoice", {
          id: this.dataItem.id,
          type: type,
        }).then((res) => {
          if (res.code == 200) {
            this.fapiaoInfoShow = false;
            if (type == 1) {
              this.$refs.alert.alertToast(
                "已同意申请，等待后台开票！",
                "提示",
                "#ff0000"
              );
            } else {
              this.$refs.alert.alertToast(
                "已拒绝客户开发票申请！",
                "提示",
                "#ff0000"
              );
            }
            this.getList();
          }
        });
    },
    //显示上传凭证弹窗
    upPingZheng() {
      this.transShow = false;
      this.credentialsShow = true;
    },
    upataPingzheng() {
      //提交凭证
      this.credentialsShow = false;
      this.credSuccessShow = true;
    },
	
	//打开邀请选择弹框
	openInvites(item){
		this.dataItem = item;
		this.invitesBoxShow = true;
	},
	
    //yao邀请工程师弹窗
    invitation(item) {
      this.engineerNo = "";
      this.inviteShow = true;
    },
	
	//打开已邀请列表
	openInviresList(){
		this.getBmInvitesList();
		this.invitesListShow = true;
	},
	
	goToInvites() {
		this.invitesBoxShow = false;
		this.$mHelper.to('/talents')
	},
	
	getBmInvitesList(){
		this.$http.get('director/bmList', {
		  id: this.dataItem.id,
		  pageNum: 1,
		  pageSize: 999,
		  status: 1,
		}).then(res => {
		  if (res.code == 200) {
				res.data.list.forEach((e)=>{
					e.rateValue = e.synthesize/20 || 0;
					e.is_fm = 0;
					if(e.team==1){ //有团队
						if(e.orderNum >= 5){
							e.is_fm = 1;
						}
					}else {
						if(e.orderNum >= 2){
							e.is_fm = 1;
						}
					}
					
				})  
		      this.bmInvitesList = res.data.list
		  }
		})
	},
	
    //打开申请弹窗
    applyBtnShow() {
      this.applyShow = true;
    },
    materialsbtnTap(item) {
      if (item.checkStatus === 2) {
        this.checkStatus = true;
      } else {
        this.checkStatus = false;
      }
      this.dataItem = item;
      this.materialsShow = true;
    },
	
	toUserApply(item){
		const h1 = this.$createElement;
		this.$msgbox({
		  title: "提示",
		  message: h1(
		    "p",
		    {
		      style: "margin-bottom: 20px",
		    },
		    [
		      h1(
		        "span",
		        {
		          style: "color: red",
		        },
		        "点击转交客户审核后项目将进入验收阶段 "
		      ),
		      h1(
		        "br",
		        {
		          style: "color: red",
		        },
		        ""
		      ),
		      h1(
		        "span",
		        {
		          style: "color: red",
		        },
		        "您确定要转交吗？ "
		      ),
		    ]
		  ),
		  center: true,
		  showCancelButton: true,
		  showConfirmButton: true,
		  confirmButtonText: "确认转交",
		  cancelButtonText: "暂不转交",
		}).then((e) => {
			this.loading = true;
			this.$http.post("director/isSee", {
			    projectId: item.id,
			  }).then((res) => {
			    this.loading = false;
			    if (res.code == 200) {
					this.getList();
			      this.$refs.alert.alertToast(
			        "已成功转交客户验收！",
			        "提示",
			        "#000"
			      );
			    }
			  }).catch((err) => {
			    this.loading = false;
			  });
		});
		this.materialsShow = false;
		
	},
	
    //确认申请弹窗，阅读协议确认申请
    applyBtnTap() {
      this.applyShow = false;
      const h2 = this.$createElement;
      this.$msgbox({
        title: "提示",
        message: h2(
          "p",
          {
            style: "margin-bottom: 20px",
          },
          [h2("span", null, "您已申请本项目，等待项目经理开放资料查阅权限 ")]
        ),
        center: true,
        showCancelButton: true,
        showConfirmButton: false,
        cancelButtonText: "我知道了",
      }).then((action) => {});
    },
    handleChange(e) {
      // this.files.url = e.data
    },
    receiptSuccess(response, file, fileList) {
      let list = [];
      fileList.forEach((e) => {
        list.push({
          name: e.name,
          url: e.response.data,
        });
      });
      this.files = list;
    },
    upFruitfileSuccess(response, file, fileList) {
      let list = [];
      fileList.forEach((e) => {
        list.push({
          name: e.name,
          url: e.response.data,
        });
      });
      this.waitFruitfiles = list;
    },
    handleRemove(file, fileList) {
      let list = [];
      fileList.forEach((e) => {
        list.push({
          name: e.name,
          url: e.response.data,
        });
      });
      this.files = list;
    },
    handleFruitRemove(file, fileList) {
      let list = [];
      fileList.forEach((e) => {
        list.push({
          name: e.name,
          url: e.response.data,
        });
      });
      this.waitFruitfiles = list;
    },
    handleExceed(files, fileList) {
      this.$message.warning(`最多选择9张图片`);
    },
    handleClose(done) {
      this.$confirm("当前页面未保存，是否退出？", "提示", {
        center: true,
      })
        .then((_) => {
          this.credentialsShow = false;
          this.subItemIdArr = [];
          this.ruleForm.principle = "";
          this.ruleForm.technology = "";
          this.ruleForm.selectedData = [];
          this.rejected = true;
          done();
        })
        .catch((_) => {});
    },
    bindCheckBox(value) {
      if (this.paycheck.length > 1) {
        this.paycheck.splice(0, 1);
      }
    },
    //倒计时
    // downTime(time) {
    //   // return new Date().getTime();
    // },
    beginTimer() {
      //这个计时器是每秒减去数组中指定字段的时间
      this.ticker = setInterval(() => {
        this.list.forEach((e) => {
          if (e.automaticTimeFy > 0) {
            e.automaticTimeFy = e.automaticTimeFy - 1000;
          }
        });
      }, 1000);
    },
    // 拼团有效期的filter
    limitTimeFilter(val) {
      if (val <= 0) {
        return "超时未支付";
      } else {
        var lefth = Math.floor((val / (1000 * 60 * 60)) % 24);
        var leftm = Math.floor((val / (1000 * 60)) % 60);
        var lefts = Math.floor((val / 1000) % 60);
        return lefth + "小时" + leftm + "分" + lefts + "秒后自动失效";
      }
    },
	
	lookBtn(item){
		this.dataItem = item  
		this.$http.get("project/checkDetails", {
		    id: item.id,
		  }).then((res) => {
		    this.loading = false;
		    if (res.code == 200) {
				this.zdList = res.data;
				this.lookPrice=true
		     
		    }
		  }).catch((err) => {
				
		  });
		
	}
	
	
  },
};
</script>

<style lang="scss" scoped>
.top-box{
	margin-top: 32px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	.topTitles{
		font-family: PingFangSC, PingFang SC;
		font-weight: 600;
		font-size: 36px;
		color: rgba(0,0,0,0.85);
		text-align: left;
		font-style: normal;
		line-height: 80px;
	}
	.searchBox {
		width: 590px;
		display: flex;
		justify-content: center;
		::v-deep .el-input__inner {
			color: #4077DD;
			border: 1px solid #4077DD;
		}
		::v-deep .el-input-group__append {
			border: 1px solid #4077DD;
			background-color: #4077DD;
		}
		::v-deep ::-webkit-input-placeholder {
			color: #BFBFBF;
		}
		::v-deep ::-moz-input-placeholder {
			color: #BFBFBF;
		}
		::v-deep ::-ms-input-placeholder {
			color: #BFBFBF;
		}
	}
}	

.tabs-navs{
	display: flex;
	justify-content: space-between;
	margin-top: 24px;
	width: 100%;
	height: 40px;
	.left{
		display: flex;
		height: 40px;
	}
	.item {
		cursor: pointer;
		margin-right: 42px;
		font-family: PingFangSC, PingFang SC;
		font-weight: 600;
		font-size: 16px;
		color: rgba(0,0,0,0.85);
		line-height: 22px;
		text-align: left;
		font-style: normal;
		position: relative;
	}
	.actives {
		&:before{
			content: '';
			width: 66px;
			height: 6px;
			background: #4077DD;
			border-radius: 2px;
			position: absolute;
			bottom: 0;
			left: 0;
		}
	}
}

.work-list{
	margin-top: 24px;
	min-height: 500px;
	.item{
		min-height: 155px;
		background: #FFFFFF;
		box-shadow: 0px 6px 10px 6px rgba(44,46,51,0.03), 6px 6px 20px 3px rgba(44,46,51,0.06);
		border-radius: 8px;
		padding: 24px;
		margin-bottom: 24px;
		position: relative;
		.item-top{
			display: flex;
			justify-content: space-between;
			position: relative;
			z-index: 99;
			.title{
				width: 690px;
				height: 26px;
				font-family: PingFangSC, PingFang SC;
				font-weight: 500;
				font-size: 18px;
				color: rgba(0,0,0,0.85);
				line-height: 21px;
				text-align: left;
				font-style: normal;
				.titTip{
					padding: 2px 5px;
					border-radius: 4px;
					border: 1px solid #4077DD;
					font-family: PingFangSC, PingFang SC;
					font-weight: 500;
					font-size: 10px;
					color: #4077DD;
					line-height: 14px;
					text-align: center;
					font-style: normal;
					margin-right: 8px;
					position: relative;
					top: -2px;
				}
			}
			.item-sn{
				font-family: PingFangSC, PingFang SC;
				font-weight: 400;
				font-size: 14px;
				color: rgba(0,0,0,0.45);
				line-height: 21px;
				text-align: right;
				font-style: normal;
			}
		}
		.item-center{
			display: flex;
			justify-content: space-between;
			align-items: center;
			.left{
				max-width: 60%;
				.left-tags{
					margin-top: 12px;
					::v-deep .el-tag{
						background: #F5F7FA;
						border-radius: 4px;
						font-family: PingFangSC, PingFang SC;
						font-weight: 400;
						font-size: 12px;
						color: #555C70;
						line-height: 20px;
						font-style: normal;
						margin-right: 8px;
						padding: 0 8px;
						height: 22px;
						margin-bottom: 5px;
					}
				}
				.left-price{
					margin-top: 11px;
					font-family: PingFangSC, PingFang SC;
					font-weight: 600;
					font-size: 24px;
					color: #FF4D4F;
					line-height: 36px;
					font-style: normal;
				}
			}
			.right{
				.pay-type{
					font-family: PingFangSC, PingFang SC;
					font-weight: 500;
					font-size: 16px;
					color: #FF4D4F;
					line-height: 21px;
					text-align: right;
					font-style: normal;
				}
				.pay-waiting{
					font-family: PingFangSC, PingFang SC;
					font-weight: 500;
					font-size: 16px;
					color: #FFB420;
					line-height: 21px;
					text-align: right;
					font-style: normal;
				}
				.no-info{
					font-family: PingFangSC, PingFang SC;
					font-weight: 400;
					font-size: 16px;
					color: rgba(0,0,0,0.45);
					line-height: 21px;
					text-align: right;
					font-style: normal;
				}
				.sure-info{
					display: flex;
					align-items: center;
					justify-content: flex-end;
					img{
						width: 20px;
						height: 20px;
					}
					span{
						font-family: PingFangSC, PingFang SC;
						font-weight: 400;
						font-size: 16px;
						color: #FF4D4F;
						line-height: 21px;
						text-align: right;
						font-style: normal;
					}
				}
				.error-info{
					font-family: PingFangSC, PingFang SC;
					font-weight: 400;
					font-size: 16px;
					color: #FFB420;
					line-height: 21px;
					text-align: right;
					font-style: normal;
				}
				.rate-list{
					.item-rate{
						display: flex;
						align-items: center;
						.item-title{
							font-family: PingFangSC, PingFang SC;
							font-weight: 400;
							font-size: 14px;
							color: rgba(0,0,0,0.85);
							line-height: 30px;
							text-align: right;
							font-style: normal;
							width: 100px;
						}
						::v-deep .el-rate{
							height: auto;
							.el-rate__icon {
							  font-size: 26px !important;
							}
						}
					}
				}
				
			}
		}
		.item-timeBtn{
			display: flex;
			justify-content: space-between;
			min-height: 40px;
			.itme-time{
				font-family: PingFangSC, PingFang SC;
				font-weight: 400;
				font-size: 14px;
				color: rgba(0,0,0,0.85);
				line-height: 21px;
				font-style: normal;
				display: flex;
				align-items: flex-end;
			}
			.itme-btn{
				::v-deep .el-button{
					// width: 96px;
					padding: 12px 15px;
					height: 40px;
					background: #4077DD;
					border-radius: 8px;
					font-family: PingFangSC, PingFang SC;
					font-weight: 400;
					font-size: 16px;
					color: #FFFFFF;
					display: flex;
					align-items: center;
					justify-content: center;
				}
				.pay-type-btn{
					background: #FFFFFF;
					border: 1px solid #4077DD;
					color: #4077DD;
				}
				.more-btn{
					background: #FFFFFF;
					border: 1px solid #E8E8E8;
					color: rgba(0,0,0,0.45);
				}
			}
		}
		.payContextBox{
			height: 53px;
			background: #F7F7F7;
			border-radius: 8px;
			font-family: PingFangSC, PingFang SC;
			font-weight: 400;
			font-size: 14px;
			color: rgba(0,0,0,0.85);
			line-height: 21px;
			display: flex;
			align-items: center;
			margin-top: 15px;
			span{
				color: #4077DD;
				margin-right: 9px;
				margin-left: 16px;
			}
		}
		.ts-info{
			min-height: 110px;
			background: #F7F7F7;
			border-radius: 8px;
			padding: 16px;
			font-family: PingFangSC, PingFang SC;
			color: rgba(0,0,0,0.85);
			font-size: 14px;
			line-height: 21px;
			margin-top: 25px;
			.ts-top{
				display: flex;
				align-items: center;
				.ts-title{
					font-weight: 500;
					color: #FF4D4F;
				}
				.ts-type{
					font-weight: 400;
					line-height: 21px;
				}
			}
			.ts-cont{
				font-weight: 400;
				margin-top: 8px;
				margin-left: 71px;
				white-space: normal;
				word-break: break-all;
			}
			.ts-time{
				font-weight: 400;
				margin-top: 16px;
			}
		}
		.top-left-tabs{
			width: 80px;
			height: 32px;
			position: absolute;
			left: 0;
			top: 0;
		}
		
	}
}

.tipPopup{
	
	::v-deep .el-dialog{
		box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.1), 0px 0px 5px 0px rgba(0,0,0,0.14);
		border-radius: 10px;
		.el-dialog__header{
			padding: 20px 36px 0 28px;
			.el-dialog__title{
				font-family: PingFangSC, PingFang SC;
				font-weight: 500;
				font-size: 18px;
				color: rgba(0,0,0,0.85);
				line-height: 25px;
				text-align: left;
				font-style: normal;
			}
		}
		.dialog-bom{
			.el-button{
				width: 96px;
				height: 40px;
				background: #4077DD;
				border-radius: 8px;
				font-family: PingFangSC, PingFang SC;
				font-weight: 400;
				font-size: 16px;
				color: #FFFFFF;
				line-height: 24px;
				font-style: normal;
				display: flex;
				justify-content: center;
				align-items: center;
			}
			.close{
				background: #FFFFFF;
				border-radius: 6px;
				border: 1px solid #4077DD;
				color: #4077DD;
			}
		}
		.fp-left{
			font-family: PingFangSC, PingFang SC;
			font-weight: 500;
			font-size: 16px;
			color: rgba(0,0,0,0.85);
			line-height: 21px;
			text-align: left;
			font-style: normal;
			margin-bottom: 12px;
		}
	}
}

.pingCss{
	::v-deep .el-dialog{
		width: 700px;
		background: #FFFFFF;
		box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.1), 0px 0px 5px 0px rgba(0,0,0,0.14);
		border-radius: 10px;
		.el-dialog__header{
			padding: 32px 36px 0 28px;
			.el-dialog__title{
				font-family: PingFangSC, PingFang SC;
				font-weight: 500;
				font-size: 18px;
				color: rgba(0,0,0,0.85);
				line-height: 25px;
				text-align: left;
				font-style: normal;
			}
		}
		.el-dialog__body{
			padding: 0 28px 8px;
			.el-form-item{
				margin-top: 24px;
				.el-form-item__content{
					margin-left: 0 !important;
					.ping-info{
						font-family: PingFangSC, PingFang SC;
						font-weight: 400;
						font-size: 16px;
						color: rgba(0,0,0,0.85);
						line-height: 32px;
						text-align: justify;
						font-style: normal;
					}
					.el-textarea{
						margin-top: 12px;
					}
					.el-rate__icon{
						font-size: 28px;
					}
					.ping-note{
						display: block;
						resize: vertical;
						padding: 5px 15px;
						line-height: 1.5;
						box-sizing: border-box;
						width: 100%;
						min-height: 120px;
						font-size: inherit;
						color: #606266;
						background-color: #fff;
						background-image: none;
						border: 1px solid #dcdfe6;
						border-radius: 4px;
						transition: border-color .2s cubic-bezier(.645, .045, .355, 1);
						margin-top: 12px;
					}
				}
			}
		}
	}
}
	
	
.proj-box{
	::v-deep .el-dialog{
		width: 700px !important;
		height: 656px;
		background: #FFFFFF;
		box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.1), 0px 0px 5px 0px rgba(0,0,0,0.14);
		border-radius: 10px;
		.el-dialog__headerbtn{
			top: 30px;
		}
		.el-dialog__body{
			padding:0 32px 32px;
			.dialog-title{
				font-family: PingFangSC, PingFang SC;
				font-weight: 500;
				font-size: 18px;
				color: rgba(0,0,0,0.85);
				line-height: 25px;
				text-align: left;
				font-style: normal;
			}
			.searchBox{
				.el-input-group__append{
					background-color: #4077dd;
					border: 1px solid #4077dd;
				}
			}
			.dialog-bom{
				.el-button{
					width: 96px;
					height: 40px;
					background: #4077DD;
					border-radius: 8px;
					font-family: PingFangSC, PingFang SC;
					font-weight: 400;
					font-size: 16px;
					color: #FFFFFF;
					line-height: 24px;
					font-style: normal;
					display: flex;
					justify-content: center;
					align-items: center;
				}
				.close{
					background: #FFFFFF;
					border-radius: 6px;
					border: 1px solid #4077DD;
					color: #4077DD;
					margin-right: 14px;
				}
			}
			
			
		}
	}
	.pro-list{
		width: 100%;
		height: 415px;
		overflow-y: scroll;
		overflow-x: hidden;
		margin-top: 24px;
		.item{
			::v-deep .el-checkbox{
				display: flex;
				align-items: center;
				margin-bottom: 24px;
				.el-checkbox__label{
					padding-left: 25px;
					width: 100%;
					display: flex;
					.el-image{
						position: relative;
						top: 4px;
						width: 46px;height: 46px;border-radius: 50%;
					}
				}
			}
			::v-deep .el-radio{
				display: flex;
				align-items: center;
				margin-bottom: 24px;
				.el-radio__label{
					padding-left: 25px;
					width: 100%;
					display: flex;
					.el-image{
						position: relative;
						top: 4px;
						width: 46px;height: 46px;border-radius: 50%;
					}
				}
			}
			.user-box{
				margin-left: 16px;
				.item-title{
					font-family: PingFangSC, PingFang SC;
					font-weight: 400;
					font-size: 16px;
					color: rgba(0,0,0,0.85);
					line-height: 21px;
					text-align: left;
					font-style: normal;
					display: flex;
					align-items: center;
					.tj-title{
						border-radius: 4px;
						border: 1px solid #4077DD;
						font-family: PingFangSC, PingFang SC;
						font-weight: 400;
						font-size: 12px;
						color: #4077DD;
						line-height: 21px;
						text-align: left;
						font-style: normal;
						padding: 0 4px;
						margin-left: 8px;
					}
					::v-deep .el-rate{
						margin-left: 24px;
						position: relative;
						top: -2px;
						.el-rate__icon{
							font-size: 22px;
							margin-right: 1px;
						}
					}
				}
				
				.item-tabs{
					margin-top: 12px;
					span{
						background: #F5F7FA;
						border-radius: 4px;
						font-family: PingFangSC, PingFang SC;
						font-weight: 400;
						font-size: 12px;
						color: #555C70;
						line-height: 20px;
						text-align: left;
						font-style: normal;
						padding: 3px 8px;
						margin-right: 8px;
					}
				}
			}
			
		}
	}
}	

.look-box{
	::v-deep .el-dialog{
		width: 700px;
		// min-height: 893px;
		background: #FFFFFF;
		box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.1), 0px 0px 5px 0px rgba(0,0,0,0.14);
		border-radius: 10px;
		padding: 32px;
		.el-dialog__header{
			padding: 0;
		}
		.el-dialog__body{
			padding: 0;	
		}
	}
	.title{
		font-family: PingFangSC, PingFang SC;
		font-weight: 500;
		font-size: 18px;
		color: rgba(0,0,0,0.85);
		line-height: 36px;
		text-align: left;
		font-style: normal;
		margin-top: 16px;
	}
	.look-title{
		font-family: PingFangSC, PingFang SC;
		font-weight: 500;
		font-size: 16px;
		color: rgba(0,0,0,0.45);
		line-height: 36px;
		text-align: left;
		font-style: normal;
		margin-top: 33px;
		margin-bottom: 8px;
	}
	.look-bbx{
		font-family: PingFangSC, PingFang SC;
		font-weight: 500;
		font-size: 18px;
		color: rgba(0,0,0,0.85);
		line-height: 36px;
		text-align: left;
		font-style: normal;
		.m-flex{
			justify-content: space-between;
			position: relative;
			img{
				width: 14px;
				height: 14px;
			}
		}
		.lines-next{
			width: 100%;
			height: 1px;
			background-color: #E8E8E8;
			margin-top: 17px;
			margin-bottom: 9px;
		}
		.color1{
			color: rgba(0,0,0,0.85);
		}
		.color2{
			color: #FF4D4F;
		}
		.color3{
			color: #4077DD;
		}
		
	}
}

.left-box-invites{
	width: 100%;
	height: 555px;
	overflow-y: scroll;
	overflow-x: hidden;
	.item-invites{
		justify-content: space-between;
		font-family: PingFangSC, PingFang SC;
		font-weight: 400;
		color: rgba(0,0,0,0.85);
		margin-top: 24px;
		.left{
			width: calc(100% - 150px);
			.user-invites{
				margin-left: 36px;
				.invits-top{
					.username{
						font-size: 16px;
						line-height: 21px;
					}
					.userTag{
						padding: 0px 3.34px;
						border-radius: 4px;
						border: 1px solid #4077DD;
						font-size: 12px;
						color: #4077DD;
						line-height: 21px;
						margin-left: 8px;
					}
					::v-deep .el-rate{
						margin-left: 24px;
					}
				}
				.invites-sn{
					font-size: 14px;
					margin-top: 12px;
				}
				.invites-tags{
					margin-top: 6px;
					line-height: 30px;
					span{
						width: 89px;
						height: 22px;
						background: #F5F7FA;
						border-radius: 4px;
						font-size: 12px;
						color: #555C70;
						line-height: 20px;
						margin-right: 8px;
						padding: 3px 8px;
					}
				}
				.invites-fen{
					margin-top: 12px;
					.invites_lines{
						margin-right: 24px;
						.numbers{
							font-size: 14px;
						}
						.text{
							font-size: 12px;
						}
					}
				}
			}
		}
		.right{
			padding-right: 10px;
		}
	}
}



</style>
