import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import './static/css/iconfont.css';
import './static/css/HYk1gj.css';
import http from './common/httpRequest.js';
import mHelper from './common/helper.js';

import * as filters from './common/filters.js'
import './static/css/theme/index.css'
//验证
import $mGraceChecker from './common/utils/graceChecker.js';
import $mFormRule from './common/utils/formRule.config.js';

// import echarts from 'echarts'
const echarts = require('echarts');

import {
	Message
} from 'element-ui'
Object.keys(filters).forEach(key => {
	Vue.filter(key, filters[key])
})

Vue.prototype.$Message = Message
Vue.config.productionTip = false
Vue.prototype.$http = http
Vue.prototype.$mHelper = mHelper
Vue.prototype.$echarts = echarts
//验证
Vue.prototype.$mGraceChecker = $mGraceChecker;
Vue.prototype.$mFormRule = $mFormRule;

router.afterEach((to, from, next) => {
	window.scrollTo(0, 0);
})

const Logins = () => {
	//console.log(store.state.token);
	if (store.state.token) {
		Vue.prototype.$http.get('user/getUserInfo').then(res => {
			if (res.code == 200) {
				if(res.data.identity==1){
					res.data.showName=res.data.nickname
				}
				if(res.data.identity==2){
					res.data.showName=res.data.name.slice(0, 1) + '工'
				}
				if(res.data.identity==3){
					res.data.showName=res.data.nickname.slice(0, 1) + '经理'
				}
				store.commit('saveUser', {
					userInfo: res.data
				})
			} else {
				store.commit('outLogin')
			}
		})
	}
};

Vue.prototype.$uniApi = {
	Logins
};

Vue.use(ElementUI, {
	zIndex: 100000
})
Vue.use(ElementUI);

new Vue({
	axios,
	router,
	store,
	http,
	render: h => h(App)
}).$mount('#app')
