const options = [{
		"_id": "61baf93039bf10000127d45a",
		"value": "110000",
		"first_letter": "B",
		"text": "北京市",
		"pinyin": "beijingshi",
		"type": 0,
		"zip_value": "100000",
		"children": [{
			"_id": "61baf93039bf10000127d45b",
			"value": "110100",
			"first_letter": "B",
			"text": "北京市",
			"parent_value": "110000",
			"pinyin": "beijingshi",
			"type": 1,
			"zip_value": "100000"
		}]
	},
	{
		"_id": "61baf93039bf10000127d46c",
		"value": "120000",
		"first_letter": "T",
		"text": "天津市",
		"pinyin": "tianjinshi",
		"type": 0,
		"zip_value": "300000",
		"children": [{
			"_id": "61baf93039bf10000127d46d",
			"value": "120100",
			"first_letter": "T",
			"text": "天津市",
			"parent_value": "120000",
			"pinyin": "tianjinshi",
			"type": 1,
			"zip_value": "300000"
		}]
	},
	{
		"_id": "61baf93039bf10000127d47e",
		"value": "130000",
		"first_letter": "H",
		"text": "河北省",
		"pinyin": "hebeisheng",
		"type": 0,
		"zip_value": "0",
		"children": [{
				"_id": "61baf93039bf10000127d47f",
				"value": "130100",
				"first_letter": "S",
				"text": "石家庄市",
				"parent_value": "130000",
				"pinyin": "shijiazhuangshi",
				"type": 1,
				"zip_value": "050000"
			},
			{
				"_id": "61baf93039bf10000127d496",
				"value": "130200",
				"first_letter": "T",
				"text": "唐山市",
				"parent_value": "130000",
				"pinyin": "tangshanshi",
				"type": 1,
				"zip_value": "063000"
			},
			{
				"_id": "61baf93039bf10000127d4a5",
				"value": "130300",
				"first_letter": "Q",
				"text": "秦皇岛市",
				"parent_value": "130000",
				"pinyin": "qinhuangdaoshi",
				"type": 1,
				"zip_value": "066000"
			},
			{
				"_id": "61baf93039bf10000127d4ad",
				"value": "130400",
				"first_letter": "H",
				"text": "邯郸市",
				"parent_value": "130000",
				"pinyin": "handanshi",
				"type": 1,
				"zip_value": "056000"
			},
			{
				"_id": "61baf93039bf10000127d4c0",
				"value": "130500",
				"first_letter": "X",
				"text": "邢台市",
				"parent_value": "130000",
				"pinyin": "xingtaishi",
				"type": 1,
				"zip_value": "054000"
			},
			{
				"_id": "61baf93039bf10000127d4d4",
				"value": "130600",
				"first_letter": "B",
				"text": "保定市",
				"parent_value": "130000",
				"pinyin": "baodingshi",
				"type": 1,
				"zip_value": "071000"
			},
			{
				"_id": "61baf93039bf10000127d4ed",
				"value": "130700",
				"first_letter": "Z",
				"text": "张家口市",
				"parent_value": "130000",
				"pinyin": "zhangjiakoushi",
				"type": 1,
				"zip_value": "075000"
			},
			{
				"_id": "61baf93039bf10000127d4fe",
				"value": "130800",
				"first_letter": "C",
				"text": "承德市",
				"parent_value": "130000",
				"pinyin": "chengdeshi",
				"type": 1,
				"zip_value": "067000"
			},
			{
				"_id": "61baf93039bf10000127d50a",
				"value": "130900",
				"first_letter": "C",
				"text": "沧州市",
				"parent_value": "130000",
				"pinyin": "cangzhoushi",
				"type": 1,
				"zip_value": "061000"
			},
			{
				"_id": "61baf93039bf10000127d51b",
				"value": "131000",
				"first_letter": "L",
				"text": "廊坊市",
				"parent_value": "130000",
				"pinyin": "langfangshi",
				"type": 1,
				"zip_value": "065000"
			},
			{
				"_id": "61baf93039bf10000127d526",
				"value": "131100",
				"first_letter": "H",
				"text": "衡水市",
				"parent_value": "130000",
				"pinyin": "hengshuishi",
				"type": 1,
				"zip_value": "053000"
			}
		]

	},
	{
		"_id": "61baf93039bf10000127d532",
		"value": "140000",
		"first_letter": "S",
		"text": "山西省",
		"pinyin": "shanxisheng",
		"type": 0,
		"zip_value": "0",
		"children": [{
				"_id": "61baf93039bf10000127d533",
				"value": "140100",
				"first_letter": "T",
				"text": "太原市",
				"parent_value": "140000",
				"pinyin": "taiyuanshi",
				"type": 1,
				"zip_value": "030000"
			},
			{
				"_id": "61baf93039bf10000127d53e",
				"value": "140200",
				"first_letter": "D",
				"text": "大同市",
				"parent_value": "140000",
				"pinyin": "datongshi",
				"type": 1,
				"zip_value": "037000"
			},
			{
				"_id": "61baf93039bf10000127d54a",
				"value": "140300",
				"first_letter": "Y",
				"text": "阳泉市",
				"parent_value": "140000",
				"pinyin": "yangquanshi",
				"type": 1,
				"zip_value": "045000"
			},
			{
				"_id": "61baf93039bf10000127d550",
				"value": "140400",
				"first_letter": "C",
				"text": "长治市",
				"parent_value": "140000",
				"pinyin": "changzhishi",
				"type": 1,
				"zip_value": "046000"
			},
			{
				"_id": "61baf93039bf10000127d55e",
				"value": "140500",
				"first_letter": "J",
				"text": "晋城市",
				"parent_value": "140000",
				"pinyin": "jinchengshi",
				"type": 1,
				"zip_value": "048000"
			},
			{
				"_id": "61baf93039bf10000127d565",
				"value": "140600",
				"first_letter": "S",
				"text": "朔州市",
				"parent_value": "140000",
				"pinyin": "shuozhoushi",
				"type": 1,
				"zip_value": "038500"
			},
			{
				"_id": "61baf93039bf10000127d56c",
				"value": "140700",
				"first_letter": "J",
				"text": "晋中市",
				"parent_value": "140000",
				"pinyin": "jinzhongshi",
				"type": 1,
				"zip_value": "038300"
			},
			{
				"_id": "61baf93039bf10000127d578",
				"value": "140800",
				"first_letter": "Y",
				"text": "运城市",
				"parent_value": "140000",
				"pinyin": "yunchengshi",
				"type": 1,
				"zip_value": "044000"
			},
			{
				"_id": "61baf93039bf10000127d586",
				"value": "140900",
				"first_letter": "X",
				"text": "忻州市",
				"parent_value": "140000",
				"pinyin": "xinzhoushi",
				"type": 1,
				"zip_value": "034000"
			},
			{
				"_id": "61baf93039bf10000127d595",
				"value": "141000",
				"first_letter": "L",
				"text": "临汾市",
				"parent_value": "140000",
				"pinyin": "linfenshi",
				"type": 1,
				"zip_value": "041000"
			},
			{
				"_id": "61baf93039bf10000127d5a7",
				"value": "141100",
				"first_letter": "L",
				"text": "吕梁市",
				"parent_value": "140000",
				"pinyin": "lvliangshi",
				"type": 1,
				"zip_value": "033000"
			}
		]

	},
	{
		"_id": "61baf93039bf10000127d5b5",
		"value": "150000",
		"first_letter": "N",
		"text": "内蒙古自治区",
		"pinyin": "neimengguzizhiqu",
		"type": 0,
		"zip_value": "0",
		"children": [{
				"_id": "61baf93039bf10000127d5b6",
				"value": "150100",
				"first_letter": "H",
				"text": "呼和浩特市",
				"parent_value": "150000",
				"pinyin": "huhehaoteshi",
				"type": 1,
				"zip_value": "010000"
			},
			{
				"_id": "61baf93039bf10000127d5c0",
				"value": "150200",
				"first_letter": "B",
				"text": "包头市",
				"parent_value": "150000",
				"pinyin": "baotoushi",
				"type": 1,
				"zip_value": "014000"
			},
			{
				"_id": "61baf93039bf10000127d5ca",
				"value": "150300",
				"first_letter": "W",
				"text": "乌海市",
				"parent_value": "150000",
				"pinyin": "wuhaishi",
				"type": 1,
				"zip_value": "016000"
			},
			{
				"_id": "61baf93039bf10000127d5ce",
				"value": "150400",
				"first_letter": "C",
				"text": "赤峰市",
				"parent_value": "150000",
				"pinyin": "chifengshi",
				"type": 1,
				"zip_value": "024000"
			},
			{
				"_id": "61baf93039bf10000127d5db",
				"value": "150500",
				"first_letter": "T",
				"text": "通辽市",
				"parent_value": "150000",
				"pinyin": "tongliaoshi",
				"type": 1,
				"zip_value": "028000"
			},
			{
				"_id": "61baf93039bf10000127d5e4",
				"value": "150600",
				"first_letter": "E",
				"text": "鄂尔多斯市",
				"parent_value": "150000",
				"pinyin": "eerduosishi",
				"type": 1,
				"zip_value": "017000"
			},
			{
				"_id": "61baf93039bf10000127d5ee",
				"value": "150700",
				"first_letter": "H",
				"text": "呼伦贝尔市",
				"parent_value": "150000",
				"pinyin": "hulunbeiershi",
				"type": 1,
				"zip_value": "021000"
			},
			{
				"_id": "61baf93039bf10000127d5fd",
				"value": "150800",
				"first_letter": "B",
				"text": "巴彦淖尔市",
				"parent_value": "150000",
				"pinyin": "bayannaoershi",
				"type": 1,
				"zip_value": "015000"
			},
			{
				"_id": "61baf93039bf10000127d605",
				"value": "150900",
				"first_letter": "W",
				"text": "乌兰察布市",
				"parent_value": "150000",
				"pinyin": "wulanchabushi",
				"type": 1,
				"zip_value": "0"
			},
			{
				"_id": "61baf93039bf10000127d611",
				"value": "152200",
				"first_letter": "X",
				"text": "兴安盟",
				"parent_value": "150000",
				"pinyin": "xinganmeng",
				"type": 1,
				"zip_value": "0"
			},
			{
				"_id": "61baf93039bf10000127d618",
				"value": "152500",
				"first_letter": "X",
				"text": "锡林郭勒盟",
				"parent_value": "150000",
				"pinyin": "xilinguoleimeng",
				"type": 1,
				"zip_value": "026000"
			},
			{
				"_id": "61baf93039bf10000127d625",
				"value": "152900",
				"first_letter": "A",
				"text": "阿拉善盟",
				"parent_value": "150000",
				"pinyin": "alashanmeng",
				"type": 1,
				"zip_value": "0"
			}
		]
	},
	{
		"_id": "61baf93039bf10000127d629",
		"value": "210000",
		"first_letter": "L",
		"text": "辽宁省",
		"pinyin": "liaoningsheng",
		"type": 0,
		"zip_value": "0",
		"children": [{
				"_id": "61baf93039bf10000127d62a",
				"value": "210100",
				"first_letter": "S",
				"text": "沈阳市",
				"parent_value": "210000",
				"pinyin": "shenyangshi",
				"type": 1,
				"zip_value": "110000"
			},
			{
				"_id": "61baf93039bf10000127d638",
				"value": "210200",
				"first_letter": "D",
				"text": "大连市",
				"parent_value": "210000",
				"pinyin": "dalianshi",
				"type": 1,
				"zip_value": "116000"
			},
			{
				"_id": "61baf93039bf10000127d643",
				"value": "210300",
				"first_letter": "A",
				"text": "鞍山市",
				"parent_value": "210000",
				"pinyin": "anshanshi",
				"type": 1,
				"zip_value": "114000"
			},
			{
				"_id": "61baf93039bf10000127d64b",
				"value": "210400",
				"first_letter": "F",
				"text": "抚顺市",
				"parent_value": "210000",
				"pinyin": "fushunshi",
				"type": 1,
				"zip_value": "113000"
			},
			{
				"_id": "61baf93039bf10000127d653",
				"value": "210500",
				"first_letter": "B",
				"text": "本溪市",
				"parent_value": "210000",
				"pinyin": "benxishi",
				"type": 1,
				"zip_value": "117000"
			},
			{
				"_id": "61baf93039bf10000127d65a",
				"value": "210600",
				"first_letter": "D",
				"text": "丹东市",
				"parent_value": "210000",
				"pinyin": "dandongshi",
				"type": 1,
				"zip_value": "118000"
			},
			{
				"_id": "61baf93039bf10000127d661",
				"value": "210700",
				"first_letter": "J",
				"text": "锦州市",
				"parent_value": "210000",
				"pinyin": "jinzhoushi",
				"type": 1,
				"zip_value": "121000"
			},
			{
				"_id": "61baf93039bf10000127d669",
				"value": "210800",
				"first_letter": "Y",
				"text": "营口市",
				"parent_value": "210000",
				"pinyin": "yingkoushi",
				"type": 1,
				"zip_value": "115000"
			},
			{
				"_id": "61baf93039bf10000127d670",
				"value": "210900",
				"first_letter": "F",
				"text": "阜新市",
				"parent_value": "210000",
				"pinyin": "fuxinshi",
				"type": 1,
				"zip_value": "123000"
			},
			{
				"_id": "61baf93039bf10000127d678",
				"value": "211000",
				"first_letter": "L",
				"text": "辽阳市",
				"parent_value": "210000",
				"pinyin": "liaoyangshi",
				"type": 1,
				"zip_value": "111000"
			},
			{
				"_id": "61baf93039bf10000127d680",
				"value": "211100",
				"first_letter": "P",
				"text": "盘锦市",
				"parent_value": "210000",
				"pinyin": "panjinshi",
				"type": 1,
				"zip_value": "124000"
			},
			{
				"_id": "61baf93039bf10000127d685",
				"value": "211200",
				"first_letter": "T",
				"text": "铁岭市",
				"parent_value": "210000",
				"pinyin": "tielingshi",
				"type": 1,
				"zip_value": "112000"
			},
			{
				"_id": "61baf93039bf10000127d68d",
				"value": "211300",
				"first_letter": "C",
				"text": "朝阳市",
				"parent_value": "210000",
				"pinyin": "chaoyangshi",
				"type": 1,
				"zip_value": "122000"
			},
			{
				"_id": "61baf93039bf10000127d695",
				"value": "211400",
				"first_letter": "H",
				"text": "葫芦岛市",
				"parent_value": "210000",
				"pinyin": "huludaoshi",
				"type": 1,
				"zip_value": "125000"
			}
		]

	},
	{
		"_id": "61baf93039bf10000127d69c",
		"value": "220000",
		"first_letter": "J",
		"text": "吉林省",
		"pinyin": "jilinsheng",
		"type": 0,
		"zip_value": "0",
		"children": [{
				"_id": "61baf93039bf10000127d69d",
				"value": "220100",
				"first_letter": "C",
				"text": "长春市",
				"parent_value": "220000",
				"pinyin": "changchunshi",
				"type": 1,
				"zip_value": "130000"
			},
			{
				"_id": "61baf93039bf10000127d6a8",
				"value": "220200",
				"first_letter": "J",
				"text": "吉林市",
				"parent_value": "220000",
				"pinyin": "jilinshi",
				"type": 1,
				"zip_value": "132000"
			},
			{
				"_id": "61baf93039bf10000127d6b2",
				"value": "220300",
				"first_letter": "S",
				"text": "四平市",
				"parent_value": "220000",
				"pinyin": "sipingshi",
				"type": 1,
				"zip_value": "136000"
			},
			{
				"_id": "61baf93039bf10000127d6b9",
				"value": "220400",
				"first_letter": "L",
				"text": "辽源市",
				"parent_value": "220000",
				"pinyin": "liaoyuanshi",
				"type": 1,
				"zip_value": "136200"
			},
			{
				"_id": "61baf93039bf10000127d6be",
				"value": "220500",
				"first_letter": "T",
				"text": "通化市",
				"parent_value": "220000",
				"pinyin": "tonghuashi",
				"type": 1,
				"zip_value": "134000"
			},
			{
				"_id": "61baf93039bf10000127d6c6",
				"value": "220600",
				"first_letter": "B",
				"text": "白山市",
				"parent_value": "220000",
				"pinyin": "baishanshi",
				"type": 1,
				"zip_value": "134300"
			},
			{
				"_id": "61baf93039bf10000127d6cd",
				"value": "220700",
				"first_letter": "S",
				"text": "松原市",
				"parent_value": "220000",
				"pinyin": "songyuanshi",
				"type": 1,
				"zip_value": "138000"
			},
			{
				"_id": "61baf93039bf10000127d6d3",
				"value": "220800",
				"first_letter": "B",
				"text": "白城市",
				"parent_value": "220000",
				"pinyin": "baichengshi",
				"type": 1,
				"zip_value": "137000"
			},
			{
				"_id": "61baf93039bf10000127d6d9",
				"value": "222400",
				"first_letter": "Y",
				"text": "延边朝鲜族自治州",
				"parent_value": "220000",
				"pinyin": "yanbianchaoxianzuzizhizhou",
				"type": 1,
				"zip_value": "133000"
			}
		]

	},
	{
		"_id": "61baf93039bf10000127d6e2",
		"value": "230000",
		"first_letter": "H",
		"text": "黑龙江省",
		"pinyin": "heilongjiangsheng",
		"type": 0,
		"zip_value": "0",
		"children": [{
				"_id": "61baf93039bf10000127d6e3",
				"value": "230100",
				"first_letter": "H",
				"text": "哈尔滨市",
				"parent_value": "230000",
				"pinyin": "haerbinshi",
				"type": 1,
				"zip_value": "150000"
			},
			{
				"_id": "61baf93039bf10000127d6f6",
				"value": "230200",
				"first_letter": "Q",
				"text": "齐齐哈尔市",
				"parent_value": "230000",
				"pinyin": "qiqihaershi",
				"type": 1,
				"zip_value": "161000"
			},
			{
				"_id": "61baf93039bf10000127d707",
				"value": "230300",
				"first_letter": "J",
				"text": "鸡西市",
				"parent_value": "230000",
				"pinyin": "jixishi",
				"type": 1,
				"zip_value": "158100"
			},
			{
				"_id": "61baf93039bf10000127d711",
				"value": "230400",
				"first_letter": "H",
				"text": "鹤岗市",
				"parent_value": "230000",
				"pinyin": "hegangshi",
				"type": 1,
				"zip_value": "154100"
			},
			{
				"_id": "61baf93039bf10000127d71a",
				"value": "230500",
				"first_letter": "S",
				"text": "双鸭山市",
				"parent_value": "230000",
				"pinyin": "shuangyashanshi",
				"type": 1,
				"zip_value": "155100"
			},
			{
				"_id": "61baf93039bf10000127d723",
				"value": "230600",
				"first_letter": "D",
				"text": "大庆市",
				"parent_value": "230000",
				"pinyin": "daqingshi",
				"type": 1,
				"zip_value": "163000"
			},
			{
				"_id": "61baf93039bf10000127d72d",
				"value": "230700",
				"first_letter": "Y",
				"text": "伊春市",
				"parent_value": "230000",
				"pinyin": "yichunshi",
				"type": 1,
				"zip_value": "153000"
			},
			{
				"_id": "61baf93039bf10000127d73f",
				"value": "230800",
				"first_letter": "J",
				"text": "佳木斯市",
				"parent_value": "230000",
				"pinyin": "jiamusishi",
				"type": 1,
				"zip_value": "154000"
			},
			{
				"_id": "61baf93039bf10000127d74a",
				"value": "230900",
				"first_letter": "Q",
				"text": "七台河市",
				"parent_value": "230000",
				"pinyin": "qitaiheshi",
				"type": 1,
				"zip_value": "154600"
			},
			{
				"_id": "61baf93039bf10000127d74f",
				"value": "231000",
				"first_letter": "M",
				"text": "牡丹江市",
				"parent_value": "230000",
				"pinyin": "mudanjiangshi",
				"type": 1,
				"zip_value": "157000"
			},
			{
				"_id": "61baf93039bf10000127d75a",
				"value": "231100",
				"first_letter": "H",
				"text": "黑河市",
				"parent_value": "230000",
				"pinyin": "heiheshi",
				"type": 1,
				"zip_value": "164300"
			},
			{
				"_id": "61baf93039bf10000127d761",
				"value": "231200",
				"first_letter": "S",
				"text": "绥化市",
				"parent_value": "230000",
				"pinyin": "suihuashi",
				"type": 1,
				"zip_value": "152000"
			},
			{
				"_id": "61baf93039bf10000127d76c",
				"value": "232700",
				"first_letter": "D",
				"text": "大兴安岭地区",
				"parent_value": "230000",
				"pinyin": "daxinganlingdiqu",
				"type": 1,
				"zip_value": "165000"
			}
		]

	},
	{
		"_id": "61baf93039bf10000127d774",
		"value": "310000",
		"first_letter": "S",
		"text": "上海市",
		"pinyin": "shanghaishi",
		"type": 0,
		"zip_value": "200000",
		"children": [{
			"_id": "61baf93039bf10000127d775",
			"value": "310100",
			"first_letter": "S",
			"text": "上海市",
			"parent_value": "310000",
			"pinyin": "shanghaishi",
			"type": 1,
			"zip_value": "200000"
		}]
	},
	{
		"_id": "61baf93039bf10000127d786",
		"value": "320000",
		"first_letter": "J",
		"text": "江苏省",
		"pinyin": "jiangsusheng",
		"type": 0,
		"zip_value": "0",
		"children": [{
				"_id": "61baf93039bf10000127d787",
				"value": "320100",
				"first_letter": "N",
				"text": "南京市",
				"parent_value": "320000",
				"pinyin": "nanjingshi",
				"type": 1,
				"zip_value": "210000"
			},
			{
				"_id": "61baf93039bf10000127d793",
				"value": "320200",
				"first_letter": "W",
				"text": "无锡市",
				"parent_value": "320000",
				"pinyin": "wuxishi",
				"type": 1,
				"zip_value": "214000"
			},
			{
				"_id": "61baf93039bf10000127d79b",
				"value": "320300",
				"first_letter": "X",
				"text": "徐州市",
				"parent_value": "320000",
				"pinyin": "xuzhoushi",
				"type": 1,
				"zip_value": "221000"
			},
			{
				"_id": "61baf93039bf10000127d7a6",
				"value": "320400",
				"first_letter": "C",
				"text": "常州市",
				"parent_value": "320000",
				"pinyin": "changzhoushi",
				"type": 1,
				"zip_value": "213000"
			},
			{
				"_id": "61baf93039bf10000127d7ad",
				"value": "320500",
				"first_letter": "S",
				"text": "苏州市",
				"parent_value": "320000",
				"pinyin": "suzhoushi",
				"type": 1,
				"zip_value": "215000"
			},
			{
				"_id": "61baf93039bf10000127d7b7",
				"value": "320600",
				"first_letter": "N",
				"text": "南通市",
				"parent_value": "320000",
				"pinyin": "nantongshi",
				"type": 1,
				"zip_value": "226000"
			},
			{
				"_id": "61baf93039bf10000127d7c0",
				"value": "320700",
				"first_letter": "L",
				"text": "连云港市",
				"parent_value": "320000",
				"pinyin": "lianyungangshi",
				"type": 1,
				"zip_value": "222000"
			},
			{
				"_id": "61baf93039bf10000127d7c7",
				"value": "320800",
				"first_letter": "H",
				"text": "淮安市",
				"parent_value": "320000",
				"pinyin": "huaianshi",
				"type": 1,
				"zip_value": "223001"
			},
			{
				"_id": "61baf93039bf10000127d7cf",
				"value": "320900",
				"first_letter": "Y",
				"text": "盐城市",
				"parent_value": "320000",
				"pinyin": "yanchengshi",
				"type": 1,
				"zip_value": "224000"
			},
			{
				"_id": "61baf93039bf10000127d7d9",
				"value": "321000",
				"first_letter": "Y",
				"text": "扬州市",
				"parent_value": "320000",
				"pinyin": "yangzhoushi",
				"type": 1,
				"zip_value": "225000"
			},
			{
				"_id": "61baf93039bf10000127d7e0",
				"value": "321100",
				"first_letter": "Z",
				"text": "镇江市",
				"parent_value": "320000",
				"pinyin": "zhenjiangshi",
				"type": 1,
				"zip_value": "212000"
			},
			{
				"_id": "61baf93039bf10000127d7e7",
				"value": "321200",
				"first_letter": "T",
				"text": "泰州市",
				"parent_value": "320000",
				"pinyin": "taizhoushi",
				"type": 1,
				"zip_value": "225300"
			},
			{
				"_id": "61baf93039bf10000127d7ee",
				"value": "321300",
				"first_letter": "S",
				"text": "宿迁市",
				"parent_value": "320000",
				"pinyin": "suqianshi",
				"type": 1,
				"zip_value": "223800"
			}
		]

	},
	{
		"_id": "61baf93039bf10000127d7f4",
		"value": "330000",
		"first_letter": "Z",
		"text": "浙江省",
		"pinyin": "zhejiangsheng",
		"type": 0,
		"zip_value": "0",
		"children": [{
				"_id": "61baf93039bf10000127d7f5",
				"value": "330100",
				"first_letter": "H",
				"text": "杭州市",
				"parent_value": "330000",
				"pinyin": "hangzhoushi",
				"type": 1,
				"zip_value": "310000"
			},
			{
				"_id": "61baf93039bf10000127d803",
				"value": "330200",
				"first_letter": "N",
				"text": "宁波市",
				"parent_value": "330000",
				"pinyin": "ningboshi",
				"type": 1,
				"zip_value": "315000"
			},
			{
				"_id": "61baf93039bf10000127d80e",
				"value": "330300",
				"first_letter": "W",
				"text": "温州市",
				"parent_value": "330000",
				"pinyin": "wenzhoushi",
				"type": 1,
				"zip_value": "325000"
			},
			{
				"_id": "61baf93039bf10000127d81a",
				"value": "330400",
				"first_letter": "J",
				"text": "嘉兴市",
				"parent_value": "330000",
				"pinyin": "jiaxingshi",
				"type": 1,
				"zip_value": "314000"
			},
			{
				"_id": "61baf93039bf10000127d822",
				"value": "330500",
				"first_letter": "H",
				"text": "湖州市",
				"parent_value": "330000",
				"pinyin": "huzhoushi",
				"type": 1,
				"zip_value": "313000"
			},
			{
				"_id": "61baf93039bf10000127d828",
				"value": "330600",
				"first_letter": "S",
				"text": "绍兴市",
				"parent_value": "330000",
				"pinyin": "shaoxingshi",
				"type": 1,
				"zip_value": "312000"
			},
			{
				"_id": "61baf93039bf10000127d82f",
				"value": "330700",
				"first_letter": "J",
				"text": "金华市",
				"parent_value": "330000",
				"pinyin": "jinhuashi",
				"type": 1,
				"zip_value": "321000"
			},
			{
				"_id": "61baf93039bf10000127d839",
				"value": "330800",
				"first_letter": "Q",
				"text": "衢州市",
				"parent_value": "330000",
				"pinyin": "quzhoushi",
				"type": 1,
				"zip_value": "324000"
			},
			{
				"_id": "61baf93039bf10000127d840",
				"value": "330900",
				"first_letter": "Z",
				"text": "舟山市",
				"parent_value": "330000",
				"pinyin": "zhoushanshi",
				"type": 1,
				"zip_value": "316000"
			},
			{
				"_id": "61baf93039bf10000127d845",
				"value": "331000",
				"first_letter": "T",
				"text": "台州市",
				"parent_value": "330000",
				"pinyin": "taizhoushi",
				"type": 1,
				"zip_value": "318000"
			},
			{
				"_id": "61baf93039bf10000127d84f",
				"value": "331100",
				"first_letter": "L",
				"text": "丽水市",
				"parent_value": "330000",
				"pinyin": "lishuishi",
				"type": 1,
				"zip_value": "323000"
			}
		]

	},
	{
		"_id": "61baf93039bf10000127d859",
		"value": "340000",
		"first_letter": "A",
		"text": "安徽省",
		"pinyin": "anhuisheng",
		"type": 0,
		"zip_value": "0",
		"children": [{
				"_id": "61baf93039bf10000127d85a",
				"value": "340100",
				"first_letter": "H",
				"text": "合肥市",
				"parent_value": "340000",
				"pinyin": "hefeishi",
				"type": 1,
				"zip_value": "230000"
			},
			{
				"_id": "61baf93039bf10000127d864",
				"value": "340200",
				"first_letter": "W",
				"text": "芜湖市",
				"parent_value": "340000",
				"pinyin": "wuhushi",
				"type": 1,
				"zip_value": "241000"
			},
			{
				"_id": "61baf93039bf10000127d86d",
				"value": "340300",
				"first_letter": "B",
				"text": "蚌埠市",
				"parent_value": "340000",
				"pinyin": "bengbushi",
				"type": 1,
				"zip_value": "233000"
			},
			{
				"_id": "61baf93039bf10000127d875",
				"value": "340400",
				"first_letter": "H",
				"text": "淮南市",
				"parent_value": "340000",
				"pinyin": "huainanshi",
				"type": 1,
				"zip_value": "232000"
			},
			{
				"_id": "61baf93039bf10000127d87d",
				"value": "340500",
				"first_letter": "M",
				"text": "马鞍山市",
				"parent_value": "340000",
				"pinyin": "maanshanshi",
				"type": 1,
				"zip_value": "243000"
			},
			{
				"_id": "61baf93039bf10000127d884",
				"value": "340600",
				"first_letter": "H",
				"text": "淮北市",
				"parent_value": "340000",
				"pinyin": "huaibeishi",
				"type": 1,
				"zip_value": "235000"
			},
			{
				"_id": "61baf93039bf10000127d889",
				"value": "340700",
				"first_letter": "T",
				"text": "铜陵市",
				"parent_value": "340000",
				"pinyin": "tonglingshi",
				"type": 1,
				"zip_value": "244000"
			},
			{
				"_id": "61baf93039bf10000127d88e",
				"value": "340800",
				"first_letter": "A",
				"text": "安庆市",
				"parent_value": "340000",
				"pinyin": "anqingshi",
				"type": 1,
				"zip_value": "246000"
			},
			{
				"_id": "61baf93039bf10000127d899",
				"value": "341000",
				"first_letter": "H",
				"text": "黄山市",
				"parent_value": "340000",
				"pinyin": "huangshanshi",
				"type": 1,
				"zip_value": "245000"
			},
			{
				"_id": "61baf93039bf10000127d8a1",
				"value": "341100",
				"first_letter": "C",
				"text": "滁州市",
				"parent_value": "340000",
				"pinyin": "chuzhoushi",
				"type": 1,
				"zip_value": "239000"
			},
			{
				"_id": "61baf93039bf10000127d8aa",
				"value": "341200",
				"first_letter": "F",
				"text": "阜阳市",
				"parent_value": "340000",
				"pinyin": "fuyangshi",
				"type": 1,
				"zip_value": "236000"
			},
			{
				"_id": "61baf93039bf10000127d8b3",
				"value": "341300",
				"first_letter": "S",
				"text": "宿州市",
				"parent_value": "340000",
				"pinyin": "suzhoushi",
				"type": 1,
				"zip_value": "234000"
			},
			{
				"_id": "61baf93039bf10000127d8b9",
				"value": "341500",
				"first_letter": "L",
				"text": "六安市",
				"parent_value": "340000",
				"pinyin": "liuanshi",
				"type": 1,
				"zip_value": "237000"
			},
			{
				"_id": "61baf93039bf10000127d8c1",
				"value": "341600",
				"first_letter": "B",
				"text": "亳州市",
				"parent_value": "340000",
				"pinyin": "bozhoushi",
				"type": 1,
				"zip_value": "236000"
			},
			{
				"_id": "61baf93039bf10000127d8c6",
				"value": "341700",
				"first_letter": "C",
				"text": "池州市",
				"parent_value": "340000",
				"pinyin": "chizhoushi",
				"type": 1,
				"zip_value": "247100"
			},
			{
				"_id": "61baf93039bf10000127d8cb",
				"value": "341800",
				"first_letter": "X",
				"text": "宣城市",
				"parent_value": "340000",
				"pinyin": "xuanchengshi",
				"type": 1,
				"zip_value": "242000"
			}
		]

	},
	{
		"_id": "61baf93039bf10000127d8d3",
		"value": "350000",
		"first_letter": "F",
		"text": "福建省",
		"pinyin": "fujiansheng",
		"type": 0,
		"zip_value": "0",
		"children": [{
				"_id": "61baf93039bf10000127d8d4",
				"value": "350100",
				"first_letter": "F",
				"text": "福州市",
				"parent_value": "350000",
				"pinyin": "fuzhoushi",
				"type": 1,
				"zip_value": "350000"
			},
			{
				"_id": "61baf93039bf10000127d8e2",
				"value": "350200",
				"first_letter": "X",
				"text": "厦门市",
				"parent_value": "350000",
				"pinyin": "xiamenshi",
				"type": 1,
				"zip_value": "361000"
			},
			{
				"_id": "61baf93039bf10000127d8e9",
				"value": "350300",
				"first_letter": "P",
				"text": "莆田市",
				"parent_value": "350000",
				"pinyin": "putianshi",
				"type": 1,
				"zip_value": "351100"
			},
			{
				"_id": "61baf93039bf10000127d8ef",
				"value": "350400",
				"first_letter": "S",
				"text": "三明市",
				"parent_value": "350000",
				"pinyin": "sanmingshi",
				"type": 1,
				"zip_value": "365000"
			},
			{
				"_id": "61baf93039bf10000127d8fc",
				"value": "350500",
				"first_letter": "Q",
				"text": "泉州市",
				"parent_value": "350000",
				"pinyin": "quanzhoushi",
				"type": 1,
				"zip_value": "362000"
			},
			{
				"_id": "61baf93039bf10000127d909",
				"value": "350600",
				"first_letter": "Z",
				"text": "漳州市",
				"parent_value": "350000",
				"pinyin": "zhangzhoushi",
				"type": 1,
				"zip_value": "363000"
			},
			{
				"_id": "61baf93039bf10000127d915",
				"value": "350700",
				"first_letter": "N",
				"text": "南平市",
				"parent_value": "350000",
				"pinyin": "nanpingshi",
				"type": 1,
				"zip_value": "353000"
			},
			{
				"_id": "61baf93039bf10000127d920",
				"value": "350800",
				"first_letter": "L",
				"text": "龙岩市",
				"parent_value": "350000",
				"pinyin": "longyanshi",
				"type": 1,
				"zip_value": "364000"
			},
			{
				"_id": "61baf93039bf10000127d928",
				"value": "350900",
				"first_letter": "N",
				"text": "宁德市",
				"parent_value": "350000",
				"pinyin": "ningdeshi",
				"type": 1,
				"zip_value": "352000"
			}
		]

	},
	{
		"_id": "61baf93039bf10000127d932",
		"value": "360000",
		"first_letter": "J",
		"text": "江西省",
		"pinyin": "jiangxisheng",
		"type": 0,
		"zip_value": "0",
		"children": [{
				"_id": "61baf93039bf10000127d933",
				"value": "360100",
				"first_letter": "N",
				"text": "南昌市",
				"parent_value": "360000",
				"pinyin": "nanchangshi",
				"type": 1,
				"zip_value": "330000"
			},
			{
				"_id": "61baf93039bf10000127d93d",
				"value": "360200",
				"first_letter": "J",
				"text": "景德镇市",
				"parent_value": "360000",
				"pinyin": "jingdezhenshi",
				"type": 1,
				"zip_value": "333000"
			},
			{
				"_id": "61baf93039bf10000127d942",
				"value": "360300",
				"first_letter": "P",
				"text": "萍乡市",
				"parent_value": "360000",
				"pinyin": "pingxiangshi",
				"type": 1,
				"zip_value": "337000"
			},
			{
				"_id": "61baf93039bf10000127d948",
				"value": "360400",
				"first_letter": "J",
				"text": "九江市",
				"parent_value": "360000",
				"pinyin": "jiujiangshi",
				"type": 1,
				"zip_value": "332000"
			},
			{
				"_id": "61baf93039bf10000127d956",
				"value": "360500",
				"first_letter": "X",
				"text": "新余市",
				"parent_value": "360000",
				"pinyin": "xinyushi",
				"type": 1,
				"zip_value": "336500"
			},
			{
				"_id": "61baf93039bf10000127d959",
				"value": "360600",
				"first_letter": "Y",
				"text": "鹰潭市",
				"parent_value": "360000",
				"pinyin": "yingtanshi",
				"type": 1,
				"zip_value": "335000"
			},
			{
				"_id": "61baf93039bf10000127d95d",
				"value": "360700",
				"first_letter": "G",
				"text": "赣州市",
				"parent_value": "360000",
				"pinyin": "ganzhoushi",
				"type": 1,
				"zip_value": "341000"
			},
			{
				"_id": "61baf93039bf10000127d970",
				"value": "360800",
				"first_letter": "J",
				"text": "吉安市",
				"parent_value": "360000",
				"pinyin": "jianshi",
				"type": 1,
				"zip_value": "343000"
			},
			{
				"_id": "61baf93039bf10000127d97e",
				"value": "360900",
				"first_letter": "Y",
				"text": "宜春市",
				"parent_value": "360000",
				"pinyin": "yichunshi",
				"type": 1,
				"zip_value": "336000"
			},
			{
				"_id": "61baf93039bf10000127d989",
				"value": "361000",
				"first_letter": "F",
				"text": "抚州市",
				"parent_value": "360000",
				"pinyin": "fuzhoushi",
				"type": 1,
				"zip_value": "344000"
			},
			{
				"_id": "61baf93039bf10000127d995",
				"value": "361100",
				"first_letter": "S",
				"text": "上饶市",
				"parent_value": "360000",
				"pinyin": "shangraoshi",
				"type": 1,
				"zip_value": "334000"
			}
		]

	},
	{
		"_id": "61baf93039bf10000127d9a2",
		"value": "370000",
		"first_letter": "S",
		"text": "山东省",
		"pinyin": "shandongsheng",
		"type": 0,
		"zip_value": "0",
		"children": [{
				"_id": "61baf93039bf10000127d9a3",
				"value": "370100",
				"first_letter": "J",
				"text": "济南市",
				"parent_value": "370000",
				"pinyin": "jinanshi",
				"type": 1,
				"zip_value": "250000"
			},
			{
				"_id": "61baf93039bf10000127d9ae",
				"value": "370200",
				"first_letter": "Q",
				"text": "青岛市",
				"parent_value": "370000",
				"pinyin": "qingdaoshi",
				"type": 1,
				"zip_value": "266000"
			},
			{
				"_id": "61baf93039bf10000127d9b9",
				"value": "370300",
				"first_letter": "Z",
				"text": "淄博市",
				"parent_value": "370000",
				"pinyin": "ziboshi",
				"type": 1,
				"zip_value": "255000"
			},
			{
				"_id": "61baf93039bf10000127d9c2",
				"value": "370400",
				"first_letter": "Z",
				"text": "枣庄市",
				"parent_value": "370000",
				"pinyin": "zaozhuangshi",
				"type": 1,
				"zip_value": "277000"
			},
			{
				"_id": "61baf93039bf10000127d9c9",
				"value": "370500",
				"first_letter": "D",
				"text": "东营市",
				"parent_value": "370000",
				"pinyin": "dongyingshi",
				"type": 1,
				"zip_value": "257000"
			},
			{
				"_id": "61baf93039bf10000127d9cf",
				"value": "370600",
				"first_letter": "Y",
				"text": "烟台市",
				"parent_value": "370000",
				"pinyin": "yantaishi",
				"type": 1,
				"zip_value": "264000"
			},
			{
				"_id": "61baf93039bf10000127d9dc",
				"value": "370700",
				"first_letter": "W",
				"text": "潍坊市",
				"parent_value": "370000",
				"pinyin": "weifangshi",
				"type": 1,
				"zip_value": "261000"
			},
			{
				"_id": "61baf93039bf10000127d9e9",
				"value": "370800",
				"first_letter": "J",
				"text": "济宁市",
				"parent_value": "370000",
				"pinyin": "jiningshi",
				"type": 1,
				"zip_value": "272000"
			},
			{
				"_id": "61baf93039bf10000127d9f5",
				"value": "370900",
				"first_letter": "T",
				"text": "泰安市",
				"parent_value": "370000",
				"pinyin": "taianshi",
				"type": 1,
				"zip_value": "271000"
			},
			{
				"_id": "61baf93039bf10000127d9fc",
				"value": "371000",
				"first_letter": "W",
				"text": "威海市",
				"parent_value": "370000",
				"pinyin": "weihaishi",
				"type": 1,
				"zip_value": "264200"
			},
			{
				"_id": "61baf93039bf10000127da01",
				"value": "371100",
				"first_letter": "R",
				"text": "日照市",
				"parent_value": "370000",
				"pinyin": "rizhaoshi",
				"type": 1,
				"zip_value": "276800"
			},
			{
				"_id": "61baf93039bf10000127da06",
				"value": "371200",
				"first_letter": "L",
				"text": "莱芜市",
				"parent_value": "370000",
				"pinyin": "laiwushi",
				"type": 1,
				"zip_value": "271100"
			},
			{
				"_id": "61baf93039bf10000127da09",
				"value": "371300",
				"first_letter": "L",
				"text": "临沂市",
				"parent_value": "370000",
				"pinyin": "linyishi",
				"type": 1,
				"zip_value": "276000"
			},
			{
				"_id": "61baf93039bf10000127da16",
				"value": "371400",
				"first_letter": "D",
				"text": "德州市",
				"parent_value": "370000",
				"pinyin": "dezhoushi",
				"type": 1,
				"zip_value": "253000"
			},
			{
				"_id": "61baf93039bf10000127da22",
				"value": "371500",
				"first_letter": "L",
				"text": "聊城市",
				"parent_value": "370000",
				"pinyin": "liaochengshi",
				"type": 1,
				"zip_value": "252000"
			},
			{
				"_id": "61baf93039bf10000127da2b",
				"value": "371600",
				"first_letter": "B",
				"text": "滨州市",
				"parent_value": "370000",
				"pinyin": "binzhoushi",
				"type": 1,
				"zip_value": "256600"
			},
			{
				"_id": "61baf93039bf10000127da33",
				"value": "371700",
				"first_letter": "H",
				"text": "菏泽市",
				"parent_value": "370000",
				"pinyin": "hezeshi",
				"type": 1,
				"zip_value": "0"
			}
		]

	},
	{
		"_id": "61baf93039bf10000127da3d",
		"value": "410000",
		"first_letter": "H",
		"text": "河南省",
		"pinyin": "henansheng",
		"type": 0,
		"zip_value": "0",
		"children": [{
				"_id": "61baf93039bf10000127da3e",
				"value": "410100",
				"first_letter": "Z",
				"text": "郑州市",
				"parent_value": "410000",
				"pinyin": "zhengzhoushi",
				"type": 1,
				"zip_value": "450000"
			},
			{
				"_id": "61baf93039bf10000127da4b",
				"value": "410200",
				"first_letter": "K",
				"text": "开封市",
				"parent_value": "410000",
				"pinyin": "kaifengshi",
				"type": 1,
				"zip_value": "475000"
			},
			{
				"_id": "61baf93039bf10000127da55",
				"value": "410300",
				"first_letter": "L",
				"text": "洛阳市",
				"parent_value": "410000",
				"pinyin": "luoyangshi",
				"type": 1,
				"zip_value": "471000"
			},
			{
				"_id": "61baf93039bf10000127da65",
				"value": "410400",
				"first_letter": "P",
				"text": "平顶山市",
				"parent_value": "410000",
				"pinyin": "pingdingshanshi",
				"type": 1,
				"zip_value": "467000"
			},
			{
				"_id": "61baf93039bf10000127da70",
				"value": "410500",
				"first_letter": "A",
				"text": "安阳市",
				"parent_value": "410000",
				"pinyin": "anyangshi",
				"type": 1,
				"zip_value": "0"
			},
			{
				"_id": "61baf93039bf10000127da7a",
				"value": "410600",
				"first_letter": "H",
				"text": "鹤壁市",
				"parent_value": "410000",
				"pinyin": "hebishi",
				"type": 1,
				"zip_value": "458000"
			},
			{
				"_id": "61baf93039bf10000127da80",
				"value": "410700",
				"first_letter": "X",
				"text": "新乡市",
				"parent_value": "410000",
				"pinyin": "xinxiangshi",
				"type": 1,
				"zip_value": "453000"
			},
			{
				"_id": "61baf93039bf10000127da8d",
				"value": "410800",
				"first_letter": "J",
				"text": "焦作市",
				"parent_value": "410000",
				"pinyin": "jiaozuoshi",
				"type": 1,
				"zip_value": "454150"
			},
			{
				"_id": "61baf93039bf10000127da98",
				"value": "410900",
				"first_letter": "P",
				"text": "濮阳市",
				"parent_value": "410000",
				"pinyin": "puyangshi",
				"type": 1,
				"zip_value": "457000"
			},
			{
				"_id": "61baf93039bf10000127da9f",
				"value": "411000",
				"first_letter": "X",
				"text": "许昌市",
				"parent_value": "410000",
				"pinyin": "xuchangshi",
				"type": 1,
				"zip_value": "461000"
			},
			{
				"_id": "61baf93039bf10000127daa6",
				"value": "411100",
				"first_letter": "L",
				"text": "漯河市",
				"parent_value": "410000",
				"pinyin": "luoheshi",
				"type": 1,
				"zip_value": "462000"
			},
			{
				"_id": "61baf93039bf10000127daac",
				"value": "411200",
				"first_letter": "S",
				"text": "三门峡市",
				"parent_value": "410000",
				"pinyin": "sanmenxiashi",
				"type": 1,
				"zip_value": "472000"
			},
			{
				"_id": "61baf93039bf10000127dab3",
				"value": "411300",
				"first_letter": "N",
				"text": "南阳市",
				"parent_value": "410000",
				"pinyin": "nanyangshi",
				"type": 1,
				"zip_value": "473000"
			},
			{
				"_id": "61baf93039bf10000127dac1",
				"value": "411400",
				"first_letter": "S",
				"text": "商丘市",
				"parent_value": "410000",
				"pinyin": "shangqiushi",
				"type": 1,
				"zip_value": "476000"
			},
			{
				"_id": "61baf93039bf10000127dacb",
				"value": "411500",
				"first_letter": "X",
				"text": "信阳市",
				"parent_value": "410000",
				"pinyin": "xinyangshi",
				"type": 1,
				"zip_value": "464000"
			},
			{
				"_id": "61baf93039bf10000127dad6",
				"value": "411600",
				"first_letter": "Z",
				"text": "周口市",
				"parent_value": "410000",
				"pinyin": "zhoukoushi",
				"type": 1,
				"zip_value": "466000"
			},
			{
				"_id": "61baf93039bf10000127dae1",
				"value": "411700",
				"first_letter": "Z",
				"text": "驻马店市",
				"parent_value": "410000",
				"pinyin": "zhumadianshi",
				"type": 1,
				"zip_value": "463000"
			},
			{
				"_id": "61baf93039bf10000127daec",
				"value": "419000",
				"first_letter": "S",
				"text": "省直辖县级行政区划",
				"parent_value": "410000",
				"pinyin": "shengzhixiaxianjixingzhengquhua",
				"type": 1,
				"zip_value": "0"
			}
		]

	},
	{
		"_id": "61baf93039bf10000127daee",
		"value": "420000",
		"first_letter": "H",
		"text": "湖北省",
		"pinyin": "hubeisheng",
		"type": 0,
		"zip_value": "0",
		"children": [{
				"_id": "61baf93039bf10000127daef",
				"value": "420100",
				"first_letter": "W",
				"text": "武汉市",
				"parent_value": "420000",
				"pinyin": "wuhanshi",
				"type": 1,
				"zip_value": "430000"
			},
			{
				"_id": "61baf93039bf10000127dafd",
				"value": "420200",
				"first_letter": "H",
				"text": "黄石市",
				"parent_value": "420000",
				"pinyin": "huangshishi",
				"type": 1,
				"zip_value": "435000"
			},
			{
				"_id": "61baf93039bf10000127db04",
				"value": "420300",
				"first_letter": "S",
				"text": "十堰市",
				"parent_value": "420000",
				"pinyin": "shiyanshi",
				"type": 1,
				"zip_value": "442000"
			},
			{
				"_id": "61baf93039bf10000127db0d",
				"value": "420500",
				"first_letter": "Y",
				"text": "宜昌市",
				"parent_value": "420000",
				"pinyin": "yichangshi",
				"type": 1,
				"zip_value": "443000"
			},
			{
				"_id": "61baf93039bf10000127db1b",
				"value": "420600",
				"first_letter": "X",
				"text": "襄阳市",
				"parent_value": "420000",
				"pinyin": "xiangyangshi",
				"type": 1,
				"zip_value": "0"
			},
			{
				"_id": "61baf93039bf10000127db25",
				"value": "420700",
				"first_letter": "E",
				"text": "鄂州市",
				"parent_value": "420000",
				"pinyin": "ezhoushi",
				"type": 1,
				"zip_value": "436000"
			},
			{
				"_id": "61baf93039bf10000127db29",
				"value": "420800",
				"first_letter": "J",
				"text": "荆门市",
				"parent_value": "420000",
				"pinyin": "jingmenshi",
				"type": 1,
				"zip_value": "448000"
			},
			{
				"_id": "61baf93039bf10000127db2f",
				"value": "420900",
				"first_letter": "X",
				"text": "孝感市",
				"parent_value": "420000",
				"pinyin": "xiaoganshi",
				"type": 1,
				"zip_value": "432000"
			},
			{
				"_id": "61baf93039bf10000127db37",
				"value": "421000",
				"first_letter": "J",
				"text": "荆州市",
				"parent_value": "420000",
				"pinyin": "jingzhoushi",
				"type": 1,
				"zip_value": "434000"
			},
			{
				"_id": "61baf93039bf10000127db40",
				"value": "421100",
				"first_letter": "H",
				"text": "黄冈市",
				"parent_value": "420000",
				"pinyin": "huanggangshi",
				"type": 1,
				"zip_value": "438000"
			},
			{
				"_id": "61baf93039bf10000127db4b",
				"value": "421200",
				"first_letter": "X",
				"text": "咸宁市",
				"parent_value": "420000",
				"pinyin": "xianningshi",
				"type": 1,
				"zip_value": "437000"
			},
			{
				"_id": "61baf93039bf10000127db52",
				"value": "421300",
				"first_letter": "S",
				"text": "随州市",
				"parent_value": "420000",
				"pinyin": "suizhoushi",
				"type": 1,
				"zip_value": "441300"
			},
			{
				"_id": "61baf93039bf10000127db56",
				"value": "422800",
				"first_letter": "E",
				"text": "恩施土家族苗族自治州",
				"parent_value": "420000",
				"pinyin": "enshitujiazumiaozuzizhizhou",
				"type": 1,
				"zip_value": "445000"
			},
			{
				"_id": "61baf93039bf10000127db5f",
				"value": "429000",
				"first_letter": "S",
				"text": "省直辖县级行政区划",
				"parent_value": "420000",
				"pinyin": "shengzhixiaxianjixingzhengquhua",
				"type": 1,
				"zip_value": "0"
			}
		]

	},
	{
		"_id": "61baf93039bf10000127db64",
		"value": "430000",
		"first_letter": "H",
		"text": "湖南省",
		"pinyin": "hunansheng",
		"type": 0,
		"zip_value": "0",
		"children": [{
				"_id": "61baf93039bf10000127db65",
				"value": "430100",
				"first_letter": "C",
				"text": "长沙市",
				"parent_value": "430000",
				"pinyin": "changshashi",
				"type": 1,
				"zip_value": "410000"
			},
			{
				"_id": "61baf93039bf10000127db6f",
				"value": "430200",
				"first_letter": "Z",
				"text": "株洲市",
				"parent_value": "430000",
				"pinyin": "zhuzhoushi",
				"type": 1,
				"zip_value": "412000"
			},
			{
				"_id": "61baf93039bf10000127db79",
				"value": "430300",
				"first_letter": "X",
				"text": "湘潭市",
				"parent_value": "430000",
				"pinyin": "xiangtanshi",
				"type": 1,
				"zip_value": "411100"
			},
			{
				"_id": "61baf93039bf10000127db7f",
				"value": "430400",
				"first_letter": "H",
				"text": "衡阳市",
				"parent_value": "430000",
				"pinyin": "hengyangshi",
				"type": 1,
				"zip_value": "421000"
			},
			{
				"_id": "61baf93039bf10000127db8c",
				"value": "430500",
				"first_letter": "S",
				"text": "邵阳市",
				"parent_value": "430000",
				"pinyin": "shaoyangshi",
				"type": 1,
				"zip_value": "422000"
			},
			{
				"_id": "61baf93039bf10000127db99",
				"value": "430600",
				"first_letter": "Y",
				"text": "岳阳市",
				"parent_value": "430000",
				"pinyin": "yueyangshi",
				"type": 1,
				"zip_value": "414000"
			},
			{
				"_id": "61baf93039bf10000127dba3",
				"value": "430700",
				"first_letter": "C",
				"text": "常德市",
				"parent_value": "430000",
				"pinyin": "changdeshi",
				"type": 1,
				"zip_value": "415000"
			},
			{
				"_id": "61baf93039bf10000127dbad",
				"value": "430800",
				"first_letter": "Z",
				"text": "张家界市",
				"parent_value": "430000",
				"pinyin": "zhangjiajieshi",
				"type": 1,
				"zip_value": "427000"
			},
			{
				"_id": "61baf93039bf10000127dbb2",
				"value": "430900",
				"first_letter": "Y",
				"text": "益阳市",
				"parent_value": "430000",
				"pinyin": "yiyangshi",
				"type": 1,
				"zip_value": "413000"
			},
			{
				"_id": "61baf93039bf10000127dbb9",
				"value": "431000",
				"first_letter": "C",
				"text": "郴州市",
				"parent_value": "430000",
				"pinyin": "chenzhoushi",
				"type": 1,
				"zip_value": "423000"
			},
			{
				"_id": "61baf93039bf10000127dbc5",
				"value": "431100",
				"first_letter": "Y",
				"text": "永州市",
				"parent_value": "430000",
				"pinyin": "yongzhoushi",
				"type": 1,
				"zip_value": "425000"
			},
			{
				"_id": "61baf93039bf10000127dbd1",
				"value": "431200",
				"first_letter": "H",
				"text": "怀化市",
				"parent_value": "430000",
				"pinyin": "huaihuashi",
				"type": 1,
				"zip_value": "418000"
			},
			{
				"_id": "61baf93039bf10000127dbde",
				"value": "431300",
				"first_letter": "L",
				"text": "娄底市",
				"parent_value": "430000",
				"pinyin": "loudishi",
				"type": 1,
				"zip_value": "417000"
			},
			{
				"_id": "61baf93039bf10000127dbe4",
				"value": "433100",
				"first_letter": "X",
				"text": "湘西土家族苗族自治州",
				"parent_value": "430000",
				"pinyin": "xiangxitujiazumiaozuzizhizhou",
				"type": 1,
				"zip_value": "416000"
			}
		]
	},
	{
		"_id": "61baf93039bf10000127dbed",
		"value": "440000",
		"first_letter": "G",
		"text": "广东省",
		"pinyin": "guangdongsheng",
		"type": 0,
		"zip_value": "0",
		"children": [{
				"_id": "61baf93039bf10000127dbee",
				"value": "440100",
				"first_letter": "G",
				"text": "广州市",
				"parent_value": "440000",
				"pinyin": "guangzhoushi",
				"type": 1,
				"zip_value": "510000"
			},
			{
				"_id": "61baf93039bf10000127dbfa",
				"value": "440200",
				"first_letter": "S",
				"text": "韶关市",
				"parent_value": "440000",
				"pinyin": "shaoguanshi",
				"type": 1,
				"zip_value": "512000"
			},
			{
				"_id": "61baf93039bf10000127dc05",
				"value": "440300",
				"first_letter": "S",
				"text": "深圳市",
				"parent_value": "440000",
				"pinyin": "shenzhenshi",
				"type": 1,
				"zip_value": "518000"
			},
			{
				"_id": "61baf93039bf10000127dc0e",
				"value": "440400",
				"first_letter": "Z",
				"text": "珠海市",
				"parent_value": "440000",
				"pinyin": "zhuhaishi",
				"type": 1,
				"zip_value": "519000"
			},
			{
				"_id": "61baf93039bf10000127dc12",
				"value": "440500",
				"first_letter": "S",
				"text": "汕头市",
				"parent_value": "440000",
				"pinyin": "shantoushi",
				"type": 1,
				"zip_value": "515000"
			},
			{
				"_id": "61baf93039bf10000127dc1a",
				"value": "440600",
				"first_letter": "F",
				"text": "佛山市",
				"parent_value": "440000",
				"pinyin": "foshanshi",
				"type": 1,
				"zip_value": "528000"
			},
			{
				"_id": "61baf93039bf10000127dc20",
				"value": "440700",
				"first_letter": "J",
				"text": "江门市",
				"parent_value": "440000",
				"pinyin": "jiangmenshi",
				"type": 1,
				"zip_value": "529000"
			},
			{
				"_id": "61baf93039bf10000127dc28",
				"value": "440800",
				"first_letter": "Z",
				"text": "湛江市",
				"parent_value": "440000",
				"pinyin": "zhanjiangshi",
				"type": 1,
				"zip_value": "524000"
			},
			{
				"_id": "61baf93039bf10000127dc32",
				"value": "440900",
				"first_letter": "M",
				"text": "茂名市",
				"parent_value": "440000",
				"pinyin": "maomingshi",
				"type": 1,
				"zip_value": "525000"
			},
			{
				"_id": "61baf93039bf10000127dc38",
				"value": "441200",
				"first_letter": "Z",
				"text": "肇庆市",
				"parent_value": "440000",
				"pinyin": "zhaoqingshi",
				"type": 1,
				"zip_value": "526000"
			},
			{
				"_id": "61baf93039bf10000127dc41",
				"value": "441300",
				"first_letter": "H",
				"text": "惠州市",
				"parent_value": "440000",
				"pinyin": "huizhoushi",
				"type": 1,
				"zip_value": "516000"
			},
			{
				"_id": "61baf93039bf10000127dc47",
				"value": "441400",
				"first_letter": "M",
				"text": "梅州市",
				"parent_value": "440000",
				"pinyin": "meizhoushi",
				"type": 1,
				"zip_value": "514000"
			},
			{
				"_id": "61baf93039bf10000127dc50",
				"value": "441500",
				"first_letter": "S",
				"text": "汕尾市",
				"parent_value": "440000",
				"pinyin": "shanweishi",
				"type": 1,
				"zip_value": "516600"
			},
			{
				"_id": "61baf93039bf10000127dc55",
				"value": "441600",
				"first_letter": "H",
				"text": "河源市",
				"parent_value": "440000",
				"pinyin": "heyuanshi",
				"type": 1,
				"zip_value": "517000"
			},
			{
				"_id": "61baf93039bf10000127dc5c",
				"value": "441700",
				"first_letter": "Y",
				"text": "阳江市",
				"parent_value": "440000",
				"pinyin": "yangjiangshi",
				"type": 1,
				"zip_value": "529500"
			},
			{
				"_id": "61baf93039bf10000127dc61",
				"value": "441800",
				"first_letter": "Q",
				"text": "清远市",
				"parent_value": "440000",
				"pinyin": "qingyuanshi",
				"type": 1,
				"zip_value": "511500"
			},
			{
				"_id": "61baf93039bf10000127dc6a",
				"value": "441900",
				"first_letter": "D",
				"text": "东莞市",
				"parent_value": "440000",
				"pinyin": "dongguanshi",
				"type": 1,
				"zip_value": "523000"
			},
			{
				"_id": "61baf93039bf10000127dc8e",
				"value": "442000",
				"first_letter": "Z",
				"text": "中山市",
				"parent_value": "440000",
				"pinyin": "zhongshanshi",
				"type": 1,
				"zip_value": "528403"
			},
			{
				"_id": "61baf93039bf10000127dca7",
				"value": "445100",
				"first_letter": "C",
				"text": "潮州市",
				"parent_value": "440000",
				"pinyin": "chaozhoushi",
				"type": 1,
				"zip_value": "521000"
			},
			{
				"_id": "61baf93039bf10000127dcab",
				"value": "445200",
				"first_letter": "J",
				"text": "揭阳市",
				"parent_value": "440000",
				"pinyin": "jieyangshi",
				"type": 1,
				"zip_value": "522000"
			},
			{
				"_id": "61baf93039bf10000127dcb1",
				"value": "445300",
				"first_letter": "Y",
				"text": "云浮市",
				"parent_value": "440000",
				"pinyin": "yunfushi",
				"type": 1,
				"zip_value": "527300"
			}
		]

	},
	{
		"_id": "61baf93039bf10000127dcb7",
		"value": "450000",
		"first_letter": "G",
		"text": "广西壮族自治区",
		"pinyin": "guangxizhuangzuzizhiqu",
		"type": 0,
		"zip_value": "0",
		"children": [{
				"_id": "61baf93039bf10000127dcb8",
				"value": "450100",
				"first_letter": "N",
				"text": "南宁市",
				"parent_value": "450000",
				"pinyin": "nanningshi",
				"type": 1,
				"zip_value": "530000"
			},
			{
				"_id": "61baf93039bf10000127dcc5",
				"value": "450200",
				"first_letter": "L",
				"text": "柳州市",
				"parent_value": "450000",
				"pinyin": "liuzhoushi",
				"type": 1,
				"zip_value": "545000"
			},
			{
				"_id": "61baf93039bf10000127dcd0",
				"value": "450300",
				"first_letter": "G",
				"text": "桂林市",
				"parent_value": "450000",
				"pinyin": "guilinshi",
				"type": 1,
				"zip_value": "541000"
			},
			{
				"_id": "61baf93039bf10000127dce2",
				"value": "450400",
				"first_letter": "W",
				"text": "梧州市",
				"parent_value": "450000",
				"pinyin": "wuzhoushi",
				"type": 1,
				"zip_value": "543000"
			},
			{
				"_id": "61baf93039bf10000127dcea",
				"value": "450500",
				"first_letter": "B",
				"text": "北海市",
				"parent_value": "450000",
				"pinyin": "beihaishi",
				"type": 1,
				"zip_value": "536000"
			},
			{
				"_id": "61baf93039bf10000127dcef",
				"value": "450600",
				"first_letter": "F",
				"text": "防城港市",
				"parent_value": "450000",
				"pinyin": "fangchenggangshi",
				"type": 1,
				"zip_value": "538000"
			},
			{
				"_id": "61baf93039bf10000127dcf4",
				"value": "450700",
				"first_letter": "Q",
				"text": "钦州市",
				"parent_value": "450000",
				"pinyin": "qinzhoushi",
				"type": 1,
				"zip_value": "535000"
			},
			{
				"_id": "61baf93039bf10000127dcf9",
				"value": "450800",
				"first_letter": "G",
				"text": "贵港市",
				"parent_value": "450000",
				"pinyin": "guigangshi",
				"type": 1,
				"zip_value": "537000"
			},
			{
				"_id": "61baf93039bf10000127dcff",
				"value": "450900",
				"first_letter": "Y",
				"text": "玉林市",
				"parent_value": "450000",
				"pinyin": "yulinshi",
				"type": 1,
				"zip_value": "0"
			},
			{
				"_id": "61baf93039bf10000127dd07",
				"value": "451000",
				"first_letter": "B",
				"text": "百色市",
				"parent_value": "450000",
				"pinyin": "baiseshi",
				"type": 1,
				"zip_value": "533000"
			},
			{
				"_id": "61baf93039bf10000127dd14",
				"value": "451100",
				"first_letter": "H",
				"text": "贺州市",
				"parent_value": "450000",
				"pinyin": "hezhoushi",
				"type": 1,
				"zip_value": "542800"
			},
			{
				"_id": "61baf93039bf10000127dd1a",
				"value": "451200",
				"first_letter": "H",
				"text": "河池市",
				"parent_value": "450000",
				"pinyin": "hechishi",
				"type": 1,
				"zip_value": "547000"
			},
			{
				"_id": "61baf93039bf10000127dd26",
				"value": "451300",
				"first_letter": "L",
				"text": "来宾市",
				"parent_value": "450000",
				"pinyin": "laibinshi",
				"type": 1,
				"zip_value": "546100"
			},
			{
				"_id": "61baf93039bf10000127dd2d",
				"value": "451400",
				"first_letter": "C",
				"text": "崇左市",
				"parent_value": "450000",
				"pinyin": "chongzuoshi",
				"type": 1,
				"zip_value": "532200"
			}
		]

	},
	{
		"_id": "61baf93039bf10000127dd35",
		"value": "460000",
		"first_letter": "H",
		"text": "海南省",
		"pinyin": "hainansheng",
		"type": 0,
		"zip_value": "0",
		"children": [{
				"_id": "61baf93039bf10000127dd36",
				"value": "460100",
				"first_letter": "H",
				"text": "海口市",
				"parent_value": "460000",
				"pinyin": "haikoushi",
				"type": 1,
				"zip_value": "570100"
			},
			{
				"_id": "61baf93039bf10000127dd3b",
				"value": "460200",
				"first_letter": "S",
				"text": "三亚市",
				"parent_value": "460000",
				"pinyin": "sanyashi",
				"type": 1,
				"zip_value": "572000"
			},
			{
				"_id": "61baf93039bf10000127dd40",
				"value": "460300",
				"first_letter": "S",
				"text": "三沙市",
				"parent_value": "460000",
				"pinyin": "sanshashi",
				"type": 1,
				"zip_value": "0"
			},
			{
				"_id": "61baf93039bf10000127dd44",
				"value": "460400",
				"first_letter": "D",
				"text": "儋州市",
				"parent_value": "460000",
				"pinyin": "danzhoushi",
				"type": 1,
				"zip_value": "571700"
			},
			{
				"_id": "61baf93039bf10000127dd5d",
				"value": "469000",
				"first_letter": "S",
				"text": "省直辖县级行政区划",
				"parent_value": "460000",
				"pinyin": "shengzhixiaxianjixingzhengquhua",
				"type": 1,
				"zip_value": "0"
			}
		]

	},
	{
		"_id": "61baf93039bf10000127dd6d",
		"value": "500000",
		"first_letter": "C",
		"text": "重庆市",
		"pinyin": "chongqingshi",
		"type": 0,
		"zip_value": "400000",
		"children": [{
				"_id": "61baf93039bf10000127dd6e",
				"value": "500100",
				"first_letter": "C",
				"text": "重庆市",
				"parent_value": "500000",
				"pinyin": "chongqingshi",
				"type": 1,
				"zip_value": "400000"
			},
			{
				"_id": "61baf93039bf10000127dd89",
				"value": "500200",
				"first_letter": "X",
				"text": "县",
				"parent_value": "500000",
				"pinyin": "xian",
				"type": 1,
				"zip_value": "0"
			}
		]
	},
	{
		"_id": "61baf93039bf10000127dd96",
		"value": "510000",
		"first_letter": "S",
		"text": "四川省",
		"pinyin": "sichuansheng",
		"type": 0,
		"zip_value": "0",
		"children": [{
				"_id": "61baf93039bf10000127dd97",
				"value": "510100",
				"first_letter": "C",
				"text": "成都市",
				"parent_value": "510000",
				"pinyin": "chengdushi",
				"type": 1,
				"zip_value": "610000"
			},
			{
				"_id": "61baf93039bf10000127ddac",
				"value": "510300",
				"first_letter": "Z",
				"text": "自贡市",
				"parent_value": "510000",
				"pinyin": "zigongshi",
				"type": 1,
				"zip_value": "643000"
			},
			{
				"_id": "61baf93039bf10000127ddb3",
				"value": "510400",
				"first_letter": "P",
				"text": "攀枝花市",
				"parent_value": "510000",
				"pinyin": "panzhihuashi",
				"type": 1,
				"zip_value": "617000"
			},
			{
				"_id": "61baf93039bf10000127ddb9",
				"value": "510500",
				"first_letter": "L",
				"text": "泸州市",
				"parent_value": "510000",
				"pinyin": "luzhoushi",
				"type": 1,
				"zip_value": "646000"
			},
			{
				"_id": "61baf93039bf10000127ddc1",
				"value": "510600",
				"first_letter": "D",
				"text": "德阳市",
				"parent_value": "510000",
				"pinyin": "deyangshi",
				"type": 1,
				"zip_value": "618000"
			},
			{
				"_id": "61baf93039bf10000127ddc8",
				"value": "510700",
				"first_letter": "M",
				"text": "绵阳市",
				"parent_value": "510000",
				"pinyin": "mianyangshi",
				"type": 1,
				"zip_value": "621000"
			},
			{
				"_id": "61baf93039bf10000127ddd2",
				"value": "510800",
				"first_letter": "G",
				"text": "广元市",
				"parent_value": "510000",
				"pinyin": "guangyuanshi",
				"type": 1,
				"zip_value": "628000"
			},
			{
				"_id": "61baf93039bf10000127ddda",
				"value": "510900",
				"first_letter": "S",
				"text": "遂宁市",
				"parent_value": "510000",
				"pinyin": "suiningshi",
				"type": 1,
				"zip_value": "629000"
			},
			{
				"_id": "61baf93039bf10000127dde0",
				"value": "511000",
				"first_letter": "N",
				"text": "内江市",
				"parent_value": "510000",
				"pinyin": "neijiangshi",
				"type": 1,
				"zip_value": "641000"
			},
			{
				"_id": "61baf93039bf10000127dde6",
				"value": "511100",
				"first_letter": "L",
				"text": "乐山市",
				"parent_value": "510000",
				"pinyin": "leshanshi",
				"type": 1,
				"zip_value": "614000"
			},
			{
				"_id": "61baf93039bf10000127ddf2",
				"value": "511300",
				"first_letter": "N",
				"text": "南充市",
				"parent_value": "510000",
				"pinyin": "nanchongshi",
				"type": 1,
				"zip_value": "637000"
			},
			{
				"_id": "61baf93039bf10000127ddfc",
				"value": "511400",
				"first_letter": "M",
				"text": "眉山市",
				"parent_value": "510000",
				"pinyin": "meishanshi",
				"type": 1,
				"zip_value": "620000"
			},
			{
				"_id": "61baf93039bf10000127de03",
				"value": "511500",
				"first_letter": "Y",
				"text": "宜宾市",
				"parent_value": "510000",
				"pinyin": "yibinshi",
				"type": 1,
				"zip_value": "644000"
			},
			{
				"_id": "61baf93039bf10000127de0e",
				"value": "511600",
				"first_letter": "G",
				"text": "广安市",
				"parent_value": "510000",
				"pinyin": "guanganshi",
				"type": 1,
				"zip_value": "638500"
			},
			{
				"_id": "61baf93039bf10000127de15",
				"value": "511700",
				"first_letter": "D",
				"text": "达州市",
				"parent_value": "510000",
				"pinyin": "dazhoushi",
				"type": 1,
				"zip_value": "635000"
			},
			{
				"_id": "61baf93039bf10000127de1d",
				"value": "511800",
				"first_letter": "Y",
				"text": "雅安市",
				"parent_value": "510000",
				"pinyin": "yaanshi",
				"type": 1,
				"zip_value": "625000"
			},
			{
				"_id": "61baf93039bf10000127de26",
				"value": "511900",
				"first_letter": "B",
				"text": "巴中市",
				"parent_value": "510000",
				"pinyin": "bazhongshi",
				"type": 1,
				"zip_value": "636600"
			},
			{
				"_id": "61baf93039bf10000127de2c",
				"value": "512000",
				"first_letter": "Z",
				"text": "资阳市",
				"parent_value": "510000",
				"pinyin": "ziyangshi",
				"type": 1,
				"zip_value": "641300"
			},
			{
				"_id": "61baf93039bf10000127de30",
				"value": "513200",
				"first_letter": "A",
				"text": "阿坝藏族羌族自治州",
				"parent_value": "510000",
				"pinyin": "abazangzuqiangzuzizhizhou",
				"type": 1,
				"zip_value": "624000"
			},
			{
				"_id": "61baf93039bf10000127de3e",
				"value": "513300",
				"first_letter": "G",
				"text": "甘孜藏族自治州",
				"parent_value": "510000",
				"pinyin": "ganzizangzuzizhizhou",
				"type": 1,
				"zip_value": "626000"
			},
			{
				"_id": "61baf93039bf10000127de51",
				"value": "513400",
				"first_letter": "L",
				"text": "凉山彝族自治州",
				"parent_value": "510000",
				"pinyin": "liangshanyizuzizhizhou",
				"type": 1,
				"zip_value": "615000"
			}
		]

	},
	{
		"_id": "61baf93039bf10000127de63",
		"value": "520000",
		"first_letter": "G",
		"text": "贵州省",
		"pinyin": "guizhousheng",
		"type": 0,
		"zip_value": "0",
		"children": [{
				"_id": "61baf93039bf10000127de64",
				"value": "520100",
				"first_letter": "G",
				"text": "贵阳市",
				"parent_value": "520000",
				"pinyin": "guiyangshi",
				"type": 1,
				"zip_value": "550000"
			},
			{
				"_id": "61baf93039bf10000127de6f",
				"value": "520200",
				"first_letter": "L",
				"text": "六盘水市",
				"parent_value": "520000",
				"pinyin": "liupanshuishi",
				"type": 1,
				"zip_value": "553000"
			},
			{
				"_id": "61baf93039bf10000127de74",
				"value": "520300",
				"first_letter": "Z",
				"text": "遵义市",
				"parent_value": "520000",
				"pinyin": "zunyishi",
				"type": 1,
				"zip_value": "563000"
			},
			{
				"_id": "61baf93039bf10000127de83",
				"value": "520400",
				"first_letter": "A",
				"text": "安顺市",
				"parent_value": "520000",
				"pinyin": "anshunshi",
				"type": 1,
				"zip_value": "561000"
			},
			{
				"_id": "61baf93039bf10000127de8a",
				"value": "520500",
				"first_letter": "B",
				"text": "毕节市",
				"parent_value": "520000",
				"pinyin": "bijieshi",
				"type": 1,
				"zip_value": "551700"
			},
			{
				"_id": "61baf93039bf10000127de93",
				"value": "520600",
				"first_letter": "T",
				"text": "铜仁市",
				"parent_value": "520000",
				"pinyin": "tongrenshi",
				"type": 1,
				"zip_value": "554300"
			},
			{
				"_id": "61baf93039bf10000127de9e",
				"value": "522300",
				"first_letter": "Q",
				"text": "黔西南布依族苗族自治州",
				"parent_value": "520000",
				"pinyin": "qianxinanbuyizumiaozuzizhizhou",
				"type": 1,
				"zip_value": "562400"
			},
			{
				"_id": "61baf93039bf10000127dea7",
				"value": "522600",
				"first_letter": "Q",
				"text": "黔东南苗族侗族自治州",
				"parent_value": "520000",
				"pinyin": "qiandongnanmiaozutongzuzizhizhou",
				"type": 1,
				"zip_value": "556000"
			},
			{
				"_id": "61baf93039bf10000127deb8",
				"value": "522700",
				"first_letter": "Q",
				"text": "黔南布依族苗族自治州",
				"parent_value": "520000",
				"pinyin": "qiannanbuyizumiaozuzizhizhou",
				"type": 1,
				"zip_value": "558000"
			}
		]

	},
	{
		"_id": "61baf93039bf10000127dec5",
		"value": "530000",
		"first_letter": "Y",
		"text": "云南省",
		"pinyin": "yunnansheng",
		"type": 0,
		"zip_value": "0",
		"children": [{
				"_id": "61baf93039bf10000127dec6",
				"value": "530100",
				"first_letter": "K",
				"text": "昆明市",
				"parent_value": "530000",
				"pinyin": "kunmingshi",
				"type": 1,
				"zip_value": "650000"
			},
			{
				"_id": "61baf93039bf10000127ded5",
				"value": "530300",
				"first_letter": "Q",
				"text": "曲靖市",
				"parent_value": "530000",
				"pinyin": "qujingshi",
				"type": 1,
				"zip_value": "655000"
			},
			{
				"_id": "61baf93039bf10000127dedf",
				"value": "530400",
				"first_letter": "Y",
				"text": "玉溪市",
				"parent_value": "530000",
				"pinyin": "yuxishi",
				"type": 1,
				"zip_value": "653100"
			},
			{
				"_id": "61baf93039bf10000127dee9",
				"value": "530500",
				"first_letter": "B",
				"text": "保山市",
				"parent_value": "530000",
				"pinyin": "baoshanshi",
				"type": 1,
				"zip_value": "678000"
			},
			{
				"_id": "61baf93039bf10000127deef",
				"value": "530600",
				"first_letter": "Z",
				"text": "昭通市",
				"parent_value": "530000",
				"pinyin": "zhaotongshi",
				"type": 1,
				"zip_value": "657000"
			},
			{
				"_id": "61baf93039bf10000127defb",
				"value": "530700",
				"first_letter": "L",
				"text": "丽江市",
				"parent_value": "530000",
				"pinyin": "lijiangshi",
				"type": 1,
				"zip_value": "674100"
			},
			{
				"_id": "61baf93039bf10000127df01",
				"value": "530800",
				"first_letter": "P",
				"text": "普洱市",
				"parent_value": "530000",
				"pinyin": "puershi",
				"type": 1,
				"zip_value": "0"
			},
			{
				"_id": "61baf93039bf10000127df0c",
				"value": "530900",
				"first_letter": "L",
				"text": "临沧市",
				"parent_value": "530000",
				"pinyin": "lincangshi",
				"type": 1,
				"zip_value": "677000"
			},
			{
				"_id": "61baf93039bf10000127df15",
				"value": "532300",
				"first_letter": "C",
				"text": "楚雄彝族自治州",
				"parent_value": "530000",
				"pinyin": "chuxiongyizuzizhizhou",
				"type": 1,
				"zip_value": "675000"
			},
			{
				"_id": "61baf93039bf10000127df20",
				"value": "532500",
				"first_letter": "H",
				"text": "红河哈尼族彝族自治州",
				"parent_value": "530000",
				"pinyin": "honghehanizuyizuzizhizhou",
				"type": 1,
				"zip_value": "661400"
			},
			{
				"_id": "61baf93039bf10000127df2e",
				"value": "532600",
				"first_letter": "W",
				"text": "文山壮族苗族自治州",
				"parent_value": "530000",
				"pinyin": "wenshanzhuangzumiaozuzizhizhou",
				"type": 1,
				"zip_value": "663000"
			},
			{
				"_id": "61baf93039bf10000127df37",
				"value": "532800",
				"first_letter": "X",
				"text": "西双版纳傣族自治州",
				"parent_value": "530000",
				"pinyin": "xishuangbannadaizuzizhizhou",
				"type": 1,
				"zip_value": "666100"
			},
			{
				"_id": "61baf93039bf10000127df3b",
				"value": "532900",
				"first_letter": "D",
				"text": "大理白族自治州",
				"parent_value": "530000",
				"pinyin": "dalibaizuzizhizhou",
				"type": 1,
				"zip_value": "671000"
			},
			{
				"_id": "61baf93039bf10000127df48",
				"value": "533100",
				"first_letter": "D",
				"text": "德宏傣族景颇族自治州",
				"parent_value": "530000",
				"pinyin": "dehongdaizujingpozuzizhizhou",
				"type": 1,
				"zip_value": "678400"
			},
			{
				"_id": "61baf93039bf10000127df4e",
				"value": "533300",
				"first_letter": "N",
				"text": "怒江傈僳族自治州",
				"parent_value": "530000",
				"pinyin": "nujianglisuzuzizhizhou",
				"type": 1,
				"zip_value": "673100"
			},
			{
				"_id": "61baf93039bf10000127df53",
				"value": "533400",
				"first_letter": "D",
				"text": "迪庆藏族自治州",
				"parent_value": "530000",
				"pinyin": "diqingzangzuzizhizhou",
				"type": 1,
				"zip_value": "674400"
			}
		]

	},
	{
		"_id": "61baf93039bf10000127df57",
		"value": "540000",
		"first_letter": "X",
		"text": "西藏自治区",
		"pinyin": "xizangzizhiqu",
		"type": 0,
		"zip_value": "0",
		"children": [{
				"_id": "61baf93039bf10000127df58",
				"value": "540100",
				"first_letter": "L",
				"text": "拉萨市",
				"parent_value": "540000",
				"pinyin": "lasashi",
				"type": 1,
				"zip_value": "850000"
			},
			{
				"_id": "61baf93039bf10000127df61",
				"value": "540200",
				"first_letter": "R",
				"text": "日喀则市",
				"parent_value": "540000",
				"pinyin": "rikazeshi",
				"type": 1,
				"zip_value": "857000"
			},
			{
				"_id": "61baf93039bf10000127df74",
				"value": "540300",
				"first_letter": "C",
				"text": "昌都市",
				"parent_value": "540000",
				"pinyin": "changdushi",
				"type": 1,
				"zip_value": "854000"
			},
			{
				"_id": "61baf93039bf10000127df80",
				"value": "540400",
				"first_letter": "L",
				"text": "林芝市",
				"parent_value": "540000",
				"pinyin": "linzhishi",
				"type": 1,
				"zip_value": "860000"
			},
			{
				"_id": "61baf93039bf10000127df88",
				"value": "540500",
				"first_letter": "S",
				"text": "山南市",
				"parent_value": "540000",
				"pinyin": "shannanshi",
				"type": 1,
				"zip_value": "856000"
			},
			{
				"_id": "61baf93039bf10000127df95",
				"value": "542400",
				"first_letter": "N",
				"text": "那曲地区",
				"parent_value": "540000",
				"pinyin": "naqudiqu",
				"type": 1,
				"zip_value": "0"
			},
			{
				"_id": "61baf93039bf10000127dfa1",
				"value": "542500",
				"first_letter": "A",
				"text": "阿里地区",
				"parent_value": "540000",
				"pinyin": "alidiqu",
				"type": 1,
				"zip_value": "859000"
			}
		]

	},
	{
		"_id": "61baf93039bf10000127dfa9",
		"value": "610000",
		"first_letter": "S",
		"text": "陕西省",
		"pinyin": "shanxisheng",
		"type": 0,
		"zip_value": "0",
		"children": [{
				"_id": "61baf93039bf10000127dfaa",
				"value": "610100",
				"first_letter": "X",
				"text": "西安市",
				"parent_value": "610000",
				"pinyin": "xianshi",
				"type": 1,
				"zip_value": "710000"
			},
			{
				"_id": "61baf93039bf10000127dfb8",
				"value": "610200",
				"first_letter": "T",
				"text": "铜川市",
				"parent_value": "610000",
				"pinyin": "tongchuanshi",
				"type": 1,
				"zip_value": "727000"
			},
			{
				"_id": "61baf93039bf10000127dfbd",
				"value": "610300",
				"first_letter": "B",
				"text": "宝鸡市",
				"parent_value": "610000",
				"pinyin": "baojishi",
				"type": 1,
				"zip_value": "721000"
			},
			{
				"_id": "61baf93039bf10000127dfca",
				"value": "610400",
				"first_letter": "X",
				"text": "咸阳市",
				"parent_value": "610000",
				"pinyin": "xianyangshi",
				"type": 1,
				"zip_value": "712000"
			},
			{
				"_id": "61baf93039bf10000127dfd9",
				"value": "610500",
				"first_letter": "W",
				"text": "渭南市",
				"parent_value": "610000",
				"pinyin": "weinanshi",
				"type": 1,
				"zip_value": "714000"
			},
			{
				"_id": "61baf93039bf10000127dfe5",
				"value": "610600",
				"first_letter": "Y",
				"text": "延安市",
				"parent_value": "610000",
				"pinyin": "yananshi",
				"type": 1,
				"zip_value": "716000"
			},
			{
				"_id": "61baf93039bf10000127dff3",
				"value": "610700",
				"first_letter": "H",
				"text": "汉中市",
				"parent_value": "610000",
				"pinyin": "hanzhongshi",
				"type": 1,
				"zip_value": "723000"
			},
			{
				"_id": "61baf93039bf10000127dfff",
				"value": "610800",
				"first_letter": "Y",
				"text": "榆林市",
				"parent_value": "610000",
				"pinyin": "yulinshi",
				"type": 1,
				"zip_value": "719000"
			},
			{
				"_id": "61baf93039bf10000127e00c",
				"value": "610900",
				"first_letter": "A",
				"text": "安康市",
				"parent_value": "610000",
				"pinyin": "ankangshi",
				"type": 1,
				"zip_value": "725000"
			},
			{
				"_id": "61baf93039bf10000127e017",
				"value": "611000",
				"first_letter": "S",
				"text": "商洛市",
				"parent_value": "610000",
				"pinyin": "shangluoshi",
				"type": 1,
				"zip_value": "726000"
			}
		]

	},
	{
		"_id": "61baf93039bf10000127e01f",
		"value": "620000",
		"first_letter": "G",
		"text": "甘肃省",
		"pinyin": "gansusheng",
		"type": 0,
		"zip_value": "0",
		"children": [{
				"_id": "61baf93039bf10000127e020",
				"value": "620100",
				"first_letter": "L",
				"text": "兰州市",
				"parent_value": "620000",
				"pinyin": "lanzhoushi",
				"type": 1,
				"zip_value": "730000"
			},
			{
				"_id": "61baf93039bf10000127e029",
				"value": "620200",
				"first_letter": "J",
				"text": "嘉峪关市",
				"parent_value": "620000",
				"pinyin": "jiayuguanshi",
				"type": 1,
				"zip_value": "735100"
			},
			{
				"_id": "61baf93039bf10000127e02d",
				"value": "620300",
				"first_letter": "J",
				"text": "金昌市",
				"parent_value": "620000",
				"pinyin": "jinchangshi",
				"type": 1,
				"zip_value": "737100"
			},
			{
				"_id": "61baf93039bf10000127e030",
				"value": "620400",
				"first_letter": "B",
				"text": "白银市",
				"parent_value": "620000",
				"pinyin": "baiyinshi",
				"type": 1,
				"zip_value": "730900"
			},
			{
				"_id": "61baf93039bf10000127e036",
				"value": "620500",
				"first_letter": "T",
				"text": "天水市",
				"parent_value": "620000",
				"pinyin": "tianshuishi",
				"type": 1,
				"zip_value": "741000"
			},
			{
				"_id": "61baf93039bf10000127e03e",
				"value": "620600",
				"first_letter": "W",
				"text": "武威市",
				"parent_value": "620000",
				"pinyin": "wuweishi",
				"type": 1,
				"zip_value": "733000"
			},
			{
				"_id": "61baf93039bf10000127e043",
				"value": "620700",
				"first_letter": "Z",
				"text": "张掖市",
				"parent_value": "620000",
				"pinyin": "zhangyeshi",
				"type": 1,
				"zip_value": "734000"
			},
			{
				"_id": "61baf93039bf10000127e04a",
				"value": "620800",
				"first_letter": "P",
				"text": "平凉市",
				"parent_value": "620000",
				"pinyin": "pingliangshi",
				"type": 1,
				"zip_value": "744000"
			},
			{
				"_id": "61baf93039bf10000127e052",
				"value": "620900",
				"first_letter": "J",
				"text": "酒泉市",
				"parent_value": "620000",
				"pinyin": "jiuquanshi",
				"type": 1,
				"zip_value": "735000"
			},
			{
				"_id": "61baf93039bf10000127e05a",
				"value": "621000",
				"first_letter": "Q",
				"text": "庆阳市",
				"parent_value": "620000",
				"pinyin": "qingyangshi",
				"type": 1,
				"zip_value": "745000"
			},
			{
				"_id": "61baf93039bf10000127e063",
				"value": "621100",
				"first_letter": "D",
				"text": "定西市",
				"parent_value": "620000",
				"pinyin": "dingxishi",
				"type": 1,
				"zip_value": "743000"
			},
			{
				"_id": "61baf93039bf10000127e06b",
				"value": "621200",
				"first_letter": "L",
				"text": "陇南市",
				"parent_value": "620000",
				"pinyin": "longnanshi",
				"type": 1,
				"zip_value": "742500"
			},
			{
				"_id": "61baf93039bf10000127e075",
				"value": "622900",
				"first_letter": "L",
				"text": "临夏回族自治州",
				"parent_value": "620000",
				"pinyin": "linxiahuizuzizhizhou",
				"type": 1,
				"zip_value": "731100"
			},
			{
				"_id": "61baf93039bf10000127e07e",
				"value": "623000",
				"first_letter": "G",
				"text": "甘南藏族自治州",
				"parent_value": "620000",
				"pinyin": "gannanzangzuzizhizhou",
				"type": 1,
				"zip_value": "747000"
			}
		]

	},
	{
		"_id": "61baf93039bf10000127e087",
		"value": "630000",
		"first_letter": "Q",
		"text": "青海省",
		"pinyin": "qinghaisheng",
		"type": 0,
		"zip_value": "0",
		"children": [{
				"_id": "61baf93039bf10000127e088",
				"value": "630100",
				"first_letter": "X",
				"text": "西宁市",
				"parent_value": "630000",
				"pinyin": "xiningshi",
				"type": 1,
				"zip_value": "810000"
			},
			{
				"_id": "61baf93039bf10000127e090",
				"value": "630200",
				"first_letter": "H",
				"text": "海东市",
				"parent_value": "630000",
				"pinyin": "haidongshi",
				"type": 1,
				"zip_value": "810699"
			},
			{
				"_id": "61baf93039bf10000127e097",
				"value": "632200",
				"first_letter": "H",
				"text": "海北藏族自治州",
				"parent_value": "630000",
				"pinyin": "haibeizangzuzizhizhou",
				"type": 1,
				"zip_value": "812200"
			},
			{
				"_id": "61baf93039bf10000127e09c",
				"value": "632300",
				"first_letter": "H",
				"text": "黄南藏族自治州",
				"parent_value": "630000",
				"pinyin": "huangnanzangzuzizhizhou",
				"type": 1,
				"zip_value": "811300"
			},
			{
				"_id": "61baf93039bf10000127e0a1",
				"value": "632500",
				"first_letter": "H",
				"text": "海南藏族自治州",
				"parent_value": "630000",
				"pinyin": "hainanzangzuzizhizhou",
				"type": 1,
				"zip_value": "813000"
			},
			{
				"_id": "61baf93039bf10000127e0a7",
				"value": "632600",
				"first_letter": "G",
				"text": "果洛藏族自治州",
				"parent_value": "630000",
				"pinyin": "guoluozangzuzizhizhou",
				"type": 1,
				"zip_value": "814000"
			},
			{
				"_id": "61baf93039bf10000127e0ae",
				"value": "632700",
				"first_letter": "Y",
				"text": "玉树藏族自治州",
				"parent_value": "630000",
				"pinyin": "yushuzangzuzizhizhou",
				"type": 1,
				"zip_value": "815000"
			},
			{
				"_id": "61baf93039bf10000127e0b5",
				"value": "632800",
				"first_letter": "H",
				"text": "海西蒙古族藏族自治州",
				"parent_value": "630000",
				"pinyin": "haiximengguzuzangzuzizhizhou",
				"type": 1,
				"zip_value": "817000"
			}
		]

	},
	{
		"_id": "61baf93039bf10000127e0be",
		"value": "640000",
		"first_letter": "N",
		"text": "宁夏回族自治区",
		"pinyin": "ningxiahuizuzizhiqu",
		"type": 0,
		"zip_value": "0",
		"children": [{
				"_id": "61baf93039bf10000127e0bf",
				"value": "640100",
				"first_letter": "Y",
				"text": "银川市",
				"parent_value": "640000",
				"pinyin": "yinchuanshi",
				"type": 1,
				"zip_value": "750000"
			},
			{
				"_id": "61baf93039bf10000127e0c6",
				"value": "640200",
				"first_letter": "S",
				"text": "石嘴山市",
				"parent_value": "640000",
				"pinyin": "shizuishanshi",
				"type": 1,
				"zip_value": "753000"
			},
			{
				"_id": "61baf93039bf10000127e0ca",
				"value": "640300",
				"first_letter": "W",
				"text": "吴忠市",
				"parent_value": "640000",
				"pinyin": "wuzhongshi",
				"type": 1,
				"zip_value": "751100"
			},
			{
				"_id": "61baf93039bf10000127e0d0",
				"value": "640400",
				"first_letter": "G",
				"text": "固原市",
				"parent_value": "640000",
				"pinyin": "guyuanshi",
				"type": 1,
				"zip_value": "756000"
			},
			{
				"_id": "61baf93039bf10000127e0d6",
				"value": "640500",
				"first_letter": "Z",
				"text": "中卫市",
				"parent_value": "640000",
				"pinyin": "zhongweishi",
				"type": 1,
				"zip_value": "751700"
			}
		]

	},
	{
		"_id": "61baf93039bf10000127e0da",
		"value": "650000",
		"first_letter": "X",
		"text": "新疆维吾尔自治区",
		"pinyin": "xinjiangweiwuerzizhiqu",
		"type": 0,
		"zip_value": "0",
		"children": [{
				"_id": "61baf93039bf10000127e0db",
				"value": "650100",
				"first_letter": "W",
				"text": "乌鲁木齐市",
				"parent_value": "650000",
				"pinyin": "wulumuqishi",
				"type": 1,
				"zip_value": "830000"
			},
			{
				"_id": "61baf93039bf10000127e0e4",
				"value": "650200",
				"first_letter": "K",
				"text": "克拉玛依市",
				"parent_value": "650000",
				"pinyin": "kelamayishi",
				"type": 1,
				"zip_value": "834000"
			},
			{
				"_id": "61baf93039bf10000127e0e9",
				"value": "650400",
				"first_letter": "T",
				"text": "吐鲁番市",
				"parent_value": "650000",
				"pinyin": "tulufanshi",
				"type": 1,
				"zip_value": "838000"
			},
			{
				"_id": "61baf93039bf10000127e0ed",
				"value": "650500",
				"first_letter": "H",
				"text": "哈密市",
				"parent_value": "650000",
				"pinyin": "hamishi",
				"type": 1,
				"zip_value": "839000"
			},
			{
				"_id": "61baf93039bf10000127e0f1",
				"value": "652300",
				"first_letter": "C",
				"text": "昌吉回族自治州",
				"parent_value": "650000",
				"pinyin": "changjihuizuzizhizhou",
				"type": 1,
				"zip_value": "831100"
			},
			{
				"_id": "61baf93039bf10000127e0f9",
				"value": "652700",
				"first_letter": "B",
				"text": "博尔塔拉蒙古自治州",
				"parent_value": "650000",
				"pinyin": "boertalamengguzizhizhou",
				"type": 1,
				"zip_value": "833400"
			},
			{
				"_id": "61baf93039bf10000127e0fe",
				"value": "652800",
				"first_letter": "B",
				"text": "巴音郭楞蒙古自治州",
				"parent_value": "650000",
				"pinyin": "bayinguolengmengguzizhizhou",
				"type": 1,
				"zip_value": "841000"
			},
			{
				"_id": "61baf93039bf10000127e108",
				"value": "652900",
				"first_letter": "A",
				"text": "阿克苏地区",
				"parent_value": "650000",
				"pinyin": "akesudiqu",
				"type": 1,
				"zip_value": "843000"
			},
			{
				"_id": "61baf93039bf10000127e112",
				"value": "653000",
				"first_letter": "K",
				"text": "克孜勒苏柯尔克孜自治州",
				"parent_value": "650000",
				"pinyin": "kezileisukeerkezizizhizhou",
				"type": 1,
				"zip_value": "845350"
			},
			{
				"_id": "61baf93039bf10000127e117",
				"value": "653100",
				"first_letter": "K",
				"text": "喀什地区",
				"parent_value": "650000",
				"pinyin": "kashidiqu",
				"type": 1,
				"zip_value": "844000"
			},
			{
				"_id": "61baf93039bf10000127e124",
				"value": "653200",
				"first_letter": "H",
				"text": "和田地区",
				"parent_value": "650000",
				"pinyin": "hetiandiqu",
				"type": 1,
				"zip_value": "848000"
			},
			{
				"_id": "61baf93039bf10000127e12d",
				"value": "654000",
				"first_letter": "Y",
				"text": "伊犁哈萨克自治州",
				"parent_value": "650000",
				"pinyin": "yilihasakezizhizhou",
				"type": 1,
				"zip_value": "835000"
			},
			{
				"_id": "61baf93039bf10000127e139",
				"value": "654200",
				"first_letter": "T",
				"text": "塔城地区",
				"parent_value": "650000",
				"pinyin": "tachengdiqu",
				"type": 1,
				"zip_value": "834700"
			},
			{
				"_id": "61baf93039bf10000127e141",
				"value": "654300",
				"first_letter": "A",
				"text": "阿勒泰地区",
				"parent_value": "650000",
				"pinyin": "aletaidiqu",
				"type": 1,
				"zip_value": "836500"
			},
			{
				"_id": "61baf93039bf10000127e149",
				"value": "659000",
				"first_letter": "Z",
				"text": "自治区直辖县级行政区划",
				"parent_value": "650000",
				"pinyin": "zizhiquzhixiaxianjixingzhengquhua",
				"type": 1,
				"zip_value": "0"
			}
		]

	},
	{
		"_id": "61baf93039bf10000127e153",
		"value": "710000",
		"first_letter": "T",
		"text": "台湾省",
		"pinyin": "taiwansheng",
		"type": 0,
		"zip_value": "0",
		"children": [{
				"_id": "61baf93039bf10000127e154",
				"value": "710100",
				"first_letter": "T",
				"text": "台北市",
				"parent_value": "710000",
				"pinyin": "taibeishi",
				"type": 1,
				"zip_value": "0"
			},
			{
				"_id": "61baf93039bf10000127e161",
				"value": "710200",
				"first_letter": "G",
				"text": "高雄市",
				"parent_value": "710000",
				"pinyin": "gaoxiongshi",
				"type": 1,
				"zip_value": "0"
			},
			{
				"_id": "61baf93039bf10000127e188",
				"value": "710300",
				"first_letter": "T",
				"text": "台南市",
				"parent_value": "710000",
				"pinyin": "tainanshi",
				"type": 1,
				"zip_value": "0"
			},
			{
				"_id": "61baf93039bf10000127e1ae",
				"value": "710400",
				"first_letter": "T",
				"text": "台中市",
				"parent_value": "710000",
				"pinyin": "taizhongshi",
				"type": 1,
				"zip_value": "0"
			},
			{
				"_id": "61baf93039bf10000127e1cc",
				"value": "710600",
				"first_letter": "N",
				"text": "南投县",
				"parent_value": "710000",
				"pinyin": "nantouxian",
				"type": 1,
				"zip_value": "0"
			},
			{
				"_id": "61baf93039bf10000127e1da",
				"value": "710700",
				"first_letter": "J",
				"text": "基隆市",
				"parent_value": "710000",
				"pinyin": "jilongshi",
				"type": 1,
				"zip_value": "0"
			},
			{
				"_id": "61baf93039bf10000127e1e2",
				"value": "710800",
				"first_letter": "X",
				"text": "新竹市",
				"parent_value": "710000",
				"pinyin": "xinzhushi",
				"type": 1,
				"zip_value": "0"
			},
			{
				"_id": "61baf93039bf10000127e1e6",
				"value": "710900",
				"first_letter": "J",
				"text": "嘉义市",
				"parent_value": "710000",
				"pinyin": "jiayishi",
				"type": 1,
				"zip_value": "0"
			},
			{
				"_id": "61baf93039bf10000127e1e9",
				"value": "711100",
				"first_letter": "X",
				"text": "新北市",
				"parent_value": "710000",
				"pinyin": "xinbeishi",
				"type": 1,
				"zip_value": "0"
			},
			{
				"_id": "61baf93039bf10000127e207",
				"value": "711200",
				"first_letter": "Y",
				"text": "宜兰县",
				"parent_value": "710000",
				"pinyin": "yilanxian",
				"type": 1,
				"zip_value": "0"
			},
			{
				"_id": "61baf93039bf10000127e214",
				"value": "711300",
				"first_letter": "X",
				"text": "新竹县",
				"parent_value": "710000",
				"pinyin": "xinzhuxian",
				"type": 1,
				"zip_value": "0"
			},
			{
				"_id": "61baf93039bf10000127e222",
				"value": "711400",
				"first_letter": "T",
				"text": "桃园市",
				"parent_value": "710000",
				"pinyin": "taoyuanshi",
				"type": 1,
				"zip_value": "0"
			},
			{
				"_id": "61baf93039bf10000127e230",
				"value": "711500",
				"first_letter": "M",
				"text": "苗栗县",
				"parent_value": "710000",
				"pinyin": "miaolixian",
				"type": 1,
				"zip_value": "0"
			},
			{
				"_id": "61baf93039bf10000127e243",
				"value": "711700",
				"first_letter": "Z",
				"text": "彰化县",
				"parent_value": "710000",
				"pinyin": "zhanghuaxian",
				"type": 1,
				"zip_value": "0"
			},
			{
				"_id": "61baf93039bf10000127e25e",
				"value": "711900",
				"first_letter": "J",
				"text": "嘉义县",
				"parent_value": "710000",
				"pinyin": "jiayixian",
				"type": 1,
				"zip_value": "0"
			},
			{
				"_id": "61baf93039bf10000127e271",
				"value": "712100",
				"first_letter": "Y",
				"text": "云林县",
				"parent_value": "710000",
				"pinyin": "yunlinxian",
				"type": 1,
				"zip_value": "0"
			},
			{
				"_id": "61baf93039bf10000127e286",
				"value": "712400",
				"first_letter": "P",
				"text": "屏东县",
				"parent_value": "710000",
				"pinyin": "pingdongxian",
				"type": 1,
				"zip_value": "0"
			},
			{
				"_id": "61baf93039bf10000127e2a8",
				"value": "712500",
				"first_letter": "T",
				"text": "台东县",
				"parent_value": "710000",
				"pinyin": "taidongxian",
				"type": 1,
				"zip_value": "0"
			},
			{
				"_id": "61baf93039bf10000127e2b9",
				"value": "712600",
				"first_letter": "H",
				"text": "花莲县",
				"parent_value": "710000",
				"pinyin": "hualianxian",
				"type": 1,
				"zip_value": "0"
			},
			{
				"_id": "61baf93039bf10000127e2c7",
				"value": "712700",
				"first_letter": "P",
				"text": "澎湖县",
				"parent_value": "710000",
				"pinyin": "penghuxian",
				"type": 1,
				"zip_value": "0"
			}
		]

	},
	{
		"_id": "61baf93039bf10000127e2ce",
		"value": "810000",
		"first_letter": "X",
		"text": "香港特别行政区",
		"pinyin": "xianggangtebiexingzhengqu",
		"type": 0,
		"zip_value": "0",
		"children": [{
			"_id": "61baf93039bf10000127e2cf",
			"value": "810100",
			"first_letter": "X",
			"text": "香港特别行政区",
			"parent_value": "810000",
			"pinyin": "xianggangtebiexingzhengqu",
			"type": 1,
			"zip_value": "0"
		}]

	},
	{
		"_id": "61baf93039bf10000127e2e2",
		"value": "820000",
		"first_letter": "A",
		"text": "澳门特别行政区",
		"pinyin": "aomentebiexingzhengqu",
		"type": 0,
		"zip_value": "0",
		"children": [{
			"_id": "61baf93039bf10000127e2e3",
			"value": "820100",
			"first_letter": "A",
			"text": "澳门特别行政区",
			"parent_value": "820000",
			"pinyin": "aomentebiexingzhengqu",
			"type": 1,
			"zip_value": "0"
		}]

	}
]
export default options