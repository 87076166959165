<template>
  <div class="boxCenter">
   <!-- <div class="topNavs">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: pathUrl }">{{pathText}}</el-breadcrumb-item>
        <el-breadcrumb-item>工程师详情</el-breadcrumb-item>
      </el-breadcrumb>
    </div> -->
	<div class="talents-top">
		<div class="left">
			<div class="avatar-box">
				 <WzkAvatar :isGender="true" :gender="enginInfo.gender" :imgSrc="enginInfo.headImgUrl" :width="72" :height="72" :waryWidth="72" :waryHeight="72"></WzkAvatar>
				 <div class="cursor invites" @click="invitesBtn">立即邀请</div>
			</div>
			<div class="info-box">
				<div class="username">{{$mHelper.nameSlice(2,userInfo,enginInfo.name,enginInfo.name)}}
					<span class="addrss">{{enginInfo.provinceFy||''}}·{{enginInfo.cityFy||''}}</span>
				</div>
				<div class="number">工程师编号：{{enginInfo.engineerNo}}</div>
				<div class="number" v-if="enginInfo.mobile&&userInfo.identity==3">
				   工程师电话：{{enginInfo.mobile||''}}
				</div>
				<div class="number"  v-if="enginInfo.mobile!=''&&userInfo.identity==3">
				   工程师QQ：{{enginInfo.qq||''}}
				</div>
				<div class="tablines">{{enginInfo.years || '0'}}年工作经验 ｜ {{enginInfo.files.length || '0'}}个专业证书 ｜ 精通{{enginInfo.softwareFy.length || '0'}}个行业软件 ｜ 精通{{enginInfo.majorFy.length || '0'}}个专业领域</div>
				<div class="scores">综合得分<span>{{enginInfo.synthesize||''}}</span></div>
				<div class="scores">已经完成<span>{{enginInfo.completed||''}}单</span></div>
			</div>
		</div>
		<div class="right">
			<div class="tubiaoLienss">
			    <div class="w-m-t-30 mdx-flex">
			        <div id="myChart" :style="{width: '50%', height: '230px'}"></div>
			        <div id="container" :style="{width: '50%', height: '230px'}"></div>
			    </div>
				<div class="w-m-t-30 mdx-flex mdx-row-between">
					<span>
					  总投资:{{enginInfo.investment||''}}元
					</span>
					<span>
					  总规模:{{enginInfo.totalArea||''}}m²
					</span>
					<span>
					  总用时:{{enginInfo.totalDay||''}}天
					</span>
				</div>
			</div>
		</div>
	</div>
	<div class="talents-box">
		<div class="left">
			<div class="lines-title w-m-t-0">
				<span>基本情况</span>
				<div class="hengxian"></div>
			</div>
			<div class="left-box-1">
				<div class="left-item">
					<div class="lfet_item_title">擅长项目：</div>
					<div class="wzk-flex-1">
						<el-tag type="info" class="tags" v-for="(item,index) in enginInfo.typeFy" :key="index+'t'">{{item.label}}</el-tag>
					</div>
				</div>
				<div class="left-item">
					<div class="lfet_item_title">擅长专业：</div>
					<div class="wzk-flex-1">
						<el-tag type="info" class="tags" v-for="(item,index) in enginInfo.majorFy" :key="index+'m'">{{item.name}}</el-tag>
					</div>
				</div>
				<div class="left-item">
					<div class="lfet_item_title">擅长软件：</div>
					<div class="wzk-flex-1">
						<el-tag type="info" class="tags" v-for="(item,index) in enginInfo.softwareFy" :key="index+'s'">{{item.label}}</el-tag>
					</div>
				</div>
				<div class="left-item">
					<div class="lfet_item_title">擅长地区：</div>
					<div class="wzk-flex-1">
						 <el-tag type="info" class="tags" v-for="(item,index) in enginInfo.regionFy" :key="index+'e'">{{item.name}}</el-tag>
					</div>
				</div>
				<div class="left-item">
					<div class="lfet_item_title">有无团队：</div>
					<div class="w-m-l-20">
						{{enginInfo.team==1?'有':'无'}}
					</div>
				</div>
				<div class="left-item">
					<div class="lfet_item_title">是否可以现场工作：</div>
					<div class="w-m-l-20">
						{{enginInfo.necessary==1?'是':'否'}}
					</div>
				</div>
				<div class="left-item">
					<div class="lfet_item_title">工程造价从业年限：</div>
					<div class="w-m-l-20">
						{{enginInfo.years||'0'}}年
					</div>
				</div>
				<div class="left-item">
					<div class="lfet_item_title">执业资格：</div>
					<div class="w-m-l-20">
						{{enginInfo.practiceQuaFy||''}}
					</div>
				</div>
			</div>
			
			<div class="lines-title" v-if="enginInfo.files.length > 0">
				<span>专业证书</span>
				<div class="hengxian"></div>
			</div>
			<div class="left-box-4" v-if="enginInfo.files.length > 0">
				<div class="mdx-flex-wrap" style="display: flex;">
				  <div class="certificateBox mdx-flex-wrap" v-for="(item,index) in enginInfo.files" :key='index'>
				    <div class="certificateBox_lines">
				      <div class="certificateBox_lines_imgBox" v-if="userInfo.identity==3">
				        <img style="width: 135px;height: 180px;" :src="item.url">
				      </div>
				      <div class="certificateBox_lines_name line1">{{item.name||''}}</div>
				    </div>
				  </div>
				</div>
			</div>
			
			<div class="left-box-2">
				<div class="person-title">人员描述</div>
				<div class="person-info">
					{{enginInfo.introduction||'无'}}
				</div>
			</div>
			
			<div class="left-box-2" v-if="userInfo.identity == 3">
				<div class="person-title mdx-flex mdx-row-between">
					<span>人员描述（项目经理）</span>
					<span style="color: #3B77FF; cursor: pointer;" @click="updataIntro()">编辑</span>
				</div>
				<div class="person-info">
					<span v-if="enginInfo.experienceManagers!=null">{{enginInfo.experienceManagers.introduction || '无'}}</span>
					<span v-else>无</span>
				</div>
			</div>
			
			<div class="lines-title">
				<span>工作经历</span>
				<div class="add-title cursor" @click="expUpdata(-1)" v-if="userInfo.identity == 3">
					<img src="../../../static/images/edit@2x.png" />
					<span>添加工作经历</span>
				</div>
				<div class="hengxian" :class="userInfo.identity==3 ? 'hengxian2':''"></div>
			</div>
			<div class="left-box-3">
				<div class="" v-if="enginInfo.experiences.length >0">
					<div class="lc-item" v-for="(item,index) in enginInfo.experiences" :key="index" >
						<div class="itemtitle">
							<div class="title">
								<span>{{item.workCompany || ''}} | {{item.position || ''}}</span><span class="tabs" v-if="item.status==1">项目经理</span>
							</div>
							<div class="time" v-if="item.status==0">{{item.frameDate || ''}}</div>
							<div class="mdx-flex" style="color: #3B77FF;" v-else>
								<span class="cursor w-m-r-10" @click="expUpdata(index,item)">编辑</span>
								<span class="cursor" @click="expDetele(index,item)">删除</span>
							</div>
						</div> 
						<div class="info">
							{{item.achievement || ''}}
						</div>
					</div>
				</div>
				<div class="w-m-t-12" v-else>无</div>
			</div>
			
			<!-- <div class="lines-title" v-if="userInfo.identity==3">
				<span>沟通记录</span>
				<div class="add-title cursor" @click="addTong()">
					<img src="../../../static/images/edit@2x.png" />
					<span>添加沟通记录</span>
				</div>
				<div class="hengxian hengxian2"></div>
			</div>
			<div class="left-box-6" v-if="userInfo.identity==3">
				<div class="item" v-for="(item,index) in enginInfo.tengineerTalksList" :key="index">
					<div class="top">
						<div class="user-box">
							 <WzkAvatar :isGender="false" :imgSrc="item.headImgUrl" :width="32" :height="32" :waryWidth="32" :waryHeight="32" :border-radius="16"></WzkAvatar>
							<span>{{item.jlName || ''}}</span>
						</div>
						<div class="user-right">{{item.createTime || ''}}</div>
					</div>
					<div class="info">
						<div class="info-name" style="#4077DD">项目编号：</div>
						<div class="info-title">{{item.projectName || '--'}}</div>
					</div>
					<div class="info">
						<div class="info-name">沟通内容：</div>
						<div class="info-title">{{item.content || ''}}</div>
					</div>
				</div>
			</div> -->
			
			
			<div class="lines-title">
				<span>项目经验</span>
				<div class="add-title cursor" @click="exproUpdata(-1)" v-if="userInfo.identity == 3">
					<img src="../../../static/images/edit@2x.png" />
					<span>添加项目经验</span>
				</div>
				<div class="hengxian" :class="userInfo.identity==3 ? 'hengxian2':''"></div>
			</div>
			
			<div class="left-box-3">
				<div class="" v-if="enginInfo.experienceProject.length >0">
					<div class="lc-item" v-for="(item,index) in enginInfo.experienceProject" :key="index" >
						<div class="itemtitle">
							<div class="title">
								<span>{{item.projectName || ''}}</span><span class="tabs" v-if="item.status==1">项目经理</span>
							</div>
							<div class="time" v-if="item.status==0">{{item.workTime || ''}}</div>
							<div class="mdx-flex" style="color: #3B77FF;" v-else>
								<span class="cursor w-m-r-10" @click="exproUpdata(index,item)">编辑</span>
								<span class="cursor" @click="exproDetele(index,item)">删除</span>
							</div>
						</div> 
						<div class="info">
							{{item.remark || ''}}
						</div>
					</div>
				</div>
				<div class="w-m-t-12" v-else>无</div>
			</div>
			
			
			<div class="lines-title">
				<span>线上项目</span>
				<div class="hengxian"></div>
			</div>
			<div class="left-box-5">
				<div class="expList" v-for="(item,index) in projectList"  :key="index+'p'">
				  <div class="names">
				    {{item.regionFy}}/{{item.typeFy}}
				    <template>
				      <span v-for="(row,index) in item.majorFy" :key="index+'q'">/{{row.name}}</span>
				    </template>
				    <span>
				      /总投资:￥{{item.totalInvestment||'00'}}
				    </span>
				    <span>
				      /总规模:{{item.totalScale}}m²
				    </span>
				    /{{item.serviceContentFy}}
				  </div>
				  <!-- <div class="prices">
				    ￥{{item.totalInvestment||'0.00'}}
				  </div>
				  <div class="teims">
				    交付时间：{{item.engineerDeadline}}
				  </div> -->
				  <div class="commont-box">
					  <!-- <div class="commont-title">客户评价：</div>
					  <div class="com-item">
						  <div class="item-box">
							  <div class="item-title">服务态度：</div>
							  <el-rate v-model="item.ufRate" disabled disabled-void-color="#D8D8D8"></el-rate>
						  </div>
						  <div class="commontText">{{item.ufRamrk || ''}}</div>
					  </div>
					  <div class="com-item">
						  <div class="item-box" >
							  <div class="item-title">成果质量：</div>
							  <el-rate v-model="item.ucRate" disabled disabled-void-color="#D8D8D8"></el-rate>
						  </div>
						  <div class="commontText">{{item.ucRamrk || ''}}</div>
					  </div>
					  
					  <div class="commont-title w-m-t-16">项目经理评价：</div>
					  <div class="com-item">
						  <div class="item-box">
							  <div class="item-title">服务态度：</div>
							  <el-rate v-model="item.utRate" disabled disabled-void-color="#D8D8D8"></el-rate>
						  </div>
						  <div class="item-box">
							  <div class="item-title">工作质量：</div>
							  <el-rate v-model="item.uzRate" disabled disabled-void-color="#D8D8D8"></el-rate>
						  </div>
						  <div class="item-box">
							  <div class="item-title">工作表现：</div>
							  <el-rate v-model="item.ubRate" disabled disabled-void-color="#D8D8D8"></el-rate>
						  </div>
					  </div> -->
					  <div class="commont-title w-m-t-16">项目评价：</div>
					  <div class="com-item">
							  <!-- <div class="item-box">
								  <div class="item-title">服务态度：</div>
								  <el-rate v-model="item.utRate" disabled disabled-void-color="#D8D8D8"></el-rate>
							  </div> -->
							  <div class="item-box">
								  <div class="item-title">工作质量：</div>
								  <el-rate v-model="item.uzRate" disabled disabled-void-color="#D8D8D8"></el-rate>
							  </div>
							  <div class="item-box">
								  <div class="item-title">工作表现：</div>
								  <el-rate v-model="item.ubRate" disabled disabled-void-color="#D8D8D8"></el-rate>
							  </div>
					  </div>
				  </div>
				 
				</div>
				<div class="mdx-flex mdx-row-center " style="margin-top: 30px;" v-if="list.length">
				  <el-pagination :pageSize="queryParams.limit" :currentPage='queryParams.page' background layout="prev, pager, next" @current-change='pageChange' :page-count="queryParams.totalPage||1"></el-pagination>
				</div>
			</div>
			
		</div>
		<div class="right">
			<div class="right-box-1">
				<div class="item w-m-t-26">
					<div class="box-right-title">综合评价</div>
					 <el-rate v-model="zRvalue" disabled disabled-void-color="#D8D8D8"></el-rate>
				</div>
				<div class="item w-m-t-19">
					<div class="box-right-title">工作质量</div>
					 <el-rate v-model="gRvalue" disabled disabled-void-color="#D8D8D8"></el-rate>
				</div>
				<!-- <div class="item w-m-t-19">
					<div class="box-right-title">服务态度</div>
					 <el-rate v-model="fRvalue" disabled disabled-void-color="#D8D8D8"></el-rate>
				</div> -->
			</div>
			<!-- <div class="right-box-2">
				<div class="box-right-top">
					<div class="right-top-title">客户反馈</div>
					<span>1投诉，待处理1</span>
				</div>
				<div class="box-right-list">
					<div class="box-right-tabs">专业能力强 3</div>
					<div class="box-right-tabs">沟通顺畅 2</div>
				</div>
			</div> -->
			<div class="right-box-2" v-if="userInfo.identity==3">
				<div class="box-right-top">
					<div class="right-top-title">
						<div>人才标签</div>
						<div class="top-edit cursor" @click="tabsShow">
							<img src="../../../static/images/edit@2x.png" />
							<span>添加标签</span>
						</div>
					</div>
				</div>
				<div class="box-right-list">
					<div class="box-right-tabs2" v-for="(item,index) in enginInfo.tengineerLabels" :key="index">{{item.label || ''}} {{item.labelNum || ''}}</div>
				</div>
			</div>
		</div>
	</div>
	<el-dialog :visible.sync="invitesShow"  width="40%" center top='30vh' class="proj-box">
	  <div class="">
		<div class="dialog-title">项目邀请 </div>
		<div class="searchBox w-m-t-16">
		    <el-input placeholder="项目ID" placeholder-class="placeholderClass" v-model="searchText" @keyup.enter.native="searchTap">
		      <i slot="prefix" class="el-input__icon el-icon-search" style="color: #bfbfbf"></i>
		      <template slot="append">
		        <el-button type="primary" style="background-color: #4077DD" @click="searchTap">搜索</el-button>
		      </template>
		    </el-input>
		</div>
	    <el-form label-position='top' label-width="80px">
	      <el-form-item>
			<div class="pro-list">
				<div class="item">
					<el-checkbox-group v-model="addCheckForm.value" :max="10" @change="speckCheckChange">
					  <el-checkbox v-for="(item,index) in invitesList" :key="index" :label="item">
					     <div class="item-title ellipsis">
							 <span class="titTip" v-if="item.isInvites==1">已经邀请</span>
							 <span>{{ item.regionFy }}/{{ item.typeFy }}</span>
							 <template v-if="item.majorFy.length">
							    <span v-for="(row, index) in item.majorFy" :key="index">/{{ row.name }}</span>
							 </template>
							 <span> /总投资:￥{{ item.totalInvestment || "" }} </span>
							 <span> /总规模:{{ item.totalScale }}m² </span>
							 <span>/{{ item.serviceContentFy }}</span>
						 </div>
						 <div class="item-info">项目ID：{{item.sn || ''}}</div>
						 <div class="item-tabs">
							 <!-- <span>成都</span><span>住宅</span><span>有CAD图纸</span><span>房屋土建</span> -->
							 <el-tag type="info" class="tags">{{ item.regionFy }}</el-tag>
							 <el-tag type="info" class="tags" v-if="item.necessary == 1">需要出现场</el-tag>
							 <el-tag type="info" class="tags" v-if="item.provided == 1">提供CAD图纸</el-tag>
							 <el-tag type="info" class="tags">{{ item.typeFy }}</el-tag>
							 <template v-if="item.majorFy.length">
							 	<el-tag type="info" class="tags" v-for="(row, index) in item.majorFy" :key="index + 'c'">
							 		{{ row.name }}
							 	</el-tag>
							 </template>
							 <el-tag type="info" class="tags" v-if="item.serviceContentFy">
							 	{{ item.serviceContentFy}}
							 </el-tag>
							 <template v-if="item.softwareFy.length">
							 	<el-tag type="info" class="tags" v-for="(rows, indexs) in item.softwareFy" :key="indexs + 'x'">
							 		{{ rows.label }}
							 	</el-tag>
							 </template>
						 </div>
					  </el-checkbox>
					</el-checkbox-group>
				</div>
			</div>
	        
	      </el-form-item>
	      <div class="dialog-bom mdx-flex mdx-row-center w-m-t-35">
	         <el-button size="small"  class="close" @click="invitesShow = false">取 消</el-button>
	         <el-button size="small" :loading="loading" type="primary" @click="userCheckSave">确认邀请</el-button>
	      </div>
	    </el-form>
	  </div>
	</el-dialog>
    
	<!--添加沟通记录-->
	<el-dialog class="tipPopup" title="沟通记录" :visible.sync="addgouShow" width="40%" center top='20vh'>
	  <div class="puopContext">
		  <el-form ref="form" label-width="80px">
			  <el-form-item label="项目编号(选填)" class="mingHeind" prop="addTongSn">
			    <el-select v-model="addTongSn" placeholder="请选择" style="width: 50%;">
			        <el-option v-for="(item,index) in majorList" :key="index" :label="item.sn" :value="item.id"></el-option>
			    </el-select>
			  </el-form-item>
			   <el-form-item label="沟通时间:" prop="addTongTime">
				 <div class="inputRight">
				   <el-date-picker v-model="addTongTime"  type="datetime" value-format="yyyy-MM-dd HH:mm:ss" format="yyyy-MM-dd HH:mm:ss" placeholder="选择日期时间"></el-date-picker>
				 </div>
			   </el-form-item>
			   
			   <el-form-item label="沟通内容:" prop="addTongNotes">
					<el-input v-model="addTongNotes" placeholder="请输入沟通内容" type="textarea" maxlength="255" :autosize="{ minRows: 4, maxRows: 6 }" class="input"></el-input>
			   </el-form-item>
		  </el-form>
		  <span slot="footer" class="dialog-bom mdx-flex mdx-row-center w-m-t-50">
			<el-button class="close" size="small" @click.stop="closeGou">取 消</el-button>
		    <el-button class="w-m-l-24" type="primary" size="small" :loading="loading" @click.stop="submitGouEvaluate">保 存</el-button>
		  </span>
	  </div>
	</el-dialog>
	
	<!--添加标签-->
	<el-dialog class="tipPopup tabsList" title="添加标签" :visible.sync="addTagShow" width="40%" left top='20vh'>
	  <div class="puopContext">
		  <el-form ref="form" label-width="80px">
			   <div class="biaoqian">
				   <div class="tagsClass" :class="addTgasList.includes(item.name)==true ? 'active' : '' " v-for="(item,index) in tabsList" :key="index" @click="activTab(item)">{{item.name}}</div>
			   </div>
			   <div class="info">
				   如在上方未找到可用标签，请在下方按照要求
			   </div>
				<el-input v-model="addTagsNotes" placeholder="请填写标签内容，多个标签之间用一个空格分隔,每个标签字符长度不得超过10个字符" type="textarea" maxlength="100" :autosize="{ minRows: 4, maxRows: 6 }" class="input"></el-input>
			   
		  </el-form>
		  <span slot="footer" class="dialog-bom mdx-flex mdx-row-center w-m-t-50">
			<el-button class="close" size="small" @click.stop="closeTags">取 消</el-button>
		    <el-button class="w-m-l-24" type="primary" size="small" :loading="loading" @click.stop="submitTagsEvaluate">提 交</el-button>
		  </span>
	  </div>
	</el-dialog>
	
	<!--邀请理由-->
	<el-dialog class="tipPopup" title="推荐理由" :visible.sync="openInvitesNotes" width="40%" center top='20vh'>
	  <div class="puopContext">
		  <el-form ref="form" label-width="0">
			   <el-form-item  prop="addInvitesNotes">
					<el-input v-model="addInvitesNotes" placeholder="请输入推荐理由" type="textarea" maxlength="200" :autosize="{ minRows: 6, maxRows: 8 }" class="input"></el-input>
			   </el-form-item>
		  </el-form>
		  <span slot="footer" class="dialog-bom mdx-flex mdx-row-center w-m-t-50">
			<el-button class="close" size="small" @click.stop="openInvitesNotes=false">取 消</el-button>
		    <el-button class="w-m-l-24" type="primary" size="small" :loading="loading" @click.stop="userCheckAdd()">确 定</el-button>
		  </span>
	  </div>
	</el-dialog>
	
	<!--个人信息-->
	<el-dialog class="tipPopup" title="个人介绍"  :visible.sync="jsShow" width="40%" left top='30vh'>
	  <div class="puopContext">
	    <p style="color: #ff0000;font-size: 13px;">禁止出现手机\QQ\微信等个人联系方式</p>
	    <div style="height: 20px;"></div>
	    <el-input type="textarea" :rows="5" placeholder="请输入内容" :maxlength="1000" v-model.trim="introduction" style="font-size: 14px;"></el-input>
	  </div>
	  <div class="puopfootBox mdx-text-right">
	    <span>{{introduction.length}}/1000字</span>
	    <el-button size="small" @click="jsShow = false">取 消</el-button>
	    <el-button size="small" type="primary" :loading="loading" @click="introductionTap()">确认</el-button>
	  </div>
	</el-dialog>
	<!--工作经历-->
	<el-dialog class="tipPopup" :title="expShowId>0?'编辑工作经历':'新增工作经历'" :visible.sync="expShow" width="50%" left top='30vh'>
	  <div class="puopContext">
	    <el-form label-position="left" label-width="200px" :model="experiences" ref="expRef" :rules="rulesExp">
	      <el-form-item label="单位性质" prop="workCompany">
	        <div class="inputWidth">
	          <el-select v-model="experiences.workCompany" placeholder="请选择单位性质">
	            <el-option :label="item" v-for="(item,index) in natureUnit" :key='index' :value="item">
	            </el-option>
	          </el-select>
	        </div>
	      </el-form-item>
	      <el-form-item label="工作岗位" prop="position">
	        <div class="inputWidth">
	          <el-input type="text" v-model="experiences.position" placeholder="请输入您的工作岗位" maxlength="20"></el-input>
	        </div>
	      </el-form-item>
		  <el-form-item label="工作开始日期-截至日期:" prop="time" style="position: relative;">
		    <div class="inputWidth">
		     <el-date-picker value-format="yyyy-MM" format="yyyy年MM" v-model="experiences.time" :picker-options="pickerOptions0" type="monthrange" range-separator="至" start-placeholder="开始月份" end-placeholder="结束月份">
		     </el-date-picker>
		    </div>
		  </el-form-item>
	      <el-form-item label="主要业绩:" prop="achievement">
	        <div class="">
	           <el-input type="textarea" :rows="5" :maxlength="1000" placeholder="请输入内容" v-model="experiences.achievement" style="font-size: 14px;"> </el-input>
	        </div>
	      </el-form-item>
	      <div style="height: 15px;"></div>
	      <div class="puopfootBox mdx-text-right">
	        <span>{{experiences.achievement.length}}/1000字</span>
	        <el-button size="small" @click="expShow = false">取 消</el-button>
	        <el-button size="small" type="primary" :loading='loading' @click="expAddAlertTap">确认</el-button>
	      </div>
	    </el-form>
	  </div>
	</el-dialog>
	<!--项目经验-->
	<el-dialog  class="tipPopup" :title="exproShowId>0?'编辑项目经验':'新增项目经验'" :visible.sync="exproShow" width="50%" left top='30vh'>
	  <div class="puopContext">
	    <el-form label-position="left" label-width="200px" :model="experienceProject" ref="exproRef" :rules="rulesExpro">
	      <el-form-item label="项目名称" prop="projectName">
	        <div class="inputWidth">
	          <el-input type="text" v-model="experienceProject.projectName" placeholder="请输入项目名称" maxlength="20"></el-input>
	        </div>
	      </el-form-item>
		  <el-form-item label="开始日期-截至日期:" prop="time" style="position: relative;">
		    <div class="inputWidth">
		     <el-date-picker value-format="yyyy-MM" format="yyyy年MM" v-model="experienceProject.time" :picker-options="pickerOptions0" type="monthrange" range-separator="至" start-placeholder="开始月份" end-placeholder="结束月份">
		     </el-date-picker>
		    </div>
		  </el-form-item>
	      <el-form-item label="主要业绩:" prop="remark">
	        <div class="">
	           <el-input type="textarea" :rows="5" :maxlength="1000" placeholder="请输入内容" v-model="experienceProject.remark" style="font-size: 14px;"> </el-input>
	        </div>
	      </el-form-item>
	      <div style="height: 15px;"></div>
	      <div class="puopfootBox mdx-text-right">
	        <span>{{experienceProject.remark.length}}/1000字</span>
	        <el-button size="small" @click="exproShow = false">取 消</el-button>
	        <el-button size="small" type="primary" :loading='loading' @click="exproAddAlertTap">确认</el-button>
	      </div>
	    </el-form>
	  </div>
	</el-dialog>
    
  </div>
</template>

<script>
  import WzkAvatar from '../../../components/wzk-Avatar/index.vue'
  import { tranNumber,bigNumberTransform } from "@/common/utils/num-validate.js";
  import rulesData from '../../../api/rules.js'
  export default {
    components: {
      WzkAvatar
    },
    data() {
      return {
		searchText: '',
		invitesShow:false,
        addgouShow: false,
		addTagShow: false,
        value: 4,
        list: [{}],
        queryParams: {
          page: 1, //当前页数
          limit: 10, //每页条数
          totalPage: 1
        },
        pathText: '',
        pathUrl: '',
        enginInfo: {},
        Id: null,
        projectList: [] ,//项目经验
		addCheckForm: {
		  value: [],
		  selectList: [],
		},
		zRvalue:'',
		gRvalue:'',
		fRvalue:'',
		invitesList:[],
		loading: false,
		majorList: [ ],
		addTongSn: '',
		addTongTime: '',
		addTongNotes:'',
		addTgasList: [],
		addTagsNotes:'',
		openInvitesNotes: false, 	//邀请理由
		addInvitesNotes:'',
		tabsList: [
			{
				id: 1,
				name: '专业性高'
			},
			{
				id: 2,
				name: '工作效率快'
			},
			{
				id: 3,
				name: '沟通顺畅'
			},
			{
				id: 4,
				name: '作业规范'
			},
			{
				id: 5,
				name: '与实际不符合'
			},
			{
				id: 6,
				name: '基础水平差'
			},
			
			{
				id: 7,
				name: '沟通困难'
			},
		],
		jsShow: false,  //编辑工程师信息
		introduction: '',  //描述
		
		pickerOptions0: {
		  disabledDate(time) {
		    return time.getTime() > Date.now();
		  }
		},
		expShow: false,
		rulesExp: rulesData.rulesExp,
		natureUnit: ["施工单位", "设计单位", "监理单位", "咨询单位", '项目管理公司', '建设单位', '管理机构', '招标代理', '个人工作室', '自由职业', '其他'],
		experiences: { //工作经历
		  "achievement": "", //成果（业绩）
		  "frameDate": "", //工作时间段（2003年4月-至今）
		  "position": "", //职位（造价师（造价员，预算员）
		  "workCompany": "", //工作单位（咨询单位）
		  "time": []
		},
		expShowId: 0,
		
		exproShow: false,
		exproShowId: 0,
		exproList: [],
		rulesExpro: rulesData.rulesExpro,
		experienceProject: { //项目经验
		  "projectName": "",
		  "workTime": "",
		  "remark": "",
		  "time": []
		},
		
      }
    },
    computed: {
      userInfo() {
        return this.$store.getters['userInfo']
      }
    },
    mounted() {
      //this.getEchartData()
    },
    created() {
      let data = this.$route.query;
      if (!data.pathName || !data.pathUrl || !data.id) {
        console.log('参数错误');
        return;
      }
      this.pathText = data.pathName
      this.pathUrl = data.pathUrl
      this.Id = data.id
      this.getUserDetail();
	  this.manrProjectList();

    },

    methods: {
	    searchTap(){
		    this.invitesBtn();
	    },	
		getProjectList() {
			this.$http.get('engineer/experience', {
			  pageNum: this.queryParams.page,
			  pageSize: this.queryParams.limit,
			  engineerId: this.enginInfo.id
			}).then(res => {
			  if (res.code == 200) {
				this.queryParams.totalPage = res.data.pages;
				
				res.data.list.forEach((vo,index)=>{
					if(vo.evaluates2!=null){
						vo.ufRate = vo.evaluates2.attitude;
						vo.ufRamrk = vo.evaluates2.gcRemark;
						vo.ucRate = vo.evaluates2.quality;
						vo.ucRamrk = vo.evaluates2.jlRemark;
					}else{
						vo.ufRate = null;
						vo.ufRamrk = "";
						vo.ucRate = null;
						vo.ucRamrk = "";
					}
					
					if(vo.jlEvaluates!=null){
						let aa = JSON.parse(vo.jlEvaluates.evaluateContent);
						vo.utRate = aa[0].isCheck;
						vo.uzRate = aa[1].isCheck;
						vo.ubRate = aa[2].isCheck;
					}else{
						vo.utRate = null;
						vo.uzRate = null;
						vo.ubRate = null;
					}
					
					if(vo.ucRate!=null || vo.uzRate!=null){
						vo.ucRate = (vo.ucRate + vo.uzRate)/2;
					}
					
					
				})
				
				this.projectList = res.data.list
			  } else {
				this.projectList = []
			  }
			}).catch(err => {})
		},
      pageChange(e) {
        this.queryParams.page = e
        this.getExpList()
      },

		//获取用户工程师信息
		getUserDetail() {
			this.$http.get('engineer/detail', {
			  id: this.Id
			}).then(res => {
			  if (res.code == 200) {
				res.data.investment = bigNumberTransform(res.data.investment);
				res.data.totalArea = bigNumberTransform(res.data.totalArea);
				res.data.totalDay = bigNumberTransform(res.data.totalDay);
				this.zRvalue = res.data.synthesize/20;
				this.gRvalue = res.data.attitude;
				this.fRvalue = res.data.quality;
				
				var ata =[];
				if(this.userInfo.identity!=3 && res.data.experiences.length >0){
					res.data.experiences.forEach(vo=>{
						 if(vo.status==0){
							 ata.push(vo);
						 }
					});
					res.data.experiences = ata;
				}
				
				var aba =[];
				if(this.userInfo.identity!=3 && res.data.experienceProject.length >0){
					res.data.experienceProject.forEach(vo=>{
						 if(vo.status==0){
							 aba.push(vo);
						 }
					});
					res.data.experienceProject = aba;
				}
				
				
				this.enginInfo = res.data;
				
				
				let datas = []
				let datas2 = []
				datas2.push({
					hpl:parseFloat(this.enginInfo.favorable.replace("%","")).toFixed(0),
					jsl:parseFloat(this.enginInfo.timeliness.replace("%","")).toFixed(0),
					yxl:parseFloat(this.enginInfo.excellent).toFixed(0),
					xyz:this.enginInfo.reputation,
					wcl:this.enginInfo.completesize
				})
				res.data.hxMap.forEach(e => {
				  datas.push({
					value: e.bfb,
					name: e.type
				  })
				})
				 this.getProjectList();
				 setTimeout(() => {
				   this.getEchartData(datas)
				   this.getEchartData2(datas2)
				 }, 1000); // 5000毫秒后执行
				  
				  
			  }
			})
		},
	  
		//邀请参加项目
		invitesBtn(){
			if(!this.userInfo.identity){
				this.$router.push({
				  path: '/login'
				});
				return;
			}
			  
			let type =  localStorage.getItem('changeType');
			if(type == 2 && this.userInfo.identity == 2){
				this.$message.warning('请先切换为客户身份');
				return;
			}
				
			   this.invitesList = [];
			   this.addCheckForm.value = [];
			   this.addCheckForm.selectList = [];
			if(this.userInfo.identity == 3){
				this.getInvitesUmarList();   
			}else{
				this.getInvitesUserList();   
			}
			 
		},
		getInvitesUmarList(){
			this.$http.get("director/list", {
				status: 3,
				pageNum: 1,
				pageSize: 999,
				qName: this.searchText
			}).then((res) => {
			  if (res.code == 200) {
				  if(res.data.projectList.list.length > 0){
						  this.invitesShow = true;	  
						  res.data.projectList.list.forEach((item)=> {
							if(item.appliesIds==null || item.appliesIds.length==0){
								item.isInvites = 0;
							}else{
								let aa = item.appliesIds.includes(this.enginInfo.id);
								if(aa==true){
									item.isInvites = 1;
								}
							}
							 
						  });
						  this.invitesList = res.data.projectList.list;
				  }else{
						  this.$message.warning('暂无报名中的项目');
						  return;
				  }
				  
			  } else {
				this.invitesList = [];
			  }
			 
			}).catch((err) => {
				
			});
		},
		getInvitesUserList(){
			this.$http.get("release/list", {
					status: 33,
					pageNum: 1,
					pageSize: 999,
					qName: this.searchText
				}).then((res) => {
				  if (res.code == 200) {
					  if(res.data.projectList.list.length > 0){
						  this.invitesShow = true;	  
						  res.data.projectList.list.forEach((item)=> {
							if(item.appliesIds==null || item.appliesIds.length==0){
								item.isInvites = 0;
							}else{
								let aa = item.appliesIds.includes(this.enginInfo.id);
								if(aa==true){
									item.isInvites = 1;
								}
							}
							 
						  });
						  this.invitesList = res.data.projectList.list;
					  }else{
						  this.$message.warning('暂无报名中的项目');
						  return;
					  }
					  
					  
					  // res.data.projectList.list.forEach(function (item) {
					  //   item.totalInvestment = initNumberValue(item.totalInvestment);
					  //   item.totalScale = initNumberValue(item.totalScale);
					  //   item.estimatedPrice = initNumberValue(item.estimatedPrice);
					  //   item.orderPrice = initNumberValue(item.orderPrice);
					  // });
					  
					 
					 
				  } else {
					this.invitesList = [];
				  }
				 
				}).catch((err) => {
					
			});
		},
	  speckCheckChange(value) {
	     this.addCheckForm.selectList = value;
	  },
		//邀请参加项目
		userCheckSave(){
			if(this.userInfo.identity==3){
				let major = [];
				this.addCheckForm.selectList.forEach((e) => {
					  major.push(e.id);
				});
				  
				if(major.length==0){
					this.$message.warning('请选择项目！');
					return;
				}
				this.loading = true
				this.openInvitesNotes = true;
				this.loading = false;
			}else{
				this.userCheckAdd();
			}
			
			
		},
		
		userCheckAdd(){
			let major = [];
			this.addCheckForm.selectList.forEach((e) => {
				  major.push(e.id);
			});
			  
			if(major.length==0){
				this.$message.warning('请选择项目！');
				return;
			}
			this.loading = true
			this.$http.post('director/invitationIds', {
				engineerId:this.enginInfo.id,
				projectIds: major,
				inviteReason: this.addInvitesNotes,
			}).then(res => {
				if(res.code==200){
					this.$message.success('邀请成功！');
					this.loading = false;
					this.invitesList = [];
					this.invitesBtn();
					this.openInvitesNotes = false;
				}
				 
			}).catch(err => {
				this.loading = false
			})
		},
		
		
		addTong(){
			this.addgouShow = true;
		},
		closeGou(){
			this.addgouShow = false;
			this.addTongSn = '';
			this.addTongTime = '';
			this.addTongNotes = '';
		},
		tabsShow(){
			this.addTagShow = true;
		},
		closeTags(){
			this.addTagShow = false;
		},
		activTab(item){
			if(this.addTgasList.includes(item.name) == true){
				//移除
				let a = [];
				this.addTgasList.forEach((e,i)=>{
					if(e!=item.name){
						a.push(e);
					}
				})
				this.addTgasList = a;
			}else{
				this.addTgasList.push(item.name);
			}
			
		},
		//获取项目经理的项目
		manrProjectList(){
			this.$http.get('engineer/talkList/'+this.Id, {
				
			}).then(res => {
				if(res.code==200){
					this.majorList = res.data; 
				}
			}).catch(err => {
				this.loading = false
			})
		},
		//添加工程师沟通记录
		submitGouEvaluate(){
			if(this.addTongTime == ''){
				this.$message.error('请选择时间！');
				return;
			}
			if(this.addTongNotes == ''){
				this.$message.error('请输入沟通内容！');
				return;
			}
			
			this.loading = true
			this.$http.post('engineer/talk', {
				engineerId:this.enginInfo.id,
				content: this.addTongNotes,
				createTime: this.addTongTime,
				projectId: this.addTongSn
			}).then(res => {
				if(res.code==200){
					this.$message.success('添加成功！');
					this.addgouShow = false,
					this.loading = false;
					this.addTongNotes ='';
					this.addTongTime = '';
					this.addTongSn = '';
					this.getUserDetail();
				}
				 
			}).catch(err => {
				this.loading = false
			})
		},
		
		//添加标签
		submitTagsEvaluate(){
			let datas = [];
			if(this.addTgasList.length==0 && this.addTagsNotes==''){
				 this.$message.warning('请选择标签或者输入标签！');
				 return;
			}
			if(this.addTgasList.length > 0 && this.addTagsNotes==''){
				datas = this.addTgasList;
			}else if(this.addTgasList.length == 0 && this.addTagsNotes!=''){
				let listb = this.addTagsNotes.split(' ');
				listb.forEach((e,i)=>{
					if(e.length > 10){
						return;
					}
					datas.push(e);
				})
			}else if(this.addTgasList.length > 0 && this.addTagsNotes!=''){
				datas = this.addTgasList;
				let lista = this.addTagsNotes.split(' ');
				lista.forEach((e,i)=>{
					if(e.length > 10){
						return;
					}
					datas.push(e);
				})
			}
			
			this.loading = true
			this.$http.post('label/save', {
				engineerId:this.Id,
				labelList: datas,
			}).then(res => {
				if(res.code==200){
					this.$message.success('标签添加成功！');
					this.addTagShow = false;
					this.loading = false;
					this.addTgasList = [];
					this.addTagsNotes = '';
					this.getUserDetail();
				}
				 
			}).catch(err => {
				this.loading = false
			})
			
		},
		
		
	  
		getEchartData(datas) {
			let myChart = this.$echarts.init(document.getElementById('myChart'))
			// 绘制图表
			myChart.setOption({
			  legend: {
				show: false
			  },
			  toolbox: {
				show: false,
				feature: {
				  mark: {
					show: false
				  },
				  dataView: {
					show: true,
					readOnly: false
				  },
				  restore: {
					show: true
				  },
				  saveAsImage: {
					show: false
				  }
				}
			  },
			  series: [{
				name: '',
				type: 'pie',
				radius: [45, 80],
				center: ['50%', '50%'],
				roseType: 'area',
				itemStyle: {
				  borderRadius: 1
				},
				// color: ['#00AFC0', '#0090D4', '#185BA1'],
				data: datas
			  }]
			});
		},
		getEchartData2(datas2) {
            var mCharts = this.$echarts.init(document.getElementById('container'))
			// 各个维度的最大值
            var dataMax = [
                {
                    //name: '好评率'+datas2[0].hpl+'%',
					name: '好评率',
                    max: 100,
					color: '#000',
                },
                {
                    //name: '及时率'+datas2[0].jsl+'%',
					name: '及时率',
                    max: 100,
					color: '#000',
                },
                {
                    //name: '优秀率'+datas2[0].yxl+'%',
					name: '优秀率',
                    max: 100,
					color: '#000',
                },
                {
                    //name: '信誉值'+datas2[0].xyz,
					name: '信誉值',
                    max: 100,
					color: '#000',
                },
                {
                    //name: '完成率'+datas2[0].wcl+'%',
					name: '完成率',
                    max: 100,
					color: '#000',
                },
            ]
            mCharts.setOption({
                radar: {
                    indicator: dataMax,  //配置各个维度的最大值
                },
                series:[
                    {
                        radius: [45, 80],
                        center: ['40%', '100%'],
                        type: 'radar',
                        data:
                            [
                                {
                                    value: [datas2[0].hpl,datas2[0].jsl,datas2[0].yxl,datas2[0].xyz,datas2[0].wcl]
                                }
                            ]
                    }
                ]
            })
        },
		
		//编辑个人介绍弹窗
		updataIntro() {
			if(this.enginInfo.experienceManagers!=null){
				this.introduction = this.enginInfo.experienceManagers.introduction || ''
			}
		  
		  this.jsShow = true
		},
		// 个人介绍
		introductionTap() {
		  if (!this.introduction) {
		    this.$message.success('请输入个人介绍')
		    return;
		  }
		  this.loading = true
		  //let data = JSON.parse(JSON.stringify(this.enginInfo))
		  //data.introduction = this.introduction;
		  
		  let id = '';
		  if(this.enginInfo.experienceManagers!=null){
			  id = this.enginInfo.experienceManagers.id;
		  }
		  
		  this.$http.post('engineer/tExperienceManager', {
			  introduction: this.introduction,
			  engineerId: this.Id,
			  id: id,
		  }).then(res => {
		    if (res.code == 200) {
		      this.$message.success('修改成功')
		      this.jsShow = false;
		      this.getUserDetail();
		    }
		    this.loading = false
		  }).catch(err => {
		    this.loading = false
		  })
		  // this.formData.introduction = JSON.parse(JSON.stringify(this.introduction))
		  // this.jsShow = false;
		},
		
		//添加工作经验
		expUpdata(index, item) {
		  if (index >= 0) {
		    this.expShowId = index;
		    this.experiences = JSON.parse(JSON.stringify(item))
		    this.experiences.time = this.experiences.frameDate.split('至')
		    this.expShow = true
		  } else {
		    this.expShowId = -1;
		    this.$nextTick(() => {
		      this.$refs['expRef'].resetFields(); //初始化数据
		      this.experiences.time = []
		      this.experiences.achievement = ''
		    })
		    this.expShow = true
		  }
		},
		//删除
		expDetele(index, item) {
		  let data = item
		  data.type = 3
		  this.$http.post('engineer/experience', item).then(res => {
		    this.loading = false
		    if (res.code == 200) {
		      this.$message.success('删除成功')
		      this.enginInfo.experiences.splice(index, 1)
		    }
		
		  })
		},
		
		//添加项目经验
		exproUpdata(index, item) {
		  if (index >= 0) {
		    this.exproShowId = index;
		    this.experienceProject = JSON.parse(JSON.stringify(item))
		    this.experienceProject.time = this.experienceProject.workTime.split('至')
		    this.exproShow = true
		  } else {
		    this.exproShowId = -1;
		    this.$nextTick(() => {
		      this.$refs['exproRef'].resetFields(); //初始化数据
		      this.experienceProject.time = []
		      this.experienceProject.remark = ''
		    })
		    this.exproShow = true
		  }
		},
		//删除
		exproDetele(index, item) {
		  let data = item
		  data.type = 3
		  this.$http.post('engineer/experienceProject', item).then(res => {
		    this.loading = false
		    if (res.code == 200) {
		      this.$message.success('删除成功')
		      this.enginInfo.experienceProject.splice(index, 1)
		    }
		
		  })
		},
		
		//添加和编辑工作经历
		expAddAlertTap() {
		  this.$refs['expRef'].validate((valid) => {
		    if (valid) {
		      console.log(this.experiences.time);
		      if (this.experiences.time.length < 1) {
		        this.$message.error('请选择工作时间段')
		        return
		      }
		      if (!this.experiences.achievement) {
		        this.$message.error('请输入主要业绩')
		        return
		      }
			  
			  this.experiences.engineerId = this.Id;
			  
		      this.loading = true
		      if (this.expShowId >= 0) {
		        //编辑
		        this.experiences.frameDate = this.experiences.time.join('至')
		        this.experiences.type = 2;
		        this.$http.post('engineer/experience', this.experiences).then(res => {
		          this.loading = false
		          if (res.code == 200) {
		            this.$message.success('修改成功')
		            this.$refs['expRef'].resetFields(); //初始化数据
		            this.experiences.time = []
		            this.experiences.achievement = ''
		            this.expShow = false;
		            this.getUserDetail();
		          }
		        }).catch(err => {
		          this.loading = false
		        })
		      } else {
		        this.experiences.frameDate = this.experiences.time.join('至')
		        this.experiences.type = 1;
		        this.$http.post('engineer/experience', this.experiences).then(res => {
		          this.loading = false
		          if (res.code == 200) {
		            this.$message.success('添加成功')
		            this.$refs['expRef'].resetFields(); //初始化数据
		            this.experiences.time = []
		            this.experiences.achievement = ''
		            this.expShow = false;
		            this.getUserDetail();
		          }
		        }).catch(err => {
		          this.loading = false
		        })
		      }
		    }
		  });
		},
		
		//添加和编辑项目经验
		exproAddAlertTap() {
		  this.$refs['exproRef'].validate((valid) => {
		    if (valid) {
		      if (this.experienceProject.time.length < 1) {
		        this.$message.error('请选择时间段')
		        return
		      }
		      if (!this.experienceProject.remark) {
		        this.$message.error('请输入项目描述')
		        return
		      }
			  
			  this.experienceProject.engineerId = this.Id;
			  
		      this.loading = true
		      if (this.exproShowId >= 0) {
		        //编辑
		        this.experienceProject.workTime = this.experienceProject.time.join('至')
		        this.experienceProject.type = 2;
		        this.$http.post('engineer/experienceProject', this.experienceProject).then(res => {
		          this.loading = false
		          if (res.code == 200) {
		            this.$message.success('修改成功')
		            this.$refs['exproRef'].resetFields(); //初始化数据
					this.experienceProject.projectName = ''
		            this.experienceProject.time = []
		            this.experienceProject.remark = ''
		            this.exproShow = false;
		            this.getUserDetail();
		          }
		        }).catch(err => {
		          this.loading = false
		        })
		      } else {
		        this.experienceProject.workTime = this.experienceProject.time.join('至')
		        this.experienceProject.type = 1;
		        this.$http.post('engineer/experienceProject', this.experienceProject).then(res => {
		          this.loading = false
		          if (res.code == 200) {
		            this.$message.success('添加成功')
		            this.$refs['exproRef'].resetFields(); //初始化数据
				    this.experienceProject.projectName = ''
		            this.experienceProject.time = []
		            this.experienceProject.remark = ''
		            this.exproShow = false;
		            this.getUserDetail();
		          }
		        }).catch(err => {
		          this.loading = false
		        })
		      }
		    }
		  });
		},
		
		
    }
  }
</script>
<style lang="css">
  .el-tooltip__popper {
    width: 800px !important;
    background-color: #ffffff;
    color: #333;
  }

  .el-tooltip__popper {
    width: 80%;
    /*修改宽度*/
    box-shadow: 0 0 10px #999;
    background: #fff !important;
    /*背景色  !important优先级*/
    /* opacity:  !important; */
    /*背景色透明度*/
    color: #333 !important;
    line-height: 20px;
    /*字体颜色*/
  }

  /* .el-tooltip__popper.is-dark {
    max-width: 60% !important;
    line-height: 24px;
    color: #fff !important;
    background-color: rgb(48, 65, 86) !important;
  } */
</style>
<style lang="scss" scoped>
	.talents-top{
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-top: 32px;
		.left{
			display: flex;
			width: 50%;
			.avatar-box{
				display: flex;
				flex-flow: column;
				align-items: center;
				.invites{
					width: 96px;
					height: 40px;
					background: #4077DD;
					border-radius: 8px;
					font-family: PingFangSC, PingFang SC;
					font-weight: 400;
					font-size: 16px;
					color: #FFFFFF;
					line-height: 24px;
					text-align: center;
					font-style: normal;
					display: flex;
					justify-content: center;
					align-items: center;
					margin-top: 33px;
				}
			}
			.info-box{
				margin-left: 48px;
				.username{
					font-family: PingFangSC, PingFang SC;
					font-weight: 500;
					font-size: 18px;
					color: rgba(0,0,0,0.85);
					line-height: 21px;
					text-align: left;
					font-style: normal;
					.addrss{
						font-family: PingFangSC, PingFang SC;
						font-weight: 400;
						font-size: 14px;
						color: rgba(0,0,0,0.45);
						line-height: 21px;
						text-align: left;
						font-style: normal;
						margin-left: 16px;
					}
				}
				.number{
					font-family: PingFangSC, PingFang SC;
					font-weight: 400;
					font-size: 16px;
					color: rgba(0,0,0,0.85);
					line-height: 21px;
					text-align: left;
					font-style: normal;
					margin-top: 22px;
				}
				.tablines{
					font-family: PingFangSC, PingFang SC;
					font-weight: 400;
					font-size: 14px;
					color: #4077DD;
					line-height: 21px;
					text-align: left;
					font-style: normal;
					margin-top: 24px;
				}
				.scores{
					font-family: PingFangSC, PingFang SC;
					font-weight: 400;
					font-size: 16px;
					color: rgba(0,0,0,0.45);
					line-height: 20px;
					text-align: left;
					font-style: normal;
					margin-top: 24px;
					span{
						color: #4077DD;
						margin-left: 16px;
					}
				}
			}
		}
		.right{
			width: 50%;
			.tubiaoLienss{
				
			}
		}
	}		
	.talents-box{
		display: flex;
		justify-content: space-between;
		margin-top: 64px;
		.left{
			width: calc(100% - 324px);
			.left-box-1{
				margin-top: 28px;
				.left-item{
					display: flex;
					margin-bottom: 12px;
					.lfet_item_title{
						font-family: PingFangSC, PingFang SC;
						font-weight: 400;
						font-size: 16px;
						color: rgba(0,0,0,0.85);
						line-height: 21px;
						text-align: left;
						font-style: normal;
					}
					::v-deep .el-tag{
						background: #F5F7FA;
						border-radius: 4px;
						font-family: PingFangSC, PingFang SC;
						font-weight: 400;
						font-size: 14px;
						color: #555C70;
						font-style: normal;
						margin-right: 8px;
						height: 28px;
						line-height: 28px;
						margin-bottom: 8px;
					}
				}
			}
			.left-box-2{
				margin-top: 24px;
				.person-title{
					font-family: PingFangSC, PingFang SC;
					font-weight: 400;
					font-size: 16px;
					color: rgba(0,0,0,0.45);
					line-height: 21px;
					text-align: left;
					font-style: normal;
				}
				.person-info{
					font-family: PingFangSC, PingFang SC;
					font-weight: 400;
					font-size: 16px;
					color: rgba(0,0,0,0.85);
					line-height: 21px;
					text-align: left;
					font-style: normal;
					margin-top: 12px;
					white-space: normal;
					word-break: break-all;
				}
			}
			.left-box-3{
				.lc-item{
				  margin-top: 24px;
				  .itemtitle{
					  display: flex;
					  justify-content: space-between;
					  .title{
						  font-family: PingFangSC, PingFang SC;
						  font-weight: 500;
						  font-size: 16px;
						  color: rgba(0,0,0,0.85);
						  line-height: 21px;
						  text-align: left;
						  font-style: normal;
						  .tabs{
							  padding: 3px 7px;
							  border-radius: 4px;
							  border: 1px solid #4077DD;
							  font-family: PingFangSC, PingFang SC;
							  font-weight: 500;
							  font-size: 10px;
							  color: #4077DD;
							  text-align: center;
							  font-style: normal;
							  margin-left: 8px;
						  }
					  }
					  .time{
						  font-family: PingFangSC, PingFang SC;
						  font-weight: 400;
						  font-size: 14px;
						  color: rgba(0,0,0,0.45);
						  line-height: 21px;
						  text-align: right;
						  font-style: normal;
					  }
				  }
				  .info{
					  font-family: PingFangSC, PingFang SC;
					  font-weight: 400;
					  font-size: 16px;
					  color: rgba(0,0,0,0.85);
					  line-height: 21px;
					  text-align: left;
					  font-style: normal;
					  margin-top: 12px;
					  white-space: normal;
					  word-break: break-all;
				  }
				}
			}
			.left-box-4{
				.certificateBox {
				  display: flex;
				
				  &_lines {
				    width: 135px;
				    margin-top: 20px;
				    margin-right: 20px;
				
				    &_imgBox {
				      position: relative;
				
				      .iconbox {
				        position: absolute;
				        top: 0;
				        right: 0;
				        width: 16px;
				        height: 16px;
				        background: rgba(0, 0, 0, 0.4);
				        text-align: center;
				        line-height: 16px;
				      }
				
				      .el-icon-close {
				        color: #fff;
				        font-size: 13px;
				
				      }
				    }
				
				    &_name {
				      margin-top: 10px;
				    }
				  }
				}
			}
			.left-box-5{
				margin-bottom: 32px;
				.expList {
					margin-top: 25px;
					margin-bottom: 12px;
				  .names {
				     font-family: PingFangSC, PingFang SC;
				     font-weight: 500;
				     font-size: 16px;
				     color: #3B77FF;
				     line-height: 21px;
				     text-align: left;
				     font-style: normal;
				  }
				  .prices {
				    font-size: 24px;
				    font-weight: 550;
				    color: #FF3B30;
				  }
				  .teims {
				    font-size: 14px;
				    color: #757575;
				  }
					.commont-box{
						min-height: 100px;
						background: #F7F7F7;
						border-radius: 4px;
						padding: 12px;
						margin-top: 12px;
						.commont-title{
							font-family: PingFangSC, PingFang SC;
							font-weight: 600;
							font-size: 14px;
							color: rgba(0,0,0,0.85);
							line-height: 20px;
							text-align: left;
							font-style: normal;
						}
						.com-item{
							margin-top: 10px;
							.item-box{
								display: flex;
								margin-bottom: 9px;
								.item-title{
									font-family: PingFangSC, PingFang SC;
									font-weight: 400;
									font-size: 14px;
									color: rgba(0,0,0,0.85);
									line-height: 21px;
									text-align: left;
									font-style: normal;
								}
							}
							.commontText {
								 font-family: PingFangSC, PingFang SC;
								 font-weight: 400;
								 font-size: 14px;
								 color: rgba(0,0,0,0.85);
								 line-height: 20px;
								 text-align: left;
								 font-style: normal;
								 margin-top: 8px;
							}
						}
						
					}
				  
				}
				
			}
			.left-box-6{
				margin-top: 24px;
				margin-bottom: 32px;
				.item{
					margin-bottom: 24px;
					.top{
						margin-bottom: 9px;
						display: flex;
						justify-content: space-between;
						.user-box{
							display: flex;
							align-items: center;
							span{
								font-family: PingFangSC, PingFang SC;
								font-weight: 400;
								font-size: 16px;
								color: rgba(0,0,0,0.85);
								line-height: 21px;
								text-align: left;
								font-style: normal;
								margin-left: 11px;
							}
							::v-deep .wzk{
								font-size: 8px;
								bottom: 0;
							}
						}
						.user-right{
							font-family: PingFangSC, PingFang SC;
							font-weight: 400;
							font-size: 16px;
							color: rgba(0,0,0,0.45);
							line-height: 36px;
							text-align: left;
							font-style: normal;
						}
						
					}
					.info{
						display: flex;
						margin-top: 7px;
						.info-name{
							font-family: PingFangSC, PingFang SC;
							font-weight: 400;
							font-size: 16px;
							color: rgba(0,0,0,0.45);
							line-height: 22px;
							text-align: left;
							font-style: normal;
							width: 80px;
						}
						.info-title{
							width: calc(100% - 80px);
							font-family: PingFangSC, PingFang SC;
							font-weight: 400;
							font-size: 16px;
							color: rgba(0,0,0,0.85);
							line-height: 20px;
							text-align: left;
							font-style: normal;
						}
					}
					
					
				}
			}
		}
		.right{
			.right-box-1{
				width: 300px;
				height: 150px;
				background: #FFFFFF;
				border-radius: 8px;
				border: 1px solid #E8E8E8;
				.item{
					display: flex;
					justify-content: space-between;
					align-items: center;
					padding: 0 24px;
					.box-right-title{
						font-family: PingFangSC, PingFang SC;
						font-weight: 500;
						font-size: 16px;
						color: rgba(0,0,0,0.85);
						line-height: 21px;
						text-align: left;
						font-style: normal;
					}
				}
			}
			.right-box-2{
				width: 300px;
				min-height: 251px;
				background: #FFFFFF;
				border-radius: 8px;
				border: 1px solid #E8E8E8;
				margin-top: 24px;
				.box-right-top{
					display: flex;
					justify-content: space-between;
					align-items: center;
					padding: 24px;
					.right-top-title{
						font-family: PingFangSC, PingFang SC;
						font-weight: 500;
						font-size: 16px;
						color: rgba(0,0,0,0.85);
						line-height: 21px;
						text-align: left;
						font-style: normal;
						display: flex;
						justify-content: space-between;
						width: 100%;
						.top-edit{
							display: flex;
							align-items: center;
							img{
								width: 16px;
								height: 16px;
							}
							span{
								color: rgba(0,0,0,0.85);
								margin-left: 8px;
							}
						}
					}
					span{
						font-family: PingFangSC, PingFang SC;
						font-weight: 400;
						font-size: 14px;
						color: #FF4D4F;
						line-height: 21px;
						text-align: left;
						font-style: normal;
					}
				}
				.box-right-list{
					padding: 0 24px;
					.box-right-tabs{
						padding: 2px 9px;
						background: rgba(16,197,80,0.1);
						border-radius: 4px;
						font-family: PingFangSC, PingFang SC;
						font-weight: 400;
						font-size: 14px;
						color: #10C550;
						line-height: 20px;
						text-align: left;
						font-style: normal;
						margin-right: 12px;
						margin-bottom: 12px;
						display: inline-flex;
					}
					.box-right-tabs2{
						padding: 2px 9px;
						background: #F5F7FA;
						border-radius: 4px;
						font-family: PingFangSC, PingFang SC;
						font-weight: 400;
						font-size: 14px;
						color: #555C70;
						line-height: 20px;
						text-align: left;
						font-style: normal;
						margin-right: 12px;
						margin-bottom: 12px;
						display: inline-flex;
					}
				}
			}
		}
	}
	.lines-title{
	   .hengxian {
	       width: calc(100% - 86px);
	   }
	   .hengxian2 {
	       width: calc(100% - 220px);
	   }
	}
	::v-deep  .el-rate__icon {
	  font-size: 22px !important;
		// color: #d8d8d8 !important;
	}
	::v-deep  .el-icon-star-on {
		color: #FFB420;
	}
	
	.proj-box{
		::v-deep .el-dialog{
			width: 700px !important;
			height: 656px;
			background: #FFFFFF;
			box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.1), 0px 0px 5px 0px rgba(0,0,0,0.14);
			border-radius: 10px;
			.el-dialog__headerbtn{
				top: 30px;
			}
			.el-dialog__body{
				padding:0 32px 32px;
				.dialog-title{
					font-family: PingFangSC, PingFang SC;
					font-weight: 500;
					font-size: 18px;
					color: rgba(0,0,0,0.85);
					line-height: 25px;
					text-align: left;
					font-style: normal;
				}
				.searchBox{
					.el-input-group__append{
						background-color: #4077dd;
						border: 1px solid #4077dd;
					}
				}
				.dialog-bom{
					.el-button{
						width: 96px;
						height: 40px;
						background: #4077DD;
						border-radius: 8px;
						font-family: PingFangSC, PingFang SC;
						font-weight: 400;
						font-size: 16px;
						color: #FFFFFF;
						line-height: 24px;
						font-style: normal;
						display: flex;
						justify-content: center;
						align-items: center;
					}
					.close{
						background: #FFFFFF;
						border-radius: 6px;
						border: 1px solid #4077DD;
						color: #4077DD;
						margin-right: 14px;
					}
				}
				
				
			}
		}
		.pro-list{
			width: 100%;
			height: 415px;
			overflow-y: scroll;
			overflow-x: hidden;
			margin-top: 24px;
			.item{
				::v-deep .el-checkbox{
					display: flex;
					align-items: center;
					margin-bottom: 24px;
					.el-checkbox__label{
						padding-left: 25px;
						width: 100%;
					}
				}
				.item-title{
					font-family: PingFangSC, PingFang SC;
					font-weight: 500;
					font-size: 18px;
					color: rgba(0,0,0,0.85);
					line-height: 24px;
					text-align: left;
					font-style: normal;
					.titTip{
						padding: 3px 7px;
						border-radius: 4px;
						border: 1px solid #4077DD;
						font-family: PingFangSC, PingFang SC;
						font-weight: 500;
						font-size: 10px;
						color: #4077DD;
						text-align: center;
						font-style: normal;
						margin-right: 8px;
					}
				}
				.item-info{
					font-family: PingFangSC, PingFang SC;
					font-weight: 400;
					font-size: 14px;
					color: rgba(0,0,0,0.45);
					line-height: 21px;
					text-align: left;
					font-style: normal;
					margin-top: 11px;
				}
				.item-tabs{
					margin-top: 9px;
					span{
						background: #F5F7FA;
						border-radius: 4px;
						font-family: PingFangSC, PingFang SC;
						font-weight: 400;
						font-size: 12px;
						color: #555C70;
						line-height: 20px;
						text-align: left;
						font-style: normal;
						padding: 3px 8px;
						margin-right: 8px;
					}
				}
			}
		}
	}
	
	.tipPopup{
		::v-deep .el-dialog{
			box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.1), 0px 0px 5px 0px rgba(0,0,0,0.14);
			border-radius: 10px;
			.el-dialog__header{
				padding: 20px 36px 0 28px;
				.el-dialog__title{
					font-family: PingFangSC, PingFang SC;
					font-weight: 500;
					font-size: 18px;
					color: rgba(0,0,0,0.85);
					line-height: 25px;
					text-align: left;
					font-style: normal;
				}
			}
			.mingHeind{
				.el-form-item__label{
					line-height: 20px;
				}
			}
			.el-input {
				width: 420px;
			}
			
			.dialog-bom{
				.el-button{
					width: 96px;
					height: 40px;
					background: #4077DD;
					border-radius: 8px;
					font-family: PingFangSC, PingFang SC;
					font-weight: 400;
					font-size: 16px;
					color: #FFFFFF;
					line-height: 24px;
					font-style: normal;
					display: flex;
					justify-content: center;
					align-items: center;
				}
				.close{
					background: #FFFFFF;
					border-radius: 6px;
					border: 1px solid #4077DD;
					color: #4077DD;
				}
			}
			.fp-left{
				font-family: PingFangSC, PingFang SC;
				font-weight: 500;
				font-size: 16px;
				color: rgba(0,0,0,0.85);
				line-height: 21px;
				text-align: left;
				font-style: normal;
				margin-bottom: 12px;
			}
		}
	}
	
	.tabsList{
		::v-deep .el-dialog{
			.el-dialog__body{
				padding: 4px 20px 30px;
				.puopContext{
					.biaoqian{
						display: flex;
						flex-wrap: wrap;
						.tagsClass{
							display: flex;
							align-items: center;
							padding: 0 20px;
							height: 30px;
							border-radius: 15px;
							border: 1px solid #E8E8E8;
							font-family: PingFangSC, PingFang SC;
							font-weight: 400;
							font-size: 14px;
							color: rgba(0,0,0,0.85);
							line-height: 20px;
							text-align: left;
							font-style: normal;
							margin-right: 14px;
							margin-bottom: 14px;
						}
						.active{
							background: #4077DD;
							color: #FFFFFF;
							border: 1px solid #4077DD;
						}
					}
					.info{
						font-family: PingFangSC, PingFang SC;
						font-weight: 400;
						font-size: 16px;
						color: rgba(0,0,0,0.85);
						line-height: 22px;
						text-align: left;
						font-style: normal;
						margin-top: 12px;
						margin-bottom: 12px;
					}
				}
			}
		}
		
	}
	
	

</style>
