<template>
    <div class="cancel-page">
		<div class="lines-title" style="margin-top: 0;">
			<div class="lines-left"></div>
			<span>注销账号</span>
			<div class="hengxian"></div>
		</div>
        <div class="cancel-tips">您提交注销前，需确认以下情况，以保障您的权益</div>
        <div class="cancel-explain">
            <div>1.账号处于安全状态，确认是您本人操作</div>
            <div>2.项目数据已结清，您所有的项目已处于已完成状态</div>
            <div>3.钱包中财产已结清，钱包中无可提现余额，工程师质保金余额中无余额</div>
        </div>
        <div class="cancel-btn-wrap">
            <el-button type="primary" :disabled="isCancel" style="padding: 10px 30px;" @click="subimtBtn">{{ cancelTxt }}</el-button>
            <div class="result-tips">
                您申请后经后台审核，通过后即可成功注销账户，注销后您账号所有的相关数据将被清除，如有疑问可联系平台客服{{customerSerTel}}
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            isCancel: false,
            cancelTxt: '申请注销',
			customerSerTel: '',
        }
    },
    beforeMount() {
        this.getUserInfo();
    },
	created() {
		//地址获取
		this.$http.get('/config/getByKey',{configKey: 'telephone_numbers'}).then(res => {
		  if (res.code == 200) {
		    this.customerSerTel = res.data.configValue
		  }
		})	
	},
    methods: {
        getUserInfo() {
            this.$http.get('user/getUserInfo').then(res => {
                if (res.code == 200) {
                    if (res.data.delFlag == 1 || res.data.delFlag == 3) {
                        this.isCancel = true;
                        this.cancelTxt = "已申请注销";
                    } else {
                        this.isCancel = false;
                        this.cancelTxt = "申请注销";
                    }
                }
            })
        },
        subimtBtn(e) {
            if(this.isCancel == true) {
                return
            }
            const h2 = this.$createElement;
            this.$confirm('', {
                title: '提示',
                message: h2('p', {
                    style: 'margin-bottom: 20px'
                }, [
                    h2('span', {
                        style: 'color: red'
                    }, '您确定要注销吗？'),
                ]),
                center: true,
                showCancelButton: true,
                confirmButtonText: '确认注销',
                cancelButtonText: '我再想想',
            })
                .then(() => {
                    var durTip = this.$message({
                        duration: 1000,
                        message: h2('p', null, [
                            h2('span', { style: 'color: #185ba1' }, '处理中… '),
                        ])
                    });

                    this.$http.post('auth/cancellation').then(res => {
                        durTip.close();
                        if (res.code == 200) {
							this.$message.success('注销成功');
							this.$store.commit('outLogin');
							this.$store.state.msgNum = 0;
							this.$router.push({
							  path: '/'
							})
							localStorage.clear();
							return;
                        }
                    }).catch((err) => {
                        durTip.close();
                        console.log(err)
                    })
                })
                .catch(action => {
                    console.log('action', action)
                });
        },
    },
}
</script>
<style lang="scss" scoped>
.lines-title{
	margin-bottom: 24px;
}
.cancel-page {
    padding: 0 0 0 45px;

    .cancel-title {
        color: #333333;
        font-size: 24px;
        line-height: 33.6px;
    }

    .cancel-tips {
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        font-size: 16px;
        color: #FFB420;
        line-height: 20px;
        text-align: left;
        font-style: normal;
    }

    .cancel-explain {
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        font-size: 16px;
        color: #FFB420;
        line-height: 20px;
        text-align: left;
        font-style: normal;

        div {
            margin-top: 16px;
        }
    }

    .cancel-btn-wrap {
        margin-top: 24px;
		::v-deep .el-button{
			width: 96px;
			height: 40px;
			background: #4077DD;
			border-radius: 8px;
			font-family: PingFangSC, PingFang SC;
			font-weight: 400;
			font-size: 16px;
			color: #FFFFFF;
			line-height: 24px;
			font-style: normal;
			display: flex;
			justify-content: center;
			align-items: center;
		}
        .result-tips {
            font-family: PingFangSC, PingFang SC;
            font-weight: 400;
            font-size: 16px;
            color: rgba(0,0,0,0.45);
            line-height: 32px;
            text-align: left;
            font-style: normal;
			margin-top: 18px;
        }
    }

}
</style>