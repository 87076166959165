/*
 * 应用表单校验相关配置
 * 依赖：graceChecker.js 进行校验
 *
 * 使用：引入该js到页面，let res = graceChecker.check({phoneNo:"",code:""},formRule.loginRule)
 */

export default {
  /* 用户密码登录 */
  loginByPassRule: [{
    name: 'mobile',
    checkType: 'notnull',
    checkRule: '',
    errorMsg: '手机号不能为空'
  }, {
    name: 'mobile',
    checkType: 'phoneno',
    checkRule: '',
    errorMsg: '手机号格式不正确'
  }, {
    name: 'password',
    checkType: 'string',
    checkRule: '6,18',
    errorMsg: '请输入6-18位密码'
  }],

  /* 用户验证码登录 */
  loginByCodeRule: [{
    name: 'mobile',
    checkType: 'notnull',
    checkRule: '',
    errorMsg: '手机号不能为空'
  }, {
    name: 'mobile',
    checkType: 'phoneno',
    checkRule: '',
    errorMsg: '手机号格式不正确'
  }, {
    name: 'code',
    checkType: 'string',
    checkRule: '6',
    errorMsg: '请输入6位验证码'
  }],

  /* 发送验证码验证手机号 */
  sendCodeRule: [{
    name: 'mobile',
    checkType: 'phoneno',
    checkRule: '',
    errorMsg: '手机号格式不正确'
  }],
  /* 发送验证码验证手机号 */
  sendemainRule: [{
    name: 'mobile',
    checkType: 'notnull',
    checkRule: '',
    errorMsg: '手机号/邮箱不能为空'
  }, {
    name: 'mobile',
    checkType: 'email',
    checkRule: '',
    errorMsg: '手机号/邮箱格式不正确'
  }],


  /* 用户注册 */
  registerRule: [{
    name: 'mobile',
    checkType: 'notnull',
    checkRule: '',
    errorMsg: '手机号不能为空'
  }, {
    name: 'mobile',
    checkType: 'phoneno',
    checkRule: '',
    errorMsg: '手机号格式不正确'
  }, {
    name: 'nickname',
    checkType: 'notnull',
    checkRule: '',
    errorMsg: '昵称不能为空'
  }, {
    name: 'password',
    checkType: 'string',
    checkRule: '6,18',
    errorMsg: '请输入6-18位密码'
  }],
  //普通用户登录
  ptLogin: [{
    name: 'mobile',
    checkType: 'notnull',
    checkRule: '',
    errorMsg: '手机号不能为空'
  }, {
    name: 'mobile',
    checkType: 'phoneno',
    checkRule: '',
    errorMsg: '手机号格式不正确'
  }, {
    name: 'code',
    checkType: 'string',
    checkRule: '6',
    errorMsg: '请输入6位验证码'
  }],
  ygLogin: [{
    name: 'username',
    checkType: 'notnull',
    checkRule: '',
    errorMsg: '请输入账号'
  }, {
    name: 'password',
    checkType: 'string',
    checkRule: '6,18',
    errorMsg: '请输入6-18位密码'
  }]
};
