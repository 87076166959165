<template>
  <div class="boxCenter">
	  <div class="topNavs">
	  	<div class="mdx-flex mdx-row-between">
	  		<el-breadcrumb separator-class="el-icon-arrow-right">
	  			<el-breadcrumb-item :to="{ path: '/workbench' }">工作台</el-breadcrumb-item>
	  			<el-breadcrumb-item>项目详情</el-breadcrumb-item>
	  		</el-breadcrumb>
	  	</div>
	  	<div class="topMeg cursor" @click="userTsShow = true">
	  		<img src="../../../static/images/project/zixun@2x.png" />
	  		<span>客户投诉(<span style="margin-left: 0;" v-if="projectInfo.tprojectComplainList!=null">{{projectInfo.tprojectComplainList.totalNum || '0'}}</span><span style="margin-left: 0;" v-else>0</span>)</span>
	  	</div>
	  </div>
   
	
	<div class="hall-top">
		<div class="topSn">
			<span>
				<span>项目编号：{{ projectInfo.sn || '--' }}</span>
				<span style="margin-left: 24px; color: #4077DD;" v-if="projectInfo.splitProjects!=null && projectInfo.splitProjects.length > 0">关联项目:
					<span v-for="(item,index) in projectInfo.splitProjects" :key="index" style="margin-left: 4px; color: #4077DD;">{{item.sn || ''}}</span>
				</span>
			</span>
		</div>
		<div class="top-content">
			<div class="left">
				<div class="topNames">
					<div class="line2 wzk-flex-1" style="display: flex; flex-flow: wrap;">
						<span v-if="projectInfo.status == 1" class="topState on-sign-up">待审核</span>
						<span v-if="projectInfo.status == 2" class="topState on-conduct">待付款</span>
						<span v-if="projectInfo.status == 3" class="topState on-sign-up">报名中</span>
						<span v-if="projectInfo.status == 4" class="topState on-conduct">项目进行中</span>
						<span v-if="projectInfo.status == 5" class="topState on-success">圆满完成</span>
						<span v-if="projectInfo.status == 6" class="topState on-completed">投诉中</span>
						<span v-if="projectInfo.status == 9" class="topState on-completed">已取消</span>
						
						<!-- 待付款<div class="status" v-if="projectInfo.status == 2 && projectInfo.automaticTimeFy <= 0"> 已失效</div>
						<div class="status error" v-if="projectInfo.status == 3 && projectInfo.zpEngineer == 1">未指派</div>
						<div class="status blue" v-if="projectInfo.status == 3 && projectInfo.zpEngineer == 2">工程师确认中</div>
						<div class="status blue" v-if="projectInfo.status == 4 && projectInfo.checkStatus == 1">成果待审核</div>
						<div class="status error" v-if="(projectInfo.status == 4 && projectInfo.checkStatus == 2) || (projectInfo.status == 4 && projectInfo.checkStatus == 5)"> 未通过</div>
						<div class="status blue" v-if="projectInfo.status == 4 && projectInfo.checkStatus == 3">待验收</div> -->
						
						<span>{{ projectInfo.regionFy }}/{{ projectInfo.typeFy }}</span>
						<template v-if="projectInfo.majorFyOne.length">
							<span v-for="(row, index) in projectInfo.majorFyOne" :key="index">/{{ row.name }}</span>
						</template>
						<span>/总投资:￥{{ projectInfo.totalInvestment || '00' }}</span>
						<span>/总规模:{{ projectInfo.totalScale }}m²</span>
						<span>/{{ projectInfo.serviceContentFy }}</span>
						<div style="color: #4077DD;font-size: 14px;margin-left: 8px;" v-if="projectInfo.progressList.length" @click="openJinduShow = true">查看进度</div>
					</div>
				</div>
				<div class="topTagsBox">
					<span class="tags">{{ projectInfo.regionFy }}</span>
					<span class="tags" v-if="projectInfo.necessary == 1">需要出现场</span>
					<span class="tags" v-if="projectInfo.provided == 1">提供CAD图纸</span>
					<span class="tags">{{ projectInfo.typeFy }}</span>
					<template v-if="projectInfo.majorFy.length">
						<span class="tags" v-for="(row, index) in projectInfo.majorFy" :key="index + 'w'">{{ row.name }}</span>
					</template>
					<span class="tags" v-if="projectInfo.serviceContentFy">{{ projectInfo.serviceContentFy }}</span>
					<span class="tags" v-for="(row, index) in projectInfo.softwareFy" :key="index + 'e'">{{ row.label }}</span>
				</div>
				<div class="topPrice">
					<span class="prices w-m-r-30" v-if="projectInfo.orderPrice">
					  平台价格：{{ projectInfo.orderPrice || '' }}
					</span>
					<span class="prices w-m-r-30" v-if="projectInfo.earningsAmount">
					  工程师收益：{{ projectInfo.earningsAmount || '' }}
					</span>
					<span class="prices w-m-r-30" v-if="projectInfo.depositAmount">
					  工程师质保金：{{ projectInfo.depositAmount || '' }}
					</span>
					<span class="prices" v-if="!projectInfo.orderPrice">
					  预估价格：{{ projectInfo.estimatedPrice || '' }}
					</span>
				</div>
				
				<div class="topTime m-flex">
					<span> 平台交付时间：{{ projectInfo.deliveryDeadline }}</span>
					<span class="yq-time" v-if="projectInfo.tprojectPostpone!=null && projectInfo.tprojectPostpone.dayNum > 0 && projectInfo.tprojectPostpone.postponeType==0 && projectInfo.tprojectPostpone.isWho==0">已延期{{projectInfo.tprojectPostpone.dayNum}}天</span>
					<span class="w-m-l-24" v-if="projectInfo.status > 1"> 工程师成果交付时间：{{ projectInfo.engineerDeadline }}</span>
					<span class="yq-time" v-if="projectInfo.tprojectPostpone!=null && projectInfo.tprojectPostpone.dayNum > 0 && projectInfo.tprojectPostpone.postponeType==0 && projectInfo.tprojectPostpone.isWho==1">已延期{{projectInfo.tprojectPostpone.dayNum}}天</span>
				</div>
				<div class="topTime m-flex">
					<span v-if="projectInfo.projectStartTime"> 项目开始时间：{{ projectInfo.projectStartTime }}</span>
					<span class="w-m-l-24" v-if="projectInfo.comTime"> 客户验收时间：{{ projectInfo.comTime }}</span>
					<!-- <span class="w-m-l-24" v-if="projectInfo.zbEndTime"> 质保期结束时间：{{ projectInfo.zbEndTime }}</span> -->
				</div>
			</div>
			<div class="right">
				<div v-if="projectInfo.status == 3">
					<div class="right-type-box2">
						<div class="right-two-box">
							<div class="type-btn cursor" @click="openInvites();" v-if="projectInfo.status == 3 && (projectInfo.zpEngineer == 1 || projectInfo.zpEngineer == 2)">
								<span>邀请工程师</span>
							</div>
							<div class="type-btn" style="background: #ddd; color: rgba(0,0,0,0.45);;" v-else>
								<span>邀请工程师</span>
							</div>
							<div class="type-number">
								<img src="../../../static/images/project/hot@2x.png" />
								<span>{{projectInfo.signUpNum || '0'}} 个工程师已报名</span>
							</div>
						</div>
					</div>
				</div>
				
				<div class="right-type-box2" v-if="projectInfo.status == 4">
					<template>
						<!-- 已发布待审核 -->
						<el-dropdown @command="handleCommand">
							<el-button size="small" class="right-btn cursor" type="text" @click.stop="">更多</el-button>
							<el-dropdown-menu slot="dropdown"> 
								<!-- <el-dropdown-item :command="{ item: projectInfo, type: 1, }">下载项目资料</el-dropdown-item> -->
								<el-dropdown-item :command="{ item: projectInfo, type: 1, }">代客户取消</el-dropdown-item>
								<el-dropdown-item :command="{ item: projectInfo, type: 2, }">代工程师取消</el-dropdown-item >
								<el-dropdown-item :command="{ item: projectInfo, type: 3, }">上传成果</el-dropdown-item>
								<el-dropdown-item :command="{ item: projectInfo, type: 4, }">平台取消</el-dropdown-item>
							</el-dropdown-menu>
						</el-dropdown>
					</template>
					<div class="right-two-box" v-if="projectInfo.checkStatus==1">
						<div class="type-btn cursor" @click.stop="materialsbtnTap(projectInfo)">
							<span>成果审核</span>
							<img src="../../../static/images/project/type-icon-1@2x.png" class="top-left-tabs" v-if="projectInfo.checkStatus == -1" />
							<img src="../../../static/images/project/type-icon-3@2x.png" class="top-left-tabs"  v-if="projectInfo.checkStatus == 1 || projectInfo.checkStatus == 2 || projectInfo.checkStatus == 6" />
							<img src="../../../static/images/project/type-icon-2@2x.png" class="top-left-tabs"   v-else-if="projectInfo.checkStatus == 3 || projectInfo.checkStatus == 5" />
						</div>
					</div>
					<div class="right-two-box" v-else>
						<div class="type-btn type-default">
							<span>成果审核</span>
							<img src="../../../static/images/project/type-icon-1@2x.png" class="top-left-tabs" v-if="projectInfo.checkStatus == -1" />
							<img src="../../../static/images/project/type-icon-3@2x.png" class="top-left-tabs"  v-if="projectInfo.checkStatus == 1 || projectInfo.checkStatus == 2 || projectInfo.checkStatus == 6" />
							<img src="../../../static/images/project/type-icon-2@2x.png" class="top-left-tabs"   v-else-if="projectInfo.checkStatus == 3 || projectInfo.checkStatus == 5" />
						</div>
					</div>
					<else></else>
				</div>
				
				<div v-if="projectInfo.status == 5">
					 <div class="right-rate-box1">
					 	<div class="rate-list">
							<div class="item-rate">
							   <div class="item-title">客户评分：</div>
							   <el-rate v-model="uvalue" disabled disabled-void-color="#D8D8D8"></el-rate>
							</div>
							<div class="item-rate">
								<div class="item-title">项目经理评分：</div>
								<el-rate v-model="mvalue" disabled disabled-void-color="#D8D8D8"></el-rate>
							</div>
							<div class="type-btn cursor" @click="goEvaluate" v-if="projectInfo.isEvaluateProject == 0">
								<span>立即评价</span>
							</div>
						</div>
					 </div>
				 </div>	
				
			</div>
		</div>
		<div class="topTip" v-if="projectInfo.cancelOrder!=null && projectInfo.cancelOrder.type==7 && projectInfo.cancelOrder.status == 1">
			<div class="topTitle">
				<div class="tipLeft">
					<img src="../../../static/images/center/wait@2x.png" />
					<span>客户已申请取消，请进行审核</span>
				</div>
				<div class="tipRight cursor" @click="applykh">
					立即审核
				</div>
			</div>
		</div>
		<div class="topTip" v-if="projectInfo.cancelOrder!=null && projectInfo.cancelOrder.type==8 && projectInfo.cancelOrder.status == 1">
			<div class="topTitle">
				<div class="tipLeft">
					<img src="../../../static/images/center/wait@2x.png" />
					<span>工程师已申请取消当前项目，请进行审核</span>
				</div>
				<div class="tipRight cursor" @click="cancalCloseShowApply=true">
					立即审核
				</div>
			</div>
			<div class="tipInfo">说明：{{projectInfo.cancelOrder.applyCause || ''}}</div>
		</div>
		
		<div class="topTip" v-if="projectInfo.status==4 && projectInfo.stageCalculate!=null && projectInfo.stageCalculate.examineStatus==0">
			<div class="topTitle">
				<div class="tipLeft">
					<img src="../../../static/images/center/wait@2x.png" />
					<span>由于该项目工期过长，工程师已发起阶段性结款，请处理</span>
				</div>
				<div class="tipRight">
					<span class="cursor" @click="jdShowApply=true">去审核</span>
				</div>
			</div>
		</div>
	</div>
	
	<div class="hall-box">
		<div class="left">
			<div class="lines-title w-m-t-0">
				<span>项目详情</span>
				<div class="hengxian"></div>
			</div>
			<div class="left-box-1">
				<div class="left-title">项目基本信息</div>
				<div class="btLines m-flex">
					<div class="tag-title" style="width: 80px;">项目类型：</div>
					<div style="width: calc(100% - 80px);">
						<el-tag type="info" class="tags">{{ projectInfo.typeFy }}</el-tag>
					</div>
				</div>
				<div class="btLines w-m-t-2 m-flex">
				    <div class="tag-title" style="width: 80px;">所含专业：</div>
					<div style="width: calc(100% - 80px);">
						<el-tag type="info" class="tags" v-for="(item, index) in projectInfo.majorFy" :key="index + 'm'">{{ item.name }}</el-tag>
					</div>
				</div>
				<div class="btLines w-m-t-2 m-flex">
				    <div class="tag-title" style="width: 80px;">服务内容：</div>
					<div style="width: calc(100% - 80px);">
						<el-tag type="info" class="tags">{{ projectInfo.serviceContentFy }}</el-tag>
					</div>
				</div>
				<div class="btLines w-m-t-2 m-flex">
				    <div class="tag-title" style="width: 80px;">要求软件：</div>
					<div style="width: calc(100% - 80px);">
						<el-tag type="info" class="tags" v-for="(item, index) in projectInfo.softwareFy" :key="index + 's'">
							{{ item.label }}
						</el-tag>
					</div>
				</div>
				<div class="btLines w-m-t-2">
				  <span class="tag-title">总投资：{{ '￥' + projectInfo.totalInvestment }}</span>
				</div>
				<div class="btLines w-m-t-16">
				    <span class="tag-title">总规模：{{ projectInfo.totalScale }}m²</span>
				</div>
				<div class="btLines">
				    <span class="tag-title">总占地面积：{{ projectInfo.totalArea }}m²</span>
				</div>
				<div class="left-title">项目描述</div>
				<div class="miaoshu">
				    {{ projectInfo.remark || '无' }}
				</div>
			</div>
			
			<div class="lines-title">
				<span>附件资料</span>
				<div class="hengxian"></div>
			</div>
			<div class="left-box-2" v-if="projectInfo.files.length > 0">
				<div class="left-list mdx-flex mdx-row-around mdx-flex-wrap">
					<div class="item" v-for="(item,index) in projectInfo.files" :key='item.id'>
						<img src="../../../static/images/project/project@2x.png" />
						<div class="item-title line1">
						  {{ item.name }}
						</div>
						<el-button type="primary" class="downFile" size="mini" @click="viewCad(item)" v-if="item.name.includes('dwg')">查看</el-button>
						<el-button type="primary" plain class="downFile w-m-t-8" size="mini" :loading="item.downloadStatus" @click="downloadFile(item, index, 1)">下载资料</el-button>
					</div>
					<template v-if="projectInfo.files % 5 != 0">
					  <div v-for="(item, index) in (5 - (projectInfo.files.length % 5))" :key="index + 'o'"></div>
					</template>
				</div>
			</div>
			
			<div class="lines-title" v-if="projectInfo.supplyFiles.length > 0">
				<span>补充资料</span>
				<div class="hengxian"></div>
			</div>
			<div class="left-box-2" v-if="projectInfo.supplyFiles.length > 0">
				<div class="left-list mdx-flex mdx-row-around mdx-flex-wrap">
					<div class="item" v-for="(item,index) in projectInfo.supplyFiles" :key='item.id' >
						<img src="../../../static/images/project/project@2x.png" />
						<div class="item-title line1">
						  {{ item.name }}
						</div>
						<!-- <el-button type="primary" class="downFile" size="mini" @click="viewCad(item)" v-if="item.name.includes('dwg')">查看</el-button> -->
						<el-button type="primary" plain class="downFile" size="mini" @click="downloadFile(item, index, 2)">下载资料</el-button>
					</div>
					<template v-if="projectInfo.supplyFiles % 5 != 0">
					   <div v-for="(item, index) in (5 - (projectInfo.supplyFiles.length % 5))" :key="index + 'p'"> </div>
					</template>
				</div>
			</div>
			
			<div class="lines-title lines-title3" v-if="projectInfo.status >= 3">
				<span class="span1" :class="bmActive==2 ?'active':''" @click="tabBmChange(2)">报名人员</span>
				<span class="span2" :class="bmActive==1 ?'active':''" @click="tabBmChange(1)">邀请列表</span>
				<div class="hengxian"></div>
			</div>
			<div class="left-box-3" v-if="projectInfo.status >= 3">
				<div class="item-invites m-flex" @click="toDetails(1)" v-for="(item, index) in bmList" :key='index'>
					<div class="left m-flex">
						<WzkAvatar :isGender="true" :gender="item.gender || 1" :imgSrc="item.headImgUrl" waryWidth="70" waryHeight="70" width="70" height="70" borderRadius="35"></WzkAvatar>
						
						<div class="user-invites">
							<div class="invits-top m-flex">
								<span class="username">{{ $mHelper.nameSlice(2, userInfo, item.name, item.name) }}</span>
								<span class="userTag" v-if="item.invitation==0">客户邀请</span>
								
								<el-popover placement="right" width="300" trigger="hover" v-if="item.invitation==1">
								  <div class="" v-if="item.inviteReason!=null">{{item.inviteReason}}</div>
								  <span slot="reference">
								     <span class="userTag">项目经理邀请</span>
								  </span>
								</el-popover>
								
								<el-popover placement="right" width="300" trigger="hover" v-if="item.is_fm==1">
								  <div class="">该工程师在进行中的项目较多，请谨慎选择</div>
								  <span slot="reference">
								     <span class="userTag" style="color: #FF4D4F; border-color: #FF4D4F;">繁忙</span>
								  </span>
								</el-popover>
								<el-rate v-model="item.rateValue" disabled disabled-void-color="#D8D8D8"></el-rate>
							</div>
							<div class="invites-sn">
								<span>工程师编号：{{ item.engineerNo || '' }}</span>
								<!-- <span class="w-m-l-24">工程师电话：{{ item.mobile || '' }}</span> -->
							</div>
							<div class="invites-tags">
								<span>精通{{item.majorFy.length || '0'}}个专业</span>
								<span>精通{{item.softwareFy.length || '0'}}个专业软件</span>
								<span>精通{{item.typeFy.length || '0'}}个项目类型</span>
								<span>擅长{{item.regionFy.length || '0'}}个地区</span>
								<span>{{item.years || '0'}}年工作经验</span>
								<span v-if="item.files!=null">有专业证书</span>
							</div>
							<!-- <div class="invites-fen m-flex">
								<div class="invites_lines">
									<div class="numbers">{{ item.favorable }}</div>
									<div class="text">好评率</div>
								</div>
								<div class="invites_lines">
									<div class="numbers">{{ item.completed || 0 }}</div>
									<div class="text">已完成</div>
								</div>
								<div class="invites_lines">
									<div class="numbers">{{ item.timeliness }}</div>
									<div class="text">及时率</div>
								</div>
								<div class="invites_lines">
									<div class="numbers" v-if="item.excellent">{{item.excellent}}%</div>
									<div class="numbers" v-else>--</div>
									<div class="text">优秀率</div>
								</div>
								<div class="invites_lines">
									<div class="numbers">{{ item.reputation }}</div>
									<div class="text">信誉值</div>
								</div>
							</div> -->
						</div>
					</div>
					<div class="right m-flex" v-if="projectInfo.status==3 && bmActive==1">
						<span style="color: #4077DD;" v-if="item.statusApply==1">待确认</span>
						<span style="color: #10C550;" v-if="item.statusApply==2">已接受</span>
						<span style="color: #FF4D4F;" v-if="item.statusApply==9">已拒绝</span>
					</div>
				</div>
			</div>
			
			<div class="lines-title" v-if="projectInfo.status >= 5">
				<span>项目阶段</span>
				<div class="hengxian"></div>
			</div>
			
			<div class="left-box-4 w-m-t-24" v-if="projectInfo.status==4 && projectInfo.stageCalculate!=null && projectInfo.stageCalculate.examineStatus!=null">
				<div class="jd-item">
					<div class="jd-top">
						<span>{{projectInfo.stageCalculate.createTime}}</span>
						<span>阶段性结款</span>
						<span style="color: #10C550;">已结算</span>
					</div>
					<div class="jd-cont">
						<div class="cont-item">
							<div class="number">1</div>
							<div class="">
								<div class="title">项目经理审核</div>
								<div class="info" v-if="projectInfo.stageCalculate.examineStatus==2">驳回说明：{{projectInfo.stageCalculate.remake || ''}}</div>
								<div class="info">{{projectInfo.stageCalculate.shTime}}</div>
							</div>
						</div>
						<div class="shuxian"></div>
						<div class="cont-item">
							<div class="number" :class="projectInfo.stageCalculate.examineStatus!=1 ? 'number2':''">2</div>
							<div class="">
								<div class="title"  :class="projectInfo.stageCalculate.examineStatus!=1 ? 'backColor':''">平台向工程师结清款项</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			
			
			<div class="lines-title lines-title2" v-if="projectInfo.status>=4 && projectInfo.tcheckAuditList.length">
				<span>质控记录</span>
				<span class="export cursor" @click="exportZhikong">导出质控记录</span>
				<div class="hengxian"></div>
			</div>
			<div class="left-box-5" v-if="projectInfo.status>=4 && projectInfo.tcheckAuditList.length">
				<el-table :data="projectInfo.tcheckAuditList" style="width: 100%; margin-top: 24px;" :header-cell-style="{ background: '#FAFAFA',  'text-align': 'left' }" :cell-style="{ 'text-align': 'left' }">
				  <el-table-column prop="title" label="文件名称" width="220px">
					  <template slot-scope="scope">
					     <span style="color: #3B77FF;">{{scope.row.fileName || '客户审核'}}</span>
					  </template>
				  </el-table-column>
				  <el-table-column prop="fileUploadTime" label="上传时间" width="170px"></el-table-column>
				  <el-table-column prop="checkStatus" label="结果" width="100px">
					  <template slot-scope="scope">
					     <span v-if="scope.row.checkStatus==1" style="color: #10C550;">通过</span>
						 <span v-if="scope.row.checkStatus==2" style="color: #FF4D4F;">驳回</span>
					  </template>
				  </el-table-column>
				  <el-table-column prop="checkRemark" label="驳回说明" width="100px">
					  <template slot-scope="scope">
						 <span v-if="scope.row.checkRemark==null || scope.row.checkRemark==''" style="color: #10C550;">--</span>
						 <el-popover placement="right" width="400" trigger="hover" v-else>
						   <div class="" v-if="isJsonString(scope.row.checkRemark)==true">
							   <div v-for="(item,index) in remarkLook(scope.row.checkRemark)" :key="index" class="w-m-b-12">
								   <div style="font-size: 16px; color: #333;">{{item.name || ''}}：</div>
								   <div v-for="(it,ii) in item.items" :key="ii" class="">
									   <div class="w-m-t-10 ">
										   <span>{{it.title || ''}}</span>
										   <span class="w-m-l-16" style="color: #FF4D4F;">扣除：{{it.score || ''}}</span>
									   </div>
									   <div class="">
										   <span>描述：{{it.note || ''}}</span>
									   </div>
								   </div>
							   </div>
						   </div>
						   <span v-else style="color: #FF4D4F;">{{remarkLook(scope.row.checkRemark)}}</span>
						   <span slot="reference">
						      <span class="tj-title" style="color: #FF4D4F; border-color: #FF4D4F;">查看说明</span>
						   </span>
						 </el-popover>
						 
					  </template>
				  </el-table-column>
				  <el-table-column prop="fileId" label="下载文件" >
					  <template slot-scope="scope">
						 <span v-if="scope.row.fileId!=null" style="color: #3b77ff; cursor: pointer;" @click="downItemOnleyChengguo2(scope.row)">下载</span>
					  </template>
				  </el-table-column>
				  <el-table-column prop="checkTime" label="审核时间" width="170px" />
				</el-table>
			</div>
			<div class="left-box-7" v-if="projectInfo.engineerInfo.id">
				<div class="lines-title">
					<span>留言板</span>
					<div class="hengxian"></div>
				</div>
				<div class="conTBVox" style="margin-top: 30px;">
				   <div class="w-m-t-20">
				     <el-radio v-model="radio" :label="1">所有人可见</el-radio>
				     <el-radio v-model="radio" :label="2">需求方可见</el-radio>
				     <el-radio v-model="radio" :label="3">工程师可见</el-radio>
				   </div>
				   <div class="w-m-t-10">
				     <el-input type="textarea" :rows="8" placeholder="请输入内容" v-model.trim="textarea"></el-input>
				   </div>
				   <div class="w-m-t-10 mdx-flex mdx-row-right">
				     <el-button type="primary" style="padding: 10px 40px;" size="mini" @click="saveMsgTap()">发布</el-button>
				   </div>
				 </div>
				 <div class="conTBVox" style="margin-top: 30px;" v-for="(item, index) in msgList" :key="index">
				   <div class="mdx-flex liuyanList">
				     <WzkAvatar :imgSrc="item.headImgUrl" :borderRadius='50'></WzkAvatar>
				     <div class="w-m-l-10">
							<div>
								{{ $mHelper.nameSlice(item.identity, userInfo, item.nickname, item.name) }} <span v-if="item.identity == 1">(需求方)</span>
								<span v-if="item.identity == 2">(工程师)</span>
								<span v-if="item.identity == 3">(项目经理)</span>
							</div>
							<div class="w-m-t-5 times">{{ item.createTime }}</div>
				     </div>
				   </div>
				   <div class="w-p-l-60 w-m-t-10">{{ item.content }}</div>
				   <div class="blue w-font-14 mdx-text-right w-m-t-10" v-if="userInfo.identity == 3">
				     <span v-if="item.type == 1">所有人可见</span>
				     <span v-if="item.type == 2">客户可见</span>
				     <span v-if="item.type == 3">工程师可见</span>
					 <span v-if="item.type == null">项目经理可见</span>
				   </div>
				 </div>
				 <div class="mdx-flex mdx-row-center " style="margin-top: 30px;" v-if="msgList.length">
				     <el-pagination :pageSize="queryParams.limit" :currentPage='queryParams.page' background layout="prev, pager, next" @current-change='pageChange' :page-count="queryParams.totalPage || 1"></el-pagination>
				 </div>
			</div>
			
		</div>
		<div class="right">
			<div class="right-box-apply" v-if="projectInfo.status==4 && projectInfo.tprojectPostpone!=null && projectInfo.tprojectPostpone.clientType==2 && projectInfo.tprojectPostpone.managerType==2 && projectInfo.tprojectPostpone.isWho==1">
				<div class="apply-title">项目延期申请 </div>
				<div class="apply-info"><span v-if="projectInfo.tprojectPostpone.isWho==0">客户</span><span v-if="projectInfo.tprojectPostpone.isWho==1">工程师</span>申请将项目延期原因如下：</div>
				<div class="apply-cont">{{projectInfo.tprojectPostpone.cause || ''}} </div>
				<div class="apply-memver" style="height: 21px;">
					<!-- <span v-if="projectInfo.tprojectPostpone.isWho==0"> </span>
					<span style="color: #10C550;" v-else-if="projectInfo.tprojectPostpone.isWho==1 && projectInfo.tprojectPostpone.clientType==0">客户已同意</span>
					<span v-else-if="projectInfo.tprojectPostpone.isWho==1 && projectInfo.tprojectPostpone.clientType==1">客户不同意</span>
					<span style="color: #3B77FF;" v-else-if="projectInfo.tprojectPostpone.isWho==1 && projectInfo.tprojectPostpone.clientType==2">客户待审核</span> -->
				</div>
				<div class="apply-btn cursor" @click="timeShowApply=true">立即处理</div>
			</div>
			
			<div class="right-box-apply" v-if="projectInfo.status==4 && projectInfo.tprojectPostpone!=null && projectInfo.tprojectPostpone.managerType==2 && projectInfo.tprojectPostpone.isWho==0">
				<div class="apply-title">项目延期申请 </div>
				<div class="apply-info"><span v-if="projectInfo.tprojectPostpone.isWho==0">客户</span><span v-if="projectInfo.tprojectPostpone.isWho==1">工程师</span>申请将项目延期原因如下：</div>
				<div class="apply-cont">{{projectInfo.tprojectPostpone.cause || ''}} </div>
				<div class="apply-memver" style="height: 21px;">
					<!-- <span v-if="projectInfo.tprojectPostpone.isWho==0"> </span>
					<span style="color: #10C550;" v-else-if="projectInfo.tprojectPostpone.isWho==1 && projectInfo.tprojectPostpone.clientType==0">客户已同意</span>
					<span v-else-if="projectInfo.tprojectPostpone.isWho==1 && projectInfo.tprojectPostpone.clientType==1">客户不同意</span>
					<span style="color: #3B77FF;" v-else-if="projectInfo.tprojectPostpone.isWho==1 && projectInfo.tprojectPostpone.clientType==2">客户待审核</span> -->
				</div>
				<div class="apply-btn cursor" @click="timeShowApply=true">立即处理</div>
			</div>
			
			<div class="right-box-guo" v-if="projectInfo.engineerInfo.engineerFiles.length">
				<div class="top-guo m-flex">
					<div class="name">验收成果</div>
					<div class="wzk-cursor jieguoBtn" @click="cgShow = true"> 查看所有</div>
				</div>
				<div class="times">
				    上传时间：{{ getLastFileTime(1) }}
				</div>
			    <img src="../../../static/images/project/default@2x.png">
				<div class="title">验收成果</div>
				<!-- <el-button type="primary" plain class="downFile" size="mini" :loading="loadEngiFruit" @click="downloadEngineerFruit">下载资料</el-button> -->
			</div>
			
			<div class="right-box-1" >
				<div class="right-title">发布者</div>
				<div class="right-user">
					<div class="block">
						<el-image class='image-slot' :src="projectInfo.customerInfo.headImgUrl">
							<div slot="error" class="image-slot">
								<img src="../../../static/images/mrtx.png" style="width: 80px;height: 80px;border-radius: 5px;">
							</div>
						</el-image>
					</div>
					<div class="right-uinfo">
						<div class="name">{{ $mHelper.nameSlice(1, userInfo, projectInfo.customerInfo.nickname, projectInfo.customerInfo.name)}}</div>
						<div class="rStatis right-wrz" v-if="projectInfo.customerInfo.certification==2 || projectInfo.customerInfo.certification==null">未认证</div>
						<div class="rStatis right-grrz" v-else-if="projectInfo.customerInfo.certification==1">个人认证</div>
						<div class="rStatis right-qyrz" v-else>企业认证</div>
					</div>
				</div>
				<div class="right-phone">电话： {{ projectInfo.customerInfo.mobile || '' }}</div>
				<div class="right-phone" v-if="projectInfo.customerInfo.qq">QQ： {{ projectInfo.customerInfo.qq || '' }}</div>
				<div class="right-phone" v-if="projectInfo.customerInfo.mailbox">邮箱： {{ projectInfo.customerInfo.mailbox || '' }}</div>
				<div class="right-time">发布时间：{{projectInfo.createTime}}</div>
			</div>
			<div class="right-box-1 right-box-2" v-if="projectInfo.memberInfo !=null && projectInfo.memberInfo.id !=null">
				<div class="right-title">项目经理</div>
				<div class="right-user">
					<div class="block">
						<el-image class='image-slot' :src="projectInfo.memberInfo.headImgUrl">
							<div slot="error" class="image-slot">
								<img src="../../../static/images/mrtx.png" style="width: 80px;height: 80px;border-radius: 5px;">
							</div>
						</el-image>
					</div>
					<div class="right-uinfo">
						<div class="name">{{ $mHelper.nameSlice(3, userInfo, projectInfo.memberInfo.nickname,projectInfo.memberInfo.name)}}</div>
					</div>
				</div>
				<div class="right-phone">电话： {{ projectInfo.memberInfo.mobile || '' }}</div>
				<div class="right-phone" v-if="projectInfo.memberInfo.qq">QQ： {{ projectInfo.memberInfo.qq || '' }}</div>
				<div class="right-phone" v-if="projectInfo.memberInfo.mailbox">邮箱： {{ projectInfo.memberInfo.mailbox || '' }}</div>
			</div>
			<div class="right-box-1 right-box-2"   v-if="projectInfo.engineerInfo !=null && projectInfo.engineerInfo.id !=null">
				<div class="right-title">工程师</div>
				<div class="right-user">
					<div class="block">
						<el-image class='image-slot' :src="projectInfo.engineerInfo.headImgUrl">
							<div slot="error" class="image-slot">
								<img src="../../../static/images/mrtx.png" style="width: 80px;height: 80px;border-radius: 5px;">
							</div>
						</el-image>
					</div>
					<div class="right-uinfo">
						<div class="name">{{ $mHelper.nameSlice(2, userInfo, projectInfo.engineerInfo.nickname, projectInfo.engineerInfo.name) }}</div>
					</div>
				</div>
				
				<div class="right-phone" v-if="projectInfo.depositAmount">质保金： <span style="color: #10C550;">已缴纳（{{ projectInfo.depositAmount || '0.00' }}元）</span></div>
				<div class="right-phone">电话： {{ projectInfo.engineerInfo.mobile || '' }}</div>
				<div class="right-phone" v-if="projectInfo.engineerInfo.qq">QQ： {{ projectInfo.engineerInfo.qq || '' }}</div>
				<div class="right-phone" v-if="projectInfo.engineerInfo.mailbox">邮箱： {{ projectInfo.engineerInfo.mailbox || '' }}</div>
			</div>
			
		</div>	
	</div>
   
	
   
	
	
	<!--成果-->
    <el-dialog class="tipPopup" title="历史成果" :visible.sync="cgShow" width="40%" center top='20vh'>
      <div class="puopContext">
        <div v-for="(item, index) in projectInfo.engineerInfo.engineerFiles" :key='index'>
			<div class="mdx-flex mdx-col-center">
				<div class="w-font-16">
				  {{ item.name }}
				</div>
				<div class="w-m-l-24 w-font-14">
				  {{ item.createDate }}
				</div>
			</div>
			 <div class="mdx-flex mdx-row-between  w-m-t-10">
				<div class="mdx-text-center">
				   <img src="../../../static/images/project/default@2x.png">
				</div>
				<div class="" style="text-align: center;">
				  <el-button type="primary" plain class="downFile" size="mini" @click="downItemOnleyChengguo(item)">下载资料 </el-button>
				</div>
			</div>
        </div>
      </div>
    </el-dialog>
	<!--上传成果-->
	<el-dialog  class="tipPopup" title="上传成果" :visible.sync="resultsShow" show-cancel-button :before-close="handleClose" width="40%" center top="20vh">
	  <div class="puopContext w-font-16">
	    <div>
	      项目名称：
	      {{ projectInfo.regionFy }}/{{ projectInfo.typeFy }}
	      <!-- {{projectInfo.majorFy}} -->
	      <template v-if="projectInfo.majorFy.length">
	        <span v-for="(row, indexss) in projectInfo.majorFy" :key="indexss">/{{ row.name }}</span>
	      </template>
	      <span> /总投资:￥{{ projectInfo.totalInvestment || "" }} </span>
	      <span> /总规模:{{ projectInfo.totalScale }}m² </span>
	      /{{ projectInfo.serviceContentFy }}
	    </div>
	    <div class="w-m-t-10">
	      <el-upload class="upload-demo" ref="files" :action="action" :on-success="upFruitfileSuccess" :on-remove="handleFruitRemove" multiple :limit="9" :on-exceed="handleExceed" :file-list="upFruitfileList">
	        <el-button size="small" type="primary">上传文件</el-button>
	      </el-upload>
	    </div>
	    <div class="mdx-flex mdx-row-center w-m-t-30">
	      <el-button type="primary" :loading="loading" style="padding: 10px 40px; border-radius: 2px" class="" @click.stop="suppleUpdata(2)">
	        提交
	      </el-button>
	    </div>
	  </div>
	</el-dialog>
	
	<!--邀请工程师-->
    <el-dialog class="tipPopup" title="邀请工程师申请此项目"  :visible.sync="inviteShow" show-cancel-button :before-close='handleInvitesClose' width="40%" center top='20vh'>
      <div class="puopContext  w-m-t-30  w-font-16">
        <div class="mdx-flex">
          <div class="">工程师编号</div>
          <div class="wzk-flex-1 w-m-l-20">
            <el-input style="margin-top: 0;" type="text" placeholder="请输入工程师编号" v-model.trim="engineerNo"></el-input>
          </div>
        </div>
		<div class="mdx-flex w-m-t-16">
		  <div class="">推荐的理由</div>
		  <div class="wzk-flex-1 w-m-l-20">
		    <el-input v-model="addInvitesNotes" placeholder="请输入推荐理由" type="textarea" maxlength="255" :autosize="{ minRows: 6, maxRows: 8 }" class="input"></el-input>
		  </div>
		</div>
		<div class="dialog-bom mdx-flex mdx-row-center w-m-t-46">
			<el-button class="close" size="small" @click="inviteShow = false">取 消</el-button>
		    <el-button type="primary" size="small" :loading="loading" @click.stop="inviteTap()">邀请</el-button>
		</div>
      </div>
    </el-dialog>
	
	<!--延期审核-->
	<el-dialog class="tipPopup" title="延期审核" :visible.sync="timeShowApply" width="30%" center top='20vh'>
	  <div class="puopContext">
		  <el-form ref="form" :model="formYanApplyData" label-width="80px">
			   <el-form-item label="延长截止:" prop="postponeTime">
				 <div class="inputRight">
					 {{formYanApplyData.postponeTime || ''}}
				   <!-- <el-date-picker v-model="formYanApplyData.postponeTime" disabled :picker-options="pickerOptions" type="datetime" value-format="yyyy-MM-dd HH:mm:ss" format="yyyy-MM-dd HH:mm:ss" placeholder="选择日期时间" @change="estimateMoney()"></el-date-picker> -->
				 </div>
			   </el-form-item>
			   <el-form-item label="审核结果:">
				 <div class="mdx-flex">
					 <div>
						 <el-radio v-model="formYanApplyData.type" :label="1">通过</el-radio>
						 <el-radio v-model="formYanApplyData.type" :label="2">不通过</el-radio>
					 </div>
				 </div>
			   </el-form-item>
			   <el-form-item label="审核说明:" prop="cause" v-if="formYanApplyData.type==2">
					<el-input v-model="formYanApplyData.managerCase" placeholder="请写明不通过的原因" type="textarea" maxlength="255" :autosize="{ minRows: 4, maxRows: 6 }" class="input"></el-input>
			   </el-form-item>
		  </el-form>
		  <span slot="footer" class="dialog-bom mdx-flex mdx-row-center w-m-t-50">
			<el-button class="close" size="small" @click.stop="timeShowApply=false">取 消</el-button>
		    <el-button class="w-m-l-24" type="primary" size="small" :loading="loading" @click.stop="submitYanApplyEvaluate">确 定</el-button>
		  </span>
		  
	     
	  </div>
	</el-dialog>
	
	<!--项目进展-->
	<el-dialog class="tipPopup" title="项目进展" :visible.sync="openJinduShow" width="30%" center top='20vh'>
	  <div class="">
		  <div class="project-jz" v-if="projectInfo.progressList.length">
		    <div class="wzk-flex-1">
		      <div class="jz-list">
		        <div class="item" v-for="(item, index) in projectInfo.progressList" :key='index'>
		  		  <div class="title">
		  			    <span>{{ item.title }}</span>
		  				<el-tooltip :content="item.content" placement="top" effect="light">
		  				  <span style="color: #185BA1;cursor: pointer; margin-left: 16px;" v-if="item.content">详情</span>
		  				</el-tooltip>
		  		  </div>
		  		  <div class="times">
		  			  {{ item.createTime }}
		  		  </div>
		        </div>
		      </div>
		    </div>
		  </div>
	  </div>
	</el-dialog>
	
	<!-- 项目评价 -->
	<el-dialog title="项目评价" class="pingCss" :visible.sync="dialogVisible" width="50%" left :modal="false" :before-close="canclePing" :close-on-click-modal="false">
	  <el-form ref="form" :model="form" label-width="80px">
	    <div v-for="(item, index) in problemList" :key="item.id">
	      <el-form-item>
	       <div class="m-flex mdx-col-center">
			   <div class="ping-info">{{ item.item }}：</div>
			   <el-rate :value="item.isCheck" @change="rateChange($event, item, index)" :max="item.num" :allow-half="true"></el-rate>
		   </div>
	        <el-input v-model="item.note" placeholder="请写明具体存在的问题" type="textarea" maxlength="255" :autosize="{ minRows: 4, maxRows: 6 }" class="input"></el-input>
	      </el-form-item>
	    </div>
	  </el-form>
	  <span slot="footer" class="dialog-footer">
	    <el-button type="primary" size="small" @click.stop="submitpingEvaluate">提交评价</el-button>
	    <el-button size="small" @click.stop="canclePing" style="width: 80px">取 消</el-button>
	  </span>
	</el-dialog>
	<!-- 评价成功 -->
	<el-dialog class="tipPopup" title="提示" :visible.sync="evaluateSucces" :modal="false" width="30%" top="40vh" center :show-close="false" @click.stop.native="evaluateSucces = true" :close-on-click-modal="false">
	  <div style="text-align: center; color: #333333ff; font-size: 16px; margin-top: 20px;">
	    您已评价成功
	  </div>
	  <span slot="footer" class="dialog-footer">
	    <el-button plain size="small" @click.stop="successEva">我知道了</el-button>
	  </span>
	</el-dialog>
	<!--投诉详情-->
	<el-dialog title="投诉详情" :visible.sync="userTsShow"  width="30%" center top='20vh' class="tipPopup ts-dbox" v-if="projectInfo.tprojectComplainList!=null">
	  <div class="">
		   <div class="ts-title">客户于{{projectInfo.tprojectComplainList.createTime || ''}}进行投诉</div>
		   <div class="ts-info">投诉原因</div>
		   <div class="ts-content">
			   <span v-if="projectInfo.tprojectComplainList.reason==1">工作成果质量存在问题</span>
			   <span v-if="projectInfo.tprojectComplainList.reason==2">项目交付时间延误</span>
			   <span v-if="projectInfo.tprojectComplainList.reason==3">造价师服务态度不好</span>
			   <span v-if="projectInfo.tprojectComplainList.reason==4">造价师个人经验及信息与实际不符</span>
			   <span v-if="projectInfo.tprojectComplainList.reason==5">其他问题</span>
		   </div>
		   <div class="ts-info">投诉说明</div>
		   <div class="ts-content">{{projectInfo.tprojectComplainList.remark || ''}}</div>
	  </div>
	</el-dialog>
	
	<!--阶段结算审核-->
	<el-dialog class="tipPopup" title="阶段性结款" :visible.sync="jdShowApply" width="30%" center top='20vh'>
	  <div class="">
		  <el-form ref="form" label-width="80px">
			   <el-form-item label="审核结果:">
				 <div class="mdx-flex">
					 <div>
						 <el-radio v-model="jdStatus" :label="1">通过</el-radio>
						 <el-radio v-model="jdStatus" :label="2">不通过</el-radio>
					 </div>
				 </div>
			   </el-form-item>
			   <el-form-item label="审核说明:" prop="jdNotes">
					<el-input v-model="jdNotes" placeholder="如若不通过，请在此说明理由" type="textarea" maxlength="255" :autosize="{ minRows: 4, maxRows: 6 }" class="input"></el-input>
			   </el-form-item>
		  </el-form>
		  <span slot="footer" class="dialog-bom mdx-flex mdx-row-center w-m-t-50">
			<el-button class="close" size="small" @click.stop="jdShowApply=false">取 消</el-button>
		    <el-button class="w-m-l-24" type="primary" size="small" @click.stop="submitJieduanEvaluate">确 定</el-button>
		  </span>
	  </div>
	</el-dialog>
	<!--客户取消审核-->
	<el-dialog title="客户取消" :visible.sync="cancalShowApply"  width="40%" center top='10vh' class="tipPopup look-box">
	  <div class="">
		   <el-form ref="form" label-width="88px">
			  <div class="title w-m-t-24" style="color: rgba(0,0,0,0.85);">客户已申请取消项目，请根据工程师作业情况进行费用扣减</div>
			  <div class="title w-m-t-12" style="color: #FF4D4F;"> 项目交付周期：{{zdMyInfo.jfTime || ''}}；订单价格：{{zdMyInfo.platformFee || '0'}}元</div>
			  <div class="title w-m-t-0" style="color: #FF4D4F;">工程师作业天数：{{zdMyInfo.gcsDays || '0'}}天</div>
			  <div class="title w-m-t-0" style="color: #FF4D4F;" v-if="zdMyInfo.checkStatus==6">工程师已上传项目成果文件，且已完成项目经理质控</div>
			  
			  <el-form-item label="收取服务费:" class="w-m-t-8">
				<div class="mdx-flex">
				  <div>
					<el-radio v-model="khRstatus" :label="1">固定比例</el-radio>
					<el-radio v-model="khRstatus" :label="2">固定金额</el-radio>
				  </div>
				  <div class="mdx-flex w-m-l-20">
					<el-input type="text" onkeyup="value=value.replace(/[^\d]/g,'')" v-model="khNumber" placeholder="请输入" :maxlength="6"></el-input>
					<span class="dabwue" v-if="khRstatus==1">%</span>
					<span class="dabwue" v-if="khRstatus==2">元</span>
				  </div>
				  <el-button class="close w-m-l-16" type="primary" size="small" @click.stop="getMoneyInfo">更 正</el-button>
				</div>
			  </el-form-item>
			  
			  <div class="look-title">以下为客户实际结算服务费账单：</div>
			  <div class="look-bbx">
				 <!-- <div class="m-flex color1">
					  <div class="left">初始服务费:</div>
					  <div class="right">{{zdMyInfo.platformFee || '0'}}元</div>
				  </div> -->
				  <div class="m-flex color1" v-for="(item,index) in zdMyInfo.serviceAccountList" :key="index">
					  <div class="left">{{item.takeReason}}:
						<!-- <el-dropdown>
							<el-button size="small" class="more-btn w-m-r-10" type="text" @click.stop="">
								<img src="../../../static/images/center/wait@2x.png" />
							</el-button>
							<el-dropdown-menu slot="dropdown"> 
								<el-dropdown-item>{{item.takeReason || ''}}，{{item.takeAmount || '0.00'}}元</el-dropdown-item>
							</el-dropdown-menu>
						 </el-dropdown> -->
					  </div>
					  <div class="right">{{item.takeAmount || '0.00'}}元</div>
				  </div>
				  <div class="lines-next"></div>
				  <div class="m-flex color3">
					  <div class="left">服务费总额:</div>
					  <div class="right">{{zdMyInfo.totalService || '0.00'}}元</div>
				  </div>
				  <div class="m-flex color2">
					  <div class="left">已结算服务费:</div>
					  <div class="right">{{zdMyInfo.serviceMoney || '0.00'}}元</div>
				  </div>
				  <div class="m-flex color2">
					  <div class="left">待结算:</div>
					  <div class="right">{{zdMyInfo.service || '0.00'}}元</div>
				  </div>
				 
			  </div>
		   </el-form>
		   <div class="check mdx-flex mdx-col-center w-m-t-32">
		     <el-checkbox-group v-model="checkRadiobox">
		        <el-checkbox label=""></el-checkbox>
		     </el-checkbox-group>
		     <div class="w-m-l-8">
		       我已与客户进行协商，并取得客户授权进行项目取消
		     </div>
		   </div>
		  <span slot="footer" class="dialog-bom mdx-flex mdx-row-center w-m-t-50">
			<el-button class="close" size="small" @click.stop="cancalShowApply=false">取 消</el-button>
		    <el-button class="w-m-l-24" type="primary" size="small" :loading="loading" @click.stop="cancalquxiao(1)">确 定</el-button>
		  </span>
	  </div>
	</el-dialog>
	
	<!--代工程师取消-->
	<el-dialog title="工程师取消" :visible.sync="cancalgcsCloseShow"  width="40%" center top='20vh' class="tipPopup look-box">
	  <div class="">
		   <el-form ref="form" >
			  <div class="title w-m-t-12" style="color: rgba(0,0,0,0.85);">您正在代替造价师进行取消申请。一旦取消，该项目进入【已完成-已取消】列表。平台将扣除全部造价师收益，扣除全部质保金；服务费全额退还客户。</div>
			  <el-form-item label="是否不可抗力:" class="w-m-t-10">
				 <div class="mdx-flex">
					 <div>
						 <el-radio v-model="cannot" :label="1">是</el-radio>
						 <el-radio v-model="cannot" :label="2">不是</el-radio>
					 </div>
				 </div>
			  </el-form-item>
			  <div class="check mdx-flex mdx-col-center w-m-t-32">
			    <el-checkbox-group v-model="checkRadiobox2">
			       <el-checkbox label=""></el-checkbox>
			    </el-checkbox-group>
			    <div class="w-m-l-8">
			      我已与造价师进行协商，并取得造价师授权进行项目取消
			    </div>
			  </div>
		   </el-form>
		  <span slot="footer" class="dialog-bom mdx-flex mdx-row-center w-m-t-50">
			<el-button class="close" size="small" @click.stop="cancalgcsCloseShow=false">取 消</el-button>
		    <el-button class="w-m-l-24" type="primary" size="small" :loading="loading"  @click.stop="cancalquxiao(2)">确 定</el-button>
		  </span>
	  </div>
	</el-dialog>
	<!--平台取消-->
	<el-dialog title="平台取消" :visible.sync="cancalpingCloseShow"  width="40%" center top='20vh' class="tipPopup look-box">
	  <div class="">
		   <el-form label-position="right" label-width="98px" ref="form">
			  <div class="title w-m-t-12" style="color: rgba(0,0,0,0.85);">由于平台原因我司将取消该项目。我司已于客户及工程师进行沟通，并于双方达成一致。全额退还客户服务费；退还工程师全部质保金，并承诺工程师承接项目时减免一定额度的管理费用。</div>
			  
			  <el-form-item label="减免管理费：" class="mingHeind w-m-t-16" prop="phone">
				 <el-input v-model="ptNumber" :maxlength="10" oninput="value=((value.replace(/[^0-9.]/g,'')).replace(/([0-9]+\.[0-9]{2})[0-9]*/,'$1'))" placeholder="请输入减免管理费金额"></el-input>
			  </el-form-item>
		   </el-form>
		  <span slot="footer" class="dialog-bom mdx-flex mdx-row-center w-m-t-50">
			<el-button class="close" size="small" @click.stop="cancalpingCloseShow=false">取 消</el-button>
		    <el-button class="w-m-l-24" type="primary" size="small" :loading="loading"  @click.stop="cancalquxiao(4)">确 定</el-button>
		  </span>
	  </div>
	</el-dialog>


	<!--工程师取消审核-->
	<el-dialog title="工程师取消" :visible.sync="cancalCloseShowApply"  width="40%" center top='20vh' class="tipPopup look-box">
	  <div class="">
		   <el-form ref="form" :model="formCancalData">
			  <div class="title w-m-t-12" style="color: rgba(0,0,0,0.85);">工程师已申请取消项目，请与工程师联系沟通核实情况。一旦通过审核，该项目将被取消，进入【已完成-已取消】列表。平台将扣除全部造价师收益，扣除全部质保金；服务费全额退还客户；</div>
			  <div class="look-title w-m-t-8 w-m-b-8>">若要驳回，请在下方写明驳回理由！</div>
			  <el-form-item label="不可抗力:">
				 <div class="mdx-flex">
					 <div>
						 <el-radio v-model="cacalStatus" :label="1">是</el-radio>
						 <el-radio v-model="cacalStatus" :label="2">不是</el-radio>
					 </div>
				 </div>
			  </el-form-item>
			  <el-form-item label="" prop="notes">
					<el-input v-model="cacalNotes" placeholder="请详细说明驳回理由" type="textarea" maxlength="255" :autosize="{ minRows: 6, maxRows: 6 }" class="input"></el-input>
			  </el-form-item>
		   </el-form>
		  <span slot="footer" class="dialog-bom mdx-flex mdx-row-center w-m-t-50">
			<el-button class="close" size="small" @click.stop="applyCacel(3)">驳 回</el-button>
		    <el-button class="w-m-l-24" type="primary" size="small" @click.stop="applyCacel(2)">通过</el-button>
		  </span>
	  </div>
	</el-dialog>

	<el-dialog  class="tipPopup" title="成果审核" :visible.sync="materialsShow" width="40%" center top="20vh">
	  <div class="puopContext w-font-16">
	    <div class="mdx-flex mdx-row-center w-m-t-50 w-m-b-50">
	      <el-button type="primary" style="padding: 10px 30px; border-radius: 2px" :loading="loadFruit" @click="downloadEngineerFruit">
	        <!-- @click='downWenjian()' -->
	        下载成果文件
	      </el-button>
	    </div>
	  </div>
	  <div class="mdx-flex mdx-row-right">
	    <el-button type="text" size="small" @click="toExamine">审核驳回</el-button>
	    <!-- :disabled="checkStatus" -->
	    <el-button type="primary" size="small" @click.stop="through">审核通过</el-button>
	  </div>
	</el-dialog>
	
	<!-- 审核驳回 -->
	<el-dialog class="tipPopup" title="审核驳回" :visible.sync="rejectShow" show-cancel-button :before-close="handleClose" width="50%" center top="20vh">
	  <div class="puopContext w-font-16">
	    <el-form :model="ruleForm" label-width="180px" ref="ruleForm">
	      <div v-for="(item, index) in listdata" :key="index + 'w'">
	        <el-form-item :label="item.item.item" prop="principle">
	          <el-select v-model="item.isCheck" placeholder="请选择" @change="selectValue(item.isCheck)">
	            <el-option label="是" value="1"></el-option>
	            <el-option label="否" value="2"></el-option>
	          </el-select>
	        </el-form-item>
	        <el-form-item label="存在的问题" v-if="item.isCheck == 1 && item.subItem.length">
	          <!-- <el-checkbox-group
	            v-if="item.isCheck == 1"
	            v-model="ruleForm.selectedData"
	            style="
	              display: flex;
	              flex-flow: column nowrap;
	              align-items: flex-start;
	            "
	          > -->
	          <div v-for="item in item.subItem" :key="item.id">
	            <el-checkbox :label="item.id" @change="datesChange(item)" :value="item.ischecked">{{ item.item }}({{ item.itemDes }})</el-checkbox>
	            <el-input type="textarea" placeholder="请写明具体存在的问题" maxlength="255" v-if="item.ischecked" v-model="item.content" @input="changeValue(item)"></el-input>
	          </div>
	          <!-- </el-checkbox-group> -->
	        </el-form-item>
	      </div>
	    </el-form>
	    <div class="mdx-flex mdx-row-right w-m-t-30">
	      <el-button
	        type="primary"
	        style="padding: 10px 40px; border-radius: 2px"
	        :disabled="rejected"
	        @click.stop="rejectedObject()"
	      >
	        确认驳回
	      </el-button>
	    </div>
	  </div>
	</el-dialog>
	
	
	<!--驳回-->
	<!-- <el-dialog class="tipPopup" title="阶段性结款" :visible.sync="jdBackShowApply" width="30%" center top='20vh'>
	  <div class="">
		  <div style="font-family: PingFangSC, PingFang SC; font-weight: 500; font-size: 18px; color: rgba(0,0,0,0.85); line-height: 39px;">
			  如要驳回项目，请在下方简要说明原因
		  </div>
		  <el-form ref="form" :model="formApplyData" class="w-m-t-24">
			   <el-form-item label="" prop="notes">
					<el-input v-model="formApplyData.notes" placeholder="请输入驳回原因" type="textarea" :autosize="{ minRows: 4, maxRows: 6 }" class="input"></el-input>
			   </el-form-item>
		  </el-form>
		  <span slot="footer" class="dialog-bom mdx-flex mdx-row-center w-m-t-50">
			<el-button class="close" size="small" @click.stop="cancle">取 消</el-button>
		    <el-button class="w-m-l-24" type="primary" size="small" @click.stop="submitEvaluate">确 定</el-button>
		  </span>
	  </div>
	</el-dialog> -->
	
	<!--邀请弹框-->
	<el-dialog class="" title="邀请工程师" :visible.sync="invitesBoxShow" width="30%" center top="20vh">
		<div style="border: 1px solid #4077DD;width: 70%; height: 60px; cursor: pointer; border-radius: 4px; font-family: PingFangSC, PingFang SC;font-weight: 400; font-size: 16px; color: #4077dd; display: flex; align-items: center; justify-content: center; margin: 20px auto;"  @click="goToInvites()">
			 到人才库邀请
		</div>
		<div class="dialog-bom mdx-flex mdx-row-center w-m-t-35">
			<el-button size="small" class="close" style="width: 120px; height: 40px;" @click="invitation()">通过工程师ID邀</el-button>
			<el-button size="small" class="close" style="width: 120px; height: 40px; margin-left: 30px;" @click="openInviresList()">查看已邀请</el-button>
		</div>
	</el-dialog>
	
	<!--邀请列表-->
	<el-dialog class="tipPopup" title="已邀请列表" :visible.sync="invitesListShow" show-cancel-button width="750px" center top="20vh">
	    <div class="left-box-invites">
	    	<div class="item-invites m-flex" v-for="(item, index) in bmInvitesList" :key='index'>
	    		<div class="left m-flex">
	    			<WzkAvatar :isGender="true" :gender="item.gender || 1" :imgSrc="item.headImgUrl" :waryWidth="70" :waryHeight="70" :width="70" :height="70" :borderRadius="35"></WzkAvatar>
	    			<div class="user-invites">
	    				<div class="invits-top m-flex">
	    					<span class="username">{{ $mHelper.nameSlice(2, userInfo, item.name, item.name) }}</span>
	    					<span class="userTag" v-if="item.invitation==0">客户邀请</span>
	    					<el-popover placement="right" width="300" trigger="hover" v-if="item.invitation==1">
	    					  <div class="" v-if="item.inviteReason!=null">{{item.inviteReason}}</div>
	    					  <span slot="reference">
	    					     <span class="userTag">项目经理邀请</span>
	    					  </span>
	    					</el-popover>
	    					<el-popover placement="right" width="300" trigger="hover" v-if="item.is_fm==1">
	    					  <div class="">该工程师在进行中的项目较多，请谨慎选择</div>
	    					  <span slot="reference">
	    					     <span class="userTag" style="color: #FF4D4F; border-color: #FF4D4F;">繁忙</span>
	    					  </span>
	    					</el-popover>
	    					<el-rate v-model="item.rateValue" disabled disabled-void-color="#D8D8D8"></el-rate>
	    				</div>
	    				<div class="invites-sn">
	    					<span>工程师编号：{{ item.engineerNo || '' }}</span>
	    					<!-- <span class="w-m-l-24">工程师电话：{{ item.mobile || '' }}</span> -->
	    				</div>
	    				<div class="invites-tags">
	    					<span>精通{{item.majorFy.length || '0'}}个专业</span>
	    					<span>精通{{item.softwareFy.length || '0'}}个专业软件</span>
	    					<span>精通{{item.typeFy.length || '0'}}个项目类型</span>
	    					<span>擅长{{item.regionFy.length || '0'}}个地区</span>
	    					<span>{{item.years || '0'}}年工作经验</span>
	    					<span v-if="item.files!=null">有专业证书</span>
	    				</div>
	    			</div>
	    		</div>
	    		<div class="right m-flex" >
	    			<span style="color: #4077DD;" v-if="item.statusApply==1">待确认</span>
	    			<span style="color: #10C550;" v-if="item.statusApply==2">已接受</span>
	    			<span style="color: #FF4D4F;" v-if="item.statusApply==9">已拒绝</span>
	    		</div>
	    	</div>
	    </div>
	</el-dialog>
	
    <el-dialog
      v-if="cadShow"
      :visible.sync="cadShow"
      width="80%"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :before-close="() => cadShow = false">
      <div id='map-container' v-loading="cadLoading"
        element-loading-text="拼命加载中"
        element-loading-spinner="el-icon-loading"
        element-loading-background="rgba(255, 255, 255, 0.8)">
      </div>
      <!-- <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="cadShow = false">确 定</el-button>
      </span> -->
    </el-dialog>
  </div>
</template>

<script>
import WzkAvatar from '../../../components/wzk-Avatar/index.vue'
import { initNumberValue } from "@/common/utils/num-validate.js"
import vjmap from 'vjmap'; 
import 'vjmap/dist/vjmap.min.css' // 样式一定要引用！！！
export default {
  components: {
    WzkAvatar
  },
  data() {
    return {
		pickerOptions: {
		  disabledDate(v) {
		    return v.getTime() < new Date().getTime() - 86400000;
		  }
		}, //控制时间显示
      inviteShow: false,
      engineerNo: '',
      cgShow: false,
      radio: 1,
      imgSrc: 'https://img2.baidu.com/it/u=1953922700,4041156140&fm=26&fmt=auto',
      titleShow: false,
      puopShow: false,
      textarea: '',
      Id: null,
      projectInfo: {
        totalInvestment: '',
        majorFyOne: [],
        majorFy: [],
        files: [],
        supplyFiles: [],
        progressList: [],
        projectFiles: [],
        customerInfo: {},
        engineerInfo: {
          engineerFiles: [],
        },
      },
      bmList: [],
	  bmActive: 2,
      msgList: [],
      queryParams: {
        page: 1, //当前页数
        limit: 10, //每页条数
        totalPage: 1
      },
      loading: false,
      loadFruit: false,
      loadEngiFruit: false,
      cadShow:false,
      cadLoading:false,
	  value: 4,
	  timeShowApply: false,  //延期审核
	  formApplyData: {
	    times: '',
		status: 2,
		notes:'',
	  },
	  cancalShowApply: false, //客户取消审核
	  formCancalData: {
	  		status: 2,
	  		number:'',
	  },
	  openJinduShow: false, //查看项目进度
	  cancalCloseShowApply: false, //工程师取消审核
	  cancalgcsCloseShow: false,	//代工程师取消
	  cancalpingCloseShow: false,	//平台取消
	  userTsShow: false,	//投诉弹框
	  jdShowApply: false,	//阶段结算审核
	  jdBackShowApply: false,	//阶段结算驳回
	  
	  formYanApplyData: {
	  	postponeTime: '',
	  	managerCase:'',
		type:'',
	  },
	  resultsShow: false, //成果
	  action: this.$store.state.baseURL + 'upload/uploadCos',
	  fileList: [],
	  upFruitfileList: [],
	  // 成果文件
	  files: { 
	    name: '',
	    url: '',
	  },
	  form: {
	    // 评价内容
	    name: "",
	    name1: "",
	    name2: "",
	  },
	  scoring: {
	    // 分数
	    num: 0,
	    num1: 0,
	    num2: 0,
	  },
	  problemList: {},
	  dialogVisible: false, // 评价弹框
	  evaluateSucces: false,
	  mvalue: '',
	  uvalue: '',
	  
	  jdStatus: '', 
	  jdNotes:'',
	  
	  ptNumber: '',//平台取消减免费用
	  khNumber: '',  //客户取消金额比例
	  khRstatus: 1, //客户取消方式0比例 1金额
	  zdMyInfo: {},
	  checkRadiobox: [],
	  checkRadiobox2: [],
	  addInvitesNotes:'', //推荐的理由
	  cacalNotes:'',  //工程师申请结束经理驳回理由
	  cacalStatus: 2, //是否是不可抗力
	  cannot: 0, //是否不可抗力
	  
	 rejectShow: false,
	 materialsShow: false, //查看材料弹窗
	 rejected: true, //确认驳回 
	 subItemIdArr: [],  
	 listdata: [], 
	 allChecked: [],
	 ruleForm: {
	   //表单
	   principle: "",
	   technology: "",
	   selectedData: [],
	 },
	 invitesBoxShow: false,  //邀请方式弹框
	 invitesListShow: false, //邀请列表弹框
	  bmInvitesList: [],
	  
    }
  },
  computed: {
    userInfo() {
      return this.$store.getters['userInfo']
    }
  },
  created() {
    this.Id = this.$route.query.id || null
	
	this.mvalue = this.$route.query.mrate || null
	this.uvalue = this.$route.query.urate || null
	
    if (!this.Id) {
      this.$message.error('参数错误')
      return
    }
    this.getDetail();
  },
  methods: {
    async viewCad(item){
        this.cadShow = true;
        this.cadLoading = true;
        let splitArr = item.url.split('.');
        let splitArr2 = (splitArr[splitArr.length-2]).split('/');
        let filterMapid = splitArr2[splitArr2.length-1];
        // --通过网络路径打开地图--传入DWG路径的http路径打开DWG图
        // js代码
        // 新建地图服务对象，传入服务地址和token
        const env = {
            serviceUrl: "https://vjmap.com/server/api/v1",
            accessToken: "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJJRCI6MiwiVXNlcm5hbWUiOiJhZG1pbjEiLCJOaWNrTmFtZSI6ImFkbWluMSIsIkF1dGhvcml0eUlkIjoiYWRtaW4iLCJCdWZmZXJUaW1lIjo4NjQwMCwiZXhwIjoxOTQyMzg5NTc0LCJpc3MiOiJ2am1hcCIsIm5iZiI6MTYyNzAyODU3NH0.VQchVXxgjl5aCp3j3Uf5U2Mpk1NJNirH62Ys-8XOfnY",
            exampleMapId: "sys_zp"
        };
        let svc = new vjmap.Service(env.serviceUrl, env.accessToken)
        // 打开地图
        // 地图ID (如果换成了您自定义的url地址，记得修改此名称id，改成您想要的唯一的名称即可)
        const mapid = filterMapid;
        // 地图url地址，注意：url地址后四个字母必须为.dwg。如果实际中的地址四4位不是.dwg可以随便加个参数如 url?param=xxx&filetype=.dwg
        // const httpDwgUrl = "http://o2o-web.oss-cn-beijing.aliyuncs.com/Sample.dwg" ;
        // https://gczjzb-1308562443.cos.ap-nanjing.myqcloud.com/file/30481654501597257.dwg
        const httpDwgUrl = item.url;
        let res = await svc.openMap({
            // (第一次打开此地图ID时，会根据fileid去获取dwg路径打开，之后会读取缓存打开)
            mapid: mapid, // 地图ID
            fileid: httpDwgUrl,
            mapopenway: vjmap.MapOpenWay.GeomRender, // 以几何数据渲染方式打开
            style: vjmap.openMapDarkStyle() // div为深色背景颜色时，这里也传深色背景样式
        })
        
        /*
        “(第一次打开此地图ID时，会根据fileid去获取dwg路径打开，之后会读取缓存打开)” 的意思是
          举例来说，您第一次打开的图的时候用的是
        {
        mapid: 'map1',
        fileid: 'http://127.0.0.1/1.dwg'
        }
        这时候因为后台没有 map1 这个图纸，所以第一次时 map1 就会去下载 'http://127.0.0.1/1.dwg'
        打开图。
        但是，您第二次的时候如果要换成另外的图2.dwg，但是如果还是用同样的mapid的话，如
        {
        mapid: 'map1',
        fileid: 'http://127.0.0.1/2.dwg'
        }
        这时候为了性能，后台不会再去下载2.dwg了，就直接用之前的下载并处理好的图的缓存数据了。
        这时候有两个选择。
        (1) 要么是updateMap去更新图。
        (2) 要么就重新命名一个新的 mapid 如map2
        */
        if (res.error) {
            message.error(res.error)
        }
        // 获取地图的范围
        let mapExtent = vjmap.GeoBounds.fromString(res.bounds);
        // 建立坐标系
        let prj = new vjmap.GeoProjection(mapExtent);
        console.log('res',res);
        // // 新建地图对象
        // console.log('map',new vjmap())
        let map = new vjmap.Map({
            container: 'map-container', // container ID
            style: svc.rasterStyle(), // 样式，这里是栅格样式,// 矢量瓦片样式
            center: prj.toLngLat(mapExtent.center()), // 中心点
            zoom: 2,
            renderWorldCopies: false
        });
       
        // 地图关联服务对象和坐标系
        map.attach(svc, prj);
        // 使地图全部可见
        map.fitMapBounds();
        await map.onLoad();
        this.cadLoading = false;
    },
    //获取详情
    getDetail() {
      this.$http.get('project/detail', {
        id: this.Id
      }).then(res => {
        if (res.code == 200) {
			if(res.data.memberInfo.id != this.userInfo.id){
				this.$mHelper.to('/'); 
			}
			
          res.data.totalInvestment = initNumberValue(res.data.totalInvestment)
          res.data.totalScale = initNumberValue(res.data.totalScale)
          res.data.totalArea = initNumberValue(res.data.totalArea)
          res.data.orderPrice = initNumberValue(res.data.orderPrice)
          res.data.earningsAmount = initNumberValue(res.data.earningsAmount)
          res.data.depositAmount = initNumberValue(res.data.depositAmount)
          res.data.estimatedPrice = initNumberValue(res.data.estimatedPrice)
          res.data.files.forEach(function (item) {
            item.downloadStatus = false
          })
		  
          res.data.supplyFiles.forEach(function (item) {
            item.downloadStatus = false
          })
          this.projectInfo = res.data;
		  if(res.data.tprojectPostpone!=null){
			   this.formYanApplyData.postponeTime = res.data.tprojectPostpone.postponeTime;
		  }
		 
          this.bmListGet();
          this.getMesList() //获取留言列表
        }
      })
    },
    getLastFileTime(type) {
      var _time = "";
      try {
        if (type==1) {
          var _index = this.projectInfo.engineerInfo.engineerFiles.length - 1;
          if (this.projectInfo.engineerInfo.engineerFiles[_index]) {
            _time = this.projectInfo.engineerInfo.engineerFiles[_index].createDate;
          }
        } else {
          var _index = this.projectInfo.projectFiles.length - 1;
          if (this.projectInfo.projectFiles[_index]) {
            _time = this.projectInfo.projectFiles[_index].createDate;
          }
        }
      } catch (error) {}
      return _time;
    },
	tabBmChange(e){
		if(e!=this.bmActive){
			this.bmActive = e;
			this.bmList = [];
			this.bmListGet();
		}
	},
    bmListGet() {
      this.$http.get('director/bmList', {
        id: this.Id,
        pageNum: 1,
        pageSize: 999,
		status: this.bmActive,
      }).then(res => {
        if (res.code == 200) {
			res.data.list.forEach((e)=>{
				e.rateValue = e.synthesize/20 || 0;
				e.is_fm = 0;
				if(e.team==1){ //有团队
					if(e.orderNum >= 5){
						e.is_fm = 1;
					}
				}else {
					if(e.orderNum >= 2){
						e.is_fm = 1;
					}
				}
			})  
            this.bmList = res.data.list
        }
      })
    },
    //权限
    OpenTakeBack(item, type) {
      this.$http.post('director/permissionSet', {
        projectId: this.Id,
        type: type,
        engineerId: item.id
      }).then(res => {
        if (res.code == 200) {
          this.$message.success('操作成功')
          this.bmListGet();
        }
      })
    },
    saveMsgTap() {
      if (!this.textarea) {
        this.$message.error('请输入留言内容')
        return
      }
      this.$http.post('project/saveMsg', {
        type: this.radio,
        projectId: this.Id,
        content: this.textarea
      }).then(res => {
        if (res.code == 200) {
          this.$message.success('留言成功')
          this.textarea = ''
          this.getMesList()
        }
      })
    },
    pageChange(e) {
      this.queryParams.page = e
      this.getMesList()
    },
    getMesList() {
      this.$http.post('project/listMsg', {
        projectId: this.Id,
        pageNum: this.queryParams.page,
        pageSize: this.queryParams.limit
      }).then(res => {
        if (res.code == 200) {
          this.queryParams.totalPage = res.data.pages
          this.msgList = res.data.list
        }
      })
    },
	
	materialsbtnTap(item) {
	  if (item.checkStatus === 2) {
	    this.checkStatus = true;
	  } else {
	    this.checkStatus = false;
	  }
	  this.materialsShow = true;
	},
	
	// 审核驳回
	toExamine() {
	  this.rejectShow = true;
	  this.materialsShow = false;
	  this.$http.get("/projectEvaluateProject/getBase2", {
	      evaluateType: 1,
	    }).then((res) => {
	      if (res.code === 200) {
	        res.data.forEach((item) => {
	          item.isCheck = "2";
	          item.subItem.forEach((subItem) => {
	            subItem.ischecked = false;
	            // this.allChecked.push(subItem);
	          });
	        });
	        this.listdata = res.data;
	      }
	    }).catch((err) => {
	      console.log(err);
	    });
	},
	
	//审核通过
	through() {
	  const h = this.$createElement;
	  this.$msgbox({
	    title: "提示",
	    message: h(
	      "p",
	      {
	        style: "margin-bottom: 20px",
	      },
	      [
	        h(
	          "span",
	          {
	            style: "color: red",
	          },
	          //"是否需要客户多退少补! "
			  "是否确认成果审核通过"
	        ),
	      ]
	    ),
	    center: true,
	    showCancelButton: true,
	    confirmButtonText: "通过",
	    cancelButtonText: "取消",
	    beforeClose: (action, instance, done) => {
	      if (action === "confirm") {
	        instance.confirmButtonLoading = true;
	        
	        this.$http.post("director/review", {
	            id: this.projectInfo.id,
	            type: 1,
	          }).then((res) => {
	            this.loading = false;
	            if (res.code == 200) {
	              this.materialsShow = false;
	              instance.confirmButtonLoading = false;
				   this.getDetail();
				   setTimeout(() => {
				     done();
				     setTimeout(() => {
				       instance.confirmButtonLoading = false;
						this.toUserApply();	
				     }, 300);
				   }, 2000);
	            }
	          }).catch((err) => {
	            this.loading = false;
	          });
	      } else {
	        done();
	      }
	    },
	  }).then((action) => {
	      this.getDetail();
	    }).catch((err) => {
	      console.error(err);
	    });
	},
	
	
	toUserApply(){
		const h1 = this.$createElement;
		this.$msgbox({
		  title: "提示",
		  message: h1(
		    "p",
		    {
		      style: "margin-bottom: 20px",
		    },
		    [
		      h1(
		        "span",
		        {
		          style: "color: red",
		        },
		        "点击转交客户审核后项目将进入验收阶段 "
		      ),
		      h1(
		        "br",
		        {
		          style: "color: red",
		        },
		        ""
		      ),
		      h1(
		        "span",
		        {
		          style: "color: red",
		        },
		        "您确定要转交吗？ "
		      ),
		    ]
		  ),
		  center: true,
		  showCancelButton: true,
		  showConfirmButton: true,
		  confirmButtonText: "确认转交",
		  cancelButtonText: "暂不转交",
		}).then((e) => {
			this.loading = true;
			this.$http.post("director/isSee", {
			    projectId: this.Id,
			  }).then((res) => {
			    this.loading = false;
			    if (res.code == 200) {
					this.getDetail();
					this.$message.success("已成功转交客户验收!");
			    }
			  }).catch((err) => {
			    this.loading = false;
			  });
		});
		this.materialsShow = false;
		
	},
	
	selectValue(index) {
	  this.listdata.forEach((item) => {
	    if (item.isCheck == 2) {
	      item.subItem.forEach((subItem) => {
	        if (subItem.ischecked) {
	          // this.$set(subItem, "ischecked", false);
	           subItem.ischecked = false;
	          if (this.allChecked.length > 0) {
	            this.allChecked.forEach((allItem, index) => {
	              if (allItem == subItem.id) {
	                this.allChecked.splice(index, 1);
	              }
	            });
	          }
	        }
	      });
	    }
	  });
	  // this.$forceUpdate();
	  // this.listdata.forEach((item) => {
	  //   if (item.isCheck == 2) {
	  //     item.subItem.forEach((subItem) => {
	  //         subItem.ischecked = false
	
	  //       if (subItem.ischecked) {
	  //         subItem.ischecked = false
	  //         if (this.allChecked.length > 0) {
	  //           this.allChecked.forEach((allItem, index) => {
	  //             if (allItem == subItem.id) {
	  //               this.allChecked.splice(index, 1);
	  //             }
	  //           });
	  //         }
	  //       }
	  //     });
	  //   }
	  // });
	  if (this.allChecked.length > 0) {
	    this.rejected = false;
	  } else {
	    this.rejected = true;
	  }
	  // this.num = item
	  // if(item == 2 && this.chischecValue){
	  //  this.rejected = true
	  // }else if(item == 1 && this.chischecValue){
	  //   this.rejected = false
	  // }
	},
	//点击多选框事件
	datesChange(data) {
	  data.ischecked = !data.ischecked;
	  if (this.allChecked.length > 0) {
	    this.allChecked.forEach((allItem, index) => {
	      if (allItem == data.id) {
	        this.allChecked.splice(index, 1);
	      }
	    });
	  }
	  this.listdata.forEach((item) => {
	    if (item.isCheck == 1) {
	      item.subItem.forEach((subItem) => {
	        if (subItem.ischecked && !this.allChecked.includes(subItem.id)) {
	          console.log('subItem',subItem)
	          this.allChecked.push(subItem.id);
	        }
	      });
	    }
	  });
	  if (this.allChecked.length > 0) {
	    this.rejected = false;
	  } else {
	    this.rejected = true;
	  }
	  //this.chischecValue = item.ischecked;
	  // 控制确认驳回按钮
	  // if(item.ischecked){
	  //   this.rejected = false
	  // } else{
	  //   this.rejected = true
	  // }
	},
	changeValue(item) {
	  this.$forceUpdate();
	},
	
	// 确认驳回
	rejectedObject() {
	  this.loading = true;
	  let arr = [];
	  this.listdata.forEach((item, index) => {
	    if (item.isCheck == 1) {
	      arr.push({
	        isCheck: 1,
	        name: item.item.item,
	        items: [],
	      });
	      item.subItem.forEach((subItem) => {
	        if (subItem.ischecked) {
	          arr[arr.length - 1].items.push({
	            id: subItem.id,
	            num: subItem.num,
	            score: subItem.score,
	            preItemId: subItem.preItemId,
	            title: subItem.item + subItem.itemDes,
	            note: subItem.content,
	          });
	        }
	      });
	    }
	  });
	  this.$http.post("director/review", {
	      id: this.projectInfo.id,
	      type: 2,
	      remark: JSON.stringify(arr),
	    }).then((res) => {
	      this.loading = false;
	      if (res.code == 200) {
	        this.rejectShow = false;
			this.$message.success("您已驳回此工作成果!");
	        this.getDetail();
	        this.ruleForm = {};
	        this.ruleForm.selectedData = [];
	        this.isReject = true;
	        this.rejected = true;
	      }
	    }).catch((err) => {
	      this.loading = false;
	    });
	  this.getDetail();
	},
	
	
    downItemOnleyChengguo(item) {
      this.loading = true
      this.$http.downloadFile('upload/download', {
        fileId: item.id,
        projectId: this.Id
      }).then(res => {
        var reader = new FileReader();
        let that = this
        reader.onloadend = function (event) {
          //因为返回的报错格式是字符串，手动转换成对象，转换成功表示请求失败
          //转换失败就意味着你拿到的result是文件流，那么直接手动下载就好
          this.loading = false
          console.log(res)
          try {
            let data = JSON.parse(event.target.result)
            that.$message.error(res.data.msg);
          } catch (err) {
            const link = document.createElement('a'); // 创建a标签
            let blob = new Blob([res.data]);
            link.style.display = 'none';
            link.href = URL.createObjectURL(blob); // 创建下载的链接
            link.setAttribute('download', item.name); // 给下载后的文件命名
            document.body.appendChild(link);
            link.click(); // 点击下载
            document.body.removeChild(link); //  完成移除元素
            window.URL.revokeObjectURL(link.href); // 释放blob对象
            that.materialsShow = false;
          }
        };
        reader.readAsText(res.data);
      }).catch(err => {
        this.loading = false
      })
    },
	
	downItemOnleyChengguo2(item) {
	  this.loading = true
	  this.$http.downloadFile('upload/download', {
	    fileId: item.fileId,
	    projectId: this.Id
	  }).then(res => {
	    var reader = new FileReader();
	    let that = this
	    reader.onloadend = function (event) {
	      //因为返回的报错格式是字符串，手动转换成对象，转换成功表示请求失败
	      //转换失败就意味着你拿到的result是文件流，那么直接手动下载就好
	      this.loading = false
	      console.log(res)
	      try {
	        let data = JSON.parse(event.target.result)
	        that.$message.error(res.data.msg);
	      } catch (err) {
	        const link = document.createElement('a'); // 创建a标签
	        let blob = new Blob([res.data]);
	        link.style.display = 'none';
	        link.href = URL.createObjectURL(blob); // 创建下载的链接
	        link.setAttribute('download', item.fileName); // 给下载后的文件命名
	        document.body.appendChild(link);
	        link.click(); // 点击下载
	        document.body.removeChild(link); //  完成移除元素
	        window.URL.revokeObjectURL(link.href); // 释放blob对象
	        that.materialsShow = false;
	      }
	    };
	    reader.readAsText(res.data);
	  }).catch(err => {
	    this.loading = false
	  })
	},
	
    //下载项目成果 release/batchDownload
    downWenjianCg() {
      this.loadFruit = true
      this.$http.downloadFile('release/batchDownload', {
        id: this.Id
      }).then(res => {
        this.loadFruit = false;
        var reader = new FileReader();
        let that = this
        reader.onloadend = function (event) {
          //因为返回的报错格式是字符串，手动转换成对象，转换成功表示请求失败
          //转换失败就意味着你拿到的result是文件流，那么直接手动下载就好
          console.log(res)
          try {
            let data = JSON.parse(event.target.result)
            that.$message.error(res.data.msg);
          } catch (err) {
            const link = document.createElement('a'); // 创建a标签
            let blob = new Blob([res.data]);
            link.style.display = 'none';
            link.href = URL.createObjectURL(blob); // 创建下载的链接

            link.setAttribute('download', '项目成果文件.zip'); // 给下载后的文件命名
            document.body.appendChild(link);
            link.click(); // 点击下载
            document.body.removeChild(link); //  完成移除元素
            window.URL.revokeObjectURL(link.href); // 释放blob对象
            that.materialsShow = false;
          }
        };
        reader.readAsText(res.data);
      }).catch(err => {
        this.loadFruit = false;
      })
    },
    //下载工程师成果 director/download
    downloadEngineerFruit() {
      this.loadEngiFruit = true
      this.$http.downloadFile('director/download', {
        id: this.Id
      }).then(res => {
        this.loadEngiFruit = false
        var reader = new FileReader();
        let that = this
        reader.onloadend = function (event) {
          //因为返回的报错格式是字符串，手动转换成对象，转换成功表示请求失败
          //转换失败就意味着你拿到的result是文件流，那么直接手动下载就好
          console.log(res)
          try {
            let data = JSON.parse(event.target.result)
            that.$message.error(res.data.msg);
          } catch (err) {
            const link = document.createElement('a'); // 创建a标签
            let blob = new Blob([res.data]);
            link.style.display = 'none';
            link.href = URL.createObjectURL(blob); // 创建下载的链接

            link.setAttribute('download', '工程师成果文件.zip'); // 给下载后的文件命名
            document.body.appendChild(link);
            link.click(); // 点击下载
            document.body.removeChild(link); //  完成移除元素
            window.URL.revokeObjectURL(link.href); // 释放blob对象
            that.materialsShow = false;
          }
        };
        reader.readAsText(res.data);
      }).catch(err => {
        this.loadEngiFruit = false
      })
    },
    downloadFile_origin(item, index, type) {
      this.loading = true
      this.$http.downloadFile('upload/download', {
        fileId: item.id,
        projectId: this.Id
      }).then(res => {
        console.log(res)
        this.loading = false
        if (res.data.type === 'application/force-download') {
          const link = document.createElement('a'); // 创建a标签
          let blob = new Blob([res.data]);
          link.style.display = 'none';
          link.href = URL.createObjectURL(blob); // 创建下载的链接
          link.setAttribute('download', item.name); // 给下载后的文件命名
          document.body.appendChild(link);
          link.click(); // 点击下载
          document.body.removeChild(link); //  完成移除元素
          window.URL.revokeObjectURL(link.href); // 释放blob对象
          this.loading = false
          this.materialsShow = false;
        } else {
          // 将blob文件流转换成json
          let _that = this;
          const reader = new FileReader()
          reader.onload = function (event) {
            let data = JSON.parse(event.target.result);
            if (data && data.hasOwnProperty('msg')) {
              _that.$message.error(data.msg);
            }
          }
          reader.readAsText(res.data)
        }
      }).catch(err => {
        console.log(err)
        this.loading = false
        //console.log(err)
        let _that = this;
        if (err.data.type === "application/json") {
          const reader = new FileReader()
          reader.onload = function (event) {
            let data = JSON.parse(event.target.result);
            console.log(data)
            if (data && data.hasOwnProperty('msg')) {
              _that.$message.error(data.msg);
            }
          }
          reader.readAsText(err.data)
        }
      })
    },
    downloadFile(item, index, type) {
		
      if (this.userInfo.identity != 3 && this.projectInfo.fgJurisdiction != 1) {
        this.$message.error('暂无权限')
        return;
      }
      //this.loading = true
      if (type == 1) {
        //附件资料下载
        this.projectInfo.files[index].downloadStatus = true;
      } else {
        //补充资料下载
        this.projectInfo.supplyFiles[index].downloadStatus = true;
      }
      this.$http.downloadFile('upload/download', {
        fileId: item.id,
        projectId: this.Id
      }).then(res => {
        console.log(res)
        //this.loading = false
        var reader = new FileReader();
        let that = this
        reader.onloadend = function (event) {
          //因为返回的报错格式是字符串，手动转换成对象，转换成功表示请求失败
          //转换失败就意味着你拿到的result是文件流，那么直接手动下载就好
          try {
            let data = JSON.parse(event.target.result)
            console.log(data)
            that.$message.error("请点击右上角登录后重新下载");
            //this.loading = false
            if (type == 1) {
              that.projectInfo.files[index].downloadStatus = false;
            } else {
              that.projectInfo.supplyFiles[index].downloadStatus = false;
            }
          } catch (err) {
            const link = document.createElement('a'); // 创建a标签
            let blob = new Blob([res.data]);
            link.style.display = 'none';
            link.href = URL.createObjectURL(blob); // 创建下载的链接
            link.setAttribute('download', item.name); // 给下载后的文件命名
            document.body.appendChild(link);
            link.click(); // 点击下载
            document.body.removeChild(link); //  完成移除元素
            window.URL.revokeObjectURL(link.href); // 释放blob对象
            //this.loading = false
            if (type == 1) {
              that.projectInfo.files[index].downloadStatus = false;
            } else {
              that.projectInfo.supplyFiles[index].downloadStatus = false;
            }
            that.materialsShow = false;
          }
        };
        reader.readAsText(res.data);
      }).catch(err => {
        console.log(err)
        // this.loading = false
        if (type == 1) {
          //附件资料下载
          this.projectInfo.files[index].downloadStatus = false;
        } else {
          //补充资料下载
          this.projectInfo.supplyFiles[index].downloadStatus = false;
        }
      })

    },
    //邀请工程师
    inviteTap() {
      if (!this.engineerNo) {
        this.$message.error('请输入工程师编号')
        return;
      }
      this.loading = true;
      this.$http.post('/director/invitation', {
        engineerNo: this.engineerNo,
        projectId: this.Id,
		 inviteReason: this.addInvitesNotes,
      }).then(res => {
        this.loading = false
        if (res.code == 200) {
          this.inviteShow = false;
          const h1 = this.$createElement;
          this.$msgbox({
            title: '提示',
            message: h1('p', {
              style: 'margin-bottom: 20px'
            }, [
              h1('span', null, '您已成功邀请工程师申请此项目！ '),
              h1('br', {
                style: 'color: red'
              }, ''),
              h1('span', null, '如此工程师同意申请，则会出现在项目申请列表中！ '),
            ]),
            center: true,
            showCancelButton: true,
            showConfirmButton: false,
            cancelButtonText: '我知道了',
          })
        }
      }).catch(err => {
        this.loading = false
      })

    },
	
	//打开邀请选择弹框
	openInvites(){
		this.invitesBoxShow = true;
	},
	//yao邀请工程师弹窗
	invitation() {
	  this.engineerNo = "";
	  this.inviteShow = true;
	},
	
	//打开已邀请列表
	openInviresList(){
		this.getBmInvitesList();
		this.invitesListShow = true;
	},
	goToInvites() {
		this.invitesBoxShow = false;
		this.$mHelper.to('/talents')
	},
	
	getBmInvitesList(){
		this.$http.get('director/bmList', {
		  id: this.Id,
		  pageNum: 1,
		  pageSize: 999,
		  status: 1,
		}).then(res => {
		  if (res.code == 200) {
				res.data.list.forEach((e)=>{
					e.rateValue = e.synthesize/20 || 0;
					e.is_fm = 0;
					if(e.team==1){ //有团队
						if(e.orderNum >= 5){
							e.is_fm = 1;
						}
					}else {
						if(e.orderNum >= 2){
							e.is_fm = 1;
						}
					}
					
				})  
		      this.bmInvitesList = res.data.list
		  }
		})
	},
	
	
    //邀请工程师面板关闭
    handleInvitesClose() {
      this.inviteShow = false;
      console.log('邀请工程师面板关闭')
    },
	handleClose(done) {
	  this.$confirm('当前页面未保存，是否退出？', '提示', {
	      center: true,
	    }).then(e => {
	     this.credentialsShow = false;
	     this.subItemIdArr = [];
	     this.ruleForm.principle = "";
	     this.ruleForm.technology = "";
	     this.ruleForm.selectedData = [];
	     this.rejected = true;
	      done();
	    }).catch(err => {});
	},
	
	//延期审核
	submitYanApplyEvaluate(){
		
		if (this.formYanApplyData.postponeTime=='') {
		  this.$message.error('请选择延期时间')
		  return;
		}
		
		if(this.formYanApplyData.type == ""){
			this.$message.error('请选择审核结果')
			return;
		}
		if (this.formYanApplyData.type==2 && this.formYanApplyData.managerCase==null || this.formYanApplyData.type==2 && this.formYanApplyData.managerCase=='') {
		  this.$message.error('请输入不通过原因')
		  return;
		}
		
		if(this.formYanApplyData.type==1){
			this.formYanApplyData.type = 0;
		}else if(this.formYanApplyData.type==2){
			this.formYanApplyData.type = 1;
		}
		
		this.loading = true;
		this.$http.post('release/checkPostpone', {
			isWho: 3,	//0-客户，1-造价师，3-项目经理
			id: this.projectInfo.tprojectPostpone.id,
			postponeTime: this.formYanApplyData.postponeTime,
			cause: this.formYanApplyData.managerCase,
			projectId: this.Id,
			type: this.formYanApplyData.type,
		}).then(res => {
		  this.loading = false
		  if (res.code == 200) {
		      this.timeShowApply = false;
		     this.$message.success('延期审核完成')
			 this.getDetail();
		  }
		}).catch(err => {
		  this.loading = false
		})
	},
	
	//下拉菜单
	handleCommand(e) {
		if(e.type==1){
			this.getMoneyInfo();
			this.cancalShowApply = true;
		}else if(e.type==2){
			this.cancalgcsCloseShow = true;
		}else if(e.type==3){
			this.cgPuopShow();
		}else if(e.type==4){
			this.cancalpingCloseShow = true;
		}
		
	},
	
	applykh(){
		this.getMoneyInfo();
		this.cancalShowApply = true;
	},
	
	upFruitfileSuccess(response, file, fileList) {
	  let list = [];
	  fileList.forEach((e) => {
	    list.push({
	      name: e.name,
	      url: e.response.data,
	    });
	  });
	  this.waitFruitfiles = list;
	},
	handleFruitRemove(file, fileList) {
	  let list = [];
	  fileList.forEach((e) => {
	    list.push({
	      name: e.name,
	      url: e.response.data,
	    });
	  });
	  this.waitFruitfiles = list;
	},
	handleExceed(files, fileList) {
	  this.$message.warning(`最多选择9张图片`);
	},
	
	cgPuopShow(item) {
	  this.files = [];
	  this.resultsShow = true;
	  this.$nextTick(() => {
	    this.fileList = [];
	    this.$refs["files"].clearFiles();
	  });
	},
	//上传结果请求
	suppleUpdata(type) {
		if (this.waitFruitfiles.length < 1) {
		  this.$message.error("请上传文件后提交");
		  return;
		}
	  this.loading = true;
	  this.$http.post("director/uploadCg", {
	      files: this.waitFruitfiles,
	      id: this.Id,
	    }).then((res) => {
	      this.loading = false;
	      if (res.code == 200) {
	        this.$message.success("上传成功");
	        this.resultsShow = false;
			this.getDetail();
	      } else {
	      }
	    }).catch((err) => {
	      this.loading = false;
	    });
	},
	//导出质控记录
	exportZhikong(){
		this.$http.get("director/export", {
		    id: this.Id,
		  }).then((res) => {
		    if (res.code == 200) {
				window.location.href = process.env.VUE_APP_BASE_API_DOWN + "/common/download?fileName=" + encodeURI(res.msg) + "&delete=" + true;
		    } else {
				
		    }
		  }).catch((err) => {
		    
		  });
	},
	
	
	successEva() {
	  this.evaluateSucces = false;
	  this.getList();
	},
	// 打分
	rateChange(e, item, index) {
	  this.problemList[index].isCheck = e;
	},
	// 取消评价
	canclePing() {
	  this.problemList.forEach((item) => {
	    if (item.note == "" && item.isCheck == 0) {
	      this.dialogVisible = false;
	    } else {
	      this.cancel = true;
	      this.dialogVisible = true;
	    }
	  });
	},
	// 项目评价
	goEvaluate() {
	  this.dialogVisible = true;
	  this.$http.get("/projectEvaluateProject/getBase", {
	      evaluateType: 2,
	    }).then((res) => {
	      if (res.code === 200) {
	        res.data.forEach((item) => {
	          item.note = "";
	          item.isCheck = 0;
	        });
	        this.problemList = res.data;
	      }
	    }).catch((err) => {
	      console.log(err);
	    });
	},
	// 提交评价
	submitpingEvaluate() {
	  let arrList = [];
	  this.problemList.forEach((item) => {
	    if (item.isCheck > 0) {
	      arrList.push({
	        isCheck: item.isCheck,
	        items: [
	          {
	            id: item.id,
	            num: item.isCheck,
	            fraction: item.isCheck * item.score,
	            score: item.score,
	            preItemId: item.preItemId,
	            title: item.item,
	            note: item.note,
	          },
	        ],
	      });
	    }
	  });
	  this.$http.post("/projectEvaluateProject/setEvaluate", {
	      projectId: this.Id,
	      evaluateType: 2,
	      mid: this.$store.state.userInfo.id,
	      content: JSON.stringify(arrList),
	    }).then((res) => {
	      if (res.code == 200) {
	        this.dialogVisible = false;
	        this.evaluateSucces = true;
			this.getDetail();
	      }
	      this.form = {};
	      this.scoring = {};
	    }).catch((err) => {});
	},
	isJsonString(str) {
	    try {
	        JSON.parse(str);
	        return true;
	    } catch (e) {
	        return false;
	    }
	},
	remarkLook(item){
		if(this.isJsonString(item)==true){
			let aa = JSON.parse(item);
			let arr = [];
			// aa.forEach((item) => {
			//   item.items.forEach((sub, index) => {
			//     arr.push(sub);
			//   });
			// });
			//return aa[0].items[0].title + ' '+ aa[0].items[0].score;
			return aa;
			
		}else{
			return item;
		}
	},
	
	//阶段性审核
	submitJieduanEvaluate(){
		if(this.jdStatus=='' || this.jdStatus==null){
			this.$message.error('请选择审核结果！');
			return;
		}
		if(this.jdStatus==2 && this.jdNotes==''){
			this.$message.error('请输入拒绝原因！');
			return;
		}
		
		const h2 = this.$createElement;
		this.$confirm("", {
		  title: "提示",
		  message: h2(
		    "p",{ style: "margin-bottom: 20px", },
		    [
		      h2(
		        "span", { style: "color: red",}, this.jdStatus ==1 ? "是否确认通过阶段性结款申请？": '是否确认拒绝阶段性结款申请'
		      ),
		      h2("br", null, ""),
		      h2(
		        "span", { style: "color: red",}, ""
		      ),
		    ]
		  ),
		  center: true,
		  showCancelButton: true,
		  confirmButtonText: this.jdStatus ==1 ? "确认通过？": '确认拒绝',
		  cancelButtonText: "我再想想",
		}).then(() => {
		    var durTip = this.$message({
		      duration: 1000,
		      message: h2("p", null, [
		        h2("span", { style: "color: #4077DD" }, "处理中… "),
		      ]),
		    });
		    this.$http.post("director/stageExamine", {
		         projectId: this.Id,
				 examineStatus: this.jdStatus,
				 remake: this.jdNotes
		      }).then((res) => {
		        durTip.close();
		        if (res.code == 200) {
					this.jdShowApply = false,
		          this.getDetail();
		           this.$message.success('操作成功')
		        }
		      }).catch((err) => {
		        durTip.close();
		      });
		  }).catch((action) => {
		    
		  });
	},
	
	getMoneyInfo(){
		let form = {};
		if(this.khNumber==""){
			form = {
				projectId: this.Id,
			}
		}else{
			let type = "";
			if(this.khRstatus==1){
				type = 0;
			}else if(this.khRstatus==2){
				type = 1;
			}
			form = {
				projectId: this.Id,
				type: type,
				typeNum: this.khNumber,
			}
		}
		
		this.$http.post("project/cancelProject", form).then((res) => {
		    if (res.code == 200) {
				if(res.data){
					this.zdMyInfo = res.data;
				}else{
					this.$message.error(res.msg);
				}
		       
		    } else {
		    }
		  }).catch((err) => {
		    
		  });
	},
	cancalquxiao(type){
		let form = {};
		if(type == 1){	//带客户取消
			if (this.checkRadiobox.length < 1) {
			  this.$message.error('请勾选我已与客户进行协商，并取得客户授权进行项目取消 ')
			  return
			}
		
			if (this.khRstatus==1 && this.khNumber == '') {
			  this.$message.error("请输入收取服务费比例");
			  return;
			}
			if (this.khRstatus==2 && this.khNumber == '') {
			  this.$message.error("请输入收取服务费金额");
			  return;
			}
			
			if(this.khRstatus==1){
				form = {
					projectId: this.Id,
					type: 10,
					payWay: 0,
					payMoneyScale: this.khNumber,
					review: 2,
				}
			}else if(this.khRstatus==2){
				form = {
					projectId: this.Id,
					type: 10,
					payWay: 1,
					payMoneyScale: this.khNumber,
					review: 2,
				}
			}
			
		}else if(type==2){	//待工程师取消
			if (this.checkRadiobox2.length < 1) {
			  this.$message.error('请勾选我已与造价师进行协商，并取得造价师授权进行项目取消 ')
			  return
			}
			
			if(this.cannot==0){
				this.$message.error('请选择是否不可抗力！ ')
				return
			}
			
			let canType = 0;
			if(this.cannot==1){
				canType = 0;
			}else if(this.cannot==2){
				canType = 1;
			}
			
			form = {
				projectId: this.Id,
				cannot: canType,
				type: 11,
				review: 2,
			}
		
		}else if(type==4){  //平台取消
			if (this.ptNumber=='' || this.ptNumber==null) {
			  this.$message.error("请输入减免管理费金额");
			  return;
			}
			form = {
				projectId: this.Id,
				type: 9,
				decreaseExpense: this.ptNumber,
				review: 2,
			}
			
		}
		
		//请求成功后弹窗
		const h2 = this.$createElement;
		this.$msgbox({
		  title: "提示",
		  message: h2(
		    "p",{ style: "margin-bottom: 20px",},
		    [
		      h2( "span", { style: "color: red",},"是否确认取消该项目"
		      ),
		      h2("br", null, ""),
		      h2( "span", {style: "color: red", },"您确定要取消吗？"
		      ),
		    ]
		  ),
		  center: true,
		  showCancelButton: true,
		  confirmButtonText: "确认取消",
		  cancelButtonText: "我再想想",
		  beforeClose: (action, instance, done) => {
		    if (action === "confirm") {
		      instance.confirmButtonLoading = true;
		      instance.confirmButtonText = "执行中...";
		      this.$http.post("project/cancelOrderSave", form).then((res) => {
		          if (res.code == 200) {
		            done();
		            setTimeout(() => {
		              instance.confirmButtonLoading = false;
		            }, 300);
		          }
		        });
		    } else {
		      done();
		    }
		  },
		}).then((action) => {
		  this.getDetail();
		  this.cancalShowApply = false;
		  this.cancalgcsCloseShow = false;
		  this.cancalpingCloseShow = false;
		  this.$message.success("取消成功！");
		});
		
	},
	applyCacel(type){
		let form = {};
		if(this.cacalStatus==''){
		  this.$message.error("请选择是否不可抗力");
		  return;
		}
		if(type == 3){	//带客户取消
			if (this.cacalNotes=='') {
			  this.$message.error("驳回请输入驳回原因");
			  return;
			}
		}
		
		let status = 1;
		if(this.cacalStatus==1){
			status  = 0;
		}
		
		form = {
			id: this.projectInfo.cancelOrder.id,
			remark: this.cacalNotes,
			review: type,
			cannot: status
		}
		
		//请求成功后弹窗
		const h2 = this.$createElement;
		this.$msgbox({
		  title: "提示",
		  message: h2(
		    "p",{ style: "margin-bottom: 20px",},
		    [
		      h2( "span", { style: "color: red",}, type==2 ? "是否确认通过取消该项目" : "是否确认驳回该请求"
		      ),
		      h2("br", null, ""),
		      h2( "span", {style: "color: red", }, type==2 ? "您确定要取消吗？" : "您确定要驳回吗"
		      ),
		    ]
		  ),
		  center: true,
		  showCancelButton: true,
		  confirmButtonText:  type==2 ? '确认取消' : '确认驳回',
		  cancelButtonText: "我再想想",
		  beforeClose: (action, instance, done) => {
		    if (action === "confirm") {
		      instance.confirmButtonLoading = true;
		      instance.confirmButtonText = "执行中...";
		      this.$http.post("director/shSave", form).then((res) => {
		          if (res.code == 200) {
		            done();
		            setTimeout(() => {
		              instance.confirmButtonLoading = false;
		            }, 300);
		          }
		        });
		    } else {
		      done();
		    }
		  },
		}).then((action) => {
		  this.getDetail();
		  this.cacalNotes = '';
		  this.cancalCloseShowApply = false;
		  this.$message.success("操作成功！");
		});
		
		
		
	},
	
	
  }
}
</script>

<style lang="scss" scoped>
	::v-deep  .el-icon-star-on {
		color: #FFB420;
	}
	.on-sign-up{
		background: #1677FF;
	}
	.on-conduct{
		background: #FFC726;
	}
	.on-completed{
		background: #E8E8E8;
	}
	.on-success{
		background: #10c550;
	}
	.lines-title{
		.export{
			font-weight: 400;
			font-size: 14px;
			color: #3B77FF;
			margin-left: 16px;
		}
	   .hengxian {
	       width: calc(100% - 86px);
	   }
	}
	.lines-title2{
	   .hengxian {
	       width: calc(100% - 186px);
	   }
	}
	.lines-title3{
		.hengxian{
			 width: calc(100% - 170px);
		}
		.span1{
			font-size: 13px;
			cursor: pointer;
		}
		.span2{
			font-size: 13px;
			margin-left: 12px;
			cursor: pointer;
		}
		.active{
			font-size: 16px;
			cursor: auto;
		}
	}
	
	.topNavs{
		display: flex;
		justify-content: space-between;
		align-items: center;
		.topMeg{
			display: flex;
			align-items: center;
			img{
				width: 21px;
				height: 21px;
			}
			span{
				font-family: PingFangSC, PingFang SC;
				font-weight: 400;
				font-size: 16px;
				color: #FF4D4F;
				line-height: 21px;
				margin-left: 8px;
			}
		}
	}
	
	.hall-top{
		margin-top: 33px;
		.topSn{
			font-family: PingFangSC, PingFang SC;
			font-weight: 400;
			font-size: 16px;
			color: rgba(0,0,0,0.85);
			line-height: 21px;
			display: flex;
			justify-content: space-between;
			align-items: center;
		}
		.topTip{
			background: #FFFBE6;
			border-radius: 8px;
			border: 1px solid #FFF1B8;
			padding: 9px 13px;
			margin-top: 17px;
			.topTitle{
				display: flex;
				justify-content: space-between;
				.tipLeft{
					display: flex;
					align-items: center;
					img{
						width: 14px;
						height: 14px;
					}
					span{
						font-family: PingFangSC, PingFang SC;
						font-weight: 400;
						font-size: 14px;
						color: rgba(0,0,0,0.88);
						line-height: 22px;
						margin-left: 8px;
						white-space: normal;
						word-break: break-all;
					}
				}
				.tipRight{
					font-family: PingFangSC, PingFang SC;
					font-weight: 400;
					font-size: 14px;
					color: #1677FF;
					line-height: 22px;
				}
			}
			.tipInfo{
				font-family: PingFangSC, PingFang SC;
				font-weight: 500;
				font-size: 14px;
				color: rgba(0,0,0,0.88);
				line-height: 22px;
				margin-top: 8px;
				white-space: normal;
				word-break: break-all;
			}
		}
		.top-content{
			display: flex;
			align-items: center;
			justify-content: space-between;
			.left{
				width: calc(100% - 400px);
				.topNames{
					font-family: PingFangSC, PingFang SC;
					font-weight: 500;
					font-size: 18px;
					color: rgba(0,0,0,0.85);
					line-height: 21px;
					margin-top: 17px;
					display: flex;
					align-items: center;
					.topState{
						padding: 0.5px 4px;
						border-radius: 4px;
						font-size: 10px;
						color: #FFFFFF;
						text-align: center;
						margin-right: 8px;
					}
				}
				.topTagsBox{
					margin-top: 16px;
					.tags{
						font-family: PingFangSC, PingFang SC;
						font-weight: 400;
						font-size: 14px;
						color: #4077DD;
						line-height: 21px;
						text-align: left;
						font-style: normal;
						margin-right: 16px;
						position: relative;
						&:before{
							content: '';
							width: 1px;
							height: 12px;
							background-color: #4077DD;
							position: absolute;
							top: 5px;
							right: -8px;
						}
						&:last-child{
							&:before{
								display: none;
							}
						}
					}
				}
				.topPrice{
					font-family: PingFangSC, PingFang SC;
					font-weight: 600;
					font-size: 18px;
					color: #FF4D4F;
					line-height: 36px;
					text-align: left;
					font-style: normal;
					margin-top: 24px;
				}
				.topTime{
					font-family: PingFangSC, PingFang SC;
					font-weight: 400;
					font-size: 16px;
					color: rgba(0,0,0,0.85);
					line-height: 21px;
					text-align: left;
					font-style: normal;
					margin-top: 25px;
					.yq-time{
						background: #FFFFFF;
						border-radius: 4px;
						border: 1px solid #4077DD;
						font-family: PingFangSC, PingFang SC;
						font-weight: 400;
						font-size: 12px;
						color: #4077DD;
						line-height: 21px;
						padding: 0 4px;
						margin-left: 16px;
					}
				}
				
			}
			.right{
				width: 338px;
				.right-type-box1{
					display: flex;
					flex-flow: column;
					align-items: flex-end;
					.type-btn{
						width: 230px;
						height: 60px;
						background: #FF4D4F;
						border-radius: 8px;
						display: flex;
						justify-content: center;
						align-items: center;
						img{
							width: 20px;
							height: 20px;
						}
						span{
							font-family: PingFangSC, PingFang SC;
							font-weight: 400;
							font-size: 16px;
							color: #FFFFFF;
							line-height: 24px;
							text-align: center;
							font-style: normal;
							margin-left: 8px;
						}
					}
					.type-number{
						width: 230px;
						font-family: PingFangSC, PingFang SC;
						font-weight: 400;
						font-size: 16px;
						color: #4077DD;
						line-height: 21px;
						text-align: center;
						font-style: normal;
						margin-top: 12px;
					}
				}
				.right-rate-box1{
					display: flex;
					justify-content: flex-end;
					.rate-list{
						display: flex;
						flex-flow: column;
						align-items: flex-end;
						.item-rate{
							display: flex;
							align-items: center;
							margin-bottom: 12px;
							.item-title{
								width: 98px;
								font-family: PingFangSC, PingFang SC;
								font-weight: 400;
								font-size: 14px;
								color: rgba(0,0,0,0.85);
								line-height: 39px;
								text-align: right;
							}
							::v-deep .el-rate{
								height: auto;
								.el-rate__icon {
								  font-size: 30px !important;
								}
							}
						}
						.type-btn{
							width: 230px;
							height: 60px;
							background: #4077DD;
							border-radius: 8px;
							font-family: PingFangSC, PingFang SC;
							font-weight: 400;
							font-size: 16px;
							color: #FFFFFF;
							line-height: 24px;
							text-align: center;
							font-style: normal;
							display: flex;
							justify-content: center;
							align-items: center;
							position: relative;
							margin-top: 4px;
							.top-left-tabs{
								width: 75px;
								height: 32.5px;
								position: absolute;
								top: 0;
								left: 0;
							}
						}
					}
				}
				
				.right-type-box2{
					display: flex;
					justify-content: flex-end;
					.right-btn{
						width: 120px;
						height: 60px;
						border-radius: 8px;
						border: 1px solid #4077DD;
						font-family: PingFangSC, PingFang SC;
						font-weight: 400;
						font-size: 16px;
						color: #4077DD;
						line-height: 24px;
						display: flex;
						justify-content: center;
						align-items: center;
						margin-right: 8px;
					}
					::v-deep .el-dropdown{
						height: 60px;
					}
					.right-two-box{
						.type-btn{
							width: 230px;
							height: 60px;
							background: #4077DD;
							border-radius: 8px;
							font-family: PingFangSC, PingFang SC;
							font-weight: 400;
							font-size: 16px;
							color: #FFFFFF;
							line-height: 24px;
							text-align: center;
							font-style: normal;
							display: flex;
							justify-content: center;
							align-items: center;
							position: relative;
							.top-left-tabs{
								width: 75px;
								height: 32.5px;
								position: absolute;
								top: 0;
								left: 0;
							}
						}
						.type-default{
							background-color: #e7e7e7;
							border-color: #e7e7e7;
						}
						.type-number{
							margin-top: 12px;
							width: 230px;
							display: flex;
							align-items: center;
							justify-content: center;
							img{
								width: 20px;
								height: 20px;
							}
							span{
								font-family: PingFangSC, PingFang SC;
								font-weight: 400;
								font-size: 16px;
								color: #FF4D4F;
								line-height: 21px;
								text-align: left;
								font-style: normal;
								margin-left: 8px;
							}
						}
					}
				}
				
			}
		}
		
	}
	.hall-box{
		display: flex;
		justify-content: space-between;
		margin-top: 64px;
		margin-bottom: 32px;
		.left{
			width: calc(100% - 346px);
			.left-box-1{
				.left-title{
					font-family: PingFangSC, PingFang SC;
					font-weight: 400;
					font-size: 16px;
					color: rgba(0,0,0,0.45);
					line-height: 21px;
					text-align: left;
					font-style: normal;
					margin-top: 24px;
				}
				.btLines{
					margin-top: 14px;
					.tag-title{
						font-family: PingFangSC, PingFang SC;
						font-weight: 400;
						font-size: 16px;
						color: rgba(0,0,0,0.85);
						line-height: 21px;
						text-align: left;
						font-style: normal;
					}
					::v-deep .el-tag{
						height: auto;
						padding: 3.34px 7.34px;
						background: #F5F7FA;
						border-radius: 4px;
						font-family: PingFangSC, PingFang SC;
						font-weight: 400;
						font-size: 14px;
						color: #555C70;
						line-height: 20px;
						text-align: left;
						font-style: normal;
						margin-bottom: 10px;
						margin-right: 8px;
					}
				}
				.miaoshu{
					font-family: PingFangSC, PingFang SC;
					font-weight: 400;
					font-size: 16px;
					color: rgba(0,0,0,0.85);
					line-height: 21px;
					text-align: left;
					font-style: normal;
					margin-top: 12px;
				}
			}
			.left-box-2{
				.left-list{
					margin-top: 24px;
					.item{
						img{
							width: 72px;
							height: 68px;
						}
						.item-title{
							width: 96px;
							height: 21px;
							font-family: PingFangSC, PingFang SC;
							font-weight: 400;
							font-size: 16px;
							color: rgba(0,0,0,0.85);
							line-height: 21px;
							text-align: left;
							font-style: normal;
							margin-top: 12px;
						}
					}
				}
			}
			.left-box-3{
				.item-invites{
					justify-content: space-between;
					font-family: PingFangSC, PingFang SC;
					font-weight: 400;
					color: rgba(0,0,0,0.85);
					margin-top: 24px;
					.left{
						width: calc(100% - 150px);
						.user-invites{
							margin-left: 36px;
							.invits-top{
								.username{
									font-size: 16px;
									line-height: 21px;
								}
								.userTag{
									padding: 0px 3.34px;
									border-radius: 4px;
									border: 1px solid #4077DD;
									font-size: 12px;
									color: #4077DD;
									line-height: 21px;
									margin-left: 8px;
								}
								::v-deep .el-rate{
									margin-left: 24px;
								}
							}
							.invites-sn{
								font-size: 14px;
								margin-top: 12px;
							}
							.invites-tags{
								margin-top: 6px;
								line-height: 30px;
								span{
									width: 89px;
									height: 22px;
									background: #F5F7FA;
									border-radius: 4px;
									font-size: 12px;
									color: #555C70;
									line-height: 20px;
									margin-right: 8px;
									padding: 3px 8px;
								}
							}
							.invites-fen{
								margin-top: 12px;
								.invites_lines{
									margin-right: 24px;
									.numbers{
										font-size: 14px;
									}
									.text{
										font-size: 12px;
									}
								}
							}
						}
					}
					.right{
						.btn-default{
							border-radius: 6px;
							border: 1px solid #4077DD;
							background: #FFFFFF;
							font-family: PingFangSC, PingFang SC;
							font-weight: 400;
							font-size: 16px;
							color: #4077DD;
							line-height: 24px;
						}
						.btn-error{
							border-radius: 6px;
							border: 1px solid #FF4D4F;
							background: #FFFFFF;
							font-family: PingFangSC, PingFang SC;
							font-weight: 400;
							font-size: 16px;
							color: #FF4D4F;
							line-height: 24px;
						}
					}
				}
			}
			.left-box-4{
				min-height: 211px;
				background: #FFFFFF;
				border-radius: 0px 0px 8px 8px;
				border: 1px solid #E8E8E8;
				.jd-item{
					.jd-top{
						height: 68px;
						background: #F5FAFF;
						border-radius: 8px 8px 0px 0px;
						border: 1px solid #E8E8E8;
						padding: 0 24px;
						display: flex;
						justify-content: space-between;
						align-items: center;
						span{
							font-family: PingFangSC, PingFang SC;
							font-weight: 500;
							font-size: 16px;
							color: rgba(0,0,0,0.85);
							line-height: 21px;
						}
					}
					.jd-cont{
						padding:24px;
						.cont-item{
							display: flex;
							.number{
								width: 24px;
								height: 24px;
								background: #1677FF;
								border-radius: 12px;
								font-family: PingFangSC, PingFang SC;
								font-weight: 400;
								font-size: 14px;
								color: #FFFFFF;
								line-height: 22px;
								display: flex;
								justify-content: center;
								align-items: center;
								position: relative;
							}
							.number2{
								background: rgba(0,0,0,0.06);
								color: rgba(0,0,0,0.45);
							}
							.number3{
								background: rgba(255,77,79,0.1);
								color: #FF4D4F;
							}
							.number4{
								background: #E6F4FF;
								color: #1677FF;
								&::after{
									box-sizing: content-box;
									content: "";
									border: 1px solid #1677FF;
									border-left: 0;
									border-top: 0;
									height: 7px;
									left: 10px;
									position: absolute;
									top: 7px;
									transform: rotate(45deg) scaleY(1);
									width: 3px;
									transition: transform .15s ease-in 50ms;
									transform-origin: center;
								}
							}
							
							
							.title{
								font-family: PingFangSC, PingFang SC;
								font-weight: 500;
								font-size: 16px;
								color: rgba(0,0,0,0.85);
								line-height: 24px;
								margin-left: 16px;
							}
							.info{
								font-family: PingFangSC, PingFang SC;
								font-weight: 400;
								font-size: 14px;
								color: rgba(0,0,0,0.85);
								line-height: 24px;
								margin-left: 16px;
							}
							.backColor{
								color: rgba(0,0,0,0.25);
							}
						}
						.shuxian{
							width: 1px;
							height: 36px;
							background: rgba(0,0,0,0.15);
							margin-left: 12px;
							margin-top: 4px;
							margin-bottom: 4px;
						}
					}
					
				}
			}
			
		}
		.right{
			.right-box-1{
				width: 300px;
				min-height: 241px;
				background: #FFFFFF;
				border-radius: 8px;
				border: 1px solid #E8E8E8;
				padding-bottom: 24px;
				.right-title{
					font-family: PingFangSC, PingFang SC;
					font-weight: 600;
					font-size: 16px;
					color: rgba(0,0,0,0.85);
					line-height: 21px;
					text-align: left;
					font-style: normal;
					margin-top: 24px;
					padding: 0 24px;
				}
				.right-user{
					display: flex;
					align-items: center;
					margin-top: 24px;
					padding: 0 24px;
					::v-deep .el-image{
						img{
							width: 42px;
							height: 42px;
							border-radius: 50%;
						}
					}
					.right-uinfo{
						margin-left: 16px;
						.name{
							font-family: PingFangSC, PingFang SC;
							font-weight: 400;
							font-size: 16px;
							color: rgba(0,0,0,0.85);
							line-height: 21px;
							text-align: left;
							font-style: normal;
						}
						.rStatis{
							width: 64px;
							height: 28px;
							border-radius: 4px;
							margin-top: 4px;
							font-family: HYk1gj;
							font-size: 12px;
							color: #FFFFFF;
							line-height: 20px;
							display: flex;
							justify-content: center;
							align-items: center;
						}
						.right-wrz{
							background: linear-gradient(180deg, #e2e2e2 2%, #eceae9 100%);
							box-shadow: 0px 0px 4px 0px rgb(225 221 217 / 59%);
						}
						.right-grrz{
							background: linear-gradient( 180deg, #0D9AF6 0%, #267CFC 100%);
							box-shadow: 0px 0px 4px 0px rgba(43,104,255,0.59);
						}
						.right-qyrz{
							background: linear-gradient( 180deg, #F6A00D 2%, #FC8526 100%);
							box-shadow: 0px 0px 4px 0px rgba(255,140,43,0.59);
						}
					}
				}
				.right-phone{
					font-family: PingFangSC, PingFang SC;
					font-weight: 400;
					font-size: 16px;
					color: rgba(0,0,0,0.85);
					line-height: 21px;
					text-align: left;
					font-style: normal;
					margin-top: 24px;
					padding: 0 24px;
					white-space: normal;
					word-break: break-all;
				}
				.right-time{
					font-family: PingFangSC, PingFang SC;
					font-weight: 400;
					font-size: 16px;
					color: rgba(0,0,0,0.45);
					line-height: 21px;
					text-align: left;
					font-style: normal;
					margin-top: 24px;
					padding: 0 24px;
				}
			}
			.right-box-2{
				width: 300px;
				min-height: 156px;
				background: #FFFFFF;
				border-radius: 8px;
				border: 1px solid #E8E8E8;
				margin-top: 24px;
				padding-bottom: 24px;
			}
			.right-box-guo{
				width: 300px;
				height: 259px;
				background: #FFFFFF;
				border-radius: 8px;
				border: 1px solid #E8E8E8;
				margin-bottom: 24px;
				.top-guo{
					padding: 0 24px;
					justify-content: space-between;
					align-items: center;
					margin-top: 24px;
					.name{
						font-family: PingFangSC, PingFang SC;
						font-weight: 500;
						font-size: 16px;
						color: rgba(0,0,0,0.85);
						line-height: 21px;
						text-align: left;
						font-style: normal;
					}
					.jieguoBtn{
						font-family: PingFangSC, PingFang SC;
						font-weight: 400;
						font-size: 16px;
						color: #4077DD;
						line-height: 36px;
						text-align: right;
						font-style: normal;
					}
				}
				.times{
					padding: 0 24px;
					font-family: PingFangSC, PingFang SC;
					font-weight: 400;
					font-size: 12px;
					color: rgba(0,0,0,0.45);
					line-height: 17px;
					text-align: left;
					font-style: normal;
					margin-top: 12px;
				}
				img{
					width: 58px;
					height: 58px;
					margin: auto;
					display: flex;
					margin-top: 29px;
				}
				.title{
					padding: 0 24px;
					font-family: PingFangSC, PingFang SC;
					font-weight: 400;
					font-size: 16px;
					color: rgba(0,0,0,0.85);
					line-height: 24px;
					text-align: center;
					font-style: normal;
					margin-top: 3px;
				}
			}
			.right-box-apply{
				width: 300px;
				min-height: 224px;
				background: #FFFFFF;
				border-radius: 8px;
				border: 1px solid #E8E8E8;
				margin-bottom: 24px;
				.apply-title{
					padding: 24px 24px 0;
					font-family: PingFangSC, PingFang SC;
					font-weight: 600;
					font-size: 16px;
					color: rgba(0,0,0,0.85);
					line-height: 21px;
				}
				.apply-info{
					padding:  0 24px;
					font-family: PingFangSC, PingFang SC;
					font-weight: 400;
					font-size: 16px;
					color: rgba(0,0,0,0.85);
					line-height: 21px;
					margin-top: 24px;
				}
				.apply-cont{
					padding:  0 24px;
					font-family: PingFangSC, PingFang SC;
					font-weight: 400;
					font-size: 16px;
					color: rgba(0,0,0,0.85);
					line-height: 21px;
					margin-top: 12px;
					white-space: normal;
					word-break: break-all;
				}
				.apply-memver{
					padding:  0 24px;
					font-family: PingFangSC, PingFang SC;
					font-weight: 400;
					font-size: 16px;
					color: #FF4D4F;
					line-height: 21px;
					margin-top: 12px;
				}
				.apply-btn{
					width: 252px;
					height: 32px;
					background: #4077DD;
					border-radius: 8px;
					font-family: PingFangSC, PingFang SC;
					font-weight: 400;
					font-size: 16px;
					color: #FFFFFF;
					display: flex;
					justify-content: center;
					align-items: center;
					margin: 12px auto;
				}
			}
		}
	}
	
	
	.tipPopup{
		::v-deep .el-dialog{
			box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.1), 0px 0px 5px 0px rgba(0,0,0,0.14);
			border-radius: 10px;
			.el-dialog__header{
				padding: 20px 36px 0 28px;
				.el-dialog__title{
					font-family: PingFangSC, PingFang SC;
					font-weight: 500;
					font-size: 18px;
					color: rgba(0,0,0,0.85);
					line-height: 25px;
					text-align: left;
					font-style: normal;
				}
			}
			.dialog-bom{
				.el-button{
					width: 96px;
					height: 40px;
					background: #4077DD;
					border-radius: 8px;
					font-family: PingFangSC, PingFang SC;
					font-weight: 400;
					font-size: 16px;
					color: #FFFFFF;
					line-height: 24px;
					font-style: normal;
					display: flex;
					justify-content: center;
					align-items: center;
				}
				.close{
					background: #FFFFFF;
					border-radius: 6px;
					border: 1px solid #4077DD;
					color: #4077DD;
				}
			}
			.fp-left{
				font-family: PingFangSC, PingFang SC;
				font-weight: 500;
				font-size: 16px;
				color: rgba(0,0,0,0.85);
				line-height: 21px;
				text-align: left;
				font-style: normal;
				margin-bottom: 12px;
			}
		}
	}
	
	.look-box{
		::v-deep .el-dialog{
			width: 700px;
			// min-height: 893px;
			background: #FFFFFF;
			box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.1), 0px 0px 5px 0px rgba(0,0,0,0.14);
			border-radius: 10px;
			padding: 20px 32px 32px;
			.el-dialog__header{
				padding: 0;
			}
			.el-dialog__body{
				padding: 0;	
			}
		}
		.title{
			font-family: PingFangSC, PingFang SC;
			font-weight: 500;
			font-size: 18px;
			color: #FF4D4F;
			line-height: 36px;
			text-align: left;
			font-style: normal;
		}
		.look-title{
			font-family: PingFangSC, PingFang SC;
			font-weight: 500;
			font-size: 18px;
			color: rgba(0,0,0,0.85);
			line-height: 36px;
			text-align: left;
			font-style: normal;
			margin-bottom: 8px;
		}
		.look-bbx{
			font-family: PingFangSC, PingFang SC;
			font-weight: 500;
			font-size: 18px;
			color: rgba(0,0,0,0.85);
			line-height: 36px;
			text-align: left;
			font-style: normal;
			.m-flex{
				justify-content: space-between;
				position: relative;
				img{
					width: 14px;
					height: 14px;
				}
			}
			.lines-next{
				width: 100%;
				height: 1px;
				background-color: #E8E8E8;
				margin-top: 17px;
				margin-bottom: 9px;
			}
			.color1{
				color: rgba(0,0,0,0.85);
			}
			.color2{
				color: #FF4D4F;
			}
			.color3{
				color: #4077DD;
			}
			
		}
	}
	
	.ts-dbox{
		.ts-title{
			font-family: PingFangSC, PingFang SC;
			font-weight: 500;
			font-size: 18px;
			color: rgba(0,0,0,0.85);
			line-height: 39px;
			text-align: left;
			font-style: normal;
		}
		.ts-info{
			font-family: PingFangSC, PingFang SC;
			font-weight: 400;
			font-size: 16px;
			color: rgba(0,0,0,0.45);
			line-height: 22px;
			margin-top: 14px;
		}
		.ts-content{
			font-family: PingFangSC, PingFang SC;
			font-weight: 400;
			font-size: 16px;
			color: rgba(0,0,0,0.85);
			line-height: 22px;
			margin-top: 16px;
		}
	}
	
	.project-jz{
		min-height: 250px;
		.jz-list{
			margin-top: 24px;
			.item{
				display: flex;
				justify-content: space-between;
				align-items: center;
				border-bottom: 1px solid #F5F7FA;
				height: 40px;
				.title{
					font-family: PingFangSC, PingFang SC;
					font-weight: 400;
					font-size: 16px;
					color: rgba(0,0,0,0.85);
					line-height: 21px;
					text-align: left;
					font-style: normal;
				}
				.times{
					font-family: PingFangSC, PingFang SC;
					font-weight: 400;
					font-size: 16px;
					color: rgba(0,0,0,0.85);
					line-height: 21px;
					text-align: left;
					font-style: normal;
				}
			}
		}
	}
	
	
	.left-box-invites{
		width: 100%;
		height: 555px;
		overflow-y: scroll;
		overflow-x: hidden;
		.item-invites{
			justify-content: space-between;
			font-family: PingFangSC, PingFang SC;
			font-weight: 400;
			color: rgba(0,0,0,0.85);
			margin-top: 24px;
			.left{
				width: calc(100% - 150px);
				.user-invites{
					margin-left: 36px;
					.invits-top{
						.username{
							font-size: 16px;
							line-height: 21px;
						}
						.userTag{
							padding: 0px 3.34px;
							border-radius: 4px;
							border: 1px solid #4077DD;
							font-size: 12px;
							color: #4077DD;
							line-height: 21px;
							margin-left: 8px;
						}
						::v-deep .el-rate{
							margin-left: 24px;
						}
					}
					.invites-sn{
						font-size: 14px;
						margin-top: 12px;
					}
					.invites-tags{
						margin-top: 6px;
						line-height: 30px;
						span{
							width: 89px;
							height: 22px;
							background: #F5F7FA;
							border-radius: 4px;
							font-size: 12px;
							color: #555C70;
							line-height: 20px;
							margin-right: 8px;
							padding: 3px 8px;
						}
					}
					.invites-fen{
						margin-top: 12px;
						.invites_lines{
							margin-right: 24px;
							.numbers{
								font-size: 14px;
							}
							.text{
								font-size: 12px;
							}
						}
					}
				}
			}
			.right{
				padding-right: 10px;
			}
		}
	}
	
		
</style>
