<template>
  <div class="boxCenter">
    <!-- <div class="topNavs m-flex m-flex-bettween">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }">项目大厅</el-breadcrumb-item>
        <el-breadcrumb-item>项目详情</el-breadcrumb-item>
      </el-breadcrumb>
      <div class="project-sn">
        <span>项目编号：</span><span>{{ projectInfo.sn || '--' }}</span>
      </div>
    </div> -->
	
	<div class="hall-top">
		<div class="left">
			<div class="topSn">项目编号：{{ projectInfo.sn || '--' }}</div>
			<div class="topNames line2 wzk-flex-1">
				<span v-if="projectInfo.status == 3" class="topState on-sign-up">报名中</span>
				<span v-if="projectInfo.status == 4" class="topState on-conduct">项目进行中</span>
				<span v-if="projectInfo.status == 5" class="topState on-success">圆满完成</span>
				<span v-if="projectInfo.status == 9" class="topState on-completed">已取消</span>
				<span>{{ projectInfo.regionFy }}/{{ projectInfo.typeFy }}</span>
				<template v-if="projectInfo.majorFyOne.length">
					<span v-for="(row, index) in projectInfo.majorFyOne" :key="index">/{{ row.name }}</span>
				</template>
				<span>/总投资:￥{{ projectInfo.totalInvestment || '00' }}</span>
				<span>/总规模:{{ projectInfo.totalScale }}m²</span>
				<span>/{{ projectInfo.serviceContentFy }}</span>
			</div>
			<div class="topTagsBox">
				<span class="tags">{{ projectInfo.regionFy }}</span>
				<span class="tags" v-if="projectInfo.necessary == 1">需要出现场</span>
				<span class="tags" v-if="projectInfo.provided == 1">提供CAD图纸</span>
				<span class="tags">{{ projectInfo.typeFy }}</span>
				<template v-if="projectInfo.majorFy.length">
					<span class="tags" v-for="(row, index) in projectInfo.majorFy" :key="index + 'w'">{{ row.name }}</span>
				</template>
				<span class="tags" v-if="projectInfo.serviceContentFy">{{ projectInfo.serviceContentFy }}</span>
				<span class="tags" v-for="(row, index) in projectInfo.softwareFy" :key="index + 'e'">{{ row.label }}</span>
			</div>
			<div class="topPrice">
				<span v-if="projectInfo.typeAB == 'A'" class="prices">
				  总规模：{{ projectInfo.totalScale }}m²
				</span>
				<span v-if="projectInfo.typeAB == 'B'" class="prices">
				  总投资：￥{{ projectInfo.totalInvestment }}
				</span>
				<span class="prices w-m-l-30" v-if="changeType!=1">
				  工程师收益：￥{{ projectInfo.earningsAmount }}
				</span>
				<span class="prices w-m-l-30" v-if="projectInfo.profitType == 2">
				  固定总价
				</span>
			</div>
			<div class="topTime">
				 交付时间：{{ projectInfo.deliveryDeadline }}
			</div>
		</div>
		<div class="right">
			<!--项目是自己的-->
			<div v-if="projectInfo.fbMid == userInfo.id">
				<!--自己的项目报名中-->
				<div v-if="projectInfo.status == 3">
					<div class="right-type-box2">
						<!-- <div class="right-btn cursor">取消</div> -->
						<div class="right-two-box" @click="inviteShow = true">
							<div class="type-btn cursor">
								<span>邀请工程师</span>
							</div>
							<div class="type-number">
								<img src="../../../static/images/project/hot@2x.png" />
								<span>{{projectInfo.signUpNum || '0'}} 个工程师已报名</span>
							</div>
						</div>
					</div>
				</div>
			</div>
			<!--项目是别人的-->
			<div v-else>
				<!--普通人员-->
				<div class="" v-if="userInfo.identity==1"> 
					<!--报名中-->
					<div v-if="projectInfo.status == 3">
						 <div class="right-type-box1">
						 	<div class="type-btn cursor">
						 		<img src="../../../static/images/project/huo@2x.png" />
						 		<span>火热报名中</span>
						 	</div>
						 	<div class="type-number"> {{projectInfo.signUpNum || '0'}} 个工程师已报名</div>
						 </div>
					 </div>
					 <!--已完成-->
					<!-- <div v-if="projectInfo.status == 5">
					 	 <div class="right-rate-box1">
					 	 	<div class="rate-list">
								<div class="item-rate">
								   <div class="item-title">服务态度：</div>
								   <el-rate v-model="value" disabled disabled-void-color="#D8D8D8"></el-rate>
								</div>
								<div class="item-rate">
									<div class="item-title">工作质量：</div>
									<el-rate v-model="value" disabled disabled-void-color="#D8D8D8"></el-rate>
								</div>
							</div>
					 	 </div>
					  </div> -->
				</div>
				<!--已注册工程师-->
				<div class="" v-else-if="userInfo.identity==2">
					<div class="right-type-box2" v-if="projectInfo.status == 3 || projectInfo.status == 4">
						<div class="right-two-box">
							<div class="type-btn cursor" v-if="fgStatus == 0 && projectInfo.status == 3" @click='puopShowTap()'>
								<span>立即报名</span>
							</div>
							<div class="type-btn type-default" v-else>
								<span>立即报名</span>
							</div>
							<div class="type-number">
								<img src="../../../static/images/project/hot@2x.png" />
								<span>{{projectInfo.signUpNum || '0'}} 个工程师已报名</span>
							</div>
						</div>
					</div>
				</div>
				
			</div>
			
			
		</div>
	</div>
	
	<div class="hall-box">
		<div class="left">
			<div class="lines-title w-m-t-0">
				<span>项目详情</span>
				<div class="hengxian"></div>
			</div>
			<div class="left-box-1">
				<div class="left-title">项目基本信息</div>
				<div class="btLines m-flex">
					<div class="tag-title" style="width: 80px;">项目类型：</div>
					<div style="width: calc(100% - 80px);">
						<el-tag type="info" class="tags">{{ projectInfo.typeFy }}</el-tag>
					</div>
				</div>
				<div class="btLines w-m-t-2 m-flex">
				    <div class="tag-title" style="width: 80px;">所含专业：</div>
					<div style="width: calc(100% - 80px);">
						<el-tag type="info" class="tags" v-for="(item, index) in projectInfo.majorFy" :key="index + 'm'">{{ item.name }}</el-tag>
					</div>
				</div>
				<div class="btLines w-m-t-2 m-flex">
				    <div class="tag-title" style="width: 80px;">服务内容：</div>
					<div style="width: calc(100% - 80px);">
						<el-tag type="info" class="tags">{{ projectInfo.serviceContentFy }}</el-tag>
					</div>
				</div>
				<div class="btLines w-m-t-2 m-flex">
				    <div class="tag-title" style="width: 80px;">要求软件：</div>
					<div style="width: calc(100% - 80px);">
						<el-tag type="info" class="tags" v-for="(item, index) in projectInfo.softwareFy" :key="index + 's'">
							{{ item.label }}
						</el-tag>
					</div>
				</div>
				<div class="btLines w-m-t-2">
				    <span class="tag-title">总规模：{{ projectInfo.totalScale }}m²</span>
				</div>
				<div class="btLines">
				    <span class="tag-title">总占地面积：{{ projectInfo.totalArea }}m²</span>
				</div>
				<div class="left-title">项目描述</div>
				<div class="miaoshu">
				    {{ projectInfo.remark || '无' }}
				</div>
			</div>
			<div class="lines-title">
				<span>附件资料</span>
				<div class="hengxian"></div>
			</div>
			<div class="left-box-2" v-if="projectInfo.files.length > 0">
				<div class="left-list mdx-flex mdx-row-around mdx-flex-wrap">
					<div class="item" v-for="(item) in projectInfo.files" :key='item.id'>
						<img src="../../../static/images/project/project@2x.png" />
						<div class="item-title line1">
						  {{ item.name }}
						</div>
						<template v-if="changeType == 1 && changeTypeShow">
						  <el-button type="primary" size="mini" @click="viewCad(item)" v-if="item.name.includes('dwg')">查看</el-button>
						</template>
						<template v-if="changeType == 2 || changeType == 3">
						  <el-button type="primary" size="mini" @click="viewCad(item)" v-if="item.name.includes('dwg')">查看</el-button>
						</template>
						<el-button type="info" class="downFile w-m-t-8" size="mini" :loading="loading" @click="downloadFile(item)">下载资料</el-button>
					</div>
					<template v-if="projectInfo.files % 6 != 0">
					  <div v-for="(item, index) in (6 - (projectInfo.files.length % 6))" :key="index + 'p'">
					  </div>
					</template>
				</div>
			</div>
			
			<div class="lines-title">
				<span>报名人员</span>
				<div class="hengxian"></div>
			</div>
			<div class="left-box-3">
				<div class="item-invites m-flex" @click="toDetails(1)" v-for="(item, index) in bmList" :key='index'>
					<div class="left m-flex">
						<WzkAvatar :isGender="true" :gender="item.gender || 1" :imgSrc="item.headImgUrl" waryWidth="70" waryHeight="70" width="70" height="70" borderRadius="35"></WzkAvatar>
						<div class="user-invites">
							<div class="invits-top m-flex">
								<span class="username">{{ $mHelper.nameSlice(2, userInfo, item.name, item.name) }}</span>
								<span class="w-m-l-12">{{ item.engineerNo || '' }}</span>
								<el-rate v-model="item.rateValue" disabled disabled-void-color="#D8D8D8"></el-rate>
							</div>
							<div class="invites-sn">
								<span>综合分：<span style="color: #4077DD;">{{ item.synthesize || '' }}</span></span>
							</div>
							<div class="invites-tags">
								<span>精通{{item.majorFy.length || '0'}}个专业</span>
								<span>精通{{item.softwareFy.length || '0'}}个专业软件</span>
								<span>精通{{item.typeFy.length || '0'}}个项目类型</span>
								<span>擅长{{item.regionFy.length || '0'}}个地区</span>
								<span>{{item.years || '0'}}年工作经验</span>
								<span v-if="item.files!=null">有专业证书</span>
							</div>
							<!-- <div class="invites-fen m-flex">
								<div class="invites_lines">
									<div class="numbers">{{ item.favorable }}</div>
									<div class="text">好评率</div>
								</div>
								<div class="invites_lines">
									<div class="numbers">{{ item.completed || 0 }}</div>
									<div class="text">已完成</div>
								</div>
								<div class="invites_lines">
									<div class="numbers">{{ item.timeliness }}</div>
									<div class="text">及时率</div>
								</div>
								<div class="invites_lines">
									<div class="numbers" v-if="item.excellent">{{item.excellent}}%</div>
									<div class="numbers" v-else>--</div>
									<div class="text">优秀率</div>
								</div>
								<div class="invites_lines">
									<div class="numbers">{{ item.reputation }}</div>
									<div class="text">信誉值</div>
								</div>
							</div> -->
						</div>
					</div>
					
				</div>
			</div>
			
			
		</div>
		<div class="right">
			<div class="right-box-1" >
				<div class="right-title">发布者</div>
				<div class="right-user">
					<div class="block">
						<el-image class='image-slot' :src="projectInfo.customerInfo.headImgUrl">
							<div slot="error" class="image-slot">
								<img src="../../../static/images/mrtx.png" style="width: 80px;height: 80px;border-radius: 5px;">
							</div>
						</el-image>
					</div>
					<div class="right-uinfo">
						<div class="name">{{ $mHelper.nameSlice(1, userInfo, projectInfo.customerInfo.nickname, projectInfo.customerInfo.name)}}</div>
						<div class="rStatis right-wrz" v-if="projectInfo.customerInfo.certification==2 || projectInfo.customerInfo.certification==null">未认证</div>
						<div class="rStatis right-grrz" v-else-if="projectInfo.customerInfo.certification==1">个人认证</div>
						<div class="rStatis right-qyrz" v-else>企业认证</div>
					</div>
				</div>
				<div class="right-phone" >电话： {{  $mHelper.mobileSlice(userInfo.identity, projectInfo.customerInfo.mobile) }}</div>
				<div class="right-phone" v-if="projectInfo.customerInfo.qq">QQ： {{ $mHelper.qqSlice(userInfo.identity, projectInfo.customerInfo.qq) }}</div>
				<div class="right-phone" v-if="projectInfo.customerInfo.mailbox">邮箱： {{ $mHelper.emailSlice(userInfo.identity, projectInfo.customerInfo.mailbox) }}</div>
				<div class="right-time">发布时间：{{projectInfo.createTime}}</div>
			</div>
			<div class="right-box-1 right-box-2" v-if="projectInfo.memberInfo !=null && projectInfo.memberInfo.id !=null">
				<div class="right-title">项目经理</div>
				<div class="right-user">
					<div class="block">
						<el-image class='image-slot' :src="projectInfo.memberInfo.headImgUrl">
							<div slot="error" class="image-slot">
								<img src="../../../static/images/mrtx.png" style="width: 80px;height: 80px;border-radius: 5px;">
							</div>
						</el-image>
					</div>
					<div class="right-uinfo">
						<div class="name">{{ $mHelper.nameSlice(3, userInfo, projectInfo.memberInfo.nickname,projectInfo.memberInfo.name)}}</div>
					</div>
				</div>
				<div class="right-phone">电话： {{ $mHelper.mobileSlice(userInfo.identity, projectInfo.memberInfo.mobile) }}</div>
				<div class="right-phone" v-if="projectInfo.memberInfo.qq">QQ： {{ $mHelper.qqSlice(userInfo.identity, projectInfo.memberInfo.qq) }}</div>
				<div class="right-phone" v-if="projectInfo.memberInfo.mailbox">邮箱： {{ $mHelper.emailSlice(userInfo.identity, projectInfo.memberInfo.mailbox) }}</div>
			</div>
			<div class="right-box-1 right-box-2" v-if="projectInfo.engineerInfo !=null && projectInfo.engineerInfo.id !=null">
				<div class="right-title">工程师</div>
				<div class="right-user">
					<div class="block">
						<el-image class='image-slot' :src="projectInfo.engineerInfo.headImgUrl">
							<div slot="error" class="image-slot">
								<img src="../../../static/images/mrtx.png" style="width: 80px;height: 80px;border-radius: 5px;">
							</div>
						</el-image>
					</div>
					<div class="right-uinfo">
						<div class="name">{{ $mHelper.nameSlice(2, userInfo, projectInfo.engineerInfo.nickname, projectInfo.engineerInfo.name) }}</div>
					</div>
				</div>
				<div class="right-phone">电话： {{ $mHelper.mobileSlice(userInfo.identity, projectInfo.engineerInfo.mobile) }}</div>
				<div class="right-phone" v-if="projectInfo.engineerInfo.qq">QQ： {{ $mHelper.qqSlice(userInfo.identity, projectInfo.engineerInfo.qq) }}</div>
				<div class="right-phone" v-if="projectInfo.engineerInfo.mailbox">邮箱： {{ $mHelper.emailSlice(userInfo.identity, projectInfo.engineerInfo.mailbox) }}</div>
			</div>
		</div>
	</div>
	
	<el-dialog class="tipPopup" title="申请确认" :visible.sync="puopShow" width="40%" :before-close="handleClose" center top='20vh' :show-close="true">
	  <div class=" ">
		  <div class="title">需在此时间前交付项目成果，请确认您可以完成工作。</div>
		  <div class="title" style="color: #FF4D4F;">项目交付时间：{{projectInfo.engineerDeadline||''}}</div>
		  <div class="title w-m-t-12">工程师收益：{{projectInfo.earningsAmount||'0.00'}}元</div>
		  <div class="title" v-if="userInfo.tryState==1"> 缴纳质保金：1元 </div>
		  <div class="title" v-else> 缴纳质保金：{{projectInfo.depositAmount||'0.00'}}元 </div>
			<!-- <div>
			  1、须在此时间前交付项目成果，点击“立即申请”表明您有意愿、有时间、有能力完成本项目。<br />
			  2、点击“立即申请”也表明您接受本项目的质量要求和平台作业质量规范并拥有要求的软件。<br />
			  3、点击“立即申请”也表明您同意对后续的资料进行保密。平台项目经理将根据系统匹配推荐依次向成功申请的工程师开放资料查阅权限，以便于工程师评估、接单。<br />
			  4、如您在查阅资料、支付质保金接单后放弃项目，将扣除您的项目质保金并限制您接单60天，同时将影响您的信誉值。<br />
			</div> -->
			<div class="info w-m-t-14">
			  收益为暂估收益，最终收益将在您计算出工程成果后确定
			</div>
			<div class="info">
			  质保金比例为您收益的10%，项目完成后2个月内如无质保问题，您的质保金将退还至您的账户
			</div>
			<div class="info">
			  如您在接单后取消接单或未完成工作，将扣除您的项目质保金并限制您接单2个月，同时将影响您的信誉值！
			</div>
			<div class="info">
			  点击确认申请表明您同意对后续的资料进行保密，并接受项目质量要求
			</div>
			<span slot="footer" class="dialog-bom mdx-flex mdx-row-center w-m-t-20">
				<el-button class="close" size="small" @click="puopShow=false">取 消</el-button>
			   <el-button :loading="loading" type="primary" class=""  @click='sqBtn()'> 确认申请</el-button>
			</span>
	  </div>
	</el-dialog>
	
	<el-dialog class="tipPopup" title="邀请工程师申请此项目" :visible.sync="inviteShow" show-cancel-button width="40%" center top="20vh">
	  <div class="puopContext w-m-t-30 w-font-16">
	    <div class="mdx-flex">
	      <div class="">工程师编号</div>
	      <div class="wzk-flex-1 w-m-l-20">
	        <el-input style="margin-top: 0" type="text" placeholder="请输入工程师编号" v-model.trim="engineerNo"></el-input>
	      </div>
	    </div>
	    <div class="mdx-flex mdx-row-center w-m-t-30">
	      <el-button type="primary" style="padding: 10px 40px; border-radius: 2px" class="" @click.stop="inviteTap()">
	        邀请
	      </el-button>
	    </div>
	  </div>
	</el-dialog>
	
	<!--实名认证-->
	<el-dialog title="" :visible.sync="certificationShow" width="50%" center class="fb-tip cert-tip">
		<img style="width: 95px; height: 95px;" src="../../../static/images/home/cert@2x.png" />
		<div style="font-family: PingFangSC, PingFang SC;font-weight: 500;font-size: 18px;color: rgba(0,0,0,0.85);line-height: 36px;margin-top: 39px;">您当前未进行实名认证，请先完成实名认证</div>
		<span slot="footer" class="dialog-footer">
			<el-button class="footer-btn-left" @click="certificationShow=false">暂不认证</el-button>
			<el-button type="primary footer-btn-right" @click="goTocert">立即认证</el-button>
		</span>
	</el-dialog>
	
	<el-dialog class="tipPopup" title="接单提示" :visible.sync="showjdMore" width="40%" center top='20vh' :show-close="true">
	  <div class=" ">
		  <div class="title">请注意，进行中项目较多，建议先完成进行中的项目，再申请新项目，否则，如果由于项目过多导致成果质量问题、交付延期等异常情况，平台将进行严肃处理。</div>
		  <div class="title w-m-t-14">为保证您的口碑和权益，请谨慎申请！ </div>
			<span slot="footer" class="dialog-bom mdx-flex mdx-row-center w-m-t-120">
				<el-button class="close" size="small" @click="showjdMore=false">暂不接单</el-button>
			   <el-button type="primary" class=""  @click='jdBtn()'>继续接单</el-button>
			</span>
	  </div>
	</el-dialog>

    <el-dialog class="tipPopup" title="提示" :visible.sync="dialogVisible"  width="20%" center>
        <span>是否使用试单模式？</span>
        <span slot="footer" class="dialog-footer">
    	<span slot="footer" class="dialog-bom mdx-flex mdx-row-center w-m-t-20">
    		<el-button class="close" size="small" @click="confirmStatusChangeno">不使用</el-button>
    	   <el-button  type="primary" @click='confirmStatusChange'>确认使用</el-button>
    	</span>
      </span>
    </el-dialog>
    <alertToast ref="alert"></alertToast>
    <el-dialog
      v-if="cadShow"
      :visible.sync="cadShow"
      width="80%"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :before-close="() => cadShow = false">
      <div id='map-container' v-loading="cadLoading"
        element-loading-text="拼命加载中"
        element-loading-spinner="el-icon-loading"
        element-loading-background="rgba(255, 255, 255, 0.8)">
      </div>
    </el-dialog>
  </div>
</template>

<script>
import WzkAvatar from '../../../components/wzk-Avatar/index.vue';
import alertToast from '../../../components/alertToast/index.vue'
import { getCustomType, initNumberValue } from "@/common/utils/num-validate.js"
import vjmap from 'vjmap'; 
import 'vjmap/dist/vjmap.min.css'
import alert from "element-ui/packages/alert"; // 样式一定要引用！！！
export default {
  components: {
    alertToast,
	WzkAvatar
  },
  data() {
    return {
      titleShow: false,
      puopShow: false,
      dialogVisible: false,
      projectInfo: {
        majorFyOne: [],
        majorFy: [],
        files: [],
      },
      Id: null,
      loading: false,
      fgStatus: 0,
      cadShow:false,
      cadLoading:false,
      changeType:2,
      changeTypeShow:false,
	  value:4.5,
	  certificationShow: false,
	  bmList: [],
	  inviteShow: false,
	  engineerNo: '',
	  usercurrent: '',
	  showjdMore: false, //接单过多提示
    }
  },
  computed: {
    userInfo() {
      return this.$store.getters['userInfo']
    }
  },

  created() {
    this.changeType = localStorage.getItem('changeType');
    // console.log('changeType1',this.changeType);
    this.Id = this.$route.query.id || null
    if (!this.Id) {
      this.$message.error('参数错误')
      return
    }
    this.getDetail();
	this.getUserCurrent();
  },
  methods: {
    async viewCad(item){
        this.cadShow = true;
        this.cadLoading = true;
        let splitArr = item.url.split('.');
        let splitArr2 = (splitArr[splitArr.length-2]).split('/');
        let filterMapid = splitArr2[splitArr2.length-1];
        // --通过网络路径打开地图--传入DWG路径的http路径打开DWG图
        // js代码
        // 新建地图服务对象，传入服务地址和token
        const env = {
            serviceUrl: "https://vjmap.com/server/api/v1",
            accessToken: "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJJRCI6MiwiVXNlcm5hbWUiOiJhZG1pbjEiLCJOaWNrTmFtZSI6ImFkbWluMSIsIkF1dGhvcml0eUlkIjoiYWRtaW4iLCJCdWZmZXJUaW1lIjo4NjQwMCwiZXhwIjoxOTQyMzg5NTc0LCJpc3MiOiJ2am1hcCIsIm5iZiI6MTYyNzAyODU3NH0.VQchVXxgjl5aCp3j3Uf5U2Mpk1NJNirH62Ys-8XOfnY",
            exampleMapId: "sys_zp"
        };
        let svc = new vjmap.Service(env.serviceUrl, env.accessToken)
        // 打开地图
        // 地图ID (如果换成了您自定义的url地址，记得修改此名称id，改成您想要的唯一的名称即可)
        const mapid = filterMapid;
        // 地图url地址，注意：url地址后四个字母必须为.dwg。如果实际中的地址四4位不是.dwg可以随便加个参数如 url?param=xxx&filetype=.dwg
        // const httpDwgUrl = "http://o2o-web.oss-cn-beijing.aliyuncs.com/Sample.dwg" ;
        // https://gczjzb-1308562443.cos.ap-nanjing.myqcloud.com/file/30481654501597257.dwg
        const httpDwgUrl = item.url;
        let res = await svc.openMap({
            // (第一次打开此地图ID时，会根据fileid去获取dwg路径打开，之后会读取缓存打开)
            mapid: mapid, // 地图ID
            fileid: httpDwgUrl,
            mapopenway: vjmap.MapOpenWay.GeomRender, // 以几何数据渲染方式打开
            style: vjmap.openMapDarkStyle() // div为深色背景颜色时，这里也传深色背景样式
        })
        
        /*
        “(第一次打开此地图ID时，会根据fileid去获取dwg路径打开，之后会读取缓存打开)” 的意思是
          举例来说，您第一次打开的图的时候用的是
        {
        mapid: 'map1',
        fileid: 'http://127.0.0.1/1.dwg'
        }
        这时候因为后台没有 map1 这个图纸，所以第一次时 map1 就会去下载 'http://127.0.0.1/1.dwg'
        打开图。
        但是，您第二次的时候如果要换成另外的图2.dwg，但是如果还是用同样的mapid的话，如
        {
        mapid: 'map1',
        fileid: 'http://127.0.0.1/2.dwg'
        }
        这时候为了性能，后台不会再去下载2.dwg了，就直接用之前的下载并处理好的图的缓存数据了。
        这时候有两个选择。
        (1) 要么是updateMap去更新图。
        (2) 要么就重新命名一个新的 mapid 如map2
        */
        if (res.error) {
            message.error(res.error)
        }
        // 获取地图的范围
        let mapExtent = vjmap.GeoBounds.fromString(res.bounds);
        // 建立坐标系
        let prj = new vjmap.GeoProjection(mapExtent);
        console.log('res',res);
        // // 新建地图对象
        // console.log('map',new vjmap())
        let map = new vjmap.Map({
            container: 'map-container', // container ID
            style: svc.rasterStyle(), // 样式，这里是栅格样式,// 矢量瓦片样式
            center: prj.toLngLat(mapExtent.center()), // 中心点
            zoom: 2,
            renderWorldCopies: false
        });
       
        // 地图关联服务对象和坐标系
        map.attach(svc, prj);
        // 使地图全部可见
        map.fitMapBounds();
        await map.onLoad();
        this.cadLoading = false;
    },
    confirmStatusChange() {
          this.userInfo.tryState = 1
          this.updateUserState(1);
          this.dialogVisible = false;
          this.puopShow = true

    },
    confirmStatusChangeno(){
        this.userInfo.tryState = 2
          this.updateUserState(2)
          this.dialogVisible = false;
          this.puopShow = true
    },
    updateUserState(state) {
        console.log("this.userInfo.id"+this.userInfo.id)
          // 调用后台接口更新用户状态
          this.$http.get('user/updateState', {
              id:this.userInfo.id,
              tryState: state
                }).then(response => {
                  }).catch(error => {
                  });
    },
    puopShowTap() {
      if (!this.$store.state.token) {
        this.$router.push({
          path: "/login"
        })
        return
      }
	  
	  if(this.userInfo.certification==1){
			if (localStorage.getItem('changeType') == 1) {
			  this.$message.error('请先切换为工程师身份')
			  this.puopShow = false
			} else {
				//判断用户是否接单过多
				let jd_type = 0;
				if(this.usercurrent.team ==1){
					if(this.usercurrent.orderNum > 5){
						jd_type = 1;
					}
				}else{
					if(this.usercurrent.orderNum > 2){
						jd_type = 1;
					}
				}
				
				if(jd_type==1){
					//弹出提示接单
					this.showjdMore = true;
				}else{
					if(this.projectInfo.tryMarkers==0){
						this.dialogVisible = true;
						// if (this.userInfo.tryState == 1 || this.userInfo.tryState == 2) {
						// 	this.dialogVisible = true;
						// }else {
						// 	this.puopShow = true
						// }
					}else{
						this.puopShow = true
					}
					
					
					
				}
				
				
				
			}
	  }else{
	  	this.certificationShow = true;	
	  }
     
      // if (this.userInfo.identity != 2) {
      //   this.$message.error('您当前的身份不能申请该项目')
      //   return
      // }
    },
	jdBtn(){
		this.showjdMore = false;
		if(this.projectInfo.tryMarkers==0){
			this.dialogVisible = true;
			// if (this.userInfo.tryState == 1 || this.userInfo.tryState == 2) {
			// 	this.dialogVisible = true;
			// }else {
			// 	this.puopShow = true
			// }
		}else{
			this.puopShow = true
		}
	},
	goTocert(){
		  this.certificationShow = false;	
		  this.$mHelper.to('/myCenter');
	},
    //获取详情
    getDetail() {
      this.$http.get('project/detail', {
        id: this.Id
      }).then(res => {
        if (res.code == 200) {
          res.data.typeAB = getCustomType(res.data.type);
          res.data.totalInvestment = initNumberValue(res.data.totalInvestment);
          res.data.totalScale = initNumberValue(res.data.totalScale);
          res.data.totalArea = initNumberValue(res.data.totalArea);
          this.projectInfo = res.data;
		  
          if (localStorage.getItem('changeType') == 1) {
            if(this.projectInfo.fbMid == this.userInfo.id){
              this.changeTypeShow = true;
            }
          }
        }
      })
      if (this.$store.state.token) {
        this.$http.get('project/getFgStatus', {
          id: this.Id
        }).then(res => {
          console.log(res)
          this.fgStatus = res.data;
		  this.bmListGet();
        })
      }
    },
	
	getUserCurrent(){
		this.$http.get('user/getUserInfo', {
		
		}).then(res => {
		  if (res.code == 200) {
				this.usercurrent = res.data;	
		  }
		})
	},
	
	//报名人员
	bmListGet() {
	  this.$http.get('director/bmList', {
	    id: this.Id,
	    pageNum: 1,
	    pageSize: 999,
		status: 2,
	  }).then(res => {
	    if (res.code == 200) {
			res.data.list.forEach((e)=>{
				e.rateValue = e.synthesize/20 || 0;
			})
			this.bmList = res.data.list
	    }
	  })
	},
    sqBtn() {
      this.loading = true
      this.$http.post('partake/applySave', {
        id: this.Id,
      }).then(res => {
        this.loading = false
        if (res.code == 200) {
          this.puopShow = false;
          this.$refs.alert.alertToast('您已申请本项目，等待开放资料查阅权限', '提示', '#000')
          this.getDetail();
        }
      }).catch(err => {
        this.loading = false
      })
    },
    downloadFile(item) {
      if (!this.$store.state.token) {
        this.$router.push({
          path: "/login"
        })
        return
      }
      if (this.userInfo.identity != 3 && this.projectInfo.fgJurisdiction != 1) {
        this.$message.error('暂无权限')
        return;
      }
      this.loading = true
      this.$http.downloadFile('upload/download', {
        fileId: item.id,
        projectId: this.Id
      }).then(res => {
        this.loading = false
        var reader = new FileReader();
        let that = this
        reader.onloadend = function (event) {
          //因为返回的报错格式是字符串，手动转换成对象，转换成功表示请求失败
          //转换失败就意味着你拿到的result是文件流，那么直接手动下载就好
          try {
            let data = JSON.parse(event.target.result)
            that.$message.error("请点击右上角登录后重新下载");
            this.loading = false
          } catch (err) {
            const link = document.createElement('a'); // 创建a标签
            let blob = new Blob([res.data]);
            link.style.display = 'none';
            link.href = URL.createObjectURL(blob); // 创建下载的链接
            link.setAttribute('download', item.name); // 给下载后的文件命名
            document.body.appendChild(link);
            link.click(); // 点击下载
            document.body.removeChild(link); //  完成移除元素
            window.URL.revokeObjectURL(link.href); // 释放blob对象
            this.loading = false
            that.materialsShow = false;
          }
        };
        reader.readAsText(res.data);
      }).catch(err => {
        this.loading = false
      })

    },
    handleClose() {
      this.puopShow = false;
    },
	
	//邀请工程师
	inviteTap() {
	  if (!this.engineerNo) {
	    this.$message.error('请输入工程师编号')
	    return;
	  }
	  this.loading = true;
	  this.$http.post('/director/invitation', {
	    engineerNo: this.engineerNo,
	    projectId: this.Id
	  }).then(res => {
	    this.loading = false
	    if (res.code == 200) {
	      this.inviteShow = false;
	      const h1 = this.$createElement;
	      this.$msgbox({
	        title: '提示',
	        message: h1('p', {
	          style: 'margin-bottom: 20px'
	        }, [
	          h1('span', null, '您已成功邀请工程师申请此项目！ '),
	          h1('br', {
	            style: 'color: red'
	          }, ''),
	          h1('span', null, '如此工程师同意申请，则会出现在项目申请列表中！ '),
	        ]),
	        center: true,
	        showCancelButton: true,
	        showConfirmButton: false,
	        cancelButtonText: '我知道了',
	      })
	    }
	  }).catch(err => {
	    this.loading = false
	  })
	
	},
	
  }
}
</script>

<style lang="scss" scoped>
	
	::v-deep  .el-icon-star-on {
		color: #FFB420;
	}
	.on-sign-up{
		background: #1677FF;
	}
	.on-conduct{
		background: #FFC726;
	}
	.on-completed{
		background: #E8E8E8;
	}
	.on-success{
		background: #10c550;
	}
	.lines-title{
	   .hengxian {
	       width: calc(100% - 86px);
	   }
	}
	
	.hall-top{
		margin-top: 33px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		.left{
			width: calc(100% - 350px);
			.topSn{
				font-family: PingFangSC, PingFang SC;
				font-weight: 400;
				font-size: 16px;
				color: rgba(0,0,0,0.85);
				line-height: 21px;
				text-align: left;
				font-style: normal;
			}
			.topNames{
				font-family: PingFangSC, PingFang SC;
				font-weight: 500;
				font-size: 18px;
				color: rgba(0,0,0,0.85);
				line-height: 21px;
				text-align: left;
				font-style: normal;
				margin-top: 17px;
				display: flex;
				align-items: center;
				flex-wrap: wrap;
				.topState{
					padding: 0.5px 4px;
					border-radius: 4px;
					font-size: 10px;
					color: #FFFFFF;
					text-align: center;
					margin-right: 8px;
				}
			}
			.topTagsBox{
				margin-top: 16px;
				.tags{
					font-family: PingFangSC, PingFang SC;
					font-weight: 400;
					font-size: 14px;
					color: #4077DD;
					line-height: 21px;
					text-align: left;
					font-style: normal;
					margin-right: 16px;
					position: relative;
					&:before{
						content: '';
						width: 1px;
						height: 12px;
						background-color: #4077DD;
						position: absolute;
						top: 5px;
						right: -8px;
					}
					&:last-child{
						&:before{
							display: none;
						}
					}
				}
			}
			.topPrice{
				font-family: PingFangSC, PingFang SC;
				font-weight: 600;
				font-size: 18px;
				color: #FF4D4F;
				line-height: 36px;
				text-align: left;
				font-style: normal;
				margin-top: 24px;
			}
			.topTime{
				font-family: PingFangSC, PingFang SC;
				font-weight: 400;
				font-size: 16px;
				color: rgba(0,0,0,0.85);
				line-height: 21px;
				text-align: left;
				font-style: normal;
				margin-top: 25px;
			}
		}
		.right{
			width: 338px;
			.right-type-box1{
				display: flex;
				flex-flow: column;
				align-items: flex-end;
				.type-btn{
					width: 230px;
					height: 60px;
					background: #FF4D4F;
					border-radius: 8px;
					display: flex;
					justify-content: center;
					align-items: center;
					img{
						width: 20px;
						height: 20px;
					}
					span{
						font-family: PingFangSC, PingFang SC;
						font-weight: 400;
						font-size: 16px;
						color: #FFFFFF;
						line-height: 24px;
						text-align: center;
						font-style: normal;
						margin-left: 8px;
					}
				}
				.type-number{
					width: 230px;
					font-family: PingFangSC, PingFang SC;
					font-weight: 400;
					font-size: 16px;
					color: #4077DD;
					line-height: 21px;
					text-align: center;
					font-style: normal;
					margin-top: 12px;
				}
			}
			.right-rate-box1{
				display: flex;
				justify-content: flex-end;
				.rate-list{
					.item-rate{
						display: flex;
						align-items: center;
						margin-bottom: 12px;
						.item-title{
							font-family: PingFangSC, PingFang SC;
							font-weight: 400;
							font-size: 14px;
							color: rgba(0,0,0,0.85);
							line-height: 39px;
							text-align: left;
							font-style: normal;
						}
						::v-deep .el-rate{
							height: auto;
							.el-rate__icon {
							  font-size: 30px !important;
							}
						}
					}
				}
			}
			
			.right-type-box2{
				display: flex;
				justify-content: flex-end;
				.right-btn{
					width: 120px;
					height: 60px;
					border-radius: 8px;
					border: 1px solid #4077DD;
					font-family: PingFangSC, PingFang SC;
					font-weight: 400;
					font-size: 16px;
					color: #4077DD;
					line-height: 24px;
					display: flex;
					justify-content: center;
					align-items: center;
					margin-right: 8px;
				}
				.right-two-box{
					.type-btn{
						width: 230px;
						height: 60px;
						background: #4077DD;
						border-radius: 8px;
						font-family: PingFangSC, PingFang SC;
						font-weight: 400;
						font-size: 16px;
						color: #FFFFFF;
						line-height: 24px;
						text-align: center;
						font-style: normal;
						display: flex;
						justify-content: center;
						align-items: center;
					}
					.type-default{
						background-color: #e7e7e7;
						border-color: #e7e7e7;
					}
					.type-number{
						margin-top: 12px;
						width: 230px;
						display: flex;
						align-items: center;
						justify-content: center;
						img{
							width: 20px;
							height: 20px;
						}
						span{
							font-family: PingFangSC, PingFang SC;
							font-weight: 400;
							font-size: 16px;
							color: #FF4D4F;
							line-height: 21px;
							text-align: left;
							font-style: normal;
							margin-left: 8px;
						}
					}
				}
			}
			
		}
	}
	.hall-box{
		display: flex;
		justify-content: space-between;
		margin-top: 64px;
		margin-bottom: 32px;
		.left{
			width: calc(100% - 346px);
			.left-box-1{
				.left-title{
					font-family: PingFangSC, PingFang SC;
					font-weight: 400;
					font-size: 16px;
					color: rgba(0,0,0,0.45);
					line-height: 21px;
					text-align: left;
					font-style: normal;
					margin-top: 24px;
				}
				.btLines{
					margin-top: 14px;
					.tag-title{
						font-family: PingFangSC, PingFang SC;
						font-weight: 400;
						font-size: 16px;
						color: rgba(0,0,0,0.85);
						line-height: 21px;
						text-align: left;
						font-style: normal;
					}
					::v-deep .el-tag{
						height: auto;
						padding: 3.34px 7.34px;
						background: #F5F7FA;
						border-radius: 4px;
						font-family: PingFangSC, PingFang SC;
						font-weight: 400;
						font-size: 14px;
						color: #555C70;
						line-height: 20px;
						text-align: left;
						font-style: normal;
						margin-bottom: 10px;
						margin-right: 8px;
					}
				}
				.miaoshu{
					font-family: PingFangSC, PingFang SC;
					font-weight: 400;
					font-size: 16px;
					color: rgba(0,0,0,0.85);
					line-height: 21px;
					text-align: left;
					font-style: normal;
					margin-top: 12px;
				}
			}
			.left-box-2{
				.left-list{
					margin-top: 24px;
					.item{
						img{
							width: 72px;
							height: 68px;
						}
						.item-title{
							width: 96px;
							height: 21px;
							font-family: PingFangSC, PingFang SC;
							font-weight: 400;
							font-size: 16px;
							color: rgba(0,0,0,0.85);
							line-height: 21px;
							text-align: left;
							font-style: normal;
							margin-top: 12px;
						}
					}
				}
			}
			.left-box-3{
				.item-invites{
					justify-content: space-between;
					font-family: PingFangSC, PingFang SC;
					font-weight: 400;
					color: rgba(0,0,0,0.85);
					margin-top: 24px;
					.left{
						width: calc(100% - 0px);
						.user-invites{
							margin-left: 36px;
							.invits-top{
								.username{
									font-size: 16px;
									line-height: 21px;
								}
								.userTag{
									padding: 0px 3.34px;
									border-radius: 4px;
									border: 1px solid #4077DD;
									font-size: 12px;
									color: #4077DD;
									line-height: 21px;
									margin-left: 8px;
								}
								::v-deep .el-rate{
									margin-left: 24px;
								}
							}
							.invites-sn{
								font-size: 14px;
								margin-top: 12px;
							}
							.invites-tags{
								margin-top: 6px;
								line-height: 30px;
								span{
									width: 89px;
									height: 22px;
									background: #F5F7FA;
									border-radius: 4px;
									font-size: 12px;
									color: #555C70;
									line-height: 20px;
									margin-right: 8px;
									padding: 3px 8px;
								}
							}
							.invites-fen{
								margin-top: 12px;
								.invites_lines{
									margin-right: 24px;
									.numbers{
										font-size: 14px;
									}
									.text{
										font-size: 12px;
									}
								}
							}
						}
					}
					.right{
						.btn-default{
							height: 40px;
							border-radius: 6px;
							border: 1px solid #4077DD;
							background: #FFFFFF;
							font-family: PingFangSC, PingFang SC;
							font-weight: 400;
							font-size: 16px;
							color: #4077DD;
							line-height: 24px;
						}
						.btn-error{
							height: 40px;
							border-radius: 6px;
							border: 1px solid #FF4D4F;
							background: #FFFFFF;
							font-family: PingFangSC, PingFang SC;
							font-weight: 400;
							font-size: 16px;
							color: #FF4D4F;
							line-height: 24px;
						}
					}
				}
			}
			
		}
		.right{
			.right-box-1{
				width: 300px;
				min-height: 241px;
				background: #FFFFFF;
				border-radius: 8px;
				border: 1px solid #E8E8E8;
				padding-bottom: 24px;
				.right-title{
					font-family: PingFangSC, PingFang SC;
					font-weight: 600;
					font-size: 16px;
					color: rgba(0,0,0,0.85);
					line-height: 21px;
					text-align: left;
					font-style: normal;
					margin-top: 24px;
					padding: 0 24px;
				}
				.right-user{
					display: flex;
					align-items: center;
					margin-top: 24px;
					padding: 0 24px;
					::v-deep .el-image{
						img{
							width: 42px;
							height: 42px;
							border-radius: 50%;
						}
					}
					.right-uinfo{
						margin-left: 16px;
						.name{
							font-family: PingFangSC, PingFang SC;
							font-weight: 400;
							font-size: 16px;
							color: rgba(0,0,0,0.85);
							line-height: 21px;
							text-align: left;
							font-style: normal;
						}
						.rStatis{
							width: 64px;
							height: 28px;
							border-radius: 4px;
							margin-top: 4px;
							font-family: HYk1gj;
							font-size: 12px;
							color: #FFFFFF;
							line-height: 20px;
							display: flex;
							justify-content: center;
							align-items: center;
						}
						.right-wrz{
							background: linear-gradient(180deg, #e2e2e2 2%, #eceae9 100%);
							box-shadow: 0px 0px 4px 0px rgb(225 221 217 / 59%);
						}
						.right-grrz{
							background: linear-gradient( 180deg, #0D9AF6 0%, #267CFC 100%);
							box-shadow: 0px 0px 4px 0px rgba(43,104,255,0.59);
						}
						.right-qyrz{
							background: linear-gradient( 180deg, #F6A00D 2%, #FC8526 100%);
							box-shadow: 0px 0px 4px 0px rgba(255,140,43,0.59);
						}
					}
				}
				.right-phone{
					font-family: PingFangSC, PingFang SC;
					font-weight: 400;
					font-size: 16px;
					color: rgba(0,0,0,0.85);
					line-height: 21px;
					text-align: left;
					font-style: normal;
					margin-top: 24px;
					padding: 0 24px;
					white-space: normal;
					word-break: break-all;
				}
				.right-time{
					font-family: PingFangSC, PingFang SC;
					font-weight: 400;
					font-size: 16px;
					color: rgba(0,0,0,0.45);
					line-height: 21px;
					text-align: left;
					font-style: normal;
					margin-top: 24px;
					padding: 0 24px;
				}
			}
			.right-box-2{
				width: 300px;
				min-height: 156px;
				background: #FFFFFF;
				border-radius: 8px;
				border: 1px solid #E8E8E8;
				margin-top: 24px;
				padding-bottom: 24px;
			}
		}
	}
	
	.tipPopup{
		::v-deep .el-dialog{
			box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.1), 0px 0px 5px 0px rgba(0,0,0,0.14);
			border-radius: 10px;
			.el-dialog__header{
				padding: 20px 36px 0 28px;
				.el-dialog__title{
					font-family: PingFangSC, PingFang SC;
					font-weight: 500;
					font-size: 18px;
					color: rgba(0,0,0,0.85);
					line-height: 25px;
					text-align: left;
					font-style: normal;
				}
			}
			.el-dialog__body{
				.title{
					font-family: PingFangSC, PingFang SC;
					font-weight: 400;
					font-size: 18px;
					color: rgba(0,0,0,0.85);
					line-height: 27px;
				}
				.info{
					font-family: PingFangSC, PingFang SC;
					font-weight: 400;
					font-size: 14px;
					color: rgba(0,0,0,0.45);
					line-height: 20px;
				}
			}
			.dialog-bom{
				.el-button{
					width: 96px;
					height: 40px;
					background: #4077DD;
					border-radius: 8px;
					font-family: PingFangSC, PingFang SC;
					font-weight: 400;
					font-size: 16px;
					color: #FFFFFF;
					line-height: 24px;
					font-style: normal;
					display: flex;
					justify-content: center;
					align-items: center;
				}
				.close{
					background: #FFFFFF;
					border-radius: 6px;
					border: 1px solid #4077DD;
					color: #4077DD;
				}
			}
			.fp-left{
				font-family: PingFangSC, PingFang SC;
				font-weight: 500;
				font-size: 16px;
				color: rgba(0,0,0,0.85);
				line-height: 21px;
				text-align: left;
				font-style: normal;
				margin-bottom: 12px;
			}
		}
	}
	
	.cert-tip{
		::v-deep .el-dialog{
			width: 456px !important;
			height: 374px;
			background: #FFFFFF;
			border-radius: 10px;
			backdrop-filter: blur(42px);
			.textStyle{
				font-family: PingFangSC, PingFang SC;
				font-weight: 500;
				font-size: 16px;
				color: rgba(0,0,0,0.85);
				line-height: 36px;
				text-align: left;
				font-style: normal;
			}
			.dialog-footer{
				display: flex;
				justify-content: center;
				.footer-btn-left{
					width: 96px;
					height: 40px;
					background: #FFFFFF;
					border-radius: 6px;
					border: 1px solid #4077DD;
					font-family: PingFangSC, PingFang SC;
					font-weight: 400;
					font-size: 16px;
					color: #4077DD;
					line-height: 24px;
					text-align: center;
					font-style: normal;
					display: flex;
					justify-content: center;
					align-items: center;
				}
				.footer-btn-right{
					width: 96px;
					height: 40px;
					background: #4077DD;
					border-radius: 8px;
					font-family: PingFangSC, PingFang SC;
					font-weight: 400;
					font-size: 16px;
					color: #FFFFFF;
					line-height: 24px;
					text-align: center;
					font-style: normal;
					display: flex;
					justify-content: center;
					align-items: center;
					margin-left: 24px;
				}
			}
			.el-dialog__body{
				width: 100%;
				padding: 0;
				display: flex;
				flex-flow: column;
				align-items: center;
			}
			.el-dialog__footer{
				margin-top: 80px;
			}
		}
	}
	
	
</style>
