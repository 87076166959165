<template>
	<!-- 登录完填写信息 -->
	<div class="">
		<div class="pagebox">
			<el-form label-position="right" label-width="125px" :model="userInfo" :rules="rulesFrom" ref="ruleForm">
				<div class="lines-title" style="margin-top: 0;">
					<div class="lines-left"></div>
					<span>账户信息</span>
					<div class="hengxian"></div>
				</div>
				<div class="headBox">
					<el-form-item label="头像:">
						<div class="inputRight mdx-flex">
							<div class="block">
								<el-image class='image-slot' :src="userInfo.headImgUrl">
									<div slot="error" class="image-slot">
										<img src="../../../static/images/mrtx.png" style="width: 80px;height: 80px;border-radius: 5px;">
									</div>
								</el-image>
							</div>
							<el-upload class="avatar-uploader" :action='action' :show-file-list="false"
								accept="image/jpg,image/jpeg,image/png" :on-success="handleAvatarSuccess"
								:before-upload="beforeAvatarUpload">
								<el-button type="primary" style="margin-left: 30px;" size="small">更换头像</el-button>
							</el-upload>
						</div>
					</el-form-item>
				</div>
				<el-form-item label="用户名:">
					{{ userInfo.userName || '' }}
				</el-form-item>
				<el-form-item label="手机号:">
					{{ displayMobile || '' }}
				</el-form-item>
				<el-form-item label="用户ID:">
					{{ userInfo.id || '' }}
				</el-form-item>
				<el-form-item label="昵称:" prop="nickname">
					<div class="inputRight">
						<el-input type="text" v-model="userInfo.nickname" maxlength="20" placeholder="请输入昵称"></el-input>
					</div>
				</el-form-item>
				<el-form-item label="性别:">
					<div class="inputRight">
						<template>
							<el-radio v-model="userInfo.gender" :label="1">男</el-radio>
							<el-radio v-model="userInfo.gender" :label="2">女</el-radio>
						</template>
					</div>
				</el-form-item>
				<el-form-item label="所在地区:" prop="address" v-if="userInfo.identity != 3">
					<div class="inputRight">
						<el-cascader v-model="userInfo.address" :options="addOptions" :props='props'
							@change="changeAddress">
						</el-cascader>
					</div>
				</el-form-item>

				<el-form-item label="QQ号码:">
					<div class="inputRight">
						<el-input type="number" v-model="userInfo.qq" maxlength="20" placeholder="请输入QQ号码"></el-input>
					</div>
				</el-form-item>
				<el-form-item label="电子邮件:">
					<div class="inputRight">
						<el-input type="email" v-model="userInfo.mailbox" maxlength="30" placeholder="请输入电子邮件"></el-input>
					</div>
				</el-form-item>
				<!-- <el-form-item label="">
					<el-button type="primary" style="padding: 12px 60px;" @click="submitForm()">保存</el-button>
				</el-form-item> -->
			</el-form>
			<div class="true-name-box">
				<div class="lines-title" style="margin-top: 0;">
					<div class="lines-left"></div>
					<span>实名认证</span>
					<div class="hengxian"></div>
				</div>
				<div class="t-form-item">
					<div class="form-label label-1">上传身份证照片</div>
				</div>
				<div class="t-form-item m-flex">
					<!-- <div class="form-label"></div> -->
					<div v-if="idCardInfo.certification == 1" class="m-flex">
						<div class="auth-uploader">
							<img style="width: 150px; height: 102px" :src="idCardInfo.portrait">
						</div>
						<div class="auth-uploader">
							<img style="width: 150px; height: 102px" :src="idCardInfo.surface">
						</div>
					</div>
					<div class="m-flex" v-else>
						<el-upload class="auth-uploader" :action='action1' :data="{ type: 1 }" :show-file-list="false"
							:on-success="(response, file, fileList) => handleIdcardSuccess(response, 1)"
							accept="image/jpg,image/jpeg,image/png" :before-upload="beforeIdcarUpload">
							<img style="width: 150px; height: 102px" :src="idCardTemp.portrait">
						</el-upload>
						<el-upload class="auth-uploader" :action="action1" :show-file-list="false" :data="{ type: 2 }"
							:on-success="(response, file, fileList) => handleIdcardSuccess(response, 2)"
							accept="image/jpg,image/jpeg,image/png" :before-upload="beforeIdcarUpload">
							<img style="width: 150px; height: 102px" :src="idCardTemp.surface">
						</el-upload>
					</div>
				</div>
				<div class="t-form-item m-flex auth-status" v-if="idCardInfo.name">
					<div class="form-label label-1">姓名</div>
					<div class="no-edit label-1">{{ idCardInfo.name }}</div>
				</div>
				<div class="t-form-item m-flex auth-status" v-if="idCardInfo.idCard">
					<div class="form-label label-1">身份证号码</div>
					<div class="no-edit label-1">{{ idCardInfo.idCard }}</div>
				</div>
				<div class="t-form-item m-flex">
					<!-- <div class="form-label"></div> -->
					<el-button type="primary" style="padding: 12px 60px;" :loading="subLoading" @click="submitForm()">保存
					</el-button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import ruleData from '../../../api/rules.js'
export default {
	data() {
		return {
			subLoading: false,
			action1: this.$store.state.baseURL + 'engineer/idCardOCRCheck',
			action: this.$store.state.baseURL + 'upload/uploadCos',
			rulesFrom: ruleData.perfectInfo,
			addOptions: [], //地址
			props: {
				label: 'cityName',
				value: 'code'
			},
			radio: '1',
			displayMobile: '',
			userInfo: {
				id: '',
				mobile: '',
				nickname: '',
				city: '',
				gender: '',
				headImgUrl: '',
				mailbox: '',
				province: '',
				mailbox: '',
				address: []
			},
			authStatus: "未认证",
			idCardTemp: {
				portrait: require('@/static/images/cardZheng.png'),//人像
				surface: require('@/static/images/cardFan.png'),//国徽
			},
			idCardInfo: {
				certification: false,
				portrait: "",//人像
				surface: "",//国徽
				name: '',//姓名
				idCard: '',//证件号码
			},
		}
	},
	computed: {
		// userInfo() {
		// 	return this.$store.getters['userInfo']
		// },
	},
	created() {
		//
		this.getUserInfo();
		this.$http.get('region/json').then(res => {
			if (res.code == 200) {
				this.addOptions = res.data
				console.log(this.addOptions);
			}
		})

	},
	methods: {
		getUserInfo() {
			this.$http.get('user/getUserInfo').then(res => {
				console.log(res)
				if (res.code == 200) {
					this.userInfo = res.data;
					this.displayMobile = res.data.mobile;
					delete this.userInfo.mobile
					this.userInfo.address = []
					this.idCardInfo.certification = res.data.certification;
					if (this.userInfo.province) {
						this.userInfo.address.push(this.userInfo.province)
					}
					if (this.userInfo.city) {
						this.userInfo.address.push(this.userInfo.city)
					}
					if (res.data.certification == 1) {
						if (res.data.portrait) {
							this.idCardInfo.portrait = res.data.portrait
						}
						if (res.data.surface) {
							this.idCardInfo.surface = res.data.surface
						}
						if (res.data.name) {
							this.idCardInfo.name = res.data.name
						}
						if (res.data.idCard) {
							this.idCardInfo.idCard = res.data.idCard
						}
					}

					this.authStatus = res.data.certification == 1 ? "已实名认证" : "未认证";
				} else {
					store.commit('outLogin')
				}
			})
		},
		beforeIdcarUpload() {
			const h2 = this.$createElement;
			this.$message({
				duration: 1500,
				message: h2('p', null, [
					h2('span', { style: 'color: #185ba1' }, '正在上传… '),
				])
			});
		},
		handleIdcardSuccess(res, type) {
			if (res.code != 200) {
				this.$message.error(res.msg)
				return
			}
			if (type == 1) {
				this.idCardInfo.portrait = res.data.url;
				this.idCardInfo.name = res.data.name;
				this.idCardInfo.idCard = res.data.idCard;
				this.idCardTemp.portrait = res.data.url;
			} else {
				this.idCardInfo.surface = res.data.url;
				this.idCardTemp.surface = res.data.url;
			}
		},
		submitForm() {
			let subData = this.userInfo;
			if (this.idCardInfo.name != "") {
				subData.name = this.idCardInfo.name
			}
			if (this.idCardInfo.idCard != "") {
				subData.idCard = this.idCardInfo.idCard
			}
			if (this.idCardInfo.portrait != "") {
				subData.portrait = this.idCardInfo.portrait
			}
			if (this.idCardInfo.surface != "") {
				subData.surface = this.idCardInfo.surface
			}
			this.$refs['ruleForm'].validate((valid) => {
				if (valid) {
					this.subLoading = true;
					this.$http.post('user/updateUser', subData).then(res => {
						if (res.code == 200) {
							this.subLoading = false;
							this.$message.success(res.msg);
							this.getUserInfo();
							this.$uniApi.Logins(); //获取个人用户信息
						}
					})
				}
			});
		},
		changeAddress(e) {
			console.log(e);
			this.userInfo.province = e[0]
			this.userInfo.city = e[1]
		},
		handleAvatarSuccess(e, e1, e2) {
			this.userInfo.headImgUrl = e.data
		},
		beforeAvatarUpload(file) {
			var testmsg = file.name.substring(file.name.lastIndexOf('.') + 1)
			const extension = testmsg === 'jpg'
			const extension2 = testmsg === 'png'
			const extension4 = testmsg === 'jpeg'
			if (!extension && !extension2 && !extension4) {
				this.$message({
					message: '上传文件只能是 jpg, jpeg, png格式!',
					type: 'warning'
				})
				return false
			}
			return extension || extension2 || extension4
		}
	}
}
</script>

<style scoped lang="scss">
.lines-title{
	margin-bottom: 24px;
}
.pagebox {
	background-color: #fff;
	padding: 0 0 0 45px;
	box-sizing: border-box;
	border-radius: 10px;
}

	
.headBox {
	::v-deep .el-form-item__label {
		padding: 20px 12px;
	}
}

.image-slot {
	border-radius: 10px;
	width: 80px;
	height: 80px;
}

.image-error {
	width: 80px;
	height: 80px;
	background-color: #eee;
}

.warys {
	padding: 30px;
}



.inputRight {
	width: 300px;
}

.true-name-box {
	margin-top: 35px;
	color: #333333;

	.t-form-item {
		margin-bottom: 30px;

		&.auth-status {
			font-size: 20px;
			line-height: 28px;
		}

		.label-1 {
			font-size: 16px;
			line-height: 22.4px;
		}

		.form-label {
			width: 112px;
			margin-right: 25px;
			text-align: right;
		}

		.no-edit {
			color: #666666;
		}
	}

	.auth-uploader {
		margin-right: 60px;
	}

}
</style>
