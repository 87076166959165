<template>
  <div class="wary" :style="{'width':waryWidth+'px','height':waryHeight+'px' }">
    <!-- {{gender}} -->
    <el-image :src='imgSrc' :style="{
			'width':width+'px',
			'height':height+'px',
			'border-radius':borderRadius+'px' }" style="box-shadow:  0 0 5px #ddd;">
      <div slot="error" class="image-slot">
        <img src="../../static/images/morenImg.png" :style="{
			'width':width+'px',
			'height':height+'px',
			'border-radius':borderRadius+'px' }">
      </div>
    </el-image>
    <i class="wzk-nan wzk nan" v-if="gender==1&&isGender"></i>
    <i class="wzk-nv wzk nv" v-if="gender==2&&isGender"></i>

  </div>
</template>

<script>
  export default {
    props: {
      width: {
        type: Number,
        default: 50
      },
      height: {
        type: Number,
        default: 50
      },
	  waryWidth: {
	    type: Number,
	    default: 50
	  },
	  waryHeight: {
	    type: Number,
	    default: 50
	  },
      borderRadius: {
        type: Number,
        default: 5
      },
      imgSrc: {
        type: String,
        default: ""
      },
      isGender: {
        type: Boolean,
        default: false
      },
      gender: {
        type: Number||String,
        default: 1
      }
    }
  }
</script>

<style lang="scss">
  .wary {
    // display: inline-block;
    position: relative;
  }

  .image-slot {
    width: 50px;
    height: 50px;

  }

  .nan {
    position: absolute;
    right: 16px;
    bottom: 4px;
    color: #25A4F5;
  }

  .nv {
    position: absolute;
    right: 16px;
    bottom: 4px;
    color: #FF8E8D;
  }
</style>
