<template>
  <div class="rightFidexBox">
    <div class="linests" v-if="isShow()">
	  
      <div class="wzk-cursor lxMe">
        <div><i class="wzk wzk-kefu wzkIcon"></i></div>
        <div class="w-m-t-10">
          联系我们
        </div>
        <div class="showboxss">
          <div class="leftPhoneQQ">
            <div class="numbers">
              <div>
                <div>
                  联系电话
                </div>
                <div style="margin-top: 10px;">
                  {{mobile||''}}
                </div>
              </div>
              <div>
                <div>
                  微信(手机同号)
                </div>
                <div style="margin-top: 10px;">
                  {{qqNum||''}}
                </div>
              </div>
            </div>
            <div class="iconqiPao">
              <i class="el-icon-caret-right" style="font-size: 30px;"></i>
            </div>
          </div>
        </div>
      </div>




      <div class="wzk-cursor" @click="showFanKui">
        <div><i class="wzk wzk-yijian wzkIcon"></i></div>
        <div class="w-m-t-10">
          意见反馈
        </div>
      </div>


        <div class="wzk-cursor lxMe">
            <div><i class="wzk wzk-xcxCode wzkIcon"></i></div>
            <div class="w-m-t-10">
                APP
            </div>
            <div class="showboxss">
                <div class="leftPhoneQQ">
                    <div class="numbers mdx-text-center">
                        <img style="width: 120px;height: 120px;" :src="appCodeImg">
                    </div>
                    <div class="iconqiPao">
                        <i class="el-icon-caret-right" style="font-size: 30px;"></i>
                    </div>
                </div>
            </div>
        </div>


      <div class="wzk-cursor lxMe">
        <div><i class="wzk wzk-xcxCode wzkIcon"></i></div>
        <div class="w-m-t-10">
          小程序
        </div>
        <div class="showboxss">
          <div class="leftPhoneQQ">
            <div class="numbers mdx-text-center">
              <img style="width: 120px;height: 120px;" :src="wxCodeImg">
            </div>
            <div class="iconqiPao">
              <i class="el-icon-caret-right" style="font-size: 30px;"></i>
            </div>
          </div>
        </div>
      </div>
      <div class="wzk-cursor" style="margin-top: 20px;border-top: 1px solid #e6e6e6;" @click="scrollTBunmer(0)">
        <div><i class="wzk wzk-backTop wzkIcon" style=""></i></div>
        <div class="w-m-t-10">
          回顶部
        </div>
      </div>
    </div>
    <el-dialog :visible.sync="puopShow" width="40%" center top='30vh'>
      <div class="mdx-text-center">
        <h4>意见反馈</h4>
      </div>
      <div class="puopContext">
        <h4>详情说明：</h4>
        <div style="height: 10px;"></div>
        <el-input type="textarea" :maxlength="255" :rows="5" placeholder="请输入内容" v-model="textarea"
          style="font-size: 14px;">
        </el-input>
      </div>
      <div style="margin-top: 15px;">
        <el-upload ref="files" class="upload-demo" :action='action' :on-success="handleChange" :on-remove='handleRemove'
          :file-list="imgList" accept="image/jpg,image/jpeg,image/png" :on-exceed="handleExceed"
          :before-upload="beforeAvatarUpload" :limit="3">
          <i class="el-icon-circle-plus-outline" style="font-size: 30px;">

          </i>
        </el-upload>
      </div>
      <div class="puopfootBox" style="margin-top: 10px;">
        <el-button size="small" type="primary" :loading="loading" @click="subimtFankui">提交</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        textarea: '',
        imgList: [],
        puopShow: false,
        fileLists: [],
        action: this.$store.state.baseURL + 'upload/uploadCos',
        loading: false,
        mobile: '',
        qqNum: '',
        wxCodeImg: '',
        appCodeImg:''
      }
    },
    computed: {
      paths() {
        return this.$route.path
      }
    },
    created() {
      //客户电话
      this.$http.get('config/pcInfo').then(res => {
        if (res.code == 200) {
          this.mobile = res.data.dh
          this.qqNum = res.data.qq
          this.wxCodeImg = res.data.qr
          this.appCodeImg = res.data.app
        }
      })
    },
    methods: {
      //提交反馈
      subimtFankui() {
        if (this.textarea.length < 1) {
          this.$message.error('请输入反馈内容')
          return
        }
        this.loading = true
        let data = {
          sourceType: 1,
          content: this.textarea
        }
        if (this.fileLists.length) {
          data.img = this.fileLists.join(',')
        }
        setTimeout(() => {
          this.$http.post('feedBack/save', data).then(res => {
            this.loading = false
            if (res.code == 200) {
              this.$message.success('反馈成功')
            }
            this.textarea = ''
            this.$refs['files'].clearFiles();
            this.fileLists = [];
            this.puopShow = false
          }).catch(err => {
            this.loading = false
          })
        }, 500);
      },
      handleChange(e, file, fileList) {
        let list = []
        fileList.forEach(e => {
          list.push(e.response.data)
        })
        this.fileLists = list
      },
      handleRemove(file, fileList) {
        let list = []
        fileList.forEach(e => {
          list.push(e.response.data)
        })
        this.fileLists = list
      },
      handleExceed(files, fileList) {
        this.$message.warning(`最多选择三张图片`);
      },
      beforeAvatarUpload(file) {
        var testmsg = file.name.substring(file.name.lastIndexOf('.') + 1)
        const extension = testmsg === 'jpg'
        const extension2 = testmsg === 'png'
        const extension4 = testmsg === 'jpeg'
        if (!extension && !extension2 && !extension4) {
          this.$message({
            message: '上传文件只能是 jpg, jpeg, png格式的图片!',
            type: 'warning'
          })
          return false
        }
        return extension || extension2 || extension4
      },
      //打开意见反馈弹窗
      showFanKui() {
        console.log(this.$store.state.token)
        if (this.$store.state.token) {
          this.puopShow = true;
          this.$nextTick(() => {
            this.textarea = ''
            this.$refs['files'].clearFiles();
            this.fileLists = [];
          })
        } else {
          this.$message({
            message: '请先登录',
            type: 'warning'
          })
        }
        
      },
      scrollTBunmer(num) {
        this.$nextTick(function() {
          window.scrollTo({
            "behavior": "smooth",
            "top": num
          });
        })
      },
      isShow() {
        let data = ['/login']
        let index = data.findIndex((e => e == this.paths))
        if (index != '-1') {
          return false
        } else {
          return true
        }
      },
    }
  }
</script>

<style lang="scss" scoped>
  // ::v-deep .el-dialog__header {
  //   display: none !important;
  // }

  .lxMe:hover {
    .showboxss {
      display: block;
    }
  }

  .showboxss {
    display: none;
    // transform: translateX(500%);
  }

  .leftPhoneQQ {
    position: absolute;
    width: 182px;
    left: -185px;
    top: 0;
    text-align: left;
    display: flex;

    // display: none;
    .numbers {
      box-shadow: 0 0 10px #ddd;
      border-radius: 5px;
      flex: 1;
      background-color: #fff;
      padding: 10px 20px;
      font-size: 14px;
      color: #757575;

    }

    .numbers>div {

      padding: 10px 0;

      &:first-child {
        border-bottom: 1px solid #ddd;
      }
    }

    .iconqiPao {
      margin-top: 10px;
      margin-left: -12px;
      color: #fff;
    }
  }

  .rightFidexBox {
    position: fixed;
    top: 0;
    right: 0;
    height: 100%;
    z-index: 99999999;
    display: flex;
    flex-direction: column;
    justify-content: center;


  }

  .linests {
	  width: 80px;
	  box-shadow: 0px 6px 10px 6px rgba(44,46,51,0.03), -6px 6px 20px 3px rgba(44,46,51,0.06);
	  border-radius: 4px;
    img {
      width: 30px;
      height: 30px;
    }

  }

  .linests>div {
    background-color: #fff;
    width: 80px;
    height: 80px;
    font-size: 14px;

    text-align: center;

    box-sizing: border-box;
    border-top: 1px solid #e6e6e6;
    
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    color: #757575;

    &:first-child {
     border-top: none;
    }
  }

  .wzkIcon {
    font-size: 26px;
    color: #757575;
  }

  .lanse {
    border: 1px solid #3291F8 !important;
    background-color: #3291F8 !important;
  }

  .numBers {
    position: absolute;
    width: 16px;
    height: 16px;
    background-color: #f00;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    border-radius: 50%;
    color: #fff;
    top: -8px;
    left: -8px;
  }
  

  
</style>
