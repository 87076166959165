//金额转换为千分位
export const formatCurrency = function(value, str = '') {
  // str 规定货币类型
  if (value == "0") return '0.00';
  if (value == ".") return '';
  if (!value) return '0';
  let val = Number(value).toFixed(2) // 提前保留两位小数
  let intPart = parseInt(val) // 获取整数部分
  let intPartFormat = intPart.toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,') // 将整数部分逢三一断 ？？？
  let floatPart = '.00' // 预定义小数部分
  val = val.toString() // 将number类型转为字符串类型，方便操作
  let value2Array = val.split('.')
  if (value2Array.length === 2) { // =2表示数据有小数位
    floatPart = value2Array[1].toString() // 拿到小数部分
    if (floatPart.length === 1) { // 补0,实际上用不着
      return str + intPartFormat + '.' + floatPart + '0'
    } else {
      return str + intPartFormat + '.' + floatPart
    }
  } else {
    return str + intPartFormat + floatPart
  }
}

//金额转换为千分位(整数)
export const formatIntCurrency = function(value, str = '') {
  // str 规定货币类型
  if (value == "0") return '0';
  if (value == ".") return '';
  if (!value) return '0';
  let val = Number(value).toFixed(0)
  let intPart = parseInt(val) // 获取整数部分
  let intPartFormat = intPart.toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,') // 将整数部分逢三一断 ？？？
  val = val.toString() // 将number类型转为字符串类型，方便操作
  return str + intPartFormat
}


//限制输入金额
export const inputLimitAmount = function(val, digit) {
  digit = digit || 12;
  let res = "";
  if (!val) return "";
  if (val == ".") return "";
  let matchArr = val.match(/\./g);
  if (matchArr && matchArr.length > 1) {
    res = null;
  } else {
    if (val.split('.')[0].length > digit) {
      res = null;
    } else {
      res = val.split('.')[1] && val.split('.')[1].length > 2 ? val.split('.')[0] + '.' + val.split('.')[1].slice(0,
        2) : val;
    }
  }
  return res ? res.replace(/[^0-9.]/g, "") : res;
}


//限制金额的输入及千分位展示
export const formatAmountsFocus = function(_attr, dataForm) {
  let that = _attr + 'F';
  dataForm[that] = dataForm[_attr];
}

export const formatAmountInput = function(_attr, dataForm, digit) {
  let that = _attr + 'F';
  let $thisVal = dataForm[_attr];
  let $thatVal = dataForm[that];
  let dig = digit ? digit : 12;
  let formateVal = this.inputLimitAmount($thatVal, dig);
  if (formateVal == null) {
    dataForm[that] = $thisVal
  } else {
    dataForm[that] = formateVal;
    dataForm[_attr] = formateVal ? Number(formateVal).toFixed(2) : formateVal;
  }
}

export const formatAmountBlur = function(_attr, dataForm) {
  let that = _attr + 'F';
  dataForm[that] = this.formatCurrency(dataForm[that]);
}

//获取金额大写的方法
export const getMoneyHanzi = function(n) {
  if ((!n && n === "") || (!n && n != 0)) return;
  let str = 0
  n = typeof n === 'number' ? n + '' : n
  str = n.substring(n.indexOf('.') + 1, n.indexOf('.') + 3)
  let fraction = ['角', '分']
  let digit = ['零', '壹', '贰', '叁', '肆', '伍', '陆', '柒', '捌', '玖']
  let unit = [
    ['元', '万', '亿'],
    ['', '拾', '佰', '仟']
  ]
  let s = ''
  for (let i = 0; i < fraction.length; i++) {
    if (i === 0) {
      s += (digit[parseInt(n * 10 * Math.pow(10, i) % 10)] + fraction[i]).replace(/零./, '')
    } else if (i === 1) {
      if (str.length === 2) {
        if (str.substring(0, 1) === '0') {
          s = '零角'
        }
        if (str.substring(0, 2) === '00') {
          s = '整'
        }
      }
      s += (digit[(n * 10 * Math.pow(10, i) % 10).toFixed(0)] + fraction[i]).replace(/零./, '')
    }
  }
  s = s || '整'
  n = Math.floor(n);
  //区分小数点左边是否有值（解决分位显示零几分缺陷）
  let flag = n;
  for (let i = 0; i < unit[0].length && n > 0; i++) {
    let p = ''
    for (let j = 0; j < unit[1].length && n > 0; j++) {
      p = digit[n % 10] + unit[1][j] + p
      n = Math.floor(n / 10)
    }
    s = p.replace(/(零.)*零$/, '').replace(/^$/, '零') + unit[0][i] + s
  }
  let sum = flag == 0 ? s.replace(/(零.)*零元/, '元').replace(/(零.)+/g, '').replace(/^整$/, '零元整') : s.replace(/(零.)*零元/,
    '元').replace(/(零.)+/g, '零').replace(/^整$/, '零元整')
  return sum
}

export const initNumberValue = function(originVal) {
  let getVal = formatCurrency(originVal);
  if (getVal!="0.00"&&getVal!="0"&&getVal!="") {
    return getVal
  }else {
    return ""
  }
}

export const getCustomType = function(originVal) {
  //A类项目；B类项目
  var newVal = "";
  if (originVal>13) {
    newVal = 'B'
  } else {
    newVal = 'A'
  }
  return newVal
}

/**
 * 验证十进制数字
 */
 function number(value) {
	return /^(?:-?\d+|-?\d{1,3}(?:,\d{3})+)?(?:\.\d+)?$/.test(value)
}

/**
 * 数字转整数 如 100000 转为10万
 * @param {需要转化的数} num
 * @param {需要保留的小数位数} point
 */
 export const tranNumber = function (num, point = 2) {
  if (!number(num)) {
    return num
  }
  let numStr = (num * 1).toString();
  // 十万以内直接返回
  if (numStr.length < 6) {
    return numStr;
  }
  //大于8位数是亿
  else if (numStr.length > 8) {
    let decimal = numStr.substring(
      numStr.length - 8,
      numStr.length - 8 + point
    );
    return parseFloat(parseInt(num / 100000000) + "." + decimal) + "亿";
  }
  //大于6位数是十万 (以10W分割 10W以下全部显示)
  else if (numStr.length > 5) {
    let decimal = numStr.substring(
      numStr.length - 4,
      numStr.length - 4 + point
    );
    return parseFloat(parseInt(num / 10000) + "." + decimal) + "万";
  }
};

/**
 * 大数字转换，将大额数字转换为万、千万、亿等
 * @param value 数字值
 */
export const bigNumberTransform = function(value) {
  if (!number(value)) {
    return value
  }
	const newValue = ['', '', '']
	let fr = 1000
	let num = 3
	let text1 = ''
	let fm = 1
	while (value / fr >= 1) {
		fr *= 10
		num += 1
		// console.log('数字', value / fr, 'num:', num)
	}
	if (num <= 4) { // 千
		newValue[0] = parseInt(value / 1000) + ''
		newValue[1] = '千'
	} else if (num <= 8) { // 万
		text1 = parseInt(num - 4) / 3 > 1 ? '千万' : '万'
		// tslint:disable-next-line:no-shadowed-variable
		fm = text1 === '万' ? 10000 : 10000000
		if (value % fm === 0) {
			newValue[0] = parseInt(value / fm) + ''
		} else {
			newValue[0] = parseFloat(value / fm).toFixed(2) + ''
		}
		newValue[1] = text1
	} else if (num <= 16) { // 亿
		text1 = (num - 8) / 3 > 1 ? '千亿' : '亿'
		text1 = (num - 8) / 4 > 1 ? '万亿' : text1
		text1 = (num - 8) / 7 > 1 ? '千万亿' : text1
		// tslint:disable-next-line:no-shadowed-variable
		fm = 1
		if (text1 === '亿') {
			fm = 100000000
		} else if (text1 === '千亿') {
			fm = 100000000000
		} else if (text1 === '万亿') {
			fm = 1000000000000
		} else if (text1 === '千万亿') {
			fm = 1000000000000000
		}
		if (value % fm === 0) {
			newValue[0] = parseInt(value / fm) + ''
		} else {
			newValue[0] = parseFloat(value / fm).toFixed(2) + ''
		}
		newValue[1] = text1
	}
	if (value < 1000) {
		newValue[0] = value + ''
		newValue[1] = ''
	}
	return newValue.join('')
}
