<template>
  <!-- 个人中心 -->
  <div class="boxCenter">
	<div class="top-box">
		<div class="topTitles">我发布的项目</div>
		<div class="searchBox">
			<el-input placeholder="地区/项目类型/专业/总投资/总规模/服务内容" placeholder-class="placeholderClass" v-model="searchText" @keyup.enter.native="searchTap">
				<i slot="prefix" class="el-input__icon el-icon-search" style="color: #4077DD"></i>
				<template slot="append">
					<el-button type="primary" style="background-color: #4077DD" @click="searchTap">搜索</el-button>
				</template>
			</el-input>
		</div>
	</div>  
	<div class="tabs-navs">
		<div class="left">
			<div class="item" v-for="(item, index) in menuList" :key="index + 'z'" :class="tabIndex == index ? 'actives' : ''" @click="selectUpdata(item, index)">
			  {{ item.title }}({{item.num || '0'}})
			</div>
		</div>
		<div class="right" v-if="tabIndex==4">
			<span style="color: #4077DD; cursor: pointer;" v-if="showType==0" @click="showGetList(1)">显示已取消</span>
			<span style="color: #4077DD; cursor: pointer;" v-if="showType==1" @click="showGetList(0)">隐藏已取消</span>
			<!-- <el-radio v-model="showType" :label="1" @change="showGetList">显示已取消</el-radio> -->
		</div>
	</div>    
	<!-- 状态status（-1拆分项目，1.待审核，2.待付款，3.已发布(报名中)，4.作业中/待验收，5.已完成，6.投诉项目，9.已取消） -->
	<div class="work-list">
		<div class="item" v-for="item in list" :key="item.id">
			<div class="item-top" @click="toDetails(item)">
				<div class="title m-flex ellipsis">
					<div class="ellipsis " style="width: 600px;">
						<span class="titTip" v-if="item.releaseMode==1">快捷发布</span>
						<span class="titTip" v-if="item.parentProjectId!=null && item.parentProjectId > 0">关联项目</span>
						
						<span>{{ item.regionFy }}/{{ item.typeFy }}</span>
						<template v-if="item.majorFy.length">
						   <span v-for="(row, index) in item.majorFy" :key="index">/{{ row.name }}</span>
						</template>
						<span> /总投资:￥{{ item.totalInvestment || "" }} </span>
						<span> /总规模:{{ item.totalScale }}m² </span>
						<span>/{{ item.serviceContentFy }}</span>
					</div>
					<span class="w-m-l-16" style="color: #10C550; font-size: 16px;" v-if="item.status == 5">圆满完成</span>
					<span class="w-m-l-16" style="color: rgba(0,0,0,0.45); font-size: 16px;" v-else-if="item.status == 9">已取消</span>
				</div>
				<div class="item-sn">项目编号:{{ item.sn }}</div>
			</div>
			<div class="item-center" @click="toDetails(item)">
				<div class="left">
					<div class="left-tags">
						<el-tag type="info" class="tags">{{ item.regionFy }}</el-tag>
						<el-tag type="info" class="tags" v-if="item.necessary == 1">需要出现场</el-tag>
						<el-tag type="info" class="tags" v-if="item.provided == 1">提供CAD图纸</el-tag>
						<el-tag type="info" class="tags">{{ item.typeFy }}</el-tag>
						<template v-if="item.majorFy.length">
							<el-tag type="info" class="tags" v-for="(row, index) in item.majorFy" :key="index + 'c'">
								{{ row.name }}
							</el-tag>
						</template>
						<el-tag type="info" class="tags" v-if="item.serviceContentFy">
							{{ item.serviceContentFy}}
						</el-tag>
						<template v-if="item.softwareFy.length">
							<el-tag type="info" class="tags" v-for="(rows, indexs) in item.softwareFy" :key="indexs + 'x'">
								{{ rows.label }}
							</el-tag>
						</template>
					</div>
					<div class="left-price" v-if="tabIndex!=5">
					  <span class="prices" v-if="item.status == 1"> 预估价格：{{ item.estimatedPrice || "" }}</span>
					  <span class="prices" v-else> 平台价格：￥{{ item.orderPrice }}</span>
					  <span class="prices w-m-l-20" v-if="item.projectType == 2"> 固定总价</span>
					</div>
				</div>
				<div class="right">
					<div v-if="item.automaticFail != 1">
						<div class="error pay-type m-flex">
							 <div v-if="item.status == 2 && item.offlineStatus == -1" class="error w-m-r-10">
							   {{ item.automaticTimeFy | limitTimeFilter }}
							 </div>
							 <div v-if="item.status == 4 && item.checkStatus == 3" class="error w-m-r-10">
							   {{ item.checkTimeFy | yanshouTimeFilter }}
							 </div>
							 <div class="status" v-if="item.automaticFail == 1 && item.status == 2">
							   已失效
							 </div>
						</div>
						<div class="status pay-waiting" v-if="item.status == 2 && item.offlineStatus == 1">
						  已上传转账凭证，审核中
						</div>
						<el-popover placement="top" width="300" trigger="hover" v-else-if="item.status == 2 && item.offlineStatus == 3">
						  <div class="">{{item.offRemark || ''}}</div>
						  <span slot="reference">
						     <span class="status error"> 已上传转账凭证，审核失败</span>
						  </span>
						</el-popover>
					</div>
					<div class="" v-else>
						<span style="color: #999;">如要重启项目支付，请联系项目经理</span>
					</div>
					<div class="m-flex" v-if="item.status == 3">
						<div>
							<div class="sure-info" v-if="item.signUpNum > 0">
								<img src="../../../static/images/project/hot@2x.png" />
								<span> {{item.signUpNum}} 个工程师已报名</span>
							</div>
							<div class="no-info" v-else>暂无工程师报名</div>
							<!-- <div class="error-info">授权人员【望江楼】已取消报名，请及时重新授权</div> -->
						</div>
						<div class="status error w-m-l-10" v-if="item.status == 3 && item.zpEngineer == 1">
						  未指派
						</div>
						<div class="status blue w-m-l-10" v-if="item.status == 3 && item.zpEngineer == 2 && item.signUpNum > 0">
						  工程师确认中
						</div>
					</div>
					<div v-if="item.status == 5 && tabIndex!=5">
						<div class="rate-list">
							<div class="item-rate">
							   <div class="item-title">客户评分：</div>
							   <el-rate v-model="item.userEvaluate" disabled disabled-void-color="#D8D8D8"></el-rate>
							</div>
							<div class="item-rate">
								<div class="item-title">项目经理评分：</div>
								<el-rate v-model="item.mangerEvaluate" disabled disabled-void-color="#D8D8D8"></el-rate>
							</div>
						</div>
					</div>
					<div class="" v-if="tabIndex==5 && item.tprojectComplainList!=null">
						<span style="color: #FFB817;" v-if="item.tprojectComplainList.status==1">待处理</span>
						<span style="color: #20E087;" v-if="item.tprojectComplainList.status==2">已通过</span>
						<span class="error" v-if="item.tprojectComplainList.status==3">已拒绝</span>
						<span style="color: #1890ff;" v-else-if="item.tprojectComplainList.statuss == 4">已接收</span>
						<span style="color: #999999;" v-else-if="item.tprojectComplainList.status == 9" >已关闭</span>
					</div>
				</div>
			</div>
			<div class="item-timeBtn" v-if="tabIndex!=5">
				<div class="itme-time">
					<span class=""> 项目交付时间：{{ item.deliveryDeadline }}</span>
					<span v-if="[5].includes(menuList[tabIndex].status) == true" style="margin-left: 25px; color: #2BD181;" >质保期剩余时间：{{ item.warrantyDay }}</span>
				</div>
				<div class="itme-btn m-flex mdx-col-center">
					<template v-if="item.status == 4">
					  <el-dropdown @command="handleCommand">
					    <el-button size="small" class="more-btn w-m-r-10" type="text" @click.stop="">更多</el-button>
					    <el-dropdown-menu slot="dropdown">
							<el-dropdown-item :command="{item, type: 11, }" v-if="item.checkStatus == 3">延期验收</el-dropdown-item>
							<!-- <el-dropdown-item :command="{item, type: 10, }" v-if="item.checkStatus == -1 && item.parentProjectId==null && item.splitProjects==null || item.checkStatus == -1 && item.parentProjectId==null && item.splitProjects.length <= 0">发布关联</el-dropdown-item> -->
							<el-dropdown-item :command="{item, type: 9, }">投诉项目</el-dropdown-item>
					    </el-dropdown-menu>
					  </el-dropdown>
					</template>
					<template v-if="item.status == 1 && !item.major">
					   <!-- 已发布待审核 -->
					   <el-button type="primary" @click.stop="alertItem(item)" >完善需求</el-button>
					</template>
					
					<template v-if="item.status == 2 && item.offlineStatus == -1 && item.automaticFail == 2">
					   <el-button type="primary" @click.stop="payPuopShow(item)">立即支付</el-button>
					</template>
					<template v-if="item.status == 2 && item.offlineStatus == 3 && item.automaticFail!=1">
					    <el-button type="primary" @click.stop="payPuopShow(item)">重新支付</el-button>
					</template>
					<template v-if="item.status == 2 && item.offlineStatus == 1">
					   <el-button type="primary" @click.stop="showJindu(item)">审核进度</el-button>
					</template>
					<!-- <template v-if="index==2">
					  <el-button type="primary">审核进度</el-button>
					</template> -->
					<!-- <template v-if="index==3">
					  <el-button type="primary" @click.stop='payPuopShow()'>重新支付</el-button>
					</template> -->
					<template v-if="item.status == 1 || (item.status == 2 && (item.offlineStatus == -1 || item.offlineStatus == 3))">
					  <el-button size="small" class="pay-type-btn" @click.stop="cancelRelease(item, index)">取消发布</el-button>
					</template>
					<!-- <template v-if="index==3">
					  <el-button @click.stop='cancelApply()'>取消申请</el-button>
					</template> -->
					<template v-if="item.status == 3">
						<!-- 已发布待审核 -->
						<el-button class="pay-type-btn" size="small" type="primary" @click="quanSave(item)">权限设置 </el-button>
					</template>
					<template v-if="item.status == 3 && (item.zpEngineer == 1 || item.zpEngineer == 2)">
						<!-- 已发布未分配 -->
						<el-button size="small" type="primary" @click.stop="openInvites(item)"> 邀请工程师</el-button>
					</template>
					<template v-if="item.status == 3 && item.isCancel == 1">
					  <el-button type="primary" @click.stop="cancelOrder(item, index)">取消订单</el-button>
					</template>
					<template v-if="item.status == 4 && item.isBjJe == 2">
					   <el-button @click.stop="payPuopShow(item)">支付尾款</el-button>
					</template>
					<template v-if="item.status == 3 || item.status == 4">
					   <el-button type="primary" @click.stop="suppleTap(item)">补充附件</el-button>
					</template>
					<template v-if="item.status == 4 && item.checkStatus == 3">
					   <el-button type="primary" @click.stop="acceptShowBtns(item)">验收成果</el-button>
					</template>
					<template v-else-if="item.status == 4">
					   <el-button @click.stop="acceptShowBtns(item)" disabled>验收成果</el-button>
					</template>
					
					<template v-if="item.status == 5">
					    <el-button type="primary" :loading="loading" @click.stop="systemThDj(item)" v-if="item.depositBalance > 0">提前退还质保金</el-button>
					    <el-button type="primary" :loading="loading" v-else disabled @click.stop="">已退还质保金</el-button>
					    <el-button type="primary" :loading="loading" @click.stop="invoiceBtn(item)" v-if="item.isInvoice == 1 || item.isInvoice == 3">申请开发票</el-button>
					    <el-button type="primary" :loading="loading" v-else-if="item.isInvoice == 4 || item.isInvoice == 5" disabled @click.stop="invoiceBtn(item)">已申请开票</el-button>
					    <el-button type="primary" :loading="loading" v-else disabled @click.stop="invoiceBtn(item)">申请开发票</el-button>
					    <el-button type="primary" @click.stop="evalutShowtap(item, 1)" v-if="item.isEvaluateUser == 0">去评价 </el-button>
					    <el-button @click.stop="evalutShowtap(item, 2)" :loading="loading" v-else>查看评价</el-button>
						<el-button size="small" type="primary" @click="lookBtn(item)">查看账单</el-button>
					</template>
					
				</div>
			</div>
			<div class="payContextBox" v-if="tabIndex > 1 && item.progress && tabIndex!=5">
			  <!-- {{item.progress}} -->
			  <span>项目进展：</span>{{ item.progress.title }} {{ " " }}{{ item.progress.createTime }}
			</div>
			
			<div class="ts-info" v-if="tabIndex==5 && item.tprojectComplainList!=null">
				<div class="ts-top">
					<div class="ts-title">
						<span v-if="item.tprojectComplainList.status==1 || item.tprojectComplainList.status==4 || item.tprojectComplainList.status==9">客户投诉：</span>
						<span v-if="item.tprojectComplainList.status==2 || item.tprojectComplainList.status==3">投诉原因：</span>
					</div>
					<div class="ts-type">
						<span v-if="item.tprojectComplainList.reason==1">工作成果质量存在问题</span>
						<span v-if="item.tprojectComplainList.reason==2">项目交付时间延误</span>
						<span v-if="item.tprojectComplainList.reason==3">造价师服务态度不好</span>
						<span v-if="item.tprojectComplainList.reason==4">造价师个人经验及信息与实际不符</span>
						<span v-if="item.tprojectComplainList.reason==5">其他问题</span>
					</div>
				</div>
				<div class="ts-cont">
					<span v-if="item.tprojectComplainList.status==1 || item.tprojectComplainList.status==4 || item.tprojectComplainList.status==9">{{item.tprojectComplainList.remark || ''}}</span>		
					<span v-if="item.tprojectComplainList.status==2 || item.tprojectComplainList.status==3">{{item.tprojectComplainList.clRemark || ''}}：</span>
				</div>
				<div class="ts-time">
					<span v-if="item.tprojectComplainList.status==1 || item.tprojectComplainList.status==4 || item.tprojectComplainList.status==9">投诉时间：{{item.tprojectComplainList.createTime || ''}}</span>
					<span v-if="item.tprojectComplainList.status==2 || item.tprojectComplainList.status==3">处理时间：{{item.tprojectComplainList.updateTime || ''}}</span>
				</div>
			</div>
			
			<img src="../../../static/images/project/type-icon-1@2x.png" class="top-left-tabs" v-if="item.status==4 && item.checkStatus == -1" />
			<img src="../../../static/images/project/type-icon-3@2x.png" class="top-left-tabs"  v-if="item.status==4 && item.checkStatus == 1 || item.status==4 && item.checkStatus == 2 || item.status==4 && item.checkStatus == 6" />
			<img src="../../../static/images/project/type-icon-2@2x.png" class="top-left-tabs" v-else-if="(item.status == 4 && item.checkStatus == 3) || (item.status == 4 && item.checkStatus == 5)" />
		</div>
		<div class="mdx-flex mdx-row-center" style="margin-top: 30px; margin-bottom: 32px;" v-if="list.length" >
		  <el-pagination :pageSize="queryParams.limit" :currentPage="queryParams.page" background layout="prev, pager, next" @current-change="pageChange" :page-count="queryParams.totalPage || 1"></el-pagination>
		</div>
	</div>
	  
	 <!--付款弹框--> 
    <el-dialog class="tipPopup" title="提示" :visible.sync="payShow" :before-close="payClose" width="40%" center top="20vh">
      <div class="puopContext" style="font-family: PingFangSC, PingFang SC; font-weight: 400; font-size: 18px; color: rgba(0,0,0,0.85); line-height: 36px;">
        <div>
          {{ dataItem.regionFy }}/{{ dataItem.typeFy }}
          <!-- {{dataItem.majorFy}} -->
          <template v-if="dataItem.majorFy.length">
            <span v-for="(row, indexss) in dataItem.majorFy" :key="indexss">/{{ row.name }}</span>
          </template>
          <span> /总投资:￥{{ dataItem.totalInvestment || "00" }} </span>
          <span> /总规模:{{ dataItem.totalScale }}m² </span>
          /{{ dataItem.serviceContentFy }}
        </div>
        <div>编号：{{ dataItem.sn }}</div>
        <div>
          平台价格：￥{{ dataItem.orderPrice || "0.00" }}
          <span v-if="dataItem.projectType == 2" class="error w-m-l-10">
            固定总价
          </span>
        </div>
        <div>交付时间：{{ dataItem.deliveryDeadline || "****-**-**" }}</div>
        <div class="blue" style="font-family: PingFangSC, PingFang SC; font-weight: 400; font-size: 14px; color: rgba(0,0,0,0.45); line-height: 20px;">
          <!-- 您付款后各专业项目将进入项目大厅由线上工程师申请。项目经理将联系平台推荐的优秀工程师进行确认、派单。如截至您付款后24小时，某专业项目仍无工程师申请和确认接单，您或项目经理均可以取消该无人接单的专业项目，平台将向您退还该专业项目的服务费；您也可以联系项目经理取消该需求内的所有专业项目，平台将向您退还尚未开始作业部分的工作对应的服务费。 -->
          <!-- 如您希望直接以单位名义签约和履约，可点击下载<a href="javascript:void(0)" class="proxy-statement" @click.stop="downloadStatement()">《授权委托书》</a>格式，由单位填写、加盖公章后上传扫描件至本页。我们将凭您上传的真实、有效、内容正确的授权委托书认定您在本项目下的操作是受单位委托。 -->
		  您付款后项目将进入项目大厅由工程师申请。项目经理将联系平台匹配推荐的优秀工程师进行确认、派单。付款前，如您对服务费用有异议，请不要进行付款操作，可与本公司取得联系；如您无异议，可进行付款操作。您付款完成，即表示您最终同意、确认按照该价格接受本公司提供的服务，不得再以其他理由提出任何异议。
        </div>
        <div class="">
          <div class="check mdx-flex mdx-row-center" style="margin-top: 10px">
            <el-checkbox-group v-model="checkList" style="margin-top: 5px">
              <el-checkbox label="" :key="1"></el-checkbox>
            </el-checkbox-group>
            <div style="font-family: PingFangSC, PingFang SC;font-weight: 400; font-size: 16px; color: rgba(0,0,0,0.85); line-height: 20px; margin-left: 8px;">
              我已阅读并同意
              <span @click="$mHelper.otherTo('/xieyi?type=12')" style="color: #4077DD;">《中一鼎合造价众包平台项目服务协议》</span>
            </div>
          </div>
        </div>
        <div class="bottom-btn-bar mdx-text-center w-m-t-20">
          <!-- <el-upload class="avatar-uploader" :action="action" :show-file-list="false" :on-success="handleStatementSuccess" :on-progress="starUploadStatement">
            <el-button type="primary" style="padding: 10px 30px; border-radius: 2px" :loading="uploadingStatement">
              {{ upStatementName }}
            </el-button>
          </el-upload> -->
          <el-button type="primary" style="padding: 10px 30px; border-radius: 2px" class="pay-btn w-m-t-20" @click="showPayType">
            去付款
          </el-button>
        </div>
        <div class="statement-file-box">
          <div v-if="statementFile.name">
            <i class="el-icon-document"></i>
            <span class="file-name">{{ statementFile.name }}</span>
            <span class="upload-result">上传成功！</span>
            <i class="el-icon-close" @click.stop="removeStatementFile"></i>
          </div>
        </div>
      </div>
    </el-dialog>
	
	<!--付款选择-->
    <el-dialog class="tipPopup" title="支付方式" :visible.sync="payTypeShow" width="40%" top="20vh">
      <div style="font-weight: 550; font-size: 18px; text-align: center">
        {{ dataItem.isBjJe == 2 ? "支付尾款" : "" }}
      </div>
      <div class="" style="font-family: PingFangSC, PingFang SC; font-weight: 400; font-size: 18px; color: rgba(0,0,0,0.85); line-height: 36px;">
        <div>
          {{ dataItem.regionFy }}/{{ dataItem.typeFy }}
          <!-- {{dataItem.majorFy}} -->
          <template v-if="dataItem.majorFy.length">
            <span v-for="(row, indexss) in dataItem.majorFy" :key="indexss"
              >/{{ row.name }}</span
            >
          </template>
          <span> /总投资:￥{{ dataItem.totalInvestment || "00" }} </span>
          <span> /总规模:{{ dataItem.totalScale }}m² </span>
          /{{ dataItem.serviceContentFy }}
        </div>
        <div v-if="dataItem.isBjJe">项目编号：{{ dataItem.sn || "" }}</div>
        <div class="">
          <el-checkbox-group v-model="paycheck" @change="bindCheckBox">
            <div class="payLines">
              <el-checkbox label="1"><i class="wzk wzk-weixinPay" style="color: #3bb431; margin-right: 5px;"></i>微信支付</el-checkbox>
            </div>
            <div class="payLines">
              <el-checkbox label="2"><i class="wzk wzk-zhifubaoPay" style="color: #1677ff; margin-right: 5px;"></i>支付宝支付</el-checkbox>
            </div>
            <div class="payLines">
              <el-checkbox label="3"><i class="wzk wzk-xianxiaPay" style="color: #fd6727; margin-right: 5px;"></i>线下转账</el-checkbox>
            </div>
			<div class="payLines">
			  <el-checkbox label="7"><i class="wzk wzk-xianxiaPay" style="color: #FF3B30; margin-right: 5px;"></i>余额支付</el-checkbox>
			</div>
          </el-checkbox-group>
        </div>
        <div class="mdx-flex mdx-row-right">
          <div class="w-m-r-20 error">
            <h3>
              应付金额：￥{{
                dataItem.isBjJe == 2 ? dataItem.bjKhJe : dataItem.orderPrice
              }}
            </h3>
          </div>
          <el-button type="primary" :loading="loading" style="padding: 10px 30px; border-radius: 2px" class="" @click="payEnd()">
            去付款
          </el-button>
        </div>
      </div>
    </el-dialog>
	
	
    <el-dialog class="tipPopup" title="支付提示" :visible.sync="transShow" width="40%" center top="20vh">
      <div class="puopContext" style="font-family: PingFangSC, PingFang SC; font-weight: 500; font-size: 18px; color: rgba(0,0,0,0.85); line-height: 36px;">
        <div>
          {{ dataItem.regionFy }}/{{ dataItem.typeFy }}
          <!-- {{dataItem.majorFy}} -->
          <template v-if="dataItem.majorFy.length">
            <span v-for="(row, indexss) in dataItem.majorFy" :key="indexss"
              >/{{ row.name }}</span
            >
          </template>
          <span> /总投资:￥{{ dataItem.totalInvestment || "00" }} </span>
          <span> /总规模:{{ dataItem.totalScale }}m² </span>
          /{{ dataItem.serviceContentFy }}
        </div>
        <div class="error w-font-14">
          请将您要充值的金额在银行柜台、网银、手机银行转账至： (
          该账户为公司账户，仅支持对公转账，如您银行转账遇到相关问题，请联系400-xxx-xxxx
          )
        </div>
        <div>银行开户名： {{ bankInfo.name || "" }}</div>
        <div>银行账号： {{ bankInfo.account || "" }}</div>
        <div>开户银行： {{ bankInfo.deposit || "" }}</div>
        <div style="height: 1px"></div>
        <div class="w-font-13">线下交易流程：</div>
        <div class="w-font-13">1.请在汇款后，保存汇款凭证</div>
        <div class="w-font-13">2.点击上传凭证按钮</div>
        <div class="w-font-13">3.上传转账凭证</div>
        <div class="w-font-13">
          4.平台审核通过后，此项目会进入到【进行中的项目】，平台开始工作
        </div>
        <div class="mdx-flex mdx-row-right w-m-t-10">
          <div class="w-m-r-20 error">
            <h3>应付金额：￥{{ dataItem.isBjJe == 2 ? dataItem.bjKhJe : dataItem.orderPrice || "0.00" }}</h3>
			
          </div>
          <el-button type="primary" style="padding: 10px 30px; border-radius: 2px" class="" @click="upPingZheng()">
            去付款
          </el-button>
        </div>
      </div>
    </el-dialog>
   
	<!-- <el-dialog :visible.sync="credentialsShow" show-cancel-button :before-close='handleClose' width="40%" center
      top='20vh'>
      <div class="puopContext w-font-13">
        <div>
          {{dataItem.regionFy}}/{{dataItem.typeFy}}
          <template v-if="dataItem.majorFy.length">
            <span v-for="(row,indexss) in dataItem.majorFy" :key="indexss">/{{row.name}}</span>
          </template>
          <span>
            /总投资:￥{{dataItem.totalInvestment||'00'}}
          </span>
          <span>
            /总规模:{{dataItem.totalScale}}m²
          </span>
          /{{dataItem.serviceContentFy}}
        </div>
        <div class="wzk-cursor">
          <img src="../../../static/images/jindu.png" style="width: 100%;">
        </div>
        <div>
          <el-form label-position="right" label-width="100px" :model="credFrom">
            <el-form-item label="开户银行:">
              <el-input v-model="credFrom.name" placeholder="请输入开户银行"></el-input>
            </el-form-item>
            <el-form-item label="付款人账号:">
              <el-input v-model="credFrom.name" placeholder="请输入付款人账号"></el-input>
            </el-form-item>
            <el-form-item label="上传回执单:">
              <el-upload class="upload-demo" :action="action" :on-success="receiptSuccess" :on-remove="handleRemove"
                multiple :limit="1" :on-exceed="handleExceed" :file-list="fileList">
                <el-button size="small" type="primary">点击上传</el-button>
              </el-upload>
            </el-form-item>
          </el-form>
        </div>

        <div class="mdx-flex mdx-row-right w-m-t-20">
          <el-button type="primary" style="padding: 10px 30px;border-radius: 2px;" class="" @click='upPingZheng()'>
            提交凭证
          </el-button>
          <el-button style="padding: 10px 30px;border-radius: 2px;" class="" @click='handleClose'>
            取消
          </el-button>
        </div>
      </div>
    </el-dialog> -->
    <el-dialog class="tipPopup" title="凭证"  :visible.sync="credentialsShow" show-cancel-button :before-close="handleClose"  width="40%" center top="20vh">
      <div class="puopContext w-font-13">
        <div style="font-family: PingFangSC, PingFang SC; font-weight: 400; font-size: 18px; color: rgba(0,0,0,0.85); line-height: 36px; margin-bottom: 20px;">
          {{ dataItem.regionFy }}/{{ dataItem.typeFy }}
          <!-- {{dataItem.majorFy}} -->
          <template v-if="dataItem.majorFy.length">
            <span v-for="(row, indexss) in dataItem.majorFy" :key="indexss">/{{ row.name }}</span>
          </template>
          <span> /总投资:￥{{ dataItem.totalInvestment || "00" }} </span>
          <span> /总规模:{{ dataItem.totalScale }}m² </span>
          /{{ dataItem.serviceContentFy }}
        </div>

        <div class="wzk-cursor" v-if="dataItem.status == 2 && dataItem.offlineStatus == 1">
          <img src="../../../static/images/jinduZhong.png" style="width: 100%" />
        </div>
        <div class="wzk-cursor" v-else>
          <img src="../../../static/images/jindu.png" style="width: 100%" />
        </div>
        <div>
          <el-form label-position="right" label-width="100px" :model="bankFrom" :rules="transferRules" ref="xianxiaPays" >
            <el-form-item label="开户银行:" prop="bankDeposit">
              <el-input v-model="bankFrom.bankDeposit" placeholder="请输入开户银行"></el-input>
            </el-form-item>
            <el-form-item label="付款人账号:" prop="paymentAccount">
              <el-input v-model="bankFrom.paymentAccount" placeholder="请输入付款人账号"></el-input>
            </el-form-item>
            <!-- {{bankFrom.receipt}} -->
            <el-form-item label="上传回执单:" prop="receipt">
              <el-upload  class="upload-demo" :action="action" :on-success="receiptSuccess" :on-remove="handleRemove" multiple :limit="1" :on-exceed="handleExceed" :file-list="fileList">
                <el-button size="small" type="primary">点击上传</el-button>
              </el-upload>
            </el-form-item>
          </el-form>
        </div>
        <div class="mdx-flex mdx-row-right w-m-t-20">
          <el-button type="primary" style="padding: 10px 30px; border-radius: 2px" class="" :loading="loading" @click="upataPingzheng()">
            提交凭证
          </el-button>
          <el-button style="padding: 10px 30px; border-radius: 2px" class="" @click="handleClose">
            取消
          </el-button>
        </div>
      </div>
    </el-dialog>
	
	
	<!--质控审核-->
	<el-dialog class="tipPopup" title="质控审核" :visible.sync="acceptShow" width="30%" center top='20vh'>
	  <div class="">
		  <div class="title w-m-b-30" style="color: rgba(0,0,0,0.85);">项目经理已审核通过验收成果，待您进行二次确认验收。请先<span class="cursor" style="color: #4077DD;" @click.stop="downWenjian">下载成果文件</span>  进行查看，如果您确认验收成果文件，则视为您已经认可了工程师的工作成果，项目将正式结束并进入质保期。</div>
		 <!-- <el-form ref="form" label-width="80px">
			   <el-form-item label="验收结果:">
				 <div class="mdx-flex">
					 <div>
						 <el-radio v-model="rejectType" :label="1">通过</el-radio>
						 <el-radio v-model="rejectType" :label="2">不通过</el-radio>
					 </div>
				 </div>
			   </el-form-item>
			   <el-form-item label="驳回原因:" prop="rejectContent" v-if="rejectType==2">
					<el-input v-model="rejectContent" placeholder="如若不通过，请在此说明理由" type="textarea" max="255" :autosize="{ minRows: 4, maxRows: 6 }" class="input"></el-input>
			   </el-form-item>
		  </el-form> -->
		  <span slot="footer" class="dialog-bom mdx-flex mdx-row-center w-m-t-50">
			<el-button class="close" size="small" @click.stop="acceptShow=false">取 消</el-button>
		    <el-button class="w-m-l-24" type="primary" size="small" :loading="loading"  @click.stop="accepBtns">确 定</el-button>
		  </span>
	  </div>
	</el-dialog>
	
    <el-dialog class="tipPopup" title="提示" :visible.sync="credSuccessShow" width="40%" center top="20vh">
      <div class="puopContext w-font-13">
         <div style="font-family: PingFangSC, PingFang SC; font-weight: 400; font-size: 18px; color: rgba(0,0,0,0.85); line-height: 36px; margin-bottom: 20px;">
          {{ dataItem.regionFy }}/{{ dataItem.typeFy }}
          <!-- {{dataItem.majorFy}} -->
          <template v-if="dataItem.majorFy.length">
            <span v-for="(row, indexss) in dataItem.majorFy" :key="indexss"
              >/{{ row.name }}</span
            >
          </template>
          <span> /总投资:￥{{ dataItem.totalInvestment || "00" }} </span>
          <span> /总规模:{{ dataItem.totalScale }}m² </span>
          /{{ dataItem.serviceContentFy }}
        </div>
        <div class="wzk-cursor">
          <img
            src="../../../static/images/jinduZhong.png"
            style="width: 100%"
          />
        </div>
        <div class="mdx-text-center" style="margin: 50px 0">
          <i class="el-icon-success w-font-120" style="color: #185ba1"></i>
          <p>您已成功上传凭证！请等待平台审核</p>
        </div>
      </div>
    </el-dialog>
    
	
    <el-dialog class="tipPopup" title="补充附件" :visible.sync="supplementShow" show-cancel-button :before-close="handleClose" width="40%" center top="20vh">
      <div class="puopContext w-font-13">
        <div style="font-family: PingFangSC, PingFang SC; font-weight: 400; font-size: 18px; color: rgba(0,0,0,0.85); line-height: 36px; margin-bottom: 20px;">
          {{ dataItem.regionFy }}/{{ dataItem.typeFy }}
          <!-- {{dataItem.majorFy}} -->
          <template v-if="dataItem.majorFy.length">
            <span v-for="(row, indexss) in dataItem.majorFy" :key="indexss">/{{ row.name }}</span>
          </template>
          <span> /总投资:￥{{ dataItem.totalInvestment || "00" }} </span>
          <span> /总规模:{{ dataItem.totalScale }}m² </span>
          /{{ dataItem.serviceContentFy }}
        </div>
        <div class="w-m-t-10">
          <el-upload
            class="upload-demo"
            ref="files"
            :action="action"
            :on-success="fujianSuccess"
            :on-progress="onProgress"
            :on-error="onError"
            :on-remove="fujianRemove"
            multiple
            :limit="9"
            :on-exceed="fujianExceed"
            :file-list="fileList"
          >
            <el-button size="small" type="primary">上传文件</el-button>
            <span v-if="onPrepareFile" class="prepare-file">
              文件准备中，请稍后… 文件名末尾显示
              <i class="tip-success el-icon-circle-check"></i>
              才算上传成功
            </span>
          </el-upload>
        </div>
        <div class="mdx-flex mdx-row-center w-m-t-30">
          <el-button type="primary" style="padding: 10px 40px; border-radius: 2px" class="" @click.stop="suppleUpdata()"> 提交</el-button>
        </div>
      </div>
    </el-dialog>
    <el-dialog class="pingCss"  title="项目评价" :visible.sync="evalutShow" show-cancel-button  width="40%" center top="20vh">
      <div class="w-font-13">
		<div class="m-flex">
		   <div class="ping-info">服务态度：</div>
		   <el-rate v-model="star1" :disabled="evalutType == 2"></el-rate>
		</div>
        <div class="w-m-t-12">
          <el-input  type="textarea"  placeholder="请输入" :disabled="evalutType == 2" v-model.trim="starText1" :autosize="{ minRows: 3, maxRows: 3 }" maxlength="255" show-word-limit />
        </div>
		<div class="m-flex w-m-t-24">
		   <div class="ping-info">成果质量：</div>
		    <el-rate v-model="star2" :disabled="evalutType == 2"></el-rate>
		</div>
		<div class="w-m-t-12">
		  <el-input  type="textarea"  placeholder="请输入" :disabled="evalutType == 2" v-model.trim="starText2" :autosize="{ minRows: 3, maxRows: 3 }" maxlength="255" show-word-limit />
		</div>
        <div class="mdx-flex mdx-row-center w-m-t-30 w-m-b-30" >
          <el-button type="primary" :loading="loading" style="padding: 10px 40px; border-radius: 2px" class="" @click.stop="CommontUpdata()" v-if="evalutType == 1">
            提交
          </el-button>
        </div>
      </div>
    </el-dialog>
    <el-dialog class="tipPopup" title="微信支付" :visible.sync="WechatShow" show-cancel-button @close="wxPayPuopClose" width="700px" center top="20vh">
      <div class="w-font-13">
        <div class="mdx-flex mdx-row-between" style="margin-top: 50px">
          <div class="w-m-l-20">
            <div class="qrcode" id="qrcode" ref="qrCodeUrl"></div>
            <div>
              <el-button type="primary" style="width: 250px; border-radius: 0">
                <div class="mdx-flex">
                  <i class="wzk wzk-full-screen" style="font-size: 30px; margin-right: 10px"></i>
                  <div class="mdx-text-left">
                    请使用微信扫一扫<br />
                    扫描二维码支付
                  </div>
                </div>
              </el-button>
            </div>
          </div>
          <div class="w-m-r-50">
            <img src="../../../static/images/wxImg1.png" height="350px" />
          </div>
        </div>
      </div>
    </el-dialog>

	<!--报名人员权限设置-->
	<el-dialog :visible.sync="saveQxShow" width="40%" center top='15vh' class="proj-box">
	  <div class="">
		<div class="dialog-title">报名工程师</div>
		<div class="searchBox w-m-t-16">
		    <el-input placeholder="请输入工程师编号" placeholder-class="placeholderClass" v-model="searchPersonText" @keyup.enter.native="searchPersonTap">
		      <i slot="prefix" class="el-input__icon el-icon-search" style="color: #bfbfbf"></i>
		      <template slot="append">
		        <el-button type="primary" style="background-color: #4077DD" @click="searchPersonTap">搜索</el-button>
		      </template>
		    </el-input>
		</div>
	    <el-form label-position='top' label-width="80px">
	      <el-form-item>
			<div class="pro-list">
				<div class="item">
					<el-radio v-model="addCheckId" :label="item.id" v-for="(item,index) in bmList" :key="index" >
						<div class="block">
							<el-image class='image-slot' :src="item.headImgUrl">
								<div slot="error" class="image-slot">
									<img src="../../../static/images/mrtx.png" style="width: 46px;height: 46px;border-radius: 50%;">
								</div>
							</el-image>
						</div>
						<div class="user-box">
						   <div class="item-title mdx-row-between">
							  <div class="mdx-flex mdx-col-center">
								  <span>{{ $mHelper.nameSlice(2, userInfo, item.name, item.name) }}  {{item.engineerNo || ''}}</span>
								  <span class="tj-title" v-if="item.invitation==0">客户邀请</span>
								 <!-- <span class="tj-title" v-if="item.invitation==1">项目经理邀请</span> -->
								  
								  <el-popover placement="right" width="300" trigger="hover" v-if="item.invitation==1">
								    <div class="" v-if="item.inviteReason!=null">{{item.inviteReason}}</div>
								    <span slot="reference">
								       <span class="tj-title">项目经理邀请</span>
								    </span>
								  </el-popover>
								  
								  <el-popover placement="right" width="300" trigger="hover" v-if="item.is_fm==1">
								    <div class="">该工程师在进行中的项目较多，请谨慎选择</div>
								    <span slot="reference">
								       <span class="tj-title" style="color: #FF4D4F; border-color: #FF4D4F;">繁忙</span>
								    </span>
								  </el-popover>
								  <el-rate v-model="item.rateValue" disabled></el-rate>
							  </div>
							  <span class="w-m-r-20" style="color: #10C550;" v-if="item.jurisdiction==1">已授权</span>
						   </div>
						   <div class="item-tabs">
								<span>精通{{item.majorFy.length || '0'}}个专业</span>
								<span>精通{{item.softwareFy.length || '0'}}个专业软件</span>
								<span>精通{{item.typeFy.length || '0'}}个项目类型</span>
								<span>擅长{{item.regionFy.length || '0'}}个地区</span>
								<span>{{item.years || '0'}}年工作经验</span>
								<span v-if="item.files!=null">有专业证书</span>
						   </div>
						</div>
						
					</el-radio>
				</div>
			</div>
	        
	      </el-form-item>
	      <div class="dialog-bom mdx-flex mdx-row-center w-m-t-35">
	         <el-button size="small" class="close" @click="saveQxShow = false">取 消</el-button>
			 <el-button size="small" class="close" @click="OpenTakeBack(2)">收回权限</el-button>
	         <el-button size="small" type="primary" @click="OpenTakeBack(1)">确认授权</el-button>
	      </div>
	    </el-form>
	  </div>
	</el-dialog>

    <!-- 项目投诉 -->
    <el-dialog class="tipPopup" title="投诉" :visible.sync="complaint" show-cancel-button :before-close="handleComplaint" width="55%" center>
      <div class="w-font-14 error w-m-t-50">
        投诉前建议您先与该项目的项目经理沟通，如不能解决您的问题，再选择此渠道。投诉后平台将在3个工作日内与您联系
      </div>
      <div class="w-m-t-20">
        <el-form label-position="left" label-width="100px" :model="formLabelAlign" :rules="rules" ref="fromref">
          <el-form-item label="项目编号" prop="sn">
            <div>{{ formLabelAlign.sn }}</div>
          </el-form-item>
          <el-form-item label="项目名称" prop="name">
            <div>{{ formLabelAlign.name }}</div>
          </el-form-item>
          <el-form-item label="投诉原因" prop="check">
            <el-checkbox-group v-model="formLabelAlign.check">
              <div v-for="(city, index) in cities" :label="city" :key="city">
                <el-checkbox :label="index + 1" @change="checkbox(index + 1)"
                  >{{ city }}
                </el-checkbox>
              </div>
            </el-checkbox-group>
          </el-form-item>
          <el-form-item label="投诉说明" prop="remark">
            <el-input type="textarea" maxlength="255" :autosize="{ minRows: 4, maxRows: 6 }" placeholder="请详细描述您要投诉的原因，方便平台快速处理您的问题" v-model="formLabelAlign.remark" ></el-input>
          </el-form-item>
          <el-form-item label="联系方式" prop="contactMethod">
            <el-input v-model="formLabelAlign.contactMethod" placeholder="请输入联系电话"></el-input>
          </el-form-item>
          <span slot="footer" class="dialog-footer">
            <el-button type="primary" style="padding: 10px 40px; border-radius: 2px" @click="subimtBtn">
              提交
            </el-button>
            <el-button style="padding: 10px 40px; border-radius: 2px; margin-left: 60px" @click="complaint = false">
              取消
            </el-button>
          </span>
          <el-form-item label="" style="margin-left: 0 !important" class="box">
            <div class="mdx-text-center">
              <el-button type="primary" style="padding: 10px 30px" @click="subimtBtn" >提交</el-button >
              <el-button class="w-m-l-30" style="padding: 10px 30px" @click="handleComplaint" >取消</el-button >
            </div>
          </el-form-item>
        </el-form>
      </div>
    </el-dialog>
	
	<!--账单查询-->
	<el-dialog title="账单详情" :visible.sync="lookPrice"  width="40%" left top='10vh' class="look-box">
	  <div class="">
		  <div class="title">
			  {{dataItem.regionFy}}/{{dataItem.typeFy}}
			  <!-- {{dataItem.majorFy}} -->
			  <template v-if="dataItem.majorFy.length">
			    <span v-for="(row,indexss) in dataItem.majorFy" :key="indexss">/{{row.name}}</span>
			  </template>
			  <span>
			    /总投资:￥{{dataItem.totalInvestment||'00'}}
			  </span>
			  <span>
			    /总规模:{{dataItem.totalScale}}m²
			  </span>
			  /{{dataItem.serviceContentFy}}
		  </div>
		  <div class="look-title">客户服务费如下:</div>
		  <div class="look-bbx">
			  <div class="m-flex color1">
				  <div class="left">初始服务费:</div>
				  <div class="right">{{zdList.service || '0.00'}}元</div>
			  </div>
			  <div class="m-flex color1">
				  <div class="left">少补服务费:</div>
				  <div class="right">{{zdList.service1 || '0.00'}}元</div>
			  </div>
			  <div class="m-flex color1">
				  <div class="left">多退服务费:</div>
				  <div class="right">{{zdList.service2 || '0.00'}}元</div>
			  </div>
			  <div class="m-flex color1">
				  <div class="left">投诉退还服务费:</div>
				  <div class="right">{{zdList.service3 || '0.00'}}元</div>
			  </div>
			  <div class="lines-next"></div>
			  <div class="m-flex color3 ">
				  <div class="left">服务费总额:</div>
				  <div class="right">{{zdList.service4 || '0.00'}}元</div>
			  </div>
			  <div class="m-flex color2">
				  <div class="left">已结算:</div>
				  <div class="right">{{zdList.service5 || '0.00'}}元</div>
			  </div>
			  <div class="m-flex color2 w-m-b-16">
				  <div class="left">待结算:</div>
				  <div class="right">{{zdList.service6 || '0.00'}}元</div>
			  </div>
		  	  			  
		  </div>
	  </div>
	</el-dialog>
	
    <!-- 投诉成功 -->
    <el-dialog  title="提示" :visible.sync="commSucces" :modal="false" width="30%" top="40vh" center :show-close="false"  @click.stop.native="commSucces = true" :close-on-click-modal="false" >
      <div style=" text-align: center; color: #333333ff; font-size: 16px; margin-top: 20px; " >
        您已投诉成功！
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button plain @click.stop="commSucces = false" >确认</el-button>
      </span>
    </el-dialog>
	
	<!--支付密码-->
	<el-dialog :visible.sync="inputPassword" width="40%" center top='20vh' class="dialog-pwd">
	  <div class="w-font-13">
		<div class="dialog-title">请输入支付密码</div>
	    <div v-if="userInfo.pwdState == 1">
	      <div class="mdx-text-center w-m-t-40 shet-title">您还未设置支付密码</div>
	      <div class="dialog-bom mdx-text-center w-m-t-40">
	        <!-- <div>设置及更多→安全设置支付密码</div> -->
	        <el-button type="primary" class="" @click='toSetPassword'>去设置支付密码</el-button>
	      </div>
	    </div>
	    <div v-else>
	      <div class="mdx-text-center">
	        <div style="width: 100%;margin: 40px 0 60px 0">
	          <el-input v-model="withdrawPassword" type="password" placeholder="请输入支付密码"></el-input>
	        </div>
	      </div>
	      <div class="dialog-bom mdx-flex mdx-row-center w-m-t-46">
			<el-button class="close" size="small" @click="inputPassword = false">取 消</el-button>
	        <el-button type="primary" size="small" :loading="loading" @click='payBalance()'> 确定</el-button>
	      </div>
	    </div>
	  </div>
	</el-dialog>
	
	<el-dialog class="tipPopup" title="邀请工程师申请此项目" :visible.sync="inviteShow" show-cancel-button width="30%" center top="20vh">
	  <div class="puopContext w-m-t-30 w-font-16">
	    <div class="mdx-flex">
	      <div class="">工程师编号</div>
	      <div class="wzk-flex-1 w-m-l-20">
	        <el-input style="margin-top: 0" type="text" placeholder="请输入工程师编号" v-model.trim="engineerNo"></el-input>
	      </div>
	    </div>
	    <div class="dialog-bom mdx-flex mdx-row-center w-m-t-46">
			<el-button class="close" size="small" @click="inviteShow = false">取 消</el-button>
	        <el-button type="primary" size="small" :loading="loading" @click.stop="inviteTap()">邀请</el-button>
	    </div>
	  </div>
	</el-dialog>
	
	<!--邀请弹框-->
	<el-dialog class="tipPopup" title="邀请工程师" :visible.sync="invitesBoxShow" width="30%" center top="20vh">
		<div style="border: 1px solid #4077DD;width: 70%; height: 60px; cursor: pointer; border-radius: 4px; font-family: PingFangSC, PingFang SC;font-weight: 400; font-size: 16px; color: #4077dd; display: flex; align-items: center; justify-content: center; margin: 20px auto;"  @click="goToInvites()">
			 到人才库邀请
		</div>
		<div class="dialog-bom mdx-flex mdx-row-center w-m-t-35">
			<el-button size="small" class="close" style="width: 120px; height: 40px;" @click="invitation()">通过工程师ID邀</el-button>
			<el-button size="small" class="close" style="width: 120px; height: 40px; margin-left: 30px;" @click="openInviresList()">查看已邀请</el-button>
		</div>
	</el-dialog>
	
	<!--邀请列表-->
	<el-dialog class="tipPopup" title="已邀请列表" :visible.sync="invitesListShow" show-cancel-button width="750px" center top="20vh">
	    <div class="left-box-invites">
	    	<div class="item-invites m-flex" v-for="(item, index) in bmInvitesList" :key='index'>
	    		<div class="left m-flex">
	    			<WzkAvatar :isGender="true" :gender="item.gender || 1" :imgSrc="item.headImgUrl" :waryWidth="70" :waryHeight="70" :width="70" :height="70" :borderRadius="35"></WzkAvatar>
	    			<div class="user-invites">
	    				<div class="invits-top m-flex">
	    					<span class="username">{{ $mHelper.nameSlice(2, userInfo, item.name, item.name) }}</span>
	    					<span class="userTag" v-if="item.invitation==0">客户邀请</span>
	    					<el-popover placement="right" width="300" trigger="hover" v-if="item.invitation==1">
	    					  <div class="" v-if="item.inviteReason!=null">{{item.inviteReason}}</div>
	    					  <span slot="reference">
	    					     <span class="userTag">项目经理邀请</span>
	    					  </span>
	    					</el-popover>
	    					<el-popover placement="right" width="300" trigger="hover" v-if="item.is_fm==1">
	    					  <div class="">该工程师在进行中的项目较多，请谨慎选择</div>
	    					  <span slot="reference">
	    					     <span class="userTag" style="color: #FF4D4F; border-color: #FF4D4F;">繁忙</span>
	    					  </span>
	    					</el-popover>
	    					<el-rate v-model="item.rateValue" disabled disabled-void-color="#D8D8D8"></el-rate>
	    				</div>
	    				<div class="invites-sn">
	    					<span>工程师编号：{{ item.engineerNo || '' }}</span>
	    					<!-- <span class="w-m-l-24">工程师电话：{{ item.mobile || '' }}</span> -->
	    				</div>
	    				<div class="invites-tags">
	    					<span>精通{{item.majorFy.length || '0'}}个专业</span>
	    					<span>精通{{item.softwareFy.length || '0'}}个专业软件</span>
	    					<span>精通{{item.typeFy.length || '0'}}个项目类型</span>
	    					<span>擅长{{item.regionFy.length || '0'}}个地区</span>
	    					<span>{{item.years || '0'}}年工作经验</span>
	    					<span v-if="item.files!=null">有专业证书</span>
	    				</div>
	    			</div>
	    		</div>
	    		<div class="right m-flex" >
	    			<span style="color: #4077DD;" v-if="item.statusApply==1">待确认</span>
	    			<span style="color: #10C550;" v-if="item.statusApply==2">已接受</span>
	    			<span style="color: #FF4D4F;" v-if="item.statusApply==9">已拒绝</span>
	    		</div>
	    	</div>
	    </div>
	</el-dialog>
	
	
    <alertToast ref="alert"></alertToast>
    <div v-html="alipayWap" ref="alipayWap"></div>
  </div>
</template>

<script>
import alertToast from "../../../components/alertToast/index.vue";
import rulesData from "../../../api/rules.js";
import QRCode from "qrcodejs2";
import { initNumberValue } from "@/common/utils/num-validate.js";
import { downloadUrlFile } from "@/common/utils/common.js";
import WzkAvatar from '../../../components/wzk-Avatar/index.vue';
export default {
  components: {
    alertToast,
	WzkAvatar
  },
  data() {
    return {
      rules: rulesData.complaints,
      cities: [
        "工作成果质量存在问题",
        "项目交付时间延误",
        "造价师服务态度不好",
        "造价师个人经验及信息与实际不符",
        "其他问题",
      ],
      formLabelAlign: {
        check: [],
        contactMethod: "", //联系方式
        reason: "", //投诉原因（1.我还未确认验收，对工作成果不满意，2.项目已结束，但我对工作成果不满意，3.投诉延误，4.服务态度，5.其他）
        remark: "",
        sn: "",
        name: "",
      },
      alipayWap: "",
      uploadingStatement: false,
      statementFile: {
        name: "",
      },
      action: this.$store.state.baseURL + "upload/uploadCos",
      loading: false,
      onPrepareFile: false,
      fileList: [],
      transferRules: rulesData.transferRules,
      paycheck: ["1"],
      checkList: false,
      upStatementName: "上传授权委托书",
      tabIndex: 0,
      menuList: [
        {
          title: "待审核",
          status: 1,
		  num: 0,
        },
        {
          title: "待付款",
          status: 2,
		  num: 0,
        },
		{
		  title: "报名中",
		  status: 3,
		  num: 0,
		},
        {
          title: "进行中",
          status: 4,
		  num: 0,
        },
        {
          title: "已结束",
          status: 5,
		  num: 0,
        },
        {
          title: "已投诉",
          status: 10,
		  num: 0,
        },
      ],
      list: [],
      dataItem: {
        majorFy: [],
        audittype: 0,
      },
      files: [], //附件文件
      queryParams: {
        page: 1, //当前页数
        limit: 10, //每页条数
        totalPage: 1,
      },
      clientHeight: "",
      WechatShow: false,
      payShow: false, //支付弹窗
      payTypeShow: false, //支付弹窗
      transShow: false, //线下转账提示
      credentialsShow: false, //显示凭证弹窗
      credSuccessShow: false, //回款凭证上传成功
      acceptShow: false, //验收弹窗
      rejectShow: false, //驳回
      supplementShow: false, //上传附件弹窗
      evalutShow: false, //评价弹窗
	  rejectType:'', //验收是否通过
      rejectContent: "", //驳回原因
      credFrom: {},
      evalutType: 1, //评价状态
      star1: 0,
      star2: 0,
      starText1: "",
      starText2: "",
      bankInfo: {},
      bankFrom: {
        bankDeposit: "", //开户银行（线下转账必传）
        payMethod: 0, //支付方式（1.微信，2.支付宝，3.小程序微信支付，4.线下转账）
        paymentAccount: "", //付款人账号（线下转账必传）
        receipt: "", //回执单（线下转账必传）
        sourceId: 0, //项目id(客户付款、工程师支付定金、工程师补缴定金（质保金）、客户补缴尾款 必传)
        type: 0, //类型（1.客户付款，2.工程师支付定金，3.工程师补缴定金（质保金），4.提现，6.客户补缴尾款）
      },
      payTimeer: null,
      ticker: null, //倒计时
      checkedCities: [],
      commSucces: false,
      sn: 0,
      complaint: false, //项目投诉
	  saveQxShow: false, //报名人员权限设置
	  addCheckForm: [],
	  searchText: '',
	  searchPersonText: '',
	  bmList: [],
	  addCheckId: '',
	  showType: 0,
	  lookPrice: false,
	  zdList: {}, //账单数据
	  inputPassword: false,
	  withdrawPassword: "",//支付密码
	  engineerNo: "", //工程师编号
	  inviteShow: false,
	  invitesBoxShow: false,  //邀请方式弹框
	  invitesListShow: false, //邀请列表弹框
	  bmInvitesList: [],
    };
  },
  computed: {
		userInfo() {
			return this.$store.getters['userInfo']
		},
		paths() {
			return this.$route.path;
		},
  },
  watch: {
    clientHeight: function () {
      this.changeFixed(this.clientHeight);
    },
  },
  mounted() {
    this.clientHeight = `${document.documentElement.clientHeight}`;
    window.onresize = function temp() {
      this.clientHeight = `${document.documentElement.clientHeight}`;
    };
    //这一段是防止进入页面出去后再进来计时器重复启动
    if (this.ticker) {
      clearInterval(this.ticker);
    }
    //这一段是防止进入页面出去后再进来计时器重复启动
    if (this.payTimeer) {
      clearInterval(this.payTimeer);
    }
    this.beginTimer();
	//this.commSucces = true;
  },
  activated() {
    //银行信息
    this.$http.get("config/getBank").then((res) => {
      if (res.code == 200) {
        this.bankInfo = res.data;
      }
    });
    this.getList();
  },
  methods: {
    handleCommand(e) {
		if(e.type==9){
			this.complaint = true;
			this.formLabelAlign.sn = e.item.sn;
			this.formLabelAlign.name = e.item.regionFy + "/" + e.item.typeFy+'/'+e.item.majorFy[0].name+'/总投资:￥'+e.item.totalInvestment+'/总规模:'+e.item.totalScale+'/'+e.item.serviceContentFy;
		}else if(e.type==10){
			const h = this.$createElement;
			this.$msgbox({
			  title: "提示",
			  message: h(
			    "p",
			    {
			      style: "margin-bottom: 20px",
			    },
			    [
			      h(
			        "span",
			        {
			          style: "color: red",
			        },
			        "您确定要发起关联吗？ "
			      ),
			    ]
			  ),
			  center: true,
			  showCancelButton: true,
			  confirmButtonText: "确认发起",
			  cancelButtonText: "我再想想",
			  beforeClose: (action, instance, done) => {
			    if (action === "confirm") {
					this.$mHelper.to("/push?id=" + e.item.id);
				   done();
			    } else {
			       done();
			    }
			  },
			}).then((action) => {
			    
			});
		}else if(e.type==11){
			const h = this.$createElement;
			this.$msgbox({
			  title: "提示",
			  message: h(
			    "p",
			    {
			      style: "margin-bottom: 20px",
			    },
			    [
			      h(
			        "span",
			        {
			          style: "color: red",
			        },
			        "您确定要发起延期验收吗？ 确认延期验收时间将增加15天！"
			      ),
			    ]
			  ),
			  center: true,
			  showCancelButton: true,
			  confirmButtonText: "确认发起",
			  cancelButtonText: "我再想想",
			  beforeClose: (action, instance, done) => {
			    if (action === "confirm") {
						this.rejectType=2;
						this.$http.post("release/confirm", {
						    id: e.item.id,
						    type: this.rejectType,
							remark: this.rejectContent,
						  }).then((res) => {
						    this.loading = false;
						    if (res.code == 200) {
								this.acceptShow = false;
								this.rejectContent = "";
								 this.$message.success("延期完成！");
						       this.getList();
						      
						    }
						  })
						  .catch((err) => {
						    this.loading = false;
						  });
					
				   done();
			    } else {
			       done();
			    }
			  },
			}).then((action) => {
			    
			});
		}
      
    },
    //关闭投诉弹框
    handleComplaint() {
      this.complaint = false;
      this.$refs["fromref"].resetFields();
    },
    //投诉多选
    complaintChange(item) {
      console.log("item", item);
    },
    //提交投诉
    subimtBtn() {
      this.$refs["fromref"].validate((valid) => {
        if (valid) {
          this.formLabelAlign.reason = this.formLabelAlign.check[0];
          this.$http.post("user/tsProject", this.formLabelAlign).then((res) => {
            if (res.code == 200) {
              this.commSucces = true;
              this.complaint = false;
              this.$refs["fromref"].resetFields();
			  this.getList();
            }
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    // showTitle() {
    //   //请求成功后弹窗
    //   const h = this.$createElement;
    //   this.$msgbox({
    //     title: '提示',
    //     message: h('p', {
    //       style: 'margin-bottom: 20px'
    //     }, [
    //       h('span', {}, '您已成功提交投诉！平台将在3个工作日内联系您，请勿重复提交！'),
    //     ]),
    //     center: true,
    //     showCancelButton: true,
    //     showConfirmButton: false,
    //     cancelButtonText: '我知道了',
    //   }).then(e => {

    //   })
    // },
    checkbox(city) {
      this.formLabelAlign.check = this.formLabelAlign.check.includes(city)
        ? [city]
        : [];
    },
    //下载委托书
    downloadStatement() {
      //console.log("下载委托书");
      this.$http.get("config/getByKey", {
          configKey: "letter_of_attorney",
        }).then((res) => {
          console.log(res);
          if (res && res.code == 200) {
            if (res.data && res.data.img) {
              // var num = res.data.img.lastIndexOf('/') + 1
              // var fileName = res.data.img.substring(num)
              // //把参数和文件名分割开
              // fileName = decodeURI(fileName.split("?")[0]);

              // this.$http.downloadFile('upload/downloadattorney',{
              //   filePath: res.data.img
              // }).then((res)=>{
              //   console.log(res)
              // }).catch((err)=>{
              //   console.log(err)
              // })

              const link = document.createElement("a"); // 创建a标签
              link.style.display = "none";
              link.href = res.data.img;
              link.setAttribute("target", "_blank");
              link.setAttribute("download", "成果文件.png"); // 给下载后的文件命名
              document.body.appendChild(link);
              link.click(); // 点击下载
              document.body.removeChild(link); //  完成移除元素
              window.URL.revokeObjectURL(link.href); // 释放blob对象
              this.materialsShow = false;
            }
          }
        }).catch((err) => {
          console.log(err);
        });
    },
    starUploadStatement() {
      this.uploadingStatement = true;
    },
    handleStatementSuccess(response, file, fileList) {
      console.log(file);
      if (file.response.code == 200) {
        this.$http.get("project/LetterOfAttorney", {
            file: file.response.data,
            id: this.dataItem.id,
          }).then((res) => {
            console.log(res);
            if (res && res.code == 200) {
              this.upStatementName = "重新上传授权委托书";
              this.statementFile.name = file.name;
              this.$message.success("上传委托书成功！");
            }
          }).catch((err) => {
            console.log(err);
          });
      } else {
        this.$message.error(file.response.msg);
      }
      this.uploadingStatement = false;
    },
    removeStatementFile() {
      this.statementFile.name = "";
    },
    pageChange(e) {
      this.queryParams.page = e;
      this.getList();
    },
	searchTap() {
	  this.queryParams.page = 1;
	  this.getList();
	},
    getList() {
      const loading = this.$loading({
        lock: true,
        text: "加载中...",
        spinner: "el-icon-loading",
        background: "rgba(255, 255, 255, 0.8)",
      });
      this.$http.get("release/list", {
			status: this.menuList[this.tabIndex].status,
			pageNum: this.queryParams.page,
			pageSize: this.queryParams.limit,
			qName: this.searchText,
			showType: this.showType
        }).then((res) => {
          if (res.code == 200) {
            this.queryParams.totalPage = res.data.projectList.pages;
			
			this.menuList[0].num = res.data.dshNum;
			this.menuList[1].num = res.data.dfkNum;
			this.menuList[2].num = res.data.bmzNum;
			this.menuList[3].num = res.data.jxzNum;
			this.menuList[4].num = res.data.yjsNum;
			this.menuList[5].num = res.data.ytsNum;
			
            // res.data.totalInvestment = initNumberValue(res.data.totalInvestment)
            // res.data.totalScale = initNumberValue(res.data.totalScale)
            // res.data.totalArea = initNumberValue(res.data.totalArea)
            // res.data.estimatedPrice = initNumberValue(res.data.estimatedPrice)
            // res.data.orderPrice = initNumberValue(res.data.orderPrice)
            // res.data.income = initNumberValue(res.data.income)
            // res.data.depositAmount = initNumberValue(res.data.depositAmount)
            res.data.projectList.list.forEach(function (item) {
              item.totalInvestment = initNumberValue(item.totalInvestment);
              item.totalScale = initNumberValue(item.totalScale);
              item.estimatedPrice = initNumberValue(item.estimatedPrice);
              item.orderPrice = initNumberValue(item.orderPrice);
            });
            this.list = res.data.projectList.list;
            console.log("-------list----------");
            console.log(res.data.projectList.list);
          } else {
            this.list = [];
          }
          loading.close();
        }).catch((err) => {
          loading.close();
        });
    },
	
	searchPersonTap() {
		this.bmList = [];
	    this.getPersonList();
	},
	quanSave(item){
		this.dataItem = item;
		this.saveQxShow = true;
		this.bmList = [];
		this.addCheckForm = [];
		this.getPersonList();
	},
	getPersonList(){
		this.$http.get('director/bmList', {
		  id: this.dataItem.id,
		  pageNum: 1,
		  pageSize: 999,
		  status: 2,
		  qName: this.searchPersonText
		}).then(res => {
		  if (res.code == 200) {
			res.data.list.forEach((e)=>{
				e.rateValue = e.synthesize/20 || 0;
				e.is_fm = 0;
				if(e.team==1){ //有团队
					if(e.orderNum >= 5){
						e.is_fm = 1;
					}
				}else {
					if(e.orderNum >= 2){
						e.is_fm = 1;
					}
				}
			})  
		    this.bmList = res.data.list
		  }
		})
	},
	//权限
	OpenTakeBack(type) {
	  this.$http.post('director/permissionSet', {
	    projectId: this.dataItem.id,
	    type: type,
	    engineerId: this.addCheckId
	  }).then(res => {
	    if (res.code == 200) {
	      this.$message.success('操作成功')
	      this.getPersonList();
		  this.searchTap();
	    }
	  })
	},
	
	speckCheckChange(value) {
	   this.addCheckForm = value;
	},
	
	//打开邀请选择弹框
	openInvites(item){
		this.dataItem = item;
		this.invitesBoxShow = true;
	},
	
	//yao邀请工程师弹窗
	invitation() {
	  this.engineerNo = "";
	  this.inviteShow = true;
	},
	
	//打开已邀请列表
	openInviresList(){
		this.getBmInvitesList();
		this.invitesListShow = true;
	},
	
	goToInvites() {
		this.invitesBoxShow = false;
		this.$mHelper.to('/talents')
	},
	
	getBmInvitesList(){
		this.$http.get('director/bmList', {
		  id: this.dataItem.id,
		  pageNum: 1,
		  pageSize: 999,
		  status: 1,
		}).then(res => {
		  if (res.code == 200) {
				res.data.list.forEach((e)=>{
					e.rateValue = e.synthesize/20 || 0;
					e.is_fm = 0;
					if(e.team==1){ //有团队
						if(e.orderNum >= 5){
							e.is_fm = 1;
						}
					}else {
						if(e.orderNum >= 2){
							e.is_fm = 1;
						}
					}
					
				})  
		      this.bmInvitesList = res.data.list
		  }
		})
	},
	
	//邀请工程师
	inviteTap() {
	  if (!this.engineerNo) {
	    this.$message.error("请输入工程师编号");
	    return;
	  }
	  this.loading = true;
	  this.$http.post("/director/invitation", {
	      engineerNo: this.engineerNo,
	      projectId: this.dataItem.id,
	    }).then((res) => {
	      this.loading = false;
	      if (res.code == 200) {
	        this.inviteShow = false;
	        this.getList();
	        const h1 = this.$createElement;
	        this.$msgbox({
	          title: "提示",
	          message: h1(
	            "p",
	            {
	              style: "margin-bottom: 20px",
	            },
	            [
	              h1("span", null, "您已成功邀请工程师申请此项目！ "),
	              h1(
	                "br",
	                {
	                  style: "color: red",
	                },
	                ""
	              ),
	              h1(
	                "span",
	                null,
	                "如此工程师同意申请，则会出现在项目报名列表中！ "
	              ),
	            ]
	          ),
	          center: true,
	          showCancelButton: true,
	          showConfirmButton: false,
	          cancelButtonText: "我知道了",
	        });
	      }
	    }).catch((err) => {
	      this.loading = false;
	    });
	},
	
    //完善需求
    alertItem(item) {
      this.$mHelper.to("/release?id=" + item.id);
    },
    toDetails(item) {
		if(item.isEvaluateProject==1 || item.isEvaluateUser==1 ){
			this.$mHelper.to("/myPublishProjectDetail?id=" + item.id + "&mrate=" + item.mangerEvaluate + "&urate=" + item.userEvaluate);
		}else{
			this.$mHelper.to("/myPublishProjectDetail?id=" + item.id);
		}
        
    },
    //下载材料
    downWenjian() {
      //this.loading = true;
      if (this.dataItem.checkStatus != 3) {
        // this.$message.success('暂无可审核的文件')
        return;
      }
      this.$http.downloadFile("release/batchDownload", {
          id: this.dataItem.id,
        }).then((res) => {
          this.loading = false;
          var reader = new FileReader();
          let that = this;
          reader.onloadend = function (event) {
            //因为返回的报错格式是字符串，手动转换成对象，转换成功表示请求失败
            //转换失败就意味着你拿到的result是文件流，那么直接手动下载就好
            try {
              let data = JSON.parse(event.target.result);
              that.$message.error(res.data.msg);
            } catch (err) {
              const link = document.createElement("a"); // 创建a标签
              let blob = new Blob([res.data]);
              link.style.display = "none";
              link.href = URL.createObjectURL(blob); // 创建下载的链接
              link.setAttribute("download", "成果文件" + "." + "zip"); // 给下载后的文件命名
              document.body.appendChild(link);
              link.click(); // 点击下载
              document.body.removeChild(link); //  完成移除元素
              window.URL.revokeObjectURL(link.href); // 释放blob对象
              that.materialsShow = false;
            }
          };
          reader.readAsText(res.data);
        }).catch((err) => {
          this.loading = false;
        });
    },
    //取消订单
    cancelOrder(item, index) {
      //请求成功后弹窗
      const h = this.$createElement;
      this.$msgbox({
        title: "提示",
        message: h(
          "p",
          {
            style: "margin-bottom: 20px",
          },
          [
            h(
              "span",
              {
                style: "color: red",
              },
              "您确定要取消此订单吗？ "
            ),
          ]
        ),
        center: true,
        showCancelButton: true,
        confirmButtonText: "确认取消",
        cancelButtonText: "我再想想",
        beforeClose: (action, instance, done) => {
          if (action === "confirm") {
            instance.confirmButtonLoading = true;
            instance.confirmButtonText = "执行中...";
            this.$http.post("project/cancelOrder", {
                projectId: item.id,
                type: 4,
              }).then((res) => {
                if (res.code == 200) {
					
                  done();
                  setTimeout(() => {
                    instance.confirmButtonLoading = false;
                  }, 300);
                }
              }).catch((error)=>{
				instance.confirmButtonText = "确认取消";
				 setTimeout(() => {
				   instance.confirmButtonLoading = false;
				 }, 300);
			  });
          } else {
            done();
          }
        },
      }).then((action) => {
        this.getList();
        this.$refs.alert.alertToast(
          "您已成功取消该项目需求！",
          "提示",
          "#ff0000"
        );
      });
	  
    },
    //取消申请
    cancelApply() {
      //请求成功后弹窗
      const h = this.$createElement;
      this.$msgbox({
        title: "取消申请项目提醒",
        message: h(
          "p",
          {
            style: "margin-bottom: 20px",
          },
          [
            h(
              "span",
              {
                style: "color: red",
              },
              "再次申请项目需平台重新审核您确定要取消申请此项目吗？"
            ),
          ]
        ),
        center: true,
        showCancelButton: true,
        confirmButtonText: "确认取消",
        cancelButtonText: "我再想想",
        beforeClose: (action, instance, done) => {
          if (action === "confirm") {
            instance.confirmButtonLoading = true;
            instance.confirmButtonText = "执行中...";
            setTimeout(() => {
              done();
              setTimeout(() => {
                instance.confirmButtonLoading = false;
              }, 300);
            }, 3000);
          } else {
            done();
          }
        },
      }).then((action) => {
        const h2 = this.$createElement;
        this.getList();
        this.$msgbox({
          title: "提示",
          message: h2(
            "p",
            {
              style: "margin-bottom: 20px",
            },
            [
              h2(
                "span",
                {
                  style: "color: red",
                },
                "您已成功取消该项目订单！ "
              ),
            ]
          ),
          center: true,
          showCancelButton: true,
          showConfirmButton: false,
          cancelButtonText: "我知道了",
          beforeClose: (action, instance, done) => {
            if (action === "confirm") {
              instance.confirmButtonLoading = true;
              instance.confirmButtonText = "执行中...";
              setTimeout(() => {
                done();
                setTimeout(() => {
                  instance.confirmButtonLoading = false;
                }, 300);
              }, 3000);
            } else {
              done();
            }
          },
        }).then((action) => {
          this.$message({
            type: "success",
            message: "action: " + action,
          });
        });
      });
    },
    //取消发布
    cancelRelease(item, index) {
      //请求成功后弹窗
      const h = this.$createElement;
      this.$msgbox({
        title: "提示",
        message: h(
          "p",
          {
            style: "margin-bottom: 20px",
          },
          [
            h(
              "span",
              {
                style: "color: red",
              },
              "您确定要取消发布此项目吗？ "
            ),
          ]
        ),
        center: true,
        showCancelButton: true,
        confirmButtonText: "确认取消",
        cancelButtonText: "我再想想",
        beforeClose: (action, instance, done) => {
          if (action === "confirm") {
            instance.confirmButtonLoading = true;
            instance.confirmButtonText = "执行中...";
            this.$http.post("project/cancelOrder", {
                projectId: item.id,
                type: 5,
              }).then((res) => {
                if (res.code == 200) {
                  done();
                  setTimeout(() => {
                    instance.confirmButtonLoading = false;
                  }, 300);
                }
              });
          } else {
            done();
          }
        },
      }).then((action) => {
        this.getList();
        this.$refs.alert.alertToast(
          "您已成功取消该项目需求！",
          "提示",
          "#ff0000"
        );
      });
    },
	
	
	
	
    //验收请求
    accepBtns() {
		// if(this.rejectType=='' || this.rejectType==null){
		// 	this.$message.error("请选择审核结果");
		// 	return;
		// }
		// if (this.rejectType==2 && !this.rejectContent) {
		// 	this.$message.error("请输入驳回原因");
		// 	return;
		// }
	  this.rejectType=1;
      this.loading = true;
      this.$http.post("release/confirm", {
          id: this.dataItem.id,
          type: this.rejectType,
		  remark: this.rejectContent,
        }).then((res) => {
          this.loading = false;
          if (res.code == 200) {
            this.acceptShow = false;
			this.rejectContent = "";
            this.getList();
			if(this.rejectType==1){
				const h2 = this.$createElement;
				this.$msgbox({
				  title: "提示",
				  message: h2(
				    "p",
				    {
				      style: "margin-bottom: 20px",
				    },
				    [
				      h2(
				        "span",
				        {
				          style: "color: red",
				        },
				        "确认验收完成 "
				      ),
				    ]
				  ),
				  center: true,
				  showCancelButton: true,
				  cancelButtonText: "确定",
				  confirmButtonText: "去评价",
				}).then((action) => {
				  this.evalutShowtap(this.dataItem, 1);
				});
			}else{
				this.$refs.alert.alertToast(
				  "您已驳回此工作成果！",
				  "提示",
				  "#ff0000"
				);
			}
			
            
          }
        })
        .catch((err) => {
          this.loading = false;
        });
    },
   
    //评论提交
    CommontUpdata() {
      //
      if (this.star1 == 0 && this.star2 == 0) {
        this.$message.error("请选择评分");
        return;
      }
      // if (this.star2 == 0) {
      //   this.$message.error('请选择成果资料评分')
      //   return
      // }
      // if (!this.starText1) {
      //   this.$message.error('请输入服务态度评语')
      //   return
      // }
      // if (!this.starText2) {
      //   this.$message.error('请输入成果资料评语')
      //   return
      // }
      this.loading = true;
      this.$http
        .post("release/evaluate", {
          projectId: this.dataItem.id,
          attitude: this.star1,
          quality: this.star2,
          gcRemark: this.starText1,
          jlRemark: this.starText2,
        })
        .then((res) => {
          if (res.code == 200) {
            this.evalutShow = false;
            this.$refs.alert.alertToast("您已评价成功！", "提示", "#000");
            this.getList();
          }
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    //评论弹窗打开
    evalutShowtap(item, type) {
      this.evalutType = type;
      if (type == 1) {
        this.dataItem = item;
        this.star1 = 0;
        this.star2 = 0;
        this.starText1 = "";
        this.starText2 = "";
        this.evalutShow = true;
      } else {
        this.loading = true;
        this.$http.get("release/evaluateSee",{
			id: item.id,
		}).then((res) => {
          this.loading = false;
          if (res.code == 200) {
            if(res.data!=null){
				this.star1 = res.data.attitude;
				this.star2 = res.data.quality;
				this.starText1 = res.data.gcRemark;
				this.starText2 = res.data.jlRemark;
			}
            this.evalutShow = true;
          }
        }).catch((err) => {
          this.loading = false;
        });
      }
    },
    //发票申请
    invoiceBtn(item) {
	
      this.loading = true;
      this.$http.get("release/applyInvoice", {
          id: item.id,
        }).then((res) => {
          if (res.code == 200) {
            this.rejectShow = false;
            this.$refs.alert.alertToast(
              "您已申请开具发票，等待平台审核！",
              "提示",
              "#000"
            );
            this.getList();
          }
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
        });
    },
      //提前结束质保
      systemThDj(item) {
          this.loading = true;
          this.$http
              .get("payOrder/ljThOrder", {
                  sn: item.sn,
              })
              .then((res) => {
                  if (res.code == 200) {
                      this.rejectShow = false;
                      this.$refs.alert.alertToast(
                          "质保金已发放给工程师!",
                          "提示",
                          "#000"
                      );
                      this.getList();
                  }
                  this.loading = false;
              })
              .catch((err) => {
                  this.loading = false;
              });
      },
    //上传附件请求
    suppleUpdata() {
      if (this.files.length < 1) {
        this.$message.error("请上传文件后提交");
        return;
      }
      this.loading = true;
      this.$http.post("release/supplyFile", {
          files: this.files,
          id: this.dataItem.id,
        }).then((res) => {
          this.loading = false;
          if (res.code == 200) {
            this.$refs.alert.alertToast(
              "您已成功上传补充附件！",
              "提示",
              "#000"
            );
            this.supplementShow = false;
          }
        }).catch((err) => {
          this.loading = false;
        });
    },

    suppleTap(item) {
      this.dataItem = item;
      this.supplementShow = true;
    },
    acceptShowBtns(item) {
      this.dataItem = item;
      this.acceptShow = true;
    },
    bindCheckBox(value) {
      if (this.paycheck.length > 1) {
        this.paycheck.splice(0, 1);
      }
    },
    upataPingzheng() {
      //提交凭证
      this.$refs["xianxiaPays"].validate((valid) => {
        if (valid) {
          this.loading = true;
          this.bankFrom.payMethod = 4;
          this.bankFrom.type = this.dataItem.isBjJe == 2 ? 6 : 1;
          this.bankFrom.sourceId = this.dataItem.id;
          this.$http.post("pay/recharge", this.bankFrom)
            .then((res) => {
              if (res.code == 200) {
                this.getList();
                this.credentialsShow = false;
                this.credSuccessShow = true;
              }
              this.loading = false;
            }).catch((err) => {
              this.loading = false;
            });
        } else {
        }
      });
    },
    onProgress(event, file, fileList) {
      this.onPrepareFile = true;
    },
    onError(event, file, fileList) {
      this.onPrepareFile = false;
      var eFile = file.name || "当前文件";
      this.$message.error(`${eFile}上传失败，请重试`);
    },
    fujianSuccess(response, file, fileList) {
      console.log(fileList);
      this.onPrepareFile = false;
      let list = [];
      fileList.forEach((e) => {
        list.push({
          name: e.name,
          url: e.response.data,
        });
      });
      this.files = list;
      console.log(this.files);
    },
    fujianRemove(file, fileList) {
      console.log(fileList);
      let list = [];
      fileList.forEach((e) => {
        list.push({
          name: e.name,
          url: e.response.data,
        });
      });
      this.files = list;
      console.log(this.files);
    },
    fujianExceed(files, fileList) {
      this.$message.warning(`最多上传9个`);
    },
    handleClose(done) {
      this.$confirm("当前页面未保存，是否退出？", "提示", {
        center: true,
      }).then((_) => {
          this.credentialsShow = false;
          done();
        }).catch((_) => {});
    },

    //显示上传凭证弹窗
    upPingZheng() {
      this.transShow = false;
      this.credentialsShow = true;
    },
    //线下支付
    xianxiaPay() {},
    //点击支付按钮
    showPayType() {
      if (!this.checkList) {
        this.$message.error("请阅读并同意《中一鼎合造价众包平台项目服务协议》");
        return;
      }
      this.payTypeShow = true;
      //this.payShow = false;
      this.clearUpStatement(1);
    },
    clearUpStatement(secne = 0) {
      if (secne == 1) {
        this.payShow = false;
      }
      this.checkList = false;
      this.upStatementName = "上传授权委托书";
      this.statementFile.name = "";
    },
    payClose(done) {
      this.clearUpStatement();
      done();
    },
    //选择支付方式后点击支付
    payEnd() {
      this.payTypeShow = false;
      if (this.paycheck[0] == "1") {
        this.payTypeShow = false;
        console.log("微信支付");
        this.WeChatPay();
        // this.$message.error('开发中...')
      } else if (this.paycheck[0] == "2") {
        this.payTypeShow = false;
        console.log("支付宝支付");
        // this.$message.error('开发中...')
        this.AlipayPay();
      } else if (this.paycheck[0] == "3") {
        console.log("线下转账");
        this.transShow = true;
      }else if (this.paycheck[0] == "7") {
	    console.log("余额支付");
	    this.inputPassword = true;
	  }
    },
    paySatausPoll(payId) {
      this.payTimeer = setInterval(() => {
        if (payId) {
          this.$http
            .get("payOrder/getOrder", {
              sn: payId,
            })
            .then((e) => {
              if (e.code == 200) {
                if (e.data.status == 2) {
                  this.WechatShow = false;
                  var tipMsg = "支付成功！";
                  if (e.data.type == 1) {
                    //客户付项目款
                    tipMsg =
                      "恭喜您支付成功！该项目已进入【进行中的项目】平台已开始工作";
                  }
                  // if(e.data.type==6) {
                  //   tipMsg = '您已补缴成功！';//客户补缴尾款
                  // }
                  this.$refs.alert.alertToast(tipMsg, "提示", "#000");
                  this.getList();
                }
              }
            });
        }
      }, 2000);
    },
    //弹窗关闭
    wxPayPuopClose() {
      console.log("关闭微信支付弹窗");
      clearInterval(this.payTimeer);
      done();
    },
    WeChatPay() {
      this.loading = true;
      this.$http.post("pay/recharge", {
          payMethod: 1,
          type: this.dataItem.isBjJe == 2 ? 6 : 1,
          sourceId: this.dataItem.id,
        }).then((res) => {
          if (res.code == 200) {
            this.WechatShow = true;
            this.$nextTick(() => {
              document.getElementById("qrcode").innerHTML = "";
              var qrcode = new QRCode(this.$refs.qrCodeUrl, {
                text: res.data.codeUrl, // 需要转换为二维码的内容
                width: 220,
                height: 220,
                colorDark: "#000000",
                colorLight: "#ffffff",
                correctLevel: QRCode.CorrectLevel.H,
              });
            });
            this.paySatausPoll(res.sn);
          }
          this.loading = false;
        }).catch((err) => {
          this.loading = false;
        });
    },
    //支付宝支付
    AlipayPay() {
      const loading = this.$loading({
        lock: true,
        text: "加载中...",
        spinner: "el-icon-loading",
        background: "rgba(255, 255, 255, 0.8)",
      });
      // this.loading = true
      this.$http.post("pay/recharge", {
          payMethod: 2,
          type: this.dataItem.isBjJe == 2 ? 6 : 1,
          sourceId: this.dataItem.id,
        }).then((res) => {
			console.log(res,'11111111111111111111');
          if (res.code == 200) {
            this.alipayWap = res.data;
            console.log(this.alipayWap);
            this.$nextTick(() => {
              this.$refs.alipayWap.children[0].submit();
            });
          }
          this.loading = false;
        }).catch((err) => {
          this.loading = false;
        });
    },
    selectUpdata(item, index) {
      this.tabIndex = index;
	  this.showType = 0;
      this.queryParams.page = 1;
      this.getList();
    },
	showGetList(e){
		this.showType = e;
		this.queryParams.page = 1;
		this.getList();
	},
    changeFixed(clientHeight) {
      //动态修改样式
      this.$refs.main.style.minHeight = this.clientHeight - (60 + 356) + "px";
    },
    showJindu(item) {
      console.log(item);
      this.dataItem = item;
      this.credSuccessShow = true;
    },
    //点击按钮去支付
    payPuopShow(item) {
      this.dataItem = item;
      if (item.isBjJe == 2) {
        this.payTypeShow = true;
      } else {
        this.payShow = true;
      }
    },
    //上传超出限制
    handleExceed() {
      this.$message.warning("最多上传一个文件");
    },
    receiptSuccess(e, file) {
      if (file.response.code == 200) {
        this.bankFrom.receipt = file.response.data;
      } else {
        this.$message.success(file.response.msg);
      }
    },
    //删除凭证
    handleRemove() {
      this.bankFrom.receipt = "";
    },
    beginTimer() {
      //这个计时器是每秒减去数组中指定字段的时间
      this.ticker = setInterval(() => {
        this.list.forEach((e) => {
          if (e.automaticTimeFy > 0) {
            e.automaticTimeFy = e.automaticTimeFy - 1000;
          }
          if (e.checkTimeFy > 0) {
            e.checkTimeFy = e.checkTimeFy - 1000;
          }
        });
      }, 1000);
    },
	
	lookBtn(item){
		this.dataItem = item  
		this.$http.get("project/checkDetails", {
		    id: item.id,
		  }).then((res) => {
		    this.loading = false;
		    if (res.code == 200) {
				this.zdList = res.data;
				this.lookPrice=true
		     
		    }
		  }).catch((err) => {
				
		  });
		
	},
	toSetPassword() {
	  this.$router.push({
	    path: "/SecuritySet"
	  })
	},
	payBalance(){
		if(this.withdrawPassword==''){
			this.$message.warning("请输入支付密码！");
			return;
		}
		this.loading = true;
		this.$http.post("pay/recharge", {
		    payMethod: 7,
		    type: this.dataItem.isBjJe == 2 ? 6 : 1,
		    sourceId: this.dataItem.id,
			walletPwd: this.withdrawPassword,
		  }).then((res) => {
		    if (res.code == 200) {
			   this.$message.success("支付成功！");
			   this.inputPassword = false,
		       this.getList();
		    }
		    this.loading = false;
		  }).catch((err) => {
		    this.loading = false;
		  });
	},
	
	
  },
  filters: {
    // 拼团有效期的filter
    limitTimeFilter(val) {
      if (val <= 0) {
        return "超时未支付";
      } else {
        var lefday = Math.floor(val / (1000 * 60 * 60) / 24);
        var lefth = Math.floor((val / (1000 * 60 * 60)) % 24);
        var leftm = Math.floor((val / (1000 * 60)) % 60);
        var lefts = Math.floor((val / 1000) % 60);
        return (
          lefday + "天" + lefth + "小时" + leftm + "分" + lefts + "秒后自动失效"
        );
      }
    },
    yanshouTimeFilter(val) {
      if (val <= 0) {
        return "超时自动验收";
      } else {
        var lefday = Math.floor(val / (1000 * 60 * 60) / 24);
        var lefth = Math.floor((val / (1000 * 60 * 60)) % 24);
        var leftm = Math.floor((val / (1000 * 60)) % 60);
        var lefts = Math.floor((val / 1000) % 60);
        return (
          lefday + "天" + lefth + "小时" + leftm + "分" + lefts + "秒后自动验收"
        );
      }
    },
  },
  
  
  
  
  
};
</script>

<style lang="scss" scoped>
.top-box{
	margin-top: 32px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	.topTitles{
		font-family: PingFangSC, PingFang SC;
		font-weight: 600;
		font-size: 36px;
		color: rgba(0,0,0,0.85);
		text-align: left;
		font-style: normal;
		line-height: 80px;
	}
	.searchBox {
		width: 590px;
		display: flex;
		justify-content: center;
		::v-deep .el-input__inner {
			color: #4077DD;
			border: 1px solid #4077DD;
		}
		::v-deep .el-input-group__append {
			border: 1px solid #4077DD;
			background-color: #4077DD;
		}
		::v-deep ::-webkit-input-placeholder {
			color: #BFBFBF;
		}
		::v-deep ::-moz-input-placeholder {
			color: #BFBFBF;
		}
		::v-deep ::-ms-input-placeholder {
			color: #BFBFBF;
		}
	}
}	

.tabs-navs{
	margin-top: 24px;
	width: 100%;
	display: flex;
	justify-content: space-between;
	.left{
		display: flex;
		height: 40px;
	}
	.item {
		cursor: pointer;
		margin-right: 42px;
		font-family: PingFangSC, PingFang SC;
		font-weight: 600;
		font-size: 16px;
		color: rgba(0,0,0,0.85);
		line-height: 22px;
		text-align: left;
		font-style: normal;
		position: relative;
	}
	.actives {
		&:before{
			content: '';
			width: 66px;
			height: 6px;
			background: #4077DD;
			border-radius: 2px;
			position: absolute;
			bottom: 0;
			left: 0;
		}
	}
}

.work-list{
	margin-top: 24px;
	min-height: 500px;
	.item{
		min-height: 155px;
		background: #FFFFFF;
		box-shadow: 0px 6px 10px 6px rgba(44,46,51,0.03), 6px 6px 20px 3px rgba(44,46,51,0.06);
		border-radius: 8px;
		padding: 24px;
		margin-bottom: 24px;
		position: relative;
		.item-top{
			display: flex;
			justify-content: space-between;
			position: relative;
			z-index: 99;
			.title{
				width: 690px;
				height: 26px;
				font-family: PingFangSC, PingFang SC;
				font-weight: 500;
				font-size: 18px;
				color: rgba(0,0,0,0.85);
				line-height: 21px;
				text-align: left;
				font-style: normal;
				.titTip{
					padding: 2px 5px;
					border-radius: 4px;
					border: 1px solid #4077DD;
					font-family: PingFangSC, PingFang SC;
					font-weight: 500;
					font-size: 10px;
					color: #4077DD;
					line-height: 14px;
					text-align: center;
					font-style: normal;
					margin-right: 8px;
					position: relative;
					top: -2px;
				}
			}
			.item-sn{
				font-family: PingFangSC, PingFang SC;
				font-weight: 400;
				font-size: 14px;
				color: rgba(0,0,0,0.45);
				line-height: 21px;
				text-align: right;
				font-style: normal;
			}
		}
		.item-center{
			display: flex;
			justify-content: space-between;
			align-items: center;
			.left{
				max-width: 60%;
				.left-tags{
					margin-top: 12px;
					::v-deep .el-tag{
						background: #F5F7FA;
						border-radius: 4px;
						font-family: PingFangSC, PingFang SC;
						font-weight: 400;
						font-size: 12px;
						color: #555C70;
						line-height: 20px;
						font-style: normal;
						margin-right: 8px;
						padding: 0 8px;
						height: 22px;
						margin-bottom: 5px;
					}
				}
				.left-price{
					margin-top: 11px;
					font-family: PingFangSC, PingFang SC;
					font-weight: 600;
					font-size: 24px;
					color: #FF4D4F;
					line-height: 36px;
					font-style: normal;
				}
			}
			.right{
				.pay-type{
					font-family: PingFangSC, PingFang SC;
					font-weight: 500;
					font-size: 16px;
					color: #FF4D4F;
					line-height: 21px;
					text-align: right;
					font-style: normal;
				}
				.pay-waiting{
					font-family: PingFangSC, PingFang SC;
					font-weight: 500;
					font-size: 16px;
					color: #FFB420;
					line-height: 21px;
					text-align: right;
					font-style: normal;
				}
				.no-info{
					font-family: PingFangSC, PingFang SC;
					font-weight: 400;
					font-size: 16px;
					color: rgba(0,0,0,0.45);
					line-height: 21px;
					text-align: right;
					font-style: normal;
				}
				.sure-info{
					display: flex;
					align-items: center;
					justify-content: flex-end;
					img{
						width: 20px;
						height: 20px;
					}
					span{
						font-family: PingFangSC, PingFang SC;
						font-weight: 400;
						font-size: 16px;
						color: #FF4D4F;
						line-height: 21px;
						text-align: right;
						font-style: normal;
					}
				}
				.error-info{
					font-family: PingFangSC, PingFang SC;
					font-weight: 400;
					font-size: 16px;
					color: #FFB420;
					line-height: 21px;
					text-align: right;
					font-style: normal;
				}
				.rate-list{
					.item-rate{
						display: flex;
						align-items: center;
						.item-title{
							font-family: PingFangSC, PingFang SC;
							font-weight: 400;
							font-size: 14px;
							color: rgba(0,0,0,0.85);
							line-height: 30px;
							text-align: right;
							font-style: normal;
							width: 100px;
						}
						::v-deep .el-rate{
							height: auto;
							.el-rate__icon {
							  font-size: 26px !important;
							}
						}
					}
				}
				
			}
		}
		.item-timeBtn{
			display: flex;
			justify-content: space-between;
			min-height: 40px;
			.itme-time{
				font-family: PingFangSC, PingFang SC;
				font-weight: 400;
				font-size: 14px;
				color: rgba(0,0,0,0.85);
				line-height: 21px;
				font-style: normal;
				display: flex;
				align-items: flex-end;
			}
			.itme-btn{
				::v-deep .el-button{
					// width: 96px;
					padding: 12px 15px;
					height: 40px;
					background: #4077DD;
					border-radius: 8px;
					font-family: PingFangSC, PingFang SC;
					font-weight: 400;
					font-size: 16px;
					color: #FFFFFF;
					display: flex;
					align-items: center;
					justify-content: center;
				}
				.pay-type-btn{
					background: #FFFFFF;
					border: 1px solid #4077DD;
					color: #4077DD;
				}
				.more-btn{
					background: #FFFFFF;
					border: 1px solid #E8E8E8;
					color: rgba(0,0,0,0.45);
				}
			}
		}
		.payContextBox{
			height: 53px;
			background: #F7F7F7;
			border-radius: 8px;
			font-family: PingFangSC, PingFang SC;
			font-weight: 400;
			font-size: 14px;
			color: rgba(0,0,0,0.85);
			line-height: 21px;
			display: flex;
			align-items: center;
			margin-top: 15px;
			span{
				color: #4077DD;
				margin-right: 9px;
				margin-left: 16px;
			}
		}
		.ts-info{
			min-height: 110px;
			background: #F7F7F7;
			border-radius: 8px;
			padding: 16px;
			font-family: PingFangSC, PingFang SC;
			color: rgba(0,0,0,0.85);
			font-size: 14px;
			line-height: 21px;
			margin-top: 25px;
			.ts-top{
				display: flex;
				align-items: center;
				.ts-title{
					font-weight: 500;
					color: #FF4D4F;
				}
				.ts-type{
					font-weight: 400;
					line-height: 21px;
				}
			}
			.ts-cont{
				font-weight: 400;
				margin-top: 8px;
				margin-left: 71px;
				white-space: normal;
				word-break: break-all;
			}
			.ts-time{
				font-weight: 400;
				margin-top: 16px;
			}
		}
		.top-left-tabs{
			width: 80px;
			height: 32px;
			position: absolute;
			left: 0;
			top: 0;
		}
		
	}
}

.tipPopup{
	
	::v-deep .el-dialog{
		box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.1), 0px 0px 5px 0px rgba(0,0,0,0.14);
		border-radius: 10px;
		.el-dialog__header{
			padding: 20px 36px 0 28px;
			.el-dialog__title{
				font-family: PingFangSC, PingFang SC;
				font-weight: 500;
				font-size: 18px;
				color: rgba(0,0,0,0.85);
				line-height: 25px;
				text-align: left;
				font-style: normal;
			}
		}
		.fp-left{
			font-family: PingFangSC, PingFang SC;
			font-weight: 500;
			font-size: 16px;
			color: rgba(0,0,0,0.85);
			line-height: 21px;
			text-align: left;
			font-style: normal;
			margin-bottom: 12px;
		}
	}
}

.pingCss{
	::v-deep .el-dialog{
		width: 700px;
		background: #FFFFFF;
		box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.1), 0px 0px 5px 0px rgba(0,0,0,0.14);
		border-radius: 10px;
		.el-dialog__header{
			padding: 32px 36px 0 28px;
			.el-dialog__title{
				font-family: PingFangSC, PingFang SC;
				font-weight: 500;
				font-size: 18px;
				color: rgba(0,0,0,0.85);
				line-height: 25px;
				text-align: left;
				font-style: normal;
			}
		}
		.el-dialog__body{
			padding: 0 28px 8px;
			.el-form-item{
				margin-top: 24px;
				.el-form-item__content{
					margin-left: 0 !important;
					.ping-info{
						font-family: PingFangSC, PingFang SC;
						font-weight: 400;
						font-size: 16px;
						color: rgba(0,0,0,0.85);
						line-height: 32px;
						text-align: justify;
						font-style: normal;
					}
					.el-textarea{
						margin-top: 12px;
					}
					.el-rate__icon{
						font-size: 28px;
					}
					.ping-note{
						display: block;
						resize: vertical;
						padding: 5px 15px;
						line-height: 1.5;
						box-sizing: border-box;
						width: 100%;
						min-height: 120px;
						font-size: inherit;
						color: #606266;
						background-color: #fff;
						background-image: none;
						border: 1px solid #dcdfe6;
						border-radius: 4px;
						transition: border-color .2s cubic-bezier(.645, .045, .355, 1);
						margin-top: 12px;
					}
				}
			}
		}
	}
}
	
	
.proj-box{
	::v-deep .el-dialog{
		width: 700px !important;
		height: 656px;
		background: #FFFFFF;
		box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.1), 0px 0px 5px 0px rgba(0,0,0,0.14);
		border-radius: 10px;
		.el-dialog__headerbtn{
			top: 30px;
		}
		.el-dialog__body{
			padding:0 32px 32px;
			.dialog-title{
				font-family: PingFangSC, PingFang SC;
				font-weight: 500;
				font-size: 18px;
				color: rgba(0,0,0,0.85);
				line-height: 25px;
				text-align: left;
				font-style: normal;
			}
			.searchBox{
				.el-input-group__append{
					background-color: #4077dd;
					border: 1px solid #4077dd;
				}
			}
			.dialog-bom{
				.el-button{
					width: 96px;
					height: 40px;
					background: #4077DD;
					border-radius: 8px;
					font-family: PingFangSC, PingFang SC;
					font-weight: 400;
					font-size: 16px;
					color: #FFFFFF;
					line-height: 24px;
					font-style: normal;
					display: flex;
					justify-content: center;
					align-items: center;
				}
				.close{
					background: #FFFFFF;
					border-radius: 6px;
					border: 1px solid #4077DD;
					color: #4077DD;
					margin-right: 14px;
				}
			}
			
			
		}
	}
	.pro-list{
		width: 100%;
		height: 415px;
		overflow-y: scroll;
		overflow-x: hidden;
		margin-top: 24px;
		.item{
			::v-deep .el-checkbox{
				display: flex;
				align-items: center;
				margin-bottom: 24px;
				.el-checkbox__label{
					padding-left: 25px;
					width: 100%;
					display: flex;
					.el-image{
						position: relative;
						top: 4px;
						width: 46px;height: 46px;border-radius: 50%;
					}
				}
			}
			::v-deep .el-radio{
				display: flex;
				align-items: center;
				margin-bottom: 24px;
				.el-radio__label{
					padding-left: 25px;
					width: 100%;
					display: flex;
					.el-image{
						position: relative;
						top: 4px;
						width: 46px;height: 46px;border-radius: 50%;
					}
				}
			}
			.user-box{
				margin-left: 16px;
				.item-title{
					font-family: PingFangSC, PingFang SC;
					font-weight: 400;
					font-size: 16px;
					color: rgba(0,0,0,0.85);
					line-height: 21px;
					text-align: left;
					font-style: normal;
					display: flex;
					align-items: center;
					.tj-title{
						border-radius: 4px;
						border: 1px solid #4077DD;
						font-family: PingFangSC, PingFang SC;
						font-weight: 400;
						font-size: 12px;
						color: #4077DD;
						line-height: 21px;
						text-align: left;
						font-style: normal;
						padding: 0 4px;
						margin-left: 8px;
					}
					::v-deep .el-rate{
						margin-left: 24px;
						position: relative;
						top: -2px;
						.el-rate__icon{
							font-size: 22px;
							margin-right: 1px;
						}
					}
				}
				
				.item-tabs{
					margin-top: 12px;
					span{
						background: #F5F7FA;
						border-radius: 4px;
						font-family: PingFangSC, PingFang SC;
						font-weight: 400;
						font-size: 12px;
						color: #555C70;
						line-height: 20px;
						text-align: left;
						font-style: normal;
						padding: 3px 8px;
						margin-right: 8px;
					}
				}
			}
			
		}
	}
}		

.look-box{
	::v-deep .el-dialog{
		width: 700px;
		min-height: 593px;
		background: #FFFFFF;
		box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.1), 0px 0px 5px 0px rgba(0,0,0,0.14);
		border-radius: 10px;
		padding: 32px;
		.el-dialog__header{
			padding: 0;
		}
		.el-dialog__body{
			padding: 0;	
		}
	}
	.title{
		font-family: PingFangSC, PingFang SC;
		font-weight: 500;
		font-size: 18px;
		color: rgba(0,0,0,0.85);
		line-height: 36px;
		text-align: left;
		font-style: normal;
		margin-top: 16px;
	}
	.look-title{
		font-family: PingFangSC, PingFang SC;
		font-weight: 500;
		font-size: 16px;
		color: rgba(0,0,0,0.45);
		line-height: 36px;
		text-align: left;
		font-style: normal;
		margin-top: 33px;
		margin-bottom: 8px;
	}
	.look-bbx{
		font-family: PingFangSC, PingFang SC;
		font-weight: 500;
		font-size: 18px;
		color: rgba(0,0,0,0.85);
		line-height: 36px;
		text-align: left;
		font-style: normal;
		.m-flex{
			justify-content: space-between;
			position: relative;
			img{
				width: 14px;
				height: 14px;
			}
		}
		.lines-next{
			width: 100%;
			height: 1px;
			background-color: #E8E8E8;
			margin-top: 17px;
			margin-bottom: 9px;
		}
		.color1{
			color: rgba(0,0,0,0.85);
		}
		.color2{
			color: #FF4D4F;
		}
		.color3{
			color: #4077DD;
		}
		
	}
}



.qrcode {
  display: inline-block;
  box-shadow: 0 0 10px #888;
  width: 250px;
  height: 250px;
  padding: 15px; // 利用padding的特性，挤出白边
  box-sizing: border-box;
  border-radius: 2px;
  margin-bottom: 20px;

  img {
    width: 220;
    height: 220;
    background-color: #fff; //设置白色背景色

    box-sizing: border-box;
  }
}

.dialog-pwd{
	::v-deep .el-dialog{
		width: 400px !important;
		min-height: 260px;
		.shet-title{
			font-family: PingFangSC, PingFang SC;
			font-weight: 500;
			font-size: 16px;
			color: rgba(0,0,0,0.85);
			line-height: 20px;
			font-style: normal;
		}
		.el-dialog__body{
			.el-input__inner{
				height: 40px;
				margin-left: 0;
			}
			.dialog-bom{
				display: flex;
				justify-content: center;
				.el-button{
					width: auto;
				}
			}
		}
	}
}

.left-box-invites{
	width: 100%;
	height: 555px;
	overflow-y: scroll;
	overflow-x: hidden;
	.item-invites{
		justify-content: space-between;
		font-family: PingFangSC, PingFang SC;
		font-weight: 400;
		color: rgba(0,0,0,0.85);
		margin-top: 24px;
		.left{
			width: calc(100% - 150px);
			.user-invites{
				margin-left: 36px;
				.invits-top{
					.username{
						font-size: 16px;
						line-height: 21px;
					}
					.userTag{
						padding: 0px 3.34px;
						border-radius: 4px;
						border: 1px solid #4077DD;
						font-size: 12px;
						color: #4077DD;
						line-height: 21px;
						margin-left: 8px;
					}
					::v-deep .el-rate{
						margin-left: 24px;
					}
				}
				.invites-sn{
					font-size: 14px;
					margin-top: 12px;
				}
				.invites-tags{
					margin-top: 12px;
					span{
						width: 89px;
						height: 22px;
						background: #F5F7FA;
						border-radius: 4px;
						font-size: 12px;
						color: #555C70;
						line-height: 20px;
						margin-right: 8px;
						padding: 3px 8px;
					}
				}
				.invites-fen{
					margin-top: 12px;
					.invites_lines{
						margin-right: 24px;
						.numbers{
							font-size: 14px;
						}
						.text{
							font-size: 12px;
						}
					}
				}
			}
		}
		.right{
			padding-right: 10px;
		}
	}
}


</style>
