<template>
  <div class="boxCenter">
    <div class="topNavs">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/informationList' }"
          >消息列表</el-breadcrumb-item
        >
        <el-breadcrumb-item>消息详情</el-breadcrumb-item>
      </el-breadcrumb>
    </div>

    <div class="conTBVox">
      <div class="listbox">
        <div class="titles wzk-flex-1">
          {{ title || "标题错误" }}
        </div>
        <div class="content">
          {{ times || "****-**-** **:**:**" }}
        </div>
      </div>
      <div class="content" style="margin-top: 20px" v-html="content"></div>
      <div v-if="processType == 9" style="margin-top: 20px">
        <div class="title" v-for="(item, index) in detail" :key="index + 0.4">
          <div style="margin-top: 20px">{{ item.name }}:</div>
          <div v-for="subItem in item.items" :key="subItem.id" class="contents">
            <div>{{ subItem.title }}；</div>
            <div>具体说明： {{ subItem.note ? subItem.note : 无 }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      content: "",
      title: "",
      id: 0,
      times: "",
      types: 1, //1.消息通知
      processType: 0,
      detail: [], //驳回内容
    };
  },
  created() {
    let data = this.$route.query;
    if (data.pathName && data.pathUrl) {
      this.pathText = data.pathName;
      this.pathUrl = data.pathUrl;
    }
    //
    this.types = data.types;
    this.id = data.id;
    this.processType = data.processType;
    if (this.id && this.types) {
      this.getDetails();
    } else {
      this.$message.error("参数错误");
    }
  },
  methods: {
    getDetails() {
      const loading = this.$loading({
        lock: true,
        text: "加载中...",
        spinner: "el-icon-loading",
        background: "rgba(255, 255, 255, 0.8)",
      });
      if (this.types == 1) {
        this.$http
          .get("notice/detail", {
            id: this.id,
          })
          .then((res) => {
            if (res.code == 200) {
              this.title = res.data.title;
              var richtext = res.data.content || "";
              const regexs = new RegExp("<img", "gi");
              var richtext = richtext.replace(
                regexs,
                `<img style="max-width: 100%;"`
              );
              this.content = richtext;
              this.times = res.data.createTime;
              this.detail = JSON.parse(res.data.detail);
              this.detail.forEach((item) => {
                console.log(1, !item.name.indexOf("是否"));
                if (!item.name.indexOf("是否")) {
                  item.name = item.name.substring(2);
                }
              });
            }
            loading.close();
          })
          .catch((err) => {
            loading.close();
          });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.contents {
  font-size: 16px;
  line-height: 20px;
  font-weight: 400;
  margin-top: 20px;
  margin-left: 30px;
}
.title {
  font-weight: 700;
  margin-top: 20px;
}
.conTBVox {
  border-radius: 5px;
  padding: 20px;
  box-sizing: border-box;
  background-color: #ffffff;
}

.listbox {
  display: flex;

  .titles {
    font-weight: 550;
  }

  .content {
    font-size: 16px;
    margin-left: 20px;
    line-height: 20px;
  }
}
</style>
