<template>
	<div class="boxCenter">
		<div class="topNavs">
			<div class="mdx-flex mdx-row-between">
				<el-breadcrumb separator-class="el-icon-arrow-right">
					<el-breadcrumb-item :to="{ path: '/myProject' }">我参与的项目</el-breadcrumb-item>
					<el-breadcrumb-item>项目详情</el-breadcrumb-item>
				</el-breadcrumb>
			</div>
			<div class="topMeg" style="height: 21px;">
				<!-- <img src="../../../static/images/project/zixun@2x.png" />
				<span>客户投诉(0)</span> -->
			</div>
		</div>
		<div class="hall-top">
			<div class="top-content">
				<div class="left">
					<div class="topSn">
						<span>
							<span>项目编号：{{ projectInfo.sn || '--' }}</span>
							<span style="margin-left: 24px; color: #4077DD;" v-if="projectInfo.splitProjects!=null && projectInfo.splitProjects.length > 0">关联项目:
								<span v-for="(item,index) in projectInfo.splitProjects" :key="index" style="margin-left: 4px; color: #4077DD;">{{item.sn || ''}}</span>
							</span>
						</span>
					</div>
					<div class="topNames">
						<div class="line2 wzk-flex-1" style="display: flex; flex-flow: wrap;">
							<span v-if="projectInfo.status == 3" class="topState on-sign-up">报名中</span>
							<span v-if="projectInfo.status == 4" class="topState on-conduct">项目进行中</span>
							<span v-if="projectInfo.status == 5" class="topState on-success">圆满完成</span>
							<span v-if="projectInfo.status == 9" class="topState on-completed">已取消</span>
							<!-- <div class="status blue" v-if="projectInfo.status == 3 && projectInfo.offlineStatus == 1"> 线下转账审核中</div>
							<div class="status error" v-else-if="projectInfo.status == 3 && projectInfo.offlineStatus == 3"> 线下转账审核未通过</div>
							<div class="status blue" v-else-if="projectInfo.status == 4 && projectInfo.checkStatus == 1"> 成果待审核</div>
							<div class="status error" v-else-if="projectInfo.status == 4 && (projectInfo.checkStatus == 2 || projectInfo.checkStatus == 5)">未通过</div>
							<div class="status blue" v-else-if="projectInfo.status == 4 && projectInfo.checkStatus == 3">待验收</div> -->
							<span>{{ projectInfo.regionFy }}/{{ projectInfo.typeFy }}</span>
							<template v-if="projectInfo.majorFyOne && projectInfo.majorFyOne.length">
								<span v-for="(row, index) in projectInfo.majorFyOne" :key="index">/{{ row.name }}</span>
							</template>
							<span>/总投资:￥{{ projectInfo.totalInvestment || '00' }}</span>
							<span>/总规模:{{ projectInfo.totalScale }}m²</span>
							<span>/{{ projectInfo.serviceContentFy }}</span>
							<div style="color: #4077DD;font-size: 14px;margin-left: 8px;" v-if="projectInfo.progressList.length" @click="openJinduShow = true">查看进度</div>
						</div>
					</div>
					<div class="topTagsBox">
						<span class="tags">{{ projectInfo.regionFy }}</span>
						<span class="tags" v-if="projectInfo.necessary == 1">需要出现场</span>
						<span class="tags" v-if="projectInfo.provided == 1">提供CAD图纸</span>
						<span class="tags">{{ projectInfo.typeFy }}</span>
						<template v-if="projectInfo.majorFy.length">
							<span class="tags" v-for="(row, index) in projectInfo.majorFy" :key="index + 'w'">{{ row.name }}</span>
						</template>
						<span class="tags" v-if="projectInfo.serviceContentFy">{{ projectInfo.serviceContentFy }}</span>
						<span class="tags" v-for="(row, index) in projectInfo.softwareFy" :key="index + 'e'">{{ row.label }}</span>
					</div>
					<div class="topPrice">
						<span class="prices w-m-r-30">
						  总规模：{{ projectInfo.totalScale || '' }}m²
						</span>
						<span class="prices w-m-r-30" v-if="projectInfo.earningsAmount">
						  工程师收益：{{ projectInfo.earningsAmount || '' }}
						</span>
						<span class="prices w-m-l-20" v-if="projectInfo.profitType == 2">
						  固定总价
						</span>
					</div>
					<div class="topTime m-flex">
						<span>成果交付时间：{{ projectInfo.engineerDeadline }}</span>
						<span class="yq-time" v-if="projectInfo.tprojectPostpone!=null && projectInfo.tprojectPostpone.dayNum > 0 && projectInfo.tprojectPostpone.postponeType==0 && projectInfo.tprojectPostpone.isWho==1">已延期{{projectInfo.tprojectPostpone.dayNum}}天</span>
					</div>
				</div>
				<div class="right">
					<div>
						<!-- <div class="right-type-box2" v-if="projectInfo.status == 3">
							<div class="right-two-box">
								<div class="type-btn cursor" style="background: #fff; color: #4077DD; border: 1px solid #4077DD;"  @click.stop="cancelBtns(projectInfo.id)" v-if="projectInfo.fgJurisdiction==2 && projectInfo.zpEngineer==1 && projectInfo.fgStatus!=9">
									<span>取消报名</span>
								</div>
								<div class="type-btn cursor" style="background: #fff; color: #4077DD; border: 1px solid #4077DD;"  @click.stop="cancelBtns(projectInfo.id)"  v-if="projectInfo.fgStatus==3||projectInfo.zpEngineer==2&&projectInfo.fgJurisdiction==1">
									<span>取消接单</span>
								</div>
								<div class="type-btn type-default" v-if="projectInfo.fgStatus == 9">
									<span>立即报名</span>
								</div>
								<div class="type-number">
									<img src="../../../static/images/project/hot@2x.png" />
									<span>{{projectInfo.signUpNum || '0'}} 个工程师已报名</span>
								</div>
							</div>
						</div> -->
						<div class="right-type-box2" v-if="projectInfo.status == 3">
							<div class="right-btn cursor" @click.stop="refuseApplyBtn()" v-if="projectInfo.fgStatus==1">
								<span>拒绝申请</span>
							</div>
							<!-- <div class="right-btn cursor" @click.stop="cancelBtns(projectInfo.id)" v-if="projectInfo.fgJurisdiction==2 && projectInfo.zpEngineer==1 && projectInfo.fgStatus!=9">
								<span>取消报名</span>
							</div> -->
							<div class="right-btn cursor" @click.stop="cancelBtns(projectInfo.id)"  v-if="projectInfo.fgStatus==3||projectInfo.zpEngineer==2&&projectInfo.fgJurisdiction==1">
								<span>取消接单</span>
							</div>
							<div class="right-two-box">
								<div class="type-btn cursor" @click.stop="applyShow=true"  v-if="projectInfo.fgStatus==1">
									<span>立即申请</span>
								</div>
								<div class="type-btn" style="background: #ddd; color: rgba(0,0,0,0.45);;" v-else-if="projectInfo.fgStatus==9">
									<span>立即报名</span>
								</div>
								<div class="type-btn cursor" @click.stop="cancelBtns(projectInfo.id)"  v-else-if="projectInfo.fgJurisdiction==2 && projectInfo.fgStatus==2">
									<span>取消报名</span>
								</div>
								<div class="type-btn cursor" @click.stop="payTypeShow=true"  v-else-if="projectInfo.fgStatus==3 && projectInfo.offlineStatus!=1||projectInfo.zpEngineer==2&&projectInfo.fgJurisdiction==1 && projectInfo.offlineStatus!=1">
									<span>缴纳质保金</span>
								</div>
								<div class="type-btn" style="background: #ddd; color: rgba(0,0,0,0.45);;" v-else>
									<span>缴纳质保金</span>
								</div>
								<div class="type-number">
									<img src="../../../static/images/project/hot@2x.png" />
									<span>{{projectInfo.signUpNum || '0'}} 个工程师已报名</span>
								</div>
							</div>
						</div>
					</div>
					
					<div class="right-type-box2" v-if="projectInfo.status==4">
						<template>
							<!-- 已发布待审核 -->
							<el-dropdown @command="handleCommand">
								<el-button size="small" class="right-btn cursor" type="text" @click.stop="">更多</el-button>
								<el-dropdown-menu slot="dropdown" style="width: 120px; left: 0;">
									<el-dropdown-item :command="{projectInfo, type: 1, }">申请延期</el-dropdown-item>
									<el-dropdown-item :command="{projectInfo, type: 2, }">下载资料</el-dropdown-item >
									<el-dropdown-item :command="{projectInfo, type: 3, }">申请取消项目</el-dropdown-item>
									<el-dropdown-item :command="{projectInfo, type: 4, }" v-if="projectInfo.isFor==1">申请阶段结款</el-dropdown-item>
								</el-dropdown-menu>
							</el-dropdown>
						</template>
						<div class="right-two-box">
							<div class="type-btn cursor" @click.stop="payTypeShow=true"  v-if="projectInfo.isBjJe==2">
								<span>补缴质保金</span>
							</div>
							<div class="type-btn cursor" @click="submitChengguo" v-else>
								<span>上传验收成果</span>
								<img src="../../../static/images/project/type-icon-1@2x.png" class="top-left-tabs" v-if="projectInfo.checkStatus == -1" />
								<img src="../../../static/images/project/type-icon-3@2x.png" class="top-left-tabs"  v-if="projectInfo.checkStatus == 1 || projectInfo.checkStatus == 2 || projectInfo.checkStatus == 6" />
								<img src="../../../static/images/project/type-icon-2@2x.png" class="top-left-tabs"   v-else-if="projectInfo.checkStatus == 3 || projectInfo.checkStatus == 5" />
							</div>
						</div>
					</div>
					
						
					<div v-if="projectInfo.status == 5">
						 <div class="right-rate-box1">
						 	<div class="rate-list">
								<div class="item-rate">
								   <div class="item-title">客户评分：</div>
								   <el-rate v-model="uvalue" disabled disabled-void-color="#D8D8D8"></el-rate>
								</div>
								<div class="item-rate">
									<div class="item-title">项目经理评分：</div>
									<el-rate v-model="mvalue" disabled disabled-void-color="#D8D8D8"></el-rate>
								</div>
							</div>
						 </div>
					 </div>	
					
				</div>
			</div>
			
			<div v-if="projectInfo.status==4 && projectInfo.cancelOrder!=null && projectInfo.cancelOrder.type==8 && projectInfo.cancelOrder.status==1">
				 <div class="topTip">
				 	<div class="topTitle">
				 		<div class="tipLeft">
				 			<img src="../../../static/images/center/wait@2x.png" />
				 			<span>您已经申请取消并终止项目，请等待项目经理审核。</span>
				 		</div>
				 	</div>
				 </div>
			</div>
			<div v-if="projectInfo.status==4 && projectInfo.cancelOrder!=null && projectInfo.cancelOrder.type==8 && projectInfo.cancelOrder.status==3">
				 <div class="topTip">
				 	<div class="topTitle">
				 		<div class="tipLeft">
				 			<img src="../../../static/images/center/wait@2x.png" />
				 			<span>拒绝原因：{{projectInfo.cancelOrder.cause || ''}}</span>
				 		</div>
				 	</div>
				 </div>
			</div>
			<div v-else>
				<div class="topTip" v-if="projectInfo.status==3&&projectInfo.fgStatus==3||projectInfo.status==3&&projectInfo.zpEngineer==2&&projectInfo.fgJurisdiction==1">
					<div class="topTitle">
						<div class="tipLeft">
							<img src="../../../static/images/center/wait@2x.png" />
							<span>您已获得项目资料的查看下载权限，请及时查看资料。</span>
						</div>
						<div class="tipRight cursor" @click="downWenjian">下载资料</div>
					</div>
				</div>
				
				<div class="topTip" v-if="projectInfo.status==4 && projectInfo.isFor==1 && projectInfo.stageCalculate==null">
					<div class="topTitle">
						<div class="tipLeft">
							<img src="../../../static/images/center/wait@2x.png" />
							<span v-if="limitTimeFilter(projectInfo.jdTime)==0">你已提交成果且通过审核，可立即申请阶段性结算</span>
							<span v-else>{{ limitTimeFilter(projectInfo.jdTime) }}</span>
						</div>
						<div class="tipRight cursor" @click="jieduanSubmit" v-if="limitTimeFilter(projectInfo.jdTime)==0">
							申请阶段性结款
						</div>
					</div>
				</div>
				<div class="topTip" v-if="projectInfo.status==4 && projectInfo.stageCalculate!=null && projectInfo.stageCalculate.examineStatus==0">
					<div class="topTitle">
						<div class="tipLeft">
							<img src="../../../static/images/center/wait@2x.png" />
							<span>您已经申请阶段性结款，请耐心等待</span>
						</div>
						<!-- <div class="tipRight cursor">
							查看进度
						</div> -->
					</div>
				</div>
				<div class="topTip" v-if="projectInfo.status==4 && projectInfo.stageCalculate!=null && projectInfo.stageCalculate.examineStatus==2">
					<div class="topTitle">
						<div class="tipLeft">
							<img src="../../../static/images/center/wait@2x.png" />
							<span>阶段性结款被拒绝</span>
						</div>
					</div>
				</div>
				<div class="topTip" v-if="projectInfo.checkStatus==1">
					<div class="topTitle">
						<div class="tipLeft">
							<img src="../../../static/images/center/wait@2x.png" />
							<span>您已经提交项目验收成果，请等待项目经理审核</span>
						</div>
					</div>
				</div>
				<div class="topTip" v-if="projectInfo.checkStatus==3">
					<div class="topTitle">
						<div class="tipLeft">
							<img src="../../../static/images/center/wait@2x.png" />
							<span>项目经理已经验收，请等待客户审核</span>
						</div>
					</div>
				</div>
			</div>
			
			
		</div>
		
		<div class="hall-box">
			<div class="left">
				<div class="lines-title w-m-t-0">
					<span>项目详情</span>
					<div class="hengxian"></div>
				</div>
				<div class="left-box-1">
					<div class="left-title">项目基本信息</div>
					<div class="btLines m-flex">
						<div class="tag-title" style="width: 80px;">项目类型：</div>
						<div style="width: calc(100% - 80px);">
							<el-tag type="info" class="tags">{{ projectInfo.typeFy }}</el-tag>
						</div>
					</div>
					<div class="btLines w-m-t-2 m-flex">
					    <div class="tag-title" style="width: 80px;">所含专业：</div>
						<div style="width: calc(100% - 80px);">
							<el-tag type="info" class="tags" v-for="(item, index) in projectInfo.majorFy" :key="index + 'm'">{{ item.name }}</el-tag>
						</div>
					</div>
					<div class="btLines w-m-t-2 m-flex">
					    <div class="tag-title" style="width: 80px;">服务内容：</div>
						<div style="width: calc(100% - 80px);">
							<el-tag type="info" class="tags">{{ projectInfo.serviceContentFy }}</el-tag>
						</div>
					</div>
					<div class="btLines w-m-t-2 m-flex">
					    <div class="tag-title" style="width: 80px;">要求软件：</div>
						<div style="width: calc(100% - 80px);">
							<el-tag type="info" class="tags" v-for="(item, index) in projectInfo.softwareFy" :key="index + 's'">
								{{ item.label }}
							</el-tag>
						</div>
					</div>
					<!-- <div class="btLines w-m-t-2">
					  <span class="tag-title">总投资：{{ '￥' + projectInfo.totalInvestment }}</span>
					</div> -->
					<div class="btLines w-m-t-16">
					    <span class="tag-title">总规模：{{ projectInfo.totalScale }}m²</span>
					</div>
					<div class="btLines">
					    <span class="tag-title">总占地面积：{{ projectInfo.totalArea }}m²</span>
					</div>
					<div class="left-title">项目描述</div>
					<div class="miaoshu">
					    {{ projectInfo.remark || '无' }}
					</div>
				</div>
				
				<div class="lines-title">
					<span>附件资料</span>
					<div class="hengxian"></div>
				</div>
				<div class="left-box-2" v-if="projectInfo.files.length > 0">
					<div class="left-list mdx-flex mdx-row-around mdx-flex-wrap">
						<div class="item" v-for="(item,index) in projectInfo.files" :key='index'>
							<img src="../../../static/images/project/project@2x.png" />
							<div class="item-title line1">
							  {{ item.name }}
							</div>
							<el-button type="primary" class="downFile" size="mini" @click="viewCad(item)" v-if="item.name.includes('dwg')">查看</el-button>
							<el-button type="primary" plain class="downFile w-m-t-8" size="mini" :loading="item.loadStatus" @click="downAttachmentFile(item, index)">下载资料</el-button>
						</div>
						<template v-if="projectInfo.files % 5 != 0">
						  <div v-for="(item, index) in (5 - (projectInfo.files.length % 5))" :key="index + 'o'"></div>
						</template>
					</div>
				</div>
				<div class="lines-title" v-if="projectInfo.supplyFiles.length > 0">
					<span>补充资料</span>
					<div class="hengxian"></div>
				</div>
				<div class="left-box-2" v-if="projectInfo.supplyFiles.length > 0">
					<div class="left-list mdx-flex mdx-row-around mdx-flex-wrap">
						<div class="item" v-for="(item,index) in projectInfo.supplyFiles" :key='index'>
							<img src="../../../static/images/project/project@2x.png" />
							<div class="item-title line1">{{ item.name }}</div>
							<!-- <el-button type="primary" class="downFile" size="mini" @click="viewCad(item)" v-if="item.name.includes('dwg')">查看</el-button> -->
							<el-button type="primary" plain class="downFile" size="mini" @click="downSupplyFile(item, index)">下载资料</el-button>
						</div>
						<template v-if="projectInfo.supplyFiles % 5 != 0">
						   <div v-for="(item, index) in (5 - (projectInfo.supplyFiles.length % 5))" :key="index + 'p'"> </div>
						</template>
					</div>
				</div>
				
				<div class="lines-title">
					<span>报名人员</span>
					<div class="hengxian"></div>
				</div>
				<div class="left-box-3">
					<div class="item-invites m-flex" @click="toDetails(1)" v-for="(item, index) in bmList" :key='index'>
						<div class="left m-flex">
							<WzkAvatar :isGender="true" :gender="item.gender || 1" :imgSrc="item.headImgUrl" waryWidth="70" waryHeight="70" width="70" height="70" borderRadius="35"></WzkAvatar>
							
							<div class="user-invites">
								<div class="invits-top m-flex">
									<span class="username">{{ $mHelper.nameSlice(2, userInfo, item.name, item.name) }}</span>
									<span class="w-m-l-12">{{ item.engineerNo || '' }}</span>
									<el-rate v-model="item.rateValue" disabled disabled-void-color="#D8D8D8"></el-rate>
									
								</div>
								<div class="invites-sn">
									<span>综合分：<span style="color: #4077DD;">{{ item.synthesize || '' }}</span></span>
								</div>
								<div class="invites-tags">
									<span>精通{{item.majorFy.length || '0'}}个专业</span>
									<span>精通{{item.softwareFy.length || '0'}}个专业软件</span>
									<span>精通{{item.typeFy.length || '0'}}个项目类型</span>
									<span>擅长{{item.regionFy.length || '0'}}个地区</span>
									<span>{{item.years || '0'}}年工作经验</span>
									<span v-if="item.files!=null">有专业证书</span>
								</div>
							</div>
						</div>
						
					</div>
				</div>
				
				<div class="lines-title">
					<span>项目阶段</span>
					<div class="hengxian"></div>
				</div>
				<div class="left-box-4 w-m-t-24" v-if="projectInfo.status==4 && projectInfo.stageCalculate!=null && projectInfo.stageCalculate.examineStatus!=null">
					<div class="jd-item">
						<div class="jd-top">
							<span>{{projectInfo.stageCalculate.createTime}}</span>
							<span>阶段性结款</span>
							<!-- <span style="color: #10C550;">已结算</span> -->
						</div>
						<div class="jd-cont">
							<div class="cont-item">
								<div class="number">1</div>
								<div class="">
									<div class="title">项目经理审核</div>
									<div class="info" v-if="projectInfo.stageCalculate.examineStatus==2">驳回说明：{{projectInfo.stageCalculate.remake || ''}}</div>
									<div class="info">{{projectInfo.stageCalculate.shTime}}</div>
								</div>
							</div>
							<div class="shuxian"></div>
							<div class="cont-item">
								<div class="number" :class="projectInfo.stageCalculate.examineStatus!=1 ? 'number2':''">2</div>
								<div class="">
									<div class="title"  :class="projectInfo.stageCalculate.examineStatus!=1 ? 'backColor':''">平台向工程师结清款项</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				
				<div class="lines-title lines-title2" v-if="projectInfo.status>=4 && projectInfo.tcheckAuditList.length">
					<span>质控记录</span>
					<span class="export cursor" @click="exportZhikong">导出质控记录</span>
					<div class="hengxian"></div>
				</div>
				<div class="left-box-5" v-if="projectInfo.status>=4 && projectInfo.tcheckAuditList.length">
					<el-table :data="projectInfo.tcheckAuditList" style="width: 100%; margin-top: 24px;" :header-cell-style="{ background: '#FAFAFA',  'text-align': 'left' }" :cell-style="{ 'text-align': 'left' }">
					  <el-table-column prop="title" label="文件名称" width="220px">
						  <template slot-scope="scope">
						     <span style="color: #3B77FF;">{{scope.row.fileName || '客户审核'}}</span>
						  </template>
					  </el-table-column>
					  <el-table-column prop="fileUploadTime" label="上传时间" width="170px"></el-table-column>
					  <el-table-column prop="checkStatus" label="结果" width="100px">
						  <template slot-scope="scope">
						     <span v-if="scope.row.checkStatus==1" style="color: #10C550;">通过</span>
							 <span v-if="scope.row.checkStatus==2" style="color: #FF4D4F;">驳回</span>
						  </template>
					  </el-table-column>
					  <el-table-column prop="checkRemark" label="驳回说明" width="190px">
						  <template slot-scope="scope">
							 <span v-if="scope.row.checkRemark==null || scope.row.checkRemark==''" style="color: #10C550;">--</span>
							 <el-popover placement="right" width="600" trigger="hover" v-else>
							   <div class="" v-if="isJsonString(scope.row.checkRemark)==true">
								   <div v-for="(item,index) in remarkLook(scope.row.checkRemark)" :key="index" class="w-m-b-12">
									   <div style="font-size: 16px; color: #333;">{{item.name || ''}}：</div>
									   <div v-for="(it,ii) in item.items" :key="ii" class="">
										   <div class="w-m-t-10 ">
											   <span>{{it.title || ''}}</span>
											   <span class="w-m-l-16" style="color: #FF4D4F;">扣除：{{it.score || ''}}</span>
										   </div>
										   <div class="">
											   <span>描述：{{it.note || ''}}</span>
										   </div>
									   </div>
								   </div>
							   </div>
							   <span v-else style="color: #FF4D4F;">{{remarkLook(scope.row.checkRemark)}}</span>
							   <span slot="reference">
							      <span class="tj-title" style="color: #FF4D4F; border-color: #FF4D4F;">查看说明</span>
							   </span>
							 </el-popover>
						  </template>
					  </el-table-column>
					  <el-table-column prop="fileId" label="下载文件" width="190px" >
						  <template slot-scope="scope">
							 <span v-if="scope.row.fileId!=null" style="color: #10C550;">下载文件</span>
						  </template>
					  </el-table-column>
					  <el-table-column prop="checkTime" label="审核时间" width="170px" />
					</el-table>
				</div>
				<div class="left-box-7" v-if="projectInfo.engineerInfo.id">
					<div class="lines-title">
						<span>留言板</span>
						<div class="hengxian"></div>
					</div>
					<div class="conTBVox" style="margin-top: 30px;">
						<div class="w-m-t-20">
						  <el-radio v-model="radio" :label="1">所有人可见</el-radio>
						  <el-radio v-model="radio" :label="null">项目经理可见</el-radio>
						</div>
					  <div class="w-m-t-10">
					    <el-input type="textarea" :rows="8" placeholder="请输入内容" v-model.trim="textarea">
					    </el-input>
					  </div>
					  <div class="w-m-t-10 mdx-flex mdx-row-right">
					    <el-button type="primary" style="padding: 10px 40px;" size="mini" @click="saveMsgTap"> 发布</el-button>
					  </div>
					</div>
					<div class="conTBVox" style="margin-top: 30px;" v-for="(item) in msgList" :key="item">
					  <div class="mdx-flex liuyanList">
					    <WzkAvatar :imgSrc="item.headImgUrl" :borderRadius='50'></WzkAvatar>
					    <div class="w-m-l-10">
					      <div>
					        {{ $mHelper.nameSlice(item.identity, userInfo, item.nickname, item.name) }}
					        <span v-if="item.identity == 1">(需求方)</span>
					        <span v-if="item.identity == 2">(工程师)</span>
					        <span v-if="item.identity == 3">(项目经理)</span>
					      </div>
					      <div class="w-m-t-5 times">{{ item.createTime }}</div>
					    </div>
					  </div>
					  <div class="w-p-l-60 w-m-t-10">{{ item.content }}</div>
					  <div class="blue w-font-14 mdx-text-right w-m-t-10">
					   <span v-if="item.type == 1">所有人可见</span>
					   <span v-if="item.type == 2">客户可见</span>
					   <span v-if="item.type == 3">工程师可见</span>
					   <span v-if="item.type == null">项目经理可见</span>
					  </div>
					</div>
					<div class="mdx-flex mdx-row-center " style="margin-top: 30px;" v-if="msgList.length">
					  <el-pagination :pageSize="queryParams.limit" :currentPage='queryParams.page' background layout="prev, pager, next" @current-change='pageChange' :page-count="queryParams.totalPage || 1"></el-pagination>
					</div>
				</div>
				
				
			</div>
			<div class="right">
				<div class="right-box-apply" v-if="projectInfo.status==4 && projectInfo.tprojectPostpone!=null && projectInfo.tprojectPostpone.isWho==1 && projectInfo.tprojectPostpone.postponeType!=0">
					<div class="apply-title mdx-flex mdx-row-between">
						<span>项目延期申请</span>	
						<!-- <span class="cursor" style="color: #4077DD;">撤回申请</span> -->
					</div>
					<div class="apply-info">延期申请如果客户通过，项目经理将进行审核。若由于您的缘故造成项目未按时完成，平台会按照程度扣除您的质保金：</div>
					<div class="apply-info" style="color: #EBBD11;" v-if="projectInfo.tprojectPostpone.clientType == 2 && projectInfo.tprojectPostpone.managerType == 2">待客户、项目经理审核</div>
					<div class="apply-info" style="color: #EBBD11;" v-if="projectInfo.tprojectPostpone.clientType ==2 && projectInfo.tprojectPostpone.managerType == 0">待客户审核</div>
					<div class="apply-info" style="color: #FF4D4F; display: flex; align-items: center;" v-if="projectInfo.tprojectPostpone.managerType == 1 && projectInfo.tprojectPostpone.clientType==2">
						<img style="width: 20px; height: 20px;" src="../../../static/images/project/toback@2x.png" />
						项目经理审核失败，原因如下：</div>
					<div class="apply-cont" style="margin-bottom: 10px;" v-if="projectInfo.tprojectPostpone.managerType==1 && projectInfo.tprojectPostpone.clientType==2">{{projectInfo.tprojectPostpone.managerCase || ''}} </div>	
						
					<div class="apply-cont"></div>
					<div class="mdx-flex mdx-row-center mdx-col-center w-m-t-32 w-m-b-32" v-if="projectInfo.tprojectPostpone.clientType == 1">
						<img src="../../../static/images/project/toback@2x.png" style="width: 36px;height: 36px; margin-right: 18px;" />
						<span style="font-family: PingFangSC, PingFang SC;font-weight: 400;font-size: 24px;color: rgba(0,0,0,0.85);line-height: 21px;">客户不同意</span>
					</div>
					<!-- <div style="font-family: PingFangSC, PingFang SC;font-weight: 400;font-size: 14px;color: #4077DD;line-height: 21px;text-align: center;text-decoration-line: underline; margin-bottom: 24px;">我已知晓，仍继续申请</div> -->
				</div>
				<div class="right-box-guo" v-if="projectInfo.engineerInfo.engineerFiles.length">
					<div class="top-guo m-flex">
						<div class="name">验收成果</div>
						<div class="wzk-cursor jieguoBtn" @click="cgShow = true"> 查看所有</div>
					</div>
					<div class="times">
					    上传时间：{{ getLastFileTime(1) }}
					</div>
				    <img src="../../../static/images/project/default@2x.png">
					<div class="title">验收成果</div>
					<!-- <el-button type="primary" plain class="downFile" size="mini" :loading="loadEngiFruit" @click="downloadEngineerFruit">下载资料</el-button> -->
				</div>
				
				<div class="right-box-1" >
					<div class="right-title">发布者</div>
					<div class="right-user">
						<div class="block">
							<el-image class='image-slot' :src="projectInfo.customerInfo.headImgUrl">
								<div slot="error" class="image-slot">
									<img src="../../../static/images/mrtx.png" style="width: 80px;height: 80px;border-radius: 5px;">
								</div>
							</el-image>
						</div>
						<div class="right-uinfo">
							<div class="name">{{ $mHelper.nameSlice(1, userInfo, projectInfo.customerInfo.nickname, projectInfo.customerInfo.name)}}</div>
							<div class="rStatis right-wrz" v-if="projectInfo.customerInfo.certification==2 || projectInfo.customerInfo.certification==null">未认证</div>
							<div class="rStatis right-grrz" v-else-if="projectInfo.customerInfo.certification==1">个人认证</div>
							<div class="rStatis right-qyrz" v-else>企业认证</div>
						</div>
					</div>
					<div class="right-phone">电话：{{  $mHelper.mobileSlice(userInfo.identity, projectInfo.customerInfo.mobile) }}</div>
					<div class="right-phone" v-if="projectInfo.customerInfo.qq">QQ： {{ $mHelper.qqSlice(userInfo.identity, projectInfo.customerInfo.qq) }}</div>
					<div class="right-phone" v-if="projectInfo.customerInfo.mailbox">邮箱： {{ $mHelper.emailSlice(userInfo.identity, projectInfo.customerInfo.mailbox) }}</div>
					<div class="right-time">发布时间：{{projectInfo.createTime}}</div>
				</div>
				<div class="right-box-1 right-box-2" v-if="projectInfo.memberInfo !=null && projectInfo.memberInfo.id !=null">
					<div class="right-title">项目经理</div>
					<div class="right-user">
						<div class="block">
							<el-image class='image-slot' :src="projectInfo.memberInfo.headImgUrl">
								<div slot="error" class="image-slot">
									<img src="../../../static/images/mrtx.png" style="width: 80px;height: 80px;border-radius: 5px;">
								</div>
							</el-image>
						</div>
						<div class="right-uinfo">
							<div class="name">{{ $mHelper.nameSlice(3, userInfo, projectInfo.memberInfo.nickname,projectInfo.memberInfo.name)}}</div>
						</div>
					</div>
					<div class="right-phone">电话： {{ projectInfo.memberInfo.mobile || '' }}</div>
					<div class="right-phone" v-if="projectInfo.memberInfo.qq">QQ： {{ projectInfo.memberInfo.qq || '' }}</div>
					<div class="right-phone" v-if="projectInfo.memberInfo.mailbox">邮箱： {{ projectInfo.memberInfo.mailbox || '' }}</div>
				</div>
				<div class="right-box-1 right-box-2"  v-if="projectInfo.engineerInfo !=null && projectInfo.engineerInfo.id !=null">
					<div class="right-title">工程师</div>
					<div class="right-user">
						<div class="block">
							<el-image class='image-slot' :src="projectInfo.engineerInfo.headImgUrl">
								<div slot="error" class="image-slot">
									<img src="../../../static/images/mrtx.png" style="width: 80px;height: 80px;border-radius: 5px;">
								</div>
							</el-image>
						</div>
						<div class="right-uinfo">
							<div class="name">{{ $mHelper.nameSlice(2, userInfo, projectInfo.engineerInfo.nickname, projectInfo.engineerInfo.name) }}</div>
						</div>
					</div>
					
					<div class="right-phone" v-if="projectInfo.status != 2 && projectInfo.status != 3 && projectInfo.depositAmount > 0">质保金： <span style="color: #10C550;">已缴纳（{{ projectInfo.depositAmount || '0.00' }}元）</span></div>
					<div class="right-phone">电话： {{ $mHelper.mobileSlice(userInfo.identity, projectInfo.engineerInfo.mobile) }}</div>
					<div class="right-phone" v-if="projectInfo.engineerInfo.qq">QQ： {{ $mHelper.qqSlice(userInfo.identity, projectInfo.engineerInfo.qq) }}</div>
					<div class="right-phone" v-if="projectInfo.engineerInfo.mailbox">邮箱： {{ $mHelper.emailSlice(userInfo.identity, projectInfo.engineerInfo.mailbox) }}</div>
				</div>
				
			</div>	
		</div>
		
		
    
	
	
	<!-- 弹窗 -->
	<el-dialog :visible.sync="bhShow" width="40%" center top='30vh'>
	    <div class="puopContext">
	        <h3>驳回详情：</h3>
	        <div style="height: 10px;"></div>
	        <el-input type="textarea" :rows="5" :maxlength="300" style="font-size: 18px;"></el-input>
	    </div>
	    <div class="puopfootBox mdx-text-right">
	        <el-button size="small" @click="bhShow = false">关 闭</el-button>
	    </div>
	</el-dialog>
	
	<!--延期申请:picker-options="pickerOptions"-->
	<el-dialog class="tipPopup" title="延期申请" :visible.sync="timeShowApply" width="30%" center top='20vh'>
	  <div class="puopContext">
		  <el-form ref="form" :model="formYanApplyData" label-width="80px">
			   <el-form-item label="延长截止:" prop="postponeTime">
				 <div class="inputRight">
				   <el-date-picker v-model="formYanApplyData.postponeTime"  type="datetime" value-format="yyyy-MM-dd HH:mm:ss" format="yyyy-MM-dd HH:mm:ss" placeholder="选择日期时间" @change="estimateMoney()"></el-date-picker>
				 </div>
			   </el-form-item>
			   
			   <el-form-item label="延期原因:" prop="cause">
					<el-input v-model="formYanApplyData.cause" placeholder="请写明延期原因" type="textarea" maxlength="255" :autosize="{ minRows: 4, maxRows: 6 }" class="input"></el-input>
			   </el-form-item>
		  </el-form>
		  <span slot="footer" class="dialog-bom mdx-flex mdx-row-center w-m-t-50">
			<el-button class="close" size="small" @click.stop="timeShowApply=false">取 消</el-button>
		    <el-button class="w-m-l-24" type="primary" size="small" :loading="loading" @click.stop="submitYanEvaluate">确 定</el-button>
		  </span>
	  </div>
	</el-dialog>
	
	<!--成果验收-->
	<el-dialog class="tipPopup" title="提交成果" :visible.sync="supplementShow" width="40%" center top='20vh'>
	  <div class="puopContext w-font-16">
	    <div>
	      <div>
	        {{projectInfo.regionFy}}/{{projectInfo.typeFy}}
	        <!-- {{projectInfo.majorFy}} -->
	        <template v-if="projectInfo.majorFy.length">
	          <span v-for="(row,indexss) in projectInfo.majorFy" :key="indexss">/{{row.name}}</span>
	        </template>
	        <span>
	          /总投资:￥{{projectInfo.totalInvestment||'00'}}
	        </span>
	        <span>
	          /总规模:{{projectInfo.totalScale}}m²
	        </span>
	        /{{projectInfo.serviceContentFy}}
	      </div>
	    </div>
	    <div class="w-m-t-10">
	      <!--  -->
	      <el-upload class="upload-demo" ref="files" :action="action" :on-success="cgSuccess" :on-remove="cgRemove" :on-progress="onProgress" :on-error="onError" multiple :limit="1" :on-exceed="handleExceed" :file-list="fileList">
	        <el-button size="small" type="primary">点击上传</el-button>
	        <span v-if="onPrepareFile" class="prepare-file">
	        文件准备中，请稍后… 文件名末尾显示
	        <i class="tip-success el-icon-circle-check"></i>
	        才算上传成功
	        </span>
	      </el-upload>
	    </div>
	    <div class="mdx-flex mdx-row-center w-m-t-30">
	      <el-button type="primary" style="padding: 10px 40px;border-radius: 2px;" class="" @click.stop="suppleUpdata()"> 提交</el-button>
	    </div>
	  </div>
	</el-dialog>
	
	<!--成果展示-->
	<el-dialog class="tipPopup" title="历史成果" :visible.sync="cgShow" width="40%" center top='20vh'>
	  <div class="puopContext">
	    <div v-for="(item, index) in projectInfo.engineerInfo.engineerFiles" :key='index'>
			<div class="mdx-flex mdx-col-center">
				<div class="w-font-16">
				  {{ item.name }}
				</div>
				<div class="w-m-l-24 w-font-14">
				  {{ item.createDate }}
				</div>
			</div>
			 <div class="mdx-flex mdx-row-between  w-m-t-10">
				<div class="mdx-text-center">
				   <img src="../../../static/images/project/default@2x.png">
				</div>
				<div class="" style="text-align: center;">
				  <el-button type="primary" plain class="downFile" size="mini" @click="downAttachmentFile(item)">下载资料 </el-button>
				</div>
			</div>
	    </div>
	  </div>
	</el-dialog>
	
	<!--项目进展-->
	<el-dialog class="tipPopup" title="项目进展" :visible.sync="openJinduShow" width="30%" center top='20vh'>
	  <div class="">
		  <div class="project-jz" v-if="projectInfo.progressList.length">
		    <div class="wzk-flex-1">
		      <div class="jz-list">
		        <div class="item" v-for="(item, index) in projectInfo.progressList" :key='index'>
		  		  <div class="title">
		  			    <span>{{ item.title }}</span>
		  				<el-tooltip :content="item.content" placement="top" effect="light">
		  				  <span style="color: #185BA1;cursor: pointer; margin-left: 16px;" v-if="item.content">详情</span>
		  				</el-tooltip>
		  		  </div>
		  		  <div class="times">
		  			  {{ item.createTime }}
		  		  </div>
		        </div>
		      </div>
		    </div>
		  </div>
	  </div>
	</el-dialog>
	
	<!--申请结束项目-->
	<el-dialog title="申请取消项目" :visible.sync="cancalgxmCloseShow"  width="40%" center top='20vh' class="tipPopup look-box">
	  <div class="w-m-t-16">
		   <el-form ref="form">
			<div class="title w-m-t-12>" style="color: rgba(0,0,0,0.85);">项目已经开始作业，如果由于您的原因取消项目，会导致您的质保金被扣除，影响您的信誉值并导致一段时间无法承接新的项目（具体处理方式请参见 <span @click="$mHelper.otherTo('/xieyi?type=3')" class="wzk-cursor" style="color: #4077DD;">《中一鼎合造价众包平台服务规则》</span>）。请问您确定要取消项目吗？</div>
			 <el-form-item label="" prop="cacalNotes" class="w-m-t-12">
				<el-input v-model="cacalNotes" placeholder="请详细说明申请理由" type="textarea" maxlength="255" :autosize="{ minRows: 6, maxRows: 6 }" class="input"></el-input>
			 </el-form-item>
		   </el-form>
		  <span slot="footer" class="dialog-bom mdx-flex mdx-row-center w-m-t-50">
			<el-button class="close" size="small" @click.stop="cancalgxmCloseShow=false">取 消</el-button>
		    <el-button class="w-m-l-24" type="primary" size="small" @click.stop="submitCacnlEvaluate">确 定</el-button>
		  </span>
	  </div>
	</el-dialog>
	
	
	<el-dialog class="tipPopup" :title="projectInfo.isBjJe==2?'补缴质保金':'支付质保金'" :visible.sync="payTypeShow" width="40%" top='20vh'>
	 
	  <div class=" ">
	    <div class="" style="font-family: PingFangSC, PingFang SC; font-weight: 400; font-size: 18px; color: rgba(0,0,0,0.85); line-height: 36px;">
	      <div>
	        {{projectInfo.regionFy}}/{{projectInfo.typeFy}}
	        <template v-if="projectInfo.majorFy.length">
	          <span v-for="(row,indexss) in projectInfo.majorFy" :key="indexss+'t'">/{{row.name}}</span>
	        </template>
	        <span>
	          /总投资:￥{{projectInfo.totalInvestment||'00'}}
	        </span>
	        <span>
	          /总规模:{{projectInfo.totalScale}}m²
	        </span>
	        /{{projectInfo.serviceContentFy}}
	      </div>
	    </div>
	    <div v-if="projectInfo.isBjJe==2">
	      项目编号：{{projectInfo.sn}}
	    </div>
	    <div class="">
	      <el-checkbox-group v-model="paycheck" @change="bindCheckBox">
	        <div class="payLines w-m-t-12">
	          <el-checkbox label="1"><i class="wzk wzk-weixinPay" style="color: #3BB431; margin-right: 5px;"></i>微信支付</el-checkbox>
	        </div>
	        <div class="payLines w-m-t-12">
	          <el-checkbox label="2"> <i class="wzk wzk-zhifubaoPay" style="color: #1677FF; margin-right: 5px;"></i>支付宝支付</el-checkbox>
	        </div>
	        <div class="payLines w-m-t-12">
	          <el-checkbox label="3"> <i class="wzk wzk-xianxiaPay" style="color: #FD6727; margin-right: 5px;"></i>线下转账</el-checkbox>
	        </div>
			<div class="payLines w-m-t-12">
			  <el-checkbox label="7"><i class="wzk wzk-xianxiaPay" style="color: #FF3B30; margin-right: 5px;"></i>余额支付</el-checkbox>
			</div>
	      </el-checkbox-group>
	    </div>
	    <div class="mdx-flex mdx-row-right ">
	      <div class="w-m-r-20 error">
	        <h3>应付金额 ￥{{projectInfo.isBjJe==2?projectInfo.bjGcsJe:projectInfo.depositAmount}}</h3>
	      </div>
	      <el-button type="primary" style="padding: 10px 30px;border-radius: 2px;" class="" @click='payEnd()'>
	        去付款
	      </el-button>
	    </div>
	  </div>
	</el-dialog>
	<el-dialog  class="tipPopup" title="支付提示"  :visible.sync="transShow" width="40%" center top='20vh'>
	  <div class="puopContext" style="font-family: PingFangSC, PingFang SC; font-weight: 500; font-size: 18px; color: rgba(0,0,0,0.85); line-height: 36px;">
	
	    <div>
	      {{projectInfo.regionFy}}/{{projectInfo.typeFy}}
	      <template v-if="projectInfo.majorFy.length">
	        <span v-for="(row,indexss) in projectInfo.majorFy" :key="indexss+'y'">/{{row.name}}</span>
	      </template>
	      <span>
	        /总投资:￥{{projectInfo.totalInvestment||'00'}}
	      </span>
	      <span>
	        /总规模:{{projectInfo.totalScale}}m²
	      </span>
	      /{{projectInfo.serviceContentFy}}
	    </div>
	    <div class="error w-font-14">
	        请将您要充值的金额在银行柜台、网银、手机银行转账至：
	        ( 该账户为公司账户，仅支持对公转账。)
	    </div>
		<div>银行开户名： {{ bankInfo.name || "" }}</div>
	    <div>银行账号： {{ bankInfo.account || "" }}</div>
	    <div>开户银行： {{ bankInfo.deposit || "" }}</div>
	    <div style="height: 1px"></div>
	    <div class="w-font-13">线下交易流程：</div>
	    <div class="w-font-13">1.请在汇款后，保存汇款凭证</div>
	    <div class="w-font-13">2.点击上传凭证按钮</div>
	    <div class="w-font-13">3.上传转账凭证</div>
	    <div class="w-font-13">
	      4.平台审核通过后，此项目会进入到【进行中的项目】，平台开始工作
	    </div>
	    <div class="mdx-flex mdx-row-right w-m-t-10">
	      <div class="w-m-r-20 error">
	        <h3> 应付金额：￥{{projectInfo.isBjJe == 2 ? projectInfo.bjGcsJe : projectInfo.depositAmount ||'0.00'  }}</h3>
	      </div>
	      <el-button type="primary" style="padding: 10px 30px;border-radius: 2px;" class="" @click='upPingZheng()'>
	        去付款
	      </el-button>
	    </div>
	  </div>
	</el-dialog>
	<el-dialog  class="tipPopup"  title="凭证" :visible.sync="credentialsShow" show-cancel-button :before-close='handleClose' width="40%" center top='20vh'>
	  <div class="puopContext w-font-13">
	    <div style="font-family: PingFangSC, PingFang SC; font-weight: 400; font-size: 18px; color: rgba(0,0,0,0.85); line-height: 36px; margin-bottom: 20px;">
	      <div>
	        {{projectInfo.regionFy}}/{{projectInfo.typeFy}}
	        <template v-if="projectInfo.majorFy.length">
	          <span v-for="(row,indexss) in projectInfo.majorFy" :key="indexss">/{{row.name}}</span>
	        </template>
	        <span>
	          /总投资:￥{{projectInfo.totalInvestment||'00'}}
	        </span>
	        <span>
	          /总规模:{{projectInfo.totalScale}}m²
	        </span>
	        /{{projectInfo.serviceContentFy}}
	      </div>
	    </div>
	    <div class="wzk-cursor">
	      <img src="../../../static/images/jindu.png" style="width: 100%;">
	    </div>
	    <div>
	      <el-form label-position="right" label-width="100px" :model="bankFrom" :rules="transferRules" ref="xianxiaPays">
	        <el-form-item label="开户银行:" prop="bankDeposit">
	          <el-input v-model="bankFrom.bankDeposit" placeholder="请输入开户银行"></el-input>
	        </el-form-item>
	        <el-form-item label="付款人账号:" prop="paymentAccount">
	          <el-input v-model="bankFrom.paymentAccount" placeholder="请输入付款人账号"></el-input>
	        </el-form-item>
	        <!-- {{bankFrom.receipt}} -->
	        <el-form-item label="上传回执单:" prop="receipt">
	          <el-upload class="upload-demo" :action="action" :on-success="receiptSuccess" :on-remove="handleRemove" multiple :limit="1" :on-exceed="handleExceed" :file-list="fileList">
	            <el-button size="small" type="primary">点击上传</el-button>
	          </el-upload>
	        </el-form-item>
	      </el-form>
	    </div>
	
	    <div class="mdx-flex mdx-row-right w-m-t-20">
	      <el-button type="primary" style="padding: 10px 30px;border-radius: 2px;" class="" @click='upataPingzheng()'>
	        提交凭证
	      </el-button>
	      <el-button style="padding: 10px 30px;border-radius: 2px;" class="" @click='handleClose'>
	        取消
	      </el-button>
	    </div>
	  </div>
	</el-dialog>
	<el-dialog  class="tipPopup" :visible.sync="credSuccessShow" width="40%" center top='20vh'>
	  <div class="puopContext w-font-13">
	     <div style="font-family: PingFangSC, PingFang SC; font-weight: 400; font-size: 18px; color: rgba(0,0,0,0.85); line-height: 36px; margin-bottom: 20px;">
	      <div>
	        {{projectInfo.regionFy}}/{{projectInfo.typeFy}}
	        <template v-if="projectInfo.majorFy.length">
	          <span v-for="(row,indexss) in projectInfo.majorFy" :key="indexss">/{{row.name}}</span>
	        </template>
	        <span>
	          /总投资:￥{{projectInfo.totalInvestment||'00'}}
	        </span>
	        <span>
	          /总规模:{{projectInfo.totalScale}}m²
	        </span>
	        /{{projectInfo.serviceContentFy}}
	      </div>
	    </div>
	    <div class="wzk-cursor">
	      <img src="../../../static/images/jinduZhong.png" style="width: 100%;">
	    </div>
	    <div class="mdx-text-center" style="margin: 50px 0;">
	      <i class="el-icon-success  w-font-120" style="color: #185BA1;"></i>
	      <p>
	        您已成功上传凭证！请等待平台审核
	      </p>
	    </div>
	  </div>
	</el-dialog>
	
	<el-dialog class="tipPopup" title="微信支付" :visible.sync="WechatShow" show-cancel-button @close='wxPayPuopClose' width="700px" center top='20vh'>
	  <div class="w-font-13">
	    <div class="mdx-flex mdx-row-between" style="margin-top: 50px;">
	      <div class="w-m-l-20">
	        <div class="qrcode" id="qrcode" ref="qrCodeUrl">
	
	        </div>
	        <div>
	          <el-button type="primary" style="width: 250px;border-radius: 0;">
	            <div class="mdx-flex">
	              <i class="wzk wzk-full-screen" style="font-size: 30px;margin-right: 10px;"></i>
	              <div class="mdx-text-left">
	                请使用微信扫一扫<br />
	                扫描二维码支付
	              </div>
	            </div>
	          </el-button>
	        </div>
	      </div>
	      <div class="w-m-r-50 ">
	        <img src="../../../static/images/wxImg1.png" height="350px">
	      </div>
	    </div>
	  </div>
	</el-dialog>
	
	<!--支付密码-->
	<el-dialog :visible.sync="inputPassword" width="40%" center top='20vh' class="dialog-pwd">
	  <div class="w-font-13">
		<div class="dialog-title">请输入支付密码</div>
	    <div v-if="userInfo.pwdState == 1">
	      <div class="mdx-text-center w-m-t-40 shet-title">您还未设置支付密码</div>
	      <div class="dialog-bom mdx-text-center w-m-t-40">
	        <!-- <div>设置及更多→安全设置支付密码</div> -->
	        <el-button type="primary" class="" @click='toSetPassword'>去设置支付密码</el-button>
	      </div>
	    </div>
	    <div v-else>
	      <div class="mdx-text-center">
	        <div style="width: 100%;margin: 40px 0 60px 0">
	          <el-input v-model="withdrawPassword" type="password" placeholder="请输入支付密码"></el-input>
	        </div>
	      </div>
	      <div class="dialog-bom mdx-flex mdx-row-center w-m-t-46">
			<el-button class="close" size="small" @click="inputPassword = false">取 消</el-button>
	        <el-button type="primary" size="small" :loading="loading" @click='payBalance()'> 确定</el-button>
	      </div>
	    </div>
	  </div>
	</el-dialog>
	
	<el-dialog class="tipPopup" title="申请确认" :visible.sync="applyShow" width="40%" center top='20vh'>
	  <div class=" ">
		  <div class="title">需在此时间前交付项目成果，请确认您可以完成工作。</div>
		  <div class="title" style="color: #FF4D4F;">项目交付时间：{{projectInfo.engineerDeadline||''}}</div>
		  <div class="title w-m-t-12">工程师收益：{{projectInfo.earningsAmount||'0.00'}}元</div>
		  <div class="title"> 缴纳质保金：{{projectInfo.depositAmount||'0.00'}}元 </div>
			<!-- <div>
			  1、须在此时间前交付项目成果，点击“立即申请”表明您有意愿、有时间、有能力完成本项目。<br />
			  2、点击“立即申请”也表明您接受本项目的质量要求和平台作业质量规范并拥有要求的软件。<br />
			  3、点击“立即申请”也表明您同意对后续的资料进行保密。平台项目经理将根据系统匹配推荐依次向成功申请的工程师开放资料查阅权限，以便于工程师评估、接单。<br />
			  4、如您在查阅资料、支付质保金接单后放弃项目，将扣除您的项目质保金并限制您接单60天，同时将影响您的信誉值。<br />
			</div> -->
			<div class="info w-m-t-14">
			  收益为暂估收益，最终收益将在您计算出工程成果后确定
			</div>
			<div class="info">
			  质保金比例为您收益的10%，项目完成后2个月内如无质保问题，您的质保金将退还至您的账户
			</div>
			<div class="info">
			  如您在接单后取消接单或未完成工作，将扣除您的项目质保金并限制您接单2个月，同时将影响您的信誉值！
			</div>
			<div class="info">
			  点击确认申请表明您同意对后续的资料进行保密，并接受项目质量要求
			</div>
			<span slot="footer" class="dialog-bom mdx-flex mdx-row-center w-m-t-20">
				<el-button class="close" size="small" @click="applyShow=false">取 消</el-button>
			   <el-button :loading="loading" type="primary" class="" @click='applyBtnTap()'> 确认申请</el-button>
			</span>
	  </div>
	</el-dialog>
	
    <el-dialog
      v-if="cadShow"
      :visible.sync="cadShow"
      width="80%"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :before-close="() => cadShow = false">
      <div id='map-container' v-loading="cadLoading"
        element-loading-text="拼命加载中"
        element-loading-spinner="el-icon-loading"
        element-loading-background="rgba(255, 255, 255, 0.8)">
      </div>
      <!-- <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="cadShow = false">确 定</el-button>
      </span> -->
    </el-dialog>
  </div>
</template>

<script>
import WzkAvatar from '../../../components/wzk-Avatar/index.vue';
import { initNumberValue } from "@/common/utils/num-validate.js"
import { isArrObj } from "@/common/utils/common.js"
import rulesData from '../../../api/rules.js'
  import QRCode from 'qrcodejs2'
import vjmap from 'vjmap'; 
import 'vjmap/dist/vjmap.min.css' // 样式一定要引用！！！
export default {
  components: {
    WzkAvatar
  },
  data() {
    return {
		radio: 1,
      imgSrc: 'https://img2.baidu.com/it/u=1953922700,4041156140&fm=26&fmt=auto',
      titleShow: false,
      puopShow: false,
      textarea: '',
      Id: null,
      projectInfo: {
        customerInfo: {},
        engineerInfo: {},
        progressList: []
      },
      content:"",//驳回信息
      bhShow: false, //驳回详情弹窗
      msgList: {},
      queryParams: {
        page: 1, //当前页数
        limit: 10, //每页条数
        totalPage: 1
      },
      loading: false,
      cadShow:false,
      cadLoading:false,
	  bmList: [],
		timeShowApply: false,
		formYanApplyData: {
			postponeTime: '',
			cause:'',
		},
		action: this.$store.state.baseURL + 'upload/uploadCos',
		fileList: [],
		// 成果文件
		files: { 
		  name: '',
		  url: '',
		},
		supplementShow: false,//提交成果
		onPrepareFile: false,
		cgShow: false,
		openJinduShow: false, //查看项目进度
		mvalue: '',
		uvalue: '',  
		cancalgxmCloseShow: false, //申请取消项目
		cacalNotes: '',
		
		paycheck: ['1'],
		alipayWap: '', //支付宝节点
		WechatShow: false, //微信支付弹窗,
		payTypeShow: false, //支付弹窗
		transShow: false, //线下转账提示
		credentialsShow: false, //显示凭证弹窗
		credSuccessShow: false, //回款凭证上传成功
		transferRules: rulesData.transferRules,
		bankInfo: {},
		bankFrom: {
		  bankDeposit: "", //开户银行（线下转账必传）
		  payMethod: 0, //支付方式（1.微信，2.支付宝，3.小程序微信支付，4.线下转账）
		  paymentAccount: "", //付款人账号（线下转账必传）
		  receipt: '', //回执单（线下转账必传）
		  sourceId: 0, //项目id(客户付款、工程师支付定金、工程师补缴定金（质保金）、客户补缴尾款 必传)
		  type: 0 //类型（1.客户付款，2.工程师支付定金，3.工程师补缴定金（质保金），4.提现，6.客户补缴尾款）
		},
		inputPassword: false,
		withdrawPassword: "",//支付密码
		applyShow: false, //确认申请项目弹窗
	  
    }
  },
  computed: {
    userInfo() {
      return this.$store.getters['userInfo']
    }
  },
  created() {
    this.Id = this.$route.query.id || null
	
	this.mvalue = this.$route.query.mrate || null
	this.uvalue = this.$route.query.urate || null
	
    if (!this.Id) {
      this.$message.error('参数错误')
      return
    }
    this.getDetail();
	//银行信息
	this.$http.get('config/getBank').then(res => {
	  if (res.code == 200) {
	    this.bankInfo = res.data
	  }
	})
  },
  methods: {
    async viewCad(item){
        this.cadShow = true;
        this.cadLoading = true;
        let splitArr = item.url.split('.');
        let splitArr2 = (splitArr[splitArr.length-2]).split('/');
        let filterMapid = splitArr2[splitArr2.length-1];
        // --通过网络路径打开地图--传入DWG路径的http路径打开DWG图
        // js代码
        // 新建地图服务对象，传入服务地址和token
        const env = {
            serviceUrl: "https://vjmap.com/server/api/v1",
            accessToken: "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJJRCI6MiwiVXNlcm5hbWUiOiJhZG1pbjEiLCJOaWNrTmFtZSI6ImFkbWluMSIsIkF1dGhvcml0eUlkIjoiYWRtaW4iLCJCdWZmZXJUaW1lIjo4NjQwMCwiZXhwIjoxOTQyMzg5NTc0LCJpc3MiOiJ2am1hcCIsIm5iZiI6MTYyNzAyODU3NH0.VQchVXxgjl5aCp3j3Uf5U2Mpk1NJNirH62Ys-8XOfnY",
            exampleMapId: "sys_zp"
        };
        let svc = new vjmap.Service(env.serviceUrl, env.accessToken)
        // 打开地图
        // 地图ID (如果换成了您自定义的url地址，记得修改此名称id，改成您想要的唯一的名称即可)
        const mapid = filterMapid;
        // 地图url地址，注意：url地址后四个字母必须为.dwg。如果实际中的地址四4位不是.dwg可以随便加个参数如 url?param=xxx&filetype=.dwg
        // const httpDwgUrl = "http://o2o-web.oss-cn-beijing.aliyuncs.com/Sample.dwg" ;
        // https://gczjzb-1308562443.cos.ap-nanjing.myqcloud.com/file/30481654501597257.dwg
        const httpDwgUrl = item.url;
        let res = await svc.openMap({
            // (第一次打开此地图ID时，会根据fileid去获取dwg路径打开，之后会读取缓存打开)
            mapid: mapid, // 地图ID
            fileid: httpDwgUrl,
            mapopenway: vjmap.MapOpenWay.GeomRender, // 以几何数据渲染方式打开
            style: vjmap.openMapDarkStyle() // div为深色背景颜色时，这里也传深色背景样式
        })
        
        /*
        “(第一次打开此地图ID时，会根据fileid去获取dwg路径打开，之后会读取缓存打开)” 的意思是
          举例来说，您第一次打开的图的时候用的是
        {
        mapid: 'map1',
        fileid: 'http://127.0.0.1/1.dwg'
        }
        这时候因为后台没有 map1 这个图纸，所以第一次时 map1 就会去下载 'http://127.0.0.1/1.dwg'
        打开图。
        但是，您第二次的时候如果要换成另外的图2.dwg，但是如果还是用同样的mapid的话，如
        {
        mapid: 'map1',
        fileid: 'http://127.0.0.1/2.dwg'
        }
        这时候为了性能，后台不会再去下载2.dwg了，就直接用之前的下载并处理好的图的缓存数据了。
        这时候有两个选择。
        (1) 要么是updateMap去更新图。
        (2) 要么就重新命名一个新的 mapid 如map2
        */
        if (res.error) {
            message.error(res.error)
        }
        // 获取地图的范围
        let mapExtent = vjmap.GeoBounds.fromString(res.bounds);
        // 建立坐标系
        let prj = new vjmap.GeoProjection(mapExtent);
        console.log('res',res);
        // // 新建地图对象
        // console.log('map',new vjmap())
        let map = new vjmap.Map({
            container: 'map-container', // container ID
            style: svc.rasterStyle(), // 样式，这里是栅格样式,// 矢量瓦片样式
            center: prj.toLngLat(mapExtent.center()), // 中心点
            zoom: 2,
            renderWorldCopies: false
        });
       
        // 地图关联服务对象和坐标系
        map.attach(svc, prj);
        // 使地图全部可见
        map.fitMapBounds();
        await map.onLoad();
        this.cadLoading = false;
    },
    //获取详情
    getDetail() {
      this.$http.get('project/detail', {
        id: this.Id
      }).then(res => {
        if (res.code == 200) {
			if(res.data.status > 3){
				if(res.data.engineerInfo.id != this.userInfo.id){
					this.$mHelper.to('/'); 
				}
			}
			
          res.data.totalInvestment = initNumberValue(res.data.totalInvestment)
          res.data.totalScale = initNumberValue(res.data.totalScale)
          res.data.totalArea = initNumberValue(res.data.totalArea)
          res.data.earningsAmount = initNumberValue(res.data.earningsAmount)
          res.data.depositAmount = initNumberValue(res.data.depositAmount)

          if(res.data.files && isArrObj(res.data.files)) {
            res.data.files.forEach(item => {
              item.loadStatus = false
            });
          }
          if(res.data.supplyFiles && isArrObj(res.data.supplyFiles)) {
            res.data.supplyFiles.forEach(item => {
              item.loadStatus = false
            });
          }
          
          this.projectInfo = res.data;
		  this.getMesList();
		   this.bmListGet();
        }
      })
    },
	
	//报名有效期
	limitTimeFilter(jdTime) {
		if(jdTime == null){
			jdTime = 0;
		}
		let time = new Date().getTime();
		jdTime = Date.parse(jdTime);
		
		let val =  ( jdTime + 45*24*60*60*1000 ) - time;
		//let val =  ( jdTime + 3*24*60*60*1000 ) - time;
		
		if(val <= 0){
			return 0;
		}else{
			var days = Math.floor(val / (1000 * 60 * 60 * 24));
			var lefth = Math.floor((val / (1000 * 60 * 60)) % 24);
			var leftm = Math.floor((val / (1000 * 60)) % 60);
			var lefts = Math.floor((val / 1000) % 60);
			return "你已提交成果且通过审核，可在"+ days + "天" +lefth + "时" + leftm + "分" +"后可以申请阶段性结算";
			//return "报名还剩"+ days + "天" +lefth + "时" + leftm + "分" + lefts + "秒";
		}
		
	},
	
	//报名人员
	bmListGet() {
	  this.$http.get('director/bmList', {
	    id: this.Id,
	    pageNum: 1,
	    pageSize: 999,
		status: 2,
	  }).then(res => {
	    if (res.code == 200) {
			res.data.list.forEach((e)=>{
				e.rateValue = e.synthesize/20 || 0;
			})  
	       this.bmList = res.data.list
	    }
	  })
	},
	
	handleCommand(e){
		if(e.type==1){
			//申请延期
			this.timeShowApply = true;
		}else if(e.type==2){
			//下载资料
			this.downWenjian();
		}
		else if(e.type==3){
			//申请结束项目
			this.cancalgxmCloseShow = true;
		}
		else if(e.type==4){
			//阶段结款
			this.jieduanSubmit();
		}
	},
	
	//延期申请
	submitYanEvaluate(){
		if (this.formYanApplyData.postponeTime=='') {
		  this.$message.error('请选择延期时间')
		  return;
		}
		if (this.formYanApplyData.cause=='') {
		  this.$message.error('请填写延期原因')
		  return;
		}
		this.loading = true;
		this.$http.post('/release/postpone', {
			isWho: 1,	//0-客户，1-造价师，3-项目经理
			postponeTime: this.formYanApplyData.postponeTime,
			cause: this.formYanApplyData.cause,
			projectId: this.Id,
		}).then(res => {
		  this.loading = false
		  if (res.code == 200) {
		    this.timeShowApply = false;
			this.getDetail();
		    const h1 = this.$createElement;
		    this.$msgbox({
		      title: '提示',
		      message: h1('p', {
		        style: 'margin-bottom: 20px'
		      }, [
		        h1('span', null, '您已成功申请项目延期！ '),
		        h1('br', {
		          style: 'color: red'
		        }, ''),
		        h1('span', null, '请等待项目经理和客户审核！ '),
		      ]),
		      center: true,
		      showCancelButton: true,
		      showConfirmButton: false,
		      cancelButtonText: '我知道了',
		    })
		  }
		}).catch(err => {
		  this.loading = false
		})
	},
	
      //驳回详情弹窗
      bhIntro(content) {
        // console.log(content)
          this.content = content
          this.bhShow = true
      },
    //下载补充材料
    downSupplyFile(item,index) {
      if (this.userInfo.identity != 3 && this.projectInfo.fgJurisdiction != 1) {
        this.$message.error('暂无权限')
        return;
      }
      try {
        this.projectInfo.supplyFiles[index].loadStatus = true;
      } catch (error) {}
      this.$http.downloadFile('upload/download', {
        fileId: item.id,
        projectId: this.Id
      }).then(res => {
        console.log(res)
        try {
          this.projectInfo.supplyFiles[index].loadStatus = false;
        } catch (error) {}
        if (res.data.type === 'application/force-download') {
          const link = document.createElement('a'); // 创建a标签
          let blob = new Blob([res.data]);
          link.style.display = 'none';
          link.href = URL.createObjectURL(blob); // 创建下载的链接
          link.setAttribute('download', item.name); // 给下载后的文件命名
          document.body.appendChild(link);
          link.click(); // 点击下载
          document.body.removeChild(link); //  完成移除元素
          window.URL.revokeObjectURL(link.href); // 释放blob对象
          this.materialsShow = false;
        } else {
          // 将blob文件流转换成json
          let _that = this;
          const reader = new FileReader()
          reader.onload = function (event) {
            let data = JSON.parse(event.target.result);
            if (data && data.hasOwnProperty('msg')) {
              _that.$message.error(data.msg);
            }
          }
          reader.readAsText(res.data)
        }
      }).catch(err => {
        console.log(err)
        try {
          this.projectInfo.supplyFiles[index].loadStatus = false;
        } catch (error) {}
        //console.log(err)
        let _that = this;
        if (err.data.type === "application/json") {
          const reader = new FileReader()
          reader.onload = function (event) {
            let data = JSON.parse(event.target.result);
            console.log(data)
            if (data && data.hasOwnProperty('msg')) {
              _that.$message.error(data.msg);
            }
          }
          reader.readAsText(err.data)
        }
      })
    },
    //下载附件资料
    downAttachmentFile(item,index) {
      if (this.userInfo.identity != 3 && this.projectInfo.fgJurisdiction != 1) {
        this.$message.error('暂无权限')
        return;
      }
      try {
        this.projectInfo.files[index].loadStatus = true;
      } catch (error) {
        
      }
      this.$http.downloadFile('upload/download', {
        fileId: item.id,
        projectId: this.Id
      }).then(res => {
        console.log(res)
        try {
          this.projectInfo.files[index].loadStatus = false;
        } catch (error) {
          
        }
        if (res.data.type === 'application/force-download') {
          const link = document.createElement('a'); // 创建a标签
          let blob = new Blob([res.data]);
          link.style.display = 'none';
          link.href = URL.createObjectURL(blob); // 创建下载的链接
          link.setAttribute('download', item.name); // 给下载后的文件命名
          document.body.appendChild(link);
          link.click(); // 点击下载
          document.body.removeChild(link); //  完成移除元素
          window.URL.revokeObjectURL(link.href); // 释放blob对象
          this.materialsShow = false;
          
        } else {
          // 将blob文件流转换成json
          let _that = this;
          const reader = new FileReader()
          reader.onload = function (event) {
            let data = JSON.parse(event.target.result);
            if (data && data.hasOwnProperty('msg')) {
              _that.$message.error(data.msg);
            }
          }
          reader.readAsText(res.data)
        }
      }).catch(err => {
        console.log(err)
        //console.log(err)
        let _that = this;
        if (err.data.type === "application/json") {
          const reader = new FileReader()
          reader.onload = function (event) {
            let data = JSON.parse(event.target.result);
            console.log(data)
            if (data && data.hasOwnProperty('msg')) {
              _that.$message.error(data.msg);
            }
          }
          reader.readAsText(err.data)
        }
        try {
          this.projectInfo.files[index].loadStatus = false;
        } catch (error) {
          
        }
      })
    },
    downloadFile(item) {
      if (this.userInfo.identity != 3 && this.projectInfo.fgJurisdiction != 1) {
        this.$message.error('暂无权限')
        return;
      }
      this.loading = true
      this.$http.downloadFile('upload/download', {
        fileId: item.id,
        projectId: this.Id
      }).then(res => {
        this.loading = false
        var reader = new FileReader();
        let that = this
        this.loading = false
        reader.onloadend = function (event) {
          //因为返回的报错格式是字符串，手动转换成对象，转换成功表示请求失败
          //转换失败就意味着你拿到的result是文件流，那么直接手动下载就好
          try {
            let data = JSON.parse(event.target.result)
            // that.$message.error("请点击右上角登录后重新下载");
            if (!this.$store.state.token) {
              that.$message.error("请登录后重新下载");
            } else {
              console.log(event);
            }

          } catch (err) {
            const link = document.createElement('a'); // 创建a标签
            let blob = new Blob([res.data]);
            link.style.display = 'none';
            link.href = URL.createObjectURL(blob); // 创建下载的链接
            link.setAttribute('download', item.name); // 给下载后的文件命名
            document.body.appendChild(link);
            link.click(); // 点击下载
            document.body.removeChild(link); //  完成移除元素
            window.URL.revokeObjectURL(link.href); // 释放blob对象
            this.loading = false
            that.materialsShow = false;
          }
        };
        reader.readAsText(res.data);
      }).catch(err => {
        this.loading = false
      })

    },
    
	//下载材料
	downWenjian() {
	  let url = this.$store.state.baseURL + '/partake/batchDownload?id=' + this.Id;
	  this.loading = true
	  // this.materialsShow = false;
	  this.$http.downloadFile('partake/batchDownload', {
	    id: this.Id
	  }).then(res => {
	    var reader = new FileReader();
	    let that = this
	    this.loading = false
	    reader.onloadend = function(event) {
	      //因为返回的报错格式是字符串，手动转换成对象，转换成功表示请求失败
	      //转换失败就意味着你拿到的result是文件流，那么直接手动下载就好
	      try {
	        let data = JSON.parse(event.target.result)
	        that.$message.error(res.msg);
	
	      } catch (err) {
	        const link = document.createElement('a'); // 创建a标签
	        let blob = new Blob([res.data]);
	        link.style.display = 'none';
	        link.href = URL.createObjectURL(blob); // 创建下载的链接
	        link.setAttribute('download', '项目资料' + '.' + 'zip'); // 给下载后的文件命名
	        document.body.appendChild(link);
	        link.click(); // 点击下载
	        document.body.removeChild(link); //  完成移除元素
	        window.URL.revokeObjectURL(link.href); // 释放blob对象
	        that.loading = false
	        that.materialsShow = false;
	      }
	    };
	    reader.readAsText(res.data);
	  }).catch(err => {
	    this.loading = false
	  })
	},
	
	saveMsgTap() {
      if (!this.textarea) {
        this.$message.error('请输入留言内容')
        return
      }
      this.$http.post('project/saveMsg', {
		type: this.radio,
        projectId: this.Id,
        content: this.textarea
      }).then(res => {
        if (res.code == 200) {
          this.$message.success('留言成功')
          this.textarea = ''
          this.getMesList()
        }
      })
    },
    pageChange(e) {
      this.queryParams.page = e
      this.getMesList()
    },
    getMesList() {
      this.$http.post('project/listMsg', {
        projectId: this.Id,
        pageNum: this.queryParams.page,
        pageSize: this.queryParams.limit
      }).then(res => {
        if (res.code == 200) {
          this.queryParams.totalPage = res.data.pages
          this.msgList = res.data.list
        }
      })
    },
    //下载材料
    downWenjianCg() {
      this.loading = true
      this.$http.downloadFile('release/batchDownload', {
        id: this.Id
      }).then(res => {
        var reader = new FileReader();
        let that = this
        this.loading = false
        reader.onloadend = function (event) {
          //因为返回的报错格式是字符串，手动转换成对象，转换成功表示请求失败
          //转换失败就意味着你拿到的result是文件流，那么直接手动下载就好
          console.log(res)
          try {
            let data = JSON.parse(event.target.result)
            that.$message.error(res.data.msg);
          } catch (err) {
            const link = document.createElement('a'); // 创建a标签
            let blob = new Blob([res.data]);
            link.style.display = 'none';
            link.href = URL.createObjectURL(blob); // 创建下载的链接
            link.setAttribute('download', '成果文件' + '.' + 'zip'); // 给下载后的文件命名
            document.body.appendChild(link);
            link.click(); // 点击下载
            document.body.removeChild(link); //  完成移除元素
            window.URL.revokeObjectURL(link.href); // 释放blob对象
            that.materialsShow = false;
          }
        };
        reader.readAsText(res.data);
      }).catch(err => {
        this.loading = false
      })
    },
	
	getLastFileTime(type) {
	  var _time = "";
	  try {
	    if (type==1) {
	      var _index = this.projectInfo.engineerInfo.engineerFiles.length - 1;
	      if (this.projectInfo.engineerInfo.engineerFiles[_index]) {
	        _time = this.projectInfo.engineerInfo.engineerFiles[_index].createDate;
	      }
	    } else {
	      var _index = this.projectInfo.projectFiles.length - 1;
	      if (this.projectInfo.projectFiles[_index]) {
	        _time = this.projectInfo.projectFiles[_index].createDate;
	      }
	    }
	  } catch (error) {}
	  return _time;
	},
	
	//确认申请弹窗，阅读协议确认申请
	applyBtnTap() {
	  this.loading = true
	  this.$http.post('partake/applySave', {
	    id: this.Id
	  }).then(res => {
	    this.loading = false
	    if (res.code == 200) {
	      this.applyShow = false;
	      //this.$refs.alert.alertToast('您已申请本项目，等待项目经理开放资料查阅权限', '提示', '#000')
	      this.getDetail();
	    }
	  }).catch(err => {
	    this.loading = false
	  })
	
	},
	
	//拒绝申请
	refuseApplyBtn() {
	  //请求成功后弹窗
	  const h1 = this.$createElement;
	  this.$msgbox({
	    title: '提示',
	    message: h1('p', {
	      style: 'margin-bottom: 20px'
	    }, [
	      h1('span', {
	        style: 'color: red'
	      }, '拒绝申请后将无法再次申请此项目 '),
	      h1('br', {
	        style: 'color: red'
	      }, ''),
	      h1('span', {
	        style: 'color: red'
	      }, '您确定要拒绝申请吗？ '),
	    ]),
	    center: true,
	    showCancelButton: true,
	    confirmButtonText: '确认拒绝',
	    cancelButtonText: '我再想想',
	    beforeClose: (action, instance, done) => {
	      if (action === 'confirm') {
	        let datas = {
	          id: this.Id,
	        }
	        console.log('----------');
	        console.log(datas);
	        instance.confirmButtonLoading = true;
	        this.$http.post('partake/reject', datas).then(res => {
	          if (res.code == 200) {
	            done();
	            setTimeout(() => {
	              instance.confirmButtonLoading = false;
	            }, 300);
	          }
	        }).catch(err => {
	          instance.confirmButtonLoading = false;
	        })
	      } else {
	        done(false);
	      }
	    }
	  }).then(action => {
	    if (action === 'confirm') {
	      this.getDetail();
		  this.$message.success('您已成功拒绝此项目！')
	    }
	  });
	
	},
	
	//取消接单
	cancelBtns(id) {
	  //请求成功后弹窗
	  const h2 = this.$createElement;
	  this.$msgbox({
	    title: '提示',
	    message: h2('p', {
	      style: 'margin-bottom: 20px'
	    }, [
	      h2('span', {
	        style: 'color: red'
	      }, '取消后将无法再次申请此项目'),
	      h2('br', null, ''),
	      h2('span', {
	        style: 'color: red'
	      }, '您确定要取消吗？'),
	    ]),
	    center: true,
	    showCancelButton: true,
	    confirmButtonText: '确认取消',
	    cancelButtonText: '我再想想',
	    beforeClose: (action, instance, done) => {
	      if (action === 'confirm') {
	        instance.confirmButtonLoading = true;
	        instance.confirmButtonText = '执行中...';
	        this.$http.post('partake/reject', {
	          id: id,
	        }).then(res => {
	          if (res.code == 200) {
	            done();
	            setTimeout(() => {
	              instance.confirmButtonLoading = false;
	            }, 300);
	          }
	        })
	      } else {
	        done();
	      }
	    }
	  }).then(action => {
	    this.getDetail();
		this.$message.success('取消成功')
	  });
	
	},
	
	//上传超出限制
	  handleExceed() {
	    this.$message.warning('最多上传一个文件')
	  },
	  onProgress(event, file, fileList) {
	  this.onPrepareFile = true;
	},
	onError(event, file, fileList) {
	  this.onPrepareFile = false;
	  var eFile = file.name||'当前文件';
	  this.$message.error(`${eFile}上传失败，请重试`)
	},
	
	//成果文件上传回调
	cgSuccess(e, files) {
	  // console.log(e);
	  // console.log(files);
	  if (e.code == 200) {
	    this.files.name = files.name
	    this.files.url = e.data
	  } else {
	    this.$message.error(e.msg)
	  }
	},
	//成果文件删除回调
	cgRemove() {
	  this.files = {}
	},
	
	//上传结果请求
	suppleUpdata() {
	  if (!this.files.name || !this.files.url) {
	    this.$message.error('请上传文件后提交')
	    return
	  }
	  this.loading = true
	  this.$http.post('partake/submitResult', {
	    url: this.files.url,
	    name: this.files.name,
	    id: this.Id
	  }).then(res => {
	    this.loading = false
	    if (res.code == 200) {
		  this.$message.success('您已成功提交工作成果')
	      this.supplementShow = false
	      this.getDetail();
	    }
	  }).catch(err => {
	    this.loading = false
	  })
	
	},
	
	submitChengguo() {
	  this.files = {};
	  this.supplementShow = true;
	  // this.$nextTick(() => {
	  //   this.$refs['files'].clearFiles();
	  // })
	},
	
	receiptSuccess(e, file) {
	  if (file.response.code == 200) {
	    this.bankFrom.receipt = file.response.data;
	  } else {
	    this.$message.success(file.response.msg);
	  }
	},
	//删除凭证
	handleRemove() {
	  this.bankFrom.receipt = ''
	},
	
	handleClose(done) {
	  this.$confirm('当前页面未保存，是否退出？', '提示', {
	      center: true,
	    }).then(e => {
	      this.credentialsShow = false;
	      done();
	    }).catch(err => {});
	},
	
	//导出质控记录
	exportZhikong(){
		//window.location.href = this.$store.state.callback + "/api/director/export?id="+this.Id;
		
		//return;
		this.$http.get("director/export", {
		    id: this.Id,
		  }).then((res) => {
			   //console.log(,'11111111111111111111');return;
		    if (res.code == 200) {
				window.location.href = process.env.VUE_APP_BASE_API_DOWN + "/common/download?fileName=" + encodeURI(res.msg) + "&delete=" + true;
		    } else {
				
		    }
		}).catch((err) => {
		    
		});
	},
	isJsonString(str) {
	    try {
	        JSON.parse(str);
	        return true;
	    } catch (e) {
	        return false;
	    }
	},
	remarkLook(item){
		if(this.isJsonString(item)==true){
			let aa = JSON.parse(item);
			
			let arr = [];
			// aa.forEach((item) => {
			//   item.items.forEach((sub, index) => {
			//     arr.push(sub);
			//   });
			// });
			// return aa[0].items[0].title + ' '+ aa[0].items[0].score;
			return aa;
			
		}else{
			return item;
		}
	},
	
	//阶段性结款
	jieduanSubmit(){
		const h2 = this.$createElement;
		this.$confirm("", {
		  title: "提示",
		  message: h2(
		    "p",{ style: "margin-bottom: 20px", },
		    [
		      h2(
		        "span", { style: "color: red",}, "是否确认申请阶段性结款？"
		      ),
		      h2("br", null, ""),
		      h2(
		        "span", { style: "color: red",}, ""
		      ),
		    ]
		  ),
		  center: true,
		  showCancelButton: true,
		  confirmButtonText: "确认申请",
		  cancelButtonText: "我再想想",
		}).then(() => {
		    var durTip = this.$message({
		      duration: 1000,
		      message: h2("p", null, [
		        h2("span", { style: "color: #4077DD" }, "处理中… "),
		      ]),
		    });
		    this.$http.get("release/stageAccount", {
		        id: this.Id,
		      }).then((res) => {
		        durTip.close();
		        if (res.code == 200) {
		          this.getDetail();
		           this.$message.success('申请成功')
		        }
		      }).catch((err) => {
		        durTip.close();
		      });
		  }).catch((action) => {
		      
		  });
	},
	//申请结束项目
	submitCacnlEvaluate(){
		
		if(this.cacalNotes=='' || this.cacalNotes==null){
			this.$message.error('请填写申请取消原因！');
			return;
		}
		
		const h2 = this.$createElement;
		this.$confirm("", {
		  title: "提示",
		  message: h2(
		    "p",{ style: "margin-bottom: 20px", },
		    [
		      h2(
		        "span", { style: "color: red",}, "是否确认申请取消项目"
		      ),
		      h2("br", null, ""),
		      h2(
		        "span", { style: "color: red",}, ""
		      ),
		    ]
		  ),
		  center: true,
		  showCancelButton: true,
		  confirmButtonText: "确认申请",
		  cancelButtonText: "我再想想",
		}).then(() => {
		    var durTip = this.$message({
		      duration: 1000,
		      message: h2("p", null, [
		        h2("span", { style: "color: #4077DD" }, "处理中… "),
		      ]),
		    });
		    this.$http.post("project/cancelOrder", {
		        projectId: this.Id,
		        type: 8,
		        applyCause: this.cacalNotes
		      }).then((res) => {
		        durTip.close();
		        if (res.code == 200) {
					this.cancalgxmCloseShow = false,
		          this.getDetail();
		           this.$message.success('申请成功')
		        }
		      }).catch((err) => {
		        durTip.close();
		      });
		  }).catch((action) => {
				 durTip.close();
		  });
	},
	
	bindCheckBox(value) {
	  if (this.paycheck.length > 1) {
	    this.paycheck.splice(0, 1)
	  }
	},
	//选择支付方式后点击支付
	payEnd() {
	  this.payTypeShow = false;
	  if (this.paycheck[0] == '1') {
	    this.payTypeShow = false
	    console.log('微信支付');
	    this.WeChatPay();
	    // this.$message.error('开发中...')
	  } else if (this.paycheck[0] == '2') {
	    this.payTypeShow = false
	    console.log('支付宝支付');
	    // this.$message.error('开发中...')
	    this.AlipayPay()
	  } else if (this.paycheck[0] == '3') {
	    console.log('线下转账');
	    this.transShow = true
	  }else if (this.paycheck[0] == "7") {
		console.log("余额支付");
		this.inputPassword = true;
	  }
	
	},
	//弹窗关闭
	wxPayPuopClose() {
	  console.log('关闭微信支付弹窗');
	  clearInterval(this.payTimeer);
	  done();
	},
	WeChatPay() {
	  this.loading = true
	  this.$http.post('pay/recharge', {
	    payMethod: 1,
	    type: this.projectInfo.isBjJe == 2 ? 3 : 2,
	    sourceId: this.projectInfo.id
	  }).then(res => {
	    if (res.code == 200) {
	      this.WechatShow = true;
	      this.$nextTick(() => {
	        document.getElementById("qrcode").innerHTML = "";
	        var qrcode = new QRCode(this.$refs.qrCodeUrl, {
	          text: res.data.codeUrl, // 需要转换为二维码的内容
	          width: 220,
	          height: 220,
	          colorDark: '#000000',
	          colorLight: '#ffffff',
	          correctLevel: QRCode.CorrectLevel.H
	        })
	      })
	      this.paySatausPoll(res.sn)
	    }
	    this.loading = false;
	  }).catch(err => {
	    this.loading = false;
	  })
	},
	paySatausPoll(payId) {
	  this.payTimeer = setInterval(() => {
	    if (payId) {
	      this.$http.get('payOrder/getOrder',{
	        sn: payId
	      }).then(e => {
	        if (e.code == 200) {
	          if (e.data.status == 2) {
	            this.WechatShow = false;
	            var tipMsg = "支付成功！";
	            if(e.data.type==1) {
	              //客户付项目款
	              tipMsg = '恭喜您支付成功！该项目已进入【进行中的项目】平台已开始工作';
	            }
	            // if(e.data.type==6) {
	            //   tipMsg = '您已补缴成功！';//客户补缴尾款
	            // }
	            //this.$refs.alert.alertToast(tipMsg, '提示', '#000')
				this.$message.success(tipMsg);
	            this.getDetail();
	          }
	        }
	      })
	    }
	  }, 2000);
	},
	//支付宝支付
	AlipayPay() {
	  const loading = this.$loading({
	    lock: true,
	    text: '加载中...',
	    spinner: 'el-icon-loading',
	    background: 'rgba(255, 255, 255, 0.8)'
	  });
	  this.$http.post('pay/recharge', {
	    payMethod: 2,
	    type: this.projectInfo.isBjJe == 2 ? 3 : 2,
	    sourceId: this.projectInfo.id
	  }).then(res => {
	    if (res.code == 200) {
	      this.alipayWap = res.data
	      console.log(this.alipayWap);
	      this.$nextTick(() => {
	        this.$refs.alipayWap.children[0].submit();
	      })
	    }
	    this.loading = false;
	  }).catch(err => {
	    this.loading = false;
	  })
	},
	//显示上传凭证弹窗
	upPingZheng() {
	  this.transShow = false
	  this.credentialsShow = true
	},
	upataPingzheng() { //提交凭证
	  this.$refs['xianxiaPays'].validate((valid) => {
	    if (valid) {
	      this.loading = true;
	      this.bankFrom.payMethod = 4;
	      //this.projectInfo.isBjJe  保质金1-已补缴，2-待补缴
	      //this.bankFrom.type  2-工程师支付定金，3-工程师补缴定金（质保金）
	      this.bankFrom.type = this.projectInfo.isBjJe == 2 ? 3 : 2;
	      this.bankFrom.sourceId = this.projectInfo.id;
	      
	      this.$http.post('pay/recharge', this.bankFrom).then(res => {
	        if (res.code == 200) {
	          this.getDetail();
	          this.credentialsShow = false
	          this.credSuccessShow = true
	        }
	        this.loading = false
	      }).catch(err => {
	        this.loading = false
	      })
	    } else {}
	  });
	},
	
	toSetPassword() {
	  this.$router.push({
	    path: "/SecuritySet"
	  })
	},
	payBalance(){
		if(this.withdrawPassword==''){
			this.$message.warning("请输入支付密码！");
			return;
		}
		this.loading = true;
		this.$http.post("pay/recharge", {
		    payMethod: 7,
		    type: this.projectInfo.isBjJe == 2 ? 3 : 2,
		    sourceId: this.projectInfo.id,
			walletPwd: this.withdrawPassword,
		  }).then((res) => {
		    if (res.code == 200) {
			   this.$message.success("支付成功！");
			   this.inputPassword = false,
		       this.getDetail();
		    }
		    this.loading = false;
		  }).catch((err) => {
		    this.loading = false;
		  });
	},
	
	
  },
}
</script>

<style lang="scss" scoped>
/*滚动条样式*/
::v-deep ::-webkit-scrollbar {
  width: 10px;
  /*height: 4px;*/
}

::v-deep ::-webkit-scrollbar-thumb {
  border-radius: 2px;
  -webkit-box-shadow: inset 0 0 5px rgba(200, 200, 200, 0.2);
  background: #185BA1;
}

::v-deep ::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  border-radius: 0;
  background: rgba(0, 0, 0, 0.1);
}


::v-deep  .el-icon-star-on {
		color: #FFB420;
	}
.on-sign-up{
	background: #1677FF;
}
.on-conduct{
	background: #FFC726;
}
.on-completed{
	background: #E8E8E8;
}
.on-success{
	background: #10c550;
}
.lines-title{
	.export{
		font-weight: 400;
		font-size: 14px;
		color: #3B77FF;
		margin-left: 16px;
	}
   .hengxian {
	   width: calc(100% - 86px);
   }
}
.lines-title2{
   .hengxian {
	   width: calc(100% - 186px);
   }
}
	.topNavs{
		display: flex;
		justify-content: space-between;
		align-items: center;
		.topMeg{
			display: flex;
			align-items: center;
			img{
				width: 21px;
				height: 21px;
			}
			span{
				font-family: PingFangSC, PingFang SC;
				font-weight: 400;
				font-size: 16px;
				color: #FF4D4F;
				line-height: 21px;
				margin-left: 8px;
			}
		}
	}
	.hall-top{
		margin-top: 33px;
		.topSn{
			font-family: PingFangSC, PingFang SC;
			font-weight: 400;
			font-size: 16px;
			color: rgba(0,0,0,0.85);
			line-height: 21px;
			display: flex;
			justify-content: space-between;
			align-items: center;
			
		}
		.topTip{
			background: #FFFBE6;
			border-radius: 8px;
			border: 1px solid #FFF1B8;
			padding: 9px 13px;
			margin-top: 17px;
			.topTitle{
				display: flex;
				justify-content: space-between;
				.tipLeft{
					display: flex;
					align-items: center;
					img{
						width: 14px;
						height: 14px;
					}
					span{
						font-family: PingFangSC, PingFang SC;
						font-weight: 400;
						font-size: 14px;
						color: rgba(0,0,0,0.88);
						line-height: 22px;
						margin-left: 8px;
						white-space: normal;
						word-break: break-all;
					}
				}
				.tipRight{
					font-family: PingFangSC, PingFang SC;
					font-weight: 400;
					font-size: 14px;
					color: #1677FF;
					line-height: 22px;
				}
			}
			.tipInfo{
				font-family: PingFangSC, PingFang SC;
				font-weight: 500;
				font-size: 14px;
				color: rgba(0,0,0,0.88);
				line-height: 22px;
				margin-top: 8px;
			}
		}
		.top-content{
			display: flex;
			align-items: center;
			justify-content: space-between;
			.left{
				width: calc(100% - 400px);
				.topNames{
					font-family: PingFangSC, PingFang SC;
					font-weight: 500;
					font-size: 18px;
					color: rgba(0,0,0,0.85);
					line-height: 21px;
					margin-top: 17px;
					display: flex;
					align-items: center;
					.topState{
						padding: 0.5px 4px;
						border-radius: 4px;
						font-size: 10px;
						color: #FFFFFF;
						text-align: center;
						margin-right: 8px;
					}
				}
				.topTagsBox{
					margin-top: 16px;
					.tags{
						font-family: PingFangSC, PingFang SC;
						font-weight: 400;
						font-size: 14px;
						color: #4077DD;
						line-height: 21px;
						text-align: left;
						font-style: normal;
						margin-right: 16px;
						position: relative;
						&:before{
							content: '';
							width: 1px;
							height: 12px;
							background-color: #4077DD;
							position: absolute;
							top: 5px;
							right: -8px;
						}
						&:last-child{
							&:before{
								display: none;
							}
						}
					}
				}
				.topPrice{
					font-family: PingFangSC, PingFang SC;
					font-weight: 600;
					font-size: 18px;
					color: #FF4D4F;
					line-height: 36px;
					text-align: left;
					font-style: normal;
					margin-top: 24px;
				}
				.topTime{
					font-family: PingFangSC, PingFang SC;
					font-weight: 400;
					font-size: 16px;
					color: rgba(0,0,0,0.85);
					line-height: 21px;
					text-align: left;
					font-style: normal;
					margin-top: 25px;
					.yq-time{
						background: #FFFFFF;
						border-radius: 4px;
						border: 1px solid #4077DD;
						font-family: PingFangSC, PingFang SC;
						font-weight: 400;
						font-size: 12px;
						color: #4077DD;
						line-height: 21px;
						padding: 0 4px;
						margin-left: 16px;
					}
				}
				
			}
			.right{
				width: 338px;
				display: flex;
				justify-content: flex-end;
				.right-type-box1{
					display: flex;
					flex-flow: column;
					align-items: flex-end;
					.type-btn{
						width: 230px;
						height: 60px;
						background: #FF4D4F;
						border-radius: 8px;
						display: flex;
						justify-content: center;
						align-items: center;
						img{
							width: 20px;
							height: 20px;
						}
						span{
							font-family: PingFangSC, PingFang SC;
							font-weight: 400;
							font-size: 16px;
							color: #FFFFFF;
							line-height: 24px;
							text-align: center;
							font-style: normal;
							margin-left: 8px;
						}
					}
					.type-number{
						width: 230px;
						font-family: PingFangSC, PingFang SC;
						font-weight: 400;
						font-size: 16px;
						color: #4077DD;
						line-height: 21px;
						text-align: center;
						font-style: normal;
						margin-top: 12px;
					}
				}
				.right-rate-box1{
					display: flex;
					justify-content: flex-end;
					.rate-list{
						display: flex;
						flex-flow: column;
						align-items: flex-end;
						.item-rate{
							display: flex;
							align-items: center;
							margin-bottom: 12px;
							.item-title{
								width: 98px;
								font-family: PingFangSC, PingFang SC;
								font-weight: 400;
								font-size: 14px;
								color: rgba(0,0,0,0.85);
								line-height: 39px;
								text-align: right;
							}
							::v-deep .el-rate{
								height: auto;
								.el-rate__icon {
								  font-size: 30px !important;
								}
							}
						}
						.type-btn{
							width: 230px;
							height: 60px;
							background: #4077DD;
							border-radius: 8px;
							font-family: PingFangSC, PingFang SC;
							font-weight: 400;
							font-size: 16px;
							color: #FFFFFF;
							line-height: 24px;
							text-align: center;
							font-style: normal;
							display: flex;
							justify-content: center;
							align-items: center;
							position: relative;
							margin-top: 4px;
							.top-left-tabs{
								width: 75px;
								height: 32.5px;
								position: absolute;
								top: 0;
								left: 0;
							}
						}
					}
				}
				
				.right-type-box2{
					display: flex;
					justify-content: flex-end;
					.right-btn{
						width: 120px;
						height: 60px;
						border-radius: 8px;
						border: 1px solid #4077DD;
						font-family: PingFangSC, PingFang SC;
						font-weight: 400;
						font-size: 16px;
						color: #4077DD;
						line-height: 24px;
						display: flex;
						justify-content: center;
						align-items: center;
						margin-right: 8px;
					}
					::v-deep .el-dropdown{
						height: 60px;
					}
					.right-two-box{
						.type-btn{
							width: 230px;
							height: 60px;
							background: #4077DD;
							border-radius: 8px;
							font-family: PingFangSC, PingFang SC;
							font-weight: 400;
							font-size: 16px;
							color: #FFFFFF;
							line-height: 24px;
							text-align: center;
							font-style: normal;
							display: flex;
							justify-content: center;
							align-items: center;
							position: relative;
							.top-left-tabs{
								width: 75px;
								height: 32.5px;
								position: absolute;
								top: 0;
								left: 0;
							}
						}
						.type-default{
							background-color: #e7e7e7;
							border-color: #e7e7e7;
						}
						.type-number{
							margin-top: 12px;
							width: 230px;
							display: flex;
							align-items: center;
							justify-content: center;
							img{
								width: 20px;
								height: 20px;
							}
							span{
								font-family: PingFangSC, PingFang SC;
								font-weight: 400;
								font-size: 16px;
								color: #FF4D4F;
								line-height: 21px;
								text-align: left;
								font-style: normal;
								margin-left: 8px;
							}
						}
						.type-back{
							font-family: PingFangSC, PingFang SC;
							font-weight: 400;
							font-size: 16px;
							color: rgba(0,0,0,0.45);
							line-height: 21px;
							text-align: left;
							font-style: normal;
							text-decoration-line: underline;
						}
					}
				}
				
			}
		}
		
	}
	.hall-box{
		display: flex;
		justify-content: space-between;
		margin-top: 64px;
		margin-bottom: 32px;
		.left{
			width: calc(100% - 346px);
			.left-box-1{
				.left-title{
					font-family: PingFangSC, PingFang SC;
					font-weight: 400;
					font-size: 16px;
					color: rgba(0,0,0,0.45);
					line-height: 21px;
					text-align: left;
					font-style: normal;
					margin-top: 24px;
				}
				.btLines{
					margin-top: 14px;
					.tag-title{
						font-family: PingFangSC, PingFang SC;
						font-weight: 400;
						font-size: 16px;
						color: rgba(0,0,0,0.85);
						line-height: 21px;
						text-align: left;
						font-style: normal;
					}
					::v-deep .el-tag{
						height: auto;
						padding: 3.34px 7.34px;
						background: #F5F7FA;
						border-radius: 4px;
						font-family: PingFangSC, PingFang SC;
						font-weight: 400;
						font-size: 14px;
						color: #555C70;
						line-height: 20px;
						text-align: left;
						font-style: normal;
						margin-bottom: 10px;
						margin-right: 8px;
					}
				}
				.miaoshu{
					font-family: PingFangSC, PingFang SC;
					font-weight: 400;
					font-size: 16px;
					color: rgba(0,0,0,0.85);
					line-height: 21px;
					text-align: left;
					font-style: normal;
					margin-top: 12px;
				}
			}
			.left-box-2{
				.left-list{
					margin-top: 24px;
					.item{
						img{
							width: 72px;
							height: 68px;
						}
						.item-title{
							width: 96px;
							height: 21px;
							font-family: PingFangSC, PingFang SC;
							font-weight: 400;
							font-size: 16px;
							color: rgba(0,0,0,0.85);
							line-height: 21px;
							text-align: left;
							font-style: normal;
							margin-top: 12px;
						}
					}
				}
			}
			.left-box-3{
				.item-invites{
					justify-content: space-between;
					font-family: PingFangSC, PingFang SC;
					font-weight: 400;
					color: rgba(0,0,0,0.85);
					margin-top: 24px;
					.left{
						width: calc(100% - 0px);
						.user-invites{
							margin-left: 36px;
							.invits-top{
								.username{
									font-size: 16px;
									line-height: 21px;
								}
								.userTag{
									padding: 0px 3.34px;
									border-radius: 4px;
									border: 1px solid #4077DD;
									font-size: 12px;
									color: #4077DD;
									line-height: 21px;
									margin-left: 8px;
								}
								::v-deep .el-rate{
									margin-left: 24px;
								}
							}
							.invites-sn{
								font-size: 14px;
								margin-top: 12px;
							}
							.invites-tags{
								margin-top: 6px;
								line-height: 30px;
								span{
									width: 89px;
									height: 22px;
									background: #F5F7FA;
									border-radius: 4px;
									font-size: 12px;
									color: #555C70;
									line-height: 20px;
									margin-right: 8px;
									padding: 3px 8px;
								}
							}
							.invites-fen{
								margin-top: 12px;
								.invites_lines{
									margin-right: 24px;
									.numbers{
										font-size: 14px;
									}
									.text{
										font-size: 12px;
									}
								}
							}
						}
					}
					.right{
						.btn-default{
							height: 40px;
							border-radius: 6px;
							border: 1px solid #4077DD;
							background: #FFFFFF;
							font-family: PingFangSC, PingFang SC;
							font-weight: 400;
							font-size: 16px;
							color: #4077DD;
							line-height: 24px;
						}
						.btn-error{
							height: 40px;
							border-radius: 6px;
							border: 1px solid #FF4D4F;
							background: #FFFFFF;
							font-family: PingFangSC, PingFang SC;
							font-weight: 400;
							font-size: 16px;
							color: #FF4D4F;
							line-height: 24px;
						}
					}
				}
			}
			.left-box-4{
				min-height: 211px;
				background: #FFFFFF;
				border-radius: 8px;
				border: 1px solid #E8E8E8;
				.jd-item{
					.jd-top{
						height: 68px;
						background: #F5FAFF;
						border-radius: 8px 8px 0px 0px;
						border: 1px solid #E8E8E8;
						padding: 0 24px;
						display: flex;
						justify-content: space-between;
						align-items: center;
						span{
							font-family: PingFangSC, PingFang SC;
							font-weight: 500;
							font-size: 16px;
							color: rgba(0,0,0,0.85);
							line-height: 21px;
						}
					}
					.jd-cont{
						padding:24px;
						.cont-item{
							display: flex;
							.number{
								width: 24px;
								height: 24px;
								background: #1677FF;
								border-radius: 12px;
								font-family: PingFangSC, PingFang SC;
								font-weight: 400;
								font-size: 14px;
								color: #FFFFFF;
								line-height: 22px;
								display: flex;
								justify-content: center;
								align-items: center;
								position: relative;
							}
							.number2{
								background: rgba(0,0,0,0.06);
								color: rgba(0,0,0,0.45);
							}
							.number3{
								background: rgba(255,77,79,0.1);
								color: #FF4D4F;
							}
							.number4{
								background: #E6F4FF;
								color: #1677FF;
								&::after{
									box-sizing: content-box;
									content: "";
									border: 1px solid #1677FF;
									border-left: 0;
									border-top: 0;
									height: 7px;
									left: 10px;
									position: absolute;
									top: 7px;
									transform: rotate(45deg) scaleY(1);
									width: 3px;
									transition: transform .15s ease-in 50ms;
									transform-origin: center;
								}
							}
							
							
							.title{
								font-family: PingFangSC, PingFang SC;
								font-weight: 500;
								font-size: 16px;
								color: rgba(0,0,0,0.85);
								line-height: 24px;
								margin-left: 16px;
							}
							.info{
								font-family: PingFangSC, PingFang SC;
								font-weight: 400;
								font-size: 14px;
								color: rgba(0,0,0,0.85);
								line-height: 24px;
								margin-left: 16px;
							}
							.backColor{
								color: rgba(0,0,0,0.25);
							}
						}
						.shuxian{
							width: 1px;
							height: 36px;
							background: rgba(0,0,0,0.15);
							margin-left: 12px;
							margin-top: 4px;
							margin-bottom: 4px;
						}
					}
					
				}
			}
			
		}
		.right{
			.right-box-1{
				width: 300px;
				min-height: 241px;
				background: #FFFFFF;
				border-radius: 8px;
				border: 1px solid #E8E8E8;
				padding-bottom: 24px;
				.right-title{
					font-family: PingFangSC, PingFang SC;
					font-weight: 600;
					font-size: 16px;
					color: rgba(0,0,0,0.85);
					line-height: 21px;
					text-align: left;
					font-style: normal;
					margin-top: 24px;
					padding: 0 24px;
				}
				.right-user{
					display: flex;
					align-items: center;
					margin-top: 24px;
					padding: 0 24px;
					::v-deep .el-image{
						img{
							width: 42px;
							height: 42px;
							border-radius: 50%;
						}
					}
					.right-uinfo{
						margin-left: 16px;
						.name{
							font-family: PingFangSC, PingFang SC;
							font-weight: 400;
							font-size: 16px;
							color: rgba(0,0,0,0.85);
							line-height: 21px;
							text-align: left;
							font-style: normal;
							white-space: normal;
							word-break: break-all;
						}
						.rStatis{
							width: 64px;
							height: 28px;
							border-radius: 4px;
							margin-top: 4px;
							font-family: HYk1gj;
							font-size: 12px;
							color: #FFFFFF;
							line-height: 20px;
							display: flex;
							justify-content: center;
							align-items: center;
						}
						.right-wrz{
							background: linear-gradient(180deg, #e2e2e2 2%, #eceae9 100%);
							box-shadow: 0px 0px 4px 0px rgb(225 221 217 / 59%);
						}
						.right-grrz{
							background: linear-gradient( 180deg, #0D9AF6 0%, #267CFC 100%);
							box-shadow: 0px 0px 4px 0px rgba(43,104,255,0.59);
						}
						.right-qyrz{
							background: linear-gradient( 180deg, #F6A00D 2%, #FC8526 100%);
							box-shadow: 0px 0px 4px 0px rgba(255,140,43,0.59);
						}
					}
				}
				.right-phone{
					font-family: PingFangSC, PingFang SC;
					font-weight: 400;
					font-size: 16px;
					color: rgba(0,0,0,0.85);
					line-height: 21px;
					text-align: left;
					font-style: normal;
					margin-top: 24px;
					padding: 0 24px;
					white-space: normal;
					word-break: break-all;
				}
				.right-time{
					font-family: PingFangSC, PingFang SC;
					font-weight: 400;
					font-size: 16px;
					color: rgba(0,0,0,0.45);
					line-height: 21px;
					text-align: left;
					font-style: normal;
					margin-top: 24px;
					padding: 0 24px;
				}
			}
			.right-box-2{
				width: 300px;
				min-height: 156px;
				background: #FFFFFF;
				border-radius: 8px;
				border: 1px solid #E8E8E8;
				margin-top: 24px;
				padding-bottom: 24px;
			}
			.right-box-guo{
				width: 300px;
				height: 259px;
				background: #FFFFFF;
				border-radius: 8px;
				border: 1px solid #E8E8E8;
				margin-bottom: 24px;
				.top-guo{
					padding: 0 24px;
					justify-content: space-between;
					align-items: center;
					margin-top: 24px;
					.name{
						font-family: PingFangSC, PingFang SC;
						font-weight: 500;
						font-size: 16px;
						color: rgba(0,0,0,0.85);
						line-height: 21px;
						text-align: left;
						font-style: normal;
					}
					.jieguoBtn{
						font-family: PingFangSC, PingFang SC;
						font-weight: 400;
						font-size: 16px;
						color: #4077DD;
						line-height: 36px;
						text-align: right;
						font-style: normal;
					}
				}
				.times{
					padding: 0 24px;
					font-family: PingFangSC, PingFang SC;
					font-weight: 400;
					font-size: 12px;
					color: rgba(0,0,0,0.45);
					line-height: 17px;
					text-align: left;
					font-style: normal;
					margin-top: 12px;
				}
				img{
					width: 58px;
					height: 58px;
					margin: auto;
					display: flex;
					margin-top: 29px;
				}
				.title{
					padding: 0 24px;
					font-family: PingFangSC, PingFang SC;
					font-weight: 400;
					font-size: 16px;
					color: rgba(0,0,0,0.85);
					line-height: 24px;
					text-align: center;
					font-style: normal;
					margin-top: 3px;
				}
			}
			.right-box-apply{
				width: 300px;
				min-height: 224px;
				background: #FFFFFF;
				border-radius: 8px;
				border: 1px solid #E8E8E8;
				margin-bottom: 24px;
				.apply-title{
					padding: 24px 24px 0;
					font-family: PingFangSC, PingFang SC;
					font-weight: 600;
					font-size: 16px;
					color: rgba(0,0,0,0.85);
					line-height: 21px;
				}
				.apply-info{
					padding:  0 24px;
					font-family: PingFangSC, PingFang SC;
					font-weight: 400;
					font-size: 16px;
					color: rgba(0,0,0,0.85);
					line-height: 21px;
					margin-top: 24px;
				}
				.apply-cont{
					padding:  0 24px;
					font-family: PingFangSC, PingFang SC;
					font-weight: 400;
					font-size: 16px;
					color: rgba(0,0,0,0.85);
					line-height: 21px;
					margin-top: 12px;
					white-space: normal;
					word-break: break-all;
				}
				.apply-memver{
					padding:  0 24px;
					font-family: PingFangSC, PingFang SC;
					font-weight: 400;
					font-size: 16px;
					color: #FF4D4F;
					line-height: 21px;
					margin-top: 12px;
				}
				.apply-btn{
					width: 252px;
					height: 32px;
					background: #4077DD;
					border-radius: 8px;
					font-family: PingFangSC, PingFang SC;
					font-weight: 400;
					font-size: 16px;
					color: #FFFFFF;
					display: flex;
					justify-content: center;
					align-items: center;
					margin: 12px auto;
				}
			}
		}
	}
	
	
	.tipPopup{
		::v-deep .el-dialog{
			box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.1), 0px 0px 5px 0px rgba(0,0,0,0.14);
			border-radius: 10px;
			.el-dialog__header{
				padding: 20px 36px 0 28px;
				.el-dialog__title{
					font-family: PingFangSC, PingFang SC;
					font-weight: 500;
					font-size: 18px;
					color: rgba(0,0,0,0.85);
					line-height: 25px;
					text-align: left;
					font-style: normal;
				}
			}
			.el-dialog__body{
				.title{
					font-family: PingFangSC, PingFang SC;
					font-weight: 400;
					font-size: 18px;
					color: rgba(0,0,0,0.85);
					line-height: 27px;
				}
				.info{
					font-family: PingFangSC, PingFang SC;
					font-weight: 400;
					font-size: 14px;
					color: rgba(0,0,0,0.45);
					line-height: 20px;
				}
			}
			.dialog-bom{
				.el-button{
					width: 96px;
					height: 40px;
					background: #4077DD;
					border-radius: 8px;
					font-family: PingFangSC, PingFang SC;
					font-weight: 400;
					font-size: 16px;
					color: #FFFFFF;
					line-height: 24px;
					font-style: normal;
					display: flex;
					justify-content: center;
					align-items: center;
				}
				.close{
					background: #FFFFFF;
					border-radius: 6px;
					border: 1px solid #4077DD;
					color: #4077DD;
				}
			}
			.fp-left{
				font-family: PingFangSC, PingFang SC;
				font-weight: 500;
				font-size: 16px;
				color: rgba(0,0,0,0.85);
				line-height: 21px;
				text-align: left;
				font-style: normal;
				margin-bottom: 12px;
			}
		}
	}
	
	.look-box{
		::v-deep .el-dialog{
			width: 700px;
			// min-height: 893px;
			background: #FFFFFF;
			box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.1), 0px 0px 5px 0px rgba(0,0,0,0.14);
			border-radius: 10px;
			padding: 20px 32px 32px;
			.el-dialog__header{
				padding: 0;
			}
			.el-dialog__body{
				padding: 0;	
			}
		}
		.title{
			font-family: PingFangSC, PingFang SC;
			font-weight: 500;
			font-size: 18px;
			color: #FF4D4F;
			line-height: 36px;
			text-align: left;
			font-style: normal;
		}
		.look-title{
			font-family: PingFangSC, PingFang SC;
			font-weight: 500;
			font-size: 18px;
			color: rgba(0,0,0,0.85);
			line-height: 36px;
			text-align: left;
			font-style: normal;
			margin-bottom: 8px;
		}
		.look-bbx{
			font-family: PingFangSC, PingFang SC;
			font-weight: 500;
			font-size: 18px;
			color: rgba(0,0,0,0.85);
			line-height: 36px;
			text-align: left;
			font-style: normal;
			.m-flex{
				justify-content: space-between;
				position: relative;
				img{
					width: 14px;
					height: 14px;
				}
			}
			.lines-next{
				width: 100%;
				height: 1px;
				background-color: #E8E8E8;
				margin-top: 17px;
				margin-bottom: 9px;
			}
			.color1{
				color: rgba(0,0,0,0.85);
			}
			.color2{
				color: #FF4D4F;
			}
			.color3{
				color: #4077DD;
			}
			
		}
	}
	
	.ts-dbox{
		.ts-title{
			font-family: PingFangSC, PingFang SC;
			font-weight: 500;
			font-size: 18px;
			color: rgba(0,0,0,0.85);
			line-height: 39px;
			text-align: left;
			font-style: normal;
		}
		.ts-info{
			font-family: PingFangSC, PingFang SC;
			font-weight: 400;
			font-size: 16px;
			color: rgba(0,0,0,0.45);
			line-height: 22px;
			margin-top: 14px;
		}
		.ts-content{
			font-family: PingFangSC, PingFang SC;
			font-weight: 400;
			font-size: 16px;
			color: rgba(0,0,0,0.85);
			line-height: 22px;
			margin-top: 16px;
		}
	}
	
	.project-jz{
		min-height: 250px;
		.jz-list{
			margin-top: 24px;
			.item{
				display: flex;
				justify-content: space-between;
				align-items: center;
				border-bottom: 1px solid #F5F7FA;
				height: 40px;
				.title{
					font-family: PingFangSC, PingFang SC;
					font-weight: 400;
					font-size: 16px;
					color: rgba(0,0,0,0.85);
					line-height: 21px;
					text-align: left;
					font-style: normal;
				}
				.times{
					font-family: PingFangSC, PingFang SC;
					font-weight: 400;
					font-size: 16px;
					color: rgba(0,0,0,0.85);
					line-height: 21px;
					text-align: left;
					font-style: normal;
				}
			}
		}
	}
	
	.qrcode {
	  display: inline-block;
	  box-shadow: 0 0 10px #888;
	  width: 250px;
	  height: 250px;
	  padding: 15px; // 利用padding的特性，挤出白边
	  box-sizing: border-box;
	  border-radius: 2px;
	  margin-bottom: 20px;
	
	  img {
	    width: 220;
	    height: 220;
	    background-color: #fff; //设置白色背景色
	
	    box-sizing: border-box;
	  }
	}
	
	.dialog-pwd{
		::v-deep .el-dialog{
			width: 400px !important;
			min-height: 260px;
			.shet-title{
				font-family: PingFangSC, PingFang SC;
				font-weight: 500;
				font-size: 16px;
				color: rgba(0,0,0,0.85);
				line-height: 20px;
				font-style: normal;
			}
			.el-dialog__body{
				.el-input__inner{
					height: 40px;
					margin-left: 0;
				}
				.dialog-bom{
					display: flex;
					justify-content: center;
					.el-button{
						width: auto;
					}
				}
			}
		}
	}

</style>
