import Axios from "../api/axios.js" // 导入配置好的axios文件

const httpAxios = {
  get(url, data) {
    return Axios({
      url: url,
      method: "get",
      params: data
    })
  },
  post(url, data) {
    return Axios({
      url: url,
      method: "post",
      data: data
    })
  },
  downloadFile(url, data) {
    return Axios({
      url: url,
      method: "get",
      responseType: 'blob',
      params: data
    })
  },
  uploadFile(url, data) {
    return Axios({
      url: url,
      method: "post",
      data: data,
      headers: {
        'Content-Type': 'multipart/form-data;'
      }
    })
  },
}
export default httpAxios
