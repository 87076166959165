<template>
  <div class="boxCenter w-p-t-20" v-html="content">

  </div>
</template>

<script>
  export default {
    data() {
      return {
        content: '',
        option: [{
          value: 1,
          text: 'user_agreement',
          mask: '用户协议'
        }, {
          value: 2,
          text: 'privacy_agreement',
          mask: '隐私协议'
        }, {
          value: 3,
          text: 'engineering_measurement',
          mask: '中一鼎合造价众包平台服务规则'
        }, {
          value: 4,
          text: 'charging_standard',
          mask: '中一鼎合造价众包平台收费标准'
        }, {
          value: 5,
          text: 'flexible_em_agree',
          mask: '工程师注册协议'
        }, {
          value: 6,
          text: 'flexible_em_agree',
          mask: '灵活用工协议'
        }, {
          value: 7,
          text: 'price_standard',
          mask: '价格标准'
        }, {
          value: 11,
          text: 'user_guide',
          mask: '用户指南'
        },{
          value: 12,
          text: 'service_agreement',
          mask: '中一鼎合造价众包平台服务协议'
        }]
      }
    },
    created() {
      let type = this.$route.query.type
      if (!type) {
        this.$message.error('参数信息错误')
        return
      }
      let data = this.option.find(e => e.value == type)
      console.log(data.mask);
      if (!data.value) {
        this.$message.error('参数信息错误')
        return
      }
      this.$http.get('config/getByKey', {
        configKey: data.text
      }).then(res => {
        if (res.code == 200) {
          this.content = res.data.content
        }
      })
    }
  }
</script>

<style>
</style>
