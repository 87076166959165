import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'

// import homevue from '@/views/pageHome/home'


Vue.use(VueRouter)

// 解决ElementUI导航栏中的vue-router在3.0版本以上重复点菜单报错问题
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}
const routes = [{
    path: '/',
    name: 'home',
    component: () => import('@/views/index/index'), //首页
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/login/index'), //登录
  }, {
    path: '/xieyi',
    name: 'xieyi',
    component: () => import('@/views/login/xieyi'), //协议
  },
  {
    path: '/fillInfo',
    name: 'fillInfo',
    component: () => import('@/views/login/fillInfo'), //首次登录填写信息
  },
  {
    path: '/search',
    name: 'search',
    component: () => import('@/views/index/search/index'), //搜索
    meta: {
      keepAlive: true, //此组件不需要被缓存
    },
  },
  {
    path: '/EngineerRegist',
    name: 'EngineerRegist',
    component: () => import('@/views/Engineer/registered'), //工程师注册
  },
  {
    path: '/hall',
    name: 'hall',
    component: () => import('@/views/hall/index'), //需求大厅
  }, {
    path: '/hallDetail',
    name: 'hallDetail',
    component: () => import('@/views/hall/detail/detail'), //需求大厅
  }, {
    path: '/talents',
    name: 'talents',
    component: () => import('@/views/talents/index'), //人才库
  }, {
    path: '/talentsDetail',
    name: 'talentsDetail',
    component: () => import('@/views/talents/detail/detail'), //人才库详情
  },
  {
    path: '/imageTextDetail',
    name: 'imageTextDetail',
    component: () => import('@/views/index/imageText/detail'), //资讯详情
  }, {
    path: '/release',
    name: 'release',

    component: () => import('@/views/release/index'), //发布任务
  }, {
    path: '/assess',
    name: 'assess',

    component: () => import('@/views/release/assess'), //估价
  }, {
    path: '/informationList',
    name: 'informationList',
    component: () => import('@/views/information/index'), //消息列表
  }, {
    path: '/informationDetail',
    name: 'informationDetail',
    component: () => import('@/views/information/detail'), //消息列表
  }, {
    path: '/newsTrends',
    name: 'newsTrends',
    component: () => import('@/views/otherView/newsTrends/index'), //消息列表
  }, {
    path: '/AboutUs',
    name: 'AboutUs',
    component: () => import('@/views/otherView/AboutUs/index'), //消息列表
  }, {
    path: '/myCenter',
    name: 'myCenter',
    component: () => import('@/views/myCenter/index'), //消息列表
    children: [{
      path: '/',
      name: '',
      component: () => import('@/views/myCenter/setting/myInfo'), //个人信息
    }, {
      path: '/engineerInfo',
      name: 'engineerInfo',
      component: () => import('@/views/myCenter/setting/engineerInfo'),  //造价师信息
    }, {
      path: '/certification',
      name: 'certification',
      component: () => import('@/views/myCenter/setting/certification'),  //企业认证
    }, {
      path: '/SecuritySet',
      name: 'SecuritySet',
      component: () => import('@/views/myCenter/setting/SecuritySet'), //安全设置
    }, {
      path: '/BankSet',
      name: 'BankSet',
      component: () => import('@/views/myCenter/setting/BankSet'),  //银行卡设置
    }, {
      path: '/InvoiceSet',
      name: 'InvoiceSet',
      component: () => import('@/views/myCenter/setting/InvoiceSet'),  //发票设置
    },
    {
      path: '/cancelAccount',
      name: 'CancelAccount',
      component: () => import('@/views/myCenter/setting/CancelAccount'),  //注销账号
    }]
  }, {
      path: '/complaints',
      name: 'complaints',
      component: () => import('@/views/myCenter/setting/complaints'),  //项目投诉
    }, {
    path: '/myPublishProject',
    name: 'myPublishProject',
    component: () => import('@/views/myCenter/myPublishProject/index'), //我发布的项目
    meta: {
      keepAlive: true, //此组件不需要被缓存
    },

  }, {
    path: '/push',
    name: 'push',
    component: () => import('@/views/myCenter/myPublishProject/push'), //我发布关联

  }, {
    path: '/myPublishProjectDetail',
    name: 'myPublishProjectDetail',
    component: () => import('@/views/myCenter/myPublishProject/myPublishProjectDetail'), //我发布的项目详情

  }, {
    path: '/myProject',
    name: 'myProject',
    component: () => import('@/views/myCenter/myProject/index'), //我参与的项目
    meta: {
      keepAlive: true, //此组件不需要被缓存
    }

  }, {
    path: '/myProjectDetail',
    name: 'myProjectDetail',
    component: () => import('@/views/myCenter/myProject/detail'), //我参与的项目详情

  }, {
    path: '/wallet',
    name: 'wallet',
    component: () => import('@/views/myCenter/wallet/index'), //钱包

  }, {
    path: '/withdrawalAlipay',
    name: 'withdrawalAlipay',
    component: () => import('@/views/myCenter/wallet/withdrawalAlipay'), //钱包
  }, {
    path: '/workbench',
    name: 'workbench',
    component: () => import('@/views/myCenter/workbench/index'), //我参与的项目
    meta: {
      keepAlive: true, //此组件不需要被缓存
    },
  }, {
    path: '/workbenchDetail',
    name: 'workbenchDetail',
    component: () => import('@/views/myCenter/workbench/detail'), //我参与的项目详情

  }, {
    path: '/needEdit',
    name: 'needEdit',
    component: () => import('@/views/myCenter/workbench/needEdit'), //我参与的项目详情
  }, {
    path: '/answer',
    name: 'answer',
    component: () => import('@/views/myCenter/answer/index'), //答题

  }, {
    path: '/answerDetail',
    name: 'answerDetail',
    component: () => import('@/views/myCenter/answer/answerDetail'), //答题
  }, {
    path: '*',
    name: '404',
    component: () => import('@/views/404'),
  }
]

const router = new VueRouter({
  mode: 'history',
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return {
        x: 0,
        y: 0
      }
    }
  }
})

// 导航守卫
// 使用 router.beforeEach 注册一个全局前置守卫，判断用户是否登陆
router.beforeEach((to, from, next) => {
  //路由白名单
  let pathList = ['/login', '/', '/register', '/fillInfo', '/bottomMenu', '/xieyi', '/hall', '/search',
    '/hallDetail', '/talents','/talentsDetail', '/newsTrends', '/AboutUs', '/assess','/imageTextDetail'
  ]
  //console.log(to);
  let index = pathList.findIndex(e => e == to.path)
  if (index != '-1') {
    next();
  } else {
    let token = store.state.token;
    // console.log(token);
    if (token === null || token === '') {
      next('/login');
    } else {
      next();
    }
  }
});

export default router
