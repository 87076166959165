<template>
	<!-- 登录完填写信息 -->
	<div class="">
		<div class="pagebox">
			<div class="lines-title" style="margin-top: 0;">
				<div class="lines-left"></div>
				<span>企业认证</span>
				<div class="hengxian"></div>
			</div>
			<div class="cert-box" v-if="tabsType==0">
				<div class="cert-top">
					<div class="left">
						<div class="top-title">
							<span v-if="userInfo.rzStatus==0">hi，您当前未完成企业认证</span>
							<span v-else>已完成企业认证</span></div>
						<div class="top-info">
							<span v-if="userInfo.rzStatus==0">完成企业认证，享受企业专享定制服务</span>
							<span v-else>完成企业认证，享受企业专享定制服务</span>
						</div>
					</div>
					<div class="right cursor" @click="gotoRz" v-if="userInfo.rzStatus==0">立即认证</div>
					<div class="right" v-if="userInfo.rzStatus==1">审核中</div>
					<div class="right" v-if="userInfo.rzStatus==2">已认证</div>
				</div>
				<div class="cert-warn" v-if="userInfo.rzStatus==0">
					<img src="../../../static/images/center/wait@2x.png" />
					<span>当前账户未完成企业认证，暂不支持该功能</span>
				</div>
				<div class="cert-list" v-if="userInfo.rzStatus==2">
					<div class="cert-item w-m-t-24">
						<div class="item-title">账户管理</div>
						<div class="item-info">
							<div>1、完成企业认证，可以添加多个企业管理账户，在平台发布需求；一个企业账户最多能添加5个子账户</div>
							<div>2、系统默认完成企业认证的账户为主账户；只有主账户具有添加、删除企业管理员的功能；</div>
						</div>
						<div class="user-list" v-if="enterpriseStatus==1">
							<div class="user-item w-m-t-21">
								<div class="left">
									<el-image class='' :src="userInfo.headImgUrl">
										<div slot="error">
											<img src="../../../static/images/mrtx.png">
										</div>
									</el-image>
									<div class="user-top">
										<div class="user-title">
											<span>{{userInfo.nickname}}</span>
											<div class="bjUser" style="color: #f56c6c; border-color: #f56c6c;">主账户</div>
										</div>
										<div class="user-info">用户ID：{{ userInfo.id || '' }}</div>
									</div>
								</div>
								<div class="right cursor"></div>
							</div>
							<div class="user-item w-m-t-16" v-for="(item,index) in userList" :key="index">
								<div class="left">
									<el-image class='' :src="item.headImgUrl || '../../static/images/morenImg.png'">
										<div slot="error">
											<img src="../../../static/images/mrtx.png" style="width: 72px; height: 72px;">
										</div>
									</el-image>
									<div class="user-top">
										<div class="user-title">
											<span>{{item.userName || item.userName1 || '未知' }}</span>
											<div class="bjUser" v-if="item.isAdministrator==1">管理员</div>
										</div>
										<div class="user-info">用户ID：{{item.userId || ''}}</div>
									</div>
								</div>
								<div class="right cursor" @click="deleteUser(item)">
									<img src="../../../static/images/center/delete@2x.png" />
									<span>删除</span>
								</div>
							</div>
							<div class="user-add cursor" @click="alertPhoneShow = true">
								添加账户
							</div>
						</div>
					</div>
					<!-- <div class="cert-item w-m-t-32">
						<div class="item-title">平台代发</div>
						<div class="item-info">
							<div>允许企业账户设定平台人员为管理员，代发企业需求</div>
						</div>
						<div class="user-list" v-if="enterpriseStatus==1">
							<div class="user-item w-m-t-16" v-for="(item,index) in 3" :key="index">
								<div class="left">
									<el-image class='' :src="userInfo.headImgUrl">
										<div slot="error">
											<img src="../../../static/images/mrtx.png">
										</div>
									</el-image>
									<div class="user-top">
										<div class="user-title">
											<span>用户昵称</span>
										</div>
										<div class="user-info">用户ID：1111111</div>
									</div>
								</div>
								<div class="right cursor">
									<img src="../../../static/images/center/delete@2x.png" />
									<span>删除</span>
								</div>
							</div>
							<div class="user-add cursor" @click="alertPhoneShow = true">
								添加账户
							</div>
						</div>
					</div> -->
				</div>
			</div>
			<div class="" v-else>
				<el-form label-position="top" label-width="125px" :model="userForm" :rules="rulesFrom" ref="ruleForm">
					<el-form-item label="企业统一信用代码" prop="code">
						<div class="inputRight">
							<el-input v-model="userForm.code" maxlength="50" placeholder="请输入"></el-input>
						</div>
					</el-form-item>
					<el-form-item label="企业名称" prop="name">
						<div class="inputRight">
							<el-input v-model="userForm.name" maxlength="30" placeholder="企业名称"></el-input>
						</div>
					</el-form-item>
					<el-form-item label="注册地址" prop="address">
						<div class="inputRight">
							<el-input v-model="userForm.address" maxlength="50" placeholder="请输入注册地址"></el-input>
						</div>
					</el-form-item>
					<el-form-item label="联系方式" prop="mobile">
						<div class="inputRight">
							<el-input v-model="userForm.mobile" maxlength="11" placeholder="请输入联系方式"></el-input>
						</div>
					</el-form-item>
					<el-form-item label="请上传企业统一信用代码证" prop="img1">
						<el-upload class="auth-uploader" :action='action' :data="{ type: 1 }" :show-file-list="false"
							:on-success="(response, file, fileList) => handleIdcardSuccess(response, 1)"
							accept="image/jpg,image/jpeg,image/png" :before-upload="beforeIdcarUpload">
							<img style="width: 104px; height: 104px" :src="idCardTemp.portrait">
						</el-upload>
					</el-form-item>
					<el-form-item label="请上传企业授权委托书" prop="img2">
						<el-upload class="auth-uploader" :action='action' :data="{ type: 2 }" :show-file-list="false"
							:on-success="(response, file, fileList) => handleIdcardSuccess(response, 2)"
							accept="image/jpg,image/jpeg,image/png" :before-upload="beforeIdcarUpload">
							<img style="width: 104px; height: 104px" :src="idCardTemp.surface">
						</el-upload>
					</el-form-item>
					<div class="t-form-item m-flex">
						<el-button type="primary" :loading="subLoading" @click="submitForm()">认证</el-button>
						<div class="calClose cursor" @click="closeRegis">取消</div>
					</div>
				</el-form>
				
			</div>
		</div>
		
		<el-dialog :visible.sync="alertPhoneShow" width="40%" center top='20vh'>
		  <div class="w-font-13">
		    <div class="dialog-title">添加账户 </div>
		    <div class="w-m-t-24">
		      <el-form label-position="left" label-width="80px" :model="formLabelAlign">
				<div class="dialog-info">1、只能邀请已进行实名认证的用户；</div>  
				<div class="dialog-info">2、需要对当前账户进行二次安全验证。</div>  
		        <el-form-item class="w-m-t-32" label="手机号:">
		          <el-input v-model="formLabelAlign.mobile" :maxlength="11" onkeyup="value=value.replace(/[^\d]/g,'')" size="small" placeholder="请输入正确的手机号码"></el-input>
		        </el-form-item>
		        <el-form-item class="w-m-t-24" label="验证码:">
		          <div class="mdx-flex">
		            <div class="wzk-flex-1">
		              <el-input v-model="formLabelAlign.code" :maxlength="6" onkeyup="value=value.replace(/[^\d]/g,'')" size="small" placeholder="请输入验证码"></el-input>
		            </div>
		            <div class="form-sms">
		              <el-button type="text" @click.stop='sendSms'>{{ codetext }} </el-button>
		            </div>
		          </div>
		        </el-form-item>
				<el-form-item class="w-m-t-32" label="是否管理:">
					<el-radio v-model="formLabelAlign.isAdministrator" :label="1">是</el-radio>
					<el-radio v-model="formLabelAlign.isAdministrator" :label="0">否</el-radio>
				</el-form-item>
				
				
		      </el-form>
		    </div>
		    <div class="dialog-bom mdx-flex mdx-row-center w-m-t-54">
				<el-button class="close" size="small" @click="close">取 消</el-button>
				<el-button type="primary" size="small" @click='alertPhoneTap'>提交</el-button>
		    </div>
		  </div>
		</el-dialog>
		
	</div>
</template>

<script>
import ruleData from '../../../api/rules.js'
export default {
	data() {
		return {
			subLoading: false,
			tabsType: 0,
			enterpriseStatus: 1,
			alertPhoneShow: false,
			formLabelAlign: {
			  mobile: '',
			  code: '',
			  isAdministrator: 0,
			},
			codeSeconds: 0, // 验证码发送时间间隔
			codetext: '获取验证码',
			userInfo: {},
			action1: this.$store.state.baseURL + 'engineer/idCardOCRCheck',
			action: this.$store.state.baseURL + 'upload/uploadCos',
			rulesFrom: ruleData.certRules,
			userForm: {
				code: '',
				name: '',
				address: '',
				mobile: '',
				img1: '',
				img2: '',
			},
			idCardTemp: {
				portrait: require('@/static/images/center/upload@2x.png'),
				surface: require('@/static/images/center/upload@2x.png'),
			},
			userList: [],
			
		}
	},
	computed: {
		
	},
	created() {
		//
		this.getUserInfo();
		this.getUserList();

	},
	methods: {
		getUserInfo() {
			this.$http.get('user/getUserInfo').then(res => {
				console.log(res)
				if (res.code == 200) {
					this.userInfo = res.data;
				} else {
					store.commit('outLogin')
				}
			})
		},
		getUserList(){
			this.$http.get('engineer/queryNum',{
				
			}).then(res => {
				console.log(res)
				if (res.code == 200) {
					this.userList = res.data;
				} else {
					
				}
			})
		},
		close(){
			this.alertPhoneShow = false;
			this.formLabelAlign.mobile = '';
			this.formLabelAlign.code = '';
			this.formLabelAlign.isAdministrator = 0;
		},
		closeRegis(){
			this.tabsType=0;
			this.userForm.code = '';
			this.userForm.name = '';
			this.userForm.address = '';
			this.userForm.mobile = '';
			this.userForm.img1 = '';
			this.userForm.img2 = '';
			
		},
		beforeIdcarUpload() {
			const h2 = this.$createElement;
			this.$message({
				duration: 1500,
				message: h2('p', null, [
					h2('span', { style: 'color: #185ba1' }, '正在上传… '),
				])
			});
		},
		handleIdcardSuccess(res, type) {
			console.log(res,type,'handleIdcardSuccess');
			if (res.code != 200) {
				this.$message.error(res.msg)
				return
			}
			if (type == 1) {
				this.idCardTemp.portrait = res.data;
				this.userForm.img1 = res.data;
			} else {
				this.idCardTemp.surface = res.data;
				this.userForm.img2 = res.data;
			}
		},
		alertPhoneTap() {
			if(this.formLabelAlign.mobile==''){
				this.$message.error('请输入手机号码');
				return;
			}
			if(this.formLabelAlign.code==''){
				this.$message.error('请输入验证码');
				return;
			}
		  
		  this.$http.post('engineer/saveNumber', this.formLabelAlign).then(res => {
		    if (res.code == 200) {
		      this.alertPhoneShow = false;
			  this.$message.success('添加成功');
			  
			  this.formLabelAlign.mobile = '';
			  this.formLabelAlign.code = '';
			  this.formLabelAlign.isAdministrator = 0;
			  
		      this.getUserInfo();
			  this.getUserList();
		      this.$uniApi.Logins(); //获取个人用户信息
		    }
		  })
		},
		
		gotoRz(){
			if(this.userInfo.identity==1){
				this.tabsType=1;
			}else if(this.userInfo.identity==2){
				//工程师双角色下申请提示
				
				this.$http.get('user/registerInfo', {
					
				}).then(res => {
					if (res.code == 200) {
						this.tabsType=1;
					}
				})
				
				
				
				// const h = this.$createElement;
				// this.$msgbox({
				//   title: "提示",
				//   message: h(
				//     "p",
				//     {
				//       style: "margin-bottom: 20px",
				//     },
				//     [
				//       h(
				//         "span",
				//         {
				//           style: "color: red",
				//         },
				//         "您确定要申请企业吗？申请成功将单独保留客户角色，请先确保工程师订单都已完成，账户资产已经转出。 "
				//       ),
				//     ]
				//   ),
				//   center: true,
				//   showCancelButton: true,
				//   confirmButtonText: "确认申请",
				//   cancelButtonText: "我再想想",
				//   beforeClose: (action, instance, done) => {
				//     if (action === "confirm") {
				//        this.tabsType=1;
				// 	   done();
				//     } else {
				//        done();
				//     }
				//   },
				// }).then((action) => {
				    
				// });
				
			}
		},
		
		submitForm() {
			this.$refs['ruleForm'].validate((valid) => {
				if (valid) {
					this.subLoading = true;
					this.$http.post('user/registerFirm', {
						firmName: this.userForm.name,
						firmAddress: this.userForm.address,
						 firmCreditCode: this.userForm.code,
						firmTel: this.userForm.mobile,
						creditCodeFile: this.userForm.img1,
						firmContractsFile: this.userForm.img2,
					}).then(res => {
						if (res.code == 200) {
							this.$message.success(res.msg);
							this.getUserInfo();
							this.$uniApi.Logins(); //获取个人用户信息
							this.tabsType=0;
							this.subLoading = false;
						}
					})
				}
			});
		},
		deleteUser(item){
			const h2 = this.$createElement;
			this.$confirm("", {
			  title: "提示",
			  message: h2(
			    "p",{ style: "margin-bottom: 20px", },
			    [
			      h2(
			        "span", { style: "color: red",}, "是否确认移除该用户！"
			      ),
			      h2("br", null, ""),
			      h2(
			        "span", { style: "color: red",}, ""
			      ),
			    ]
			  ),
			  center: true,
			  showCancelButton: true,
			  confirmButtonText: "确认移除",
			  cancelButtonText: "我再想想",
			}).then(() => {
			    var durTip = this.$message({
			      duration: 1000,
			      message: h2("p", null, [
			        h2("span", { style: "color: #4077DD" }, "处理中… "),
			      ]),
			    });
			    this.$http.get("engineer/deleteNumber/"+item.userId, {
			         
			      }).then((res) => {
			        durTip.close();
			        if (res.code == 200) {
			            this.getUserInfo();
						this.getUserList();
			            this.$message.success('操作成功')
			        }
			      }).catch((err) => {
			        durTip.close();
			      });
			  }).catch((action) => {
			    
			  });
		},
	
		
	}
}
</script>

<style scoped lang="scss">
.lines-title{
	margin-bottom: 8px;
}
.pagebox {
	padding: 0 0 32px 45px;
	box-sizing: border-box;
	.cert-box{
		height: 100%;
		width: 100%;
		.cert-top{
			width: calc(100% - 72px);
			height: 148px;
			background-image: url('../../../static/images/center/bg@2x.png');
			background-size: 100% 100%;
			display: flex;
			align-items: center;
			justify-content: space-between;
			margin-top: 25px;
			padding: 0 36px;
			.left{
				.top-title{
					font-family: PingFangSC, PingFang SC;
					font-weight: 600;
					font-size: 24px;
					color: #FFFFFF;
					line-height: 33px;
					text-align: left;
					font-style: normal;
				}
				.top-info{
					font-family: PingFangSC, PingFang SC;
					font-weight: 400;
					font-size: 16px;
					color: #FFFFFF;
					line-height: 22px;
					text-align: left;
					font-style: normal;
					margin-top: 16px;
				}
			}
			.right{
				background: rgba(255,255,255,0.68);
				border-radius: 8px;
				padding: 9px 16px;
				font-family: PingFangSC, PingFang SC;
				font-weight: 400;
				font-size: 16px;
				color: #FFB420;
				font-style: normal;
				display: flex;
				align-items: center;
				justify-content: center;
			}
		}
		.cert-warn{
			width: 100%;
			height: 40px;
			background: #FFFBE6;
			border-radius: 8px;
			border: 1px solid #FFF1B8;
			margin-top: 25px;
			line-height: 40px;
			img{
				width: 13px;
				height: 13px;
				margin-left: 13px;
			}
			span{
				font-family: PingFangSC, PingFang SC;
				font-weight: 400;
				font-size: 14px;
				color: rgba(0,0,0,0.88);
				line-height: 22px;
				text-align: left;
				font-style: normal;
				margin-left: 9px;
			}
		}
		.cert-list{
			.cert-item{
				.item-title{
					font-family: PingFangSC, PingFang SC;
					font-weight: 500;
					font-size: 16px;
					color: rgba(0,0,0,0.85);
					line-height: 22px;
					text-align: left;
					font-style: normal;
				}
				.item-info{
					font-family: PingFangSC, PingFang SC;
					font-weight: 400;
					font-size: 14px;
					color: rgba(0,0,0,0.45);
					line-height: 28px;
					text-align: left;
					font-style: normal;
					margin-top: 14px;
				}
				.user-list{
					.user-item{
						width: calc(100% - 68px);
						height: 106px;
						border-radius: 10px;
						border: 1px solid #E8E8E8;
						display: flex;
						justify-content: space-between;
						align-items: center;
						padding: 0 32px;
						.left{
							display: flex;
							justify-content: space-between;
							align-items: center;
							::v-deep .el-image{
								width: 72px;
								height: 72px;
								border-radius: 36px;
							}
							.user-top{
								margin-left: 24px;
								.user-title{
									font-family: PingFangSC, PingFang SC;
									font-weight: 600;
									font-size: 18px;
									color: rgba(0,0,0,0.85);
									line-height: 25px;
									text-align: left;
									font-style: normal;
									display: flex;
									align-items: center;
									.bjUser{
										width: 54px;
										height: 22px;
										border-radius: 4px;
										border: 1px solid #4077DD;
										font-family: PingFangSC, PingFang SC;
										font-weight: 500;
										font-size: 10px;
										color: #4077DD;
										font-style: normal;
										margin-left: 12px;
										display: flex;
										justify-content: center;
										align-items: center;
									}
								}
								.user-info{
									font-family: PingFangSC, PingFang SC;
									font-weight: 400;
									font-size: 16px;
									color: rgba(0,0,0,0.85);
									line-height: 22px;
									text-align: left;
									font-style: normal;
									margin-top: 8px;
								}
							}
						}
						.right{
							display: flex;
							align-items: center;
							img{
								width: 35px;
								height: 35px;
							}
							span{
								font-family: PingFangSC, PingFang SC;
								font-weight: 400;
								font-size: 16px;
								color: #FF4D4F;
								line-height: 22px;
								text-align: left;
								font-style: normal;
							}
						}
					}
					.user-add{
						width: 100%;
						height: 45px;
						background: #FFFFFF;
						border-radius: 4px;
						border: 1px dashed rgba(0,0,0,0.15);
						font-family: PingFangSC, PingFang SC;
						font-weight: 400;
						font-size: 14px;
						color: rgba(0,0,0,0.88);
						font-style: normal;
						display: flex;
						align-items: center;
						justify-content: center;
						margin-top: 26px;
					}
				}
				
			}
		}
	}
}

.image-slot {
	border-radius: 10px;
	width: 80px;
	height: 80px;
}

.image-error {
	width: 80px;
	height: 80px;
	background-color: #eee;
}

.warys {
	padding: 30px;
}

::v-deep .el-form{
	margin-top: 8px;
	margin-bottom: 32px;
	.el-form-item {
		margin-bottom: 0;
	}
	.el-form-item__label {
		font-family: PingFangSC, PingFang SC;
		font-weight: 400;
		font-size: 16px;
		color: rgba(0,0,0,0.85);
		line-height: 20px;
		text-align: left;
		font-style: normal;
		margin-top: 24px;
		padding-bottom: 16px;
	}
	.el-form-item__content{
		max-width: 690px;
		.el-textarea__inner,
		.el-input__inner{
			font-family: PingFangSC, PingFang SC;
			font-weight: 400;
			font-size: 14px;
			color: rgba(0,0,0,0.85);
			line-height: 20px;
			text-align: left;
			font-style: normal;
		}
	}
	.t-form-item{
		margin-top: 24px;
		.el-button{
			width: 96px;
			height: 40px;
			background: #4077DD;
			border-radius: 8px;
			font-family: PingFangSC, PingFang SC;
			font-weight: 400;
			font-size: 16px;
			color: #FFFFFF;
			line-height: 24px;
			font-style: normal;
			display: flex;
			justify-content: center;
			align-items: center;
		}
		.calClose{
			width: 96px;
			height: 40px;
			background: #FFFFFF;
			border-radius: 6px;
			border: 1px solid #4077DD;
			font-family: PingFangSC, PingFang SC;
			font-weight: 400;
			font-size: 16px;
			color: #4077DD;
			font-style: normal;
			display: flex;
			justify-content: center;
			align-items: center;
			margin-left: 24px;
		}
	}
	
}

::v-deep .el-dialog{
	width: 700px !important;
	min-height: 367px;
	background: #FFFFFF;
	box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.1), 0px 0px 5px 0px rgba(0,0,0,0.14);
	border-radius: 10px;
	.el-dialog__headerbtn{
		top: 30px;
	}
	.el-dialog__body{
		padding:0 32px 32px;
		.dialog-title{
			font-family: PingFangSC, PingFang SC;
			font-weight: 500;
			font-size: 18px;
			color: rgba(0,0,0,0.85);
			line-height: 25px;
			text-align: left;
			font-style: normal;
		}
		.dialog-info{
			font-family: PingFangSC, PingFang SC;
			font-weight: 400;
			font-size: 14px;
			color: rgba(0,0,0,0.45);
			line-height: 20px;
			text-align: left;
			font-style: normal;
		}
		.el-form-item__label{
			font-family: PingFangSC, PingFang SC;
			font-weight: 400;
			font-size: 14px;
			color: rgba(0,0,0,0.85);
			font-style: normal;
			margin-top: 10px;
			padding-bottom: 0px;
		}
		.form-sms{
			margin-left: 24px;
			.el-button{
				width: 120px;
				height: 32px;
				background: #10C550;
				border-radius: 6px;
				font-family: PingFangSC, PingFang SC;
				font-weight: 400;
				font-size: 14px;
				color: #FFFFFF;
				line-height: 22px;
				font-style: normal;
				display: flex;
				justify-content: center;
				align-items: center;
			}
		}
		.dialog-bom{
			.el-button{
				width: 96px;
				height: 40px;
				background: #4077DD;
				border-radius: 8px;
				font-family: PingFangSC, PingFang SC;
				font-weight: 400;
				font-size: 16px;
				color: #FFFFFF;
				line-height: 24px;
				font-style: normal;
				display: flex;
				justify-content: center;
				align-items: center;
			}
			.close{
				background: #FFFFFF;
				border-radius: 6px;
				border: 1px solid #4077DD;
				color: #4077DD;
				margin-right: 14px;
			}
		}
		
	}
}
	
</style>
