<template>
    <div class="answer-box">
		<div class="boxCenter">
		    <div class="answer-content">
				<div class="con-top">
					<div class="left">
						<div class="top-title">题目总数：<span>{{answerCount}}道</span></div>
						<div class="top-title">当前已答：<span>{{answerOk}}道</span></div>
						<div class="top-title">未答题：<span>{{unAnswer}}道</span></div>
					</div>
					<div class="right">
						<div class="cursor"  @click="$mHelper.to('/')">取消</div>
						<div class="right-btn cursor" @click="toSubmit">提交答案</div>
					</div>
				</div>
				<div class="list">
					<div class="item" v-for="(item, index) in  listData" :key="index">
						<div class="item-top">
							<span class="tabs" v-if="item.type==1">单选</span>
							<span class="tabs tabs2" v-else-if="item.type==2">多选</span>
							<span class="tabs tabs3" v-else-if="item.type==3">判断</span>
							{{item.title || ''}}
						</div>
						<div class="item-check" v-for="(ittt, iidex) in  item.optionsVoList" :key="iidex" @click="handelData(item, index, iidex)">
							<div v-if="item.type==1 || item.type==3">
								<span :class=" ['orderNo', item.answer== ittt.answerLabel ? 'choiceNo' : ''] ">
									{{ ittt.answerLabel }}
								</span>
								<span :class=" ['text', item.answer== ittt.answerLabel ? 'choiceText' : ''] ">
									{{ ittt.answerContent }}
								</span>
							</div>
							<div v-else>
								<span :class=" ['orderNo', handleClass(item, index, iidex)== true ? 'choiceNo' : ''] ">
									{{ ittt.answerLabel }}
								</span>
								<span :class=" ['text', handleClass(item, index, iidex)== true ? 'choiceText' : ''] ">
									{{ ittt.answerContent }}
								</span>
							</div>
						</div>
							
						
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
  data() {
    return {
		answerCount: 0, //总题数
		answerOk: 0, //已做提数
		unAnswer: 0, //未做的题
        listData:[
			{
				title: '1、这里显示的题目，暂时无法辜负任何官方回顾和梵蒂冈地方很高暂时无法辜负任何官方回顾和梵蒂冈地方很高暂时无法辜负任何官方回顾和梵蒂冈地方很高的题目，暂时无法辜',
				answer: '',
				type: 2,
				optionsVoList:[
					{
						id: 1,
						answerContent: '放大啊地方',
						answerLabel: 'A',
					},
					{
						id: 2,
						answerContent: '发发发发发',
						answerLabel: 'B',
					},
					{
						id: 3,
						answerContent: '灌灌灌灌灌',
						answerLabel: 'C',
					},
					{
						id: 4,
						answerContent: '合格哈哈工',
						answerLabel: 'D',
					},
				]
			},
			{
				title: '判断判断判断判断判断判断判断判断暂时无法辜负任何官方回顾和梵蒂冈地方很高暂时无法辜负任何官方回顾和梵蒂冈地方很高暂时无法辜负任何官方回顾和梵蒂冈地方很高的题目判断判断判断判断判断判断判断判断判断判断判断判断判断判断判断判断判断判断',
				answer: '',
				type: 3,
				optionsVoList:[
					{
						id: 1,
						answerContent: '正确',
						answerLabel: 'A',
					},
					{
						id: 2,
						answerContent: '错误',
						answerLabel: 'B',
					},
				]
			},
			{
				title: '单选单选单选单选单选单选单选单暂时无法辜负任何官方回顾和梵蒂冈地方很高暂时无法辜负任何官方回顾和梵蒂冈地方很高暂时无法辜负任何官方回顾和梵蒂冈地方很高的题目选单选单选单选单选单选单选单选单选单选单选',
				answer: '',
				type: 1,
				optionsVoList:[
					{
						id: 1,
						answerContent: '发发发发发',
						answerLabel: 'A',
					},
					{
						id: 2,
						answerContent: '咕咕咕咕咕',
						answerLabel: 'B',
					},
				]
			}
			
		]
    };
  },
  computed: {
    userInfo() {
      return this.$store.getters['userInfo']
    },
  },
  created() {
    //this.getList();
    this.setKeyWords();
	this.answerCount = this.listData.length;
	this.unAnswer = this.listData.length;
  },
  methods: {
    getList() {
      const loading = this.$loading({
        lock: true,
        text: "加载中...",
        spinner: "el-icon-loading",
        background: "rgba(255, 255, 255, 0.8)",
      });
      
      this.$http.post("project/list", {
         
        })
        .then((res) => {
          if (res.code == 200) {
            
          } else {
            this.list = [];
          }
          loading.close();
        }).catch((err) => {
          loading.close();
        });
    },
   //多选选中渲染
   handleClass(sub, index, iindex) {
   	if(this.listData[index].answer!=null){
   		var to = String(sub.optionsVoList[iindex].answerLabel);
   		var arr = this.listData[index].answer.split(',');
   		//判断当前点击是否在素组内
   		if(arr.indexOf(to)==-1){
   			return false;
   		}else{
   			return true;
   		}
   	}else{
   		return false;
   	}
   },
	// 选择后存放试卷
	handelData(sub, index, iidex) {
		var to = String(sub.optionsVoList[iidex].answerLabel);
		if (sub.type == 1 || sub.type == 3) {
			if(this.listData[index].answer == null){
				this.listData[index].answer = to
				this.answerOk += 1; //已做加一
				this.unAnswer -= 1; //未做减一
			}else if(this.listData[index].answer == to){
				this.listData[index].answer = null;
				this.answerOk -= 1; //已做减一
				this.unAnswer += 1; //未做加一
			}else if(this.listData[index].answer != null && this.listData[index].answer != to){
				this.listData[index].answer = to
			}
			
		} else if(sub.type == 2) {
			if(this.listData[index].answer==null){
				this.listData[index].answer = to;
				this.answerOk += 1; //已做加一
				this.unAnswer -= 1; //未做减一
			}else{
				//列表答案转数组
				var arr = this.listData[index].answer.split(',');
				//判断当前点击是否在素组内
				if(arr.indexOf(to)==-1){
					 arr.push(to);
				}else{
					arr.splice(arr.indexOf(to),1);
				}
			
				if(arr.length > 0){
					this.listData[index].answer = arr.join(',');
				}else{
					this.listData[index].answer = null;
					this.answerOk -= 1; //已做减一
					this.unAnswer += 1; //未做加一
				}
				
			}
		}
		
	},
    toSubmit(){
		this.$mHelper.to('/answerDetail');
	},
    setKeyWords() {
      let element = document.getElementsByTagName("meta");
      let elementArr = Array.from(element);
      let keyWordMeta = elementArr.find((item) => item.name == "keywords");
      if (keyWordMeta) {
        keyWordMeta.content =
          "中一鼎合项目,中一鼎合做的项目有哪些,房建土建,钢结构,精装修,幕墙,房建安装,市政土建,市政管网,市政电气,市政智能化,工程量计算,编清单,组价,概算,住宅,公寓,别墅,学校,医疗建筑,宾馆酒店,交通枢纽,场馆,商业,办公,厂房,物流仓储,地下室,污水处理厂,自来水厂,变电站,加油站,热力站,公交场站,垃圾站,公厕,市政工程,广联达,斯维尔,鲁班,品茗,宏业,新点,一点智慧,智多星,算王,晨曦,广龙,易达,博奥,福莱,造价项目";
      }
    },
   
  },
};
</script>

<style lang="scss" scoped>
.answer-box{
	width: 100%;
	height: 100%;
	background-image: url('../../../static/images/ceshibg@2x.jpg');
	background-repeat: no-repeat;
	background-size: 100% 610px;
	padding-top: 300px;
	.answer-content{
		width: 100%;
		min-height: 1079px;
		background: #FFFFFF;
		box-shadow: 0px 6px 10px 6px rgba(44,46,51,0.03), 6px 6px 20px 3px rgba(44,46,51,0.06);
		border-radius: 8px;
		margin-bottom: 30px;
		.con-top{
			display: flex;
			justify-content: space-between;
			align-items: center;
			width: calc(100% - 48px);
			height: 88px;
			border-bottom: 1px solid #E8E8E8;
			padding: 0 24px;
			.left{
				display: flex;
				align-items: center;
				.top-title{
					font-family: PingFangSC, PingFang SC;
					font-weight: 500;
					font-size: 16px;
					color: rgba(0,0,0,0.85);
					line-height: 20px;
					text-align: left;
					font-style: normal;
					margin-right: 27px;
					span{
						color: #4077DD;
					}
				}
			}
			.right{
				font-family: PingFangSC, PingFang SC;
				font-weight: 400;
				font-size: 16px;
				color: #4077DD;
				line-height: 24px;
				text-align: left;
				font-style: normal;
				display: flex;
				justify-content: center;
				align-items: center;
				.right-btn{
					width: 96px;
					height: 40px;
					background: #4077DD;
					border-radius: 8px;
					font-family: PingFangSC, PingFang SC;
					font-weight: 400;
					font-size: 16px;
					color: #FFFFFF;
					line-height: 24px;
					text-align: center;
					font-style: normal;
					margin-left: 24px;
					display: flex;
					justify-content: center;
					align-items: center;
				}
			}
		}
		.list{
			padding: 48px;
			.item{
				margin-bottom: 48px;
				.item-top{
					font-family: PingFangSC, PingFang SC;
					font-weight: 500;
					font-size: 16px;
					color: rgba(0,0,0,0.85);
					line-height: 30px;
					text-align: left;
					font-style: normal;
					.tabs{
						padding: 3px 8px;
						border-radius: 4px;
						border: 1px solid #4077DD;
						font-family: PingFangSC, PingFang SC;
						font-weight: 400;
						font-size: 12px;
						color: #4077DD;
						line-height: 20px;
						text-align: center;
						font-style: normal;
						margin-right: 8px;
					}
					.tabs2{
						
					}
					.tabs3{
						border: 1px solid #FF4D4F;
						color: #FF4D4F;
					}
				}
				.item-check{
					margin-top: 16px;
					.orderNo {
						width: 30px;
						height: 30px;
						color: #C3C3C3;
						font-size: 14px;
						text-align: center;
						line-height: 30px;
						border-radius: 100%;
						display: inline-block;
						box-sizing: border-box;
						border: 1px solid #bdbdbd;
					}
					.text {
						font-family: PingFangSC, PingFang SC;
						font-weight: 400;
						font-size: 16px;
						color: rgba(0,0,0,0.88);
						line-height: 22px;
						text-align: left;
						font-style: normal;
						margin-left: 8px;
					}
					
					.choiceNo {
						color: #1677FF;
						background-color: #D6E3FE;
						border: 1px solid #D6E3FE;
					}
					
					.choiceText {
						color: #1677FF;
					}
				}
			}
		}
		
		
	}
	
}	
.mingHeind {
  ::v-deep .el-form-item__content {
    line-height: 20px;
  }

  ::v-deep .el-form-item__label {
    line-height: 20px;
  }
 //  ::v-deep .el-checkbox__label{
	//   font-family: PingFangSC, PingFang SC;
	// font-weight: 400;
	// font-size: 16px;
	// color: rgba(0,0,0,0.85);
	// line-height: 18px;
	// text-align: left;
	// font-style: normal;
 //  }
}
	
</style>
