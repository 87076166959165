<template>
  <div class="warysc">
    <div class="content boxCenter mdx-flex mdx-row-between">
		<div class="left">
			<div class="top-title">网站服务</div>
			<div class="listLens ">
				<div @click.stop="zcGcsTaps('/release')">发布需求</div>
				<div @click.stop="toPage('/EngineerRegist')">注册工程师</div>
				<div @click.stop="toPage('/xieyi?type=3')">平台规则</div>
				<div @click.stop="toPage('/hall')">项目大厅</div>
				<div @click.stop="toPage('/hall')">申请项目</div>
				<div @click.stop="toPage('/xieyi?type=4')">收费标准</div>
				<div @click.stop="toPage('/assess')">智能估价</div>
				<div @click.stop="toPage('/talents')">人才库</div>
				<div class="wzk-cursor" @click.stop="toPage('/xieyi?type=11')">用户指南</div>
			</div>
			<div style="margin-top: 24px; margin-bottom: 20px;">
				<div style="flex: 1;">
				  <div class="top-title">联系我们</div>
				  <div class="listLens">
					<span>
						<img src="../../static/images/home/phone@2x.png"/>
						{{mobile||''}}
					</span>
					<span>
						<img src="../../static/images/home/weixin@2x.png"/>
						{{qqNum||''}}
					</span>
					<!-- <span><i class="wzk wzk-weixin" style="font-size: 16px;"></i>{{wxChectNum||''}}</span> -->
				  </div>
				  <div class="listLens addrestText">
					  <span>
						<img src="../../static/images/home/address@2x.png"/>
						{{address||''}}
					  </span>
				  </div>
				  <div class="listLens addrestText">Copyrihgt@2021xxxxx 版权所有 增值电信业务经营许可证：XXXXXXXX </div>
				  <div class="listLens mdx-flex addrestText">
					<a href="https://beian.miit.gov.cn/" target="_blank" rel="noopener noreferrer" class="lines-text" style="text-decoration: none;">
					  <img src="../../static/images/baImg.png" width="16px" height="16px"><span class="w-m-l-10">{{bah||''}}</span>
					</a>
					<!-- <span class="w-m-l-20">京ICP备12043664号-18</span> -->
				  </div>
				</div>
			   
			  </div>
		</div>
		<div>
		  <img :src="wxCodeImg" width="184px" height="184px">
		</div>
		
    </div>
   
    <el-dialog
      title=""
      :visible.sync="centerDialogVisible"
      width="50%"
      center>
      <div class="textStyle">请选择发布模式 </div>
      <div class="textStyle">（首次使用建议选择便捷发布）</div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="convenientRelease">便捷发布</el-button>
        <el-button type="primary" @click="releaseFn">发布</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        centerDialogVisible:false,
        mobile: '',
        qqNum: '',
        wxChectNum: '',
        address: '',
        wxCodeImg: '',
        bah: ''
      }
    },
    created() {
      //客户电话
      this.$http.get('config/pcInfo').then(res => {
        if (res.code == 200) {
          this.mobile = res.data.dh
          this.qqNum = res.data.qq
          this.wxChectNum = res.data.wx
          this.wxCodeImg = res.data.qr
          this.address = res.data.dz
          this.bah = res.data.bah
        }
      })


    },
    computed: {
      userInfo() {
        return this.$store.getters['userInfo']
      }
    },
    methods: {
      zcGcsTaps(path){
        if(!this.userInfo.identity){
          this.$router.push({
            path: '/login'
          })
        }
        if(this.userInfo.identity == 2 && localStorage.getItem('changeType') == 2){
            this.$message.warning('请先切换为客户身份');
            return
        }
        this.centerDialogVisible = true;
      },
      toPage(path) {
        if(!this.userInfo.identity){
          this.$router.push({
            path: '/login'
          })
        }
        if (this.userInfo.identity == 2 && path == '/EngineerRegist') {
          this.$message.error('您当前已是工程师，请勿重复注册工程师')
          return
        }
        if (path == '/release' || path == '/EngineerRegist') {
         
          if (this.userInfo.identity == 3) {
            this.$message.error('经理没有权限发布需求或者注册工程师')
            return
          }

        }
        this.$mHelper.to(path)
      },
        //便捷发布
      convenientRelease(){
        this.centerDialogVisible = false;
        this.$router.push({
          path:'/release',
          query:{
            releaseType: 1
          }
        })
      },
      // 发布
      releaseFn(){
        this.centerDialogVisible = false;
        this.$router.push({
          path:'/release',
          query:{
            releaseType: 2
          }
        })
      },
    }
  }
</script>

<style lang="scss" scoped>
::v-deep .el-dialog{
		width: 414px !important;
		height: 235px;
		background: #FFFFFF;
		border-radius: 10px;
		backdrop-filter: blur(42px);
		.textStyle{
			font-family: PingFangSC, PingFang SC;
			font-weight: 500;
			font-size: 16px;
			color: rgba(0,0,0,0.85);
			line-height: 36px;
			text-align: left;
			font-style: normal;
		}
		.dialog-footer{
			display: flex;
			justify-content: center;
			.footer-btn-left{
				width: 96px;
				height: 40px;
				background: #FFFFFF;
				border-radius: 6px;
				border: 1px solid #4077DD;
				font-family: PingFangSC, PingFang SC;
				font-weight: 400;
				font-size: 16px;
				color: #4077DD;
				line-height: 24px;
				text-align: center;
				font-style: normal;
				display: flex;
				justify-content: center;
				align-items: center;
			}
			.footer-btn-right{
				width: 96px;
				height: 40px;
				background: #4077DD;
				border-radius: 8px;
				font-family: PingFangSC, PingFang SC;
				font-weight: 400;
				font-size: 16px;
				color: #FFFFFF;
				line-height: 24px;
				text-align: center;
				font-style: normal;
				display: flex;
				justify-content: center;
				align-items: center;
				margin-left: 24px;
			}
		}
		
	}
  .warysc {
    background: #F5FAFF;
    box-sizing: border-box;
    color: #fff;
    padding: 42px 0;
    padding-bottom: 10px;
    width: 100%;
    // border: 1px solid #ff0000;
  }

  .content {
	.top-title{
		font-family: PingFangSC, PingFang SC;
		font-weight: 600;
		font-size: 16px;
		color: rgba(0,0,0,0.85);
		line-height: 24px;
		text-align: left;
		font-style: normal;
	}
  }

  .listLens {
	font-family: PingFangSC, PingFang SC;
	font-weight: 500;
	font-size: 14px;
	color: rgba(0,0,0,0.85);
	line-height: 24px;
	text-align: left;
	font-style: normal;  
    display: flex;
	margin-top: 16px;
    >div {
      cursor: pointer;
      margin-right: 24px;
    }

    span {
      margin-right: 20px;
	  display: flex;
	  align-items: center;
	  img{
		  width: 16px; 
		  height: 16px;
		  margin-right: 8px;
	  }
    }
  }

  .addrestText {
	   font-family: PingFangSC, PingFang SC;
	   font-weight: 500;
	   font-size: 14px;
	   color: rgba(0,0,0,0.85);
	   line-height: 24px;
	   text-align: left;
	   font-style: normal;
  }
  .lines-text{
	  font-family: PingFangSC, PingFang SC;
	  font-weight: 500;
	  font-size: 14px;
	  color: rgba(0,0,0,0.85);
	  line-height: 24px;
	  text-align: left;
	  font-style: normal;
	  display: flex;
	  align-items: center;
  }

</style>
