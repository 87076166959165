<template>
  <div class="pages">
	  <div class="lines-title" style="margin-top: 0;">
	  	<div class="lines-left"></div>
	  	<span>银行卡设置</span>
	  	<div class="hengxian"></div>
	  </div>
    <el-form label-position="top" label-width="80px" :model="formLabelAlign" ref="bankRule" :rules="engineerRule">
      <el-form-item label="开户人姓名" prop="name">
        <el-input type="text" v-model="formLabelAlign.name" :maxlength="12" placeholder="请输入开户人姓名"></el-input>
      </el-form-item>
      <el-form-item label="身份证号" prop="idCard">
        <el-input type="text" v-model="formLabelAlign.idCard" :maxlength="18" placeholder="请输入开户人身份证号码"></el-input>
      </el-form-item>
      <el-form-item label="开户行" prop="khBank">
        <el-input type="text" v-model="formLabelAlign.khBank" :maxlength="30" placeholder="请输入开户行名称"></el-input>
      </el-form-item>
      <el-form-item label="银行卡号" prop="card">
        <el-input type="text" v-model="formLabelAlign.card" :maxlength="30"  placeholder="请输入银行卡号"></el-input>
      </el-form-item>
      <div class="w-m-t-32">
        <el-button type="primary" style="padding:10px 40px;" @click='save'>保存</el-button>
      </div>
    </el-form>
  </div>
</template>

<script>
import rulesData from '../../../api/rules.js'
  export default {
    data() {
      return {
		engineerRule: rulesData.backRules,
        formLabelAlign: {
          card: "",
          idCard: "",
          khBank: "",
          name: ""
        }
      }
    },
    created() {
      this.$http.get('user/bank').then(res => {
        if (res.code == 200) {
          if (res.data) {
            this.formLabelAlign = res.data
          }
        }
      })
    },
    methods: {
      save() {
		  this.$refs['bankRule'].validate((valid) => {
		    if (valid) {
				this.$http.post('user/bankSet', this.formLabelAlign).then(res => {
				  if (res.code == 200) {
				    this.$message.success('保存成功')
				  }
				})
			}
		  })
        
      }
    }
  }
</script>

<style lang="scss" scoped>
.lines-title{
	.hengxian{
		 width: calc(100% - 130px);
	}
   
}
.pages {
	width: 100%;
	padding: 0 0 0 45px;
	box-sizing: border-box;
}

::v-deep .el-form{
	margin-top: 8px;
	.el-form-item {
		margin-bottom: 0;
	}
	.el-form-item__label {
		font-family: PingFangSC, PingFang SC;
		font-weight: 400;
		font-size: 16px;
		color: rgba(0,0,0,0.85);
		line-height: 20px;
		text-align: left;
		font-style: normal;
		margin-top: 24px;
		padding-bottom: 16px;
	}
	.el-form-item__content{
		max-width: 690px;
	}
	.el-button{
		width: 96px;
		height: 40px;
		background: #4077DD;
		border-radius: 8px;
		font-family: PingFangSC, PingFang SC;
		font-weight: 400;
		font-size: 16px;
		color: #FFFFFF;
		line-height: 24px;
		font-style: normal;
		display: flex;
		justify-content: center;
		align-items: center;
	}
}

</style>
