export default {
  // enterpriseRegistered: ,
  forgotPhone: {
    mobile: [{
        required: true,
        message: '请输入手机号',
        trigger: 'blur'
      },
      // 方法一：
      {
        validator: (rule, value, cb) => {
          if (/^1[3-9]\d{9}$/.test(value)) {
            cb()
          } else {
            cb(new Error('手机号格式错误'))
          }
        }
      },
    ],
    code: [{
      min: 6,
      max: 6,
      message: '请输入6位数验证码',
      trigger: 'blur'
    }],
  },
  rulesMoney: {
    moneys: [{
      required: true,
      message: '充值金额不能为空'
    }],
  },
  addresTitle: {
    names: [{
      required: true,
      message: '请输入地址组名称',
      trigger: 'blur'
    }]
  },
  addres: {

    name: [{
        required: true,
        message: '请输入收货人姓名',
        trigger: 'blur'
      },
      {
        min: 1,
        max: 10,
        message: '长度在 1 到 10 个字符',
        trigger: 'blur'
      }
    ],
    phone: [{
        required: true,
        message: '请输入手机号',
        trigger: 'blur'
      },
      // 方法一：
      {
        validator: (rule, value, cb) => {
          if (/^1[3-9]\d{9}$/.test(value)) {
            cb()
          } else {
            cb(new Error('手机号格式错误'))
          }
        }
      },
      // // 方法二：
      // {
      //   pattern: /^1[3-9]\d{9}$/,
      //   message: '手机号格式错误'
      // }
    ],
    address: [{
      required: true,
      message: '请选择所在区域',
      trigger: 'blur'
    }],
    addresDetail: [{
      required: true,
      message: '请输入详细地址',
      trigger: 'blur'
    }]
  },
  perfectInfo: {
    headImgUrl: [{
      required: true,
      message: '请上传头像',
      trigger: 'blur'
    }],
    nickname: [{
      required: true,
      message: '请输入昵称',
      trigger: 'blur'
    }],
    mobile: [{
      required: true,
      message: '手机号有误',
      trigger: 'blur'
    }],
    gender: [{
      required: true,
      message: '请选择性别',
      trigger: 'blur'
    }],
    address: [{
      type: 'array',
      required: true,
      message: '请选择所在区域',
      trigger: 'change'
    }]
  },
  rulesExp: {
    workCompany: [{
      required: true,
      message: '请选择单位性质',
      trigger: 'blur'
    }],
    position: [{
      required: true,
      message: '请输入工作岗位',
      trigger: 'blur'
    }],
    time: [{
      type: 'array',
      required: true,
      message: '请选择工作时间段',
      trigger: 'change'
    }],
    achievement: [{
      required: true,
      message: '请输入主要业绩',
      trigger: 'blur'
    }],
  },
  rulesExpro: {
    projectName: [{
      required: true,
      message: '请输入项目名称',
      trigger: 'blur'
    }],
    time: [{
      type: 'array',
      required: true,
      message: '请选择时间段',
      trigger: 'change'
    }],
    remark: [{
      required: true,
      message: '请输入项目描述',
      trigger: 'blur'
    }],
  },
  rulesSetPwd: {
    mobile: [{
      required: true,
      message: '请输入手机号码',
      trigger: 'blur'
    }],
    password: [{
      required: true,
      message: '请输入新密码',
      trigger: 'blur'
    }],
    confPassword: [{
      required: true,
      message: '请再次输入密码',
      trigger: 'blur'
    }],
    code: [{
      required: true,
      message: '请输入验证码',
      trigger: 'blur'
    }],
  },
  gcsRules: {
    type: [{
      type: 'array',
      required: true,
      message: '请选择擅长项目类型',
      trigger: 'change'
    }],
    software: [{
      type: 'array',
      required: true,
      message: '请选择擅长软件',
      trigger: 'change'
    }],
    region: [{
      type: 'array',
      required: true,
      message: '请选择擅长地区',
      trigger: 'change'
    }],
    major: [{
      type: 'array',
      required: true,
      message: '请选择擅长专业',
      trigger: 'change'
    }],
    necessary: [{
      required: true,
      message: '请选择是否可以出现场',
      trigger: 'blur'
    }],
    years: [{
      required: true,
      message: '请输入工程造价从业年限',
      trigger: 'blur'
    }],
    name: [{
      required: true,
      message: '请输入姓名',
      trigger: 'blur'
    }],
    idCard: [{
      required: true,
      message: '请输入身份证号',
      trigger: 'blur'
    }],
    portrait: [{
      required: true,
      message: '请输入上传身份证正反面',
      trigger: 'blur'
    }],
  },
  engineerRules: {
    type: [{
      type: 'array',
      required: true,
      message: '请选择擅长项目类型',
      trigger: 'change'
    }],
    software: [{
      type: 'array',
      required: true,
      message: '请选择擅长软件',
      trigger: 'change'
    }],
    region: [{
      type: 'array',
      required: true,
      message: '请选择擅长地区',
      trigger: 'change'
    }],
    major: [{
      type: 'array',
      required: true,
      message: '请选择擅长专业',
      trigger: 'change'
    }],
    necessary: [{
      required: true,
      message: '请选择是否可以出现场',
      trigger: 'blur'
    }],
    years: [{
      required: true,
      message: '请输入工程造价从业年限',
      trigger: 'blur'
    }],
  },
  releaseRules: {
    type: [{
      required: true,
      message: '请选择擅长项目类型',
      trigger: 'change'
    }],
    majorListValue: [{
      type: 'array',
      required: true,
      message: '请选择所含专业',
      trigger: 'change'
    }],
    serviceContent: [{
      required: true,
      message: '请选择服务内容',
      trigger: 'change'
    }],
    region: [{
      // type: 'array',
      required: true,
      message: '请选择所在地区',
      trigger: 'change'
    }],
    deliveryDeadline: [{
      required: true,
      message: '请选择交付截止时间',
      trigger: 'blur'
    }],
    necessary: [{
      required: true,
      message: '请选择是否出现场',
      trigger: 'change'
    }],
    provided: [{
      required: true,
      message: '请选择是否提供CAD图纸',
      trigger: 'change'
    }],
    files: [{
      type: 'array',
      required: true,
      message: '请上传附件',
      trigger: 'change'
    }],
	bmTime: [{
	  required: true,
	  message: '请输入自由报名期',
	  trigger: 'blur'
	}],
	totalInvestment: [{
	  required: true,
	  message: '请输入总投资',
	  trigger: 'blur'
	}],
	totalScale: [{
	  required: true,
	  message: '请输入总规模',
	  trigger: 'blur'
	}],
	totalArea: [{
	  required: true,
	  message: '请输入占地面积',
	  trigger: 'blur'
	}],
    phone: [{
        required: true,
        message: '请输入手机号',
        trigger: 'blur'
      },
      // 方法一：
      {
        validator: (rule, value, cb) => {
          if (/^1[3-9]\d{9}$/.test(value)) {
            cb()
          } else {
            cb(new Error('手机号格式错误'))
          }
        }
      },
    ],
  },
  releaseIndexRules: {
    type: [{
      required: true,
      message: '请选择擅长项目类型',
      trigger: 'change'
    }],
    majorListValue: [{
      type: 'array',
      required: true,
      message: '请选择所含专业',
      trigger: 'change'
    }],
    serviceContent: [{
      required: true,
      message: '请选择服务内容',
      trigger: 'change'
    }],
    region: [{
      // type: 'array',
      required: true,
      message: '请选择所在地区',
      trigger: 'change'
    }],
    deliveryDeadline: [{
      required: true,
      message: '请选择交付截止时间',
      trigger: 'blur'
    }],
    necessary: [{
      required: true,
      message: '请选择是否出现场',
      trigger: 'change'
    }],
    provided: [{
      required: true,
      message: '请选择是否提供CAD图纸',
      trigger: 'change'
    }],
    files: [{
      type: 'array',
      required: true,
      message: '请上传附件',
      trigger: 'change'
    }],
  	bmTime: [{
  	  required: true,
  	  message: '请输入自由报名期',
  	  trigger: 'blur'
  	}],
  	totalInvestment: [{
  	  required: true,
  	  message: '请输入总投资',
  	  trigger: 'blur'
  	}],
  	totalScale: [{
  	  required: true,
  	  message: '请输入总规模',
  	  trigger: 'blur'
  	}],
  	totalArea: [{
  	  required: true,
  	  message: '请输入占地面积',
  	  trigger: 'blur'
  	}],
    phone: [{
        required: true,
        message: '请输入手机号',
        trigger: 'blur'
      },
      // 方法一：
      {
        validator: (rule, value, cb) => {
          if (/^1[3-9]\d{9}$/.test(value)) {
            cb()
          } else {
            cb(new Error('手机号格式错误'))
          }
        }
      },
    ],
  },
  //线下转账信息
  transferRules: {
    bankDeposit: [{
      required: true,
      message: '请输入开户银行',
      trigger: 'blur'
    }],
    paymentAccount: [{
      required: true,
      message: '请输入付款人账号',
      trigger: 'blur'
    }],
    receipt: [{
      required: true,
      message: '请上传回执单',
      trigger: 'change'
    }]
  },
  auditRules: {
    type: [{
      required: true,
      message: '请选择擅长项目类型',
      trigger: 'change'
    }],
    majorListValue: [{
      type: 'array',
      required: true,
      message: '请选择所含专业',
      trigger: 'change'
    }],
    serviceContent: [{
      required: true,
      message: '请选择服务内容',
      trigger: 'change'
    }],
    regionInt: [{
      type: 'array',
      required: true,
      message: '请选择所在地区',
      trigger: 'change'
    }],
    deliveryDeadline: [{
      required: true,
      message: '请选择交付截止时间',
      trigger: 'blur'
    }],
    necessary: [{
      required: true,
      message: '请选择是否出现场',
      trigger: 'change'
    }],
    provided: [{
      required: true,
      message: '请选择是否提供CAD图纸',
      trigger: 'change'
    }],
    isTeam: [{
      required: true,
      message: '请选择是否需要团队',
      trigger: 'change'
    }],
    orderPrice: [{
      required: true,
      message: '请输入项目订单价格',
      trigger: 'blur'
    }],
    income: [{
      required: true,
      message: '请输入工程师收益',
      trigger: 'blur'
    }],
    engineerDeadline: [{
      required: true,
      message: '请选择工程师交付时间',
      trigger: 'blur'
    }],
    files: [{
      type: 'array',
      required: true,
      message: '请上传附件',
      trigger: 'change'
    }],
	bmTime: [{
	  required: true,
	  message: '请输入自由报名期',
	  trigger: 'blur'
	}],
	totalInvestment: [{
	  required: true,
	  message: '请输入总投资',
	  trigger: 'blur'
	}],
	totalScale: [{
	  required: true,
	  message: '请输入总规模',
	  trigger: 'blur'
	}],
	totalArea: [{
	  required: true,
	  message: '请输入占地面积',
	  trigger: 'blur'
	}],
    phone: [{
        required: true,
        message: '请输入手机号',
        trigger: 'blur'
      },
      // 方法一：
      {
        validator: (rule, value, cb) => {
          if (/^1[3-9]\d{9}$/.test(value)) {
            cb()
          } else {
            cb(new Error('手机号格式错误'))
          }
        }
      },
    ],
  },
  complaints: {
    sn: [{
      required: true,
      message: '请输入项目编号',
      trigger: 'blur'
    }],
    name: [{
      required: true,
      message: '请输入项目名称',
      trigger: 'blur'
    }],
    remark: [{
      required: true,
      message: '请输入投诉说明',
      trigger: 'blur'
    }],
    contactMethod: [{
        required: true,
        message: '请输入手机号',
        trigger: 'blur'
      },
      // 方法一：
      {
        validator: (rule, value, cb) => {
          if (/^1[3-9]\d{9}$/.test(value)) {
            cb()
          } else {
            cb(new Error('手机号格式错误'))
          }
        }
      },
    ],
    check: [{
      type: 'array',
      required: true,
      message: '请选择投诉原因',
      trigger: 'change'
    }],
  },
  alipayRules: {
    txId: [{
      required: true,
      message: '请输入支付宝账号',
      trigger: 'blur'
    }],
    code: [{
      required: true,
      message: '请输入验证码',
      trigger: 'blur'
    }],
    actuallyAmount: [{
      required: true,
      message: '请输入提现金额',
      trigger: 'blur'
    }],
  },
  backRules: {
    name: [{
      required: true,
      message: '请输入开户人姓名',
      trigger: 'blur'
    }],
    idCard: [{
      required: true,
      message: '请输入开户人身份证号码',
      trigger: 'blur'
    }],
    khBank: [{
      required: true,
      message: '请输入开户行名称',
      trigger: 'blur'
    }],
    card: [{
      required: true,
      message: '请输入银行卡号',
      trigger: 'blur'
    }],
  },
  invoiceRules: {
    invoiceHeader: [{
      required: true,
      message: '请输入发票单位名称',
      trigger: 'blur'
    }],
    taxNo: [{
      required: true,
      message: '请输入单位税号',
      trigger: 'blur'
    }],
    bank: [{
      required: true,
      message: '请输入开户银行',
      trigger: 'blur'
    }],
    account: [{
      required: true,
      message: '请输入开户银行账号',
      trigger: 'blur'
    }],
	zcAddress: [{
	  required: true,
	  message: '请输入注册地址',
	  trigger: 'blur'
	}],
	phone: [{
	  required: true,
	  message: '请输入注册电话',
	  trigger: 'blur'
	}],
	email: [{
	  required: true,
	  message: '请输入发票接收邮箱',
	  trigger: 'blur'
	}],
  },
  certRules: {
    name: [{
      required: true,
      message: '请输入企业名称',
      trigger: 'blur'
    }],
    code: [{
      required: true,
      message: '信用代码',
      trigger: 'blur'
    }],
    address: [{
      required: true,
      message: '请输入地址',
      trigger: 'blur'
    }],
    mobile: [{
      required: true,
      message: '请输入电话',
      trigger: 'blur'
    }],
  	img1: [{
  	  required: true,
  	  message: '请上传企业信用代码',
  	  trigger: 'blur'
  	}],
  	img2: [{
  	  required: true,
  	  message: '请上传企业授权委托书',
  	  trigger: 'blur'
  	}],
  	
  },
}
