<template>
  <div class="pages">
	  <div class="lines-title" style="margin-top: 0;">
	  	<div class="lines-left"></div>
	  	<span>发票设置</span>
	  	<div class="hengxian"></div>
	  </div>
    <el-form label-position="top" label-width="80px" :model="formLabelAlign" :rules="engineerRule">
      <el-form-item label="发票抬头" prop="invoiceHeader">
        <el-input v-model="formLabelAlign.invoiceHeader" placeholder="请输入发票单位名称"></el-input>
      </el-form-item>
      <el-form-item label="单位税号" prop="taxNo">
        <el-input v-model="formLabelAlign.taxNo" placeholder="请输入单位税号"></el-input>
      </el-form-item>
	  <el-form-item label="开户银行" prop="bank">
	    <el-input v-model="formLabelAlign.bank" placeholder="请输入开户银行"></el-input>
	  </el-form-item>
	  <el-form-item label="银行账号" prop="account">
	    <el-input v-model="formLabelAlign.account" placeholder="请输入开户银行账号"></el-input>
	  </el-form-item>
      <el-form-item label="注册地址" prop="zcAddress">
        <el-input v-model="formLabelAlign.zcAddress" placeholder="请输入注册地址"></el-input>
      </el-form-item>
      <el-form-item label="注册电话" prop="phone">
        <el-input v-model="formLabelAlign.phone" placeholder="请输入注册电话"></el-input>
      </el-form-item>
      <el-form-item label="发票接收邮箱" prop="email">
        <el-input v-model="formLabelAlign.email" placeholder="请输入发票接收邮箱"></el-input>
      </el-form-item>
      <el-form-item label="" label-width="0">
        <div class="invContent m-flex m-flex-between">
          <span>如需纸质发票，请在下方填写正确的发票邮寄地址；</span>
		  <span style="color: rgba(0,0,0,0.45);">例如：xx省xx市xx区(县）xxxxxx详细地址</span>
        </div>
        <el-input type="textarea" maxlength="100" :rows="8" placeholder="请输入发票邮寄地址" v-model="formLabelAlign.yjAddress"></el-input>
      </el-form-item>

      <div class="w-m-t-32 w-m-b-30">
        <el-button type="primary" style="padding:10px 40px;" @click='alertPhoneTap'>保存</el-button>
      </div>
    </el-form>
  </div>
</template>

<script>
	import rulesData from '../../../api/rules.js'
  export default {
    data() {
      return {
		engineerRule: rulesData.invoiceRules,  
        formLabelAlign: {
          account: '', //银行账号
          bank: '', //开户银行
          email: '', //电子发票接收邮箱
          invoiceHeader: '', //发票抬头
          phone: '', //注册电话
          taxNo: '', //单位税号
          yjAddress: '', //发票邮寄地址
          zcAddress: '' //注册地址
        }
      }
    },
    created() {
      this.$http.get('/invoice/InvoiceInfo').then(res => {
        if (res.code == 200) {
          if (res.data) {
            this.formLabelAlign = res.data
          }
        }
      })
    },
    methods: {
      alertPhoneTap() {
        this.$http.post('/invoice/invoiceSet', this.formLabelAlign).then(res => {
          if (res.code == 200) {
            this.$message.success('保存成功')
          }
        })
      }
    }
  }
</script>

<style lang="scss" scoped>
  .pages {
    width: 100%;
    padding: 0 0 0 45px;
    box-sizing: border-box;
  }

::v-deep .el-form{
	margin-top: 8px;
	.el-form-item {
		margin-bottom: 0;
	}
	.el-form-item__label {
		font-family: PingFangSC, PingFang SC;
		font-weight: 400;
		font-size: 16px;
		color: rgba(0,0,0,0.85);
		line-height: 20px;
		text-align: left;
		font-style: normal;
		margin-top: 24px;
		padding-bottom: 16px;
	}
	.el-form-item__content{
		max-width: 690px;
		.invContent{
			margin-top: 24px;
			margin-bottom: 16px;
			width: 900px;
			span{
				font-family: PingFangSC, PingFang SC;
				font-weight: 400;
				font-size: 16px;
				color: rgba(0,0,0,0.85);
				line-height: 20px;
				text-align: left;
				font-style: normal;
			}
		}
		.el-textarea{
			width: 900px;
		}
		.el-textarea__inner,
		.el-input__inner{
			font-family: PingFangSC, PingFang SC;
			font-weight: 400;
			font-size: 14px;
			color: rgba(0,0,0,0.85);
			line-height: 20px;
			text-align: left;
			font-style: normal;
		}
	}
	.el-button{
		width: 96px;
		height: 40px;
		background: #4077DD;
		border-radius: 8px;
		font-family: PingFangSC, PingFang SC;
		font-weight: 400;
		font-size: 16px;
		color: #FFFFFF;
		line-height: 24px;
		font-style: normal;
		display: flex;
		justify-content: center;
		align-items: center;
	}
}
</style>
