<template>
  <div class="boxCenter">
    <div class="topNavs">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: pathUrl }">{{pathText}}</el-breadcrumb-item>
        <el-breadcrumb-item>图文详情</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="content">
      <div class="titles">
        {{title||'标题错误'}}
      </div>
      <div class="times">
        发布时间：{{times||'****-**-** **:**:**'}}
      </div>
      <div v-html="content">

      </div>
    </div>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        types: 1, //1.首页banner，2.平台优势banner，3.关于我们banner，4.小程序--平台优势，5.小程序--服务范围，6.PC服务范围banner），7.平台证书banner 8.项目案例 9 新闻资讯  
        id: 0,
        content: '',
        title: '',
        times: '',
        pathText: '首页',
        pathUrl: '/',
      }
    },
    created() {
      let data = this.$route.query;
      if (data.pathName && data.pathUrl) {
        this.pathText = data.pathName
        this.pathUrl = data.pathUrl
      }
      //
      this.types = data.types
      this.id = data.id
      if (this.id && this.types) {
        this.getDetails();
      } else {
        this.$message.error('参数错误')
      }
    },
    methods: {
      getDetails() {
        const loading = this.$loading({
          lock: true,
          text: '加载中...',
          spinner: 'el-icon-loading',
          background: 'rgba(255, 255, 255, 0.8)'
        });
        if (this.types == 1) {
          console.log('首页banner');
          this.$http.get('banner/detail', {
            id: this.id
          }).then(res => {
            if (res.code == 200) {
              this.title = res.data.title
              this.content = res.data.content
              this.times = res.data.updateTime
            }
            loading.close();
          }).catch(err=>{
            loading.close();
          })
        }
        if(this.types==8){
          console.log('项目案例');
          this.$http.get('case/detail', {
            id: this.id
          }).then(res => {
            if (res.code == 200) {
              this.title = res.data.title
              this.content = res.data.content
              this.times = res.data.createTime
            }
            loading.close();
          }).catch(err=>{
            loading.close();
          })
        }
        if(this.types==9){
          console.log('新闻资讯');
          this.$http.get('information/detail', {
            id: this.id
          }).then(res => {
            if (res.code == 200) {
              this.title = res.data.title
              this.content = res.data.content
              this.times = res.data.createTime
            }
            loading.close();
          }).catch(err=>{
            loading.close();
          })
        }
      }
    }

  }
</script>

<style lang="scss" scoped>
  .titles {
    font-size: 18px;
    font-weight: 550;
  }

  .content {
    background-color: #fff;
    padding: 20px;
    border-radius: 4px;
  }

  .times {
    margin: 15px 0;
    font-size: 14px;
    color: #757575;
  }

  .topNavs {
    margin: 20px 0;
  }
</style>
