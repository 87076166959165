const fidexMoney = function(num) {
  if (num) {
    return parseFloat(num).toFixed(2)
  } else {
    return '0.00'
  }

}
export {
  fidexMoney
}
