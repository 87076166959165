<template>
  <div class="boxCenter">
    <div class="topNavs">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/wallet' }">钱包</el-breadcrumb-item>
        <el-breadcrumb-item>-支付宝提现</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div style="width: 600px;" class="fromBox">
      <el-form label-position="right" label-width="200px" :model="formLabelAlign" ref="alipayRule" :rules="alipayRules">
        <el-form-item label="支付宝实名认证姓名：">
          {{userInfo.name||''}}
        </el-form-item>
        <el-form-item label="支付宝账户：" prop="txId">
          <el-input v-model="formLabelAlign.txId" size="small"></el-input>
        </el-form-item>
        <el-form-item label="短信验证码发送至：">
          {{userInfo.mobile||''}}
          <el-button class="w-m-l-20" size="mini" @click.stop='sendSms'>{{codetext}}</el-button>
        </el-form-item>
        <el-form-item label="请输入短信验证码：" prop="code">
          <el-input v-model="formLabelAlign.code" size="small"></el-input>
        </el-form-item>
        <el-form-item label="账户余额：">
          <div class="error">{{balance}}元</div>
        </el-form-item>
        <el-form-item label="提现金额：" prop="actuallyAmount">
          <el-input v-model="formLabelAlign.actuallyAmount" size="small"></el-input>
        </el-form-item>
        <div style="height: 20px;"></div>
        <el-form-item label="">
          <el-button type="primary" size="small" @click='tixianNow'>立即提现</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
  import rules from '../../../api/rules.js'
  export default {
    data() {
      return {
        formLabelAlign: {
          txId: '',
          actuallyAmount: '',
          payMethod: 2,
          type: 4,
          mobile: ''
        },
        codeSeconds: 0, // 验证码发送时间间隔
        codetext: '免费获取验证码',
        alipayRules: rules.alipayRules,
        balance: ''
      }
    },
    computed: {
      userInfo() {
        return this.$store.getters['userInfo']
      }
    },
    created() {
      this.getBalance();

    },
    methods: {
      getBalance() {
        this.$http.get('user/getUserInfo').then(res => {
          if (res.code == 200) {
            this.balance = res.data.balance
          } else {
            store.commit('outLogin')
          }
        })
      },
      tixianNow() {
        this.$refs['alipayRule'].validate((valid) => {
          if (valid) {
            this.formLabelAlign.amount = this.formLabelAlign.actuallyAmount
            this.formLabelAlign.mobile = this.userInfo.mobile
            this.$http.post('pay/recharge', this.formLabelAlign).then(res => {
              if (res.code == 200) {
                const h2 = this.$createElement;
                this.$msgbox({
                  title: '提示',
                  message: h2('p', {
                    style: 'margin-bottom: 20px'
                  }, [
                    h2('span', {}, '您已成功提现！'),
                  ]),
                  center: true,
                  showCancelButton: true,
                  showConfirmButton: false,
                  cancelButtonText: '我知道了',
                }).then(action => {
                  if(this){
                    if (res.code == 200) {
                      this.$message.success('提现成功')
                      this.getBalance();
                    }
                  }
                });
              }
            })
          }
        });
        return;

      },
      //发送验证码
      sendSms() {
        if (this.codeSeconds > 0) {
          return;
        }
        let checkSendCode = this.$mGraceChecker.check(this.userInfo, this.$mFormRule.sendCodeRule);
        if (!checkSendCode) {
          this.$message.error(this.$mGraceChecker.error)
          return;
        }
        this.$http.post('sms/sendCode', this.userInfo).then(res => {
          if (res.code == 200) {
            this.$message.success('验证码发送成功，请注意查收')
            this.handleSmsCodeTime(59);
          }
        })
      },
      //验证码倒计时
      handleSmsCodeTime(time) {
        let timer = setInterval(() => {
          if (time <= 0) {
            clearInterval(timer);
            this.codeSeconds = 0;
            this.codetext = '再次发送';
          } else {
            this.codeSeconds = time;
            this.codetext = time;
            time--;
          }
        }, 1000);
      },
    }
  }
</script>

<style lang="scss" scoped>
  .fromBox {
    margin: auto;
    margin-top: 50px;
  }

  ::v-deep .el-form-item {
    margin-bottom: 3px;
  }
</style>
