<template>
  <div class="boxCenter">
    <div class="top-tips">投诉</div>
    <div class="top-info">
      投诉前建议您先与该项目的项目经理沟通，如不能解决您的问题，再选择此渠道。投诉后平台将在3个工作日内与您联系
    </div>
    <div class="w-m-t-32">
      <el-form label-position="top" label-width="80px" :model="formLabelAlign" :rules="rules" ref="fromref">
        <el-form-item label="项目编号" prop="sn">
          <el-input v-model="formLabelAlign.sn" placeholder="请输入您要投诉的项目编号，方便平台快速处理"></el-input>
        </el-form-item>
        <el-form-item label="投诉原因" prop="check">
          <!-- {{formLabelAlign.reason}} -->
          <el-checkbox-group v-model="formLabelAlign.check">
            <div v-for="(city,index) in cities" :label="city" :key="city">
              <el-checkbox :label="(index+1)" @change="checkbox(index+1)">{{ city }}
              </el-checkbox>
            </div>
          </el-checkbox-group>
        </el-form-item>
        <el-form-item label="投诉说明" prop="remark">
          <el-input type="textarea" maxlength="255" :rows="6" placeholder="请详细描述您要投诉的原因，方便平台快速处理您的问题" v-model="formLabelAlign.remark">
          </el-input>
        </el-form-item>
        <el-form-item label="联系方式" prop="contactMethod">
          <el-input v-model="formLabelAlign.contactMethod" placeholder="请输入联系电话"></el-input>
        </el-form-item>
        <el-form-item label="">
          <div class="mdx-text-center">
            <el-button type="primary" style="padding: 10px 30px;" @click="subimtBtn">提交</el-button>
            <el-button class="w-m-l-30" style="padding: 10px 30px;" @click="chongzhi">重置</el-button>
          </div>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
  import rulesData from '../../../api/rules.js'
  export default {
    data() {
      return {
        rules: rulesData.complaints,
        checkList: [],
        cities: ['工作成果质量存在问题', '项目交付时间延误', '造价师服务态度不好', '造价师个人经验及信息与实际不符', '其他问题'],
        formLabelAlign: {
          check: [],
          contactMethod: '', //联系方式
          reason: '', //投诉原因（1.我还未确认验收，对工作成果不满意，2.项目已结束，但我对工作成果不满意，3.投诉延误，4.服务态度，5.其他）
          remark: '',
          sn: '',
        }
      }
    },
    methods: {
      chongzhi(){
        this.$nextTick(e=>{
          this.$refs['fromref'].resetFields();
        })
      },
      subimtBtn() {
        this.$refs['fromref'].validate((valid) => {
          if (valid) {
            this.formLabelAlign.reason = this.formLabelAlign.check[0]
            this.$http.post('user/tsProject', this.formLabelAlign).then(res => {
              if (res.code == 200) {
                this.showTitle();
                 this.$refs['fromref'].resetFields();
              }
            })
          } else {
            console.log('error submit!!');
            return false;
          }
        });

      },
      showTitle() {
        //请求成功后弹窗
        const h = this.$createElement;
        this.$msgbox({
          title: '提示',
          message: h('p', {
            style: 'margin-bottom: 20px'
          }, [
            h('span', {}, '您已成功提交投诉！平台将在3个工作日内联系您，请勿重复提交！'),
          ]),
          center: true,
          showCancelButton: true,
          showConfirmButton: false,
          cancelButtonText: '我知道了',
        }).then(e => {

        })
      },
      checkbox(city) {
        this.formLabelAlign.check = this.formLabelAlign.check.includes(city) ? [city] : [];
      },
    }
  }
</script>

<style lang="scss" scoped>
  .top-tips{
	  font-family: PingFangSC, PingFang SC;
	  font-weight: 600;
	  font-size: 36px;
	  color: rgba(0,0,0,0.85);
	  line-height: 80px;
	  text-align: left;
	  font-style: normal;
	  margin-top: 32px;
  }
  .top-info{
	  font-family: PingFangSC, PingFang SC;
	  font-weight: 400;
	  font-size: 16px;
	  color: rgba(0,0,0,0.85);
	  line-height: 22px;
	  text-align: left;
	  font-style: normal;
	  margin-top: 8px;
  }
  ::v-deep .el-form{
	  .el-form-item__label{
		  font-family: PingFangSC, PingFang SC;
		  font-weight: 400;
		  font-size: 16px;
		  color: rgba(0,0,0,0.85);
		  line-height: 20px;
		  text-align: left;
		  font-style: normal;
		  padding-bottom: 16px;
	  }
	  .el-checkbox__label{
		  font-family: PingFangSC, PingFang SC;
		  font-weight: 400;
		  font-size: 14px;
		  color: rgba(0,0,0,0.88);
		  line-height: 22px;
		  text-align: left;
		  font-style: normal;
	  }
  }
</style>
