<template>
  <div class="boxCenter">
	<div class="top-title">钱包</div>
	<div class="top-Navs">
		<div class="numBox">
			<div class="num-person" v-if="userIdentity == 1">
				<div class="num-item">
				    <div class="title">服务费账户</div>
				    <div class="m-flex">
						<span class="num-title">{{ userInfodata.tempCustomerAmount || 0 }}元</span>
					</div>
				</div>
				<div class="num-item">
				    <div class="title">余额账户</div>
				    <div class="m-flex">
					    <span class="num-title">{{ userInfodata.customerBalance || 0 }}元</span>
						<span class="cursor" @click="handlerCheckBank(1)">提现</span>
				    </div>
				</div>
			</div>
			<div class="num-enginner" v-if="userIdentity == 2">
				<div class="num-item">
				    <div class="title">收益账户</div>
				    <div class="m-flex">
					    <span class="num-title">{{ userInfodata.engineerBalance || 0 }}元</span>
						<span class="cursor" @click="handlerCheckBank(1)">提现</span>
				    </div>
				</div>
				<div class="num-item">
				    <div class="title">质保金账户</div>
				    <div class="m-flex">
						<span class="num-title">{{ userInfodata.tempDepositAmount || 0 }}元</span>
					</div>
				</div>
				<div class="num-item">
				    <div class="title">质保金余额账户</div>
				    <div class="m-flex">
					    <span class="num-title">{{ userInfodata.depositBalance || 0 }}元</span>
						<span class="cursor" @click="handlerCheckBank(2)">提现</span>
				    </div>
				</div>
			</div>
		</div>	
	    <div class="set-box">
			<div class="set-item">
				<span class="set-title">银行卡</span>
				<span class="set-type" v-if="backCard!=''">已设置</span>
				<span class="set-type set-color cursor" @click="routerBankSet" v-else>去设置</span>
			</div>  
			<div class="set-item w-m-t-18">
				<span class="set-title">发票</span>
				<span class="set-type" v-if="invoiceType!=null">已设置</span>
				<span class="set-type set-color cursor" @click="routerInvoiceSet" v-else>去设置</span>
			</div>  
			<div class="set-item w-m-t-18">
				<span class="set-title">支付密码</span>
				<span class="set-type set-color cursor" v-if="userInfo.pwdState==1" @click="routerPaySet">去设置</span>
				<span class="set-type" v-else>已设置</span>
			</div> 
		</div>
    </div>
    <div class="mdx-flex w-m-t-32">
      <div>交易时间：</div>
      <div class="w-m-l-10">
          <el-date-picker size="small" v-model="timeValue" value-format="yyyy-MM-dd" format="yyyy-MM-dd" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" @change="timeChange"> </el-date-picker>
      </div>
      <div class="w-m-l-50">流水类型：</div>
      <div class="w-m-l-15">
        <el-select size="small" v-model="value" placeholder="请选择" @change="timeChange" clearable>
          <!-- <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"></el-option> -->
          <el-option v-for="item in options" :key="item.dictValue" :label="item.dictLabel" :value="item.dictValue"></el-option>
        </el-select>
      </div>
	  <div class="w-m-l-50">账户类型：</div>
	  <div class="w-m-l-15">
	    <el-select size="small" v-model="acType" placeholder="请选择" @change="timeChange" clearable>
	      <el-option v-for="item in accountType" :key="item.value" :label="item.label" :value="item.value"></el-option>
	    </el-select>
	  </div>
    </div>
    <div class="wallet-box">
      <el-table :data="list" border style="width: 100%" :header-cell-style="{ background: '#FAFAFA',  'text-align': 'center' }" :cell-style="{ 'text-align': 'center' }">
        <el-table-column prop="sn" label="流水号" width="180px"></el-table-column>
        <el-table-column prop="accountTypeStr" label="账户类型" width="150px"></el-table-column>
        <el-table-column prop="createTime" label="时间" width="150px"></el-table-column>
        <el-table-column label="项目编号" align="center" prop="projectSn" />
        <el-table-column label="流水类型" align="center" prop="recordTypeStr"/>
        <el-table-column label="金额（元）" align="center" prop="amount" />
        <el-table-column label="渠道收支" align="center" prop="payMethodStr" />
      </el-table>
      <div class="mdx-flex mdx-row-center " style="margin-top: 30px;" v-if="list.length">
        <el-pagination :pageSize="queryParams.limit" :currentPage='queryParams.page' background layout="prev, pager, next" @current-change='pageChange' :page-count="queryParams.totalPage || 1"></el-pagination>
      </div>
    </div>
	
    <!-- 仅工程师身份有质保金 -->
    <!-- <div v-if="userIdentity == 2">
      <div class=" guarantee-money">
        <h2>质保金账户</h2>
        <div style="display:flex;">
          <div style="flex:1;">
            <div class="titl" style="margin-top:20px;margin-bottom:10px;">质保金账户</div>
            <div><span>{{ userInfodata.tempDepositAmount || 0 }}</span>元</div>
          </div>
          <div style="flex:1;">
            <div class="titl" style="margin-top:20px;margin-bottom:10px;">余额账户</div>
            <div><span>{{ userInfodata.depositBalance || 0 }}</span>元</div>
          </div>
        </div>
      </div>
      <div class="mdx-flex mdx-row-right w-m-t-15">
        <el-button type="primary" size="small" @click="handlerCheckBank(2)">余额提现</el-button>
      </div>
      <div class="mdx-flex w-m-t-20">
        <div>交易时间</div>
        <div class="w-m-l-10">
          <el-date-picker size="small" v-model="depositTime" value-format="yyyy-MM-dd" format="yyyy-MM-dd" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" @change="desposeTimeChange"></el-date-picker>
        </div>
        <div class="w-m-l-50">流水类型</div>
        <div class="w-m-l-15">
          <el-select size="small" v-model="depositType" placeholder="请选择" @change="desposeTimeChange" clearable>
            <el-option v-for="item in depositOption" :key="item.dictValue" :label="item.dictLabel" :value="item.dictValue"></el-option>
          </el-select>
        </div>
      </div>
      <div style="margin-top: 20px;">
        <el-table :data="depositList" border style="width: 100%" :header-cell-style="{ background: '#eee', 'text-align': 'center' }" :cell-style="{ 'text-align': 'center' }">
          <el-table-column prop="sn" label="流水号" width="180px"></el-table-column>
          <el-table-column prop="accountTypeStr" label="账户类型" width="150px"></el-table-column>
          <el-table-column prop="createTime" label="时间" width="150px"></el-table-column>
          <el-table-column label="项目编号" align="center" prop="projectSn" />
          <el-table-column label="流水类型" align="center" prop="recordTypeStr"/>
          <el-table-column label="金额（元）" align="center" prop="amount" />
          <el-table-column label="渠道收支" align="center" prop="payMethodStr" />
        <div class="mdx-flex mdx-row-center " style="margin-top: 30px;" v-if="depositList.length">
          <el-pagination :pageSize="depositQuery.limit" :currentPage='depositQuery.page' background layout="prev, pager, next" @current-change='depositPageChange' :page-count="depositQuery.totalPage || 1"></el-pagination>
        </div>
      </div>
    </div> -->


    <el-dialog :visible.sync="tiXianShow" width="40%" center top='20vh'>
      <div class="w-font-13">
		<div class="dialog-title">提现到银行卡</div>
		<div class="dialog-info w-m-t-24">将提现至您的银行卡账户：</div>
		<div class="dialog-info">开户银行：{{backName || ''}}</div>
		<div class="dialog-info">银行账户：{{backCard || ''}}</div>
		<div class="mdx-flex w-m-t-16">
		  <div class="dept-title">提现金额：</div>
		  <div style="width: 80%;">
		    <el-input v-model="wxtixianNum" placeholder="请输入提现金额" onkeyup="value=value.replace(/[^\d]/g,'')"></el-input>
		  </div>
		</div>
		<div class="w-m-t-16 m-flex m-flex-items-center">
			<div class="money-title">
				<div v-if="userIdentity == 1">可提现金额：{{ userInfodata.customerBalance || 0 }}元</div>
				<div v-else>
				  <div v-if="chargeType == 1">可提现金额：{{ userInfodata.engineerBalance || 0 }}元</div>
				  <div v-else>可提现金额：{{ userInfodata.depositBalance || 0 }}元</div>
				</div>
			</div>
			<span class="money-all" @click="setValue">全部提现</span>
        </div>
        <div class="dialog-bom mdx-flex mdx-row-center w-m-t-46">
			<el-button class="close" size="small" @click="tiXianShow = false">取 消</el-button>
            <el-button type="primary" class="" @click='wechetTixian()'>立即提现</el-button>
        </div>
      </div>
    </el-dialog>
	
	
    <el-dialog :visible.sync="inputPassword" width="40%" center top='20vh' class="dialog-pwd">
      <div class="w-font-13">
		<div class="dialog-title">请输入支付密码</div>
        <div v-if="userInfo.pwdState == 1">
          <div class="mdx-text-center w-m-t-40 shet-title">您还未设置支付密码</div>
          <div class="dialog-bom mdx-text-center w-m-t-40">
            <!-- <div>设置及更多→安全设置→支付密码</div> -->
            <el-button type="primary" class="" @click='toSetPassword'>去设置支付密码</el-button>
          </div>
        </div>
        <div v-else>
          <div class="mdx-text-center">
            <div style="width: 100%;margin: 40px 0 60px 0">
              <el-input v-model="withdrawPassword" type="password" placeholder="请输入支付密码"></el-input>
            </div>
          </div>
          <div class="dialog-bom mdx-flex mdx-row-center w-m-t-46">
			<el-button class="close" size="small" @click="inputPassword = false">取 消</el-button>
            <el-button type="primary" class="" @click='checkPassword(1)'> 确定</el-button>
          </div>
        </div>
      </div>
    </el-dialog>
    <el-dialog :visible.sync="inputAliPassword" width="40%" center top='20vh' class="dialog-pwd">
      <div class="w-font-13">
        <div class="dialog-title">请输入支付密码</div>
        <div v-if="userInfo.pwdState == 1">
          <div class="mdx-text-center w-m-t-40 shet-title">您还未设置支付密码</div>
          <div class="dialog-bom mdx-text-center w-m-t-40">
            <!-- <div>设置及更多→安全设置→支付密码</div> -->
            <el-button type="primary" class="" @click='toSetPassword'>去设置支付密码</el-button>
          </div>
        </div>
        <div v-else>
          <div class="mdx-text-center">
             <div style="width: 100%;margin: 40px 0 60px 0">
              <el-input v-model="withdrawPassword" type="password" placeholder="请输入支付密码"></el-input>
            </div>
          </div>
          <div class="dialog-bom mdx-flex mdx-row-center w-m-t-46">
			  <el-button class="close" size="small" @click="inputAliPassword = false">取 消</el-button>
              <el-button type="primary" class="" @click='checkPassword(2)'> 确定</el-button>
          </div>
        </div>
      </div>
    </el-dialog>
    <el-dialog :visible.sync="wxCode" width="40%" center top='20vh'>
      <div class="w-font-13">
        <div class="mdx-text-center">
          <h3>提示</h3>
        </div>
        <div class="w-m-t-10 w-m-t-15">
          您还未绑定微信，扫码绑定微信后继续提现
        </div>
        <div class="mdx-flex mdx-row-center" style="margin-bottom: -100px;">
          <wxlogin appid="wxa4d88247803ff3be" :scope="'snsapi_login'"
            :redirect_uri='encodeURIComponent($store.state.callback + "/wallet")' :state="userInfo.openId ? 2 : 1"
            :href="'data:text/css;base64,LmltcG93ZXJCb3ggLnFyY29kZSB7d2lkdGg6IDIwMHB4O30KLmltcG93ZXJCb3ggLnRpdGxlIHtkaXNwbGF5OiBub25lO30KLmltcG93ZXJCb3ggLmluZm8ge3dpZHRoOiAyMDBweDt9Ci5zdGF0dXNfaWNvbiB7ZGlzcGxheTogbm9uZX0KLmltcG93ZXJCb3ggLnN0YXR1cyB7dGV4dC1hbGlnbjogY2VudGVyO30g'"
            rel="external nofollow">
          </wxlogin>
        </div>
        <!-- <div class="w-m-t-10 mdx-text-center w-m-t-15">
          使用微信*扫一扫*扫码绑定
        </div> -->
      </div>
    </el-dialog>
  </div>
</template>

<script>
import wxlogin from 'vue-wxlogin';
import { initNumberValue } from "@/common/utils/num-validate.js"
import { isArrObj } from "@/common/utils/common.js"



export default {
  components: {
    wxlogin
  },
  data() {
    return {
      userIdentity: 1,//用户身份（1.客户，2.工程师，3.项目经理）
      userInfodata:{},//用户信息
      balance: 0,
      depositBalance: 0,
      wxCode: false,
      tiXianShow: false,
      inputPassword: false,
      inputAliPassword: false,
      wxtixianNum: '',// 文本框的初始值
      withdrawPassword: "",//支付密码
      queryParams: {
        id: 0,
        page: 1, //当前页数
        limit: 10, //每页条数
        totalPage: 1
      },
      list: [],
      timeValue: [],
      value: null,
      depositType: null,
	  acType: null,
      depositList: [],
      depositQuery: {
        page: 1,
        limit: 10,
        totalPage: 1,
      },
      depositTime: [],//0.全部，1.缴纳，2.自动退回，3.补缴，4.扣除，5.平台线下退回
      depositOption: [
        {
          value: 0,
          label: '全部',
        },
        {
          value: 1,
          label: '缴纳',
        },
        {
          value: 2,
          label: '自动退回',
        },
        {
          value: 3,
          label: '补缴',
        },
        {
          value: 4,
          label: '扣除',
        },
        {
          value: 5,
          label: '平台线下退回',
        },
      ],
      options: [{
        value: 0,
        label: '全部'
      }, {
        value: 2,
        label: '收入'
      }, {
        value: 1,
        label: '支出'
      }, {
        value: 3,
        label: '提现'
      }],
      // ，8.工程师中途取消订单(TCancelOrder)，9.给客户退款，10.给工程师退部分质保金，11.工程师完成项目（客户验收通过平台转入项目款））
      // 1.微信，2.支付宝，3.余额，4.线下转账,5.平台扣除，6.平台线下支付
      payMethodMap: {
        1: '微信', 2: '支付宝', 3: '余额', 4: '线下转账', 5: '平台扣除', 6: '平台线下支付'
      },
      typeList: [{
        value: 1,
        text: '客户付款'
      }, {
        value: 2,
        text: '工程师定金（质保金）'
      }, {
        value: 3,
        text: '工程师补缴定金（质保金）'
      }, {
        value: 4,
        text: '提现'
      }, {
        value: 5,
        text: '客户取消发布(TCancelOrder)'
      }, {
        value: 6,
        text: '客户补缴尾款'
      }, {
        value: 7,
        text: '客户中途取消订单退款(TCancelOrder)'
      }, {
        value: 8,
        text: '工程师中途取消订单(TCancelOrder)'
      }, {
        value: 9,
        text: '给客户退款'
      }, {
        value: 10,
        text: '给工程师退部分质保金'
      }, {
        value: 11,
        text: '工程师完成项目（客户验收通过平台转入项目款）'
      }],
      depositMap: {
        //类型(0.全部，1.缴纳，2.自动退回，3.补缴，4.扣除，5.平台线下退回) 来自文档api/payOrder/depositList
        0: "全部", 1: "缴纳", 2: "自动退回", 3: "补缴", 4: "扣除", 5: "平台线下退回",
      },
      chargeType:null,
	  accountType: [
		{
			value: 0,
			label: '质保金账户',
		},
		{
			value: 1,
			label: '收益账户',
		},
		{
			value: 2,
			label: '余额账户',
		}  
	  ],
	  backName: '',
	  backCard: '',
	  invoiceType: null,
    }
  },
  computed: {
    userInfo() {
      return this.$store.getters['userInfo']
    }
  },
  created() {
    this.userIdentity = localStorage.getItem('changeType') || 1;
    let data = this.$route.query
    if (data.code && data.state == 1) {
      this.$http.post('user/bindWechat', {
        type: 1,
        pcCode: data.code
      }).then(res => {
        if (res.code == 200) {
          // this.$mHelper.to('/setting')
          this.$message.success('绑定成功')
          this.$uniApi.Logins(); //获取个人用户信息
          this.$router.push({
            query: {}
          });
        }
      }).catch(err => {
        this.$router.push({
          query: {}
        });
      })

    }
    this.getBalance();
	this.$http.get('user/bank').then(res => {
	  if (res.code == 200) {
	    if (res.data) {
			this.backCard = res.data.card;
			this.backName = res.data.khBank;
	    }
	  }
	})
	this.$http.get('/invoice/InvoiceInfo').then(res => {
	  if (res.code == 200) {
	    if (res.data) {
	      this.invoiceType = 1;
	    }
	  }
	})
  },
  methods: {
    setValue(){
      var buttonValue = this.balance; // 按钮上的变量
      if(this.userIdentity == 1){
        this.wxtixianNum = this.userInfodata.customerBalance; // 点击按钮后将按钮上的变量赋值给文本框
      }else{
        if(this.chargeType == 1){
          this.wxtixianNum = this.userInfodata.engineerBalance;
        }else{
          this.wxtixianNum = this.userInfodata.depositBalance;
        }
      }
    },
    routerInvoiceSet(){
      this.$router.push({
        path: "/InvoiceSet",
        query:{
          type:1
        }
      })
    },
    routerBankSet(){
      this.$router.push({
        path: "/BankSet",
        query:{
          type:1
        }
      })
    },
	routerPaySet(){
      this.$router.push({
        path: "/SecuritySet",
        query:{
          type:1
        }
      })
    },
    handleEdit(data) {
      console.log(data)
      var res = this.typeList.find(e => e.value == data.row.type)
      console.log(res)
      if (res) {
        console.log(res['text'])
      }
    },
    checkPassword(type) {
      if (this.withdrawPassword == "") {
        this.$message.error('请输入支付密码')
        return
      }

      this.$http.post('user/walletPwdCheck', {
        newPassword: this.withdrawPassword
      }).then(res => {
        if (res.code == 200) {
          if (type == 1) {
            this.inputPassword = false;
            this.tiXianShow = true;
          } else {
            this.inputAliPassword = false;
            this.$router.push({
              path: "/withdrawalAlipay"
            })
          }
        } else {
          if (res.msg) {
            this.$message.error(res.msg)
          }
        }
      })
    },
    toSetPassword() {
      this.$router.push({
        path: "/SecuritySet"
      })
    },
    checkWithdPassword(type) {
      if (type == 1) {
        this.inputPassword = true;
      } else {
        this.inputAliPassword = true;
      }
    },
    getBalance() {
      this.$http.get('user/getUserInfo').then(res => {
        if (res.code == 200) {
          console.log('用户信息',res);
          this.userInfodata = res.data;
          this.balance = initNumberValue(res.data.balance);
          this.depositBalance = initNumberValue(res.data.depositBalance);
          if(!localStorage.getItem('changeType')){
            this.userIdentity = res.data.identity;
          }
          console.log('userIdentity',this.userIdentity);
          this.options = [];
          this.depositOption = [];
          this.$http.get('dict_data/getDictData', {
            dictType:'account_record_type'
          }).then(response => {
              if (response.code == 200) {
                console.log('流水类型',response);
                if(this.userIdentity == 2){
                  response.data.forEach((item,index)=>{
                    // if([6,7,8].includes(item.dictValue)){
                    //   this.options.push(item);
                    // }else if([1,2,3,4,5].includes(item.dictValue)){
                    //   this.depositOption.push(item);
                    // }
					if([1,2,3,4,5,6,7,8].includes(item.dictValue)){
					  this.options.push(item);
					}
                  });
                }else{
                  response.data.forEach((item,index)=>{
                    if([9,10,11,12,13,14].includes(item.dictValue)){
                      this.options.push(item);
                    }
                  });
                }
              }
          }).catch(err => {
            
          })
          this.getRecordList();
          if (res.data.identity == 2) {
            //this.getDepositList();
          }
        } else {
          store.commit('outLogin')
        }
      })
    },
    timeChange(e) {
      this.queryParams.page = 1
      this.getRecordList();
    },
    desposeTimeChange() {
      this.depositQuery.page = 1
      this.getDepositList(1);
    },
    getRecordList() {
      const loading = this.$loading({
        lock: true,
        text: '加载中...',
        spinner: 'el-icon-loading',
        background: 'rgba(255, 255, 255, 0.8)'
      });
      this.timeValue = this.timeValue ? this.timeValue : []
      this.$http.post('accountRecord/list', {
        pageNum: this.queryParams.page,
        pageSize: this.queryParams.limit,
        queryType:this.userIdentity == 1 ? 3 : 2,//1 工程师账户流水，2 工程师收益账户， 3 客户账户流水
        beginTime: this.timeValue.length ? this.timeValue[0] : null,
        endTime: this.timeValue.length ? this.timeValue[1] : null,
        recordType: this.value || null
      }).then(res => {
        this.list = [];
        if (res.code == 200) {
          this.queryParams.totalPage = res.data.pages;
          if (res.data && isArrObj(res.data.list)) {
            res.data.list.forEach(item => {
              item.actuallyAmount = initNumberValue(item.actuallyAmount);
              this.list.push(item)
            });
          }

        } else {
          this.list = []
        }
        loading.close();
      }).catch(err => {
        loading.close();
      })
      // this.$http.post('payOrder/list', {
      //   pageNum: this.queryParams.page,
      //   pageSize: this.queryParams.limit,
      //   beginTime: this.timeValue.length ? this.timeValue[0] : null,
      //   endTime: this.timeValue.length ? this.timeValue[1] : null,
      //   type: this.value || null
      // }).then(res => {
      //   this.list = [];
      //   if (res.code == 200) {
      //     this.queryParams.totalPage = res.data.pages;
      //     if (res.data && isArrObj(res.data.list)) {
      //       res.data.list.forEach(item => {
      //         item.actuallyAmount = initNumberValue(item.actuallyAmount);
      //         this.list.push(item)
      //       });
      //     }

      //   } else {
      //     this.list = []
      //   }
      //   loading.close();
      // }).catch(err => {
      //   loading.close();
      // })
    },
    getDepositList(scene = 0) {
      var loadSkin = null;
      if (scene == 1) {
        loadSkin = this.$loading({
          lock: true,
          text: '加载中...',
          spinner: 'el-icon-loading',
          background: 'rgba(255, 255, 255, 0.8)'
        });
      }
      this.$http.post('accountRecord/list', {
        pageNum: this.depositQuery.page,
        pageSize: this.depositQuery.limit,
        queryType:1,//1 工程师账户流水，2 工程师收益账户， 3 客户账户流水
        beginTime: this.depositTime.length ? this.depositTime[0] : null,
        endTime: this.depositTime.length ? this.depositTime[1] : null,
        recordType: this.depositType || null,
      }).then((res) => {
        console.log(res)
        if (scene == 1) {
          loadSkin.close();
        }
        this.depositList = [];
        if (res.code == 200) {
          this.depositQuery.totalPage = res.data.pages;
          if (res.data && isArrObj(res.data.list)) {
            res.data.list.forEach(item => {
              // item.amount = initNumberValue(item.amount);
              item.depositBalance = initNumberValue(item.depositBalance);
              this.depositList.push(item)
            });
          }
          //this.depositList = res.data.list
        } else {
          this.depositList = []
        }
      }).catch((err) => {
        if (scene == 1) {
          loadSkin.close();
        }
        console.warn(err)
      })
      // this.$http.post('payOrder/depositList', {
      //   pageNum: this.depositQuery.page,
      //   pageSize: this.depositQuery.limit,
      //   beginTime: this.depositTime.length ? this.depositTime[0] : null,
      //   endTime: this.depositTime.length ? this.depositTime[1] : null,
      //   type: this.depositType || null,
      //   userId: this.userInfo.id,
      // }).then((res) => {
      //   console.log(res)
      //   if (scene == 1) {
      //     loadSkin.close();
      //   }
      //   this.depositList = [];
      //   if (res.code == 200) {
      //     this.depositQuery.totalPage = res.data.pages;
      //     if (res.data && isArrObj(res.data.list)) {
      //       res.data.list.forEach(item => {
      //         item.amount = initNumberValue(item.amount);
      //         item.depositBalance = initNumberValue(item.depositBalance);
      //         this.depositList.push(item)
      //       });
      //     }
      //     //this.depositList = res.data.list
      //   } else {
      //     this.depositList = []
      //   }
      // }).catch((err) => {
      //   if (scene == 1) {
      //     loadSkin.close();
      //   }
      //   console.warn(err)
      // })

    },
    handlerCheckBank(type){
      this.wxtixianNum = null;
      this.chargeType = type;
      this.$http.get('payOrder/checkBank', {
        userId:this.userInfo.id,
      }).then(res => {
        if (res.code == 200) {
			this.inputPassword = true;
            //this.tiXianShow=true;
        }
      }).catch(err => {
          // this.$Message.error('请设置银行卡信息');
          this.$mHelper.to('/BankSet')
      })
    },
    wechetTixian() {
      // if (!this.userInfo.openId) {
      //   this.wxCode = true
      //   return
      // }
      if (this.wxtixianNum <= 0) {
        this.$message.error('请输入有效的提现金额')
        return
      }
      // if(this.wxtixianNum>this.balance){
      //   this.$message.error('提现金额不足')
      //   return
      // }
      this.$http.post('payOrder/apply', {
        mid:this.userInfo.id,//会员id  
        money:this.wxtixianNum,
        identity:this.userIdentity,
        chargeType:this.chargeType //工程师提现类型 1 工程师收益提现 2 工程师余额提现
      }).then(res => {
        if (res.code == 200) {
          this.tiXianShow = false;
          this.wxtixianNum = '';
          this.$message.success('提现成功')
          this.getBalance();
        }
      }).catch(err => {
        // console.log('提现error',err);
        if(err.data.code == 5001){
          this.$mHelper.to('/BankSet')
        }
      })
      // console.log(this.userInfo)
      // return
      // 之前的微信提现流程
      // const loading = this.$loading({
      //   lock: true,
      //   text: '加载中...',
      //   spinner: 'el-icon-loading',
      //   background: 'rgba(255, 255, 255, 0.8)'
      // });
      // this.$http.post('pay/recharge', {
      //   payMethod: 1,
      //   type: 4,
      //   actuallyAmount: this.wxtixianNum,
      //   amount: this.wxtixianNum,
      // }).then(res => {
      //   if (res.code == 200) {
      //     this.tiXianShow = false;
      //     this.$message.success('提现成功')
      //     this.getBalance();
      //   }
      // }).catch(err => {
      //   loading.close();
      // })
    },
    pageChange(e) {
      this.queryParams.page = e
      this.getRecordList();
    },
    depositPageChange(e) {
      this.depositQuery.page = e
      this.getDepositList();
    }
  }
}
</script>

<style lang="scss" scoped>
.top-title{
	font-family: PingFangSC, PingFang SC;
	font-weight: 600;
	font-size: 36px;
	color: rgba(0,0,0,0.85);
	line-height: 80px;
	text-align: left;
	font-style: normal;
	margin-top: 32px;
}	
.top-Navs {
	display: flex;
	justify-content: space-between;
	margin-top: 24px;
    .numBox{
		width: calc(100% - 318px);
		height: 149px;
		background: #FFFFFF;
		border-radius: 10px;
		border: 1px solid #E8E8E8;
		.num-item{
			padding-left: 32px;
			.title{
				font-family: PingFangSC, PingFang SC;
				font-weight: 600;
				font-size: 18px;
				color: rgba(0,0,0,0.85);
				line-height: 25px;
				text-align: left;
				font-style: normal;
				margin-top: 32px;
			}
			.num-title{
				font-family: PingFangSC, PingFang SC;
				font-weight: 600;
				font-size: 24px;
				color: rgba(0,0,0,0.85);
				line-height: 36px;
				text-align: right;
				font-style: normal;
			}
			.cursor{
				font-family: PingFangSC, PingFang SC;
				font-weight: 400;
				font-size: 16px;
				color: #4077DD;
				line-height: 36px;
				text-align: left;
				font-style: normal;
				margin-left: 16px;
			}
			
		}
		.num-person{
			display: flex;
			justify-content: space-between;
			align-items: center;
			.num-item{
				width: 50%;
				.m-flex{
					margin-top: 24px;
				}
			}
		}
		.num-enginner{
			display: flex;
			justify-content: space-between;
			align-items: center;
			.num-item{
				width: 33.33%;
				.m-flex{
					margin-top: 24px;
				}
			}
		}
	}
	.set-box{
		width: 294px;
		height: 149px;
		background: #FFFFFF;
		border-radius: 10px;
		border: 1px solid #E8E8E8;  
		display: flex;
		flex-flow: column;
		justify-content: center;
		.set-item{
			display: flex;
			justify-content: space-between;
			align-items: center;
			padding: 0 32px;
			.set-title{
				font-family: PingFangSC, PingFang SC;
				font-weight: 600;
				font-size: 18px;
				color: rgba(0,0,0,0.85);
				line-height: 25px;
				text-align: left;
				font-style: normal;
			}
			.set-type{
				font-family: PingFangSC, PingFang SC;
				font-weight: 400;
				font-size: 16px;
				color: rgba(0,0,0,0.85);
				line-height: 36px;
				text-align: left;
				font-style: normal;
			}
			.set-color{
				color: #4077DD;
			}
		}
	}
}
.wallet-box{
	margin-top: 24px;
	margin-bottom: 32px;
	::v-deep .has-gutter{
		.el-table__cell{
			font-family: PingFangSC, PingFang SC;
			font-weight: 600;
			font-size: 16px;
			color: rgba(0,0,0,0.88);
			line-height: 21px;
			text-align: left;
			font-style: normal;
			text-transform: none;
		}
	}
}
	
	
	
	
::v-deep .el-dialog{
	width: 700px !important;
	min-height: 367px;
	background: #FFFFFF;
	box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.1), 0px 0px 5px 0px rgba(0,0,0,0.14);
	border-radius: 10px;
	.el-dialog__headerbtn{
		top: 30px;
	}
	.el-dialog__body{
		padding:0 32px 32px;
		.dialog-title{
			font-family: PingFangSC, PingFang SC;
			font-weight: 500;
			font-size: 18px;
			color: rgba(0,0,0,0.85);
			line-height: 25px;
			text-align: left;
			font-style: normal;
		}
		.dialog-info{
			font-family: PingFangSC, PingFang SC;
			font-weight: 400;
			font-size: 14px;
			color: rgba(0,0,0,0.45);
			line-height: 28px;
			text-align: left;
			font-style: normal;
		}
		.dept-title{
			font-family: PingFangSC, PingFang SC;
			font-weight: 400;
			font-size: 14px;
			color: rgba(0,0,0,0.85);
			line-height: 20px;
			text-align: right;
			font-style: normal;
		}
		.money-title{
			font-family: PingFangSC, PingFang SC;
			font-weight: 500;
			font-size: 16px;
			color: rgba(0,0,0,0.85);
			line-height: 20px;
			text-align: right;
			font-style: normal;
		}
		.money-all{
			font-family: PingFangSC, PingFang SC;
			font-weight: 400;
			font-size: 14px;
			color: #4077DD;
			line-height: 20px;
			text-align: right;
			font-style: normal;
			margin-left: 8px;
		}
		.el-form-item__label{
			font-family: PingFangSC, PingFang SC;
			font-weight: 400;
			font-size: 14px;
			color: rgba(0,0,0,0.85);
			font-style: normal;
		}
		.el-input__inner{
			height: 32px;
			background: #FFFFFF;
			border-radius: 4px;
			border: 1px solid #E8E8E8;
			margin-left: 6px;
		}
		.dialog-bom{
			.el-button{
				width: 96px;
				height: 40px;
				background: #4077DD;
				border-radius: 8px;
				font-family: PingFangSC, PingFang SC;
				font-weight: 400;
				font-size: 16px;
				color: #FFFFFF;
				line-height: 24px;
				font-style: normal;
				display: flex;
				justify-content: center;
				align-items: center;
			}
			.close{
				background: #FFFFFF;
				border-radius: 6px;
				border: 1px solid #4077DD;
				color: #4077DD;
				margin-right: 14px;
			}
		}

	}
}

.dialog-pwd{
	::v-deep .el-dialog{
		width: 400px !important;
		min-height: 260px;
		.shet-title{
			font-family: PingFangSC, PingFang SC;
			font-weight: 500;
			font-size: 16px;
			color: rgba(0,0,0,0.85);
			line-height: 20px;
			font-style: normal;
		}
		.el-dialog__body{
			.el-input__inner{
				height: 40px;
				margin-left: 0;
			}
			.dialog-bom{
				display: flex;
				justify-content: center;
				.el-button{
					width: auto;
				}
			}
		}
	}
}

</style>
