<template>
  <div class="boxCenter">
    <!-- 发布 -->
	<div class="tits">
		<div class="tits-title">审核需求</div>
		<div class="topnavsss mdx-flex" v-if="dataList.length>1">
		  <div class="mdx-flex lines wzk-cursor" @click="alertTabIndex(item,index)" :class="{'activeLines':dataIndex==index}" v-for="(item,index) in dataList" :key='index'>
		    <div class="names">项目订单{{index+1}}</div>
		    <div class="w-m-l-5" v-if="index>0" @click.stop="deleteObject(index)">
		       <i class="el-icon-close" style="font-size: 18px;"></i>
		    </div>
		  </div>
		</div>
		<div class="tits-info">
			<div class="tits-left"></div>
			基本信息
			<div class="hengxian"></div>
		</div>
	</div>
    <div class="conTBVox">
      <el-form label-position="top" label-width="150px" :model="formData" :rules="rulesForm" ref="releaseRef">
        <div class="mingHeind">
          <el-form-item label="项目类型" class="mingHeind" prop="type">
            <el-radio v-model="formData.type" :label="item.dictValue" v-for="(item,index) in typeList" :key='index' @change="estimateMoney()">
              {{item.dictLabel}}
            </el-radio>
          </el-form-item>
		  <el-form-item label="所在地区:" prop="region">
		    <div class="inputRight">
		      <el-cascader class="width420" v-model="formData.regionInt" :options="addOptions" :props='props' @change="changeAddress"></el-cascader>
		    </div>
		  </el-form-item>
		  <el-form-item label="交付截止时间:" prop="deliveryDeadline">
		    <div class="inputRight">
		      <el-date-picker v-model="formData.deliveryDeadline" type="datetime" value-format="yyyy-MM-dd HH:mm"
		        format="yyyy-MM-dd HH:mm" placeholder="选择日期时间" :picker-options="pickerOptions">
		      </el-date-picker>
		    </div>
		  </el-form-item>
		  <el-form-item label="联系电话" class="mingHeind" prop="phone">
		    <el-input class="width420" v-model="formData.phone " :maxlength="11" onkeyup="value=value.replace(/[^\d]/g,'')" placeholder="请填写总投资" @change="estimateMoney()"></el-input>
		  </el-form-item>
		  <el-form-item label="QQ号码" class="mingHeind">
		    <el-input class="width420" v-model="formData.qq" :maxlength="11" onkeyup="value=value.replace(/[^\d]/g,'')" placeholder="请输入QQ号码"></el-input>
		  </el-form-item>
		  <div class="lines-title">
		  	<div class="lines-left"></div>
		  	<span>项目规模</span>
		  	<div class="hengxian"></div>
		  </div>
          <!-- <el-form-item label="所含专业(计算范围)" class="mingHeind" prop="major">
            <el-select v-model="formData.major" multiple placeholder="请选择" style="width: 50%;">
              <el-option v-for="item in majorList" :key="item.value" :label="item.name" :value="item.id">
              </el-option>
            </el-select>
          </el-form-item> -->
          <el-form-item label="所含专业(计算范围)" class="mingHeind w-m-t-24" prop="majorListValue">
            <el-cascader v-model="formData.majorListValue" :options="majorList" :show-all-levels="false" :props="{ multiple: true, checkStrictly: false,}" clearable @change="estimateMoney()"></el-cascader>
          </el-form-item>
          <el-form-item label="总投资" class="mingHeind" prop="totalInvestment">
            <div class="m-flex">
				<div class="mdx-flex">
				  <el-input class="width420" v-model="formData.totalInvestment" :maxlength="10" oninput="value=((value.replace(/[^0-9.]/g,'')).replace(/([0-9]+\.[0-9]{2})[0-9]*/,'$1'))" placeholder="请填写总投资" @change="estimateMoney()"></el-input>
				  <span class="dabwue"> 元</span>
				</div>
				<div class="error tipTiel">
				  提示：请填写所需服务对应工程范围的工程费总投资
				</div>
			</div>
          </el-form-item>
          <el-form-item label="总规模" class="mingHeind" prop="totalScale">
            <div class="m-flex">
				<div class="mdx-flex">
				  <el-input class="width420" v-model="formData.totalScale" :maxlength="10" oninput="value=((value.replace(/[^0-9.]/g,'')).replace(/([0-9]+\.[0-9]{2})[0-9]*/,'$1'))" placeholder="请填写建筑面积" @change="estimateMoney()"></el-input>
				  <span class="dabwue">m²</span>
				</div>
				<div class="error tipTiel">
				  提示：请填写建筑面积
				</div>
			</div>
          </el-form-item>
          <el-form-item label="总占地面积" class="mingHeind" prop="totalArea">
            <div class="m-flex">
				<div class="mdx-flex">
				  <el-input class="width420" v-model="formData.totalArea" :maxlength="10" placeholder="请填写项目总占地面积" @change="estimateMoney()"></el-input>
				  <span class="dabwue">m²</span>
				</div>
				<div class="error tipTiel">
				  提示：请填写项目总占地面积
				</div>
			</div>
          </el-form-item>
          <el-form-item label="服务内容" class="mingHeind" prop="serviceContent">
            <el-radio v-model="formData.serviceContent" :label="item.dictValue" v-for="(item,index) in serviceList"
              :key='index' @change="estimateMoney()">
              {{item.dictLabel}}
            </el-radio>
          </el-form-item>
         <el-form-item label="是否提供CAD图纸:" prop="provided">
           <el-radio v-model="formData.provided" :label="1" @change="estimateMoney()">有</el-radio>
           <el-radio v-model="formData.provided" :label="2" @change="estimateMoney()">无</el-radio>
         </el-form-item>
		 <el-form-item label="是否需要出现场:" @change="estimateMoney()">
		   <div class="mdx-flex">
		     <div>
		       <el-radio v-model="formData.necessary" :label="1" @change="estimateMoney()">是</el-radio>
		       <el-radio v-model="formData.necessary" :label="2" @change="estimateMoney()">否</el-radio>
		     </div>
		     <div class="mdx-flex w-m-l-20" v-if="formData.necessary==1">
		       <el-input type="text" onkeyup="value=value.replace(/[^\d]/g,'')" v-model="formData.days"
		         placeholder="出现场天数" :maxlength="6" @change="estimateMoney()"></el-input>
		       <span class="dabwue day-unit">天</span>
		     </div>
		   </div>
		 </el-form-item>
		 
		 <el-form-item label="自由报名期" class="mingHeind" prop="bmTime">
		   <div class="mdx-flex">
		     <el-input class="width420" v-model="formData.bmTime" :maxlength="10" oninput="value=((value.replace(/[^0-9.]/g,'')).replace(/([0-9]+\.[0-9]{2})[0-9]*/,'$1'))" placeholder="请填写自由报名期"></el-input>
		     <span class="dabwue">时</span>
		   </div>
		   <div class="error tipTiel w-m-l-0 w-m-t-12">
		     提示：自由报名期从审核通过后开始计时，如果自由报名期内申请项目的工程师不足三位，项目经理将主动为您推荐工程师；
		   </div>
		 </el-form-item>
		 <el-form-item label="上传项目附件资料" class="mingHeind" prop="files">
		   <el-upload class="avatar-uploader" :action="action" :on-progress='starUpload' :show-file-list="false"
		     :on-success="handleAvatarSuccess">
		     <el-button size="small" type="primary" :loading="uploading">点击上传</el-button>
		   </el-upload>
		   <div class="error">提示：为保证您的权益，我们将保护您的附件内容，您上传的附件不会直接展示在需求大厅中。</div>
		   <div>
		     <div class="certificateBox mdx-flex-wrap">
		       <div class="certificateBox_lines " v-for="(item,index) in formData.files" :key='index'>
		         <div class="certificateBox_lines_imgBox">
		           <!-- <img style="width: 135px;height: 180px;" :src="item.url"> -->
		           <img :src="IconImg" height="85px" width="88px">
		           <div class="iconbox" @click.stop="formData.files.splice(index,1)">
		             <i class="el-icon-close"></i>
		           </div>
		         </div>
		         <div class="certificateBox_lines_name line2">{{item.name}}</div>
		       </div>
		     </div>
		   </div>
		 </el-form-item>
		 <div class="lines-title">
		 	<div class="lines-left"></div>
		 	<span>其它要求</span>
		 	<div class="hengxian"></div>
		 </div>
          <el-form-item label="要求软件" class="mingHeind w-m-t-24">
            <template>
              <el-checkbox-group v-model="formData.software">
                <el-checkbox :label="item.dictValue" v-for="(item,index) in softwareList" :key='index' @change="softChange(item.dictValue)">
                  {{item.dictLabel}}
                </el-checkbox>
              </el-checkbox-group>
            </template>
          </el-form-item>
          <el-form-item label="项目描述" class="mingHeind">
            <div class="w-m-t-10">
              <el-input type="textarea" :rows="5" v-model="formData.remark" maxlength="500"  placeholder="针对项目的说明和特殊要求，例如工程特殊性说明、图纸注意事项、规则选用、计算范围、划分要求、成果报告要求、工作质量标准要求"></el-input>
            </div>
          </el-form-item>
		  <div class="lines-title">
		  	<div class="lines-left"></div>
		  	<span>项目审核</span>
		  	<div class="hengxian"></div>
		  </div>
		  
		  
          <div class="labelRed w-m-t-24">
            <el-form-item label="智能估价金额">
              <div class="mdx-flex">
                <el-input v-model="formData.estimatedPrice" placeholder="" readonly style="width:300px;" :maxlength="10" oninput="value=((value.replace(/[^0-9.]/g,'')).replace(/([0-9]+\.[0-9]{2})[0-9]*/,'$1'))"></el-input>
                <span class="dabwue"> 元</span>
              </div>
            </el-form-item>
          </div>
          <div class="labelRed">
            <el-form-item label="项目订单价格" class="mingHeind" prop="orderPrice">
              <div class="mdx-flex">
                <el-input v-model="formData.orderPrice" placeholder="请输入项目价格" style="width:300px;" :maxlength="10" @input="handlerInput" oninput="value=((value.replace(/[^0-9.]/g,'')).replace(/([0-9]+\.[0-9]{2})[0-9]*/,'$1'))"></el-input>
                <span class="dabwue"> 元</span>
                <div class="w-m-l-30">
                  <el-radio-group v-model="formData.projectType">
                    <el-radio :label="2" @click.native.prevent="radioClick(1)">固定总价</el-radio>
                  </el-radio-group>
                </div>
              </div>
            </el-form-item>
          </div>
          <div class="labelRed">
            <el-form-item label="管理费比例">
              <div class="mdx-flex">
                <el-select v-model="formData.platformRate" placeholder="选择比例" @change="handlerSe">
                  <el-option
                    v-for="item in managementRatio"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                  </el-option>
                </el-select>
              </div>
            </el-form-item>
          </div>
          <div class="labelRed">
            <el-form-item label="工程师收益" prop="income">
              <div class="mdx-flex">
                <el-input v-model="formData.income" placeholder="请输入工程师收益" readonly style="width:300px;" :maxlength="10"
                  oninput="value=((value.replace(/[^0-9.]/g,'')).replace(/([0-9]+\.[0-9]{2})[0-9]*/,'$1'))"></el-input>
                <span class="dabwue"> 元</span>
                <div class="w-m-l-30">
                  <el-radio-group v-model="formData.profitType">
                    <el-radio :label="2" @click.native.prevent="radioClick(2)">固定总价</el-radio>
                  </el-radio-group>
                </div>
              </div>
            </el-form-item>
          </div>
          <el-form-item label="工程师交付时间" prop="engineerDeadline">
            <div class="inputRight">
              <el-date-picker v-model="formData.engineerDeadline" type="datetime" value-format="yyyy-MM-dd HH:mm"
                format="yyyy-MM-dd HH:mm" placeholder="选择日期时间" :picker-options="pickerOptions" @change="estimateMoney()">
              </el-date-picker>
            </div>
          </el-form-item>
            <!-- <h2 class="error">编辑后预估价格：
                {{guPrice>0?guPrice:'暂无估价'}}
            </h2> -->
          <el-form-item label="是否需要团队:"  style="display:none">
            <el-radio v-model="formData.isTeam" :label="1">是</el-radio>
            <el-radio v-model="formData.isTeam" :label="2">否</el-radio>
          </el-form-item>
        </div>
      </el-form>
    </div>
    <div class="btn-list">
		<el-button class="btns" @click='$router.go(-1)'>取消编辑</el-button>
		<el-button class="btns" type="primary" @click.stop="resolution">拆分项目</el-button>
		<el-button class="btns" type="primary" @click="releaseXuqiu(2)">仅保存</el-button>
		<el-button class="btns btns-submit" type="primary" @click="releaseXuqiu(1)" :loading="loading">保存提交</el-button>
    </div>
    <alertToast ref="alert"></alertToast>
  </div>
</template>

<script>
  import alertToast from '../../../components/alertToast/index.vue'
  import comData from '../../../common/commonData.js'
  import ruleData from '../../../api/rules.js'

  export default {
    components: {
      alertToast
    },
    data() {
      return {
        managementRatio:[{
          value:5,
          label:'5%'
        },{
          value:10,
          label:'10%'
        },{
          value:15,
          label:'15%'
        }],//管理比例数据
        pickerOptions: {
          disabledDate(v) {
            return v.getTime() < new Date().getTime() - 86400000;
          }
        }, //控制时间显示
        IconImg: require('../../../static/images/project/project@2x.png'),
        action: this.$store.state.baseURL + 'upload/uploadCos',
        uploading: false,
        loading: false,
        typeList: [], //擅长类型
        majorList: [], //专业类型获取
        serviceList: [], //服务内容
        softwareList: [], //要求软件
        checkList: [],
        props: {
          label: 'cityName',
          value: 'code'
        },
        rulesForm: ruleData.auditRules,
        addOptions: [], //地址
        files: [{}],
        formData: {
          platformRate:'',
          regionInt: [],
          majorListValue: [],
          days: '', //出现场天数
          deliveryDeadline: "", //交付截止时间
          estimatedPrice: '', //预估价格（元）
          files: [], //上传附件
          major: [], //所含专业
          necessary: 2, //是否需要出现场（1.是，2.否）
          phone: "", //联系电话
          provided: 1, //是否提供CAD图纸（1有，2.无）
          qq: "", //QQ号码
          region: "", //所在地区(直接存储名称)
          remark: "", //项目描述
          serviceContent: '', //服务内容
          software: [], //要求软件
          totalArea: '', //总占地面积（m2）
          totalInvestment: '', //总投资(元)
          totalScale: '', //总规模（m2）
          type: [], //项目类型
          isTeam: 2,
          orderPrice: '', //项目订单价格（元）
          income: '', //工程师收益（元）
          engineerDeadline: '', //工程师收益（元）
          reqMethod: 1 ,//保存方式（1.保存并提交，2.仅保存）
		  bmTime:'',
        },
        dataList: [],
        dataIndex: 0,
        // initData:[],
        check: [],
        list: [
          [1, 2],
          [1]
        ],
          guPrice: 0
      }
    },
    created() {

      let queryData = this.$route.query;
      if (queryData.id) {
        const loading = this.$loading({
          lock: true,
          text: '加载中...',
          spinner: 'el-icon-loading',
          background: 'rgba(255, 255, 255, 0.8)'
        });
        this.$http.get('project/shDetail', {
          id: queryData.id
        }).then(res => {
          if (res.code == 200) {
            let data1 = []
            let data2 = []
            res.data.majorFy.forEach(e => {
              data1.push(e.id)
            })
            res.data.softwareFy.forEach(e => {
              data2.push(e.value)
            })
            res.data.major = data1
            res.data.software = data2
            res.data.majorListValue = res.data.majorBjFy
            if (res.data.region) {
              let str1 = res.data.region.substring(0, 2);
              let data3 = []
              data3.push(str1)
              data3.push(res.data.region)
              res.data.regionInt = data3
            }
            this.dataList.push(res.data);
            if(res.data.splitProjects!=null){
				// res.data.splitProjects.forEach(e => {
				//   let minData1 = []
				//   let minData2 = []
				//   e.majorFy.forEach(e => {
				//     minData1.push(e.id)
				//   })
				//   e.softwareFy.forEach(e => {
				//     minData2.push(e.value)
				//   })
				//   e.major = minData1
				//   e.software = minData2
				//   e.majorListValue = e.majorBjFy
				//   if (e.region) {
				//     let minData3 = e.region.substring(0, 2);
				//     let minData4 = []
				//     minData4.push(minData3)
				//     minData4.push(e.region)
				//     e.regionInt = minData4
				//   }
				//   this.dataList.push(e)
				// })
			}
            this.formData = JSON.parse(JSON.stringify(this.dataList[0]))
            // this.forData.push()
            loading.close();
          }
        }).catch(err => {
          loading.close();
        })
      }
      //项目类型获取
      this.$http.get('dict_data/getDictData', {
        dictType: 'pro_type'
      }).then(res => {
        if (res.code == 200) {
          this.typeList = res.data
        } else {
          this.$message.error('项目类型获取失败')
        }
    })
      //专业类型获取
    this.$http.get('dict_data/majorList').then(res => {
        if (res.code == 200) {
          let list = []
          res.data.forEach(e => {
            if (e.children.length) {
              let children = []
              e.children.forEach(es => {
                children.push({
                  value: es.id,
                  label: es.name
                })
              })
              list.push({
                value: e.id,
                label: e.name,
                children: children
              })
            } else {
              list.push({
                value: e.id,
                label: e.name
              })
            }
          })
          this.majorList = list
        } else {
          this.$message.error('专业获取失败')
        }
    })
      //服務類容
      this.$http.get('dict_data/getDictData', {
        dictType: 'project_service_content'
      }).then(res => {
        if (res.code == 200) {
          this.serviceList = res.data
        } else {
          this.$message.error('服務類容获取失败')
        }
      })
      //地址获取
      this.$http.get('region/json').then(res => {
        if (res.code == 200) {
          this.addOptions = res.data
          //console.log(this.addOptions);
        }
      })
      //擅长软件获取
      this.$http.get('dict_data/getDictData', {
        dictType: 'cc_rj'
      }).then(res => {
        if (res.code == 200) {
          this.softwareList = res.data
        } else {
          this.$message.error('擅长软件获取失败')
        }
      })

    },

    mounted() {
      window.addEventListener('beforeunload', e => this.beforeunloadHandler(e))
    },
    destroyed() {
      window.removeEventListener('beforeunload', e => this.beforeunloadHandler(e))
    },
    methods: {
        estimateMoney() {
            let dataLines = []
            this.formData.majorListValue.forEach(e => {
                dataLines.push(e[e.length - 1])
            })
            this.formData.major = dataLines;
            this.$http.post('project/assess', this.formData).then(res => {
                if (res.code == 200) {
                    this.guPrice = res.data || 0;
					this.formData.estimatedPrice = res.data || 0;
                }
            })
        },
      handlerSe(){
        if(this.formData.orderPrice>0 && this.formData.engineerIncomeRate>0 && this.formData.platformRate){
          let managementFees = (this.formData.orderPrice * (this.formData.platformRate/100)).toFixed(2); // 管理费
          let Amountwithouttax = ((this.formData.orderPrice - managementFees)/(1 + this.formData.engineerIncomeRate)).toFixed(2); // 无税金额
          let valueAddedTax = (this.formData.orderPrice - managementFees - Amountwithouttax).toFixed(2); // 增值税
          let threeTerms = (valueAddedTax * 0.12).toFixed(2); //三项附加费
          //console.log('增值税',valueAddedTax,'无税金额',Amountwithouttax,'三项附加费',threeTerms,'管理费',managementFees,(this.formData.orderPrice - valueAddedTax - threeTerms - managementFees).toFixed(2),'input222222222222')
          this.formData.income = (this.formData.orderPrice - valueAddedTax - threeTerms - managementFees).toFixed(2);
		  
        }else{
          this.formData.income = '';
        }
      },
      handlerInput(value){
        if(value>0 && this.formData.engineerIncomeRate>0 && this.formData.platformRate){
		  let managementFees = (value * (this.formData.platformRate/100)).toFixed(2); // 管理费
          let Amountwithouttax = ((value - managementFees) /(1+this.formData.engineerIncomeRate)).toFixed(2); // 无税金额
          let valueAddedTax = (value - managementFees - Amountwithouttax).toFixed(2); // 增值税
          let threeTerms = (valueAddedTax * 0.12).toFixed(2); //三项附加费
          //console.log('增值税',valueAddedTax,'无税金额',Amountwithouttax,'三项附加费',threeTerms,'管理费',managementFees,(value - valueAddedTax - threeTerms - managementFees).toFixed(2),'input11111111111')
          this.formData.income = (value - valueAddedTax - threeTerms - managementFees).toFixed(2);
          // this.formData.income = (Number(value)*this.formData.engineerIncomeRate).toFixed(2);
		  
		  
        }else{
          this.formData.income = '';
        }
      },
      beforeunloadHandler(e) {
        this._beforeUnload_time = new Date().getTime()
        console.log('this._beforeUnload_time：', this._beforeUnload_time)
        e = e || window.event
        if (e && this.$route.name == 'needEdit') {
          e.returnValue = '111'
        }
        // return '关闭提示'
      },
      unloadHandler() {

      },
      releaseXuqiu(type) {
        this.formData.reqMethod = type
        if (this.dataList.length > 1) {
          if (this.dataIndex == 0) {
            this.$message.error('请保存完其他项目后再来操作该项目')
            return
          }
        }

        this.$refs['releaseRef'].validate((valid) => {
          if (valid) {
            if (this.formData.necessary == 1 && !this.formData.days) {
              this.$message.error('请输入出现场天数')
              return
            }
            if (this.formData.orderPrice < 1000) {
              this.$message.error('项目订单价格最低为1000元！')
              return
            }
            if (type == 1) { //保存并提交
              //请求成功后弹窗
              const h = this.$createElement;
              this.$msgbox({
                title: '提示',
                message: h('p', {
                  style: 'margin-bottom: 20px'
                }, [
                  h('span', {
                    style: 'color: red'
                  }, '提交后内容将不可修改，您确认要提交吗？ '),
                ]),
                center: true,
                showCancelButton: true,
                confirmButtonText: '确认提交',
                cancelButtonText: '我再想想',
                beforeClose: (action, instance, done) => {
                  if (action === 'confirm') {
                    instance.confirmButtonLoading = true;

                    let dataLines = []
                    this.formData.majorListValue.forEach(e => {
                      dataLines.push(e[e.length - 1])
                    })
                    this.formData.major = dataLines;
                    this.formData.platformRate = this.formData.platformRate; //管理费比例
                    this.formData.platformFee = (this.formData.orderPrice * (this.formData.platformRate/100)).toFixed(2);
                    console.log(8, this.formData)
                    this.$http.post('director/saveExamine', this.formData).then(res => {
                      instance.confirmButtonLoading = false;
                      if (res.code == 200) {
                        if (this.dataList.length > 1) {
                          done();
                          this.dataList.splice(this.dataIndex, 1);
                          this.dataIndex = 0
                          this.formData = JSON.parse(JSON.stringify(this.dataList[0]))
                          // let distance = document.documentElement.scrollTop || document.body
                          //   .scrollTop; //获得当前高度
                          // let step = distance / 30; //每步的距离
                          // (function jump() {
                          //   if (distance > 0) {
                          //     distance -= step;
                          //     window.scrollTo(0, distance);
                          //     setTimeout(jump, 10);
                          //   }
                          // })();
                        } else {
                          done();
                          this.$message.success('提交成功');
                          this.$router.go(-1)
                        }
                      }
                    }).catch(err => {
                      instance.confirmButtonLoading = false;
                    })
                  } else {
                    done();
                  }
                }
              }).then(action => {
                this.$refs.alert.alertToast('提交成功！', '提示', '#ff0000')
              });
              //

            } else {
              this.loading = true
              let dataLines = []
              this.formData.majorListValue.forEach(e => {
                dataLines.push(e[e.length - 1])
              })
              this.formData.major = dataLines;
              this.formData.platformRate = this.formData.platformRate; //管理费比例
              this.formData.platformFee = (this.formData.orderPrice * (this.formData.platformRate/100)).toFixed(2);
              this.$http.post('director/saveExamine', this.formData).then(res => {
                if (res.code == 200) {
                  if (this.dataList.length > 1) {
                    this.loading = false
                    this.$refs.alert.alertToast('保存成功！', '提示', '#ff0000')
                    this.dataList.splice(this.dataIndex, 1);
                    this.dataIndex = 0
                    this.formData = JSON.parse(JSON.stringify(this.dataList[0]))
                    // let distance = document.documentElement.scrollTop || document.body.scrollTop; //获得当前高度
                    // let step = distance / 30; //每步的距离
                    // (function jump() {
                    //   if (distance > 0) {
                    //     distance -= step;
                    //     window.scrollTo(0, distance);
                    //     setTimeout(jump, 10);
                    //   }
                    // })();
                  } else {
                    this.loading = false
                    this.$message.success('保存成功')
                    this.$router.go(-1)
                  }
                }
              }).catch(err => {
                this.loading = false
              })
            }
          } else {
            this.$message.warning('请完善信息');
            return false;
          }
        });
      },
      softChange(city) {
        this.formData.software = this.formData.software.includes(city) ? [city] : [];
          this.estimateMoney();
      },
      resolution() {
        console.log('拆分项目');
        let data = JSON.parse(JSON.stringify(this.formData))
        data.id = null
        this.dataList.push(JSON.parse(JSON.stringify(data)))
        let distance = document.documentElement.scrollTop || document.body.scrollTop; //获得当前高度
        let step = distance / 30; //每步的距离
        (function jump() {
          if (distance > 0) {
            distance -= step;
            window.scrollTo(0, distance);
            setTimeout(jump, 10);
          }
        })();
      },
      alertTabIndex(item, index) {
        this.dataList[this.dataIndex] = this.formData;
        this.dataIndex = index
        this.formData = JSON.parse(JSON.stringify(this.dataList[index]))
      },
      deleteObject(index) { //
        const h = this.$createElement;
        this.$msgbox({
          title: '提示',
          message: h('p', {
            style: 'margin-bottom: 20px'
          }, [
            h('span', {
              style: 'color: red'
            }, '删除后该页面内容不会保存，确认删除此项目订单吗？ '),
          ]),
          center: true,
          showCancelButton: true,
          confirmButtonText: '确认删除',
          cancelButtonText: '我再想想',
        }).then(action => {
          this.dataIndex = 0
          this.formData = JSON.parse(JSON.stringify(this.dataList[0]))
          this.dataList.splice(index, 1);
        });

      },
      starUpload() {
        this.uploading = true
      },
      handleAvatarSuccess(response, file, fileList) {
        if (file.response.code == 200) {
          let data = {
            name: file.name,
            url: file.response.data
          }
          this.formData.files.push(data)
        } else {
          this.$message.error(file.response.msg)
        }
        this.uploading = false
      },
      changeAddress(e) {
        this.formData.region = e[1]
        //console.log(this.formData.region);
      },
      radioClick(e) {
        this.formData.projectType = this.formData.projectType == 1 ? 2 : 1
        this.formData.profitType = this.formData.profitType == 1 ? 2 : 1
        // if (e == 1) {
        //   this.formData.projectType = this.formData.projectType == 1 ? 2 : 1
        // } else {
        //   this.formData.profitType = this.formData.profitType == 1 ? 2 : 1
        // }
      }
    },

  }
</script>

<style lang="scss" scoped>
.subimtBtmn {
    margin-top: 30px;
    display: flex;
    align-items: center;

    &_btn {
      padding: 12px 60px;
      font-size: 16px;
      margin-right: 30px;
    }
	.el-button{
		width: 96px;
		height: 40px;
		background: #4077DD;
		border-radius: 8px;
		font-family: PingFangSC, PingFang SC;
		font-weight: 400;
		font-size: 16px;
		color: #FFFFFF;
		line-height: 24px;
		font-style: normal;
		display: flex;
		justify-content: center;
		align-items: center;
	}
  }
  
  ::v-deep .el-button--small{
	  background-color: #4077DD;
	  border-color: #4077DD;
  }
  
    ::v-deep .el-form-item{
		display: flex;
		flex-flow: column;
		margin-bottom: 24px;
	   .el-form-item__label {
		  font-family: PingFangSC, PingFang SC;
		  font-weight: 400;
		  font-size: 16px;
		  color: rgba(0,0,0,0.85);
		  line-height: 20px;
		  text-align: left;
		  font-style: normal;
		  padding-bottom: 0;
		}
		.el-form-item__content{
			margin-left: 0 !important;
			margin-top: 16px;
			.el-radio__label{
				font-family: PingFangSC, PingFang SC;
				font-weight: 400;
				font-size: 14px;
				color: rgba(0,0,0,0.88);
				line-height: 22px;
				text-align: left;
				font-style: normal;
				padding-left: 8px;
			}
			.el-radio__input.is-checked .el-radio__inner{
				border-color: #1677FF;
				background-color: #1677FF;
			}
			.el-cascader{
				width: 100%;
			}
			.el-input{
				min-width: 420px;
			}
			.width420{
				width: 420px;
			}
			.tipTiel{
				font-family: PingFangSC, PingFang SC;
				font-weight: 400;
				font-size: 14px;
				// color: rgba(0,0,0,0.45);
				line-height: 20px;
				text-align: left;
				font-style: normal;
				margin-left: 24px;
				display: flex;
				align-items: center;
			}
			
		}
    }
 

  .dabwue {
    margin-left: 10px;
    position: relative;
    top: 10px;
  }


  ::v-deep .el-tag.el-tag--info {
    background-color: #B5B5B5;
  }

  .conTBVox {
	   border-radius: 5px;
	   padding: 24px 0;
	   box-sizing: border-box;
	   background-color: #FFFFFF;
  }

 .certificateBox {
   display: flex;
 
   &_lines {
     width: 110px;
     margin-top: 20px;
     margin-right: 18px;
     text-align: center;
 
     &_imgBox {
       position: relative;
 
       .iconbox {
         display: none;
         position: absolute;
         top: 0;
         right: 0;
         width: 16px;
         height: 16px;
         background: rgba(0, 0, 0, 0.4);
         text-align: center;
         line-height: 16px;
       }
 
       .el-icon-close {
         color: #fff;
         font-size: 13px;
 
       }
     }
 
     &_name {
       line-height: 20px;
       word-break: break-all;
       text-align: center;
     }
   }
 }
 
 .certificateBox_lines_imgBox:hover {
   .iconbox {
     display: block;
   }
 }
  
  .tits {
    margin-top: 32px;
  	.tits-title{
  		font-family: PingFangSC, PingFang SC;
  		font-weight: 600;
  		font-size: 36px;
  		color: rgba(0,0,0,0.85);
  		line-height: 80px;
  		text-align: left;
  		font-style: normal;
  	}
  	.tits-info{
  		display: flex;
  		align-items: center;
  		font-family: PingFangSC, PingFang SC;
  		font-weight: 600;
  		font-size: 16px;
  		color: rgba(0,0,0,0.85);
  		line-height: 22px;
  		text-align: left;
  		font-style: normal;
  		margin-top: 32px;
  		.tits-left{
  			width: 6px;
  			height: 18px;
  			background: #4077DD;
  			border-radius: 2px;
  			margin-right: 17px;
  		}
  		.hengxian{
  			width: calc(100% - 115px);
  			height: 1px;
  			background-color: #E8E8E8;
  			margin-left: 22px;
  		}
  	}
  }
  
  .btn-list{
	  display: flex;
	  justify-content: center;
	  align-items: center;
	  margin-top: 20px;
	  margin-bottom: 32px;
	  ::v-deep .el-button{
	  	width: 96px;
	  	height: 40px;
	  	background: #FFFFFF;
		border: 1px solid #4077DD;
	  	border-radius: 8px;
	  	font-family: PingFangSC, PingFang SC;
	  	font-weight: 400;
	  	font-size: 16px;
	  	color: #4077DD;
	  	display: flex;
	  	align-items: center;
	  	justify-content: center;
	  }
	  .btns-submit{
		background: #4077DD;  
	  	color: #FFFFFF;
	  }
  }
  
  .topnavsss{
	  .lines{
		  padding: 1px 10px;
		  height: 28px;
		  background: rgba(64,119,221,0.06);
		  border-radius: 4px;
		  border: 1px solid #dedede;
		  margin-right: 8px;
	  }
	 
	  .names{
		  font-family: PingFangSC, PingFang SC;
		  font-weight: 400;
		  font-size: 16px;
		  color: #666;
		  line-height: 20px;
		  text-align: left;
		  font-style: normal;
	  }
	  
	  .activeLines{
		  border: 1px solid #4077DD;
		  .names{
			  color: #4077DD;
		  }
	  }
	  
	  
  }
  
</style>
